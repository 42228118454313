module.exports = {
	"Language": {
		"title": "भाषा"
	},
	"Noproductsforsale": "बिक्री के लिए कोई उत्पाद नहीं",
	"rechargePresentationText0_1": "रिचार्ज 1000 से  ",
	"rechargePresentationText0_2": ", रिचार्ज 10000 पर ",
	"rechargePresentationText0_3": ", इत्यादि... मुझे क्लिक करें!",
	"rechargePresentationText0": "अभी रिचार्ज शुरू करें और आपको रिचार्ज राशि का ",
	"rechargePresentationText1": "% मिलेगा। गतिविधि का समय सीमित है और दान समाप्त होने पर रुक जाएगा।",
	"rechargePresentationText2": "इनाम समाप्ति समय, उलटी गिनती!",
	"Online_Chat": "ऑनलाइन बातचीत",
	"gobindgoogle": "कृपया अपने Google प्रमाणक को बिन करें",
	"Order": "आदेश",
	"Record": "रिकॉर्ड",
	"Assist": "सहायता",
	"Market": "बाजार",
	"History": "इतिहास",
	"Confirm": "पुष्टि करें",
	"close": "बंद करे",
	"Cancel": "रद्द करना",
	"Previous": "पहले का",
	"Next": "अगला",
	"Copy": "प्रतिलिपि",
	"Nomoredata": "कोई और डेटा नहीं",
	"moneychar": "₹",
	"DownloadApp": "अभी ऐप डाउनलोड करें",
	"Yes": "हाँ",
	"No": "नहीं न",
	"Wating": "वाटिंग",
	"Recharge": "फिर से दाम लगाना",
	"Withdrawal": "निकासी",
	"Success": "सफलता",
	"OrderSuccessful": "आदेश सफल",
	"Handledataerror": "डेटा त्रुटि संभालें",
	"Copysuccess": "कॉपी सफलता",
	"MyBalanceLoding": "माई बैलेंस लॉजिंग",
	"UPDATE": "अपडेट करें",
	"New_version_available": "नया संस्करण उपलब्ध है",
	"Please,update_app_to_new_version": "कृपया, ऐप को नए संस्करण में अपडेट करें",
	"tabbar": {
		"Home": "घर",
		"AndarBahar": "Andar Bahar",
		"Invest": "निवेश",
		"Bonus": "बक्शीश",
		"Mine": "मेरी",
		"Trending": "ट्रेंडिंग",
		"Wallet": "बटुआ",
		"Rewards": "पुरस्कार"
	},
	"homeTop": {
		"Sign_Up": "साइन अप करें",
		"Sign_up_for_rewards": "पुरस्कार के लिए साइन अप करें",
		"up_to": "तक",
		"TOTAL_ASSETS": "कुल संपत्ति",
		"User_ID": "उपयोगकर्ता पहचान",
		"Registration_time": "पंजीकरण का समय"
	},
	"PieChart": {
		"BIG&SMALL": "छोटे बड़े",
		"NUMBER": "संख्या",
		"ODD&EVEN": "विषम सम",
		"ALL_NUMBER_TRENDING": "सभी नंबर ट्रेंडिंग",
		"BIG&SMALL_NUMBER_TRENDING": "बिग स्मॉल नंबर ट्रेंडिंग",
		"ODD&EVEN_NUMBER_TRENDING": "ऑड ईवन नंबर ट्रेंड कर रहा है"
	},
	"register": {
		"tips_3": "अगर आपको अपने इनबॉक्स में कोड नहीं मिला है, तो कृपया अपना स्पैम फोल्डर देखें।",
		"receiveCode": "कोड प्राप्त नहीं किया?",
		"receiveCode_title": "आपके ईमेल पर कोड भेज दिया गया है। कुछ प्रयासों के बाद भी यदि आपको कोड प्राप्त नहीं हुआ है, तो कृपया निम्नलिखित की कोशिश करें:",
		"receiveCode_text1": "1. अपने जंक / स्पैम मेल में देखें।",
		"receiveCode_text2": "2. सुनिश्चित करें कि आपका ईमेल पता",
		"receiveCode_text3": "3. संदेश कुछ मिनट के लिए देरी हो सकता है। 20 मिनट बाद फिर से कोशिश करें।",
		"receiveCode_text4": "4. यदि यह ईमेल पता पहले से ही मौजूद है, तो हम आपको एक प्रमाणीकरण कोड नहीं भेजेंगे। कृपया लॉगिन या अपना पासवर्ड रीसेट करें।",
		"Sign_up_for_Rewards": "पुरस्कार के लिए साइन अप करें",
		"Email_Verification": "ईमेल सत्यापन",
		"tips": "कृपया छह अंकों का सत्यापन कोड दर्ज करें जो {{formData.email}} पर भेजा गया था। कोड 30 मिनट के लिए मान्य है।",
		"tips_1": "कृपया छह अंकों का सत्यापन कोड दर्ज करें जो ",
		"tips_2": "पर भेजा गया था। कोड 30 मिनट के लिए मान्य है।.",
		"Resend_code_in": "कोड को फिर से भेजें",
		"Sent_again": "फिर भेजा है",
		"CREATE_ACCOUNT": "खाता बनाएं",
		"inviteCodecheck": "आमंत्रण कोड खाली नहीं हो सकता!",
		"Mobile": "मोबाइल नंबर",
		"Mobilemsg": "मोबाइल नंबर चाहिए",
		"Password": "कुंजिका",
		"Passwordmsg": "पासवर्ड की आवश्यकता है",
		"OTPCode": "ओटीपी कोड",
		"OTPCodemsg": "ओटीपी कोड आवश्यक है",
		"SendOTP": "OTP भेजें",
		"Email": "ईमेल",
		"Emailmsg": "ईमेल जरुरी है",
		"Invitecode": "कोड आमंत्रित",
		"Invitecodemsg": "आमंत्रण कोड आवश्यक है",
		"emailFormatter": "कृपया सही ईमेल पता दर्ज करें",
		"usernameFormatter": "उपयोगकर्ता नाम वर्ण और संख्या का होना चाहिए और श्रेणी 6<=लंबाई<=20 . होनी चाहिए",
		"phoneFormatter": "कृपया 10 अंकों का मोबाइल फ़ोन नंबर भरें phone",
		"msgsuccess": "सफलतापूर्वक पंजीकृत",
		"msgerr": "पंजीकरण करने में विफल",
		"errorphonecheck": "मोबाइल फ़ोन नंबर खाली नहीं हो सकता!",
		"phonecheck": "कृपया 10 अंकों का मोबाइल फ़ोन नंबर भरें!",
		"imgcodecheck": "ओटीपी कोड खाली नहीं हो सकता!"
	},
	"login": {
		"Welcome_back": "वापसी पर स्वागत है",
		"tips": "I have read and aggree to {{this.$LogoName}} Term of Service and Privacy Policy",
		"tips_1": "मैंने पढ़ लिया है और इससे सहमत हूं",
		"tips_2": "सेवा की अवधि",
		"tips_3": "और",
		"tips_4": "गोपनीयता नीति",
		"Continue_with_Google": "Google के साथ जारी रखें",
		"Continue_with_Apple": "सेब के साथ जारी रखें",
		"text": "अब तक कोई खाता नहीं है?",
		"text2": "क्या आपके पास पहले से एक खाता मौजूद है?",
		"Login": "लॉग इन करें",
		"Register": "रजिस्टर करें",
		"username": "उपयोगकर्ता नाम या फोन नंबर",
		"password": "कुंजिका",
		"imgcode": "छवि कोड",
		"BackHome": "घर वापस",
		"Forgetpwd": "पासवर्ड भूल गए"
	},
	"forgot": {
		"title": "पासवर्ड भूल गए",
		"msgsuccess": "पासवर्ड रीसेट पूर्ण",
		"newpassword": "नया पासवर्ड",
		"newpasswordmsg": "नया पासवर्ड आवश्यक है",
		"Continue": "जारी रखें"
	},
	"home": {
		"Revolutionize_Trading": "ट्रेडिंग में क्रांति लाएं",
		"Shape_the_Future": "भविष्य को आकार दें",
		"content0": "आइए एक साथ क्रिप्टो करेंसी एक्सचेंज के नए युग का अनुभव करें!",
		"3_Reasons_for_Choosing": "चुनने के 3 कारण",
		"Innovative_Trading_Features": "अभिनव ट्रेडिंग सुविधाएँ",
		"content1": "क्रांतिकारी व्यापारिक अनुभव जो कि वर्तमान में बाजार में उपलब्ध किसी भी चीज़ के विपरीत है। आपको नवीन व्यापारिक सुविधाओं, उन्नत सुरक्षा उपायों और अत्यधिक तेज़ लेनदेन गति के साथ प्रतिस्पर्धा में बढ़त देने के लिए डिज़ाइन किया गया है।",
		"Lower_Fees_and_Better_Rewards": "कम शुल्क और बेहतर पुरस्कार",
		"content2": "हमारा इंटरफ़ेस नौसिखियों के लिए ट्रेडिंग को आसान बनाता है। आप हमारे बाज़ार से कम लेनदेन शुल्क के साथ मुफ्त में एक खाता बना सकते हैं, क्रिप्टो व्यापार कर सकते हैं। इसके अलावा, आप अपने द्वारा आमंत्रित सभी उपयोगकर्ताओं के लिए एक्सचेंज से 20% तक लेनदेन शुल्क साझा कर सकते हैं।",
		"Institutional-grade_Security": "संस्थागत ग्रेड सुरक्षा",
		"content3": "उद्योग के उच्चतम सुरक्षा मानकों में से एक का पालन करता है। साथ ही हम आपके क्रिप्टो का 100% कोल्ड स्टोरेज में स्टोर करते हैं। हम अपने ग्राहक निधियों के कम से कम 1:1 आरक्षित अनुपात की गारंटी देते हैं। हमारा 300 मिलियन यूएसडीटी फंड सुरक्षा खतरों के खिलाफ सुरक्षा की एक अतिरिक्त परत प्रदान करता है।",
		"Start_investing_in_as_little_as_5_minutes": "कम से कम 5 मिनट में निवेश करना शुरू करें",
		"content4": "{{this.$LogoNames.toUpperCase()}} के साथ निःशुल्क साइन अप करें",
		"content4_1": "इसके साथ साइन अप करें ",
		"content4_2": " मुक्त करने के लिए",
		"Complete_google_authentication": "पूरा गूगल प्रमाणीकरण",
		"content5": "आरंभ करने के लिए अपना ईमेल पता दर्ज करें और सत्यापित करें। खाता खोलने के लिए कोई शुल्क नहीं है।",
		"content7": "अपने Google प्रमाणक को 5 मिनट से कम समय में सत्यापित करके पूर्ण कार्यक्षमता अनलॉक करें।",
		"content8": "Fund your {{this.$LogoNames.toUpperCase()}} account instantly",
		"content8_1": "अपना फंड",
		"content8_2": "तुरंत खाता",
		"content9": "चुनिंदा नेटवर्क के माध्यम से USDT जमा करें और अपने फंड तक तुरंत पहुंचें। ट्रेडिंग शुरू करने के लिए 3-5 दिन इंतजार करने की जरूरत नहीं है।",
		"Start_building_your_crypto_portfolio": "अपना क्रिप्टो पोर्टफोलियो बनाना शुरू करें",
		"Invitation_Program": "निमंत्रण कार्यक्रम",
		"Referral_Program": "नाम लेने का कार्यक्रम",
		"content10": "किसी भी मान्य आमंत्रण को पूरा करें और 10 USDT का आमंत्रण बोनस औसत मूल्य प्राप्त करें। एक व्यक्ति के लिए अधिकतम बोनस है ",
		"content11": "आमंत्रणकर्ता अपने आमंत्रितों द्वारा सृजित लेनदेन की मात्रा के अनुपात में बोनस प्राप्त कर सकते हैं। एक वैध आमंत्रण के लिए अधिकतम बोनस Unlimit USDT है।",
		"TRADING_TASKS": "ट्रेडिंग कार्य",
		"content12": "विभिन्न ट्रेडिंग मोड के लिए ट्रेडिंग, विविध ट्रेडिंग कार्यों के दौरान उपयोगकर्ताओं को यूएसडीटी में पुरस्कार प्राप्त होंगे। पुरस्कार तुरंत वितरित किए जाएंगे",
		"Trade_anytime_anywhere": "व्यापार कभी भी, कहीं भी",
		"content13": "न्यूयॉर्क स्टेट डिपार्टमेंट ऑफ फाइनेंशियल सर्विसेज द्वारा यूएसए, इंक। को वर्चुअल करेंसी बिजनेस एक्टिविटी में संलग्न होने के लिए लाइसेंस दिया गया है।",
		"USA_Inc": "यूएसए, इंक",
		"content6": "24/7 कनेक्ट रहने के लिए हमारा ऐप डाउनलोड करें",
		"Legal&Disclosures": "कानूनी और प्रकटीकरण",
		"Regulatory_License": "नियामक लाइसेंस",
		"AML/KYC_Policies": "एएमएल/केवाईसी नीतियां",
		"Privacy_Policy": "गोपनीयता नीति",
		"Terms_of_Use": "उपयोग की शर्तें",
		"Legal_Statement": "कानूनी वक्तव्य",
		"Risk_Disclosure": "जोखिम प्रकटीकरण",
		"About_Us": "हमारे बारे में",
		"Proof_of_Reserves": "आरक्षण का प्रमाण",
		"Twitter": "ट्विटर",
		"Bitget_Token": "बिटगेट टोकन",
		"Careers": "करियर",
		"Company": "कंपनी",
		"Support_Service": "सपोर्ट सेवा",
		"title": "एक ऐप के साथ खोलें",
		"titlebtn": "डाउनलोड",
		"Balance": "संतुलन",
		"ID": "ईद",
		"Recharge": "फिर से दाम लगाना",
		"Withdraw": "निकालना",
		"Bonus": "बक्शीश",
		"Checkin": "चेक इन",
		"Eventclosed": "घटना बंद",
		"Ranking": "श्रेणी"
	},
	"Checkin": {
		"Checkin": "चेक इन",
		"CheckinList": "चेक इन लिस्ट"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "नियम",
		"Record": "अभिलेख",
		"Records": "Andar Bahar अभिलेख",
		"MyOrder": "मेरा आदेश",
		"more": "अधिक",
		"Period": "अवधि",
		"Order": "गण",
		"Buy": "खरीद",
		"Result": "परिणाम",
		"Profit": "फायदा",
		"AndarBaharRule": "Andar Bahar नियम",
		"CountDown": "काउंट डाउन",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "परिणाम",
		"ok": "ठीक है",
		"start": "शुरू",
		"Point": "बिंदु",
		"ContractPoint": "अनुबंध बिंदु",
		"Number": "संख्या",
		"Delivery": "वितरण",
		"Fee": "शुल्क",
		"Amount": "रकम"
	},
	"pokerOrder": {
		"OrderRecord": "आदेश रिकॉर्ड",
		"WatingOrder": "खाने का आदेश",
		"HistoryOrder": "इतिहास आदेश",
		"Nomoredata": "कोई और डेटा नहीं",
		"Buy": "खरीद",
		"Result": "परिणाम",
		"Time": "समय",
		"Amount": "रकम",
		"Fee": "शुल्क",
		"Profit": "फायदा"
	},
	"pokerRecord": {
		"title": "Andar Bahar अभिलेख",
		"Period": "अवधि",
		"Result": "परिणाम",
		"OpenTime": "खुला समय",
		"Nomore": "अब और नहीं"
	},
	"Google2FA": {
		"title": "Google 2FA प्रमाणीकरण",
		"Authenticator_Instructions": "प्रमाणक निर्देश",
		"Authenticator_Status": "प्रमाणक स्थिति",
		"Google_Authenticator": "गूगल प्रमाणक",
		"Download_Google_Authenticator_App": "Google प्रमाणक ऐप डाउनलोड करें",
		"App_Store": "ऐप स्टोर",
		"Google_Play": "गूगल प्ले",
		"Next": "अगला",
		"Backup_key": "बैकअप कुंजी",
		"Backup_key_text": "कृपया इस बैकअप कुंजी को सुरक्षित स्थान पर सहेजें। यदि आप अपना फोन खो देते हैं तो यह कुंजी आपको अपने प्रमाणीकरणकर्ता को पुनर्प्राप्त करने की अनुमति देगी। अन्यथा, अपने प्रमाणक को रीसेट करने के लिए ग्राहक सेवा से संपर्क करना होगा।",
		"Verifiaction": "सत्यापन",
		"Verifiaction_text": "Google सत्यापन कोड दर्ज करें",
		"Google_verification_code": "Google सत्यापन कोड",
		"contentlist1": "1.16 अंकों की बैकअप कुंजी को +this.$LogoName+ से रिकॉर्ड करें और इसे तिजोरी में रखें।",
		"contentlist1_1": "1.16 अंकों की बैकअप कुंजी को",
		"contentlist1_2": "से रिकॉर्ड करें और इसे तिजोरी में रखें।",
		"contentlist2": "2. Google प्रमाणक खोलें।",
		"contentlist3": "3. एक +this.$LogoName+ खाता जोड़ें और कॉपी की गई 16-अंकीय कुंजी दर्ज करें।",
		"contentlist3_1": "3. एक +this.$LogoName",
		"contentlist3_2": "खाता जोड़ें और कॉपी की गई 16-अंकीय कुंजी दर्ज करें।.",
		"contentlist4": "4. आपको स्क्रीन पर 6 अंकों का सत्यापन कोड दिखाई देगा।",
		"contentlist5": "5. 6 अंकों का कोड कॉपी करें और इसे +this.$LogoName+ में पेस्ट करें।",
		"contentlist5_1": "5. 6 अंकों का कोड कॉपी करें और इसे",
		"contentlist5_2": "में पेस्ट करें।",
		"contentlist6": "6.फिर, आपका प्रमाणक सफलतापूर्वक लिंक हो जाएगा।"
	},
	"win": {
		"Live_Price": "लाइव कीमत",
		"title1": "विनिवेश परिणाम UTC समय के अनुसार Binance BTC/USDT स्पॉट व्यापार के अंतिम अंक द्वारा गणना की जाती है। निवेशकों को किसी भी समय Binance पर परिणाम की जांच करने की सलाह दी जाती है ताकि निवेश की निष्पक्षता और सुरक्षा सुनिश्चित हो सके।",
		"title": "विनिवेश में 2 मोड हैं",
		"1M_Mode": "लाइट 1M",
		"1M_Mode_text": "प्रत्येक दौर में 1 मिनट लगता है। लाइट मोड केवल विषम और सम क्रम के नंबर निवेश प्रदान करता है। परिणाम को Binance BTC/USDT मार्जिन ट्रेड 1M अंतराल के बंद परिणाम के अंतिम अंक द्वारा गणना की जाती है।",
		"5M_Mode": "प्रो 5M",
		"5M_Mode_text": "प्रत्येक दौर 5 मिनट लेता है। प्रो मोड EPOCH EPT के पूर्ण निवेश विधि को अनलॉक करता है। परिणाम Binance BTC/USDT मार्जिन व्यापार 5 मिनट के अंतराल के बंद परिणाम के अंतिम अंक द्वारा गणना की जाती है।",
		"5M_Mode_text_1": "प्रत्येक दौर 5 मिनट लेता है। प्रो मोड ",
		"5M_Mode_text_2": " के पूर्ण निवेश विधि को अनलॉक करता है। परिणाम Binance BTC/USDT मार्जिन व्यापार 5 मिनट के अंतराल के बंद परिणाम के अंतिम अंक द्वारा गणना की जाती है।",
		"Investment_Guide": "निवेश गाइड",
		"Investment_Rules": "निवेश नियम",
		"title2": "कैसे साबित करें कि परिणाम निष्पक्ष है?",
		"Investment_Info": "निवेश की जानकारी",
		"3M_Mode": "3M मोड",
		"3M_Mode_text": "प्रत्येक दौर में 3 मिनट लगते हैं। परिणाम की गणना Binance BTC/USDT मार्जिन ट्रेड 3M इंटरवल के क्लोज रिजल्ट के अंतिम अंक से की जाती है।",
		"Investment_return": "निवेश पर रिटर्न",
		"Number": "संख्या",
		"Profit_Invested_amount": "लाभ: निवेशित राशि",
		"guide1": "एक समय मोड चुनें।",
		"guide2": "अपनी निवेश राशि समायोजित करें।",
		"guide3": "उन नंबरों को चुनें जिनमें आप निवेश करना चाहते हैं।",
		"guide4": "अपने पोर्टफोलियो की जांच करें और निवेश पर क्लिक करें।",
		"NEXT": "अगला",
		"Invest_Amount": "निवेश राशि",
		"Total": "कुल",
		"INVEST": "निवेश करना",
		"Portfolio": "विभाग",
		"Invest": "निवेश करना",
		"BINANCE": "बायनेन्स",
		"Bitcoin_Price": "बिटकॉइन की कीमत",
		"InvestmentRecord": "निवेश रिकॉर्ड",
		"Best_Assistant": "सर्वश्रेष्ठ सहायक",
		"last_100_rounds": "(आखिरी 100 राउंड)",
		"What_do_these_data_mean": "इन डेटा का क्या मतलब है",
		"Missing": "मिसिंग",
		"Missing_text": "इसका मतलब है कि पिछले राउंड के बाद से राउंड की संख्या जिसने इस संख्या का उत्पादन किया।.",
		"Frequency": "आवृत्ति",
		"Frequency_text": "नवीनतम 100 राउंड में दिखाई देने वाली संबंधित संख्या के समय को संदर्भित करता है।.",
		"Max_Continued": "अधिकतम जारी",
		"Max_Continued_text": "पिछले 100 राउंड में संबंधित संख्या की अधिकतम लगातार उपस्थिति अवधि को संदर्भित करता है।",
		"Rule": "नियम",
		"Trend": "ट्रेंड",
		"MyBalance": "मेरा संतुलन",
		"JoinGreen": "ग्रीन में शामिल हों",
		"JoinRed": "रेड में शामिल हों",
		"JoinViolet": "वायलेट में शामिल हों",
		"Parity": "Parity",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "अभिलेख",
		"more": "अधिक",
		"Period": "अवधि",
		"Price": "कीमत",
		"Result": "परिणाम",
		"My": "मेरे",
		"Order": "गण",
		"Buy": "खरीद",
		"Profit": "फायदा",
		"Green": "हरा भरा",
		"Red": "लाल",
		"Violet": "बैंगनी",
		"tips": "The transaction fee is {bet_fee*100}% of the order amount",
		"tips1": "लेन-देन शुल्क ऑर्डर राशि का ",
		"tips2": "है ",
		"OrderAmount": "ऑर्डर करने की राशि",
		"Quantity": "मात्रा",
		"TransactionFee": "लेनदेन शुल्क",
		"TotalAmount": "कुल राशि",
		"PresalePolicy": "पूर्व बिक्री नीति",
		"Iagree": "मैं सहमत हूं",
		"err1": "कृपया पहले बिक्री-पूर्व प्रबंधन नियमों से सहमत हों"
	},
	"historyPeriods": {
		"Orderrecord": "आदेश रिकॉर्ड",
		"record": "अभिलेख"
	},
	"historyOrderPeriods": {
		"WatingOrder": "खाने का आदेश",
		"HistoryOrder": "इतिहास आदेश"
	},
	"Activity": {
		"added_to_account_balance": "खाते की शेष राशि में जोड़ा गया।.",
		"contentText": "जब आमंत्रित अपनी पहली बार जमा करते हैं, तो यह एक वैध आमंत्रण के रूप में गिना जाता है। आमंत्रक प्रत्येक वैध आमंत्रण के लिए एक आमंत्रण बोनस प्राप्त करेंगे। आमंत्रण बोनस टियर 1 वैध आमंत्रण के लिए {{aaaaaa}} USDT से शुरू होता है और 500 वैध आमंत्रण के लिए {{aaaaaa}} USDT तक होता है।",
		"contentText_1": "जब आमंत्रित अपनी पहली बार जमा करते हैं, तो यह एक वैध आमंत्रण के रूप में गिना जाता है। आमंत्रक प्रत्येक वैध आमंत्रण के लिए एक आमंत्रण बोनस प्राप्त करेंगे। आमंत्रण बोनस टियर 1 वैध आमंत्रण के लिए",
		"contentText_2": "1 वैध आमंत्रण के लिए ",
		"contentText_3": " 500 वैध आमंत्रण के लिए।",
		"contentText_3_1": " ",
		"contentText_3_2": " वैध आमंत्रण के लिए।",
		"contentText2": "आमंत्रक द्वारा प्रत्येक LV.1 आमंत्रित व्यापार आय के {{LV.1%}} ट्रेडिंग शुल्क का साझा किया जाएगा। आमंत्रक द्वारा प्रत्येक LV.2 आमंत्रित व्यापार आय के {{LV.2%}} ट्रेडिंग शुल्क का साझा किया जाएगा। आमंत्रित व्यापार आय बोनस के लिए अधिकतम बोनस Unlimit USDT होगा। ",
		"contentText2_1": "आमंत्रित करने वाले हर LV.1 आमंत्रित के ट्रेडिंग वॉल्यूम से {{LV.1%}} ट्रेडिंग फीस साझा करेंगे।",
		"contentText2_2": "आमंत्रित करने वाले हर LV.2 आमंत्रित के ट्रेडिंग वॉल्यूम से {{LV.2%}} ट्रेडिंग फीस साझा करेंगे।",
		"contentText2_3": "एक आमंत्रित के ट्रेडिंग वॉल्यूम बोनस के लिए अधिकतम बोनस असीमित USDT है।",
		"My_Rewards_History": "मेरा पुरस्कार इतिहास",
		"Cumulative_Single_Number_Trading_Volumn": "संचयी एकल संख्या ट्रेडिंग वॉल्यूम",
		"Cumulative_ODD_Number_Trading_Volumn": "संचयी ODD नंबर ट्रेडिंग वॉल्यूम",
		"Cumulative_EVEN_Number_Trading_Volumn": "संचयी ईवन नंबर ट्रेडिंग वॉल्यूम",
		"Cumulative_BIG_Number_Trading_Volumn": "संचयी बिग नंबर ट्रेडिंग वॉल्यूम",
		"Cumulative_SMALL_Number_Trading_Volumn": "संचयी छोटी संख्या ट्रेडिंग वॉल्यूम",
		"Advanced_Tasks": "उन्नत कार्य",
		"Invite_Friends_and_Earn_Up_to": "मित्रों को आमंत्रित करें और तक कमाएँ",
		"Referral_Code": "रेफरल कोड",
		"Referral_Link": "सिफ़ारिश के लिंक",
		"INVITE_FRIENDS": "मित्रों को आमंत्रित करें",
		"Complete_tasks_to_earn_up_to": "तक कमाने के लिए कार्य पूर्ण करें",
		"MY_REWARDS": "मेरे पुरस्कार",
		"SHARE": "शेयर करना",
		"Earn_with_your_friends_together": "अपने दोस्तों के साथ मिलकर कमाएं",
		"Get_your_link": "अपना लिंक प्राप्त करें",
		"Claim_your_rewards": "अपने पुरस्कारों का दावा करें",
		"Earn_together": "एक साथ कमाएँ",
		"contentlist0": "{{gogobit}} से शामिल हों और अपना रेफरल लिंक प्राप्त करें। इस लिंक के माध्यम से साइन अप करने वाले उपयोगकर्ताओं को इस लिंक के माध्यम से पुरस्कार मिलेंगे।",
		"contentlist0_1": "शामिल हों ",
		"contentlist0_2": " और अपना रेफरल लिंक प्राप्त करें। इस लिंक के माध्यम से साइन अप करने वाले उपयोगकर्ताओं को इस लिंक के माध्यम से पुरस्कार मिलेंगे।",
		"contentlist1": "आपको आपके दोस्तों द्वारा पहली जमा राशि करने और आवश्यक व्यापार मात्रा पूरी करने के बाद {{XX}} USDT का रेफरल पुरस्कार मिलेगा। पुरस्कार {{XX}} USDT तक हो सकते हैं।",
		"contentlist1_1": "आपको आपके दोस्तों द्वारा पहली जमा राशि करने और आवश्यक व्यापार मात्रा पूरी करने के बाद औसत मूल्य ",
		"contentlist1_2": " USDT का रेफरल पुरस्कार मिलेगा। आपके दोस्तों द्वारा पहली जमा राशि करने और आवश्यक व्यापार मात्रा पूरी करने के बाद पुरस्कार ",
		"contentlist1_3": "USDT तक हो सकते हैं।",
		"contentlist2": "आपके मित्र द्वारा खाता बनाने के बाद, वह 5,050 USDT तक के ट्रेडिंग बोनस का दावा कर सकता/सकती है।",
		"Referral_Rewards": "रेफरल पुरस्कार",
		"Detail_Rules": "विवरण नियम",
		"Invitation_Bonus": "निमंत्रण बोनस",
		"Up_to": "तक",
		"content0": "किसी भी मान्य आमंत्रण को पूरा करें और 10 USDT का आमंत्रण बोनस औसत मूल्य प्राप्त करें। एक व्यक्ति के लिए अधिकतम बोनस है ",
		"Trading_Bonus": "ट्रेडिंग बोनस",
		"content1": "आमंत्रणकर्ता अपने आमंत्रितों द्वारा सृजित लेनदेन की मात्रा के अनुपात में बोनस प्राप्त कर सकते हैं। एक वैध आमंत्रण के लिए अधिकतम बोनस है",
		"My_Referral_History": "मेरा रेफरल इतिहास",
		"Total_Invitees": "कुल आमंत्रित",
		"Valid_Invitees": "वैध आमंत्रित",
		"Cumulative_Invitees": "आमंत्रित",
		"Trading_Volumn": "व्यापार की मात्रा",
		"Detail_Rules_title": "888 यूएसडीटी का दैनिक लकी ड्रॉ",
		"Detail_Rules_title1": "1. दिन के फ्यूचर ट्रेडिंग डेटा की गणना अगले दिन की जाएगी।",
		"Detail_Rules_title2": "2. जो उपयोगकर्ता उस दिन 200,000USDT या उससे अधिक का वायदा कारोबार करते हैं, उन्हें 888 USDT पुरस्कार के लिए एक बार ड्रॉ करने का मौका मिलेगा।",
		"CLAIM_NOW": "अभी दावा करें",
		"INVEST_NOW": "अभी निवेश करें",
		"title": "बोनस वॉलेट",
		"BonusBalance": "बोनस बैलेंस",
		"Withdrawtobalance": "संतुलन के लिए वापस लेना",
		"BonusList": "बोनस सूची",
		"Bonuswithdrawtobalance": "शेष राशि के लिए बोनस निकालें",
		"WithdrawalAmount": "निकाली गयी राशि",
		"Pleaseentertheamount": "कृपया राशि दर्ज करें",
		"err1": "निकासी राशि 0 . नहीं हो सकती",
		"success": "स्थानांतरण सफलता"
	},
	"Refer": {
		"title": "मेरा संदर्भ",
		"ReferTotalBonus": "कुल बोनस देखें",
		"InviteFriends": "मित्रों को आमंत्रित करें",
		"Level1": "स्तर 1",
		"Level2": "स्तर 2",
		"TotalPeople": "कुल लोग",
		"Bonus": "बक्शीश",
		"Myinvitationlink": "मेरा आमंत्रण लिंक",
		"Rebate_description": "छूट विवरण"
	},
	"Task": {
		"InviteBonusToday": "आज ही बोनस आमंत्रित करें",
		"bonusrecord": "बोनस रिकॉर्ड",
		"hint1": "1. डेटा हर 10-30 मिनट में अपडेट किया जाता है !",
		"hint2": "2. केवल उन लोगों की गणना की जाती है जिन्होंने पिछले 3 दिनों में पंजीकरण किया है और आज रिचार्ज पूरा किया है !",
		"hint2_1": "2. केवल उन लोगों की गणना की जाती है जिन्होंने पिछले ",
		"hint2_2": " दिनों में पंजीकरण किया है और आज रिचार्ज पूरा किया है !",
		"hint3": "3. 20u minimum deposit required today!",
		"hint3_1": "3. ",
		"hint3_2": " न्यूनतम जमा आज आवश्यक है!",
		"hint4": "पिछले 3 दिनों में आमंत्रित लोगों की संख्या",
		"hint4_1": "पिछले ",
		"hint4_2": " दिनों में आमंत्रित लोगों की संख",
		"hint5": "आज रिचार्ज पूरा करने वालों की संख्या",
		"Invite": "आमंत्रण",
		"friendstorecharge": "दोस्तों रिचार्ज करने के लिए",
		"Earn": "कमाना",
		"Completed": "पूरा हुआ",
		"ExpireTime": "समाप्त हुई समय सीमा"
	},
	"Invited": {
		"title": "बोनस रिकॉर्ड",
		"InviteTotalBonus": "कुल बोनस आमंत्रित करें",
		"InviteTime": "समय आमंत्रित करें",
		"Bonus": "बक्शीश",
		"NoRecored": "कोई रिकॉर्ड नहीं"
	},
	"mine": {
		"JOIN US": "हमसे जुड़ें",
		"title": "व्यक्तिगत केंद्र",
		"InviteCode": "कोड आमंत्रित",
		"AccountBalance": "खाते में शेष",
		"BonusBalance": "बोनस बैलेंस",
		"Wallet": "बटुआ",
		"Bank": "बैंक",
		"Invite": "आमंत्रण",
		"Refer": "उल्लेख",
		"CustomerService": "ग्राहक सेवा",
		"ComplaintsSuggestions": "शिकायतें और सुझाव",
		"ChangePassword": "पासवर्ड बदलें",
		"Activity": "गतिविधि",
		"PresalePolicy": "पूर्व बिक्री नीति",
		"DownloadApp": "ऐप डाउनलोड करें",
		"AppVersion": "एप्लिकेशन वेरीज़न",
		"Logout": "लॉग आउट",
		"Logouttips": "क्या आप लॉग आउट करना चाहते हैं ?"
	},
	"App": {
		"title": "ऐप डाउनलोड",
		"DownloadforAndroid": "एंड्रॉइड के लिए डाउनलोड करें",
		"DownloadforiOS": "आईओएस के लिए डाउनलोड करें"
	},
	"Presale": {
		"title": "पूर्व बिक्री नीति"
	},
	"Account": {
		"title2": "नया पासवर्ड सेट करें",
		"title2_text": "अपने गूगल अकाउंट के लिए एक नया पासवर्ड सेट करें। आप पासवर्ड का उपयोग करके अपने गूगल अकाउंट में लॉगिन कर सकते हैं।",
		"title_text": "आप अपना पासवर्ड रीसेट करने की कोशिश कर रहे हैं। कृपया रीसेट के लिए अपने गूगल एथेंटिकेटर को तैयार रखें।",
		"err1": "दो नए पासवर्ड अलग-अलग हैं",
		"success2": "पासवर्ड सफलतापूर्वक बदल दिया गया है",
		"title": "पासवर्ड बदलें",
		"OldPassword": "पुराना पासवर्ड",
		"OldPasswordmsg": "पुराना पासवर्ड आवश्यक है",
		"NewPassword": "नया पासवर्ड",
		"NewPasswordmsg": "नया पासवर्ड आवश्यक है",
		"RetypeNewPassword": "नया पासवर्ड दोबारा टाइप करें",
		"RetypeNewPasswordmsg": "नया पासवर्ड दोबारा टाइप करें",
		"Continue": "जारी रखें"
	},
	"Service": {
		"title": "सेवा",
		"CONTACTUS": "संपर्क करें",
		"tips": "ग्राहक सेवा विराम ले रही है !",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "संपर्क"
	},
	"Bank": {
		"BankName": "बैंक का नाम",
		"ChooseaCurrencyType": "मुद्रा प्रकार का चयन करें",
		"SelectWithdrawalMethod": "निकासी विधि का चयन करें",
		"Newbankcard": "नया बैंक कार्ड",
		"Editbankcard": "बैंक कार्ड संपादित करें",
		"title": "मेरा बैंक",
		"Cannotbeempty": "खाली नहीं किया जा सकता",
		"BankHolderName": "बैंक धारक का नाम",
		"BankAccountNumber": "बैंक खाता संख्या",
		"IFSCCode": "IFSC कोड",
		"Email": "ईमेल",
		"Phone": "फ़ोन",
		"OTPCode": "ओटीपी कोड",
		"tips1": "ओटीपी प्राप्त करने के लिए कृपया पंजीकृत मोबाइल फोन नंबर का उपयोग करें",
		"tips2": "धन की सुरक्षा के लिए, बैंक कार्ड को दिन में केवल एक बार संशोधित किया जा सकता है",
		"Add": "बढ़ना",
		"error1": "बैंक धारक का नाम खाली नहीं हो सकता!",
		"error2": "कृपया बैंक धारक का सही नाम दर्ज करें!",
		"error3": "बैंक का नाम खाली नहीं हो सकता!",
		"error4": "कृपया बैंक का सही नाम दर्ज करें!",
		"error5": "बैंक खाता संख्या खाली नहीं हो सकती!",
		"error6": "कृपया सही बैंक खाता संख्या दर्ज करें!",
		"error7": "IFSC कोड खाली नहीं हो सकता!",
		"error8": "कृपया सही IFSC कोड दर्ज करें!",
		"error9": "ईमेल खाली नहीं हो सकता!",
		"error10": "कृपया सही ईमेल पता दर्ज करें!",
		"error11": "फ़ोन खाली नहीं हो सकता!",
		"error12": "ओटीपी कोड खाली नहीं हो सकता!",
		"Successfully": "सफलतापूर्वक बैंक कार्ड जोड़ें"
	},
	"wallet": {
		"title": "मेरा बटुआ",
		"MyBalance": "मेरा संतुलन",
		"MyBank": "मेरा बैंक",
		"RechargeRecords": "रिचार्ज रिकॉर्ड",
		"WithdrawalRecords": "निकासी रिकॉर्ड"
	},
	"Rechargepage": {
		"Reminder": "अनुस्मारक",
		"Reminder_1": "1. पते पर यूएसडीटी के अलावा कोई अन्य मुद्रा जमा न करें। इस पते पर असमर्थित सिक्के या टोकन भेजने से अपूरणीय हानि हो सकती है।",
		"Reminder_2": "2. न्यूनतम जमा राशि 5USDT है।",
		"Reminder_2_1": "2. न्यूनतम जमा राशि ",
		"Reminder_2_2": "USDT है।.",
		"Reminder_3": "3. जमा 15 नेटवर्क की पुष्टि के बाद आ जाएगा।",
		"Minimum": "न्यूनतम",
		"title": "फिर से दाम लगाना",
		"History": "इतिहास",
		"Mybalance": "मेरा संतुलन",
		"RechargeAmount": "रिचार्ज राशि",
		"RechargeAmountmsg": "कृपया राशि दर्ज करें",
		"tips1": "भुगतान चैनल रखरखाव के अधीन है !",
		"tips2": "कृपया भुगतान चैनल चुनें",
		"PayNow": "अब भुगतान करें",
		"err1": "भुगतान चैनल रखरखाव के अधीन है",
		"err2": "राशि . से कम नहीं हो सकती ",
		"err3": "कृपया भुगतान चैनल चुनें",
		"Rechargemethod": "रिचार्ज विधि"
	},
	"Rechargerecord": {
		"title": "रिचार्ज रिकॉर्ड",
		"WatingOrder": "खाने का आदेश",
		"HistoryOrder": "इतिहास आदेश",
		"Rupee": "डॉलर",
		"Status": "स्थिति",
		"Time": "समय"
	},
	"withdrawal": {
		"Tips": "टिप्स",
		"Tips_1": "युक्तियाँ: ",
		"Tips_2": " USDT के लिए निकासी शुल्क और ",
		"Tips_3": " USDT से कम है, ",
		"Tips_4": " USDT से अधिक के लिए निकासी शुल्क ",
		"Tips_5": "% है।",
		"WalletAddress": "बटुआ पता",
		"Addwalletaddress": "बटुआ पता जोड़ें",
		"Network_fee": "नेटवर्क शुल्क",
		"Receive_amount": "राशि प्राप्त करें",
		"Select Chains": "जंजीरों का चयन करें",
		"Bankcard": "बैंक कार्ड",
		"Nobankcardselected": "कोई बैंक कार्ड नहीं जोड़ा गया",
		"Withdrawalfee": "निकासी शुल्क",
		"title": "निकासी",
		"Mybalance": "मेरा संतुलन",
		"Withdrawablebalance": "निकासी योग्य शेष राशि",
		"tips1": "The buy total amount must be more than {{codeAmount}} can be withdrawn in full",
		"tips1-1": "खरीद की कुल राशि",
		"tips1-2": " से अधिक होनी चाहिए, पूरी तरह से निकाली जा सकती है",
		"tips2": "Withdrawals per day is {{draw_num}} times",
		"tips2-1": "प्रति दिन निकासी ",
		"tips2-2": "गुना है",
		"tips3": "अधिकतम दैनिक निकासी राशि है",
		"tips4": "The minimum single withdrawal amount is ₹{{draw_min_money}} and the maximum single withdrawal amount is ₹{{draw_max_money}}",
		"tips4-1": "न्यूनतम एकल निकासी राशि ",
		"tips4-2": "है और अधिकतम एकल निकासी राशि ",
		"tips5": "The withdrawal time is {{draw_time}}, please withdraw at the specified time",
		"tips5-1": "निकासी का समय",
		"tips5-2": "है, कृपया निर्दिष्ट समय पर निकासी करें",
		"tips6": "Cash withdrawal will deduct {{draw_fee}}% of the cash withdrawal fee",
		"tips6-1": "नकद निकासी से नकद निकासी शुल्क का",
		"tips6-2": "काट लिया जाएगा",
		"tips7": "If your withdrawal amount is less than or equal to ₹{{solid_min_amount}}, a fixed fee of ₹{{solid_feemoney}} will be charged",
		"tips7-1": "अगर आपकी निकासी राशि",
		"tips7-2": "से कम या उसके बराबर है, तो",
		"tips7-3": "का एक निश्चित शुल्क लिया जाएगा",
		"WithdrawalAmount": "निकाली गयी राशि",
		"WithdrawalAmountmsg": "कृपया राशि दर्ज करें",
		"Withdrawal": "निकासी",
		"WithdrawalHistory": "निकासी इतिहास",
		"Tipsmsg": "निकासी आवेदन सफल रहा, ऑडिट परिणाम की प्रतीक्षा है !",
		"dangerMsg1": "कृपया पहले अपना बैंक कार्ड जोड़ें",
		"dangerMsg2": "निकासी राशि . से कम नहीं हो सकती",
		"dangerMsg3": "निकासी राशि 100 . का गुणक होनी चाहिए",
		"dangerMsg4": "निकासी राशि . से अधिक नहीं हो सकती",
		"dangerMsg5": "निकासी राशि निकासी योग्य शेष राशि से अधिक नहीं हो सकती है",
		"dangerMsg6": "कृपया पहले अपना बैंक कार्ड जोड़ें"
	},
	"withdrawalrecord": {
		"title": "निकासी रिकॉर्ड",
		"WatingOrder": "खाने का आदेश",
		"HistoryOrder": "इतिहास आदेश",
		"Rupee": "डॉलर",
		"Status": "स्थिति",
		"Time": "समय",
		"Nomore": "अधिक नहीं!"
	},
	"Version": {
		"title": "संस्करण",
		"stableversion": "स्थिर संस्करण",
		"Alreadythelatestversion": "पहले से ही नवीनतम संस्करण",
		"Newversionfound": "नया संस्करण मिला",
		"DownloadUpdate": "अद्यतन डाउनलोड करे"
	},
	"Bonus": {
		"title": "बोनस गतिविधि",
		"ReceiveBonus": "बोनस प्राप्त करें",
		"Hi": "नमस्ते",
		"tips1": "बोनस मिलने पर बधाई",
		"tips2": "आपको से अधिक या उसके बराबर रिचार्ज करने की आवश्यकता है",
		"tips3": "और फिर बोनस स्वचालित रूप से आपके खाते की शेष राशि में जुड़ जाएगा",
		"Received": "प्राप्त किया था",
		"Back": "वापस",
		"Rechargenow": "अभी रिचार्ज करें"
	},
	"ComplaintsSuggestions": {
		"title": "शिकायतों&सुझाव",
		"Answered": "उत्तर",
		"AddNew": "नया जोड़ो",
		"Time": "समय",
		"Type": "प्रकार",
		"WhatsApp": "WhatsApp",
		"Description": "विवरण",
		"Remark": "टिप्पणी",
		"Waiting": "इंतज़ार कर रही",
		"Suggestion": "सुझाव",
		"Consult": "परामर्श",
		"RechargeProblem": "रिचार्ज की समस्या",
		"WithdrawProblem": "समस्या वापस लेना",
		"OrderProblem": "आदेश समस्या",
		"Other": "अन्य",
		"Describeyourproblems": "अपनी समस्याओं का वर्णन करें",
		"WhatsApptocontactyou": "आपसे संपर्क करने के लिए व्हाट्सएप",
		"submit": "प्रस्तुत",
		"err1": "WhatsApp खाली नहीं हो सकता!",
		"err2": "प्रकार खाली नहीं हो सकता!",
		"err3": "विवरण खाली नहीं हो सकता!"
	},
	"slotMachine": {
		"title": "फल मशीन",
		"Myblance": "मेरा संतुलन",
		"Records": "फल मशीन अभिलेख",
		"titleRecord": "अभिलेख"
	},
	"javaCode": {
		"200": "सफलता",
		"20001": "अमान्य प्रवेश टोकन",
		"20002": "अमान्य लॉगिन टोकन",
		"20003": "फ़ोन त्रुटि",
		"20004": "फोन मौजूद है !",
		"20005": "ईमेल त्रुटि !",
		"20006": "ईमेल मौजूद है !",
		"20007": "पासवर्ड त्रुटि !",
		"20010": "खाता या पीडब्ल्यूडी त्रुटि !",
		"20011": "बैंक खाता त्रुटि !",
		"20012": "गलत बैंक आईएफएससी, (मानक आईएफएससी प्रारूप) - लंबाई 11, पहले चार आईएफएससी और 5वां 0!",
		"20013": "लाभार्थी का नाम त्रुटि, 5 <नाम <= 50!",
		"20021": "खाता मौजूद नहीं है!",
		"20022": "अकाउंट फ्रीज हो गया है!",
		"20031": "परत 10 . की सीमा है!",
		"20032": "अद्यतन संबंध भूमिका त्रुटि !",
		"20033": "पर्याप्त संतुलन नहीं !",
		"20034": "गलत निकासी राशि !",
		"30001": "सेवा अस्थायी रूप से बंद !",
		"30002": "सीमा शर्त पैसा १० और ५०००० के बीच है!",
		"30003": "वर्तमान अंक समाप्त हो गया है !",
		"30005": "आगमन की सूचना दिया",
		"31001": "एकल लेनदेन सीमा से अधिक है !",
		"31002": "आज की कुल राशि ऊपरी सीमा पर पहुंच गई है !",
		"31003": "आज की संख्या सीमा तक पहुंच गई है !",
		"31004": "वर्तमान स्टाफ अन कॉन्फिग, pls संपर्क व्यवस्थापक या एजेंट!",
		"200081": "रजिस्टर त्रुटि !",
		"200082": "खाता अक्षम हो गया है !",
		"200091": "कार्ड जोड़ें सीमा 10 . है !",
		"200092": "इनपुट त्रुटि लॉगिन pwd बहुत बार, और खाता अक्षम है !",
		"300041": "इश्यू की सभी लेन-देन राशि की सीमा है !",
		"300042": "उपयोगकर्ता की सभी लेन-देन राशि की सीमा है !",
		"300043": "बेट आइटम त्रुटि !",
		"300044": "आप इसे पहले ही खरीद चुके हैं और इसे दोबारा नहीं खरीद सकते !",
		"Servererror": "सर्वर त्रुटि!",
		"Handledataerror": "डेटा त्रुटि संभालें!",
		"-91": "सर्वर त्रुटि !",
		"-92": "डेटा त्रुटि संभालें !",
		"-1": "सिस्टम में गड़बड़ी!",
		"-2": "पैराम्स त्रुटि",
		"-3": "कोई डेटा उपलब्ध नहीं है",
		"-4": "कम समय में लगातार संचालन",
		"-5": "सिस्टम व्यस्त है, बाद में पुन: प्रयास करें!",
		"-6": "इस समय बहुत से लोग हैं, कृपया पुनः प्रयास करें !",
		"-7": "डेटा त्रुटि !",
		"-8": "गूगल कोड त्रुटि!",
		"-9": "व्यवस्था की जा रही है! कृपया बाद में पुन: प्रयास करें !",
		"-10": "त्रुटि सहेजें !",
		"-11": "त्रुटि हटाएं !",
		"-12": "अद्यतन त्रुटि !",
		"-13": "अवैध संचालन !",
		"-14": "ऑपरेशन विफल !",
		"-15": "ऑपरेशन अक्षम !",
		"-16": "ऑपरेशन समाप्त !",
		"-17": "त्रुटि मौजूद है",
		"-18": "मौजूद नहीं !",
		"-19": "समय सीमा समाप्त",
		"-20": "अक्षम कर दिया है",
		"-21": "त्रुटि सत्यापन कोड",
		"-22": "संशोधन अंतराल 1 दिन से अधिक होना चाहिए",
		"-23": "नया पासवर्ड मूल पासवर्ड के समान नहीं हो सकता",
		"-100": "कस्टम त्रुटि!",
		"-881": "व्यवस्था की जा रही है! कृपया बाद में पुन: प्रयास करें !",
		"-882": "राशि पैरामीटर त्रुटि",
		"-883": "चैनल पैराम्स त्रुटि",
		"-884": "चैनल का रखरखाव किया जाता है, कृपया बाद में पुन: प्रयास करें!",
		"-885": "चैनल का रखरखाव किया जाता है, कृपया बाद में पुन: प्रयास करें!",
		"-886": "वर्तमान रिचार्ज राशि न्यूनतम सीमा राशि से कम है!",
		"-887": "राशि पैरामीटर त्रुटि",
		"-888": "एक दिन में निकासी की अधिकतम संख्या",
		"-889": "अधिकतम निकासी राशि एक दिन से अधिक",
		"-8810": "पर्याप्त कोड राशि नहीं, कोड राशि की भी आवश्यकता है ",
		"-8811": "अधिकतम निकासी राशि को पार कर गया",
		"-8812": "न्यूनतम निकासी राशि से कम",
		"-8813": "वर्तमान समय निकासी के लिए सेवा नहीं है !"
	},
	"Management": {
		"TotalPurchaseAmount": "कुल खरीद राशि",
		"TotalRevenue": "कुल मुनाफा",
		"AccountBalance": "खाते में शेष",
		"Income": "आय",
		"Transaction": "लेनदेन",
		"Myhold": "मेरी पकड़",
		"ProductList": "उत्पादों की सूची",
		"Investmentperiod": "निवेश अवधि",
		"days": "दिन",
		"Buynow": "अभी खरीदें",
		"Saletime": "बिक्री का समय",
		"Nomore": "अब और नहीं",
		"ProductDetails": "उत्पाद विवरण",
		"ForSale": "बेचने के लिए",
		"Sale": "बिक्री",
		"SoldOut": "बिक गया",
		"ProductInformation": "उत्पाद की जानकारी",
		"InvestmentPeriod": "निवेश अवधि",
		"TotalShare": "कुल शेयर",
		"ExpectedReturn": "अपेक्षित आय",
		"SaleTime": "बिक्री का समय",
		"PurchaseConditionsAndRules": "खरीद की शर्तें और नियम",
		"MinimumPurchaseAmount": "न्यूनतम खरीद राशि",
		"MaximumSalesLimit": "अधिकतम बिक्री सीमा",
		"AccountBalanceMustBeGreaterThan": "खाता शेष राशि से अधिक होना चाहिए",
		"DayBetIsGreaterThan": "डे बेट इज़ ग्रेटर दैन",
		"ProductDescription": "उत्पाद वर्णन",
		"MyHold": "माई होल्ड",
		"Ordernumber": "क्रम संख्या",
		"PurchasingPrice": "खरीदारी मूल्य",
		"NoRecord": "कोई रिकॉर्ड नहीं",
		"Expiredate": "समाप्त होने की तिथि",
		"Purchasetime": "खरीदारी का समय",
		"redemption": "मोचन",
		"redemptionTips": "जल्दी छुटकारे से कोई आय नहीं होगी, क्या मोचन की पुष्टि की गई है?",
		"cancel": "रद्द करना",
		"determine": "ठानना",
		"Buyin": "खरीदना",
		"Buyamount": "राशि खरीदें",
		"BuyinTips": "जल्दी मोचन से कोई आय नहीं होगी ",
		"confirm": "पुष्टि करना",
		"Minimumbuy": "न्यूनतम खरीद",
		"Maturity": "परिपक्वता",
		"EarlyRedemption": "प्रारंभिक मोचन",
		"Redemptiontime": "मोचन समय",
		"Amountofincome": "आय की राशि",
		"prompt": "तत्पर"
	},
	"HelpHa": {
		"Help": "मदद",
		"HelpCenter": "सहायता केंद्र",
		"text": "कोई प्रश्न, यहां देखें"
	},
	"SetingHa": {
		"Seting": "सेटिंग",
		"SetingCenter": "सेटिंग केंद्र",
		"text": "अधिक पुरस्कार प्राप्त करें"
	},
	"stop_investing": "निवेश बन्द करें",
	"register.checkerr": "पासवर्ड: 8 या अधिक अक्षर इस्तेमाल करें (अक्षर और संख्या के संयोजन)",
	"home.Products&Services": "उत्पादन तथा सेवा",
	"home.Protection_Fund": "सुरक्षा फंड",
	"Ranking.Today_s winning ranking": "आज प्रोफाइट रेन्गिंग",
	"Ranking.tips1": "जितना बड़ा मात्रा जीता है, जितना उच्च रेजिंग है!",
	"Ranking.UserName": "उपयोक्ता नाम",
	"Ranking.Winning Amount": "जीते हुए मात्रा",
	"Ranking.Today_s rebate ranking": "आज रिबेट रेण्डिंग",
	"Ranking.tips2": "निम्न स्तर प्रयोक्ताओं को उच्च रिबेट्स \n उच्च रेण्डिंग प्राप्त करते हैं!",
	"Ranking.Rebate Amount": "मात्रा पुनः प्रारंभ करें",
	"Ranking.Rebate ranking": "शीर्ष 20 पुनरारंभ",
	"Activity.content0_1": "कोई वैध आमन्त्रणा पूरा करें",
	"Activity.content0_2": "एक सौ आमन्त्रणा के लिए अधिकतम बोनास है",
	"Activity.Daily_Tasks": "दिनांक कार्य",
	"mine.ProvacyPolicy": "निजी नीति",
	"Rechargepage.Tips": "प्रथम रिचार्ज प्रतिफल",
	"Rechargepage.Tips_1": "प्रथम चार्ज पुनरार्ज प्रतिदान पर आधारित है",
	"Rechargepage.Tips_2": "%",
	"withdrawal.Google_Code": "गूगल परीक्षण कोड",
	"bankrecharge.Orderamount": "क्रम मात्रा",
	"bankrecharge.Ordernumber": "क्रम संख्या",
	"bankrecharge.BankName": "बैंक नाम",
	"bankrecharge.BankAccount": "बैंक खाता",
	"bankrecharge.IFSC": "IFSC एनकोडिंग",
	"bankrecharge.Yourbankcardnumber": "आपका बैंक कार्ड क्रमांक",
	"bankrecharge.Enterthebanktransactionordernumber": "बैंक ट्रांसेक्शन क्रम संख्या भरें",
	"bankrecharge.submit": "भेजें",
	"bankrecharge.Tips": "प्रश्न",
	"bankrecharge.tips1": "1. तंत्र वर्तमान में आपका क्रम प्रक्रिया कर रहा है.",
	"bankrecharge.tips2": "यदि खाता बहुत समय तक प्राप्त नहीं है, कृपया ग्राहक सेवा से संपर्क करें.",
	"bankrecharge.err1": "कृपया अपना बैंक कार्ड क्रमांक भरें",
	"bankrecharge.err2": "कृपया बैंक क्रम क्रम संख्या भरें",
	"coinjs.tips1": "कृपया नेटवर्क को +इस पर स्विच करें. mNetWorkConfig. name+and try again",
	"coinjs.tips1_1": "कृपया नेटवर्क को स्विच करें",
	"coinjs.tips1_2": "और फिर कोशिश करें",
	"coinjs.trxErr": "आपके पास पर्याप्त TRX नहीं है तुम्हें माइनर फीज़ देने के लिए 10 ट्रेक्स की जरूरत है",
	"coinDefi.staking": "अब रोक रहा है",
	"coinDefi.All_Chains": "सभी चाइनKCharselect unicode block name",
	"coinDefi.Annual_Percentage_Yield": "सालिक प्रतिशत उत्पन्न",
	"coinDefi.Daily_Yield_Rate": "दिन रेट",
	"coinDefi.Mining_Period": "डीफ़ाई परिवार",
	"coinDefi.Flexible": "फ्लैक्सिबल",
	"coinDefi.Wallet_Balance": "वालेट बैलेंस",
	"coinDefi.Daily_Yield": "रोज़ी उत्पन्न",
	"coinDefi.DeFi_Mining": "अब डीफ़ाई मिनिंग में जोड़ें",
	"coinDefi.Disconnect": "डिस्कनेक्ट करें",
	"coinDefi.Copy_Link": "लिंक कॉपी करें",
	"coinDefi.text1": "मोबाइल ब्राउजर समर्थित नहीं है",
	"coinDefi.text2": "TRON वालेट से जोड़ने के लिए मोबाइल ब्राउज़र समर्थन नहीं करता",
	"coinDefi.text3": "कृपया इसको <strong>मोबाइल वालेट ब्राउज़र < /strong>में खोलें जो TRON (TRX) संख्या को समर्थन करता है और निश्चित करें कि मौजूदा संख्या TRON (TR जहिना",
	"coinDefi.text3_1": "कृपया इसे खोलें",
	"coinDefi.text3_2": "मोबाइल वालेट ब्राउजर",
	"coinDefi.text3_3": "जो TRON (TRX) संख्या को समर्थित करता है और निश्चित करो कि मौजूदा संख्या TRON (TRX) है. जहिना",
	"coinDefi.text3_4": "ETC",
	"coinDefi.tips1": "डीफ़ाई मिनिंग दोहरा मत करें",
	"coinDefi.tips2": "असफल, कृपया बाद फिर कोशिश करें",
	"coinDefi.tips3": "वालेट प्लगइन पता नहीं किया गया है, कृपया TronLink वालेट को संस्थापित करें",
	"coinDefi.tips4": "कृपया पहिले TronLink वालेट में लॉग इन करें",
	"index.index.gayb21": "बदला प्राप्त करने के लिए रेजिस्टर करें:",
	"index.index.72y628": "EPOCH EPT चुने के लिए तीन कारण",
	"index.index.z6022y": "गोगोबिट उद्योग में सबसे उच्च सुरक्षा मानकों में से एक का अनुसरण करता है। हम अपने क्रिप्टोवेन्सी के 100% को फ्रिज़्डेड भण्डार में रखेंगे. हम कम से कम 1:1 रेज़रेशन अनुपात को ग्राहक फंड के लिए देने के लिए गारंट कर रहे हैं. हमारे 300 मिलियन फंड से सुरक्षा के विरुद्ध अधिक सुरक्षा प्रदान करते हैं.",
	"index.index.574982": "1. अब EPOCH EPT के लिए रिजिस्टर करें",
	"index.index.qdi1g4": "2. पूरा गूगल परीक्षण",
	"index.index.weko28": "3. तुरंत अपने गोगोबिट खाता फिर चार्ज करें",
	"index.index.sgkb35": "गोगोबिट निमंत्रण प्रोग्रामName",
	"index.index.2u17nf": "किसी वैध आमन्त्रणा को पूर्ण करने के लिए 5. 59 यूएसडी तक एक औसत मूल्य प्राप्त कर सकता है एक सौ आमन्त्रणा के लिए अधिकतम बोनास है",
	"index.index.e8is4a": "EPOCH EPT सिफारिसेंडेशन प्रोग्रामName",
	"index.index.47uevl": "गोगोबिट ट्रांसेक्शन कार्य",
	"index.index.2516gq": "गोगोबिट अमेरिका, इंक्यू. न्यूयॉर्क राज्य वित्तीय सेवा विभाग से आभासी मुद्रा व्यवसाय क्रिया लाइस",
	"invest.index.3aqi3d": "खाता बैलेंस:",
	"invest.index.ubk1j4": "निवेश बन्द करो!",
	"invest.prize1.4733u6": "निवेश पोर्टफोलियो:",
	"invest.prize1.t55a16": "कुल मात्रा:",
	"login.login.09m52q": "मैंने गोगोबिट के साथ पढ़ा और सहमत किया है",
	"login.login.045csy": "उपयोक्ता नाम",
	"login.login.r7rbm5": "कृपया मौजूदा के साथ सहमत है!",
	"rewards.activityb.4nvrq9": "जब आमन्त्रणा अपने पहले डेस्पोज़िट को पूरा करेगा, तो यह वैध आमन्त्रण माना जाता है. प्रत्येक वैध आमन्त्रण के लिए आमन्त्रण बोनास प्राप्त होगा. निमन्त्रण बोनास स्तर 2 USD 1 वैध निमंत्रण से आता है, 559 USD 100 वैध निमंत्रण.",
	"rewards.activityb.t10k6e": "प्रत्येक LV. 1 आमन्त्रणा के लिए प्रत्येक ट्रांसेक्शन फीज के लिए अनुमंत्रण के अनुमंत्रण के 20% साझेदार होगा. आमन्त्रकर्ता प्रत्येक LV. 2 आमन्त्रकर्ता के ट्रांसेक्शन फीज के लिए ट्रांसेक्शन का कमिशिशन के 10% साझा करेगा. अनुमंत्रण के लिए अधिकतम बोनास निर्देशित अमेरिका डॉलर है.",
	"rewards.activityb.3d6850": "Name",
	"rewards.activityb.lvxo74": "Name",
	"rewards.activityb.zj26f9": "एलवी.1 आमन्त्रणाओं की संख्या",
	"rewards.activityb.dkgxr5": "LV.2 सम्मिलित आमन्त्रणाओं की संख्या",
	"rewards.activityb.xjo077": "सिफ़ारिश बदला:",
	"rewards.activityb.k8wv6v": "गोगोबिट में जोड़ने और अपनी सिफारिस की लिंक प्राप्त करें. इस लिंक के द्वारा रेजिस्टरिज किया गया उपयोक्ता बदला प्राप्त होगा.",
	"rewards.activityb.4slpzg": "अपने दोस्त के पहले डोपोसिट को पूरा कर दे और आवश्यक ट्रांसेक्शन वोल्यूम को पूरा कर दे, तुम्हें 5-15 डॉलर के औसत मूल्य के सा अधिकतम बदला 559 डॉलर तक पहुँच सकता है.",
	"rewards.index.993jwg": "सफलतापूर्वक नक़ल किया गया",
	"rewards.index.7141ft": "यह ब्राउजर स्वचालित प्रतिलिपि समर्थन नहीं करता",
	"wallet.index.9uz2gn": "नेटवर्क हैंडलिंग फीड 0 USDT",
	"wallet.index.ych3e1": "वास्तविक रिसीप्ट:",
	"wallet.index.3v558d": "याद रीमाइन्डर: यदि प्रतिक्रिया डॉलर 20 से कम है, तो प्रतिक्रिया हैंडलिंग फीड डॉलर 1 है. यदि प्रतिक्रिया डॉलर 20 से अधिक है, तो प्रतिक्रिया हैं",
	"wallet.index.255e30": "1. अधिकतम रोज़ी प्रतिलिपि प्रतिलिपि मात्रा $8000000 है",
	"wallet.index.3g68m8": "2. न्यूनतम एकल घटना मात्रा 5 डॉलर है, और अधिकतम एकल घटना मात्रा 8000000 डॉलर है",
	"wallet.index.c6x3ti": "3. घटाने का समय 00: 00 से 23: 59 तक है, कृपया निर्दिष्ट समय के अंतर्गत घटाना बनाएँ",
	"wallet.index.h94rlg": "न्यूनतम सीमा 5",
	"wallet.index.167862": "कृपया वालेट पता भरें!",
	"wallet.index.pi869h": "कृपया रिट्रोकल मात्रा भरें!",
	"wallet.index.ysa85i": "अधिकतम एकल घटना मात्रा है",
	"components.changepass.68j9kh": "आप अपने पासवर्ड फिर सेट करने की कोशिश कर रहे हैं.",
	"components.changepass.ouxtp2": "मौलिक पासवर्ड",
	"components.languagebox.8no849": "जापानीName",
	"components.languagebox.oif20b": "पारंपरिक चीनीName",
	"components.mycode.xyqr66": "कार्य पूरा करने के लिए 5000 USDT तक प्राप्त कर सकते हैं",
	"components.mymenu.277ss7": "उपयोक्ता आईडी:",
	"components.mymenu.74l443": "रेजिस्टरेशन समय:",
	"components.myposter.36rw5e": "सिफारिसिंग कोड:",
	"components.mytabbar.zw2bh2": "घर पृष्ठ",
	"components.mytabbar.8x6c28": "ट्रेंड",
	"components.mytabbar.28s7qr": "निवेश",
	"components.mytabbar.4r75d9": "बदला",
	"components.xieyi.zqk7pe": "फ़ॉन्ट आकार: 16px; फ़ॉन्ट परिवार: ताहोमा;",
	"components.help.6gpud8": "दो निवेश मॉडल हैं:",
	"components.help.5qrq36": "प्रत्येक अवधि १ मिनट तक रहेगा और सिर्फ एकल या दुबुल निवेश की उपलब्ध करता है। परिणाम बाइन्स बीटीस/USDT स्पोट ट्रेडिंग के बंद करने की 1-मिनिट के-लाइन के अन्तिम अंक के द्वारा गणना है.",
	"components.help.isl7r1": "हर सत्र 5 मिनट तक रहेगा. व्यवसायिक संस्करण गोगोबिट के सभी निवेश पद्धतियों को खोल देता है। परिणाम बाइन्स बीटीस/USDT स्पॉट ट्रेडिंग के बंद करने की 5-मिनिट के-लाइन के अन्तिम अंक के द्वारा गणना है.",
	"components.help.eai3nm": "निवेश वापस:",
	"components.help.35xg82": "एकल संख्या:",
	"components.help.mpfg0s": "अंत: निवेश मात्रा X 9. 4",
	"components.help.v2ziib": "अज्ञात संख्या:",
	"components.help.vfzxor": "अंत: निवेश मात्रा X 1. 94",
	"components.help.73168b": "घर संख्या:",
	"components.help.76wgv1": "बड़ी संख्या:",
	"components.help.05ed33": "छोटी संख्या:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "कृपया मूल पासवर्ड भरें!",
	"components.changepass.xme6my": "कृपया एक नया पासवर्ड भरें!",
	"components.changepass.u434f8": "The two passwords do not match!",
	"components.changepass.574790": "सफलतापूर्वक परिवर्धित!",
	"invest.index.9y8wg0": "प्रतिज्ञा नहीं जीता",
	"invest.index.ql5nc1": "Winning awards",
	"login.login.76451t": "मूल्य भिन्न एक मूल्यात्मक व्यापार!",
	"login.login.915p36": "Financial management ranks first globally",
	"login.login.sen863": "हेलो लगइन के बाद ज्यादा उत्साहक है",
	"login.login.nz6q9x": "फोन लॉगइन",
	"login.login.w7p592": "इमेल लागइन",
	"login.login.g9y91k": "Mobile phone number",
	"login.login.456gbb": "इमेल संख्या",
	"login.login.5ly8m8": "देश",
	"login.login.69h472": "Please select a country",
	"login.login.i4p378": "संख्या",
	"login.login.fl82xx": "उदाहरण:",
	"wallet.index.urkoub": "QR कोड फिर चार्ज करें",
	"wallet.index.25cocl": "पता कॉपी करें",
	"wallet.index.t35fvg": "1. कृपया सोमवार से शनिवार से 24 घंटे में क्षेत्रीय करें!",
	"wallet.index.68xx61": "2. वैश्विक घरानी मुद्रा निकालने के लिए अन्तिम 48 घंटे में प्राप्त होना चाहिए, जिसके साथ 8% (क्षेत्रीय टैक्स के सम",
	"wallet.index.4ws3w9": "3. यूएसडीटी निकालने के लिए खाता को अन्तिम 24 घंटे के बाद दिया जाना चाहिए, 2% हैंडलिंग फी",
	"wallet.index.9b7wq8": "4. न्यूनतम घटाने की मात्रा 12 USDT है, और अधिकतम एकल घटाने की मात्रा 3000 USDT है.",
	"wallet.index.23y92a": "कृपया जानते हो कि ",
	"wallet.index.pl5473": "5 की न्यूनतम सीमा",
	"wallet.index.nb3u72": "बैंक कार्ड जानकारी",
	"wallet.index.842so4": "बैंक कार्ड क्रमांक जोड़ें",
	"wallet.index.86j717": "संबंधित बैंक जोड़ें",
	"wallet.index.763d7x": "बैंक कार्ड नाम जोड़ें",
	"wallet.index.50j237": "अफिलिटेड बैंक",
	"wallet.index.hb6lun": "बैंक",
	"wallet.index.62y2jc": "बैंक कार्ड क्रमांक",
	"login.register.666hp4": "अल क्रीस फरक के मात्रिक व्यापार",
	"login.register.9298b8": "मित्रों को आमन्त्रित करें",
	"login.register.375g14": "पहली बार से जोड़े हुए नया आयोजक",
	"login.register.01922f": "एक बार कार्य किया जा सकता है",
	"login.register.527o4q": "दिनीय रासायनिक आगत AI",
	"login.register.c563gt": "इमेल रिजिस्ट्रेशन",
	"login.register.2ls856": "फोन संख्या फ़ॉर्मेट त्रुटि!",
	"login.register.4nu77e": "इमेल संख्या फ़ॉर्मेट त्रुटि!",
	"lang.zh.pu623z": "1 वैध आमन्त्रणा",
	"lang.zh.3hv80b": "USDT पुनरार्ज!",
	"lang.zh.9tli5f": "टिप्प: नीचे हटाएँ",
	"lang.zh.62e882": "यूएसडी, प्रतिलिपि फीड है",
	"lang.zh.m3818q": "यूएसडी, प्रतिक्रिया",
	"lang.zh.xs2f10": "प्रारंभिक विमोचन को कोई परिणाम नहीं बनाएगा"
}