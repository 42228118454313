<template>
  <div class="bg">
      <div @click="changeOn" :class="isOff?'isOff':'isOn'"></div>
      <audio id="audio" ref="audio" src="https://www.nftbzb.top/BGM-8f7f6fa1.mp3" :autoplay=!isOff  preload="auto" ></audio>
  </div>
</template>
<script>
export default {
  data() {
    return {
        isOff:true
    }
  },
  components: {},
  created(){
     
     
  },
  mounted() {
     this.changeOn();
      // 自动播放音乐效果，解决微信自动播放问题
    //  document.addEventListener('touchstart',this.audioAutoPlay,false);
    //  document.addEventListener('WeixinJSBridgeReady', this.audioAutoPlay,false);
     let oAudio = document.querySelector("#audio");
    //  oAudio.play();
      oAudio.onended = function () {//播放完毕，重新循环播放
          oAudio.load();
          oAudio.play();
      }
  },
  methods: { 
         changeOn(){
              let oAudio = document.querySelector("#audio");
             if(this.isOff){
              oAudio.play();//开始播放     
             }else{
              oAudio.pause();//暂停播放 
             }
             this.isOff = !this.isOff;
         },
         audioAutoPlay() {
              let audio = document.getElementById('audio');
                  this.isOff = false;
                  audio.play();
              document.removeEventListener('touchstart',this.audioAutoPlay);
          }
         
  }
}
</script>

<style scoped>

.isOn{
  width: 1.3rem;
  height: 1.3rem;
  position: fixed;
  z-index: 2000;
  bottom: 45%;
  right: 1.4rem;
   -webkit-animation: rotating 1.2s linear infinite;
  animation: rotating 1.2s linear infinite;
  background:#ff544f url("../assets/img/ic_sound_on.png")  no-repeat;
  border-radius: 50%;
  padding: 1rem;
  background-size:100%; 
}
@keyframes rotating {
  from { -webkit-transform: rotate(0) }
  to { -webkit-transform: rotate(360deg) }
}
@-webkit-keyframes rotating {
  from { -webkit-transform: rotate(0) }
  to { -webkit-transform: rotate(360deg) }
}
.isOff{
  width: 1.3rem;
  height: 1.3rem;
  position: fixed;
  z-index: 2000;
  bottom: 45%;
  right: 1.4rem;
  padding: 1rem;
  border-radius: 50%;
  background:#ff544f url("../assets/img/ic_sound_off.png") no-repeat;
  background-size:100%; 
}
</style>