import i18n from '../lang/index.js';
// 配置编译环境和线上环境之间的切换
let baseUrl = process.env.VUE_APP_BASEURL;
let imgUrl = process.env.VUE_APP_IMGURL;
const env = process.env
// console.log(env.NODE_ENV);
if (env.NODE_ENV == "development") {
    baseUrl = "https://www.ctrate.xyz";
//   baseUrl = "http://local.gobit";
    // baseUrl = "https://api.nftbzb.top"
}else{
    baseUrl = "https://www.ctrate.xyz"
}

export {
    baseUrl,
    imgUrl,
    env
}