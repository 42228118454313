module.exports = {
	"Language": {
		"title": "Langue"
	},
	"Noproductsforsale": "Aucun produit en vente",
	"rechargePresentationText0_1": "Rechargez 1000 et recevez une récompense de ",
	"rechargePresentationText0_2": ". Rechargez 10000 et recevez une récompense de ",
	"rechargePresentationText0_3": " et ainsi de suite... Cliquez ici !",
	"rechargePresentationText0": "Vous recevrez ",
	"rechargePresentationText1": "% du montant de la recharge dès que vous effectuez une recharge maintenant. L'activité est limitée dans le temps et s'arrêtera après l'épuisement des cadeaux.",
	"rechargePresentationText2": "Fin de la récompense, compte à rebours !",
	"Online_Chat": "Chat en ligne",
	"gobindgoogle": "Veuillez lier votre application Google Authenticator",
	"Order": "Commander",
	"Record": "Historique",
	"Assist": "Assistance",
	"Market": "Marché",
	"History": "Histoire",
	"Confirm": "Confirmer",
	"close": "Fermer",
	"Cancel": "Annuler",
	"Previous": "Précédent",
	"Next": "Suivant",
	"Copy": "Copier",
	"Nomoredata": "Pas de données",
	"moneychar": "₹",
	"DownloadApp": " Téléchargez l'application maintenant",
	"Yes": "Oui",
	"No": "Non",
	"Wating": "En attente",
	"Recharge": "Dépôt",
	"Withdrawal": "Retrait",
	"Success": "Succès",
	"stop_investing": "Arrêter l'investissement",
	"OrderSuccessful": "Commande réussie",
	"Handledataerror": "Erreur de traitement des données",
	"Copysuccess": "Copie réussie",
	"MyBalanceLoding": "Chargement de mon solde",
	"UPDATE": "MISE À JOUR",
	"New_version_available": "Nouvelle version disponible",
	"Please,update_app_to_new_version": "Veuillez mettre à jour l'application vers la nouvelle version",
	"tabbar": {
		"Home": "Accueil",
		"AndarBahar": "Andar Bahar",
		"Invest": "Investir",
		"Bonus": "Bonus",
		"Mine": "Portefeuille",
		"Trending": "Tendances",
		"Wallet": "Portefeuille",
		"Rewards": "Récompenses"
	},
	"homeTop": {
		"Sign_Up": "Inscription",
		"Sign_up_for_rewards": "Inscrivez-vous pour les récompenses",
		"up_to": "jusqu'à",
		"TOTAL_ASSETS": "ACTIFS TOTAUX",
		"User_ID": "ID utilisateur",
		"Registration_time": "Date d'inscription"
	},
	"PieChart": {
		"BIG&SMALL": "GRAND ET PETIT",
		"NUMBER": "NOMBRE",
		"ODD&EVEN": "IMPAIR ET PAIR",
		"ALL_NUMBER_TRENDING": "TENDANCE DE TOUS LES NOMBRES",
		"BIG&SMALL_NUMBER_TRENDING": "TENDANCE DES NOMBRES GRANDS ET PETITS",
		"ODD&EVEN_NUMBER_TRENDING": "TENDANCE DES NOMBRES IMPAIRS ET PAIRS"
	},
	"register": {
		"checkerr": "Mot de passe : utilisez au moins 8 caractères (une combinaison de lettres et de chiffres)",
		"tips_3": "Si vous n'avez pas reçu le code dans votre boîte de réception, veuillez vérifier votre dossier de spam.",
		"receiveCode": "Vous n'avez pas reçu le code?",
		"receiveCode_title": "Code envoyé à votre e-mail. Si vous n'avez pas reçu le code après plusieurs tentatives, veuillez essayer:",
		"receiveCode_text1": "1. Vérifiez si le message se trouve dans vos courriers indésirables.",
		"receiveCode_text2": "2. Assurez-vous que votre adresse e-mail est",
		"receiveCode_text3": "3. Le message peut être retardé de quelques minutes. Réessayez après 20 minutes.",
		"receiveCode_text4": "4. Si cette adresse e-mail existe déjà, nous ne vous enverrons pas de code d'authentification. Veuillez vous connecter ou réinitialiser votre mot de passe.",
		"Sign_up_for_Rewards": "Inscrivez-vous pour obtenir des récompenses",
		"Email_Verification": "Vérification de l'e-mail",
		"tips": "Veuillez saisir le code de vérification à 6 chiffres qui a été envoyé à {{formData.email}}. Le code est valable pendant 30 minutes.",
		"tips_1": "Veuillez saisir le code de vérification à 6 chiffres qui a été envoyé à ",
		"tips_2": "Le code est valable pendant 30 minutes.",
		"Resend_code_in": "Renvoyer le code dans",
		"Sent_again": "Envoyé à nouveau",
		"CREATE_ACCOUNT": "CRÉER UN COMPTE",
		"inviteCodecheck": "Le code d'invitation ne peut pas être vide !",
		"Mobile": "Numéro de téléphone portable",
		"Mobilemsg": "Le numéro de portable est obligatoire",
		"Password": "Mot de passe",
		"Passwordmsg": "Le mot de passe est obligatoire",
		"OTPCode": "Code OTP",
		"OTPCodemsg": "Le code OTP est obligatoire",
		"SendOTP": "Envoyer OTP",
		"Email": "E-mail",
		"Emailmsg": "L'e-mail est obligatoire",
		"Invitecode": "Code de référence (facultatif)",
		"Invitecodemsg": "Le code de référence est obligatoire",
		"emailFormatter": "Veuillez saisir l'adresse e-mail correcte",
		"usernameFormatter": "Le nom d'utilisateur doit être un caractère et un nombre et la plage est de 6 <= longueur <= 20",
		"phoneFormatter": "Veuillez remplir le numéro de téléphone portable à 10 chiffres",
		"msgsuccess": "inscrit avec succès",
		"msgerr": "échec de l'inscription",
		"errorphonecheck": "Le numéro de téléphone portable ne peut pas être vide!",
		"phonecheck": "Veuillez remplir le numéro de téléphone portable à 10 chiffres!",
		"imgcodecheck": "Le code OTP ne peut pas être vide!"
	},
	"login": {
		"Welcome_back": "Bienvenue de retour",
		"tips": "J'ai lu et j'accepte les conditions d'utilisation et la politique de confidentialité de {{this.$LogoName}}",
		"tips_1": "J'ai lu et j'accepte les",
		"tips_2": "conditions d'utilisation",
		"tips_3": "et la",
		"tips_4": "politique de confidentialité",
		"Continue_with_Google": "Continuer avec Google",
		"Continue_with_Apple": "Continuer avec Apple",
		"text": "Vous n'avez pas encore de compte?",
		"text2": "Vous avez déjà un compte?",
		"Login": "Connexion",
		"Register": "S'inscrire",
		"username": "Nom d'utilisateur ou numéro de téléphone",
		"password": "Mot de passe",
		"imgcode": "Code image",
		"BackHome": "Retour à la page d'accueil",
		"Forgetpwd": "Mot de passe oublié"
	},
	"forgot": {
		"title": "Mot de passe oublié",
		"msgsuccess": "Réinitialisation du mot de passe terminée",
		"newpassword": "Nouveau mot de passe",
		"newpasswordmsg": "Le nouveau mot de passe est requis",
		"Continue": "Continuer"
	},
	"home": {
		"Products&Services": "Produits & Services",
		"content11": "Les parrains peuvent obtenir une prime proportionnelle au volume de transactions généré par leurs filleuls. La prime maximale pour une invitation valide est de Unlimit USDT.",
		"Revolutionize_Trading": "Révolutionner le trading",
		"Shape_the_Future": "Façonner l'avenir",
		"content0": "Expérimentons ensemble la nouvelle ère de l'échange de crypto-monnaie !",
		"3_Reasons_for_Choosing": "3 raisons de choisir",
		"Innovative_Trading_Features": "Fonctionnalités de trading innovantes",
		"content1": "Une expérience de trading révolutionnaire qui est différente de tout ce qui est actuellement disponible sur le marché. Conçue pour vous donner un avantage compétitif grâce à des fonctionnalités de trading innovantes, des mesures de sécurité avancées et des vitesses de transaction ultra-rapides.",
		"Lower_Fees_and_Better_Rewards": "Frais plus bas et meilleures récompenses",
		"content2": "Notre interface rend le trading simple pour les débutants. Vous pouvez créer un compte gratuitement, échanger des crypto-monnaies avec des frais de transaction réduits sur notre marché. De plus, vous pouvez partager jusqu'à 20 % des frais de transaction de l'échange pour tous les utilisateurs que vous invitez. ",
		"Institutional-grade_Security": "Sécurité de niveau institutionnel",
		"content3": "suit l'une des normes de sécurité les plus élevées de l'industrie. Nous stockons également 100 % de votre crypto-monnaie dans un stockage à froid. Nous garantissons un ratio de réserve d'au moins 1:1 des fonds de nos clients. Notre fonds de 300 millions de USDT offre une couche supplémentaire de protection contre les menaces de sécurité.",
		"Start_investing_in_as_little_as_5_minutes": "Commencez à investir en aussi peu que 5 minutes",
		"content4": "Inscrivez-vous gratuitement sur {{this.$LogoNames.toUpperCase()}}",
		"content4_1": "Inscrivez-vous gratuitement sur",
		"content4_2": " ",
		"Complete_google_authentication": "Compléter l'authentification Google",
		"content5": "Entrez et vérifiez votre adresse e-mail pour commencer. Il n'y a pas de frais pour l'ouverture d'un compte.",
		"content7": "Déverrouillez toutes les fonctionnalités en vérifiant votre authentificateur Google en moins de 5 minutes.",
		"content8": "Approvisionnez instantanément votre compte {{this.$LogoNames.toUpperCase()}}",
		"content8_1": "Approvisionnez instantanément votre compte",
		"content8_2": " ",
		"content9": "Déposez des USDT via un réseau sélectionné et accédez instantanément à vos fonds. Pas besoin d'attendre 3 à 5 jours pour commencer à trader.",
		"Start_building_your_crypto_portfolio": "Commencez à construire votre portefeuille de crypto-monnaie",
		"Invitation_Program": "Programme d'invitation",
		"Referral_Program": "Programme de référence",
		"content10": "Complétez une invitation valide et recevez une prime d'invitation d'une valeur moyenne de 10 USDT. La prime maximale pour une personne est de",
		"TRADING_TASKS": "TÂCHES DE TRADING",
		"content12": "Les utilisateurs recevront des récompenses en USDT lorsqu'ils effectueront des échanges, diverses tâches de trading pour différents modes de trading. Les récompenses seront distribuées instantanément.",
		"Trade_anytime_anywhere": "Tradez à tout moment, n'importe où",
		"content13": "USA, Inc. est autorisée à exercer des activités commerciales de monnaie virtuelle par le département des services financiers de l'État de New York.",
		"USA_Inc": "USA, Inc",
		"content6": "Téléchargez notre application pour rester connecté 24h/24 et 7j/7",
		"Legal&Disclosures": "Mentions légales et divulgations",
		"Regulatory_License": "Licence réglementaire",
		"AML/KYC_Policies": "Politiques AML/KYC",
		"Privacy_Policy": "Politique de confidentialité",
		"Terms_of_Use": "Conditions d'utilisation",
		"Legal_Statement": "Déclaration légale",
		"Risk_Disclosure": "Divulgation des risques",
		"About_Us": "À propos de nous",
		"Protection_Fund": "Fonds de protection",
		"Proof_of_Reserves": "Preuve de réserves",
		"Twitter": "Twitter",
		"Bitget_Token": "Jetons Bitget",
		"Careers": "Carrières",
		"Company": "Entreprise",
		"Support_Service": "Support",
		"title": "Ouvrir avec une application",
		"titlebtn": "Télécharger",
		"Balance": "Solde",
		"ID": "Identifiant",
		"Recharge": "Dépôt",
		"Withdraw": "Retrait",
		"Bonus": "Bonus",
		"Checkin": "Enregistrement",
		"Eventclosed": "Événement terminé",
		"Ranking": "Classement"
	},
	"Ranking": {
		"Today_s winning ranking": "Classement des gains d'aujourd'hui",
		"tips1": "Plus le montant des gains est élevé, plus le classement est élevé !",
		"UserName": "Nom d'utilisateur",
		"Winning Amount": "Montant des gains",
		"Today_s rebate ranking": "Classement des rabais d'aujourd'hui",
		"tips2": "Des rabais plus élevés pour les utilisateurs de niveau inférieur = un classement plus élevé !",
		"Rebate Amount": "Montant de remise",
		"Rebate ranking": "Classement des 20 meilleures remises"
	},
	"Checkin": {
		"Checkin": "Enregistrement",
		"CheckinList": "Liste d'enregistrement"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Règle",
		"Record": "Enregistrement",
		"Records": "Enregistrements Andar Bahar",
		"MyOrder": "Ma commande",
		"more": "plus",
		"Period": "Période",
		"Order": "Commander",
		"Buy": "Acheter",
		"Result": "Résultat",
		"Profit": "Profit",
		"AndarBaharRule": "Règle Andar Bahar",
		"CountDown": "Compte à rebours",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "résultat",
		"ok": "ok",
		"start": "démarrer",
		"Point": "Point",
		"ContractPoint": "Point de contrat",
		"Number": "Numéro",
		"Delivery": "Livraison",
		"Fee": "Frais",
		"Amount": "Montant"
	},
	"pokerOrder": {
		"OrderRecord": "Enregistrement de commande",
		"WatingOrder": "Commande en attente",
		"HistoryOrder": "Historique des commandes",
		"Nomoredata": "Pas plus de données",
		"Buy": "Acheter",
		"Result": "Résultat",
		"Time": "Temps",
		"Amount": "Montant",
		"Fee": "Frais",
		"Profit": "Profit"
	},
	"pokerRecord": {
		"title": "Enregistrement Andar Bahar",
		"Period": "Période",
		"Result": "Résultat",
		"OpenTime": "Heure d'ouverture",
		"Nomore": "Plus de données"
	},
	"Google2FA": {
		"title": "Google Authenticator",
		"Authenticator_Instructions": "Instructions pour Google Authenticator",
		"Authenticator_Status": "État de Google Authenticator",
		"Google_Authenticator": "Google Authenticator",
		"Download_Google_Authenticator_App": "Télécharger l'application Google Authenticator",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "Suivant",
		"Backup_key": "Clé de sauvegarde",
		"Backup_key_text": "Veuillez enregistrer cette clé de sauvegarde dans un endroit sûr. Cette clé vous permettra de récupérer votre Authenticator en cas de perte de votre téléphone. Sinon, la réinitialisation de votre Authenticator devra être consultée auprès du service client.",
		"Verifiaction": "Vérification",
		"Verifiaction_text": "Entrez le code de vérification Google",
		"Google_verification_code": "Code de vérification Google",
		"contentlist1": "1. Notez la clé de sauvegarde de 16 chiffres de +this.$LogoName+ et conservez-la dans un endroit sûr.",
		"contentlist1_1": "1. Notez la clé de sauvegarde de 16 chiffres de",
		"contentlist1_2": "et conservez-la dans un endroit sûr.",
		"contentlist2": "2. Ouvrez Google Authenticator.",
		"contentlist3": "3. Ajoutez un compte +this.$LogoName+ et entrez la clé de 16 chiffres copiée.",
		"contentlist3_1": "3. Ajoutez un compte",
		"contentlist3_2": "et entrez la clé de 16 chiffres copiée.",
		"contentlist4": "4. Vous verrez un code de vérification à 6 chiffres à l'écran.",
		"contentlist5": "5. Copiez le code à 6 chiffres et collez-le dans +this.$LogoName+ .",
		"contentlist5_1": "5. Copiez le code à 6 chiffres et collez-le dans",
		"contentlist5_2": ".",
		"contentlist6": "6. Votre Authenticator sera alors lié avec succès."
	},
	"win": {
		"Live_Price": "Cours en direct",
		"title1": "Les résultats de l'investissement sont calculés par le dernier chiffre de l'échange Spot Binance BTC/USDT correspondant à l'heure UTC. Les investisseurs peuvent vérifier les résultats sur Binance à tout moment pour garantir l'équité et la sécurité de l'investissement.",
		"title": "Il y a 2 modes d'investissement",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Chaque tour dure 1 minute. Le mode Lite ne fournit que l'investissement en nombre pair et impair. Le résultat est calculé par le dernier chiffre de l'échange Spot Binance BTC/USDT de l'intervalle de 1 minute.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Chaque tour dure 5 minutes. Le mode Pro débloque la méthode d'investissement complète de EPOCH EPT. Le résultat est calculé par le dernier chiffre de l'échange Spot Binance BTC/USDT de l'intervalle de 5 minutes.",
		"5M_Mode_text_1": "Chaque tour dure 5 minutes. Le mode Pro débloque la méthode d'investissement complète de ",
		"5M_Mode_text_2": ". Le résultat est calculé par le dernier chiffre de l'échange Spot Binance BTC/USDT de l'intervalle de 5 minutes.",
		"Investment_Guide": "Guide d'investissement",
		"Investment_Rules": "Règles d'investissement",
		"title2": "Comment prouver que le résultat est équitable ?",
		"Investment_Info": "Informations sur l'investissement",
		"3M_Mode": "Mode 3M",
		"3M_Mode_text": "Chaque tour dure 3 minutes. Le résultat est calculé par le dernier chiffre de l'échange Spot Binance BTC/USDT de l'intervalle de 3 minutes.",
		"Investment_return": "Retour sur investissement",
		"Number": "Nombre",
		"Profit_Invested_amount": "Profit : Montant investi",
		"guide1": "Choisissez un mode de temps.",
		"guide2": "Ajustez le montant de votre investissement.",
		"guide3": "Choisissez les nombres dans lesquels vous souhaitez investir.",
		"guide4": "Vérifiez votre portefeuille et cliquez sur Investir.",
		"NEXT": "SUIVANT",
		"Invest_Amount": "Montant investi",
		"Total": "Total",
		"INVEST": "INVESTIR",
		"Portfolio": "Portefeuille",
		"Invest": "Investir",
		"BINANCE": "Binance",
		"Bitcoin_Price": "Prix du Bitcoin",
		"InvestmentRecord": "Historique d'investissement",
		"Best_Assistant": "Assistant d'investissement",
		"last_100_rounds": "(100 derniers tours)",
		"What_do_these_data_mean": "Que signifient ces données ?",
		"Missing": "Manquant",
		"Missing_text": "Cela signifie le nombre de tours depuis le dernier tour ayant produit ce nombre.",
		"Frequency": "Fréquence",
		"Frequency_text": "Fait référence au nombre de fois que le nombre correspondant apparaît dans les 100 derniers tours.",
		"Max_Continued": "Max Continué",
		"Max_Continued_text": "Fait référence à la période d'apparition consécutive maximale du numéro correspondant dans les 100 dernières parties.",
		"Rule": "Règle",
		"Trend": "Tendance",
		"MyBalance": "Mon Solde",
		"JoinGreen": "Rejoindre Vert",
		"JoinRed": "Rejoindre Rouge",
		"JoinViolet": "Rejoindre Violet",
		"Parity": "Parité",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "Enregistrement",
		"more": "plus",
		"Period": "Période",
		"Price": "Prix",
		"Result": "Résultat",
		"My": "Mon",
		"Order": "Ordre",
		"Buy": "Acheter",
		"Profit": "Profit",
		"Green": "Vert",
		"Red": "Rouge",
		"Violet": "Violet",
		"tips": "Les frais de transaction sont de {bet_fee*100}% du montant de la commande",
		"tips1": "Les frais de transaction sont de ",
		"tips2": "du montant de la commande ",
		"OrderAmount": "Montant de la commande",
		"Quantity": "Quantité",
		"TransactionFee": "Frais de Transaction",
		"TotalAmount": "Montant Total",
		"PresalePolicy": "Politique de Confidentialité",
		"Iagree": "Je suis d'accord",
		"err1": "Veuillez d'abord accepter les règles de gestion de la prévente"
	},
	"historyPeriods": {
		"Orderrecord": "Historique des commandes",
		"record": "enregistrer"
	},
	"historyOrderPeriods": {
		"Wating Order": "Ordre en attente",
		"History Order": "Historique des ordres"
	},
	"Activity": {
		"added_to_account_balance": "ajouté au solde du compte.",
		"contentText": "Lorsque les personnes invitées effectuent leur premier dépôt, cela compte comme une invitation valide. L'invitant recevra une prime d'invitation pour chaque invitation valide. Les primes d'invitation varient de {{aaaaaa}}USDT pour 1 invitation valide à {{aaaaaa}}USDT pour 500 invitations valides.",
		"contentText_1": "Lorsque les personnes invitées effectuent leur premier dépôt, cela compte comme une invitation valide. L'invitant recevra une prime d'invitation pour chaque invitation valide. Les primes d'invitation varient de",
		"contentText_2": "pour 1 invitation valide à ",
		"contentText_3": "pour 500 invitations valides.",
		"contentText_3_1": "pour ",
		"contentText_3_2": "invitation valide.",
		"contentText2": "Les invitateurs partageront {{LV.1%}} des frais de négociation de chaque volume de trading des personnes invitées de niveau 1. Les invitateurs partageront {{LV.2%}} des frais de négociation de chaque volume de trading des personnes invitées de niveau 2. Le bonus maximum pour le volume de trading des personnes invitées est illimité en USDT.",
		"contentText2_1": "Les invitateurs partageront",
		"contentText2_2": "des frais de négociation de chaque volume de trading des personnes invitées de niveau 1. Les invitateurs partageront",
		"contentText2_3": "des frais de négociation de chaque volume de trading des personnes invitées de niveau 2. Le bonus maximum pour le volume de trading des personnes invitées est illimité en USDT.",
		"My_Rewards_History": "Mon historique de récompenses",
		"Cumulative_Single_Number_Trading_Volumn": "Volume de trading cumulatif sur un seul chiffre",
		"Cumulative_ODD_Number_Trading_Volumn": "Volume de trading cumulatif sur chiffre impair",
		"Cumulative_EVEN_Number_Trading_Volumn": "Volume de trading cumulatif sur chiffre pair",
		"Cumulative_BIG_Number_Trading_Volumn": "Volume de trading cumulatif sur grand chiffre",
		"Cumulative_SMALL_Number_Trading_Volumn": "Volume de trading cumulatif sur petit chiffre",
		"Advanced_Tasks": "Tâches avancées",
		"Invite_Friends_and_Earn_Up_to": "Invitez des amis et gagnez jusqu'à",
		"Referral_Code": "Code de parrainage",
		"Referral_Link": "Lien de parrainage",
		"INVITE_FRIENDS": "INVITER DES AMIS",
		"Complete_tasks_to_earn_up_to": "Effectuez des tâches pour gagner jusqu'à",
		"MY_REWARDS": "MES RÉCOMPENSES",
		"SHARE": "PARTAGER",
		"Earn_with_your_friends_together": "Gagnez avec vos amis ensemble",
		"Get_your_link": "Obtenez votre lien",
		"Claim_your_rewards": "Réclamez vos récompenses",
		"Earn_together": "Gagnez ensemble",
		"contentlist0": "Rejoignez {{gogobit}} et obtenez votre lien de parrainage. Les utilisateurs qui s'inscrivent via ce lien recevront des récompenses.",
		"contentlist0_1": "Rejoignez ",
		"contentlist0_2": " et obtenez votre lien de parrainage. Les utilisateurs qui s'inscrivent via ce lien recevront des récompenses.",
		"contentlist1": "Vous recevrez une récompense de parrainage d'une valeur moyenne de {{XX}} USDT après que vos amis effectuent leur premier dépôt et atteignent le volume de trading requis. Les récompenses peuvent atteindre {{XX}} USDT.",
		"contentlist1_1": "Vous recevrez une récompense de parrainage d'une valeur moyenne de ",
		"contentlist1_2": " USDT après que vos amis effectuent leur premier dépôt et atteignent le volume de trading requis. Les récompenses peuvent atteindre ",
		"contentlist1_3": " USDT.",
		"contentlist2": "Après que votre ami a créé un compte, il/elle peut réclamer une prime de trading pouvant aller jusqu'à 5 050 USDT.",
		"Referral_Rewards": "Récompenses de parrainage",
		"Detail_Rules": "Règles détaillées",
		"Invitation_Bonus": "Bonus d'invitation",
		"Up_to": "Jusqu'à",
		"content0": "Terminez n'importe quelle invitation valide et recevez une prime d'invitation d'une valeur moyenne de 10 USDT. La prime maximale pour cent personnes est de ",
		"content0_1": "Terminez n'importe quelle invitation valide et recevez une prime d'invitation d'une valeur moyenne de ",
		"content0_2": "La prime maximale pour cent invités est de ",
		"Trading_Bonus": "Bonus de trading",
		"content1": "Les parrains peuvent recevoir une prime proportionnelle au volume de transactions généré par leurs filleuls. La prime maximale pour une invitation valide est de ",
		"My_Referral_History": "Mon historique de parrainage",
		"Total_Invitees": "Nombre total de filleuls",
		"Valid_Invitees": "Filleuls valides",
		"Cumulative_Invitees": "Filleuls",
		"Trading_Volumn": "Volume de trading",
		"Daily_Tasks": "Tâches quotidiennes",
		"Detail_Rules_title": "TIRAGE AU SORT quotidien de 888 USDT",
		"Detail_Rules_title1": "1. Les données de trading à terme du jour seront calculées le jour suivant.",
		"Detail_Rules_title2": "2. Les utilisateurs qui réalisent un volume de trading à terme de 200 000 USDT ou plus le jour même auront une chance de tirer une fois pour le prix de 888 USDT.",
		"CLAIM_NOW": "RÉCLAMER MAINTENANT",
		"INVEST_NOW": "INVESTIR MAINTENANT",
		"title": "Portefeuille de bonus",
		"BonusBalance": "Solde de bonus",
		"Withdrawtobalance": "Retirer vers le solde",
		"BonusList": "Liste de bonus",
		"Bonuswithdrawtobalance": "Retirer le bonus vers le solde",
		"WithdrawalAmount": "Montant du retrait",
		"Pleaseentertheamount": "Veuillez entrer le montant",
		"err1": "Le montant du retrait ne peut pas être de 0",
		"success": "Transfert réussi"
	},
	"Refer": {
		"title": "Mon référentiel",
		"ReferTotalBonus": "Total des bonus de parrainage",
		"InviteFriends": "Inviter des amis",
		"Level1": "Niveau 1",
		"Level2": "Niveau 2",
		"TotalPeople": "Total des personnes",
		"Bonus": "Bonus",
		"Myinvitationlink": "Mon lien d'invitation",
		"Rebate_description": "Description du rabais"
	},
	"Task": {
		"InviteBonusToday": "Bonus d'invitation aujourd'hui",
		"bonusrecord": "historique des bonus",
		"hint1": "1. Les données sont mises à jour toutes les 10 à 30 minutes !",
		"hint2": "2. Seuls ceux qui se sont inscrits au cours des 3 derniers jours et ont effectué un dépôt aujourd'hui sont pris en compte !",
		"hint2_1": "2. Seuls ceux qui se sont inscrits au cours des ",
		"hint2_2": " derniers jours et ont effectué un dépôt aujourd'hui sont pris en compte !",
		"hint3": "3. Un dépôt minimum de 20u est requis aujourd'hui !",
		"hint3_1": "3. Un dépôt minimum de ",
		"hint3_2": "u est requis aujourd'hui !",
		"hint4": "Nombre de personnes invitées au cours des trois derniers jours",
		"hint4_1": "Nombre de personnes invitées au cours des ",
		"hint4_2": " derniers jours",
		"hint5": "Nombre de personnes ayant effectué un dépôt aujourd'hui",
		"Invite": "Inviter",
		"friendstorecharge": "amis pour effectuer un dépôt",
		"Earn": "Gagner",
		"Completed": "Effectué",
		"ExpireTime": "Heure d'expiration"
	},
	"Invited": {
		"title": "Enregistrement des bonus",
		"InviteTotalBonus": "Bonus total d'invitation",
		"InviteTime": "Temps d'invitation",
		"Bonus": "Bonus",
		"NoRecored": "Aucun enregistrement"
	},
	"mine": {
		"ProvacyPolicy": "Politique de confidentialité",
		"JOIN US": "REJOIGNEZ-NOUS",
		"title": "Centre personnel",
		"InviteCode": "Code d'invitation",
		"AccountBalance": "Solde du compte",
		"BonusBalance": "Solde de bonus",
		"Wallet": "Portefeuille",
		"Bank": "Banque",
		"Invite": "Inviter",
		"Refer": "Référence",
		"CustomerService": "Service clientèle",
		"ComplaintsSuggestions": "Plaintes et suggestions",
		"ChangePassword": "Modifier le mot de passe",
		"Activity": "Activité",
		"PresalePolicy": "Politique de confidentialité",
		"DownloadApp": "Télécharger l'application",
		"AppVersion": "Version de l'application",
		"Logout": "Se déconnecter",
		"Logouttips": "Êtes-vous sûr de vouloir vous déconnecter ?"
	},
	"App": {
		"title": "Téléchargement de l'application",
		"DownloadforAndroid": "Télécharger pour Android",
		"DownloadforiOS": "Télécharger pour iOS"
	},
	"Presale": {
		"title": "Politique de confidentialité"
	},
	"Account": {
		"title2": "Définir un nouveau mot de passe",
		"title2_text": "Définissez un nouveau mot de passe pour votre compte Google. Vous pouvez vous connecter à votre compte Google en utilisant ce nouveau mot de passe.",
		"title_text": "Vous essayez de réinitialiser votre mot de passe. Veuillez avoir votre Google Authenticator prêt pour la réinitialisation.",
		"err1": "Les deux nouveaux mots de passe saisis sont différents",
		"success2": "Mot de passe changé avec succès",
		"title": "Changer de mot de passe",
		"OldPassword": "Ancien mot de passe",
		"OldPasswordmsg": "Le vieux mot de passe est requis",
		"NewPassword": "Nouveau mot de passe",
		"NewPasswordmsg": "Le nouveau mot de passe est requis",
		"RetypeNewPassword": "Retaper le nouveau mot de passe",
		"RetypeNewPasswordmsg": "Le nouveau mot de passe doit être retapé",
		"Continue": "Continuer"
	},
	"Service": {
		"title": "Service",
		"CONTACTUS": "NOUS CONTACTER",
		"tips": "Le service client est en pause !",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "lien"
	},
	"Bank": {
		"BankName": "Nom de la banque",
		"ChooseaCurrencyType": "Sélectionner le type de devise",
		"SelectWithdrawalMethod": "Sélectionner la méthode de retrait",
		"Newbankcard": "Nouvelle carte bancaire",
		"Editbankcard": "Modifier la carte bancaire",
		"title": "Ma banque",
		"Cannotbeempty": "Ne peut pas être vide",
		"BankHolderName": "Nom du titulaire du compte bancaire",
		"BankAccountNumber": "Numéro de compte bancaire",
		"IFSCCode": "Code IFSC",
		"Email": "E-mail",
		"Phone": "Téléphone",
		"OTPCode": "Code OTP",
		"tips1": "Veuillez utiliser le numéro de téléphone portable que vous avez enregistré pour recevoir OTP",
		"tips2": "Pour la sécurité des fonds, la carte bancaire ne peut être modifiée qu'une fois par jour",
		"Add": "Ajouter",
		"error1": "Le nom du titulaire du compte bancaire ne peut pas être vide !",
		"error2": "Veuillez saisir le nom du titulaire du compte bancaire correctement !",
		"error3": "Le nom de la banque ne peut pas être vide !",
		"error4": "Veuillez saisir le nom de la banque correctement !",
		"error5": "Le numéro de compte bancaire ne peut pas être vide !",
		"error6": "Veuillez saisir le numéro de compte bancaire correctement !",
		"error7": "Le code IFSC ne peut pas être vide !",
		"error8": "Veuillez saisir le code IFSC correctement !",
		"error9": "L'e-mail ne peut pas être vide !",
		"error10": "Veuillez saisir une adresse e-mail correcte !",
		"error11": "Le téléphone ne peut pas être vide !",
		"error12": "Le code OTP ne peut pas être vide !",
		"Successfully": "Carte bancaire ajoutée avec succès"
	},
	"wallet": {
		"title": "Mon portefeuille",
		"MyBalance": "Mon solde",
		"MyBank": "Ma banque",
		"RechargeRecords": "Historique des recharges",
		"WithdrawalRecords": "Historique des retraits"
	},
	"Rechargepage": {
		"Tips": "Conseils : La première récompense de recharge est de {{firstRPR*100}} % du montant de la recharge",
		"Tips_1": "Conseils : La première récompense de recharge est de ",
		"Tips_2": " % du montant de la recharge",
		"Reminder": "Rappel",
		"Reminder_1": "1. Ne déposez aucune autre devise que l'USDT à cette adresse. L'envoi de jetons ou de pièces non pris en charge à cette adresse peut entraîner une perte irrécupérable.",
		"Reminder_2": "2. Le montant minimum de dépôt est de 5 USDT.",
		"Reminder_2_1": "2. Le montant minimum de dépôt est de ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Les dépôts arriveront après 15 confirmations du réseau.",
		"Minimum": "Minimum",
		"title": "Recharge",
		"History": "Historique de dépôt",
		"Mybalance": "Mon solde",
		"RechargeAmount": "Montant de la recharge",
		"RechargeAmountmsg": "Veuillez saisir le montant",
		"tips1": "Le canal de paiement est en maintenance!",
		"tips2": "Veuillez sélectionner un canal de paiement",
		"PayNow": "Payer maintenant",
		"err1": "Le canal de paiement est en maintenance",
		"err2": "Le montant ne peut pas être inférieur à",
		"err3": "Veuillez sélectionner un canal de paiement",
		"Rechargemethod": "Méthode de dépôt"
	},
	"Rechargerecord": {
		"title": "Historique des dépôts",
		"WatingOrder": "Ordre en attente",
		"HistoryOrder": "Historique des ordres",
		"Rupee": "Dollar",
		"Status": "Statut",
		"Time": "Heure"
	},
	"withdrawal": {
		"Google_Code": "Code Google",
		"Tips": "Conseils",
		"Tips_1": "Conseils: Les frais de retrait pour les montants inférieurs à ",
		"Tips_2": " USDT sont de ",
		"Tips_3": " USDT, les frais de retrait pour ",
		"Tips_4": "USDT et plus sont de",
		"Tips_5": "%",
		"WalletAddress": "Adresse de portefeuille",
		"Addwalletaddress": "Ajouter une adresse de portefeuille",
		"Network_fee": "Frais de réseau",
		"Receive_amount": "Montant reçu",
		"Select Chains": "Sélectionnez les chaînes",
		"Bankcard": "Carte bancaire",
		"Nobankcardselected": "Aucune carte bancaire ajoutée",
		"Withdrawalfee": "Frais de retrait",
		"title": "Retrait",
		"Mybalance": "Mon solde",
		"Withdrawablebalance": "Solde disponible pour le retrait",
		"tips1": "Le montant total d'achat doit être supérieur à {{codeAmount}} pour pouvoir être retiré intégralement",
		"tips1-1": "Le montant total d'achat doit être supérieur à",
		"tips1-2": "pour pouvoir être retiré intégralement",
		"tips2": "Le nombre de retraits par jour est de {{draw_num}} fois",
		"tips2-1": "Le nombre de retraits par jour est de",
		"tips2-2": "fois",
		"tips3": "Le montant maximum de retrait quotidien est de",
		"tips4": "Le montant minimum de retrait unique est de ₹{{draw_min_money}} et le montant maximum de retrait unique est de ₹{{draw_max_money}}",
		"tips4-1": "Le montant minimum de retrait unique est de",
		"tips4-2": "et le montant maximum de retrait unique est de",
		"tips5": "Le temps de retrait est {{draw_time}}, veuillez retirer au moment spécifié",
		"tips5-1": "Le temps de retrait est",
		"tips5-2": "veuillez retirer au moment spécifié",
		"tips6": "Le retrait en espèces déduira {{draw_fee}}% des frais de retrait",
		"tips6-1": "Le retrait en espèces déduira",
		"tips6-2": "des frais de retrait",
		"tips7": "Si votre montant de retrait est inférieur ou égal à ₹{{solid_min_amount}}, des frais fixes de ₹{{solid_feemoney}} seront facturés",
		"tips7-1": "Si votre montant de retrait est inférieur ou égal à",
		"tips7-2": "des frais fixes de",
		"tips7-3": "seront facturés",
		"WithdrawalAmount": "Montant du retrait",
		"WithdrawalAmountmsg": "Veuillez saisir le montant",
		"Withdrawal": "Retrait",
		"WithdrawalHistory": "Historique de retrait",
		"Tipsmsg": "La demande de retrait a été soumise avec succès, en attente de l'approbation !",
		"dangerMsg1": "Veuillez d'abord ajouter votre carte bancaire",
		"dangerMsg2": "Le montant de retrait ne peut pas être inférieur à",
		"dangerMsg3": "Le montant de retrait doit être un multiple de 100",
		"dangerMsg4": "Le montant de retrait ne peut pas être supérieur à",
		"dangerMsg5": "Le montant de retrait ne peut pas être supérieur au solde disponible pour le retrait",
		"dangerMsg6": "Veuillez d'abord ajouter votre carte bancaire"
	},
	"withdrawalrecord": {
		"title": "enregistrement de retrait",
		"WatingOrder": "Ordre en attente",
		"HistoryOrder": "Ordre d'historique",
		"Rupee": "Dollar",
		"Status": "Statut",
		"Time": "Temps",
		"Nomore": "Pas plus!"
	},
	"Version": {
		"title": "Version",
		"stableversion": "version stable",
		"Alreadythelatestversion": "Déjà la dernière version",
		"Newversionfound": "Nouvelle version trouvée",
		"DownloadUpdate": "Télécharger la mise à jour"
	},
	"Bonus": {
		"title": "Activité de Bonus",
		"ReceiveBonus": "Recevoir un Bonus",
		"Hi": "Salut",
		"tips1": "Félicitations, vous avez obtenu un bonus",
		"tips2": "Vous devez recharger un montant supérieur ou égal à",
		"tips3": "et le bonus sera automatiquement ajouté à votre solde de compte",
		"Received": "Reçu",
		"Back": "Retour",
		"Rechargenow": "Recharger maintenant"
	},
	"ComplaintsSuggestions": {
		"title": "Réclamations et Suggestions",
		"Answered": "Répondu",
		"AddNew": "Ajouter nouveau",
		"Time": "Temps",
		"Type": "Type",
		"WhatsApp": "WhatsApp",
		"Description": "Description",
		"Remark": "Remarque",
		"Waiting": "En attente",
		"Suggestion": "Suggestion",
		"Consult": "Consultation",
		"RechargeProblem": "Problème de recharge",
		"WithdrawProblem": "Problème de retrait",
		"OrderProblem": "Problème de commande",
		"Other": "Autre",
		"Describeyourproblems": "Décrivez votre problème",
		"WhatsApptocontactyou": "WhatsApp pour vous contacter",
		"submit": "Soumettre",
		"err1": "WhatsApp ne peut pas être vide!",
		"err2": "Le type ne peut pas être vide!",
		"err3": "La description ne peut pas être vide!"
	},
	"slotMachine": {
		"title": "Machine à fruits",
		"Myblance": "Mon solde",
		"Records": "Enregistrements de la machine à fruits",
		"titleRecord": "Enregistrements"
	},
	"bankrecharge": {
		"Orderamount": "Montant de la commande",
		"Ordernumber": "Numéro de commande",
		"BankName": "Nom de la banque",
		"BankAccount": "Compte bancaire",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Votre numéro de carte bancaire",
		"Enterthebanktransactionordernumber": "Entrez le numéro de commande de transaction bancaire",
		"submit": "soumettre",
		"Tips": "Conseils",
		"tips1": "1. Le système traite votre commande.",
		"tips2": "2. veuillez contacter le service clientèle si le compte n'est pas arrivé depuis longtemps.",
		"err1": "Veuillez saisir votre numéro de carte bancaire",
		"err2": "Veuillez saisir le numéro de commande de transaction bancaire"
	},
	"javaCode": {
		"200": "succès",
		"20001": "Jeton d'accès invalide",
		"20002": "Jeton de connexion invalide",
		"20003": "Erreur de téléphone",
		"20004": "Le téléphone existe déjà !",
		"20005": "Erreur d'e-mail !",
		"20006": "L'e-mail existe déjà !",
		"20007": "Erreur de mot de passe !",
		"20010": "Erreur de compte ou de mot de passe !",
		"20011": "Erreur de compte bancaire !",
		"20012": "Erreur de code IFSC bancaire, (format IFSC standard) - longueur 11, les quatre premiers IFSC et le 5ème 0 !",
		"20013": "Erreur de nom de bénéficiaire, 5 < nom <= 50 !",
		"20021": "Le compte n'existe pas !",
		"20022": "Le compte est gelé !",
		"20031": "La couche est limitée à 10 !",
		"20032": "Erreur de mise à jour du rôle de relation !",
		"20033": "Solde insuffisant !",
		"20034": "Montant de retrait incorrect !",
		"30001": "Service temporairement fermé !",
		"30002": "Le montant de la mise en jeu est compris entre 10 et 50000 !",
		"30003": "L'issue en cours est terminée !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Erreur d'enregistrement !",
		"200082": "Le compte est désactivé !",
		"200091": "Ajout de carte limité à 10 !",
		"200092": "Erreur de saisie du mot de passe de connexion trop de fois et le compte est désactivé !",
		"300041": "Tout le montant de la transaction de l'émission est limité !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Erreur du serveur !",
		"Handledataerror": "Veuillez vous connecter !",
		"-91": "Erreur du serveur !",
		"-92": "Erreur de données !",
		"-1": "Erreur système !",
		"-2": "Erreur de paramètres",
		"-3": "Pas de données",
		"-4": "Opération fréquente en peu de temps",
		"-5": "Système occupé, veuillez réessayer plus tard !",
		"-6": "Il y a beaucoup de monde actuellement, veuillez réessayer !",
		"-7": "Erreur de données !",
		"-8": "Erreur de code Google !",
		"-9": "Le système est en maintenance ! Veuillez réessayer plus tard !",
		"-10": "Erreur d'enregistrement !",
		"-11": "Erreur de suppression !",
		"-12": "Erreur de mise à jour !",
		"-13": "Opération illégale !",
		"-14": "Échec de l'opération !",
		"-15": "Opération désactivée !",
		"-16": "Opération terminée !",
		"-17": "Erreur d'existence",
		"-18": "N'existe pas !",
		"-19": "expiré",
		"-20": "Désactivé",
		"-21": "Erreur de code de vérification",
		"-22": "L'intervalle de modification doit être supérieur à 1 jour",
		"-23": "Le nouveau mot de passe ne peut pas être identique à l'ancien mot de passe",
		"-100": "Erreur personnalisée !",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Montant total d'achat",
		"TotalRevenue": "Revenu total",
		"AccountBalance": "Solde du compte",
		"Income": "Revenu",
		"Transaction": "Transaction",
		"Myhold": "Mon investissement",
		"ProductList": "Liste des produits",
		"Investmentperiod": "Période d'investissement",
		"days": "jours",
		"Buynow": "Acheter maintenant",
		"Saletime": "Horaire de vente",
		"Nomore": "Pas plus",
		"ProductDetails": "Détails du produit",
		"ForSale": "En vente",
		"Sale": "Vente",
		"SoldOut": "Épuisé",
		"ProductInformation": "Informations sur le produit",
		"InvestmentPeriod": "Période d'investissement",
		"TotalShare": "Parts totales",
		"ExpectedReturn": "Retour attendu",
		"SaleTime": "Heure de vente",
		"PurchaseConditionsAndRules": "Conditions et règles d'achat",
		"MinimumPurchaseAmount": "Montant d'achat minimum",
		"MaximumSalesLimit": "Limite maximale de vente",
		"AccountBalanceMustBeGreaterThan": "Le solde du compte doit être supérieur à",
		"DayBetIsGreaterThan": "Le pari quotidien est supérieur à",
		"ProductDescription": "Description du produit",
		"MyHold": "Mon investissement",
		"Ordernumber": "Numéro de commande",
		"PurchasingPrice": "Prix d'achat",
		"NoRecord": "Aucun enregistrement",
		"Expiredate": "Date d'expiration",
		"Purchasetime": "Heure d'achat",
		"redemption": "Rachat",
		"redemptionTips": "Le rachat anticipé ne générera aucun revenu, êtes-vous sûr de vouloir procéder au rachat ?",
		"cancel": "Annuler",
		"determine": "Confirmer",
		"Buyin": "Acheter",
		"Buyamount": "Montant d'achat",
		"BuyinTips": "Le rachat anticipé ne générera aucun revenu",
		"confirm": "Confirmer",
		"Minimumbuy": "Achat minimum",
		"Maturity": "Échéance",
		"EarlyRedemption": "Rachat anticipé",
		"Redemptiontime": "Horaire de rachat",
		"Amountofincome": "Montant du revenu",
		"prompt": "Remarque"
	},
	"HelpHa": {
		"Aide": "Aide",
		"Centre d'aide": "Centre d'aide",
		"texte": "Des questions? Regardez ici."
	},
	"SetingHa": {
		"Seting": "Réglage",
		"SetingCenter": "Centre de réglage",
		"text": "Obtenez plus de récompenses"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Mise en jeu maintenant",
		"All_Chains": "Toutes les chaînes",
		"Annual_Percentage_Yield": "Pourcentage de rendement annuel",
		"Daily_Yield_Rate": "Taux de rendement quotidien",
		"Mining_Period": "Période DeFi",
		"Flexible": "Flexible",
		"Wallet_Balance": "Solde du portefeuille",
		"Daily_Yield": "Rendement quotidien",
		"DeFi_Mining": "Rejoignez la mise en jeu DeFi maintenant",
		"Disconnect": "Déconnexion",
		"Copy_Link": "Copier le lien",
		"text1": "Navigateur mobile non pris en charge",
		"text2": "TRON ne prend pas en charge le navigateur mobile pour se connecter au portefeuille.",
		"text3": "Veuillez l'ouvrir dans le <strong>navigateur de portefeuille mobile</strong> qui prend en charge la chaîne TRON (TRX) et assurez-vous que la chaîne actuelle est TRON (TRX). comme",
		"text3_1": "Veuillez l'ouvrir dans ",
		"text3_2": "le navigateur de portefeuille mobile",
		"text3_3": "qui prend en charge la chaîne TRON (TRX) et assurez-vous que la chaîne actuelle est TRON (TRX). comme",
		"text3_4": " etc.",
		"tips1": "Ne répétez pas la mise en jeu DeFi",
		"tips2": "Échec, veuillez réessayer plus tard",
		"tips3": "Le plugin de portefeuille n'a pas été détecté, veuillez installer le portefeuille TronLink",
		"tips4": "Veuillez d'abord vous connecter au portefeuille TronLink"
	},
	"historyOrderPeriods.WatingOrder": "Commandes en attente de traitement",
	"historyOrderPeriods.HistoryOrder": "Ordre historique",
	"HelpHa.Help": "Aide",
	"HelpHa.HelpCenter": "Centre d'aide",
	"HelpHa.text": "Pour toute question, regardez ici",
	"index.index.gayb21": "Inscrivez - vous pour recevoir des récompenses:",
	"index.index.72y628": "Trois raisons de choisir gogobit",
	"index.index.z6022y": "EPOCH EPT respecte l'une des normes de sécurité les plus élevées de l'industrie. Nous stockons 100% de nos crypto - monnaies dans des entrepôts frigorifiques. Nous garantissons un ratio de réserve minimum de 1: 1 pour les fonds de nos clients. Notre Fonds de 300 millions de dollars offre une protection supplémentaire contre les menaces à la sécurité.",
	"index.index.574982": "1 gratuit Inscrivez - vous maintenant gogobit",
	"index.index.qdi1g4": "2. Vérification Google complète",
	"index.index.weko28": "3 rechargez instantanément votre compte gogobit",
	"index.index.sgkb35": "Programme d'invitation gogobit",
	"index.index.2u17nf": "Remplissez toute invitation valide pour obtenir une valeur moyenne allant jusqu'à 5,59 USD. Le bonus maximum pour cent invités est",
	"index.index.e8is4a": "Programme de recommandation gogobit",
	"index.index.47uevl": "Mission de Trading gogobit",
	"index.index.2516gq": "EPOCH EPT, Inc. A reçu une licence pour ses activités commerciales de monnaie virtuelle de la Division des services financiers de l'État de New York.",
	"invest.index.3aqi3d": "Solde du compte:",
	"invest.index.ubk1j4": "Arrêtez d'investir!",
	"invest.prize1.4733u6": "Portefeuille d'investissement:",
	"invest.prize1.t55a16": "Total:",
	"login.login.09m52q": "J'ai lu et j'accepte gogobit",
	"login.login.045csy": "Nom d'utilisateur",
	"login.login.r7rbm5": "Veuillez accepter l'accord!",
	"rewards.activityb.4nvrq9": "Lorsque l'invité effectue son premier dépôt, il compte comme une invitation valide. Les invités recevront un bonus d'invitation pour chaque invitation valide. Niveau de bonus d'invitation à partir de 2usdt 1 invitation valide 559usdt 100 invitations valides.",
	"rewards.activityb.t10k6e": "Les invitants se partageront une Commission de transaction de 20% sur les frais de transaction de chaque invité lv.1. Les invitants se partageront une Commission de transaction de 10% sur les frais de transaction de chaque invité lv.2. Le bonus maximum attribué par les frais de transaction de la personne invitée est en dollars illimités.",
	"rewards.activityb.3d6850": "Lv.1 nombre effectif d'invités",
	"rewards.activityb.lvxo74": "Lv.2 nombre effectif d'invités",
	"rewards.activityb.zj26f9": "Lv.1 nombre cumulé d'invités",
	"rewards.activityb.dkgxr5": "Lv.2 nombre cumulé d'invités",
	"rewards.activityb.xjo077": "Récompenses recommandées:",
	"rewards.activityb.k8wv6v": "Rejoignez gogobit et obtenez votre lien de parrainage. Les utilisateurs qui s'inscrivent via ce lien sont récompensés.",
	"rewards.activityb.4slpzg": "Une fois que votre ami a effectué son premier dépôt et complété le volume de négociation requis, vous recevrez une récompense de référence d'une valeur moyenne de 5,59 $. Les récompenses peuvent atteindre 559 $.",
	"rewards.index.993jwg": "Copie réussie",
	"rewards.index.7141ft": "Ce navigateur ne supporte pas la copie automatique",
	"wallet.index.9uz2gn": "Frais réseau 0 usdt",
	"wallet.index.ych3e1": "Arrivée réelle:",
	"wallet.index.3v558d": "Conseil: retraits inférieurs à 20 $, frais de retrait de 1 $, retraits supérieurs à 20 $, frais de retrait de 3%.",
	"wallet.index.255e30": "1. Le montant maximal de retrait quotidien est de 8 000 000 $",
	"wallet.index.3g68m8": "2. Le montant minimum de retrait unique est de 5 $et le montant maximum de retrait unique est de 8 000 000 $",
	"wallet.index.c6x3ti": "3. L'heure de retrait est 00: 00 - 23: 59, veuillez retirer dans le délai spécifié",
	"wallet.index.h94rlg": "Minimum 5",
	"wallet.index.167862": "Veuillez entrer l'adresse du portefeuille!",
	"wallet.index.pi869h": "Veuillez entrer le montant retiré!",
	"wallet.index.ysa85i": "Le montant maximal du retrait unique est",
	"components.changepass.68j9kh": "Vous essayez de réinitialiser votre mot de passe.",
	"components.changepass.ouxtp2": "Le mot de passe original",
	"components.languagebox.8no849": "Japonais",
	"components.languagebox.oif20b": "Chinois traditionnel",
	"components.mycode.xyqr66": "Obtenez jusqu'à 5000 usdt pour terminer une tâche",
	"components.mymenu.277ss7": "ID utilisateur:",
	"components.mymenu.74l443": "Heures d'inscription:",
	"components.myposter.36rw5e": "Code recommandé:",
	"components.mytabbar.zw2bh2": "Accueil",
	"components.mytabbar.8x6c28": "Les tendances",
	"components.mytabbar.28s7qr": "Investissement",
	"components.mytabbar.4r75d9": "Récompenses",
	"components.xieyi.zqk7pe": "Font - size: 16px; Font - family: Corps de Song",
	"components.help.6gpud8": "Il existe 2 modes d’investissement:",
	"components.help.5qrq36": ": chaque période dure 1 minute et offre uniquement un double investissement. Le résultat est calculé à partir du dernier chiffre du cours de clôture de la ligne K pendant 1 minute sur binance BTC / usdt spot Trading.",
	"components.help.isl7r1": ": chaque période dure 5 minutes. La version pro débloque toute l'approche d'investissement de gogobit. Le résultat est calculé à partir du dernier chiffre du cours de clôture de la ligne K de 5 minutes sur binance BTC / usdt spot Trading.",
	"components.help.eai3nm": "Retour sur investissement:",
	"components.help.35xg82": "Single chiffres:",
	"components.help.mpfg0s": "Gain: montant investi x 9,4",
	"components.help.v2ziib": "Odd chiffres:",
	"components.help.vfzxor": "Gain: montant investi x 1,94",
	"components.help.73168b": "Les chiffres even:",
	"components.help.76wgv1": "Big chiffres:",
	"components.help.05ed33": "Petit numéro:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Veuillez entrer votre mot de passe original!",
	"components.changepass.xme6my": "Veuillez entrer un nouveau mot de passe!",
	"components.changepass.u434f8": "Deux fois le mot de passe est incohérent!",
	"components.changepass.574790": "Modification réussie!",
	"invest.index.9y8wg0": "Non récompensé",
	"invest.index.ql5nc1": "Récompensé",
	"login.login.76451t": "Exchange Spread a quantifiez le trading!",
	"login.login.915p36": "Première mondiale en gestion financière",
	"login.login.sen863": "Hello débarque encore mieux",
	"login.login.nz6q9x": "Téléphone login",
	"login.login.w7p592": "Mailbox login",
	"login.login.g9y91k": "Numéro de téléphone portable",
	"login.login.456gbb": "Numéro de boîte aux lettres",
	"login.login.5ly8m8": "Pays",
	"login.login.69h472": "Veuillez sélectionner un pays",
	"login.login.i4p378": "Numéro",
	"login.login.fl82xx": "Exemple:",
	"wallet.index.urkoub": "Code QR rechargeable",
	"wallet.index.25cocl": "Copier l'adresse",
	"wallet.index.t35fvg": "1. Heure de retrait: s'il vous plaît semaine 1 à samedi dans les 24 heures, la comptabilité de la taxe régionale le dimanche!",
	"wallet.index.68xx61": "Les retraits en monnaie nationale dans le monde entier sont facturés dans les 48 heures au plus tard, avec des frais de traitement de retrait de 8% (taxes régionales incluses).",
	"wallet.index.4ws3w9": "3. Retrait usdt au plus tard 24 heures à payer, frais de traitement 2% (taxes régionales incluses)",
	"wallet.index.9b7wq8": "4. Retrait minimum 12usdt, montant maximum de retrait unique 3000usdt.",
	"wallet.index.23y92a": "Veuillez savoir",
	"wallet.index.pl5473": "Minimum 5",
	"wallet.index.nb3u72": "Informations sur la carte bancaire",
	"wallet.index.842so4": "Ajouter un numéro de carte bancaire",
	"wallet.index.86j717": "Ajouter une banque d'appartenance",
	"wallet.index.763d7x": "Ajouter un nom de carte bancaire",
	"wallet.index.50j237": "La Banque à laquelle appartient",
	"wallet.index.hb6lun": "Nom de la carte bancaire",
	"wallet.index.62y2jc": "Numéro de carte bancaire",
	"login.register.666hp4": "Trading quantitatif sur Al Spread",
	"login.register.9298b8": "Inviter des amis",
	"login.register.375g14": "Les nouveaux arrivants rejoignent pour la première fois",
	"login.register.01922f": "Peut être effectué 1 fois",
	"login.register.527o4q": "Gains quotidiens ai",
	"login.register.c563gt": "Inscription à la boîte aux lettres",
	"login.register.2ls856": "Le numéro de téléphone est au mauvais format!",
	"login.register.4nu77e": "Le numéro de boîte aux lettres est mal formaté!",
	"lang.zh.pu623z": "1 invitation valide",
	"lang.zh.3hv80b": "Recharge pour usdt!",
	"lang.zh.9tli5f": "Conseil: les retraits sont inférieurs à",
	"lang.zh.62e882": "USD, frais de retrait sont",
	"lang.zh.m3818q": "USD, retrait",
	"lang.zh.xs2f10": "Le rachat anticipé ne génère aucun gain",
	"invest.index.hl86r1": "Assistant d'investissement",
	"wallet.index.645d70": "Veuillez entrer le nom de la Banque à laquelle vous appartenez",
	"wallet.index.n21787": "Veuillez entrer le nom d'utilisateur de votre carte bancaire",
	"wallet.index.7s5j14": "Veuillez entrer le numéro de carte bancaire",
	"components.recharge.l576h7": "Changement de solde",
	"components.recharge.3x6844": "Remarques",
	"components.appjump.u85nhy": "Moneyeasily est devenu ma nouvelle Addiction!",
	"components.appjump.543ht1": "Je viens de gagner 50 $sur moneyeasily et je suis enfin libre de gagner de l'argent supplémentaire n'importe où, n'importe quand. Je peux participer à des sondages, jouer à des jeux ou tester de nouvelles applications et être payé! Inscrivez - vous maintenant pour un bonus de 50 $!",
	"components.appjump.8k17w7": "Partager le succès",
	"components.appjump.648wav": "L'échec du partage:",
	"components.appjump.7c2hl4": "Les navigateurs actuels ne supportent pas la fonction de partage",
	"components.fuwu.1t2h7i": "Contenu",
	"components.fuwu.6t32g3": "Contexte du marché.",
	"components.fuwu.4c8u7w": "Système de Trading quantitatif intelligent",
	"components.fuwu.mw89jh": "Demande automatique de réémission",
	"components.fuwu.v5o60h": "Naissance.",
	"components.fuwu.12x554": "Champ de vision",
	"components.fuwu.d8kj48": "Valeurs fondamentales.",
	"components.fuwu.sns568": "Logique du design",
	"components.fuwu.131mc5": "Principes fondamentaux.",
	"components.fuwu.76i3z7": "Modèle technique du système de Trading quantitatif intelligent",
	"components.fuwu.253h82": "Technologie blockchain.",
	"components.fuwu.64z717": "Architecture",
	"components.fuwu.14n9v4": "Membres de l'équipe.",
	"components.fuwu.ewgac0": "Histoire et programme",
	"components.fuwu.yy18w5": "Valeur future du marché",
	"components.fuwu.h4l80q": "Considérations juridiques",
	"components.fuwu.1nvu89": "Disclaimer",
	"components.fuwu.yvo758": "Contexte du marché",
	"components.fuwu.pgt1oc": "À partir de",
	"components.fuwu.4vy2ub": "Année à",
	"components.fuwu.sxj11f": "Au début de l'année, les actions de valeur ont affiché un net retard par rapport aux actions de croissance, aggravant la Sous - performance chronique des facteurs de valeur depuis la crise financière mondiale. Certains l’attribuent au facteur macro - taux d’intérêt & \\ 8212; & \\ \\ 8212; Des taux d'intérêt bas, une baisse des rendements obligataires ou une courbe de rendement plate.",
	"components.fuwu.ihu32d": "Ces affirmations ont été examinées et il a été constaté que la relation entre la valeur et le taux d'intérêt était théoriquement assez vague et complexe. Empiriquement, il n'y a qu'une légère corrélation entre la variable de taux d'intérêt et le facteur de valeur sous certains paramètres. Bien qu'il existe des tendances significatives associées aux variations du rendement obligataire ou de la marge de rendement dans l'échantillon de données le plus récent, l'importance économique de ces corrélations est faible et incohérente dans les autres échantillons.",
	"components.fuwu.ynfmrx": "La conclusion est que la performance du facteur de valeur ne peut pas être expliquée par un facteur de taux d'intérêt et qu'une stratégie de sélection de facteur basée sur les signaux de taux d'intérêt est difficile à mettre en œuvre efficacement. Pour retrouver la courbe de retour sur investissement précédemment considérable, il est essentiel de se concentrer sur l’économie numérique.",
	"components.fuwu.041265": "Est par",
	"components.fuwu.7ybglf": "Et dix employés",
	"components.fuwu.cv8q4k": "Une société de hedge funds fondée à New York pour résoudre les problèmes de ses clients de manière professionnelle avec des méthodes de recherche universitaire et d'innovation.",
	"components.fuwu.u18kd5": "Dans le passé",
	"components.fuwu.345nr1": "Pendant de nombreuses années au milieu de l'année,",
	"components.fuwu.uux4kt": "Plusieurs stratégies d'actions internationales et produits d'investissement financier ont été lancés pour aider les investisseurs à obtenir une valeur ajoutée stable de leurs actifs sur les marchés financiers. à",
	"components.fuwu.2k2868": "Année,",
	"components.fuwu.dfo204": "Taille des actifs gérés par les hedge funds et les produits financiers",
	"components.fuwu.rer5h5": "A dépassé",
	"components.fuwu.y62673": "Milliards de dollars, à partir de",
	"components.fuwu.mdq3mn": "À la fin de l'année,",
	"components.fuwu.944x0p": "La taille des fonds gérés dans le monde dépasse",
	"components.fuwu.64sd2z": "Milliards de dollars",
	"components.fuwu.i2bs5g": "Avec",
	"components.fuwu.34l46q": "Puissance de calcul, Big Data, Internet des objets",
	"components.fuwu.t8n5nh": "Le développement exponentiel de domaines tels que le cloud computing, l'intelligence artificielle a commencé à libérer sa puissance, conduisant à un boom de l'industrie.",
	"components.fuwu.5765y1": "Balayé Internet en quelques mois seulement. Ses applications dans l'exploration de données, la connaissance de contenu, le diagnostic intelligent, l'analyse prédictive, l'évolution intelligente et bien plus encore sont devenues progressivement connues. Cependant, beaucoup de gens ne savent pas que les applications d’intelligence artificielle, telles que la reconnaissance faciale et les technologies d’interaction vocale, sont intégrées dans la vie quotidienne des gens.",
	"components.fuwu.138tt4": "Selon les statistiques, à partir de",
	"components.fuwu.jk42p7": "Le nombre de startups d’ia dans le monde a augmenté",
	"components.fuwu.lu0nu3": "Dix mille, le montant total du financement atteint",
	"components.fuwu.edrz45": "Billions de dollars. De nombreuses entreprises technologiques ont créé des départements d'intelligence artificielle, ce qui indique que l'ère de l'intelligence artificielle est arrivée. Dans le passé",
	"components.fuwu.0ty944": "Pendant l'année,",
	"components.fuwu.267zhq": "Toujours à la pointe de l'innovation en matière d'investissement croisé dans la technologie, les données et la finance comportementale. Elle explore en permanence les marchés et applique ces connaissances aux portefeuilles de clients, aidant ainsi les clients du monde entier à réaliser une croissance stable de leur patrimoine.",
	"components.fuwu.768x8x": "Avec le cloud computing, Edge Computing, Big Data,",
	"components.fuwu.8hhz36": "Et le développement rapide de l'intelligence artificielle,",
	"components.fuwu.o32734": "Le fondateur de",
	"components.fuwu.8hhu5s": "Prendre conscience que les produits doivent être en phase avec leur temps",
	"components.fuwu.jwkv4j": "Sinon, ils seront éliminés du marché. En plus de maintenir les actions existantes et les opérations financières,",
	"components.fuwu.4m7d39": "À",
	"components.fuwu.n48x5n": "Début du déploiement de produits d'intelligence artificielle. En tirant parti des technologies d'intelligence artificielle pour améliorer l'efficacité, anticiper les tendances d'application, intégrer diverses données et exploiter les modèles de négociation Wave - frequency,",
	"components.fuwu.4ukw28": "Capable de maintenir le rendement moyen du produit combiné dans",
	"components.fuwu.ed1635": "Gauche et droite.",
	"components.fuwu.o0516q": "Plonger profondément dans la frontière de l'intelligence artificielle, s'efforcer d'intégrer des caractéristiques telles que l'exploration de données, l'analyse intelligente, l'évolution intelligente dans les produits financiers, de sorte que",
	"components.fuwu.7ognk6": "Capacité à faire progresser les marchés financiers avec plus de précision, de facilité et de solidité. Après les efforts inlassables de l'équipe,",
	"components.fuwu.9ye2y0": "Le système de Trading quantitatif intelligent est terminé et a passé avec succès deux ans de tests, et nous pensons qu'il est temps de le mettre sur le marché.",
	"components.fuwu.hrwn57": "Le système de négociation quantifiable intelligent est un produit financier numérique déployé sur la blockchain après ses produits financiers boursiers. S'appuyer sur l'économie numérique et l'intelligence artificielle pour gérer les actifs numériques des utilisateurs de manière plus efficace, précise, stable, flexible, à faible coût et traçable. Il s'agit d'une nouvelle combinaison d'économie, de Finance comportementale, de données et de technologie qui permet de valoriser plus efficacement les actifs des clients.",
	"components.fuwu.jb545g": "Année par",
	"components.fuwu.31h712": "Personnel établi à New York. C'est un Hedge Fund qui utilise la recherche universitaire spécialisée et des approches innovantes pour résoudre les problèmes de ses clients. Dans le passé",
	"components.fuwu.623c56": "Plusieurs stratégies d'actions internationales et produits d'investissement financier ont été lancés pour aider les investisseurs à atteindre une croissance solide sur les marchés financiers. à",
	"components.fuwu.o8270p": "Actifs gérés de hedge funds et de produits financiers",
	"components.fuwu.j53w1u": "Milliards de dollars. À partir de",
	"components.fuwu.4fogox": "Gère plus de",
	"components.fuwu.h0al2w": "Un fonds de milliards de dollars grâce à son ombre mondiale géante",
	"components.fuwu.202g6o": "Sonner la force.",
	"components.fuwu.85g7o1": "Ouverture du premier Bureau international en Australie.",
	"components.fuwu.2ubf7n": "Un Bureau a été établi au Royaume - Uni.",
	"components.fuwu.ud1j47": "L'Institut de gestion d'actifs a été créé à la London Business School pour faire progresser la recherche en gestion d'actifs.",
	"components.fuwu.z45lgv": "Ouverture d'un bureau à Hong Kong, expansion des opérations en Asie.",
	"components.fuwu.4582f8": "Un centre d'ingénierie a été créé à Bangalore, en Inde.",
	"components.fuwu.m00yuh": "Avec un nouveau Bureau en Allemagne, elle étend encore sa portée mondiale. Dans",
	"components.fuwu.3r7069": "Années.",
	"components.fuwu.jwwo48": "Est reconnu.",
	"components.fuwu.jnvf5m": "Année à",
	"components.fuwu.l0bqqt": "Meilleure société de gestion de fonds de pension et d'investissement de l'année.",
	"components.fuwu.e777k5": "Posséder",
	"components.fuwu.j0u235": "Un partenaire et près d'un millier d'employés, avec une",
	"components.fuwu.8d8hnh": "Une équipe de recherche avec de nombreuses années d'expérience de recherche et de nombreux articles publiés dans les meilleures revues académiques. La société combine une solide expérience pratique avec des outils quantitatifs pour gérer les informations essentielles et gérer les risques. L'adoption d'une approche systématique axée sur la recherche pour gérer les stratégies alternatives et traditionnelles est un rare & 8220; Recherche académique",
	"components.fuwu.y17m3k": "Investissement quantifié & quot; 8221 & quot; Société à double entraînement.",
	"components.fuwu.59t85m": "Naissance",
	"components.fuwu.xd3f51": "Depuis",
	"components.fuwu.p1o1m9": "Depuis des années,",
	"components.fuwu.s6631u": "Il y a eu une réflexion constante sur l'application de l'intelligence artificielle dans le domaine financier pour résoudre les points douloureux du Trading humain.",
	"components.fuwu.654rrg": "Lancement officiel de la création du Centre de recherche sur les systèmes de négociation quantifiés pour l'intelligence artificielle et rédaction du premier",
	"components.fuwu.3w3i3q": "Livre blanc sur les systèmes de Trading quantifiés intelligents. La recherche résout les problèmes liés à l'intelligence artificielle et aux modèles de Trading, construit une plate - forme informatique d'intelligence artificielle et construit un système de Trading quantitatif d'intelligence artificielle.",
	"components.fuwu.315354": "Application réussie de l'intelligence artificielle au Trading et développement",
	"components.fuwu.oef1gv": "Système de Trading quantitatif intelligent. À partir de la collecte de données, le système utilise l'intelligence artificielle pour capturer les données du marché, y compris les prix historiques, les volumes, le sentiment du marché et plus encore. Le marché est ensuite analysé et exploré, plusieurs stratégies de Trading sont définies, différents modèles de Trading sont établis et les signaux de Trading correspondants sont générés en fonction de la stratégie de Trading et des paramètres prédéfinis de contrôle du vent. Le système fonctionne par le biais de transactions automatisées.",
	"components.fuwu.kinmrh": "Le Smart Quantified Trading System est une plate - forme de Trading intelligente développée et validée par le Hong Kong Fund en collaboration avec une équipe de technologie blockchain de premier plan en Allemagne. Il utilise des technologies informatiques de pointe telles que l'intelligence artificielle, la blockchain, le Big Data et d'autres pour permettre une gestion décentralisée des transactions financières.",
	"components.fuwu.1khwn2": "Les algorithmes technologiques de base du système de négociation quantifiable intelligent utilisent la technologie blockchain pour permettre des transactions financières décentralisées à l'échelle mondiale sans restriction géographique.",
	"components.fuwu.e5g3fa": "Le système de négociation quantifiable intelligent servira également d'écosystème de l'économie numérique du futur",
	"components.fuwu.7ftmw3": "& quot; 8220; Assistant & 8211; Fournir des solutions de transaction quantifiées pour les transactions financières mondiales, permettant le déploiement rapide d'applications blockchain efficaces. Il simplifie le Trading et l'échange d'actifs et d'actifs numériques. Il offre également des applications financières majeures",
	"components.fuwu.4646q8": "Interfaces clés pour connecter divers scénarios d'application et permettre une intégration transparente des actifs numériques avec diverses applications industrielles. Cela offre aux investisseurs un environnement d’investissement sûr, rapide, fluide et stable. Dans",
	"components.fuwu.kh359j": "Dans le cadre du système de négociation quantifiable intelligent, la puissance de l'intelligence artificielle sera exploitée pour construire un écosystème de services d'applications financières avec des capacités ultra - intelligentes et ultra - rapides. Cela permettra à plus de gens de profiter de la super expérience que l'ère numérique apporte, avec des directions claires pour la vitesse, la sécurité, la stabilité, la décentralisation, etc. Il vise à créer un nouvel écosystème qui applique l'intelligence artificielle à la numérisation des actifs, offrant liberté et innovation.",
	"components.fuwu.wsp5m5": "La vision d'un système de Trading quantitatif intelligent est basée sur la pensée de la Banque d'investissement et le modèle de capital - risque. Avec la technologie de l'intelligence artificielle au cœur, s'appuyant sur le Big Data, fournir aux investisseurs des produits d'investissement rentables, sûrs et fiables pour des rendements stables à long terme.",
	"components.fuwu.861380": "Les facteurs de valeur apportent des rendements attrayants à long terme, mais peuvent également être affectés par des périodes difficiles qui peuvent être douloureuses pour les investisseurs. Cependant, pour ceux qui persévèrent, une reprise économique ultérieure est rentable.",
	"components.fuwu.igh8q8": "Un système de Trading quantifiable intelligent peut aider les investisseurs à surmonter la détresse émotionnelle dans les moments difficiles pour obtenir un rendement à long terme de la valeur.",
	"components.fuwu.u7cy1f": "Rétrospective depuis",
	"components.fuwu.502xa6": "Changements survenus au cours de l'année, le faible rendement attendu de nombreuses classes d'actifs n'a pas sensiblement changé.",
	"components.fuwu.yg0781": "Un système de Trading quantitatif intelligent peut résoudre efficacement ces problèmes.",
	"components.fuwu.k7s41z": "Les données volumineuses montrent que la stratégie systématique de mise en œuvre de l'acquisition exponentielle directe entraîne une diminution progressive des pertes nettes au cours des premières années suivant la mise en œuvre. Ces stratégies affichent également des résultats de perte nette très diversifiés. Ainsi, par",
	"components.fuwu.pw7jg1": "Une stratégie factor Invest long short pilotée par un système de Trading quantitatif intelligent peut être significativement supérieure à une stratégie indicielle directe.",
	"components.fuwu.9gya07": "Les primes attendues pour investir dans les marchés émergents et développés sont dans le passé",
	"components.fuwu.yfiuur": "L'extrémité supérieure de l'intervalle observé en années. Dans le même temps, bon nombre des risques historiquement associés aux marchés émergents ont depuis longtemps diminué. Les investisseurs ont de bonnes raisons d'augmenter leur allocation aux marchés émergents, qui incluent les actifs numériques.",
	"components.fuwu.j8nq18": "Valeurs fondamentales",
	"components.fuwu.40fdvu": "Au cours des vingt dernières années,",
	"components.fuwu.w5kk35": "Les moteurs du marché sont constamment explorés et comment ils peuvent être appliqués aux portefeuilles de clients dans les domaines de l'économie, de la finance comportementale, des données et de la technologie. Avec l'essor du Big Data et de l'artificiel et le développement de l'intelligence,",
	"components.fuwu.pg3e4s": "Les stratégies et les modèles de Trading deviennent de plus en plus complexes.",
	"components.fuwu.kcjcom": "Le système de Trading quantitatif intelligent est entré dans sa phase de test et est maintenant officiellement ouvert au monde entier via le Bureau de Hong Kong.",
	"components.fuwu.n21shs": "Le système de Trading quantitatif intelligent est basé sur la pensée de la Banque d'investissement et le modèle de capital - risque, avec la technologie de l'intelligence artificielle au cœur. Il s'appuie sur le Big Data pour construire des modèles de Trading quantifiés conçus pour fournir aux investisseurs des produits d'investissement rentables et sûrs avec des rendements stables à long terme.",
	"components.fuwu.0l7u49": "En utilisant la technologie blockchain,",
	"components.fuwu.lgcse5": "Smart Quantified trading systems développe un réseau informatique distribué, à faible coût et à haute performance qui protège la vie privée et offre une gamme complète de produits financiers.",
	"components.fuwu.0t263a": "Les nœuds de calcul haute performance fournissent une puissance de calcul puissante pour les modèles de transaction, y compris la formation et l'inférence de l'intelligence artificielle, l'exploration de données, le calcul à zéro connaissance de la blockchain et bien plus encore.",
	"components.fuwu.f5s17o": "Les nœuds de calcul haute performance peuvent prendre de nombreuses formes, y compris l'exécution à grande échelle",
	"components.fuwu.u2615w": "Ou",
	"components.fuwu.a5en87": "Nœuds entièrement fonctionnels sur un cluster de serveurs",
	"components.fuwu.d4f145": "Nœuds permanents",
	"components.fuwu.29o1r5": ", ainsi que des petites et moyennes entreprises et des particuliers",
	"components.fuwu.r54iix": "Le libre",
	"components.fuwu.5zx75g": "Le serveur calcule les nœuds.",
	"components.fuwu.6156mg": "Le système de négociation quantifiable intelligent permet à tous les investisseurs d'en bénéficier tout en assurant un fonctionnement sûr et stable du système.",
	"components.fuwu.p54y10": "Dans",
	"components.fuwu.o69yz3": "Les capacités de traitement et d'apprentissage de l'IA sont essentielles dans le processus de négociation quantifiable intelligente. Il rend les systèmes de Trading plus intelligents, précis et automatisés, ce qui permet des décisions de Trading et une gestion des risques plus efficaces.",
	"components.fuwu.h1r7s5": "Les principes de conception d'un système de Trading quantitatif intelligent sont les suivants:",
	"components.fuwu.x27379": "Principe d'extensibilité",
	"components.fuwu.rhqg19": "Les différents modules d'un système de négociation quantifiable intelligent doivent être couplés de manière lâche, ce qui permet d'ajouter facilement de nouveaux modules sans modifier les interfaces des autres modules.",
	"components.fuwu.c7uqex": "Client produit utilisateur paire",
	"components.fuwu.46e7x1": "L'accès au système de Trading quantifiable intelligent est volatil. Lorsqu'un grand nombre d'utilisateurs accèdent à un seul nœud, cela peut entraîner un plantage de l'entreprise. Par conséquent, les conteneurs des nœuds devraient pouvoir être déployés automatiquement, permettant une expansion horizontale rapide lorsqu'il y a une pression sur les demandes des utilisateurs.",
	"components.fuwu.xc8u4k": "Principes de confidentialité",
	"components.fuwu.1n531t": "Les investisseurs, les nœuds de calcul, les sources de données et d'autres composants du système de Trading quantitatif intelligent bénéficient de garanties de protection de la vie privée.",
	"components.fuwu.ed16w3": "Principes fondamentaux",
	"components.fuwu.7h553b": "Ce qui est",
	"components.fuwu.k4l2md": "Transactions quantifiées intelligentes",
	"components.fuwu.7nve0q": "Le Trading quantifiable intelligent fait référence à l'utilisation de technologies et d'algorithmes d'intelligence artificielle pour automatiser les décisions d'investissement afin de maximiser l'efficacité des transactions. C'est une stratégie de Trading à haute fréquence basée sur des technologies telles que le Big Data, le Machine Learning, le Deep Learning, etc. Programmé automatiquement grâce à une analyse rapide des données du marché. Identifier les meilleures opportunités de Trading et les exécuter",
	"components.fuwu.g21385": "L'avantage du Trading quantifiable intelligent est la capacité d'analyser rapidement les données du marché, d'automatiser les décisions de Trading et d'éviter les interférences et les erreurs de calcul dues aux facteurs humains. En outre, il peut optimiser et étendre les transactions sur un marché plus large et à plus grande échelle. En outre,",
	"components.fuwu.v75es3": "Les systèmes de Trading intelligents apprendront en permanence grâce à l'intelligence artificielle. Créez de meilleurs modèles de Trading pour s'adapter aux changements du marché et aider les utilisateurs à réaliser des bénéfices à long terme grâce à une formation à long terme des données et à l'optimisation des modèles.",
	"components.fuwu.rcm80u": "Le processus de transaction est en",
	"components.fuwu.2x15l9": "Dans le système de Trading quantitatif intelligent, la technologie d'intelligence artificielle est appliquée pour atteindre la capacité de décision autonome de Trading intelligent. Il peut automatiser les transactions en fonction de stratégies et de paramètres de Trading prédéfinis et effectuer des analyses et des prévisions intelligentes en fonction de la situation du marché et des données historiques, permettant ainsi des décisions de Trading plus intelligentes et autonomes. Plus précisément,",
	"components.fuwu.xe1844": "Les systèmes de Trading quantifiable intelligents exploitent de grandes quantités de données historiques et d'informations de marché pour l'exploration de données et l'apprentissage automatique. Il construit des modèles et des algorithmes de Trading, surveille et analyse les marchés en temps réel, optimise et adapte les signaux et les stratégies de Trading pour des décisions de Trading et une gestion des risques plus efficaces. En outre, les systèmes de Trading quantifiés basés sur l'IA permettent des décisions de Trading plus intelligentes et autonomes grâce à des technologies d'intelligence artificielle et à des capacités d'auto - apprentissage qui améliorent et améliorent constamment leurs propres stratégies et algorithmes de Trading. Par conséquent,",
	"components.fuwu.8jr1h7": "& quot; 8220; Intelligent & 8221; Représente le niveau d'intelligence et la capacité de prise de décision autonome d'un système de négociation quantifié par l'IA. Il permet d’obtenir un retour sur investissement plus efficace, plus précis et plus important pour les investisseurs. Ainsi que la prise de décision de Trading automatisée et la gestion des risques, apporte",
	"components.fuwu.62r19x": "Dans le trading quantifiable intelligent,",
	"components.fuwu.1v4571": "& quot; 8220; Quantification & & 8221; Représente la quantification et l'analyse des données de marché et des stratégies de Trading. Le Trading quantitatif est un algorithme numérique basé sur la collecte, l'analyse et le traitement de grandes quantités de données qui transforment les informations de marché et les stratégies de Trading en décisions et transactions automatisées. Plus précisément,",
	"components.fuwu.35fgx0": "Le système de négociation quantifiable intelligent recueille une grande quantité de données sur le marché, y compris les prix historiques, les volumes, le sentiment du marché et plus encore, pour analyser et explorer le marché. Il construit diverses stratégies de Trading basées sur ces données. En utilisant ces stratégies de Trading et les paramètres prédéfinis de contrôle des risques, le système génère les signaux de Trading correspondants et exécute des transactions automatisées. Dans ce processus, la quantification et l'analyse des données rendent les systèmes de Trading plus intelligents, précis et automatisés, ce qui permet des décisions de Trading et une gestion des risques plus efficaces. Par conséquent,",
	"components.fuwu.ce4mr4": "& quot; 8220; Quantification & & 8221; Représenté",
	"components.fuwu.cfq4pv": "Techniques de base pour quantifier le Trading. C'est un moyen important de numériser les informations sur les marchés et les stratégies de négociation, permettant des décisions de négociation et un contrôle des risques plus rapides, plus précis et automatisés.",
	"components.fuwu.6b0w31": "Avantages",
	"components.fuwu.7q677y": "Efficacité",
	"components.fuwu.624ufa": "Le système de Trading quantitatif intelligent exécute automatiquement les stratégies de Trading et est capable de saisir les opportunités de Trading rapidement et avec précision, tout en évitant les erreurs humaines et les perturbations émotionnelles.",
	"components.fuwu.6k6y74": "Précision",
	"components.fuwu.y23qq8": "Le système de Trading quantitatif intelligent utilise de grandes quantités de données historiques et d'informations de marché pour l'analyse des données et les prévisions des modèles, améliorant ainsi la précision et la fiabilité des décisions de Trading.",
	"components.fuwu.s11cqu": "Stabilité",
	"components.fuwu.3q6d66": "Le système de négociation quantifiable intelligent met en œuvre une gestion des risques et des contrôles de négociation rigoureux qui garantissent la stabilité et le rendement à long terme du portefeuille.",
	"components.fuwu.w386vn": "Flexibilité",
	"components.fuwu.530y3q": "Le système de Trading quantitatif intelligent permet une adaptation rapide aux changements du marché et aux nouvelles opportunités de Trading, tout en permettant une stratégie de Trading et des paramètres adaptés aux préférences des investisseurs.",
	"components.fuwu.ph3l81": "Traçabilité",
	"components.fuwu.7ap85x": "Le système de Trading quantitatif intelligent enregistre et suit les détails et les données historiques de chaque transaction, ce qui facilite l'analyse et l'optimisation ultérieures pour les investisseurs.",
	"components.fuwu.v2jkcm": "Ecosystème",
	"components.fuwu.ics30f": "Système de Trading quantifiable intelligent pour",
	"components.fuwu.0nui8m": "Au cœur, l'alimentation décentralisée du calcul haute performance est réalisée. Le système se concentre sur la construction d'une infrastructure en tant que service pour l'intelligence artificielle",
	"components.fuwu.n868ra": "Les couches. Toutes les plateformes en tant que service nécessitant un calcul haute performance",
	"components.fuwu.ns02co": "Et logiciel en tant que service",
	"components.fuwu.f94h07": "Les services de couche sont disponibles en",
	"components.fuwu.6ftu9v": "Fonctionne librement sur un système de Trading quantifiable intelligent.",
	"components.fuwu.b19msv": "Technologie blockchain",
	"components.fuwu.864583": "Le cœur du système de Trading quantitatif intelligent réside dans la technologie de chaîne publique sous - jacente, qui combine le modèle financier, l'intelligence artificielle et le trading quantitatif pour gérer un débit de transaction élevé. Par conséquent,",
	"components.fuwu.61j6bp": "La chaîne principale du système de Trading quantitatif intelligent a adopté",
	"components.fuwu.q1j1n6": "Mécanisme de consensus, et combine",
	"components.fuwu.30nl6v": "L'utilité, améliore la tolérance aux pannes du système, l'efficacité des transactions et la fréquence des interactions. Ce réseau est constitué par",
	"components.fuwu.3kqnle": "Un super - nœud se compose, responsable de la validation des transactions et de la génération de blocs. En plus des supernœuds, il existe des nœuds réguliers qui synchronisent les données avec les nœuds principaux, gèrent les clés privées et les adresses de portefeuille et initient des transactions en chaîne. Pour répondre aux besoins de modélisation de l’intelligence artificielle et de Trading quantifié à haute fréquence,",
	"components.fuwu.i1g6e7": "Le système de Trading quantitatif intelligent continue de faire progresser le développement de la technologie de chaîne mâle sous - jacente. En optimisant le mécanisme de consensus, en utilisant la technologie sidechain pour atteindre un niveau supérieur",
	"components.fuwu.j3spal": ", traiter plus de transactions",
	"components.fuwu.d6r6r2": "Gérez plus de transactions simultanément et soutenez l'augmentation du trafic utilisateur.",
	"components.fuwu.rfs8eb": "Le système de négociation quantifiable intelligent met en œuvre un mécanisme inter - chaîne qui introduit un mécanisme de protection de la vie privée zéro preuve de connaissance, garantissant que les informations des utilisateurs ne sont pas excrétées. Le système s'enregistre sur la blockchain de manière unifiée",
	"components.fuwu.5l87r6": "Une application qui garantit que toutes les données sont ouvertes, transparentes et inviolables une fois que les actifs sont confirmés par un contrat intelligent. Par conséquent, l'utilisation de",
	"components.fuwu.ci8bt7": "Le Trading d'actifs partagés est basé sur des données totalement fiables qui éliminent le risque de faux actifs ou de fausses transactions.",
	"components.fuwu.li76g2": "Architecture générale",
	"components.fuwu.2881li": "Les nœuds de réseau d'un système de négociation quantifié intelligent peuvent être à la fois de grands nœuds de calcul tels que de grands bassins miniers ou",
	"components.fuwu.u15fc6": "Des nœuds de calcul de taille moyenne ou des ressources de Cloud Computing fournies par des plates - formes telles que Alibaba Cloud.",
	"components.fuwu.2w1u75": "La puissance de calcul Cloud et Edge acquise par le système de Trading quantitatif intelligent garantit la stabilité de l'environnement opérationnel de l'IA. Une fois que l’investisseur aura",
	"components.fuwu.6cu4ph": "Déposer",
	"components.fuwu.88fccf": "Système de Trading quantifiable intelligent, l'intelligence artificielle sera basée sur",
	"components.fuwu.4gfpl8": "Les quantités sont recherchées, identifiées, hiérarchisées, gérées et conservées. Par la suite",
	"components.fuwu.5vdauc": "Par",
	"components.fuwu.0ud3oi": "Dans le réseau, correspondant",
	"components.fuwu.8sm2by": "Et les nœuds de calcul ont été trouvés pour convenir à ce lot",
	"components.fuwu.189o1y": "Le mode de transaction blockchain. La puissance de calcul requise pour ce modèle est attribuée à la",
	"components.fuwu.y83nn2": "Réaliser des transactions quantifiées.",
	"components.fuwu.78ik3b": "3.2.2 architecture des nœuds de calcul",
	"components.fuwu.0wdoj7": "Architecture des nœuds de calcul",
	"components.fuwu.25g0dk": "3.2.3 Big Data + intelligence artificielle",
	"components.fuwu.r2e1x7": "Le système de Trading quantitatif intelligent contient un réseau distribué de calcul haute performance, essentiellement une infrastructure où le Big Data est combiné avec l'intelligence artificielle. Il a les caractéristiques suivantes",
	"components.fuwu.n4ct1t": ": le Framework sous - jacent est construit sur la plate - forme Matrix avec une architecture logicielle basée sur les abonnements Topic, le traitement événementiel et les processeurs. Avec une architecture asynchrone haute performance, il peut atteindre des performances exceptionnelles. La structure multichain est composée d'une chaîne principale et d'une chaîne de travail. La chaîne principale contient les définitions de mode de toutes les chaînes de travail et le nombre de chaînes de travail n'est pas limité. Chaque chaîne de travail est composée d'une chaîne de blocs fractionnés qui supporte le fractionnement infini. Les données de bloc sont basées sur la définition de schéma, ce qui permet une taille de bloc flexible et prend en charge le stockage compressé des données de bloc.",
	"components.fuwu.b9yr3c": "La communication et la transmission au sein d'un système de négociation quantifié intelligent utilisent un Protocole de compression auto - codé ou un Protocole de transmission crypté. Cela permet de réduire la bande passante du réseau et de négocier différents protocoles de codage de paquets de messages au sein d'un même lien, améliorant ainsi la sécurité de la transmission.",
	"components.fuwu.92179q": "Réseau 3D",
	"components.fuwu.y24z29": ": construit sur un réseau Peer - to - Peer (P2P), il établit un réseau hiérarchique qui accélère la propagation des messages. Les nœuds SN sont géographiquement diversifiés et peuvent adapter dynamiquement la topologie du réseau, améliorer la robustesse globale du réseau, résister aux attaques DDOS et réduire les risques de sécurité. Mécanisme de consensus du réseau: Adoption de dpos & nbsp; (delegated & nbsp; Proof & nbsp; of & nbsp; Stake) mécanisme de consensus qui combine les droits et les contributions des utilisateurs pour évaluer la participation au réseau. Il utilise une approche de traitement hiérarchique pour le stockage, le consensus et la validation, améliorant l'évolutivité de l'ensemble du réseau.",
	"components.fuwu.i5s5w9": "3.2.4 structure du réseau",
	"components.fuwu.248t2x": "Les solutions courantes pour résoudre l'évolutivité de la blockchain peuvent être divisées en deux types",
	"components.fuwu.75e81d": ": extensibilité verticale et horizontale. Les solutions typiques d'évolutivité verticale comprennent l'optimisation des algorithmes de consensus, le stockage de blocs volumineux et l'amélioration des performances du système, telles que Block extension, EOS & nbsp; Dpos et vrf + pos + pbft. Les solutions d'évolutivité latérale typiques comprennent les réseaux Lightning, les architectures multichain et les architectures segmentées, toutes conçues pour résoudre les problèmes d'évolutivité et d'évolutivité de la blockchain. Blockchain 4.0 est conçu pour construire des systèmes d'affaires distribués haute performance sur des réseaux décentralisés à grande échelle, en mettant l'accent sur la mise en œuvre pratique de l'entreprise. Il n'y a pas d'amélioration technique unique qui puisse résoudre complètement le problème de l'évolutivité globale. La conception architecturale globale de toute blockchain doit prendre en compte quatre éléments clés: l'architecture du réseau P2P, l'architecture de stockage sous - jacente, l'architecture de calcul / consensus et la conception des processus métier. Pour aller plus loin, dans le monde de la blockchain, il ne peut y avoir que deux attributs de conception pour la décentralisation, l'évolutivité et la sécurité, et toutes les conceptions d'évolutivité sont basées sur ce principe.",
	"components.fuwu.854i8h": "Le système de Trading quantitatif intelligent utilise une architecture multichain qui améliore l'évolutivité de l'ensemble du réseau. Il peut être isolé en toute sécurité en tirant parti de l'isolation fournie par la multi - chaîne",
	"components.fuwu.kw1e7d": "Données dapp. Avec plusieurs chaînes exécutées en parallèle, les performances du système peuvent croître linéairement. Cependant, dans une architecture multichain, il existe encore des goulots d'étranglement de performance pour une seule chaîne. Ainsi, l'introduction de la technologie de segmentation améliore encore les performances d'une seule blockchain, permettant une évolutivité horizontale. Introduction d'un routeur inter - chaînes et d'une couche de relais inter - chaînes entre plusieurs chaînes, prenant en charge le relais de sous - chaînes homogènes et de chaînes latérales hétérogènes. En ancrant la Sous - chaîne / chaîne latérale à la chaîne principale établie, l'interconnexion entre les chaînes multiples est interdépendante, créant ainsi une architecture blockchain avec une évolutivité illimitée.",
	"components.fuwu.y11qiw": "3.2.5 architecture de segmentation en",
	"components.fuwu.q7u6kb": "L'objectif de l'adoption du Sharding dans les systèmes de négociation quantifiés intelligents est principalement d'améliorer les performances des chaînes de blocs individuelles et l'évolutivité du réseau global. Chaque segment traite différents sous - ensembles de transactions en parallèle, ce qui permet un consensus intra - segment et permet l'exécution parallèle des transactions.",
	"components.fuwu.k61o0n": "3.2.5.1 l'architecture de segmentation complète se compose de trois parties: la segmentation du réseau, la segmentation du calcul (transaction / consensus) et la segmentation du stockage (segmentation de l'état). Tout d'abord,",
	"components.fuwu.4t6qe4": "Le système de Trading quantitatif intelligent divise les nœuds du réseau en différents segments virtuels selon les règles de segmentation",
	"components.fuwu.7yx4qo": "(aussi appelé Cluster). Il garantit la connectivité au sein de chaque tranche et la connectivité réseau entre les noeuds au sein de la tranche et le réseau principal. Les transactions au sein d'un segment se propagent uniquement au sein de ce segment afin de réduire la consommation de bande passante. Deuxièmement, tous les nœuds impliqués dans le consensus doivent fournir une marge et s'inscrire sur un contrat intelligent. Sur la base des règles de segmentation, nous pouvons segmenter les nœuds de consensus les transactions qui ont lieu dans différents segments sont empaquetées et validées par les nœuds de consensus du segment correspondant. Étant donné que les différentes transactions de partitionnement sont validées en parallèle à travers les partitions, les performances de consensus peuvent être étendues linéairement. À mesure que les performances du réseau s'améliorent, il devient impossible de stocker des données de grand livre entières sur chaque nœud du réseau. En supposant que la performance globale du réseau est de 1 million de tps, chaque nœud aura plus de 20 & nbsp d'espace de stockage de données de registre par jour; Le TB. Pour résoudre ce problème,",
	"components.fuwu.2wb32r": "Le système de négociation quantifié intelligent sépare la couche de stockage de la couche de calcul, stocke les données du grand livre à l'aide du système de fichiers du grand livre,",
	"components.fuwu.69229m": "Le réseau de systèmes de Trading quantifiés intelligents est maintenu conjointement.",
	"components.fuwu.l65907": "3.2.5.2 Chaque nœud d'un réseau P2P à fragmentation virtuelle Programmable (vshard) possède un peerid indépendant qui forme un réseau P2P structuré ou non structuré avec d'autres nœuds. En fonction des différents besoins commerciaux, les nœuds de réseau utilisent des contrats intelligents pour le fractionnement virtuel sur un réseau P2P physique. Chaque nœud calcule par programmation son propre vshard & nbsp; ID, appartenant au même vshard & nbsp; Les nœuds de l'ID constituent logiquement un fragment de réseau indépendant. Chaque segment de réseau provient physiquement d'un réseau P2P unifié. Cependant, il est logiquement isolé, offrant l'indépendance logique requise pour la conception de l'évolutivité du réseau fonctionnel. Chaque nœud de réseau P2P indépendant peut logiquement appartenir à plusieurs partitions virtuelles. Une connexion réseau doit être établie entre les nœuds de partitionnement, formant un réseau de partitionnement autonome de la région. Le fractionnement est dynamique et élastique, ce qui permet au réseau d'augmenter de manière flexible le nombre de fractions. Le réseau principal peut encore établir des réseaux P2P non structurés et structurés.",
	"components.fuwu.255q2n": "3.2.5.3 calcul des nœuds de consensus fractionnés: divisés en nœuds de consensus globaux et en nœuds de consensus internes. Les nœuds de consensus global sont responsables de l'obtention d'un consensus au sein du réseau principal, tandis que les nœuds de consensus intra - partition sont responsables du traitement du consensus au sein de chaque partition. Le consensus peut adopter un mécanisme de plugin qui permet à différentes tranches de supporter différents mécanismes de consensus. Protocoles de génération aléatoire distribués: les protocoles aléatoires doivent être équitables, imprévisibles, vérifiables par des tiers et évolutifs. Le nœud de consensus de partitionnement principal utilise l'algorithme vrf pour allouer les partitions et est responsable aléatoirement du consensus des différentes partitions. Les nœuds de consensus des différents segments traitent les transactions au sein de leurs segments respectifs. Witness Node Selection: un pool de nœuds de consensus de segmentation de nœuds est sélectionné aléatoirement pour participer au processus de consensus réel. Les nœuds de consensus empêchent les attaques malveillantes par des élections régulières. Le nombre de nœuds de consensus par tranche peut être augmenté de manière flexible. Les nœuds de consensus empêchent les attaques malveillantes par des élections régulières. Le nombre de nœuds de consensus par tranche peut être augmenté de manière flexible.",
	"components.fuwu.vr9w4q": "3.2.5.4 Division des services",
	"components.fuwu.524h25": "Le système de Trading quantitatif intelligent divise les transactions en transactions intra - segments et Cross - segments.",
	"components.fuwu.25ebl0": "Shard transaction address par transaction address et vshard & nbsp; Composition de l'id. Si l'adresse de l'expéditeur et l'adresse du destinataire d'une transaction appartiennent à la même tranche, la transaction est une transaction intra - tranche. Si les adresses de l'expéditeur et du destinataire appartiennent à différents segments, il s'agit d'une transaction Cross - segment. Assurance & nbsp; Will & nbsp; être & nbsp; Each & nbsp; à & nbsp; E & nbsp; Ouvriers & nbsp; Le & nbsp; Transactions intra - partitionnement TSA: les transactions se propagent uniquement au sein de la partition vshard et sont empaquetées et validées par les nœuds de consensus de partitionnement. Transactions Cross - Sharding: validées par l'empaquetage du nœud de consensus de Sharding principal",
	"components.fuwu.737wf6": "3.2.6 Crawlers Web",
	"components.fuwu.6ub735": "Le fonctionnement d'un système de Trading quantitatif intelligent repose sur des capacités telles que la collecte de données, l'analyse et la modélisation.",
	"components.fuwu.3917n5": "Le système de Trading quantitatif intelligent utilise l'intelligence artificielle pour collecter régulièrement et quantitativement des données de l'ensemble du réseau via une approche basée sur le cloud. Il passe par",
	"components.fuwu.540486": "Intégration intégration transparente avec les systèmes quantitatifs d'intelligence artificielle. Le système effectue ensuite le filtrage, le filtrage, l'analyse et la modélisation pour fournir des informations visuelles au personnel back - end et un support de données pour l'intelligence artificielle.",
	"components.fuwu.i576fn": "Membres de l'équipe",
	"components.fuwu.8a523u": "Cliff & nbsp; Asness à Eugene & nbsp, économiste renommé et lauréat du prix Nobel d’économie en 2013; Titulaire d'un double baccalauréat de la Wharton School et de la School of Engineering and Applied Sciences de l'Université de Pennsylvanie, sous la direction du professeur Fama.",
	"components.fuwu.clunb8": "Capital Management est l'un des hedge funds quantitatifs les plus connus de Wall Street. Il a été",
	"components.fuwu.w47xsg": "Goldman, & nbsp; Sachs & nbsp; & & nbsp; Nbsp; Directeur général et Directeur de la recherche quantitative chez Co. Asset management. Il est le & nbsp; Journal et nbsp; De & nbsp; Portfolio & nbsp; Membre du Comité de rédaction de Management, membre du Comité de direction de la New York University School of Mathematical Finance, membre du Conseil d’administration de Q - Group, membre du Conseil d’administration de l’international Rescue Committee et de The & nbsp; Membre du Conseil d'administration de NTN",
	"components.fuwu.8v041z": "David",
	"components.fuwu.1yi18o": "& quot; 183; Chef fondateur de kabyle",
	"components.fuwu.f126cm": "Années d’expérience en investissement financier,",
	"components.fuwu.551s9t": "Titulaire d'un MBA, B.A., Northwestern University, Vice - Président de Goldman Sachs, Sachs Corporation",
	"components.fuwu.638xk0": "EPOCH EPT par John Liu",
	"components.fuwu.2d27j5": "Principalement",
	"components.fuwu.891s7f": "Années d'expérience en investissement financier doctorat, MBA, Université de Chicago Baccalauréat ès arts de l'Université de Chicago il a obtenu un baccalauréat en économie de l'Université de Chicago, où il a été sélectionné pour",
	"components.fuwu.khhc66": "Membre et continue d'obtenir à Chicago",
	"components.fuwu.qad15u": "Et un doctorat en finance.",
	"components.fuwu.ummboy": "Paul",
	"components.fuwu.f1uc41": "& quot; 183; Jacobs",
	"components.fuwu.7du1a3": "Années d'expérience en développement technologique ce responsable technique Epoch EPT a travaillé chez Google en tant qu'ingénieur logiciel et possède l'Indian Institute of Technology.",
	"components.fuwu.7964ma": "Baccalauréat de la branche, maîtrise de Stanford et Wharton School de l'Université de Pennsylvanie",
	"components.fuwu.7m912c": "Degré. Responsable",
	"components.fuwu.nj2151": ", Google toolbar, et a participé au développement du système d'exploitation de Google, les individus ont également travaillé comme Google",
	"components.fuwu.itkrrj": "Président, familier avec cette application.",
	"components.fuwu.c7gc6f": "Michel",
	"components.fuwu.27102u": "& quot; 183; Agassi main 25 ans d'expérience mit PhD Brown University Bachelor",
	"components.fuwu.5549r8": "Position:",
	"components.fuwu.qtx3m3": "EPOCH & nbsp; EPT & nbsp; 3 - 5 jours quantificateur d'IA à court terme",
	"components.fuwu.xj5p65": "Citation célèbre: les marchés changent rapidement, saisissez les opportunités à court terme et avancez rapidement.",
	"components.fuwu.nbk6k7": "Gregor",
	"components.fuwu.o11m0w": "& quot; 183; M & quot; 183; Andrade main 21 ans d'expérience Université de Chicago doctorat Massachusetts Institute of Technology",
	"components.fuwu.58snmu": "EPOCH & nbsp; EPT & nbsp; 5 - 10 jours quantificateur d'IA à court terme",
	"components.fuwu.sdve3x": "Citation célèbre: une expérience suffisante du marché s'associe à jamais",
	"components.fuwu.3no8us": "Intelligence artificielle, les gains sont si simples.",
	"components.fuwu.8ru302": "Bradley",
	"components.fuwu.6zk9gb": "& quot; 183; D) 183; Asnis main 25 ans d'expérience New York Law School JD Brandeis University Bachelor of Arts",
	"components.fuwu.748c4r": "EPOCH & nbsp; EPT & nbsp; 15 - 20 jours quantificateur d'IA à court terme",
	"components.fuwu.ykoem3": "Citation célèbre: beaucoup de temps apporte de meilleures opportunités et des gains plus stables.",
	"components.fuwu.c8741k": "Jeffrey",
	"components.fuwu.mnj8g5": "& quot; 183; Bolduc major 19 ans d'expérience Chartered Financial Analyst Bachelor of Arts Bates College MBA 5. & nbsp; Université de Chicago;",
	"components.fuwu.pt4258": "EPOCH & nbsp; EPT & nbsp; 30 - 50 jours quantificateur d'IA à court terme",
	"components.fuwu.yhx273": "Citation célèbre: Je suis très optimiste sur les investissements à long terme et stables et rien ne vaut cela.",
	"components.fuwu.l1axrb": "Événements temporels",
	"components.fuwu.425wcf": "/ jalons",
	"components.fuwu.n33tpy": "1998. Création et lancement du premier produit de fonds spéculatifs.",
	"components.fuwu.hch6ip": "2000. La première stratégie d’actions internationales a été lancée et ses rendements ont été salués à l’échelle internationale.",
	"components.fuwu.51k20j": "2016. Ouverture d'un bureau à Hong Kong pour étendre sa présence en Asie.",
	"components.fuwu.e7b8ez": "2016. Quatrième trimestre Epoch EPT inaugure un centre de recherche en intelligence artificielle à Hong Kong",
	"components.fuwu.gk53k8": "2019. Deuxième saison",
	"components.fuwu.51y6x1": "Publication du Livre blanc version 1.0",
	"components.fuwu.7p2h89": "2020. Développement de la blockchain publique Epoch EPT au premier trimestre",
	"components.fuwu.19t2i2": "2020. Epoch EPT construit un Big Data Center au quatrième trimestre",
	"components.fuwu.00bmfi": "2021. Premier trimestre",
	"components.fuwu.812he4": "Le système de Trading quantitatif connecte le Big Data Center Epoch EPT.",
	"components.fuwu.7vb4bx": "2021. Troisième saison",
	"components.fuwu.i5s411": "Test du package de démarrage du système de Trading quantitatif",
	"components.fuwu.4rzg05": "2022. Deuxième saison",
	"components.fuwu.6f1vku": "Le système de Trading quantifié atteint un total",
	"components.fuwu.7j4ksr": "Bénéfice quantitatif de 1,3 milliard de dollars.",
	"components.fuwu.oox35c": ". Birmingham, Royaume - Uni",
	"components.fuwu.t13534": "Enregistré pour être établi",
	"components.fuwu.27p688": "EPOCH & nbsp; EPT et lance le premier système intelligent ai au monde.",
	"components.fuwu.0at9r7": "6. & nbsp; Valeur future du marché dans un avenir proche, il est prévisible que des milliards de personnes stockeront leur richesse sous forme d'actifs numériques. Ces actifs comprennent non seulement les actifs monétaires et physiques traditionnels, mais aussi les actifs numériques virtuels. Les actifs numériques ont de multiples attributs tels que la conservation, la convertibilité et la couverture, ce qui peut atténuer le risque de dépréciation ou de perte causée par une crise financière ou un conflit. Ils fournissent un mécanisme de protection et de protection des biens personnels, permettant une interaction sans restriction entre les différentes périodes et domaines. Comme de plus en plus d'utilisateurs détiennent des actifs numériques, ils recherchent des investissements stables pour ajouter de la valeur aux actifs numériques et faire face aux changements dans l'économie réelle et l'inflation. C'est ça",
	"components.fuwu.r4o4y5": "Le système de Trading quantitatif comme le meilleur endroit pour choisir. Actuellement, plus de",
	"components.fuwu.yt83vn": "300 millions de personnes participent à des activités d'investissement sur le marché de la blockchain, avec des actifs numériques mondiaux évalués à plus de 50 milliards de dollars. Avec plus de 500 millions de dollars de commissions générées par les transactions d'actifs numériques blockchain, le potentiel de marché est énorme. Pour une meilleure conservation de la valeur et de la valeur ajoutée, l'adoption",
	"components.fuwu.74n7mh": "Le système de Trading quantitatif a révolutionné la façon traditionnelle de négocier, permettant à un plus grand nombre d'investisseurs en actifs numériques d'obtenir des rendements et des rendements plus élevés. Il offre également aux investisseurs traditionnels des possibilités de couverture et de croissance de la richesse. Dans le monde de demain, les marchés financiers seront le domaine de la négociation d'actifs numériques, et",
	"components.fuwu.h335ga": "Les systèmes de Trading quantifiés dirigeront le développement de cette ère. Un regard global,",
	"components.fuwu.5173h3": "Le système de Trading quantitatif s'est imposé dans le domaine de l'investissement financier en un minimum de temps. Il crée de nouveaux modèles d'application basés sur l'économie numérique, les actifs numériques et la technologie blockchain.",
	"components.fuwu.jw5ueb": "7. & nbsp; Considérations juridiques À l'heure actuelle, les autorités financières aux États - Unis, à Singapour, en Chine, en Suisse et en Allemagne ont resserré leurs politiques ou émis des avertissements concernant les offres initiales de jetons (ICO), car de plus en plus de cryptomonnaies sont classées comme des titres.",
	"components.fuwu.r3jm21": "Reconnaît et respectera les règlements sur les valeurs mobilières de plusieurs juridictions majeures et",
	"components.fuwu.2a723k": "Les réglementations KYC (know your customer) et AML (anti - Money Laundering). Plus précisément, cela signifie: règles sur les valeurs mobilières: Veuillez lire attentivement le contenu de cette section. Si vous avez des questions sur les mesures à prendre, nous vous recommandons de consulter votre Conseiller juridique, financier, fiscal ou autre professionnel. Les informations présentées dans ce document peuvent ne pas être exhaustives et ne représentent aucun élément essentiel de la relation contractuelle. Ce document ne constitue pas un Conseil en investissement, fiscal, juridique, réglementaire, financier, comptable ou tout autre conseil et ne peut être la seule raison de participer au soutien et au développement de l'écosystème de l'indice constant. Avant de prendre une décision, les acheteurs potentiels doivent consulter leurs conseillers juridiques, d'investissement, fiscaux, comptables et autres pour déterminer les avantages potentiels, les limites et les autres conséquences d'une telle transaction. Aucune partie du présent document n'est une offre, un prospectus ou une sollicitation de propositions et n'est destinée à être une exigence d'investissement sous forme de titres dans aucune juridiction. Ce document n'a pas été rédigé conformément aux lois ou règlements de toute juridiction qui interdit ou restreint les transactions relatives aux jetons numériques ou à leur utilisation. KYC et AML: les détails techniques des réglementations know your customer (KYC) et anti - blanchiment d’argent (AML) peuvent varier d’un pays à l’autre, mais elles nécessitent toutes une vérification et une vérification croisée de l’identité du client par rapport à diverses listes de terroristes, d’embargos et de personnes politiquement exposées (PEP).",
	"components.fuwu.n5ijvs": "Ce processus est mis en œuvre en collaboration avec des fournisseurs de services professionnels. Dans le processus d'investissement de masse, l'investisseur Kate",
	"components.fuwu.y06wpn": "8. & nbsp; Disclaimer ce document ne traite pas des produits réglementés dans la juridiction légale. Ce document est un document conceptuel (Livre blanc) pour la description du projet et est fourni à titre informatif uniquement et n'est pas destiné à la vente ou à la sollicitation d'actions, de titres ou de tout autre",
	"components.fuwu.5t61y5": "Produits liés et produits réglementés des sociétés affiliées. Le présent document ne doit pas être considéré comme un prospectus ou un document normalisé de conformité et ne constitue pas un Conseil en placement ou une sollicitation en faveur de titres ou de tout autre produit réglementé dans une juridiction quelconque. Ce document ne doit pas être utilisé comme une recommandation de vente, de souscription ou d'invitation à acheter ou à souscrire des titres ou tout contrat, contact ou engagement basé sur eux. Il ne doit pas être considéré comme une recommandation de participation à l'investissement. Toute information ou analyse fournie dans ce document ne constitue pas une recommandation pour participer à un investissement en jetons et aucune recommandation spécifique ne sera faite. Vous devez demander tous les conseils professionnels nécessaires sur des questions connexes, telles que l'examen fiscal et comptable. Ce document ne constitue aucune déclaration ou garantie. Pour expliquer ce que nous proposons",
	"components.fuwu.j73g2y": "- & nbsp; Al système de Trading quantitatif, mais",
	"components.fuwu.3ip5we": "La Fondation indique clairement ce qui suit:",
	"components.fuwu.1x311y": "8.1 Aucune déclaration ou garantie n’est donnée quant à l’exactitude ou à l’exhaustivité de tout contenu décrit dans le présent document ou de tout autre contenu publié dans le cadre du projet.",
	"components.fuwu.0c5427": "8.2 aucune déclaration ou garantie prospective ou conceptuelle concernant les réalisations ou la faisabilité n'est fournie sans conditions préalables.",
	"components.fuwu.h6u91t": "8.3 rien dans ce document ne sert de base à un engagement ou une déclaration pour l'avenir.",
	"components.fuwu.if1i12": "8.4 La société décline toute responsabilité pour toute perte subie par une personne en raison de ce livre blanc ou autrement.",
	"components.fuwu.m21co1": "8.5 La responsabilité est limitée dans toute la mesure permise par la loi applicable, dans les limites de la responsabilité légale inévitable. Risques associés aux politiques de réglementation régionales et nationales pertinentes: la technologie blockchain est soigneusement examinée par divers organismes de réglementation tout en étant soutenue et reconnue dans le monde entier.",
	"components.fuwu.t5i928": "La fonctionnalité des systèmes de négociation quantifiés peut être affectée par certaines politiques réglementaires, y compris, mais sans s'y limiter, la limitation de l'utilisation ou de la détention",
	"components.fuwu.77k38h": "Usdt, qui peut entraver ou limiter",
	"components.fuwu.sxf326": "Développement de systèmes de Trading quantifiés. Autres risques inconnus",
	"components.fuwu.t62x6w": ": la technologie blockchain et sa contrepartie, la technologie de la monnaie numérique, sont des technologies relativement nouvelles et non suffisamment éprouvées qui peuvent présenter des risques imprévus de diverses manières.",
	"login.register.6l6651": "Inscription par téléphone",
	"login.register.bsu16p": "Confirmer le mot de passe",
	"login.register.8r578v": "Le mot de passe entré deux fois est différent",
	"components.xieyi.z4tui6": "Vous êtes invités à utiliser",
	"components.xieyi.985i12": "Ltd. (ci - après abrégé)",
	"components.xieyi.k71g1d": "Nous",
	"components.xieyi.6473ug": "'Ou' plateforme 'de produits et services! Afin de vous fournir des services techniques dans le cadre de la plateforme, nous traitons vos informations personnelles dans le cadre de vos opérations. Conscients de l’importance des informations personnelles pour vous et de l’importance de votre confiance pour nous, nous nous engageons à protéger la sécurité de vos informations personnelles en prenant les mesures de sécurité appropriées dans les conditions techniques en vigueur et en stricte conformité avec les exigences légales et réglementaires. Sur cette base, nous avons développé cette politique de confidentialité (ci - après la « politique») pour vous aider à comprendre pleinement comment nous recueillons, utilisons, partageons, stockons et protégeons vos informations personnelles dans le cadre de votre utilisation de nos produits et services et comment vous pouvez gérer vos informations personnelles afin que vous puissiez mieux faire Les choix appropriés.",
	"components.xieyi.r997hr": "Avant de commencer à utiliser nos produits et services, il est important que vous lisiez et compreniez attentivement cette politique, en mettant l'accent sur notre",
	"components.xieyi.23i3k9": "Gras / souligné en gras",
	"components.xieyi.5jo3lp": "Les termes du logo, assurez - vous que vous comprenez et acceptez pleinement avant de commencer à les utiliser. Le vocabulaire professionnel impliqué dans cette politique, nous essayons de vous l'expliquer en termes simples et simples pour faciliter votre compréhension.",
	"components.xieyi.sw2x3b": "Pour toute question, commentaire ou suggestion concernant le contenu de cette politique, vous pouvez",
	"components.xieyi.w1nc5l": "Le service client officiel de la plateforme nous contacte.",
	"components.xieyi.u855jt": "Votre consentement à la politique de confidentialité représente uniquement le fait que vous avez pris connaissance des fonctionnalités offertes par l’application et des informations personnelles nécessaires au fonctionnement de la fonctionnalité, et ne signifie pas que vous avez consenti à ce que nous collections des informations personnelles non essentielles pour lesquelles votre consentement est sollicité séparément en fonction de votre autorisation au cours de votre utilisation.",
	"components.xieyi.jiyuvt": "Si vous refusez cette politique de confidentialité, vous pourrez toujours utiliser les fonctionnalités de base de navigation sans inscription / connexion. Vous pouvez également l'autoriser séparément lors d'une utilisation ultérieure des services.",
	"components.xieyi.61890q": "Cette politique vous aidera à comprendre ce qui suit:",
	"components.xieyi.5mt6j8": "I. définitions et champ d'application",
	"components.xieyi.41xpk4": "II. Comment nous collectons et utilisons vos informations",
	"components.xieyi.83j8rl": "Iii. Comment nous utilisons les cookies et les technologies similaires",
	"components.xieyi.8b639p": "Iv. Comment nous partageons, transférons et divulguons publiquement vos informations",
	"components.xieyi.6dq28b": "V. comment nous protégeons vos informations",
	"components.xieyi.2ulrqx": "Vi. Comment nous stockons vos informations",
	"components.xieyi.3nz3ga": "Vii. Comment vous gérez vos informations",
	"components.xieyi.9s39yt": "Viii. Comment nous traitons les informations des mineurs",
	"components.xieyi.ew14nj": "Ix. Comment cette politique est mise à jour",
	"components.xieyi.12eq82": "X. comment nous contacter",
	"components.xieyi.b1sb46": "I) Définitions",
	"components.xieyi.we1z6j": "Fait référence à Birmingham, Royaume - Uni",
	"components.xieyi.rw8pei": "Enregistrement Epoch EPT a",
	"components.xieyi.idu1sg": "Limitée à la société.",
	"components.xieyi.m568up": "La plateforme:",
	"components.xieyi.hvxy61": "Se référant à",
	"components.xieyi.73ijj4": "Site Web (nom de domaine www.weipaitang.com), client app (",
	"components.xieyi.h0re24": "), ainsi que des médias de service tels que le numéro public Wechat, également connu sous le nom de «",
	"components.xieyi.ivy1b3": "« la plateforme ».",
	"components.xieyi.ki07fh": "Sociétés affiliées:",
	"components.xieyi.6c317m": "Se réfère à et",
	"components.xieyi.wr8y6r": "Entreprises ayant entre elles une relation de contrôle direct ou indirect ou une relation d'influence significative, y compris la détention",
	"components.xieyi.5865bv": "Actionnaires de plus de 5%,",
	"components.xieyi.hty2r8": "Les filiales,",
	"components.xieyi.36ty5w": "Autres entreprises contrôlées par le Contrôleur effectif, etc.",
	"components.xieyi.lsnc7x": "Société de paiement",
	"components.xieyi.47x242": ": signifie pour vous dans",
	"components.xieyi.gmmfq4": "Shopping for the platform Établissement de paiement offrant des services de paiement.",
	"components.xieyi.k10tpg": "Renseignements personnels:",
	"components.xieyi.69e87n": "Désigne toutes sortes d'informations enregistrées électroniquement ou autrement concernant une personne physique identifiée ou identifiable, à l'exclusion des informations traitées de manière anonyme.",
	"components.xieyi.o1m0al": "Informations personnelles sensibles:",
	"components.xieyi.2h5e47": "Désigne les renseignements personnels qui, s’ils sont divulgués ou utilisés illégalement, risquent de porter atteinte à la dignité humaine d’une personne physique ou de compromettre la sécurité de sa personne ou de ses biens, y compris:",
	"components.xieyi.2ipl66": "Biométrie, croyances religieuses, identités spécifiques, santé médicale, comptes financiers, traces de localisation",
	"components.xieyi.8122dw": "Des informations telles que, et",
	"components.xieyi.124tei": "Informations personnelles des mineurs de moins de quatorze ans",
	"components.xieyi.swn32o": "(nous traiterons les informations personnelles sensibles spécifiques dans cette politique pour",
	"components.xieyi.704x6v": "En gras",
	"components.xieyi.274q3q": "Effectuer une identification significative).",
	"components.xieyi.u2twjv": "Traitement des informations personnelles:",
	"components.xieyi.9813ba": "Cela inclut la collecte, le stockage, l'utilisation, le traitement, le transfert, la mise à disposition, la divulgation, la suppression, etc. des informations personnelles.",
	"components.xieyi.rutsx1": "Enfants",
	"components.xieyi.81twf5": ": désigne les mineurs de moins de 14 ans.",
	"components.xieyi.5jsxyn": "Sauf convention contraire, les définitions utilisées dans cette politique sont",
	"components.xieyi.hu1431": "Accord d'utilisateur des services de transaction",
	"components.xieyi.f61m93": "Les définitions dans ont la même signification.",
	"components.xieyi.4kd4rv": "Ii) Champ d'application",
	"components.xieyi.s893e8": "Cette politique s'applique à",
	"components.xieyi.p95qjo": "Avec",
	"components.xieyi.1d536g": "Les sites Web, les clients, les applets, les numéros publics et les produits et services qui vous sont proposés au fur et à mesure de l'évolution technologique.",
	"components.xieyi.d02u4n": "Si utilisé dans nos produits ou services et ceux de nos sociétés affiliées",
	"components.xieyi.vd5142": "Produits ou services (p. ex. utilisation",
	"components.xieyi.2ifgyw": "Connexion au compte de la plateforme), mais sans politique de confidentialité distincte, cette politique s'applique également à cette partie des produits ou services. Si nous et nos sociétés affiliées avons des politiques de confidentialité distinctes en ce qui concerne les produits ou services qu'ils vous fournissent, les politiques de confidentialité correspondantes s'appliquent aux produits ou services concernés.",
	"components.xieyi.lzne8r": "En plus des activités connexes de collecte d’informations et d’utilisation décrites dans la présente politique,",
	"components.xieyi.8o7eza": "Cette politique ne s'applique pas aux",
	"components.xieyi.c7i3mg": "Autres services que les vendeurs de la plateforme et d’autres tiers vous fournissent, d’autres services s’appliquent aux règles d’utilisation de la collecte de renseignements personnels telles que leurs politiques de confidentialité qui vous sont autrement indiquées",
	"components.xieyi.f4n8lb": "- Oui. Par exemple, lorsque vous participez",
	"components.xieyi.51p7u0": "Lorsque Epoch EPT fonctionne",
	"components.xieyi.9ngrl3": ", sur la base des exigences légales et réglementaires pour vous informer de ses règles de collecte et d’application de vos informations personnelles.",
	"components.xieyi.62e3v6": "Conformément au règlement sur la portée des renseignements personnels nécessaires pour les types courants d’applications Internet mobiles,",
	"components.xieyi.3n4mh2": "L'APP appartient à 'Web",
	"components.xieyi.8q81vo": "Gestion des finances",
	"components.xieyi.57m6k2": "« les services fonctionnels de base sont »",
	"components.xieyi.6891w4": "Intelligence artificielle",
	"components.xieyi.s2ji36": "Les informations personnelles nécessaires dans le cadre de cette fonction comprennent: votre numéro de téléphone; Nom (prénom), adresse, numéro de téléphone du destinataire; Informations de paiement telles que le moment du paiement, le montant du paiement, les canaux de paiement, etc.",
	"components.xieyi.wyy6r5": "Pour vous fournir des services de plateforme de commerce électronique pratiques, nous traitons vos informations personnelles séparément en fonction des différentes fonctionnalités de la plateforme:",
	"components.xieyi.t1c3k8": "Fonctions essentielles: afin de vous fournir les fonctions essentielles de nos produits et / ou services, vous devez nous autoriser à collecter et utiliser les informations nécessaires. Si vous refusez de fournir les informations correspondantes, sinon vous ne pourrez pas utiliser nos produits et / ou services correctement",
	"components.xieyi.y7m0r2": "Fonctionnalités supplémentaires: afin de mettre en œuvre des fonctionnalités supplémentaires pour vous fournir nos produits et / ou services, vous pouvez choisir d'autoriser ou non les informations que nous collectons et utilisons. Si vous refusez de le fournir, vous ne serez pas en mesure d'utiliser correctement les fonctionnalités supplémentaires concernées ou d'obtenir les effets fonctionnels que nous avons l'intention d'atteindre.",
	"components.xieyi.cwp3h6": "Nous vous rappelons en particulier:",
	"components.xieyi.63078v": "1, nous nous engageons à créer une variété de produits et de services pour répondre à vos besoins.",
	"components.xieyi.11t174": "En raison de la grande variété de produits et de services que nous vous fournissons et des différences dans la gamme de produits / services spécifiques que les utilisateurs choisissent d'utiliser, les fonctionnalités de base / supplémentaires et le type, la portée, etc. des informations personnelles collectées seront différents, selon la fonctionnalité du produit / service spécifique.",
	"components.xieyi.654y1j": "Afin de vous offrir une meilleure expérience avec nos produits et services, nous nous efforçons continuellement d'améliorer nos produits, services et technologies, ce qui nous permet d'introduire de nouvelles fonctionnalités ou des fonctionnalités optimisées de temps à autre, qui peuvent nécessiter la collecte, l'utilisation de nouvelles informations personnelles ou des changements dans les fins ou la manière dont les informations personnelles sont utilisées. Si cela se produit, nous vous fournirons des détails supplémentaires en mettant à jour cette politique, des fenêtres contextuelles, des conseils de page, etc., et nous vous fournirons des moyens de choisir librement votre consentement avant de commencer à le collecter et à l'utiliser avec votre consentement exprès.",
	"components.xieyi.228036": "Au cours de ce processus, si vous avez des questions, des commentaires ou des suggestions, vous pouvez nous contacter via les canaux indiqués dans la présente politique et nous vous répondrons dans les meilleurs délais.",
	"components.xieyi.3l69jv": "Les scénarios de fonctionnalités spécifiques que nous vous fournirons incluent:",
	"components.xieyi.xq77st": "(i) Enregistrement des utilisateurs et gestion des comptes",
	"components.xieyi.hgd0m6": "1. Service utilisateur de base",
	"components.xieyi.sy4rg3": "Nous sommes basés sur",
	"components.xieyi.3768od": "Un compte de plateforme vous fournit des services. Pour créer",
	"components.xieyi.k5pm58": "Compte de plateforme vous devez nous fournir au moins votre",
	"components.xieyi.ijyhl0": "Numéro de téléphone mobile, nom d'utilisateur et mot de passe à utiliser",
	"components.xieyi.k04bce": "- Oui. Si vous refusez de fournir les informations ci - dessus, vous ne pourrez pas vous inscrire",
	"components.xieyi.4b0us6": "Un compte de plateforme, mais vous pouvez toujours utiliser les services de navigation et de recherche.",
	"components.xieyi.3k9d16": "Vous pouvez modifier et compléter votre pseudonyme, votre sexe, votre région, vos contacts, ainsi que les informations relatives à l'authentification de votre vrai nom, qui appartiennent toutes à vos « informations de compte».",
	"components.xieyi.rqik5d": "Parmi eux, le pseudonyme et l'Avatar que vous avez définis seront affichés publiquement.",
	"components.xieyi.ilmlc1": "Vos informations de compte supplémentaires nous aideront à vous fournir des recommandations personnalisées d'articles et une meilleure expérience d'achat, mais si vous ne fournissez pas ces informations supplémentaires, cela n'affectera pas votre utilisation des fonctionnalités de base des achats en ligne.",
	"components.xieyi.2d8htr": "Pour nous assurer que nous vous fournissons des services, nous pouvons vérifier votre identité sur la base des informations que vous avez fournies ci - dessus. Lorsqu'il existe des scénarios dans lesquels il est légalement nécessaire de déterminer votre identité d'utilisateur (y compris la retenue d'impôts pour vous en vertu de la loi, l'application de la loi administrative ou la détermination du sujet concerné dans une procédure judiciaire, etc.), vous nous autorisez à obtenir vos informations d'authentification pertinentes auprès de nos partenaires aux fins décrites ci - dessus.",
	"components.xieyi.qimk2p": "Nous nous baserons sur votre",
	"components.xieyi.z57g36": "L'utilisation du compte de la plateforme et les règles de calcul du niveau de compte définies par la plateforme déterminent votre niveau actuel et vous fournissent les avantages de base correspondant au niveau de compte correspondant.",
	"components.xieyi.21n38j": "2. Services supplémentaires",
	"components.xieyi.dh7o8w": "Certification de personne réelle: Vous pouvez fournir la certification correspondante selon les exigences de certification",
	"components.xieyi.57s046": "Informations d'identification (carte d'identité, passeport, livret de famille et autres informations sur les documents d'identité), caractéristiques biométriques (caractéristiques faciales statiques ou dynamiques)",
	"components.xieyi.eb3ena": "Pour compléter la certification de personne réelle.",
	"components.xieyi.2fnv9d": "Connexion autorisée: Nous pouvons partager les informations de votre compte (Avatar, pseudonyme et autres informations demandées par la page) avec des tiers, avec votre consentement, afin de faciliter l'enregistrement de votre compte tiers ou la connexion directe à un tiers. En outre, nous pouvons obtenir des informations sur votre compte tiers auprès de tiers tels que Wechat avec votre autorisation et",
	"components.xieyi.2586kj": "Les comptes de la plateforme sont liés afin que vous puissiez vous connecter et utiliser nos produits et / ou services directement auprès de tiers. Nous utiliserons vos informations pertinentes dans la mesure où vous avez autorisé votre consentement.",
	"components.xieyi.75o8vj": "Autres services de rôle d'utilisateur: Si vous postulez pour vous inscrire en tant que vendeur ou pour d'autres rôles d'utilisateur pour lesquels des exigences d'identification supplémentaires existent, vous devez nous fournir activement des informations d'identification et / ou des informations relatives à l'entreprise pour vérifier votre identité et l'éligibilité d'autres rôles d'utilisateur, pour l'enregistrement, la publicité et à d'autres fins que nous vous informons expressément. Comme »",
	"components.xieyi.ni3907": "Autorisation financière",
	"components.xieyi.1168tn": "Vous consentez à ce que nous recueillions votre numéro de téléphone mobile, votre numéro d'identification à des fins de gestion de la dénomination réelle du compte en direct, ainsi que les identifiants de l'appareil, le modèle de marque de l'appareil, le système d'exploitation de l'appareil, la géolocalisation à des fins de correspondance des fonctionnalités en direct et de contrôle de la sécurité en direct.",
	"components.xieyi.k2wd14": "3, authentification",
	"components.xieyi.1ns543": "Afin de satisfaire aux exigences légales et réglementaires pertinentes, d’assurer l’authenticité de l’identité des utilisateurs (y compris la protection des droits et intérêts des mineurs conformément à la loi, la lutte contre la fraude sur les réseaux de télécommunications, la retenue de l’impôt sur le revenu des particuliers, l’application de la loi administrative ou l’identification des sujets concernés dans Les procédures judiciaires), de contrôler les risques tels que la lutte antifraude, de protéger la sécurité des systèmes et des services, nous devons collecter des informations vous concernant à des fins d’authentification lorsque vous utilisez des services / fonctionnalités spécifiques, Par exemple: [modifier le numéro de téléphone mobile] lorsque vous utilisez la fonction « modifier le numéro de téléphone mobile » pour récupérer votre numéro de compte, nous pouvons collecter des informations vous identifiant, y compris votre",
	"components.xieyi.3lj887": "Nom réel, numéro de pièce d'identité, informations de reconnaissance faciale, informations de commande",
	"components.xieyi.7y2f63": "Pour confirmer votre identité et vous aider à récupérer votre compte de plateforme.",
	"components.xieyi.y57u7o": "Utilisateurs professionnels nous pouvons collecter des informations d'identification juridique vous concernant, y compris",
	"components.xieyi.82yye6": "Informations de certificat d'entreprise, nom réel de la personne morale de l'entreprise, numéro de document d'identité, informations de reconnaissance faciale",
	"components.xieyi.t6tsvp": "Si vous avez déjà",
	"components.xieyi.3coa6i": "Compte de plateforme, nous pouvons être en",
	"components.xieyi.4g34dh": "L’affichage de vos informations personnelles ci - dessus dans les services de la plateforme, ainsi que votre",
	"components.xieyi.i3lx1u": "Sur la plateforme ou avec",
	"components.xieyi.cd0jo8": "Actions effectuées dans les produits et services associés au compte de la plateforme, y compris par",
	"components.xieyi.f5dq1g": "Plateforme compte affichage centralisé de votre profil, avantages préférentiels, ordres de Trading",
	"components.xieyi.vo3428": "- Oui. Nous respecterons votre",
	"components.xieyi.y3j2fo": "Choix effectués par les paramètres du compte de la plateforme.",
	"components.xieyi.pw3g77": "(II) vous montrer des biens, des informations de service et des messages push",
	"components.xieyi.82aac2": "Pour vous montrer des informations sur les biens ou services, y compris votre empreinte de visite, votre historique de recherche, nous collectons et utilisons les informations que vous utilisez lors de votre visite ou de votre utilisation.",
	"components.xieyi.h4s010": "Navigation, enregistrements de recherche pendant la plate - forme. Nous utilisons des modèles algorithmiques pour prédire les caractéristiques de vos préférences en combinaison avec les informations sur les appareils, les informations sur les journaux de service et d'autres informations que nous avons collectées conformément à la loi. Nous pouvons vous envoyer des publicités commerciales et d'autres informations susceptibles de vous intéresser ou vous envoyer des messages commerciaux courts en fonction de vos préférences.",
	"components.xieyi.kf81h2": "Les algorithmes de classe de recommandation et de récupération personnalisés prédisent les caractéristiques de vos préférences en fonction du modèle, correspondent aux biens, services ou autres informations susceptibles de vous intéresser, trient les biens, services ou autres informations qui vous sont présentés. Nous adaptons en permanence les résultats des recommandations optimisées en fonction de votre comportement de navigation lors de l'utilisation du produit, avec un retour en temps réel sur le modèle de recommandation. Pour répondre à vos besoins multiples, nous introduisons des techniques de recommandation diversifiées dans le processus de tri, étendons le contenu recommandé et évitons une concentration excessive du même type de contenu.",
	"components.xieyi.182j2p": "Informations sur l'appareil: Nous recevons et enregistrons les informations relatives à l'appareil que vous utilisez (y compris le modèle de l'appareil, la version du système d'exploitation, les paramètres de l'appareil, l'adresse MAC et IMEI, idfa, oaid, Android) en fonction de vos actions spécifiques lors de l'accès et / ou de l'utilisation.",
	"components.xieyi.1ns292": "Identificateur de périphérique ID, environnement de périphérique, liste d'applications mobiles informations sur les caractéristiques logicielles et matérielles), informations relatives à l'emplacement de l'appareil (y compris l'emplacement GPS que vous avez autorisé et les informations sur le point d'accès WLAN, le Bluetooth et le capteur de la station de base).",
	"components.xieyi.y2hur5": "Informations de journal de service: lorsque vous accédez ou utilisez",
	"components.xieyi.2l7v45": "Lorsque vous utilisez la plate - forme, nous collectons et enregistrons automatiquement les détails de votre utilisation, conservés sous forme de journaux de service, y compris les enregistrements de navigation, les enregistrements de clics, les enregistrements de requêtes de recherche, les commentaires, les rapports, les transactions, le service après - vente, les informations partagées, les informations publiées, ainsi que l'adresse IP, le type de navigateur, l'opérateur de télécommunications, la langue d'utilisation, la date et l'heure d'accès.",
	"components.xieyi.i2q27g": "Veuillez noter que les informations séparées sur l'appareil, les informations de journal de service sont des informations qui ne permettent pas d'identifier une personne physique spécifique.",
	"components.xieyi.48iqgk": "Si nous combinons ces informations non personnelles avec d'autres informations pour identifier une personne physique spécifique, ou si nous les combinons avec des informations personnelles, ces informations non personnelles seront considérées comme des informations personnelles pendant la durée de l'utilisation combinée et, sauf si nous avons votre autorisation ou si la loi ou la réglementation en dispose autrement, Nous les traiterons de manière anonyme et dépersonnalisée.",
	"components.xieyi.2e8s23": "Dans le même temps, nous nous efforçons de protéger votre expérience de confidentialité en prenant les mesures suivantes:",
	"components.xieyi.83qc71": "(1) Si vous souhaitez supprimer vos enregistrements de navigation et de recherche, vous pouvez choisir de le faire dans « mes - empreintes », « zone de recherche - recherches récentes »;",
	"components.xieyi.72qs2r": "(2) Si vous avez besoin de voir des résultats de recherche qui ne sont pas spécifiques à vos caractéristiques personnelles lorsque vous utilisez nos services de recherche sur le site, vous pouvez les configurer sur la page de résultats de recherche en cliquant sur « filtrer»;",
	"components.xieyi.35ps8a": "(3) Si vous souhaitez gérer les publicités personnalisées que nous vous envoyons, vous pouvez le faire en",
	"components.xieyi.777kl1": "My - Paramètres (en haut à droite) - confidentialité - Personnalisation '",
	"components.xieyi.8026t2": "Faire des réglages. Il est important de noter que vous pouvez configurer que nous n'utilisons pas certaines catégories d'informations pour vous montrer des publicités pertinentes, mais vous verrez toujours des publicités, le nombre de publicités que vous voyez ne changera pas, sauf que la pertinence des publicités diminuera.",
	"components.xieyi.5hx7h7": "Si vous souhaitez gérer le contenu personnalisé que nous vous envoyons, vous pouvez le faire en »",
	"components.xieyi.5vxbqy": "My - Paramètres (coin supérieur droit) - confidentialité - personnalisation",
	"components.xieyi.8wfq82": "« faire les réglages. Le contenu du module de recommandation après la fermeture associé 'recommander, recommander pour vous', etc. est une recommandation aléatoire Recommandation non personnalisée.",
	"components.xieyi.f98v89": "(4) Si vous souhaitez supprimer l'étiquette de loisirs avec vos caractéristiques personnelles, peut passer par",
	"components.xieyi.3ve6e7": "(5) Si vous n'êtes pas intéressé par les articles affichés sur la page d'accueil, vous pouvez choisir 'lot ne vous intéresse pas' en appuyant longuement sur l'image des articles recommandés, en fonction de la fenêtre contextuelle d'invite, nous réduirons l'affichage des articles connexes.",
	"components.xieyi.6mlx8c": "2, push d'information commerciale et service de rappel d'activité",
	"components.xieyi.1y06bp": "Pour vous permettre de mieux comprendre et utiliser",
	"components.xieyi.t45916": ", vous consentez à ce que nous vous envoyions des informations telles que des informations commerciales et d'autres rappels d'événements,",
	"components.xieyi.q2y74e": "Ses fournisseurs de services collecteront, utiliseront votre nom et vos coordonnées aux fins décrites ci - dessus.",
	"components.xieyi.84b8nh": "Si vous ne souhaitez pas recevoir de messages Push de notre part,",
	"components.xieyi.eh6abn": "Vous pouvez le désactiver via 'mes - Paramètres (en haut à droite) - Paramètres des messages'.",
	"components.xieyi.3drpe9": "Si vous ne souhaitez pas recevoir de publicité commerciale de notre part,",
	"components.xieyi.16389e": "Vous pouvez vous désabonner ou fermer en répondant à un message texte pour vous désabonner ou par tout autre moyen que nous fournissons;",
	"components.xieyi.i69xy9": "Si vous désactivez ou désactivez la fonction Push, vous ne serez pas en mesure d'obtenir les services push pour les informations sur les biens ou les événements.",
	"components.xieyi.2s65pg": "3, informations de capteur.",
	"components.xieyi.vn4s8x": "Lorsque vous utilisez nos produits / services, nous pouvons collecter des informations sur vos capteurs pour nous adapter à l'état de votre appareil ou pour identifier vos actions lorsque vous choisissez de participer à un événement, les types de capteurs peuvent ne pas être cohérents selon les scénarios et nous ne les utiliserons que dans les situations où cela est spécifiquement nécessaire. Par exemple, lors de la prise de vue, pour savoir si l'utilisateur tourne l'écran lors de la prise de vue, il est pratique d'optimiser la correction de la vidéo capturée.",
	"components.xieyi.opxu33": "(III) vous fournir des fonctionnalités de regard, d'attention et de partage",
	"components.xieyi.46wa51": "Dans votre navigation",
	"components.xieyi.sm4d35": "Au cours de la plate - forme, vous pouvez choisir de consulter les biens, services et / ou autres utilisateurs qui vous intéressent, de partager des informations sur les biens / services avec des tiers via les composants fonctionnels que nous fournissons. Dans le cadre de votre utilisation des fonctionnalités ci - dessus, nous collectons des informations qui incluent votre",
	"components.xieyi.qt6mj3": "Opérations",
	"components.xieyi.k465t1": "Enregistrement,",
	"components.xieyi.13pdf3": "Coopération",
	"components.xieyi.j6413h": "Les informations du Journal de service, y compris les relations, l'historique de partage, sont utilisées pour remplir les fonctions décrites ci - dessus et à d'autres fins que nous avons expressément indiquées.",
	"components.xieyi.g5yo4i": "Vous pouvez supprimer vos messages de commentaires et de suivi dans « mon - regard », « mon - regard ».",
	"components.xieyi.es68il": "(IV) vous aider à passer des commandes et à les gérer",
	"components.xieyi.439hc7": "Lorsque vous commandez des produits et / ou des services spécifiques dans l'un de nos produits et / ou services, nous générons une commande via le système pour vous d'acheter ces produits et / ou services. Lors du processus de commande, vous devez fournir au moins votre",
	"components.xieyi.4zfg7p": "Nom du destinataire, adresse de réception, numéro de contact du destinataire",
	"components.xieyi.875811": "Pour certains types particuliers de biens et de services, vous devez également fournir d'autres informations nécessaires à la fourniture de ces biens ou services, par exemple en ce qui concerne",
	"components.xieyi.sg3r7o": "Vous devrez fournir votre vrai nom, votre numéro d'identification lorsque vous effectuez une transaction d'artefacts pour remplir les documents requis par les lois et règlements relatifs à la protection des artefacts et les autorités de surveillance et d'administration des artefacts.",
	"components.xieyi.x52n3c": "Cette commande contient également des informations sur les produits et / ou services que vous avez achetés, le numéro d'article spécifique, le montant que vous devez payer. Nous collectons ces informations pour vous aider à effectuer vos transactions, pour sécuriser vos transactions, pour faciliter vos demandes de renseignements sur les commandes, pour fournir un service à la clientèle et un service après - vente et à d'autres fins que nous avons clairement indiquées.",
	"components.xieyi.633jos": "Vous pouvez passer par",
	"components.xieyi.s43d5n": "La plate - forme commande des biens et / ou des services pour d'autres personnes et vous devez fournir les informations personnelles susmentionnées sur ce destinataire réel et vous assurer que le consentement de ce destinataire réel a été obtenu.",
	"components.xieyi.q3y8ui": "Pour vous permettre de comprendre, de consulter et de gérer vos informations de commande, nous collectons les informations de commande générées par votre utilisation de nos services afin de vous présenter et de faciliter la gestion de votre commande.",
	"components.xieyi.prc15q": "Vous pouvez consulter et gérer vos informations de commande via « mes commandes ».",
	"components.xieyi.ncd26i": "(v) vous aider à finaliser le paiement",
	"components.xieyi.3u52sy": "Pour finaliser le paiement de votre commande, vous devez sélectionner le mode de paiement et le canal de paiement au moment du paiement. Nous pouvons partager vos informations personnelles, les informations relatives au paiement de la commande, les informations relatives à l'équipement de sécurité de la commande et d'autres informations nécessaires requises par la loi anti - blanchiment d'argent avec la société de paiement, par exemple via le kit de développement d'outils de la société de paiement (le kit de développement d'outils logiciels est appelé « sdk» dans la présente politique) intégré au site Web ou au client. Si vous choisissez de recevoir des services de paiement d'une autre institution financière, nous partageons également les informations nécessaires à votre paiement par carte bancaire, y compris votre numéro de carte bancaire et sa date d'expiration, avec l'institution financière correspondante de votre choix.",
	"components.xieyi.m65845": "Pour nous permettre d'être informés et de confirmer rapidement l'avancement et le statut de votre paiement et pour vous fournir des services après - vente et de résolution des litiges, vous acceptez que nous puissions recueillir des informations relatives à l'avancement de votre paiement auprès de la personne que vous avez choisie pour effectuer la transaction, de la société de paiement ou d'une autre institution financière de votre choix.",
	"components.xieyi.bydbtx": "Vous pouvez voir votre",
	"components.xieyi.rarv1g": "Informations sur le compte bancaire lié au compte portefeuille de la plateforme ou demande de remplacement du compte bancaire lié.",
	"components.xieyi.73148a": "(vi) pour vous aider à effectuer la livraison de biens ou de services",
	"components.xieyi.f8ce6b": "Afin de garantir que les biens et / ou services que vous achetez sont livrés et fournis de manière fluide, sûre et précise, vous reconnaissez et acceptez que vos informations de livraison soient inévitablement portées à la connaissance et utilisées par les prestataires logistiques concernés pour la réalisation des objectifs de livraison.",
	"components.xieyi.wi14jv": "Afin de vous fournir des services après - vente et de résolution des litiges, nous devons être informés et confirmés en temps opportun de l'avancement et du statut de la livraison, et vous acceptez que nous puissions collecter des informations sur l'avancement de la livraison auprès des prestataires de services liés à la logistique.",
	"components.xieyi.h3txyu": "Vii) Service à la clientèle et règlement des différends",
	"components.xieyi.75p62d": "Lorsque vous nous contactez ou faites une demande de règlement des litiges en cours de vente, après - vente ou pour protéger la sécurité de votre compte et de vos systèmes,",
	"components.xieyi.u3u59b": "Nous avons besoin que vous fournissiez les informations personnelles nécessaires pour vérifier votre identité d'utilisateur.",
	"components.xieyi.r9ucq4": "Afin de faciliter la prise de contact avec vous, de vous aider à résoudre votre problème le plus rapidement possible ou de documenter les solutions de traitement et les résultats des problèmes connexes, nous pouvons conserver des enregistrements de vos communications, communications / appels / vidéos et du contenu connexe (y compris les informations de compte, les informations de commande, d'autres informations que vous fournissez pour prouver les faits pertinents, ou les informations de contact que vous avez laissées) avec nous si vous avez des questions, des plaintes ou des suggestions concernant une commande spécifique. Nous utilisons les informations de votre compte et les informations de commande.",
	"components.xieyi.te87ps": "Nous pouvons également utiliser d'autres informations vous concernant, y compris les informations pertinentes que vous fournissez lorsque vous contactez le service client, les informations de réponse au questionnaire que vous nous envoyez lorsque vous participez à un questionnaire, afin de fournir le service et d'améliorer la qualité du service.",
	"components.xieyi.259477": "(VIII) vous fournir des fonctionnalités de publication publique d'informations telles que des commentaires, des questions et des réponses",
	"components.xieyi.67h2ep": "Vous pouvez publier des informations publiquement par le biais de commentaires, de communautés, de diffusion en direct et d'autres fonctionnalités de publication d'informations que nous mettons à votre disposition, y compris en tant qu'utilisateur pour publier du contenu graphique / vidéo, du contenu d'évaluation, etc., et en tant que vendeur pour publier des informations relatives aux biens et / ou services, des informations relatives à la boutique.",
	"components.xieyi.194252": "Nous pouvons déterminer si vous bénéficiez des autorisations de fonctionnalité correspondantes en fonction de votre type d'utilisateur et de vos informations de journal Web (par exemple, seuls les utilisateurs qui ont déjà acheté un produit peuvent publier une évaluation de ce produit).",
	"components.xieyi.x8rr9h": "Veuillez noter que des informations personnelles ou même des informations personnelles sensibles vous concernant ou concernant d'autres personnes peuvent être impliquées dans les informations que vous publiez publiquement, comme lorsque vous choisissez de télécharger des images contenant des informations personnelles lorsque vous évaluez, et vous êtes invité à être plus prudent quant à savoir si vous partagez ou même partagez publiquement Des informations pertinentes lorsque vous utilisez nos services. Si les informations que vous publiez publiquement concernent des informations personnelles d'autres personnes, vous devez obtenir leur consentement avant de les publier.",
	"components.xieyi.h60ox8": "(IX) vous fournir la sécurité",
	"components.xieyi.q466n3": "Afin de respecter l'obligation légale de sécuriser les transactions de commerce électronique, d'améliorer la sécurité de votre utilisation de nos services et de ceux de nos sociétés affiliées et partenaires, de protéger votre sécurité personnelle ou celle d'autres utilisateurs ou du public contre les atteintes à vos biens, de mieux prévenir les risques de sécurité tels que les sites de phishing, la fraude, les cyberattaques, les cyberattaques, les intrusions, et de mieux identifier les violations des lois et règlements ou",
	"components.xieyi.3p5627": "Cas des protocoles, règles liés à la plate - forme, nous intégrons dans l'application un SDK de sécurité d'application développé par nos sociétés affiliées pour collecter des informations sur votre appareil",
	"components.xieyi.4n220b": "Les informations de journal de service, les données de capteur de périphérique, les informations logicielles couramment utilisées et qui peuvent utiliser ou intégrer vos informations d'utilisateur, les informations de transaction, les informations de périphérique, les informations de journal de service et les informations que nos sociétés affiliées et partenaires ont obtenues votre autorisation ou que nous partageons en vertu de la loi (où nous pouvons collecter des adresses Mac, des informations relatives aux périphériques IMEI à des fins de vérification des risques pendant le fonctionnement du back - Office de l'application), Pour évaluer les risques liés à votre compte et à vos transactions, vérifier votre identité, détecter et prévenir les incidents de sécurité et prendre les mesures nécessaires en matière d'enregistrement, d'audit, d'analyse et de résolution conformément à la loi.",
	"components.xieyi.4ay5f9": "(X) démarrage automatique ou associé de l'app",
	"components.xieyi.i2rhwf": "Afin d'améliorer l'expérience des utilisateurs avec nos services et de mieux sécuriser les transactions, nous avons besoin de conseils et d'aide pour obtenir une version mise à jour de nos services d'application app via la fonctionnalité d'auto - démarrage ou de démarrage associé de l'application.",
	"components.xieyi.46wk64": "(XI) Autres services supplémentaires pour vous",
	"components.xieyi.9w14b7": "Dans le but d’améliorer votre expérience en vous proposant des produits et / ou services plus pratiques, de meilleure qualité et personnalisés, nous pouvons collecter et utiliser vos informations personnelles dans le cadre des services supplémentaires suivants que nous vous fournissons.",
	"components.xieyi.7jt388": "Vous êtes libre de choisir de fournir ou non ces informations et si vous ne les fournissez pas, cela n'affectera pas votre utilisation",
	"components.xieyi.1c6h45": "Les services de base,",
	"components.xieyi.035322": "Mais vous ne pouvez pas obtenir l'expérience utilisateur que ces services supplémentaires vous apportent.",
	"components.xieyi.w541k9": "Vous pouvez vérifier l’état des autorisations ci - dessus dans les « paramètres » de votre appareil ou dans notre client « mes – paramètres – confidentialité – autorisations système », à votre discrétion, pour les activer ou les désactiver à tout moment.",
	"components.xieyi.t77ks8": "Veuillez noter que vous nous autorisez à collecter et à utiliser les informations personnelles pertinentes pour vous fournir les services correspondants en activant l'une ou l'autre de ces autorisations, et que lorsque vous désactivez l'une ou l'autre de ces autorisations, vous annulez l'autorisation en votre nom, nous ne pourrons plus continuer à collecter et à utiliser Les informations personnelles pertinentes sur la base de l'autorisation correspondante, ni continuer à vous fournir les services auxquels cette autorisation correspond. Votre décision de désactiver les autorisations n'affectera pas la collecte et l'utilisation antérieures d'informations basées sur votre autorisation.",
	"components.xieyi.q563dr": "Vous pouvez",
	"components.xieyi.bs34gv": "Cliquez ici",
	"components.xieyi.w6lbn0": "Voir la demande d'autorisation d'application et la description de l'utilisation",
	"components.xieyi.835h48": "(XII) Autres règles relatives à la collecte et à l’utilisation des renseignements personnels",
	"components.xieyi.jy12wf": "Si les informations que vous fournissez contiennent des informations personnelles sur d'autres utilisateurs, dans",
	"components.xieyi.m6i6t5": "Avant que la plateforme ne fournisse ces informations personnelles, vous devez vous assurer que vous avez obtenu une autorisation légale.",
	"components.xieyi.a1o5v4": "Si nous utilisons des informations à d'autres fins que celles décrites dans la présente politique ou si nous utilisons des informations collectées à d'autres fins, sauf dans les cas prévus par la loi ou la réglementation, nous vous demanderons votre consentement préalable.",
	"components.xieyi.41gslf": "Lorsque nous obtenons indirectement des informations vous concernant auprès d’un tiers, nous demandons expressément par écrit à ce tiers, avant la collecte, d’indiquer l’origine de ses informations personnelles et si vous avez obtenu votre autorisation légale pour collecter, traiter et nous fournir vos informations personnelles. Sauf dans les cas expressément autorisés par la loi, nous ne collecterons vos informations personnelles auprès d’un tiers qu’après avoir confirmé que le tiers a obtenu votre consentement autorisé et, si l’autorisation du tiers ne couvre pas nos objectifs de traitement et d’utilisation, nous traiterons vos informations personnelles nous - mêmes ou après avoir demandé à ce tiers votre consentement. Dans le même temps, nous renforçons la sécurité des informations personnelles (y compris la préparation des informations sensibles, le stockage crypté des informations sensibles, le contrôle des droits d'accès, etc.). Nous protégerons les renseignements personnels obtenus indirectement en utilisant des mesures et des moyens de protection non moins efficaces que ceux que nous appliquons aux renseignements personnels de nos utilisateurs.",
	"components.xieyi.x3r3y6": "4 - exceptions au consentement autorisé",
	"components.xieyi.3jev4v": "Conformément aux dispositions légales et réglementaires applicables, nous n’avons pas besoin de votre consentement autorisé pour traiter vos informations personnelles lorsque:",
	"components.xieyi.214uq6": "(1) nécessaire à l'exécution d'une obligation légale ou d'une obligation légale;",
	"components.xieyi.81k4nu": "(2) directement liés à la sécurité nationale, à la sécurité de la défense, à un intérêt public important;",
	"components.xieyi.j7fomu": "(3) en réponse à une urgence de santé publique;",
	"components.xieyi.7806cy": "(4) le traitement des informations personnelles dans la mesure où cela est raisonnable pour la réalisation d'actes tels que la couverture médiatique, la surveillance de l'opinion publique, etc., dans l'intérêt public;",
	"components.xieyi.754474": "(5) en cas d'urgence, qui ne peut pas vous être communiquée en temps opportun ou efficacement pour protéger votre vie ou celle d'une autre personne ou pour protéger des biens vitaux, mais qui vous sera communiquée en temps opportun lorsque l'urgence aura disparu;",
	"components.xieyi.i1yv2t": "(6) traiter, dans la mesure où cela est raisonnable en vertu de la loi applicable, les informations personnelles qu’une personne a rendues publiques ou qui ont déjà été rendues publiques légalement;",
	"components.xieyi.5dz1kl": "(7) dans la mesure où cela est nécessaire à la conclusion et à l’exécution d’un accord pertinent ou d’un autre document écrit avec vous;",
	"components.xieyi.5bon72": "(8) nécessaire pour maintenir un fonctionnement sûr et stable des produits et / ou services fournis, par exemple pour détecter, éliminer les défauts des produits et / ou services;",
	"components.xieyi.cw60x0": "(9) dans les autres cas prévus par les lois et règlements.",
	"components.xieyi.vyw19u": "Veuillez noter que, conformément à la législation en vigueur, si nous",
	"components.xieyi.4t3bn8": "Le traitement des données à caractère personnel par des mesures techniques et autres mesures nécessaires pour que le destinataire des données ne puisse pas ré - identifier une personne en particulier et ne puisse pas être rétabli",
	"components.xieyi.1c74f9": "Ou nous pouvons effectuer des recherches désidentifiées, des analyses statistiques et des prévisions sur les informations collectées pour améliorer",
	"components.xieyi.1m85sf": "Le contenu et la mise en page de la plateforme, la fourniture de produits ou de services pour soutenir les décisions commerciales et l’amélioration de nos produits et services (y compris l’utilisation de données anonymes pour l’apprentissage automatique ou la formation d’algorithmes de modèle),",
	"components.xieyi.me4rhf": "L'utilisation de ces données après traitement ne nécessite pas de vous en informer et d'obtenir votre consentement.",
	"components.xieyi.257c58": "Iii. Comment nous utilisons",
	"components.xieyi.77f388": "Et technologies similaires",
	"components.xieyi.759p76": "(i) Utilisation de cookies",
	"components.xieyi.48ze34": "Pour assurer le bon fonctionnement du site, vous faciliter l’accès, vous recommander du contenu susceptible de vous intéresser, nous stockons des cookies, des cookies flash ou d’autres stockages locaux fournis par votre navigateur (ou une application associée) qui contiennent généralement des identifiants, le nom du site et certains numéros et caractères (collectivement, les « cookies ») sur votre ordinateur ou appareil mobile. Avec l'aide de",
	"components.xieyi.s020rs": "Vous pouvez modifier votre acceptation des cookies ou refuser nos cookies si votre navigateur ou les services supplémentaires du navigateur le permettent.",
	"components.xieyi.3w12li": "Voir aboutcookies.org pour plus de détails. Toutefois, si vous effectuez les actions décrites dans cet article, cela peut, dans certains cas, affecter votre accès sécurisé à notre site Web et peut nécessiter de modifier les paramètres de l'utilisateur à chaque fois que vous visitez notre site Web.",
	"components.xieyi.q45ikb": "(II) Utilisation de cookies du même type de technologie",
	"components.xieyi.h550rh": "Excepté",
	"components.xieyi.7248f8": "En outre, nous utilisons d'autres technologies similaires telles que les balises Web, les balises pixel, les ETAG, etc. sur notre site Web.",
	"components.xieyi.jvxl8f": "Par exemple, les courriels que nous vous envoyons peuvent contenir un lien d’adresse vers le contenu de notre site Web et, si vous cliquez sur ce lien, nous suivons ce clic pour nous aider à comprendre vos préférences en matière de produits ou de services afin de nous permettre d’améliorer activement l’expérience utilisateur. Une balise de site Web est généralement une image transparente intégrée à un site Web ou à un e - mail. Grâce aux balises pixel dans les e - mails, nous pouvons savoir si un e - mail a été ouvert. Si vous ne souhaitez pas que votre activité soit suivie de cette manière, vous pouvez vous désabonner de notre liste d'envoi à tout moment.",
	"components.xieyi.52wb6a": "ETAG (entity Tag) est un en - tête de protocole HTTP qui est transmis derrière le navigateur Internet et le serveur Internet et peut remplacer les cookies. ETAG peut nous aider à éviter les charges de serveur inutiles, à améliorer l'efficacité du service et à économiser des ressources et de l'énergie. Dans le même temps, nous pouvons enregistrer votre identité via ETAG afin que nous puissions mieux comprendre et améliorer nos produits ou services.",
	"components.xieyi.y92k4u": "La plupart des navigateurs offrent aux utilisateurs la possibilité d'effacer les données du cache du navigateur et vous pouvez effectuer les opérations d'effacement de données correspondantes dans la fonction paramètres du navigateur.",
	"components.xieyi.o54yhy": "Toutefois, veuillez noter que si vous désactivez ETAG, vous risquez de ne pas bénéficier d'une expérience de produit ou de service relativement meilleure.",
	"components.xieyi.37e045": "(i) le partage",
	"components.xieyi.675822": "Nous ne serons pas avec",
	"components.xieyi.x1y6r8": "Des sociétés, organisations et personnes extérieures partagent vos informations personnelles, sauf dans les cas suivants:",
	"components.xieyi.8q0s05": "Partage dans des circonstances légales: Nous partageons vos informations personnelles à l'extérieur conformément aux lois et règlements.",
	"components.xieyi.9irym2": "Partage avec consentement explicite: après avoir obtenu votre consentement explicite, nous partageons vos informations personnelles avec d'autres parties.",
	"components.xieyi.371i1r": "3, partager si nécessaire pour vous fournir le produit ou le service dont vous avez besoin: vous",
	"components.xieyi.75u39x": "La plate - forme achète des biens ou des services et, selon votre choix, nous partageons les informations nécessaires relatives à la transaction dans les informations de votre commande avec le fournisseur du bien ou du service concerné pour répondre à vos besoins en matière de transaction, de livraison et de service après - vente.",
	"components.xieyi.u6d81m": "Partage avec les sociétés affiliées:",
	"components.xieyi.30si92": "Pour faciliter notre base",
	"components.xieyi.6pysc9": "Les comptes de la plateforme vous proposent des produits et services, vous recommandent des informations susceptibles de vous intéresser, identifient les anomalies des comptes utilisateurs, protègent",
	"components.xieyi.7vmrlb": "La sécurité des biens personnels des sociétés affiliées ou d'autres utilisateurs ou du public est protégée et vos informations personnelles peuvent être partagées avec nos sociétés affiliées et / ou leurs fournisseurs de services désignés.",
	"components.xieyi.269i0k": "Nous ne partagerons que les informations personnelles nécessaires et sous réserve des objectifs énoncés dans la présente politique, et nous vous demanderons à nouveau votre consentement autorisé si nous partageons vos informations personnelles sensibles ou si une société affiliée modifie l'utilisation et le traitement des informations personnelles.",
	"components.xieyi.5475kt": "5. Confier aux partenaires autorisés:",
	"components.xieyi.u1sa8e": "Nous pouvons confier à des partenaires autorisés la fourniture de certains services pour vous ou l’exécution de fonctions en notre nom,",
	"components.xieyi.f1h4lc": "Nous ne partagerons vos informations qu'à des fins légitimes, légitimes, nécessaires, spécifiques et explicites dans le cadre de la présente Déclaration de politique, les partenaires autorisés n'auront accès qu'aux informations dont ils ont besoin pour s'acquitter de leurs fonctions et nous leur demanderons par contrat de ne pas utiliser ces informations à d'autres fins.",
	"components.xieyi.g700e1": "Actuellement, nos partenaires autorisés comprennent les types suivants:",
	"components.xieyi.36nvwn": "(1) partenaires autorisés dans la catégorie des services de publicité, d'analyse. Nous confions à ces partenaires le traitement des informations relatives à la portée et à l'efficacité de la publicité, mais nous ne partageons pas vos informations personnelles avec des partenaires qui fournissent des services de publicité et d'analyse, sauf avec votre permission, ou nous les désidentifions afin que les partenaires autorisés ne puissent pas vous identifier personnellement. Ces partenaires peuvent combiner les informations ci - dessus avec d'autres données qu'ils ont légalement acquises pour exécuter des services publicitaires ou des recommandations décisionnelles que nous leur avons confiées.",
	"components.xieyi.5xh5u7": "(2) fournisseurs, prestataires de services et autres partenaires. Nous envoyons des informations à des fournisseurs, prestataires de services et autres partenaires qui soutiennent nos activités, notamment en fournissant des services d'infrastructure technique, en analysant la manière dont nos services sont utilisés, en mesurant l'efficacité de la publicité et des services, en fournissant un service à la clientèle, en facilitant les paiements ou en effectuant Des recherches et des enquêtes académiques, des services de livraison logistique, des services de paiement, le traitement des données, etc. Nous partageons ces informations dans le but de permettre la fonctionnalité d'achat de base de nos produits et / ou services, comme lorsque nous devons partager les informations de votre commande avec un prestataire de services logistiques pour organiser la livraison; Ou nous devons partager votre numéro de commande et le montant de votre commande avec un organisme de paiement tiers pour confirmer votre ordre de paiement et effectuer le paiement, etc.",
	"components.xieyi.67e7b8": "(3) nom réel du prestataire de services de certification. Lorsque nous vous fournissons des services de certification de nom réel, vous devez nous autoriser à collecter vos informations de certification de nom réel pour les fournir à des organismes tiers (y compris, mais sans s'y limiter, Tencent Cloud Computing (Beijing) LLC, ci - après dénommés « organismes tiers »), Par des organismes tiers qui recherchent, vérifient l'authenticité, l'exactitude et l'exhaustivité de ces informations auprès des unités concernées (y compris, mais sans s'y limiter, la base de données de base d'informations personnelles sur le crédit de la Banque populaire de Chine et d'autres organismes gouvernementaux concernés, les opérateurs de télécommunications de base, les agences d'information, les organes de sécurité publique, les procureurs, les tribunaux, les institutions financières, d'autres organismes de coopération ou des canaux publics), analysent, comparent, traitent, compilent ces informations, Et nous faire part d'un rapport d'évaluation holistique de vos informations de crédit personnelles.",
	"components.xieyi.12lv44": "Pour la garantie",
	"components.xieyi.3749fw": "Le fonctionnement stable de la plate - forme et la mise en œuvre des fonctionnalités ci - dessus vous permettent de profiter et d'utiliser davantage de services et de fonctionnalités, et les SDK de nos partenaires autorisés ou d'autres applications similaires sont intégrés à nos applications.",
	"components.xieyi.3nos3g": "À propos de nos partenaires d'accès, etc. partagez la liste avec des tiers vous pouvez",
	"components.xieyi.70yu7f": "Faites une vue.",
	"components.xieyi.89014s": "Nous effectuons des tests de sécurité rigoureux sur les interfaces d'application (API), les kits de développement d'outils logiciels (SDK) et nous nous engageons avec nos partenaires autorisés à prendre des mesures strictes de protection des données pour traiter les informations personnelles conformément à la présente politique et à toute autre mesure de confidentialité et de sécurité pertinente.",
	"components.xieyi.1l5529": "Ii) transfert",
	"components.xieyi.ls670h": "Nous ne transférons pas vos informations personnelles à des sociétés, organisations et individus, sauf dans les cas suivants:",
	"components.xieyi.u50832": "Transfert avec consentement explicite: après avoir obtenu votre consentement explicite, nous transférons vos informations personnelles à d'autres parties;",
	"components.xieyi.6snoud": "2, dans",
	"components.xieyi.411611": "Dans le cas d'une fusion, d'une acquisition ou d'une liquidation de faillite, ou dans d'autres circonstances impliquant une fusion, une acquisition ou une liquidation de faillite, en ce qui concerne le transfert de renseignements personnels, nous vous informerons rapidement du nom et des coordonnées de la nouvelle société, organisation ou personne détenant vos renseignements personnels et nous demanderons qu'elle continue d'être assujettie à la présente politique, faute de quoi nous demanderons à cette société, organisation ou personne de vous demander à nouveau son consentement autorisé.",
	"components.xieyi.783yjz": "Iii) divulgation publique",
	"components.xieyi.zdq4b7": "Nous ne divulguerons publiquement vos informations personnelles que si:",
	"components.xieyi.3in5j6": "Nous pouvons divulguer publiquement vos renseignements personnels avec votre consentement explicite ou en fonction de vos choix non sollicités.",
	"components.xieyi.ey5b87": "La divulgation de vos renseignements personnels dans la mesure prescrite, sur la base des exigences de divulgation d'informations imposées par les lois et règlements, tels que la publicité d'informations sur les vendeurs en vertu des pratiques administratives de surveillance des transactions en ligne.",
	"components.xieyi.ok7p68": "Si nous déterminons que vous avez commis une violation des lois et règlements ou une infraction grave",
	"components.xieyi.6kb306": "Les accords et règles relatifs à la plate - forme, ou pour protéger",
	"components.xieyi.41el81": "Protection contre les atteintes à la sécurité des biens personnels des utilisateurs de la plateforme ou du public, nous pouvons divulguer des informations personnelles vous concernant et cesser de vous fournir des services conformément aux lois et règlements ou avec votre consentement,",
	"components.xieyi.4c16r3": "Y compris les violations connexes et",
	"components.xieyi.7uq8p7": "Mesures que la plateforme a prises à votre égard. Par exemple, si vous avez commis une infraction grave en vendant des produits contrefaits",
	"components.xieyi.kc0i6h": "Règles de la plate - forme, nous pouvons divulguer publiquement des informations sur les sujets de certification de votre boutique, des violations et",
	"components.xieyi.100i6j": "Mesures de traitement.",
	"components.xieyi.veqih2": "(IV) Exceptions au consentement préalable autorisé en cas de partage, de transfert ou de divulgation publique de renseignements personnels",
	"components.xieyi.i5fma7": "Conformément aux lois et règlements applicables, votre consentement préalable n’est pas nécessaire pour partager, transférer ou divulguer publiquement vos renseignements personnels:",
	"components.xieyi.r82363": "1. Nécessaire à l'exécution d'une obligation légale ou d'une obligation légale;",
	"components.xieyi.ett47l": "2) directement liés à la sécurité nationale, à la sécurité de la défense, à un intérêt public important;",
	"components.xieyi.nql27g": "En réponse à une urgence de santé publique;",
	"components.xieyi.r2138s": "La mise en œuvre de la couverture médiatique, de la surveillance de l'opinion publique, etc. dans l'intérêt public, pour traiter les informations personnelles dans la mesure du raisonnable;",
	"components.xieyi.i32523": "5. En cas d'urgence, lorsque cela n'est pas possible ou efficace pour protéger votre vie ou celle d'une autre personne ou la sécurité de biens vitaux, mais vous sera communiqué en temps opportun lorsque la situation d'urgence aura disparu;",
	"components.xieyi.4stqc6": "6. Traiter, dans la mesure du raisonnable conformément aux lois et règlements pertinents, les informations personnelles qu’une personne a rendues publiques par elle - même ou qui ont déjà été rendues publiques légalement.",
	"components.xieyi.7k22k8": "Veuillez noter que, conformément à la législation applicable,",
	"components.xieyi.7w6sng": "Lorsque nous traitons des informations personnelles à l’aide de mesures techniques et d’autres mesures nécessaires pour que le destinataire des données ne puisse pas identifier à nouveau une personne spécifique et ne puisse pas les récupérer, le partage, le transfert et la divulgation publique de ces données après traitement ne nécessitent pas de vous en informer et de demander votre consentement.",
	"components.xieyi.t8w9o9": "(i) Mesures techniques de protection des données",
	"components.xieyi.5k26r2": "Nous avons mis en place des mesures de sécurité conformes aux normes de l'industrie et raisonnablement réalisables pour protéger vos informations personnelles contre tout accès non autorisé, divulgation publique, utilisation, modification, dommage ou perte. Par exemple, nous utilisons des technologies de cryptage pour améliorer la sécurité des informations personnelles lors de l'échange de données entre votre navigateur et le serveur.",
	"components.xieyi.824h5u": "Le protocole SSL est protégé par cryptage et nous avons simultanément",
	"components.xieyi.rs1e76": "La plateforme offre une navigation sécurisée via le protocole HTTPS; Nous utilisons des mécanismes de protection fiables pour prévenir les attaques malveillantes contre les informations personnelles; Nous déployons des mécanismes de contrôle d'accès pour nous assurer que seules les personnes autorisées ont accès aux informations personnelles.",
	"components.xieyi.qj2xrx": "Ii) Système de conformité du système de gestion de la protection des données",
	"components.xieyi.y1edur": "Nous avons mis en place un système de conformité avancé de gestion de la sécurité des données centré sur les données et axé sur le cycle de vie des données au sein de l'industrie, améliorant la sécurité des informations personnelles de manière multidimensionnelle, de la construction organisationnelle, de la conception institutionnelle, de la gestion des personnes, de la technologie des produits et plus encore. Nous mettons en place un organisme de protection des renseignements personnels pour surveiller la protection des renseignements personnels et organiser des séances de formation sur la sécurité et la protection de la vie privée afin de sensibiliser nos employés à l’importance de protéger les renseignements personnels.",
	"components.xieyi.d7ix35": "(III) réponse aux incidents de sécurité des renseignements personnels",
	"components.xieyi.2o454t": "Si nos protections physiques, techniques ou administratives sont compromises, ce qui entraîne un accès non autorisé, une divulgation publique, une altération ou une destruction des informations, ce qui porte atteinte à vos droits et intérêts légitimes, nous déclencherons rapidement un plan d’urgence pour prendre des mesures de secours raisonnables afin de minimiser l’impact sur vos informations personnelles et vos autres droits et intérêts. En cas d’incident de sécurité des données personnelles, nous vous informerons également, comme l’exigent les lois et règlements: des circonstances de base et des conséquences possibles de l’incident de sécurité, des mesures d’élimination que nous avons prises ou que nous prendrons, des recommandations que vous pouvez vous protéger et réduire les risques de manière autonome, des recours à votre encontre, etc. Circonstances liées à l'incident nous vous informerons par courrier, lettre, téléphone, notification Push, etc. lorsqu'il est difficile d'informer les sujets de renseignements personnels un par un, nous prendrons des mesures raisonnables et efficaces pour publier l'annonce. Dans le même temps, nous signalerons également les incidents liés à la sécurité des informations personnelles, conformément aux exigences réglementaires.",
	"components.xieyi.4706wi": "Iv) surveillance sociale",
	"components.xieyi.4ouosr": "Nous publions régulièrement des rapports de responsabilité sociale sur la protection des informations personnelles, conformément aux exigences légales et administratives du pays, sous surveillance sociale.",
	"components.xieyi.6r5a4q": "(v) Prévention autonome des risques liés à la sécurité des comptes",
	"components.xieyi.veukf5": "Internet n'est pas un environnement absolument sûr, l'utilisation",
	"components.xieyi.ri9a0j": "Lorsque vous êtes sur la plateforme, nous vous recommandons fortement de ne pas utiliser non",
	"components.xieyi.382lb2": "Les moyens de communication recommandés par la plateforme pour envoyer vos informations, tout en vous demandant d'utiliser des mots de passe complexes, nous aident à sécuriser votre compte.",
	"components.xieyi.s2h72a": "En utilisation",
	"components.xieyi.8mtgk8": "Lorsque la plate - forme effectue des transactions en ligne, il est inévitable que vous divulguiez vos informations personnelles à la contrepartie commerciale ou à la contrepartie commerciale potentielle, telles que",
	"components.xieyi.6ue558": "Mode de contact",
	"components.xieyi.5h8pl3": "Adresse de contact",
	"components.xieyi.74n5cs": "- Oui. En outre, vous pouvez également établir des communications, échanger des informations ou analyser le contenu avec des tiers via nos services. Veuillez protéger correctement vos informations personnelles et ne les fournir à d'autres que dans la mesure nécessaire. Si vous constatez une violation de vos informations personnelles, en particulier de votre compte ou de votre mot de passe, veuillez contacter immédiatement",
	"components.xieyi.1100gs": "Le service client officiel de la plate - forme nous permet de prendre les mesures appropriées en fonction de votre demande.",
	"components.xieyi.p898io": "Veuillez noter que les informations que vous partagez volontairement ou même publiquement lorsque vous utilisez nos services peuvent impliquer des informations personnelles ou même des informations personnelles sensibles vous concernant ou concernant d'autres personnes. Vous êtes invité à être plus prudent quant à savoir si vous partagez ou même partagez publiquement des informations pertinentes lorsque vous utilisez nos services.",
	"components.xieyi.pfhp6l": "(i) lieu de stockage",
	"components.xieyi.id973y": "Les informations personnelles que nous collectons et produisons dans le cadre de nos opérations en République populaire de Chine seront stockées en Chine.",
	"components.xieyi.76w8ku": "Dans la mesure où il est nécessaire de fournir vos informations personnelles à l'étranger en raison de l'activité ou des fins couvertes par la présente politique, nous procéderons à une évaluation de l'impact sur la protection des informations personnelles, enregistrerons le traitement conformément aux dispositions légales pertinentes et, sur la base de votre consentement explicite, nous vous informerons rapidement du nom du destinataire des informations à l'étranger, de ses coordonnées, des finalités du traitement, des moyens de traitement, Des questions telles que le type de renseignements personnels et les moyens et procédures par lesquels vous pouvez exercer vos droits en vertu de la législation nationale auprès de destinataires situés à l'extérieur du pays.",
	"components.xieyi.7fciuv": "(II) durée de conservation",
	"components.xieyi.f1a2af": "Nous ne conserverons vos renseignements personnels que pendant la période nécessaire aux fins décrites dans la politique de cost, à moins que la loi n’impose des exigences de conservation,",
	"components.xieyi.42nn2k": "Par exemple, la loi sur le commerce électronique de la République populaire de Chine exige que les informations sur les biens et services et les informations sur les transactions soient conservées pendant au moins trois ans à compter de la date de conclusion de la transaction. Nous jugeons que la durée de conservation des informations personnelles se réfère principalement aux critères suivants, selon la durée la plus longue:",
	"components.xieyi.6hv739": "Pour réaliser les transactions qui vous concernent, pour conserver les transactions correspondantes et les dossiers commerciaux en réponse à vos éventuelles demandes de renseignements ou plaintes;",
	"components.xieyi.483k46": "2. Garantir la sécurité et la qualité des services que nous vous fournissons;",
	"components.xieyi.v5a774": "Si vous acceptez une période de conservation plus longue;",
	"components.xieyi.7g6607": "Selon les besoins pertinents en matière de prescription;",
	"components.xieyi.8iszne": "L'existence d'autres conventions spéciales ou de dispositions légales et réglementaires concernant la durée de conservation.",
	"components.xieyi.2xiglt": "À l'expiration de la période de conservation, nous traitons vos informations personnelles dans les délais prévus par les lois et règlements applicables.",
	"components.xieyi.7rg77i": "En plus de suivre le chemin d'action convenu dans cette politique, vous pouvez",
	"components.xieyi.t2esz7": "Au - delà de votre propre fonctionnement sur la plateforme, vous pouvez accéder à vos informations et les gérer de la manière suivante:",
	"components.xieyi.kb51e6": "(i) Demander, corriger et compléter vos informations",
	"components.xieyi.nu7m3c": "Vous avez le droit de demander, corriger ou compléter vos informations. Vous pouvez le faire en vous connectant",
	"components.xieyi.bcd5d5": "Plate - forme, cliquez sur « mes paramètres (en haut à droite) » pour accéder à des informations telles que les requêtes du Centre d'achat, la correction de votre profil et des informations relatives à votre compte personnel, la sécurité de votre compte et l'adresse de réception.",
	"components.xieyi.043c4c": "(II) supprimer vos informations",
	"components.xieyi.2d2n9p": "Vous pouvez supprimer certaines de vos informations de la manière indiquée dans (i) Demander, corriger et compléter vos informations.",
	"components.xieyi.8n2d00": "Dans les cas suivants, vous pouvez",
	"components.xieyi.4zh20s": "Service clientèle de la plateforme contact faites - nous une demande de suppression de vos informations personnelles:",
	"components.xieyi.25t31b": "1. Si nous traitons des informations personnelles en violation des lois et règlements;",
	"components.xieyi.8tjokj": "Lorsque nous collectons et utilisons vos informations personnelles sans votre consentement explicite;",
	"components.xieyi.4m1259": "3. Si notre traitement des informations personnelles constitue une violation grave de notre engagement avec vous;",
	"components.xieyi.q81de3": "Si votre compte est déconnecté.",
	"components.xieyi.xd43vl": "Si nous décidons de répondre à votre demande de suppression, nous informerons également, dans la mesure du possible, les sujets qui ont obtenu vos informations personnelles de notre part et leur demanderons de les supprimer rapidement (sauf disposition contraire des lois et règlements ou si ces sujets ont obtenu votre autorisation de manière indépendante).",
	"components.xieyi.1c21t6": "Lorsque vous ou nous vous aidons à supprimer les informations pertinentes, il se peut que nous ne soyons pas en mesure de supprimer immédiatement les informations correspondantes de nos systèmes de sauvegarde en raison des restrictions légales et techniques de sécurité applicables, nous stockerons vos informations personnelles en toute sécurité et limiterons tout traitement ultérieur jusqu'à ce que la sauvegarde puisse être effacée ou anonymisée.",
	"components.xieyi.43p4r8": "(III) déconnexion de votre compte",
	"components.xieyi.w2lnx4": "Vous pouvez demander la déconnexion de votre compte en:",
	"components.xieyi.d17ah0": "1, se connecter",
	"components.xieyi.113v7u": "La plateforme, en soumettant une demande de déconnexion de compte via « mon – paramètres (en haut à droite) – Centre de sécurité du numéro de compte – déconnexion du numéro de compte »;",
	"components.xieyi.390iqo": "2. Le contact",
	"components.xieyi.n6o511": "Service client de la plateforme pour vous aider à demander la déconnexion de votre compte",
	"components.xieyi.g4i68q": "Une fois que vous vous êtes déconnecté activement de votre compte, nous cesserons de vous fournir des produits ou des services et supprimerons vos informations personnelles, comme l'exige la loi applicable, ou les traiterons de manière anonyme.",
	"components.xieyi.i8g44k": "(IV) Modifier la portée de votre consentement autorisé",
	"components.xieyi.7162l2": "Vous pouvez modifier ou retirer la portée de votre autorisation de collecte et de traitement de vos informations personnelles en:",
	"components.xieyi.83m5qy": "« mon",
	"components.xieyi.ih02m6": "- Paramètres - confidentialité - autorisations système '",
	"components.xieyi.3aq776": "Gérer les applications que vous avez autorisées.",
	"components.xieyi.uyy3k2": "Pour les autorisations que vous ne pouvez pas configurer directement de la manière décrite ci - dessus, vous pouvez les modifier en contactant le service clientèle Epoch EPT ou en envoyant un e - mail à jubao@weipaitang.com - Oui. Nous répondrons à votre demande de rétractation dans les 15 jours ouvrables. Toutefois, veuillez noter que pour certains types d’informations personnelles, telles que celles qui sont nécessaires pour remplir les fonctions essentielles d’epoch EPT ou qui nous sont nécessaires pour remplir nos obligations légales et réglementaires, il se peut que nous n’ayons pas les moyens de répondre à votre demande de modification de la portée de votre autorisation.",
	"components.xieyi.38w831": "Lorsque vous retirez votre autorisation, nous ne traiterons plus les informations personnelles correspondantes. Toutefois, votre décision de retirer votre autorisation n'affectera pas le traitement des informations personnelles que nous avons effectué précédemment sur la base de votre autorisation.",
	"components.xieyi.o2932d": "Si vous ne souhaitez pas recevoir de publicité commerciale personnalisée que nous vous envoyons, vous pouvez annuler à tout moment en:",
	"components.xieyi.7l39m6": "'Mon Epoch EPT - Paramètres - À propos d'Epoch EPT - Personnalisation'",
	"components.xieyi.uw6w84": "(v) prise de décision automatisée des systèmes d'information contraignants",
	"components.xieyi.6ixh35": "Dans certaines fonctions commerciales, nous pouvons prendre des décisions basées uniquement sur des mécanismes de prise de décision automatisés non humains, tels que des systèmes d'information, des algorithmes, etc. Si ces décisions affectent de manière significative vos intérêts légitimes, vous avez le droit de nous demander des explications, et nous ne violerons pas",
	"components.xieyi.8i0uc6": "Les méthodes de réclamation sont fournies sous réserve de secrets commerciaux ou d'autres intérêts des utilisateurs, de l'intérêt public de la société.",
	"components.xieyi.sace84": "(vi) Copie des renseignements personnels",
	"components.xieyi.iz38g1": "Si vous avez besoin d’une copie de vos informations personnelles, vous pouvez nous contacter via les canaux de rétroaction énumérés dans la section « comment nous contacter » de la présente politique et, après vérification de votre identité, nous vous fournirons une copie de vos informations personnelles à votre demande, sauf disposition contraire des lois et règlements ou de la présente politique.",
	"components.xieyi.q0m5n8": "(vii) répondre à votre demande ci - dessus",
	"components.xieyi.fqi536": "En ce qui concerne les demandes que vous ou éventuellement votre tuteur, vos proches parents et d'autres sujets autorisés nous avez adressées, comme indiqué ci - dessus, ainsi que vos droits en matière d'informations personnelles en vertu des lois de la République populaire de Chine et d'autres lois applicables, vous pouvez nous contacter via le service clientèle ou déposer une plainte directement auprès de notre service dédié à la protection des informations personnelles. Nous vous répondrons dans les 15 jours.",
	"components.xieyi.a1304e": "Pour assurer la sécurité de votre compte, lorsque vous nous faites une demande telle que décrite ci - dessus, nous pouvons d'abord demander une vérification de votre identité (par exemple, en vous demandant de fournir une demande écrite ou autrement) avant de traiter votre demande.",
	"components.xieyi.31h37g": "Nous ne serons pas en mesure de répondre à votre demande, conformément aux exigences légales et réglementaires, dans les cas suivants:",
	"components.xieyi.1udf40": "1. Pour l'exécution d'une obligation légale ou d'une obligation légale;",
	"components.xieyi.0ro7r7": "3. S'il existe des preuves suffisantes de mauvaise foi subjective ou d'abus de droit de la part de la personne concernée par les informations personnelles;",
	"components.xieyi.70z43w": "Répondre à votre demande entraînerait un préjudice grave à vos intérêts légitimes ou à ceux d'autres personnes ou organisations;",
	"components.xieyi.335lfg": "5. Concernant les secrets commerciaux;",
	"components.xieyi.1eb324": "Autres cas prévus par la loi ou les règlements administratifs.",
	"components.xieyi.e0vv42": "Dans les activités de commerce électronique, nous présumons que vous avez la capacité civile correspondante. Si vous êtes mineur, nous vous demandons de demander à vos parents ou autres tuteurs de lire attentivement cette politique et d'utiliser nos services ou de nous fournir des informations avec le consentement de vos parents ou autres tuteurs.",
	"components.xieyi.jp5623": "Si vous êtes un enfant de moins de 14 ans, avant d'utiliser nos services, vous et votre tuteur devez lire attentivement nos règles spécifiques sur la protection des informations personnelles des enfants pour vous assurer que vous obtenez l'Accord de votre tuteur.",
	"components.xieyi.4637q9": "Règles sur la protection des renseignements personnels des enfants",
	"components.xieyi.b7i17n": "Une fois que vous avez donné votre consentement, vous pouvez utiliser nos services sous la direction d'un tuteur afin d'assurer votre sécurité lorsque vous utilisez nos services et effectuez des transactions.",
	"components.xieyi.63z56b": "Si vous êtes le parent ou un autre tuteur d'un enfant, nous vous demandons de vérifier si l'enfant utilise nos services après avoir obtenu votre consentement autorisé. Si vous avez des questions concernant les renseignements personnels d’un enfant, veuillez communiquer avec notre service dédié à la protection des renseignements personnels.",
	"components.xieyi.064797": "Nos services et technologies sont constamment mis à jour et optimisés pour répondre à vos besoins et à ceux des autres utilisateurs, de sorte que nous pouvons mettre à jour notre politique de confidentialité pour vous informer des changements spécifiques lorsque de nouveaux services et processus commerciaux changent. Cependant, nous ne limiterons pas vos droits en vertu de cette politique sans votre consentement explicite. Nous serons dans",
	"components.xieyi.7xys1n": "Pages spécialisées",
	"components.xieyi.5ln786": "Toute modification apportée à cette politique est publiée sur.",
	"components.xieyi.1i9q62": "Nous fournissons également une notification plus visible des changements importants (y compris le fait que nous",
	"components.xieyi.dg46h5": "La manière dont la publicité de la plate - forme est annoncée ou vous fournit des conseils de fenêtre).",
	"components.xieyi.o3si3a": "Les changements importants auxquels cette politique fait référence incluent, mais ne sont pas limités à:",
	"components.xieyi.sntd36": "Des changements importants dans notre modèle de service, tels que les finalités du traitement des informations personnelles, le type d’informations personnelles traitées, la manière dont les informations personnelles sont utilisées, etc.;",
	"components.xieyi.x97b27": "2. Nous subissons des changements importants dans le contrôle, etc., tels que le changement de contrôleur des informations résultant de la réorganisation des fusions et acquisitions, etc.;",
	"components.xieyi.hc7g60": "3. Le changement de l'objet principal du partage, du transfert ou de la divulgation publique des renseignements personnels;",
	"components.xieyi.4icom0": "Des changements importants dans les droits dont vous disposez en ce qui concerne le traitement des informations personnelles et la manière dont vous les exercez;",
	"components.xieyi.krv6jh": "Les modifications apportées à notre département responsable de la protection des informations personnelles, à nos coordonnées et à nos canaux de réclamation;",
	"components.xieyi.t6x4vu": "Le rapport d'évaluation de l'impact sur la sécurité des renseignements personnels indique un risque élevé.",
	"components.xieyi.oso4jc": "Nom de la compagnie: Hangzhou Epoch EPT Culture Creative Co., Ltd",
	"components.xieyi.0vvw2h": "Adresse enregistrée: 7ème étage, bâtiment d'affaires chuanfeng Huaxing, 19 Huaxing Road, Xihu District, Hangzhou, Zhejiang, Chine",
	"components.xieyi.4j9bb7": "Si vous avez des questions, des commentaires ou des suggestions concernant cette politique, vous pouvez nous contacter à l’adresse suivante et nous répondrons à votre demande dans les 15 jours ouvrables:",
	"components.xieyi.5mz349": "1, EPOCH EPT service à la clientèle en ligne",
	"components.xieyi.py8lg2": "2, nous avons également mis en place un département dédié à la protection des informations personnelles, vous pouvez contacter notre responsable de la protection des informations personnelles boîte aux lettres shaktrysrtgscrtbslanderor5046@gmail.com - Oui.",
	"components.xieyi.nfphnk": "Il est important de noter que nous ne pouvons pas répondre aux questions qui ne sont pas liées à cette politique ou à la protection des renseignements personnels des utilisateurs.",
	"components.xieyi.6vag57": "Si vous n’êtes pas satisfait de notre réponse, en particulier si vous pensez que nos pratiques de traitement des informations personnelles nuisent à vos intérêts légitimes,",
	"components.xieyi.iw8u70": "Vous pouvez également rechercher une solution en saisissant le tribunal compétent du Siège de la plateforme Epoch EPT.",
	"components.xieyi.668kbh": "Première publication: 13 décembre 2018",
	"components.xieyi.1hqds4": "Dernière mise à jour: 30 mars 2023",
	"src.error.3if060": "> > > > > > message d'erreur > > > > >",
	"src.error.f78hx4": "> > > > > instance vue > > > > >",
	"libs.axios.3m3ozo": "Serveur anormal!",
	"libs.axios.u68235": "Erreur de demande!",
	"libs.axios.8pvp71": "Vous n'avez aucune autorisation!",
	"libs.axios.3e23l0": "Erreur dans l'adresse demandée",
	"libs.axios.ctl7o2": "Demande expirée, s'il vous plaît rafraîchir et réessayer!",
	"libs.axios.7f90de": "Erreur interne au serveur",
	"libs.axios.6r1ua2": "Service non réalisé",
	"libs.axios.232in7": "Erreur de passerelle",
	"libs.axios.ft8yo6": "Service non disponible",
	"libs.axios.1o3gds": "La passerelle a expiré, Veuillez réessayer après l'actualisation!",
	"libs.axios.43w873": "Les versions http ne sont pas prises en charge",
	"goods.index.86p673": "Acheter",
	"goods.index.40o4np": "Quantité achetée",
	"goods.index.r257np": "Acheter maintenant",
	"goods.index.6i5t88": "Ce produit n'est pas disponible à l'achat!",
	"goods.index.263l56": "Veuillez sélectionner un produit",
	"goods.index.e5yso2": "Veuillez choisir un mode de paiement!",
	"rewards.activityb.t04rn4": "Commission totale",
	"rewards.activityb.r5174s": "Commission aujourd'hui",
	"rewards.activityb.q4544h": "Commission d'hier",
	"rewards.activityb.uflar8": "Nombre de pousses directes",
	"rewards.activityb.fsyaor": "Commission",
	"rewards.activityb.xd17r7": "Nombre de personnes indirectes",
	"trending.index copy 2.891396": "Le Track",
	"trending.index copy 2.rc6wik": "Intervalle d'entrée:",
	"trending.index copy 2.y4r77n": "Cycle de quantification:",
	"trending.index copy 2.m2v371": "Heures",
	"trending.index copy 2.5vyoay": "Bénéfices quantifiés:",
	"trending.index copy 2.rpeid0": "Gain du vendeur $1185.65",
	"trending.index copy 2.g0677s": "Avec taille unique $15120184.00",
	"trending.index copy 2.8sgku2": "En hébergement",
	"trending.index copy 2.g2jni6": "Montant de l'entiercement",
	"trending.index copy 2.39cp6b": "Durée de l'hébergement",
	"trending.index copy 2.050n02": "Gains quotidiens",
	"trending.index copy 2.380j4f": "Heure de début de l'hébergement",
	"trending.index copy 2.6en3x1": "Heure de fin d'hébergement",
	"trending.index copy 2.v761ws": "Cycle de suivi:",
	"trending.index copy 2.8gow40": "Restrictions de vote:",
	"trending.index copy 2.1ul440": "Gain minimum:",
	"trending.index copy 2.7fk35c": "Gains les plus élevés:",
	"trending.index copy 2.3ys73k": "Solde disponible",
	"trending.index copy 2.f615yk": "Prix total",
	"trending.index copy 2.11c26y": "Immédiatement avec le vote",
	"trending.index copy 2.ojxv4u": "Tous",
	"trending.index copy 2.2v0ku3": "Ma commande",
	"trending.index copy 2.9m6dh6": "Passer commande avec succès!",
	"trending.index copy 3.7v38ky": "Annulé",
	"trending.index copy 3.3ksfcn": "Ordre de pré - commande",
	"trending.index copy 3.u6n586": "À régler",
	"trending.index copy 3.7d8jnh": "Titre",
	"trending.index copy 3.22f28b": "Confirmez - vous que vous souhaitez annuler votre commande?",
	"trending.index copy 3.plw663": "Confirmation",
	"trending.index copy 3.e4pcfx": "Annulation réussie!",
	"trending.index copy.voov3i": "Rendement sur près de 30 jours",
	"trending.index copy.60fqu2": "Près de 30 jours de gains",
	"trending.index copy.38c2yt": "Taux de victoire",
	"trending.touzi1.30c92u": "Numéro de commande",
	"trending.touzi1.w3r6h6": "Montant",
	"trending.touzi1.2q58dy": "Statut",
	"trending.touzi1.830j6d": "Le temps",
	"wallet.index.ralw0t": "Veuillez entrer le numéro de téléphone portable",
	"wallet.index.tme1u9": "Numéro de téléphone",
	"wallet.index.h1wve2": "Veuillez entrer un numéro de téléphone",
	"login.login.qnpe05": "Veuillez entrer un téléphone",
	"login.login.8za2m1": "Login le mot de passe ne peut pas être vide",
	"components.recharge.r79s4d": "Est entré en vigueur",
	"components.recharge.v93frd": "Dans le traitement",
	"wallet.index.bm205v": "Le montant minimum ne peut être inférieur à 0",
	"components.recharge.h1w232": "En remise",
	"components.recharge.wx5ntw": "Succès de la remise",
	"components.recharge.42t1o3": "Recharge réussie",
	"components.recharge.d03old": "L'échec de la présentation",
	"components.recharge.cn2rpv": "Présenter le succès"
}