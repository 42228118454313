import i18n from '../lang/index.js';
export default [
    {
        language: "en",
        name: "English",
    },
    {
        language: "sp",
        name: "Español",
    },
    // {
    //     language: "pt",
    //     name: "Português",
    // },
    {
        language: "de",
        name: "Deutsch",
    },
    {
        language: "fr",
        name: "Français",
    },
    {
        language: "it",
        name: "Italiano",
    },
    {
        language: "ru",
        name: "Pусский",
    },
    {
        language: "vi",
        name: "Tiếng Việt",
    },
    {
        language: "tr",
        name: "Türkçe",
    },
    {
        language: "id",
        name: "Indonesia",
    },
    {
        language: "fil",
        name: "Filipino",
    },
    {
        language: "jp",
        name: i18n.t('components.languagebox.8no849'),
    },
    {
        language: "ko",
        name: "한국어",
    },
    {
        language: "hi",
        name: "हिंद",
    },
    // {
    //     language: "ua",
    //     name: "Українська",
    // },
    {
        language: "zh",
        name: i18n.t('components.languagebox.oif20b'),
    },
    // {
    //     language: "mn",
    //     name: "Монгол",
    // },
    {
        language: "th",
        name: "ไทย",
    },
]