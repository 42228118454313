module.exports = {
    "Language": {
        "title": "Bahasa"
    },
    "Noproductsforsale": "Tidak ada produk yang dijual",
    "rechargePresentationText0_1": "Isi ulang 1000 hadiah ",
    "rechargePresentationText0_2": ", Isi ulang 10000 hadiah ",
    "rechargePresentationText0_3": ", dan seterusnya... Klik saya!",
    "rechargePresentationText0": "Anda akan menerima ",
    "rechargePresentationText1": "% dari jumlah pengisian ulang saat Anda mulai isi ulang sekarang. Waktu kegiatan terbatas, dan akan berhenti setelah hadiah habis.",
    "rechargePresentationText2": "Waktu Berakhir Hadiah, Mundur !",
    "Online_Chat": "Chat Online",
    "gobindgoogle": "Silakan bind Google Authenticator Anda",
    "Order": "Pesan",
    "Record": "Rekaman",
    "Assist": "Membantu",
    "Market": "Pasar",
    "History": "Sejarah",
    "Confirm": "Konfirmasi",
    "close": "Tutup",
    "Cancel": "Batal",
    "Previous": "Sebelumnya",
    "Next": "Selanjutnya",
    "Copy": "Salin",
    "Nomoredata": "tidak ada data",
    "moneychar": "₹",
    "DownloadApp": " Unduh Aplikasi Sekarang",
    "Yes": "Ya",
    "No": "Tidak",
    "Wating": "menunggu",
    "Recharge": "Isi ulang",
    "Withdrawal": "Penarikan",
    "Success": "Sukses",
    "stop_investing": "berhenti berinvestasi",
    "OrderSuccessful": "Pesanan Sukses",
    "Handledataerror": "Kesalahan dalam memproses data",
    "Copysuccess": "Berhasil menyalin",
    "MyBalanceLoding": "Saldo Saya Sedang Dimuat",
    "UPDATE": "MENINGKATKAN",
    "New_version_available": "Versi baru tersedia",
    "Please,update_app_to_new_version": "Silakan, perbarui aplikasi ke versi baru",
    "tabbar": {
        "Home": "Beranda",
        "AndarBahar": "Andar Bahar",
        "Invest": "Investasi",
        "Bonus": "Bonus",
        "Mine": "Dompet",
        "Trending": "Trending",
        "Wallet": "Dompet",
        "Rewards": "Hadiah"
    },
    "homeTop": {
        "Sign_Up": "Daftar",
        "Sign_up_for_rewards": "Daftar untuk mendapatkan hadiah",
        "up_to": "hingga",
        "TOTAL_ASSETS": "TOTAL ASET",
        "User_ID": "ID Pengguna",
        "Registration_time": "Waktu Pendaftaran"
    },
    "PieChart": {
        "BESAR & KECIL": "Besar & Kecil",
        "NOMOR": "Nomor",
        "GANJIL & GENAP": "Ganjil & Genap",
        "SEMUA_TREN_NOMOR": "Semua Trending Nomor",
        "TREN_NOMOR_BESAR_KECIL": "Tren Nomor Besar & Kecil",
        "TREN_NOMOR_GANJIL_GENAP": "Tren Nomor Ganjil & Genap"
    },
    "register": {
        "checkerr": "Kata Sandi: Gunakan 8 karakter atau lebih (kombinasi huruf dan angka)",
        "tips_3": "Jika Anda tidak menerima kode di kotak masuk, periksa folder spam Anda.",
        "receiveCode": "Tidak menerima kode?",
        "receiveCode_title": "Kode dikirim ke email Anda. Jika Anda belum menerima kode setelah beberapa kali percobaan, coba:",
        "receiveCode_text1": "1. Periksa apakah itu ada di kotak surat sampah / spam Anda.",
        "receiveCode_text2": "2. Pastikan alamat email Anda adalah",
        "receiveCode_text3": "3. Pesan mungkin tertunda selama beberapa menit. Coba lagi setelah 20 menit.",
        "receiveCode_text4": "4. Jika alamat email ini sudah ada, kami tidak akan mengirimkan kode otentikasi ke Anda. Silakan login atau reset kata sandi Anda.",
        "Sign_up_for_Rewards": "Daftar untuk Reward",
        "Email_Verification": "Verifikasi Email",
        "tips": "Silakan masukkan kode verifikasi 6 digit yang dikirimkan ke {{formData.email}}. Kode ini berlaku selama 30 menit.",
        "tips_1": "Silakan masukkan kode verifikasi 6 digit yang dikirimkan ke ",
        "tips_2": "Kode ini berlaku selama 30 menit.",
        "Resend_code_in": "Kirim ulang kode dalam",
        "Sent_again": "Kirim ulang",
        "CREATE_ACCOUNT": "BUAT AKUN",
        "inviteCodecheck": "Kode undangan tidak boleh kosong!",
        "Mobile": "Nomor Telepon Seluler",
        "Mobilemsg": "Nomor telepon seluler diperlukan",
        "Password": "Kata sandi",
        "Passwordmsg": "Kata sandi diperlukan",
        "OTPCode": "Kode OTP",
        "OTPCodemsg": "Kode OTP diperlukan",
        "SendOTP": "Kirim OTP",
        "Email": "Email",
        "Emailmsg": "Email diperlukan",
        "Invitecode": "Kode Referral (opsional)",
        "Invitecodemsg": "Kode referral diperlukan",
        "emailFormatter": "Silakan masukkan alamat email yang benar",
        "usernameFormatter": "Nama pengguna harus berupa kombinasi karakter dan angka dan panjangnya 6<=length<=20",
        "phoneFormatter": "Silakan isi nomor telepon seluler 10 digit",
        "msgsuccess": "Berhasil mendaftar",
        "msgerr": "Gagal mendaftar",
        "errorphonecheck": "Nomor telepon seluler tidak boleh kosong!",
        "phonecheck": "Silakan isi nomor telepon seluler 10 digit!",
        "imgcodecheck": "Kode OTP tidak boleh kosong!"
    },
    "login": {
        "Welcome_back": "Selamat datang kembali",
        "tips": "Saya telah membaca dan menyetujui {{this.$LogoName}} Syarat Penggunaan dan Kebijakan Privasi",
        "tips_1": "Saya telah membaca dan menyetujui",
        "tips_2": "Syarat Penggunaan",
        "tips_3": "dan",
        "tips_4": "Kebijakan Privasi",
        "Continue_with_Google": "Lanjutkan dengan Google",
        "Continue_with_Apple": "Lanjutkan dengan Apple",
        "text": "Belum punya akun?",
        "text2": "Sudah punya akun?",
        "Login": "Masuk",
        "Register": "Daftar",
        "username": "Nama pengguna atau nomor telepon",
        "password": "Kata sandi",
        "imgcode": "Kode gambar",
        "BackHome": "Kembali ke beranda",
        "Forgetpwd": "Lupa kata sandi"
    },
    "forgot": {
        "title": "Lupa Kata Sandi",
        "msgsuccess": "Reset kata sandi berhasil",
        "newpassword": "Kata sandi baru",
        "newpasswordmsg": "Kata sandi baru diperlukan",
        "Continue": "Lanjut"
    },
    "home": {
        "content11": "Inviters dapat menerima bonus yang proporsional dengan volume transaksi yang dihasilkan oleh pengundang mereka. Bonus maksimum untuk undangan yang valid adalah Unlimit USDT.",
        "Revolutionize_Trading": "Revolutionize Trading",
        "Shape_the_Future": "Shape the Future",
        "content0": "Mari Bersama-sama Mengalami Era Baru Pertukaran Mata Uang Kripto!",
        "3_Reasons_for_Choosing": "3 Alasan untuk Memilih",
        "Innovative_Trading_Features": "Fitur Perdagangan Inovatif",
        "content1": "Pengalaman perdagangan revolusioner yang berbeda dengan apa pun yang tersedia saat ini di pasaran. Dirancang untuk memberi Anda keunggulan kompetitif dengan fitur perdagangan inovatif, tindakan keamanan canggih, dan kecepatan transaksi yang sangat cepat.",
        "Lower_Fees_and_Better_Rewards": "Biaya Rendah dan Hadiah Lebih Baik",
        "content2": "Antarmuka kami membuat perdagangan sederhana untuk pemula. Anda dapat membuat akun secara gratis, berdagang kripto dengan biaya transaksi rendah dari pasar kami. Selain itu, Anda dapat membagikan hingga 20% biaya transaksi dari pertukaran untuk semua pengguna yang Anda undang.",
        "Institutional-grade_Security": "Keamanan Tingkat Institusional",
        "content3": "mengikuti salah satu standar keamanan tertinggi di industri. Kami juga menyimpan 100% kripto Anda dalam penyimpanan dingin. Kami menjamin rasio cadangan 1:1 dari dana pelanggan kami. Dana 300M USDT kami memberikan lapisan perlindungan tambahan terhadap ancaman keamanan.",
        "Start_investing_in_as_little_as_5_minutes": "Mulailah berinvestasi dalam waktu kurang dari 5 menit",
        "content4": "Daftar dengan {{this.$LogoNames.toUpperCase ()}} secara gratis",
        "content4_1": "Daftar dengan",
        "content4_2": " secara gratis",
        "Complete_google_authentication": "Lengkapi otentikasi google",
        "content5": "Masukkan dan verifikasi alamat email Anda untuk memulai. Tidak ada biaya untuk membuka akun.",
        "content7": "Buka fungsi penuh dengan memverifikasi pengautentikasian google Anda dalam waktu kurang dari 5 menit.",
        "content8": "Dana akun {{this.$LogoNames.toUpperCase()}} Anda secara instan",
        "content8_1": "Dana akun ",
        "content8_2": " Anda secara instan",
        "content9": "Deposit USDT melalui jaringan terpilih dan dapatkan akses instan ke dana Anda. Tidak perlu menunggu 3-5 hari untuk memulai perdagangan.",
        "Start_building_your_crypto_portfolio": "Mulailah membangun portofolio kripto Anda",
        "Invitation_Program": "Program Undangan",
        "Referral_Program": "Program Rujukan",
        "content10": "Selesaikan undangan yang valid dan dapatkan bonus undangan dengan rata-rata nilai 10 USDT. Bonus maksimum untuk satu orang adalah ",
        "TRADING_TASKS": "TUGAS PERDAGANGAN",
        "content12": "Pengguna akan menerima imbalan dalam USDT saat berdagang, berbagai tugas perdagangan untuk mode perdagangan yang berbeda. Hadiah akan didistribusikan secara instan",
        "Trade_anytime_anywhere": "Perdagangan kapan saja, di mana saja",
        "content13": "USA, Inc. diizinkan untuk melakukan kegiatan bisnis mata uang virtual oleh Departemen Layanan Keuangan Negara Bagian New York.",
        "USA_Inc": "USA, Inc",
        "content6": "Unduh aplikasi kami untuk tetap terhubung 24/7",
        "Legal&Disclosures": "Hukum & Pengungkapan",
        "Regulatory_License": "Lisensi Regulasi",
        "AML/KYC_Policies": "Kebijakan AML/KYC",
        "Privacy_Policy": "Kebijakan Privasi",
        "Terms_of_Use": "Syarat Penggunaan",
        "Legal_Statement": "Pernyataan Hukum",
        "Risk_Disclosure": "Pengungkapan Risiko",
        "About_Us": "Tentang Kami",
        "Protection_Fund": "Dana Perlindungan",
        "Proof_of_Reserves": "Bukti Cadangan",
        "Twitter": "Twitter",
        "Bitget_Token": "Token Bitget",
        "Careers": "Karir",
        "Company": "Perusahaan",
        "Support_Service": "Dukungan",
        "title": "Buka dengan aplikasi",
        "titlebtn": "Unduh",
        "Balance": "Saldo",
        "ID": "ID",
        "Recharge": "Isi ulang",
        "Withdraw": "Tarik",
        "Bonus": "Bonus",
        "Checkin": "Daftar masuk",
        "Eventclosed": "Acara ditutup",
        "Ranking": "Peringkat"
    },
    "Ranking": {
        "Today_s winning ranking": "Peringkat kemenangan hari ini",
        "tips1": "Semakin besar jumlah kemenangan = semakin tinggi peringkat!",
        "UserName": "Nama pengguna",
        "Winning Amount": "Jumlah kemenangan",
        "Today_s rebate ranking": "Peringkat rabat hari ini",
        "tips2": "Rabat lebih tinggi untuk pengguna tingkat rendah = peringkat lebih tinggi!",
        "Rebate Amount": "Jumlah rabat",
        "Rebate ranking": "Peringkat rabat top 20"
    },
    "Checkin": {
        "Checkin": "Check in",
        "CheckinList": "Daftar Check in"
    },
    "AndarBahar": {
        "title": "Andar Bahar",
        "Rule": "Aturan",
        "Record": "Rekor",
        "Records": "Rekor Andar Bahar",
        "MyOrder": "Pesanan Saya",
        "more": "lebih",
        "Period": "Periode",
        "Order": "Pesanan",
        "Buy": "Beli",
        "Result": "Hasil",
        "Profit": "Keuntungan",
        "AndarBaharRule": "Aturan Andar Bahar",
        "CountDown": "Hitung Mundur",
        "Andar": "Andar",
        "Bahar": "Bahar",
        "Tie": "Seri",
        "result": "hasil",
        "ok": "oke",
        "start": "mulai",
        "Point": "Titik",
        "ContractPoint": "Titik Kontrak",
        "Number": "Nomor",
        "Delivery": "Pengiriman",
        "Fee": "Biaya",
        "Amount": "Jumlah"
    },
    "pokerOrder": {
        "OrderRecord": "Catatan Pesanan",
        "WatingOrder": "Pesanan Menunggu",
        "HistoryOrder": "Riwayat Pesanan",
        "Nomoredata": "Tidak ada data lagi",
        "Buy": "Beli",
        "Result": "Hasil",
        "Time": "Waktu",
        "Amount": "Jumlah",
        "Fee": "Biaya",
        "Profit": "Keuntungan"
    },
    "pokerRecord": {
        "title": "Catatan Andar Bahar",
        "Period": "Periode",
        "Result": "Hasil",
        "OpenTime": "Waktu Buka",
        "Nomore": "Tidak ada lagi"
    },
    "Google2FA": {
        "title": "Google Authenticator",
        "Authenticator_Instructions": "Instruksi Authenticator",
        "Authenticator_Status": "Status Authenticator",
        "Google_Authenticator": "Google Authenticator",
        "Download_Google_Authenticator_App": "Unduh Aplikasi Google Authenticator",
        "App_Store": "App Store",
        "Google_Play": "Google Play",
        "Next": "Lanjut",
        "Backup_key": "Kunci Cadangan",
        "Backup_key_text": "Harap simpan Kunci Cadangan ini di tempat yang aman. Kunci ini akan memungkinkan Anda untuk memulihkan Authenticator Anda jika Anda kehilangan ponsel Anda. Jika tidak, mereset Authenticator Anda harus berkonsultasi dengan layanan pelanggan.",
        "Verifiaction": "Verifikasi",
        "Verifiaction_text": "Masukkan kode verifikasi Google",
        "Google_verification_code": "Kode verifikasi Google",
        "contentlist1": "1. Catat Kunci Cadangan 16 digit dari +this.$LogoName+ dan simpan di tempat yang aman.",
        "contentlist1_1": "1. Catat Kunci Cadangan 16 digit dari",
        "contentlist1_2": "dan simpan di tempat yang aman.",
        "contentlist2": "2. Buka Google Authenticator.",
        "contentlist3": "3. Tambahkan akun +this.$LogoName+ dan masukkan kunci 16 digit yang disalin.",
        "contentlist3_1": "3. Tambahkan akun",
        "contentlist3_2": "dan masukkan kunci 16 digit yang disalin.",
        "contentlist4": "4. Anda akan melihat kode verifikasi 6 digit di layar.",
        "contentlist5": "5. Salin kode 6 digit dan tempelkan di +this.$LogoName+.",
        "contentlist5_1": "5. Salin kode 6 digit dan tempelkan di",
        "contentlist5_2": ".",
        "contentlist6": "6. Kemudian, Authenticator Anda akan berhasil terhubung."
    },
    "win": {
        "Live_Price": "Harga Langsung",
        "title1": "Hasil investasi dihitung berdasarkan digit terakhir dari Binance BTC/USDT Spot Trade yang sesuai dengan waktu UTC. Investor dapat memeriksa hasilnya di BINANCE kapan saja untuk memastikan keadilan dan keamanan investasi.",
        "title": "Ada 2 mode dalam investasi",
        "1M_Mode": "Lite 1M",
        "1M_Mode_text": "Setiap putaran memakan waktu 1 menit. Mode Lite hanya menyediakan investasi nomor Ganjil & Genap. Hasil dihitung berdasarkan digit terakhir dari hasil tutup perdagangan Binance BTC/USDT Spot 1M interval.",
        "5M_Mode": "Pro 5M",
        "5M_Mode_text": "Setiap putaran memakan waktu 5 menit. Mode Pro membuka metode investasi penuh dari EPOCH EPT. Hasil dihitung berdasarkan digit terakhir dari hasil tutup perdagangan Binance BTC/USDT Spot 5M interval.",
        "5M_Mode_text_1": "Setiap putaran memakan waktu 5 menit. Mode Pro membuka metode investasi penuh dari",
        "5M_Mode_text_2": ". Hasil dihitung berdasarkan digit terakhir dari hasil tutup perdagangan Binance BTC/USDT Spot 5M interval.",
        "Investment_Guide": "Panduan Investasi",
        "Investment_Rules": "Aturan Investasi",
        "title2": "Bagaimana membuktikan hasil yang adil?",
        "Investment_Info": "Informasi Investasi",
        "3M_Mode": "Mode 3M",
        "3M_Mode_text": "Setiap putaran memakan waktu 3 menit. Hasil dihitung berdasarkan digit terakhir dari hasil tutup perdagangan margin BINANCE 3M interval.",
        "Investment_return": "Pengembalian Investasi",
        "Number": "Nomor",
        "Profit_Invested_amount": "Keuntungan: Jumlah investasi",
        "guide1": "Pilih mode waktu.",
        "guide2": "Atur jumlah investasi Anda.",
        "guide3": "Pilih nomor yang ingin Anda investasikan.",
        "guide4": "Periksa portofolio Anda dan klik investasi.",
        "NEXT": "NEXT",
        "Invest_Amount": "Jumlah Investasi",
        "Total": "Total",
        "INVEST": "INVESTASI",
        "Portfolio": "Portofolio",
        "Invest": "Investasikan",
        "BINANCE": "BINANCE",
        "Bitcoin_Price": "Harga Bitcoin",
        "InvestmentRecord": "Rekam Investasi",
        "Best_Assistant": "Asisten Investasi",
        "last_100_rounds": "(100 putaran terakhir)",
        "What_do_these_data_mean": "Apa makna data ini",
        "Missing": "Tidak Ada",
        "Missing_text": "Itu berarti jumlah putaran sejak putaran terakhir yang menghasilkan angka ini.",
        "Frequency": "Frekuensi",
        "Frequency_text": "Merujuk pada jumlah kali angka yang sesuai muncul dalam 100 putaran terakhir.",
        "Max_Continued": "Maksimum Terus",
        "Max_Continued_text": "Merujuk pada periode penampilan berurutan maksimum dari angka yang sesuai dalam 100 putaran terakhir.",
        "Rule": "Aturan",
        "Trend": "Tren",
        "MyBalance": "Saldo Saya",
        "JoinGreen": "Bergabung dengan Hijau",
        "JoinRed": "Bergabung dengan Merah",
        "JoinViolet": "Bergabung dengan Ungu",
        "Parity": "Ganjil/Genap",
        "Sapre": "Rendah/Tinggi",
        "Bcone": "B1-B6",
        "Emerd": "E1-E6",
        "Record": "Catatan",
        "more": "selengkapnya",
        "Period": "Periode",
        "Price": "Harga",
        "Result": "Hasil",
        "My": "Saya",
        "Order": "Pesan",
        "Buy": "Beli",
        "Profit": "Keuntungan",
        "Green": "Hijau",
        "Red": "Merah",
        "Violet": "Ungu",
        "tips": "Biaya transaksi adalah {bet_fee*100}% dari jumlah pesanan",
        "tips1": "Biaya transaksi adalah ",
        "tips2": "dari jumlah pesanan ",
        "OrderAmount": "Jumlah Pesanan",
        "Quantity": "Kuantitas",
        "TransactionFee": "Biaya Transaksi",
        "TotalAmount": "Jumlah Total",
        "PresalePolicy": "Kebijakan Privasi",
        "Iagree": "Saya menyetujui",
        "err1": "Harap setujui terlebih dahulu kebijakan pengelolaan pra-penjualan"
    },
    "historyPeriods": {
        "Orderrecord": "Catatan pesanan",
        "record": "catatan"
    },
    "historyOrderPeriods": {
        "WatingOrder": "Pesanan Menunggu",
        "HistoryOrder": "Riwayat Pesanan"
    },
    "Activity": {
        "added_to_account_balance": "ditambahkan ke saldo akun.",
        "contentText": "Ketika undangan menyelesaikan deposit pertama, dianggap sebagai undangan yang valid. Pemberi undangan akan menerima bonus undangan untuk setiap undangan yang valid. Bonus undangan berkisar dari {{aaaaaa}}USDT untuk 1 undangan yang valid hingga {{aaaaaa}}USDT untuk 500 undangan yang valid.",
        "contentText_1": "Ketika undangan menyelesaikan deposit pertama, dianggap sebagai undangan yang valid. Pemberi undangan akan menerima bonus undangan untuk setiap undangan yang valid. Bonus undangan berkisar dari",
        "contentText_2": "untuk 1 undangan yang valid hingga",
        "contentText_3": "untuk 500 undangan yang valid",
        "contentText_3_1": "untuk",
        "contentText_3_2": "undangan yang valid",
        "contentText2": "Pemberi undangan akan berbagi {{LV.1%}} biaya perdagangan dari setiap volume perdagangan undangan LV.1. Pemberi undangan akan berbagi {{LV.2%}} biaya perdagangan dari setiap volume perdagangan undangan LV.2. Bonus maksimum untuk bonus volume perdagangan undangan tidak terbatas.",
        "contentText2_1": "Pemberi undangan akan berbagi",
        "contentText2_2": "biaya perdagangan dari setiap volume perdagangan undangan LV.1. Pemberi undangan akan berbagi",
        "contentText2_3": "biaya perdagangan dari setiap volume perdagangan undangan LV.2. Bonus maksimum untuk bonus volume perdagangan undangan tidak terbatas.",
        "My_Rewards_History": "Riwayat Hadiah Saya",
        "Cumulative_Single_Number_Trading_Volumn": "Volume Perdagangan Tunggal Kumulatif",
        "Cumulative_ODD_Number_Trading_Volumn": "Volume Perdagangan Bilangan GANJIL Kumulatif",
        "Cumulative_EVEN_Number_Trading_Volumn": "Volume Perdagangan Bilangan GENAP Kumulatif",
        "Cumulative_BIG_Number_Trading_Volumn": "Volume Perdagangan Bilangan BESAR Kumulatif",
        "Cumulative_SMALL_Number_Trading_Volumn": "Volume Perdagangan Bilangan KECIL Kumulatif",
        "Advanced_Tasks": "Tugas Lanjutan",
        "Invite_Friends_and_Earn_Up_to": "Undang Teman dan Dapatkan Hingga",
        "Referral_Code": "Kode Referral",
        "Referral_Link": "Tautan Referral",
        "INVITE_FRIENDS": "UNDANG TEMAN",
        "Complete_tasks_to_earn_up_to": "Lakukan tugas untuk mendapatkan hingga",
        "MY_REWARDS": "HADIAH SAYA",
        "SHARE": "BAGIKAN",
        "Earn_with_your_friends_together": "Dapatkan bersama temanmu",
        "Get_your_link": "Dapatkan tautanmu",
        "Claim_your_rewards": "Klaim hadiahmu",
        "Earn_together": "Dapatkan bersama-sama",
        "contentlist0": "Bergabunglah dengan {{gogobit}} dan dapatkan tautan referralmu. Pengguna yang mendaftar melalui tautan ini akan menerima hadiah.",
        "contentlist0_1": "Bergabunglah dengan",
        "contentlist0_2": " dan dapatkan tautan referralmu. Pengguna yang mendaftar melalui tautan ini akan menerima hadiah.",
        "contentlist1": "Anda akan menerima hadiah referral rata-rata senilai {{XX}} USDT setelah teman Anda melakukan deposit pertama, dan menyelesaikan volume perdagangan yang diperlukan. Hadiah dapat mencapai {{XX}} USDT.",
        "contentlist1_1": "Anda akan menerima hadiah referral rata-rata senilai ",
        "contentlist1_2": " USDT setelah teman Anda melakukan deposit pertama, dan menyelesaikan volume perdagangan yang diperlukan. Hadiah dapat mencapai ",
        "contentlist1_3": " USDT.",
        "contentlist2": "Setelah temanmu membuat akun, dia dapat mengklaim bonus perdagangan hingga 5.050 USDT.",
        "Referral_Rewards": "Hadiah Referral",
        "Detail_Rules": "Aturan Detail",
        "Invitation_Bonus": "Bonus Undangan",
        "Up_to": "Hingga",
        "content0": "Lakukan satu undangan yang valid dan dapatkan bonus undangan rata-rata hingga 10 USDT. Bonus maksimum untuk seratus orang undangan adalah ",
        "content0_1": "Lakukan satu undangan yang valid dan dapatkan bonus undangan rata-rata hingga ",
        "content0_2": "Bonus maksimum untuk seratus orang undangan adalah ",
        "Trading_Bonus": "Bonus Perdagangan",
        "content1": "Pemilik undangan dapat menerima bonus yang sebanding dengan volume transaksi yang dihasilkan oleh undangan mereka. Bonus maksimum untuk satu undangan yang valid adalah ",
        "My_Referral_History": "Riwayat Undangan Saya",
        "Total_Invitees": "Total Undangan",
        "Valid_Invitees": "Undangan yang Valid",
        "Cumulative_Invitees": "Undangan Kumulatif",
        "Trading_Volumn": "Volume Perdagangan",
        "Daily_Tasks": "Tugas Harian",
        "Detail_Rules_title": "Undian Keberuntungan Harian 888 USDT",
        "Detail_Rules_title1": "1. Data perdagangan berjangka pada hari itu akan dihitung keesokan harinya.",
        "Detail_Rules_title2": "2. Pengguna yang melakukan volume perdagangan berjangka sebesar 200.000 USDT atau lebih pada hari itu akan mendapatkan kesempatan mengundih satu kali untuk hadiah 888 USDT.",
        "CLAIM_NOW": "KLAIM SEKARANG",
        "INVEST_NOW": "INVESTASI SEKARANG",
        "title": "Dompet Bonus",
        "BonusBalance": "Saldo Bonus",
        "Withdrawtobalance": "Tarik ke saldo",
        "BonusList": "Daftar Bonus",
        "Bonuswithdrawtobalance": "Bonus tarik ke saldo",
        "WithdrawalAmount": "Jumlah Penarikan",
        "Pleaseentertheamount": "Silakan masukkan jumlah",
        "err1": "Jumlah penarikan tidak boleh 0",
        "success": "Berhasil transfer"
    },
    "Refer": {
        "title": "Referensi Saya",
        "ReferTotalBonus": "Total Bonus Referensi",
        "InviteFriends": "Undang Teman",
        "Level1": "Level 1",
        "Level2": "Level 2",
        "TotalPeople": "Total Orang",
        "Bonus": "Bonus",
        "Myinvitationlink": "Tautan Undangan Saya",
        "Rebate_description": "Deskripsi Rebate"
    },
    "Task": {
        "InviteBonusToday": "Bonus Undangan Hari Ini",
        "bonusrecord": "Catatan Bonus",
        "hint1": "1. Data diperbarui setiap 10-30 menit!",
        "hint2": "2. Hanya orang yang mendaftar dalam 3 hari terakhir dan menyelesaikan pengisian ulang hari ini yang dihitung!",
        "hint2_1": "2. Hanya orang yang mendaftar dalam ",
        "hint2_2": " hari terakhir dan menyelesaikan pengisian ulang hari ini yang dihitung!",
        "hint3": "3. Deposit minimum 20u diperlukan hari ini!",
        "hint3_1": "3. Deposit minimum ",
        "hint3_2": " diperlukan hari ini!",
        "hint4": "Jumlah orang yang diundang dalam tiga hari terakhir",
        "hint4_1": "Jumlah orang yang diundang dalam ",
        "hint4_2": " hari terakhir",
        "hint5": "Jumlah orang yang menyelesaikan pengisian ulang hari ini",
        "Invite": "Undang",
        "friendstorecharge": "teman untuk mengisi ulang",
        "Earn": "Dapatkan",
        "Completed": "Selesai",
        "ExpireTime": "Waktu Kadaluwarsa"
    },
    "Invited": {
        "title": "Catatan Bonus",
        "InviteTotalBonus": "Total Bonus Undangan",
        "InviteTime": "Waktu Undangan",
        "Bonus": "Bonus",
        "NoRecored": "Tidak ada catatan"
    },
    "mine": {
        "ProvacyPolicy": "Kebijakan Privasi",
        "JOIN US": "BERGABUNG BERSAMA KAMI",
        "title": "Pusat Personal",
        "InviteCode": "Kode Undangan",
        "AccountBalance": "Saldo Akun",
        "BonusBalance": "Saldo Bonus",
        "Wallet": "Dompet",
        "Bank": "Bank",
        "Invite": "Undang",
        "Refer": "Refer",
        "CustomerService": "Layanan Pelanggan",
        "ComplaintsSuggestions": "Keluhan&Saran",
        "ChangePassword": "Ubah Password",
        "Activity": "Aktivitas",
        "PresalePolicy": "Kebijakan Privasi",
        "DownloadApp": "Unduh Aplikasi",
        "AppVersion": "Versi Aplikasi",
        "Logout": "Keluar",
        "Logouttips": "Apakah Anda yakin ingin keluar?"
    },
    "App": {
        "title": "Unduh Aplikasi",
        "DownloadforAndroid": "Unduh untuk Android",
        "DownloadforiOS": "Unduh untuk iOS"
    },
    "Presale": {
        "title": "Kebijakan Privasi"
    },
    "Account": {
        "title2": "Atur kata sandi baru",
        "title2_text": "Atur kata sandi baru untuk akun Google Anda. Anda dapat masuk ke akun Google Anda menggunakan kata sandi.",
        "title_text": "Anda mencoba mereset kata sandi Anda. Harap siapkan Google Authenticator Anda untuk reset.",
        "err1": "Dua kata sandi baru yang dimasukkan berbeda",
        "success2": "Kata sandi berhasil diubah",
        "title": "Ubah Kata Sandi",
        "OldPassword": "Kata Sandi Lama",
        "OldPasswordmsg": "kata sandi lama diperlukan",
        "NewPassword": "Kata Sandi Baru",
        "NewPasswordmsg": "kata sandi baru diperlukan",
        "RetypeNewPassword": "Ketik Ulang Kata Sandi Baru",
        "RetypeNewPasswordmsg": "ketik ulang kata sandi baru",
        "Continue": "Lanjutkan"
    },
    "Service": {
        "title": "Layanan",
        "CONTACTUS": "HUBUNGI KAMI",
        "tips": "Layanan pelanggan sedang istirahat!",
        "WhatsApp": "WhatsApp",
        "Telegram": "Telegram",
        "link": "tautan"
    },
    "Bank": {
        "BankName": "Nama Bank",
        "ChooseaCurrencyType": "Pilih Jenis Mata Uang",
        "SelectWithdrawalMethod": "Pilih Metode Penarikan",
        "Newbankcard": "Kartu Bank Baru",
        "Editbankcard": "Edit kartu bank",
        "title": "Bank Saya",
        "Cannotbeempty": "Tidak boleh kosong",
        "BankHolderName": "Nama Pemilik Bank",
        "BankAccountNumber": "Nomor Rekening Bank",
        "IFSCCode": "Kode IFSC",
        "Email": "Email",
        "Phone": "Telepon",
        "OTPCode": "Kode OTP",
        "tips1": "1. Harap gunakan nomor ponsel yang Anda daftarkan untuk menerima OTP",
        "tips2": "2. Untuk keamanan dana, kartu bank hanya dapat dimodifikasi sekali sehari",
        "Add": "Tambahkan",
        "error1": "Nama Pemilik Bank tidak boleh kosong!",
        "error2": "Harap masukkan Nama Pemilik Bank yang benar!",
        "error3": "Nama Bank tidak boleh kosong!",
        "error4": "Harap masukkan Nama Bank yang benar!",
        "error5": "Nomor Rekening Bank tidak boleh kosong!",
        "error6": "Harap masukkan Nomor Rekening Bank yang benar!",
        "error7": "Kode IFSC tidak boleh kosong!",
        "error8": "Harap masukkan Kode IFSC yang benar!",
        "error9": "Email tidak boleh kosong!",
        "error10": "Harap masukkan alamat email yang benar!",
        "error11": "Telepon tidak boleh kosong!",
        "error12": "Kode OTP tidak boleh kosong!",
        "Successfully": "Berhasil menambahkan kartu bank"
    },
    "wallet": {
        "title": "Dompet saya",
        "MyBalance": "Saldo saya",
        "MyBank": "Bank saya",
        "RechargeRecords": "Catatan pengisian",
        "WithdrawalRecords": "Catatan penarikan"
    },
    "Rechargepage": {
        "Reminder": "Pengingat",
        "Reminder_1": "1. Jangan melakukan deposit kecuali dengan mata uang USDT ke alamat ini. Mengirimkan koin atau token yang tidak didukung ke alamat ini dapat mengakibatkan kerugian yang tidak dapat dipulihkan.",
        "Reminder_2": "2. Jumlah deposit minimum adalah 5USDT.",
        "Reminder_2_1": "2. Jumlah deposit minimum adalah ",
        "Reminder_2_2": " USDT.",
        "Reminder_3": "3. Deposit akan tiba setelah 15 konfirmasi jaringan.",
        "Minimum": "Minimum",
        "title": "Deposit",
        "History": "Riwayat Deposit",
        "Mybalance": "Saldo saya",
        "RechargeAmount": "Jumlah Deposit",
        "RechargeAmountmsg": "Silakan masukkan jumlahnya",
        "tips1": "Saluran pembayaran sedang dalam perawatan!",
        "tips2": "Silakan pilih saluran pembayaran",
        "PayNow": "Bayar Sekarang",
        "err1": "Saluran pembayaran sedang dalam perawatan",
        "err2": "Jumlah tidak boleh kurang dari ",
        "err3": "Silakan pilih saluran pembayaran",
        "Rechargemethod": "Metode Deposit"
    },
    "Rechargerecord": {
        "title": "Catatan isi ulang",
        "WatingOrder": "Pesanan Menunggu",
        "HistoryOrder": "Riwayat Pesanan",
        "Rupee": "Dolar",
        "Status": "Status",
        "Time": "Waktu"
    },
    "withdrawal": {
        "Tips": "Tips",
        "Tips_1": "Tips: Biaya penarikan untuk kurang dari ",
        "Tips_2": " USDT adalah ",
        "Tips_3": " USDT, biaya penarikan untuk ",
        "Tips_4": "USDT atau lebih adalah",
        "Tips_5": "%",
        "WalletAddress": "Alamat Wallet",
        "Addwalletaddress": "Tambahkan alamat wallet",
        "Network_fee": "Biaya jaringan",
        "Receive_amount": "Jumlah yang diterima",
        "Select Chains": "Pilih Rantai",
        "Bankcard": "Kartu bank",
        "Nobankcardselected": "Tidak ada kartu bank yang ditambahkan",
        "Withdrawalfee": "Biaya penarikan",
        "title": "Penarikan",
        "Mybalance": "Saldo Saya",
        "Withdrawablebalance": "Saldo yang dapat ditarik",
        "tips1": "Jumlah total pembelian harus lebih dari {{codeAmount}} dapat ditarik sepenuhnya",
        "tips1-1": "Jumlah total pembelian harus lebih dari",
        "tips1-2": "dapat ditarik sepenuhnya",
        "tips2": "Penarikan per hari adalah {{draw_num}} kali",
        "tips2-1": "Penarikan per hari adalah",
        "tips2-2": "kali",
        "tips3": "Jumlah penarikan harian maksimum adalah",
        "tips4": "Jumlah penarikan tunggal minimum adalah ₹{{draw_min_money}} dan jumlah penarikan tunggal maksimum adalah ₹{{draw_max_money}}",
        "tips4-1": "Jumlah penarikan tunggal minimum adalah",
        "tips4-2": "dan jumlah penarikan tunggal maksimum adalah",
        "tips5": "Waktu penarikan adalah {{draw_time}}, silakan tarik pada waktu yang ditentukan",
        "tips5-1": "Waktu penarikan adalah",
        "tips5-2": "silakan tarik pada waktu yang ditentukan",
        "tips6": "Penarikan tunai akan dikenakan biaya sebesar {{draw_fee}}% dari biaya penarikan tunai",
        "tips6-1": "Penarikan tunai akan dikenakan biaya sebesar",
        "tips6-2": "dari biaya penarikan tunai",
        "tips7": "Jika jumlah penarikan Anda kurang dari atau sama dengan ₹{{solid_min_amount}}, biaya tetap sebesar ₹{{solid_feemoney}} akan dikenakan",
        "tips7-1": "Jika jumlah penarikan Anda kurang dari atau sama dengan",
        "tips7-2": "biaya tetap sebesar",
        "tips7-3": "akan dikenakan",
        "WithdrawalAmount": "Jumlah penarikan",
        "WithdrawalAmountmsg": "Silakan masukkan jumlah penarikan",
        "Withdrawal": "Tarik",
        "WithdrawalHistory": "Riwayat Penarikan",
        "Tipsmsg": "Pengajuan penarikan berhasil, menunggu hasil audit!",
        "dangerMsg1": "Silakan tambahkan kartu bank Anda terlebih dahulu",
        "dangerMsg2": "Jumlah penarikan tidak dapat kurang dari",
        "dangerMsg3": "Jumlah penarikan harus kelipatan 100",
        "dangerMsg4": "Jumlah penarikan tidak dapat lebih besar dari",
        "dangerMsg5": "Jumlah penarikan tidak dapat lebih besar dari saldo penarikan yang tersedia",
        "dangerMsg6": "Silakan tambahkan kartu bank Anda terlebih dahulu"
    },
    "withdrawalrecord": {
        "title": "rekaman penarikan",
        "WatingOrder": "Pesanan Menunggu",
        "HistoryOrder": "Riwayat Pesanan",
        "Rupee": "Dolar",
        "Status": "Status",
        "Time": "Waktu",
        "Nomore": "Tidak ada lagi!"
    },
    "Version": {
        "title": "Versi",
        "stableversion": "versi stabil",
        "Sudah versi terbaru": "Sudah versi terbaru",
        "Newversionfound": "Versi baru ditemukan",
        "DownloadUpdate": "Unduh pembaruan"
    },
    "Bonus": {
        "title": "Aktivitas Bonus",
        "ReceiveBonus": "Terima Bonus",
        "Hi": "Halo",
        "tips1": "Selamat, Anda telah mendapatkan bonus",
        "tips2": "Anda harus melakukan pengisian saldo lebih besar atau sama dengan",
        "tips3": "dan bonus akan secara otomatis ditambahkan ke saldo akun Anda",
        "Received": "Diterima",
        "Back": "Kembali",
        "Rechargenow": "Isi Saldo Sekarang"
    },
    "ComplaintsSuggestions": {
        "title": "Keluhan & Saran",
        "Answered": "Dijawab",
        "AddNew": "Tambah Baru",
        "Time": "Waktu",
        "Type": "Jenis",
        "WhatsApp": "WhatsApp",
        "Description": "Deskripsi",
        "Remark": "Catatan",
        "Waiting": "Menunggu",
        "Suggestion": "Saran",
        "Consult": "Konsultasi",
        "RechargeProblem": "Masalah Isi Ulang",
        "WithdrawProblem": "Masalah Penarikan",
        "OrderProblem": "Masalah Pesanan",
        "Other": "Lainnya",
        "Describeyourproblems": "Jelaskan masalah Anda",
        "WhatsApptocontactyou": "WhatsApp untuk menghubungi Anda",
        "submit": "kirim",
        "err1": "WhatsApp tidak boleh kosong!",
        "err2": "Jenis tidak boleh kosong!",
        "err3": "Deskripsi tidak boleh kosong!"
    },
    "slotMachine": {
        "title": "Mesin Buah",
        "Myblance": "Saldo saya",
        "Records": "Catatan Mesin Buah",
        "titleRecord": "Catatan"
    },
    "bankrecharge": {
        "Orderamount": "Jumlah pesanan",
        "Ordernumber": "Nomor pesanan",
        "BankName": "Nama bank",
        "BankAccount": "Nomor akun bank",
        "IFSC": "Kode IFSC",
        "Yourbankcardnumber": "Nomor kartu bank Anda",
        "Enterthebanktransactionordernumber": "Masukkan nomor pesanan transaksi bank",
        "submit": "Kirim",
        "Tips": "Tips",
        "tips1": "1. Sistem sedang memproses pesanan Anda.",
        "tips2": "2. Silakan hubungi layanan pelanggan jika akun tidak tiba dalam waktu yang lama.",
        "err1": "Silakan masukkan nomor kartu bank Anda",
        "err2": "Silakan masukkan nomor pesanan bank"
    },
    "javaCode": {
        "200": "berhasil",
        "20001": "token akses tidak valid",
        "20002": "token login tidak valid",
        "20003": "kesalahan telepon",
        "20004": "Telepon sudah ada!",
        "20005": "kesalahan email!",
        "20006": "Email sudah ada!",
        "20007": "kesalahan kata sandi!",
        "20010": "kesalahan akun atau kata sandi!",
        "20011": "kesalahan rekening bank!",
        "20012": "kesalahan bank ifsc, (format IFSC standar) - panjang 11, empat pertama IFSC dan 5 digit 0!",
        "20013": "kesalahan nama penerima, 5 <nama <= 50!",
        "20021": "Akun tidak ada!",
        "20022": "Akun dibekukan!",
        "20031": "Lapisan terbatas 10!",
        "20032": "pembaruan kesalahan peran hubungan!",
        "20033": "tidak cukup saldo!",
        "20034": "Jumlah penarikan yang salah!",
        "30001": "Layanan sementara ditutup!",
        "30002": "limit uang taruhan antara 10 dan 50000!",
        "30003": "Masalah saat ini telah selesai!",
        "30005": "Checked in",
        "31001": "Single transaction exceeds the limit !",
        "31002": "Today's total amount has reached the upper limit !",
        "31003": "The number of times today has reached the limit  !",
        "31004": "Current Staff un config, pls contact admin or agent!",
        "200081": "Kesalahan Registrasi!",
        "200082": "Akun telah dinonaktifkan!",
        "200091": "tambah kartu terbatas 10!",
        "200092": "kesalahan masukan login pwd terlalu banyak kali, dan akun dinonaktifkan!",
        "300041": "Semua jumlah transaksi masalah memiliki batas!",
        "300042": "All transaction amount of user has limit !",
        "300043": "Bet item error !",
        "300044": "You have already purchased it and cannot buy it again!",
        "Servererror": "Server error!",
        "Handledataerror": "Silahkan masuk!",
        "-91": "Kesalahan server!",
        "-92": "Kesalahan data yang ditangani!",
        "-1": "Kesalahan sistem!",
        "-2": "kesalahan parameter",
        "-3": "tidak ada data",
        "-4": "operasi yang sering dilakukan dalam waktu singkat",
        "-5": "Sistem sibuk, coba lagi nanti!",
        "-6": "Ada banyak orang saat ini, silakan coba lagi!",
        "-7": "kesalahan data!",
        "-8": "Kesalahan kode Google!",
        "-9": "Sistem sedang dipelihara! silakan coba lagi nanti!",
        "-10": "kesalahan simpan!",
        "-11": "kesalahan hapus!",
        "-12": "kesalahan pembaruan!",
        "-13": "operasi ilegal!",
        "-14": "Operasi gagal!",
        "-15": "Operasi dinonaktifkan!",
        "-16": "Operasi Selesai!",
        "-17": "kesalahan yang ada",
        "-18": "Tidak ada!",
        "-19": "kedaluwarsa",
        "-20": "Telah dinonaktifkan",
        "-21": "kesalahan kode verifikasi",
        "-22": "Interval modifikasi harus lebih besar dari 1 hari",
        "-23": "Kata sandi baru tidak boleh sama dengan kata sandi asli",
        "-100": "kesalahan khusus!",
        "-881": "System is being maintained! please try again later !",
        "-882": "Amount params error",
        "-883": "Channel Params error",
        "-884": "Channel is maintained, pls try again later!",
        "-885": "Channel is maintained, pls try again later!",
        "-886": "Current recharge amount is less than the min limit amount!",
        "-887": "Amount params error",
        "-888": "Maximum number of withdrawals over a day",
        "-889": "Maximum withdrawal amount exceeding one day",
        "-8810": "Not enough code amount, also need code amount ",
        "-8811": "Exceeded the maximum withdrawal amount",
        "-8812": "Below the minimum withdrawal amount",
        "-8813": "Current time is not service for withdraw !"
    },
    "Management": {
        "TotalPurchaseAmount": "Total Jumlah Pembelian",
        "TotalRevenue": "Total Pendapatan",
        "AccountBalance": "Saldo Akun",
        "Income": "Pendapatan",
        "Transaction": "Transaksi",
        "Myhold": "Saham Saya",
        "ProductList": "Daftar Produk",
        "Investmentperiod": "Periode Investasi",
        "days": "hari",
        "Buynow": "Beli sekarang",
        "Saletime": "Waktu penjualan",
        "Nomore": "Tidak ada lagi",
        "ProductDetails": "Detail Produk",
        "ForSale": "Untuk Dijual",
        "Sale": "Dijual",
        "SoldOut": "Habis Terjual",
        "ProductInformation": "Informasi Produk",
        "InvestmentPeriod": "Periode Investasi",
        "TotalShare": "Total Saham",
        "ExpectedReturn": "Pendapatan yang Diharapkan",
        "SaleTime": "Waktu Penjualan",
        "PurchaseConditionsAndRules": "Syarat dan Aturan Pembelian",
        "MinimumPurchaseAmount": "Jumlah Pembelian Minimum",
        "MaximumSalesLimit": "Batasan Penjualan Maksimum",
        "AccountBalanceMustBeGreaterThan": "Saldo Akun Harus Lebih Besar Dari",
        "DayBetIsGreaterThan": "Jumlah Taruhan Lebih Besar Dari",
        "ProductDescription": "Deskripsi Produk",
        "MyHold": "Saham Saya",
        "Ordernumber": "Nomor Pesanan",
        "PurchasingPrice": "Harga Pembelian",
        "NoRecord": "Tidak Ada Rekaman",
        "Expiredate": "Tanggal Jatuh Tempo",
        "Purchasetime": "Waktu Pembelian",
        "redemption": "penebusan",
        "redemptionTips": "Penebusan awal tidak akan menghasilkan pendapatan apa pun, apakah Anda yakin ingin menebus?",
        "cancel": "Batal",
        "determine": "Tentukan",
        "Buyin": "Beli",
        "Buyamount": "Jumlah Pembelian",
        "BuyinTips": "Penebusan awal tidak akan menghasilkan pendapatan apa pun ",
        "confirm": "Konfirmasi",
        "Minimumbuy": "Jumlah Beli Minimum",
        "Maturity": "Jatuh Tempo",
        "EarlyRedemption": "Penebusan Awal",
        "Redemptiontime": "Waktu Penebusan",
        "Amountofincome": "Jumlah Pendapatan",
        "prompt": "peringatan"
    },
    "HelpHa": {
        "Bantuan": "Bantuan",
        "PusatBantuan": "Pusat Bantuan",
        "text": "Ada pertanyaan, lihat di sini"
    },
    "SetingHa": {
        "Seting": "Pengaturan",
        "SetingCenter": "Pusat Pengaturan",
        "text": "Dapatkan Hadiah Lebih Banyak"
    },
    "coinjs": {
        "tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
        "tips1_1": "Please switch the network to ",
        "tips1_2": " and try again",
        "trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
    },
    "coinDefi": {
        "staking": "Staking Now",
        "All_Chains": "All Chains",
        "Annual_Percentage_Yield": "Annual Percentage Yield",
        "Daily_Yield_Rate": "Daily Yield Rate",
        "Mining_Period": "DeFi Period",
        "Flexible": "Flexible",
        "Wallet_Balance": "Wallet Balance",
        "Daily_Yield": "Daily Yield",
        "DeFi_Mining": "Join DeFi Mining Now",
        "Disconnect": "Disconnect",
        "Copy_Link": "Copy Link",
        "text1": "Mobile brower not supported",
        "text2": "TRON doesn't support mobile browser to connet to the wallet.",
        "text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
        "text3_1": "Please open it in ",
        "text3_2": "mobile wallet browser",
        "text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
        "text3_4": " etc.",
        "tips1": "Don't repeat DeFi Mining",
        "tips2": "Failed, please try again later",
        "tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
        "tips4": "Please log in to TronLink wallet first"
    }
}