<template>
    <div class="Adaptive">
        <div class="layout">
            <div style="position: relative;">
                <div
                    class="pop"
                    style="opacity: 1;"
                >

                    <van-popover
                        v-model="showPopover"
                        trigger="click"
                        placement="left-end"
                    >
                        <div
                            v-if="info"
                            class="HeiTi"
                            style="max-height: 72vh; overflow-y: auto; width: 100%;"
                        >
                            <div style="width: 12.5rem; overflow: auto; font-size: 0.8rem; line-height: 1.2rem;">
                                <div
                                    class="$color-bg-h"
                                    style="margin-top: 0.5rem; width: 100%; overflow-y: auto; max-height: 16.5rem; border-radius: 1rem;"
                                >
                                    <div v-if="info.Telegram"><a
                                            :href="info.Telegram.content.indexOf('http') != -1 ? info.Telegram.content : 'https://'+info.Telegram.content"
                                            target="_blank"
                                            class="selectkfu1_box"
                                            style="height: 3rem; margin-bottom: 0.7rem; border-radius: 5rem; background-color: rgb(31, 50, 76); display: block; box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px 1px;"
                                        >
                                            <div style="width: 2rem; height: 2rem; float: left; border-radius: 5rem; margin: 0.5rem;"><img
                                                    src="img/Telegram.8745c65d.png"
                                                    style="width: 100%;"
                                                ></div>
                                            <div
                                                class="selectkfu1_box_text"
                                                style="height: 3rem; float: right; border-radius: 5rem; font-size: 1rem; font-weight: 100; margin-right: 1rem; line-height: 3rem;"
                                            > Telegram </div>
                                        </a></div>
                                    <div v-if="info.WhatsApp"><a
                                            :href="info.WhatsApp.content.indexOf('http') != -1 ? info.WhatsApp.content : 'https://'+info.WhatsApp.content"
                                            target="_blank"
                                            class="selectkfu1_box"
                                            style="height: 3rem; margin-bottom: 0.7rem; border-radius: 5rem; background-color: rgb(31, 50, 76); display: block; box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px 1px;"
                                        >
                                            <div style="width: 2rem; height: 2rem; float: left; border-radius: 5rem; margin: 0.5rem;"><img
                                                    src="img/WhatsApp.2efb34e8.png"
                                                    style="width: 100%;"
                                                ></div>
                                            <div
                                                class="selectkfu1_box_text"
                                                style="height: 3rem; float: right; border-radius: 5rem; font-size: 1rem; font-weight: 100; margin-right: 1rem; line-height: 3rem;"
                                            > WhatsApp </div>
                                        </a></div>
                                    <!-- <div
                                        class="selectkfu1_box"
                                        style="height: 3rem; margin-bottom: 0.7rem; border-radius: 5rem; background-color: rgb(31, 50, 76); box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 1px 1px;"
                                    >
                                        <div style="width: 2rem; height: 2rem; float: left; border-radius: 5rem; margin: 0.5rem;"><img
                                                src="img/message.png"
                                                style="width: 100%;"
                                            ></div>
                                        <div
                                            class="selectkfu1_box_text"
                                            style="height: 3rem; float: right; border-radius: 5rem; font-size: 1rem; font-weight: 100; margin-right: 1rem; line-height: 3rem;"
                                        >{{$t('Online_Chat')}}</div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <template #reference>
                            <img
                                class="chat"
                                src="img/chatimg.4a6d94fd.png"
                                style="width: 60px; height: 60px;"
                            >
                        </template>
                    </van-popover>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            showPopover: false,
            info: {},
        };
    },
    props: {},
    created() {
        this.getInfo();
    },
    mounted() {},
    computed: {},
    methods: {
        getInfo() {
            this.axios
                .post("/data/api.data/contact")
                .then((v) => {
                    this.info = v.data;
                })
                .catch((err) => {});
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
<style scoped>
.Adaptive{
  
  
}
.selectkfu1_box_text {
    color: white !important;
}
.text_color {
    color: #029798 !important;
}
.bg_color {
    border: #029798 !important;
    color: white !important;
    background: linear-gradient(to right, #0a8076, #029798);
}
.bg_color2 {
    border: #029798 !important;
    color: white !important;
    background: linear-gradient(to right, #0a8076, #0a8076);
}
.text_color2 {
    color: #0a8076;
}
.color_bg_btn {
    background: linear-gradient(to right, #0a8076, #029798) !important;
}
.color-bg-h {
    background-color: #262434;
}
.color-bg-1 {
    background-color: #000000;
}
.color-bg-blue {
    background-color: #029798;
}
.color-bg-tips-top {
    background-color: #f5f5f5;
}
.color-bg-h-top {
    background-color: #262434;
}
.color-bg-h-top22 {
    background-image: linear-gradient(
        52deg,
            #18cb98 0%,
            #08906a 44%,
            #0c8865 100%
    );
}
.van-notice-bar {
    background-color: rgba(56, 97, 251, 0.1) !important;
}
.van-notice-bar__content {
    color: #2962ef;
    font-size: 0.9rem;
    font-weight: bold;
}
.pop2 {
    z-index: 999;
    width: 100%;
    position: fixed;
    top: 4rem;
}
.pop2 .btn2 {
    width: 2.75rem;
    height: 2.75rem;
    background: #ddd;
    border-radius: 1.875rem;
    text-align: center;
    line-height: 1;
    font-size: 1.25rem;
    padding: 0.625rem 0.125rem;
    box-sizing: border-box;
}
.pop2 .btn2 p {
    margin: 0 auto;
    font-size: 0.75rem;
}
.pop2 .btn2 .iconfont {
    font-size: 1.5rem;
}
.pop {
  float: right;
   /* position: fixed;
    bottom: 50%;
    right: 0.7rem;
    z-index: 2000;*/
}
.pop .popper__arrow::after {
    border-top-color: rgba(56, 97, 251, 0) !important;
}
.pop .popper__arrow {
    border-top-color: rgba(56, 97, 251, 0) !important;
}
.pop .btn {
    width: 4.5rem;
    height: 4.5rem;
    background: #ddd;
    border-radius: 1.875rem;
    text-align: center;
    line-height: 1;
    font-size: 1.25rem;
    box-sizing: border-box;
    color: white;
    background: rgba(0, 0, 0, 0);
}
.pop .btn p {
    margin: 0 auto;
    font-size: 0.75rem;
}
.pop .img {
    width: 1.25rem;
    height: 1.25rem;
}
.pop .btns {
    width: 0rem;
    position: absolute;
    top: calc(50% - 3.75rem);
    right: 3.75rem;
    overflow: hidden;
    transition: ease-in-out 0.3s;
}
.pop .btns .btn {
    background: #f4a460;
    margin-bottom: 0.625rem;
}
.pop .btns .btn .iconfont {
    font-size: 20px;
}
.pop .btns .btn .icon-whatsapp {
    color: #10d44f;
}
.pop .btns .btn p {
    font-size: 0.625rem;
}
.pop .active {
    transition: ease-in 0.3s;
    width: 3.75rem;
}

/************客服********************/
.van-nav-bar {
    background: #323233 !important;
}
.van-nav-bar__title {
    font-size: 1rem !important;
}
.van-hairline--bottom::after {
    border-bottom-width: 0px;
}
.download-page0 {
    background-color: #26272c;
    /*********自适应****** <div class="layout">*****/
    max-width: 28rem;
    min-width: 16rem;
    margin: 0 auto;
    display: flex;
    min-height: 100vh;
}
.download-page0 .layout {
    width: 100%;
    flex: 1;
    min-height: 100vh;
    background: #26272c;
}
.errmessage {
    color: #aaaaaa;
    text-align: center;
}
.download-page {
    font-size: 1.0625rem;
    background: #26272c;
    height: 100%;
}
.download-page .content {
    padding: 1.875rem;
}
.download-page .content h3 {
    text-align: center;
}
.ServiceTitle {
    font-size: 1rem;
    font-weight: bold;
    color: #46cf72;
}
.ServiceBody {
    margin-left: -1rem;
    padding-left: 0.5rem;
    margin-right: -1rem;
    background-color: #323233;
    border: 1px solid #323233;
    border-radius: 1rem;
    padding-bottom: 2rem;
    margin-top: 1.875rem;
}
.ServiceBody-WhatsApp {
    margin-top: 1.875rem;
    height: 2.5rem;
}
.ServiceBody-WhatsApp .sw1 {
    height: 2.5rem;
    width: 2.5rem;
    float: left;
}
.ServiceBody-WhatsApp .sw1 .img {
    padding: 0.3125rem;
    width: 1.875rem;
    height: 1.875rem;
}
.ServiceBody-WhatsApp .sw2 {
    color: white;
    height: 2.5rem;
    width: 7.5rem;
    float: left;
}
.ServiceBody-WhatsApp .sw2 .sw2-text1 {
    height: 1.25rem;
    width: 7.5rem;
    font-weight: bold;
    font-size: 1rem;
}
.ServiceBody-WhatsApp .sw2 .sw2-text2 {
    height: 1.25rem;
    width: 7.5rem;
    font-size: 0.9375rem;
}
.ServiceBody-WhatsApp .sw3 {
    padding-top: 0.3125rem;
    margin-left: 0.625rem;
    height: 2.5rem;
    width: 3.75rem;
    float: left;
}
.ServiceBody-WhatsApp .sw4 {
    padding-top: 0.3125rem;
    margin-left: 0.3125rem;
    height: 2.5rem;
    width: 3rem;
    float: left;
}
.ServiceBody-WhatsApp .btn {
    text-align: center;
    margin-left: 1rem;
    border-radius: 1.5625rem;
    font-size: 0.825rem;
    height: 1.875rem;
    line-height: 0.2rem;
    width: 4rem;
    color: #409eff;
    border-color: #409eff;
}
.ServiceBody-WhatsApp .btn2 {
    text-align: center;
    margin-left: 2.5rem;
    border-radius: 1.5625rem;
    font-size: 0.825rem;
    height: 1.875rem;
    line-height: 1.875rem;
    width: 3rem;
    background: linear-gradient(76deg, #08906a 0%, #0b787d 64%, #0d608f 100%),
        linear-gradient(#30c6e4, #30c6e4);
    font-weight: bold;
    color: white;
}
.cobyDomObj {
    font-weight: bold;
    border: 0rem solid;
    color: white !important;
    background: linear-gradient(76deg, #08906a 0%, #0b787d 64%, #0d608f 100%),
        linear-gradient(#30c6e4, #30c6e4);
    text-align: center;
    height: 1.875rem;
    font-size: 0.825rem;
    line-height: 0.2rem;
    border-radius: 2.5625rem;
}
.layout p {
    word-break: break-all;
}
</style>

<style>
.van-popover--light .van-popover__content {
    box-shadow: none !important;
}
</style>