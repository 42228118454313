import i18n from '../lang/index.js';
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index/index.vue'
import AudioMp3 from '@/components/AudioMp3';
import Cache from 'store2'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/invest',
    name: 'invest',
    component: () => import(/* webpackChunkName: "invest" */ '../views/invest/index.vue')
  },
  {
    path: '/rewards',
    name: 'rewards',
    meta: { permission: true },
    component: () => import(/* webpackChunkName: "rewards" */ '../views/rewards/index.vue')
  },
  {
    path: '/trending',
    name: 'trending',
    component: () => import(/* webpackChunkName: "trending" */ '../views/trending/index.vue')
  },
  {
    path: '/trending_detail',
    name: 'trending_detail',
    component: () => import(/* webpackChunkName: "trending" */ '../views/trending/detail.vue')
  },
  {
    path: '/goods',
    name: 'goods',
    meta: { permission: true },
    component: () => import(/* webpackChunkName: "goods" */ '../views/goods/index.vue')
  },
  {
    path: '/wallet',
    name: 'wallet',
    meta: { permission: true },
    component: () => import(/* webpackChunkName: "wallet" */ '../views/wallet/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/login.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/register.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


// 这里就可以进行vue-router的beforeEach拦截了，你也可以放其他地方，我比较喜欢放这
router.beforeEach((to, from, next) => {
  let token = Cache.get('token') || ''
  // 这里必须加上to.path !== 'login'的判断，不然会陷入无限循环，
  // 因为逻辑是第一次进来，判断用户信息不存在，即!user为true，由于使用的是next('/login')而非next()，
  // 会再次进入路由跳转，next()方法没有参数是直接进入页面，不会再次进入路由拦截，有参数则会，因为跳转，
  // 所以再次进入路由，再次判断，再次进入路由，再次判断，循环往复无限循环
  // 所以一定要加to.path !== 'login'的判断
  if (to.meta && to.meta.permission) {
    if (!token && (to.path !== '/login')) {
      next('/login')
    } else {
      next();
    }
  } else {
    next();
  }
})


export default router
