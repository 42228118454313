module.exports = {
	"Language.title": "語言",
	"Noproductsforsale": "目前無可購買產品",
	"rechargePresentationText0_1": "充值1000獎勵",
	"rechargePresentationText0_2": ", 充值10000獎勵",
	"rechargePresentationText0_3": ", 以此類推... 點擊我！",
	"rechargePresentationText0": "您將獲得充值金額的",
	"rechargePresentationText1": "%獎勵金，立即充值即可參與活動，活動時間有限，贈送結束即止。",
	"rechargePresentationText2": "獎勵結束時間，倒計時！",
	"Online_Chat": "在線聊天",
	"gobindgoogle": "請綁定您的Google身份驗證器",
	"Order": "訂單",
	"Record": "記錄",
	"Assist": "助手",
	"Market": "市場",
	"History": "歷史",
	"Confirm": "確認",
	"close": "關閉",
	"Cancel": "取消",
	"Previous": "上一頁",
	"Next": "下一頁",
	"Copy": "複製",
	"Nomoredata": "暫無數據",
	"moneychar": "₹",
	"DownloadApp": "立即下載APP",
	"Yes": "是",
	"No": "否",
	"Wating": "等待中",
	"Recharge": "充值",
	"Withdrawal": "提現",
	"Success": "成功",
	"stop_investing": "停止投資",
	"OrderSuccessful": "訂單成功",
	"Handledataerror": "請先登錄",
	"Copysuccess": "複製成功",
	"MyBalanceLoding": "我的餘額正在加載中",
	"UPDATE": "更新",
	"New_version_available": "有新版本可用",
	"Please,update_app_to_new_version": "請更新至新版本的APP",
	"tabbar.Home": "首頁",
	"tabbar.AndarBahar": "AndarBahar",
	"tabbar.Invest": "投資",
	"tabbar.Bonus": "獎勵",
	"tabbar.Mine": "我的",
	"tabbar.Trending": "趨勢",
	"tabbar.Wallet": "錢包",
	"tabbar.Rewards": "獎勵",
	"homeTop.Sign_Up": "註冊",
	"homeTop.Sign_up_for_rewards": "註冊獲取獎勵",
	"homeTop.up_to": "高達",
	"homeTop.TOTAL_ASSETS": "總資產",
	"homeTop.User_ID": "用戶ID",
	"homeTop.Registration_time": "註冊時間",
	"PieChart.BIG&SMALL": "大小",
	"PieChart.NUMBER": "數字",
	"PieChart.ODD&EVEN": "單雙",
	"PieChart.ALL_NUMBER_TRENDING": "全部數字趨勢",
	"PieChart.BIG&SMALL_NUMBER_TRENDING": "大小數字趨勢",
	"PieChart.ODD&EVEN_NUMBER_TRENDING": "單雙數字趨勢",
	"register.checkerr": "密碼：使用8位或更多字符（字母和數字的組合）",
	"register.tips_3": "如果您沒有在收件箱中收到驗證碼，請檢查您的垃圾郵件文件夾。",
	"register.receiveCode": "沒有收到驗證碼？",
	"register.receiveCode_title": "驗證碼已發送到您的電子郵件。如果您在多次嘗試後仍未收到驗證碼，請嘗試：",
	"register.receiveCode_text1": "1. 檢查是否在垃圾郵件中。",
	"register.receiveCode_text2": "2. 確保您的電子郵件地址是",
	"register.receiveCode_text3": "3. 信息可能會延遲幾分鐘。 20分鐘後再試一次。",
	"register.receiveCode_text4": "4. 如果此電子郵件地址已存在，我們將不會向您發送驗證碼。請登錄或重置密碼。",
	"register.Sign_up_for_Rewards": "註冊以獲取獎勵",
	"register.Email_Verification": "電子郵件驗證",
	"register.tips": "請輸入發送到{{formData.email}}的6位驗證碼。驗證碼有效期為30分鐘。",
	"register.tips_1": "請輸入發送到",
	"register.tips_2": "的6位驗證碼。驗證碼有效期為30分鐘。",
	"register.Resend_code_in": "重新發送驗證碼",
	"register.Sent_again": "再次發送",
	"register.CREATE_ACCOUNT": "創建帳戶",
	"register.inviteCodecheck": "邀請碼不能為空！",
	"register.Mobile": "手機號碼",
	"register.Mobilemsg": "手機號碼為必填項",
	"register.Password": "密碼",
	"register.Passwordmsg": "密碼為必填項",
	"register.OTPCode": "驗證碼",
	"register.OTPCodemsg": "驗證碼為必填項",
	"register.SendOTP": "發送驗證碼",
	"register.Email": "電子郵件",
	"register.Emailmsg": "電子郵件為必填項",
	"register.Invitecode": "推薦碼（可選）",
	"register.Invitecodemsg": "推薦碼為必填項",
	"register.emailFormatter": "請輸入正確的電子郵件地址",
	"register.usernameFormatter": "用戶名必須為字符和數字，範圍為6<=長度<=20",
	"register.phoneFormatter": "請填寫10位手機號碼",
	"register.msgsuccess": "註冊成功",
	"register.msgerr": "註冊失敗",
	"register.errorphonecheck": "手機號碼不能為空！",
	"register.phonecheck": "請填寫10位手機號碼！",
	"register.imgcodecheck": "驗證碼不能為空！",
	"login.Welcome_back": "歡迎回來",
	"login.tips": "我已閱讀並同意{{this.$LogoName}}的服務條款和隱私政策",
	"login.tips_1": "我已閱讀並同意",
	"login.tips_2": "服務條款",
	"login.tips_3": "和",
	"login.tips_4": "隱私政策",
	"login.Continue_with_Google": "使用 Google 帳號登入",
	"login.Continue_with_Apple": "使用 Apple 帳號登入",
	"login.text": "還沒有帳號嗎？",
	"login.text2": "已經有帳號了嗎？",
	"login.Login": "登入",
	"login.Register": "註冊",
	"login.username": "用戶名或手機號碼",
	"login.password": "密碼",
	"login.imgcode": "驗證碼",
	"login.BackHome": "回到首頁",
	"login.Forgetpwd": "忘記密碼",
	"forgot.title": "忘記密碼",
	"forgot.msgsuccess": "密碼重置完成",
	"forgot.newpassword": "新密碼",
	"forgot.newpasswordmsg": "新密碼為必填項",
	"forgot.Continue": "繼續",
	"home.Products&Services": "產品和服務",
	"home.content11": "邀請者可以獲得其被邀請人所產生交易手续费一定比例的佣金。有效邀請的最高佣金為無限制的 USDT。",
	"home.Revolutionize_Trading": "創新交易",
	"home.Shape_the_Future": "塑造未來",
	"home.content0": "讓我們一起體驗新時代的加密貨幣交易吧！",
	"home.3_Reasons_for_Choosing": "三個選擇理由",
	"home.Innovative_Trading_Features": "創新的交易功能",
	"home.content1": "革新性的交易體驗，打破市場上現有的交易模式。設計新穎的交易功能，先進的安全措施和極速的交易體驗，為您提供競爭優勢。",
	"home.Lower_Fees_and_Better_Rewards": "更低的費用和更好的獎勵",
	"home.content2": "我們的介面使初學者進行交易變得簡單。您可以免費創建帳戶，從我們的市場進行低交易手續費的加密貨幣交易。此外，您可以分享邀請的所有用戶的交易手續費高達20％的佣金。",
	"home.Institutional-grade_Security": "機構級安全",
	"home.content3": "遵循行業最高安全標準之一。我們將100％的加密貨幣存儲在冷藏庫中。我們保證至少為客戶資金提供1:1的儲備比例。我們的 3 億美元基金為安全威脅提供了額外的保護。",
	"home.Start_investing_in_as_little_as_5_minutes": "只需 5 分鐘即可開始投資",
	"home.content4": "現在免費註冊{{this.$LogoNames.toUpperCase()}}",
	"home.content4_1": "現在免費註冊",
	"home.content4_2": "",
	"home.Complete_google_authentication": "完成谷歌驗證",
	"home.content5": "輸入和驗證您的電子郵件地址即可開始。開戶不收費用。",
	"home.content7": "在不到 5 分鐘的時間內完成谷歌驗證以解鎖完整功能。",
	"home.content8": "即時為您的 {{this.$LogoNames.toUpperCase()}} 帳戶充值",
	"home.content8_1": "即時為您的",
	"home.content8_2": "帳戶充值",
	"home.content9": "透過指定網路充值USDT，即可立即使用您的資金，無需等待3-5個工作日開始交易。",
	"home.Start_building_your_crypto_portfolio": "開始建立您的加密貨幣投資組合",
	"home.Invitation_Program": "邀請計劃",
	"home.Referral_Program": "推薦計劃",
	"home.content10": "完成任何有效邀請並獲得10美元等值的邀請獎金。單人的最高獎金為",
	"home.TRADING_TASKS": "交易任務",
	"home.content12": "用戶在交易時將獲得以USDT為獎勵的佣金，不同交易模式有不同的交易任務。獎勵將即時分發。",
	"home.Trade_anytime_anywhere": "隨時隨地進行交易",
	"home.content13": "USA, Inc.已獲得紐約州金融服務部門頒發的虛擬貨幣業務活動許可證。",
	"home.USA_Inc": "USA, Inc",
	"home.content6": "下載我們的應用程序以24/7保持聯繫",
	"home.Legal&Disclosures": "法律聲明及披露",
	"home.Regulatory_License": "監管許可證",
	"home.AML/KYC_Policies": "AML/KYC政策",
	"home.Privacy_Policy": "隱私政策",
	"home.Terms_of_Use": "使用條款",
	"home.Legal_Statement": "法律聲明",
	"home.Risk_Disclosure": "風險披露",
	"home.About_Us": "關於我們",
	"home.Protection_Fund": "保護基金",
	"home.Proof_of_Reserves": "儲備證明",
	"home.Twitter": "Twitter",
	"home.Bitget_Token": "EPOCH EPT Token",
	"home.Careers": "職業生涯",
	"home.Company": "公司",
	"home.Support_Service": "支持服務",
	"home.title": "打開應用程序",
	"home.titlebtn": "下載",
	"home.Balance": "餘額",
	"home.ID": "識別號",
	"home.Recharge": "充值",
	"home.Withdraw": "提現",
	"home.Bonus": "獎金",
	"home.Checkin": "簽到",
	"home.Eventclosed": "活動已結束",
	"home.Ranking": "排名",
	"Ranking.Today_s winning ranking": "今日盈利排名",
	"Ranking.tips1": "赢取的金额越大 = 排名越高！",
	"Ranking.UserName": "用户名",
	"Ranking.Winning Amount": "赢取金额",
	"Ranking.Today_s rebate ranking": "今日返利排名",
	"Ranking.tips2": "低等级用户获得更高的返利 = 排名更高！",
	"Ranking.Rebate Amount": "返利金额",
	"Ranking.Rebate ranking": "前20名返利排名",
	"Checkin.Checkin": "簽到",
	"Checkin.CheckinList": "簽到列表",
	"AndarBahar.title": "安達巴哈",
	"AndarBahar.Rule": "規則",
	"AndarBahar.Record": "記錄",
	"AndarBahar.Records": "安達巴哈記錄",
	"AndarBahar.MyOrder": "我的訂單",
	"AndarBahar.more": "更多",
	"AndarBahar.Period": "期數",
	"AndarBahar.Order": "訂單",
	"AndarBahar.Buy": "買入",
	"AndarBahar.Result": "結果",
	"AndarBahar.Profit": "盈虧",
	"AndarBahar.AndarBaharRule": "安達巴哈規則",
	"AndarBahar.CountDown": "倒計時",
	"AndarBahar.Andar": "安達",
	"AndarBahar.Bahar": "巴哈",
	"AndarBahar.Tie": "和局",
	"AndarBahar.result": "結果",
	"AndarBahar.ok": "確定",
	"AndarBahar.start": "開始",
	"AndarBahar.Point": "數字",
	"AndarBahar.ContractPoint": "合約數字",
	"AndarBahar.Number": "數量",
	"AndarBahar.Delivery": "交割",
	"AndarBahar.Fee": "手續費",
	"AndarBahar.Amount": "金額",
	"pokerOrder.OrderRecord": "訂單記錄",
	"pokerOrder.WatingOrder": "等待中的訂單",
	"pokerOrder.HistoryOrder": "歷史訂單",
	"pokerOrder.Nomoredata": "沒有更多數據",
	"pokerOrder.Buy": "買入",
	"pokerOrder.Result": "結果",
	"pokerOrder.Time": "時間",
	"pokerOrder.Amount": "金額",
	"pokerOrder.Fee": "手續費",
	"pokerOrder.Profit": "收益",
	"pokerRecord.title": "安達巴哈記錄",
	"pokerRecord.Period": "期數",
	"pokerRecord.Result": "結果",
	"pokerRecord.OpenTime": "開獎時間",
	"pokerRecord.Nomore": "沒有更多了",
	"Google2FA.title": "Google身份驗證器",
	"Google2FA.Authenticator_Instructions": "身份驗證器說明",
	"Google2FA.Authenticator_Status": "身份驗證器狀態",
	"Google2FA.Google_Authenticator": "Google身份驗證器",
	"Google2FA.Download_Google_Authenticator_App": "下載Google身份驗證器應用程序",
	"Google2FA.App_Store": "App Store",
	"Google2FA.Google_Play": "Google Play",
	"Google2FA.Next": "下一步",
	"Google2FA.Backup_key": "備份密鑰",
	"Google2FA.Backup_key_text": "請將此備份密鑰保存在安全的位置。如果您丟失手機，此密鑰將允許您恢復您的身份驗證器。否則，重置您的身份驗證器將需要諮詢客戶服務。",
	"Google2FA.Verifiaction": "驗證",
	"Google2FA.Verifiaction_text": "輸入Google驗證碼",
	"Google2FA.Google_verification_code": "Google驗證碼",
	"Google2FA.contentlist1": "1.記錄來自+this.$LogoName+的16位備份密鑰，並將其保存在安全的位置。",
	"Google2FA.contentlist1_1": "1.記錄來自",
	"Google2FA.contentlist1_2": "的16位備份密鑰，並將其保存在安全的位置。",
	"Google2FA.contentlist2": "2. 打開 Google Authenticator 應用程序。",
	"Google2FA.contentlist3": "3. 添加一個 +this.$LogoName+ 賬戶並輸入複製的 16 位數字密鑰。",
	"Google2FA.contentlist3_1": "3. 添加一個",
	"Google2FA.contentlist3_2": "賬戶並輸入複製的 16 位數字密鑰。",
	"Google2FA.contentlist4": "4. 您將在屏幕上看到一個 6 位數的驗證碼。",
	"Google2FA.contentlist5": "5. 複製 6 位數的驗證碼並粘貼到 +this.$LogoName+。",
	"Google2FA.contentlist5_1": "5. 複製 6 位數的驗證碼並粘貼到",
	"Google2FA.contentlist5_2": "。",
	"Google2FA.contentlist6": "6. 然後，您的身份驗證器將成功連接。",
	"win.Live_Price": "實時價格",
	"win.title1": "投資結果由Binance BTC/USDT現貨交易對應UTC時間的最後一位數字計算。投資者可以隨時在Binance上查看結果，以確保投資的公平和公正。",
	"win.title": "投資有2種模式",
	"win.1M_Mode": "1M簡約版",
	"win.1M_Mode_text": "每期持續1分鐘，僅提供單雙投資。結果由Binance BTC/USDT現貨交易1分鐘K線收盤價的最後一位數字計算。",
	"win.5M_Mode": "5M專業版",
	"win.5M_Mode_text": "每期持續5分鐘。專業版解鎖了EPOCH EPT的全部投資方法。結果由Binance BTC/USDT現貨交易5分鐘K線收盤價的最後一位數字計算。",
	"win.5M_Mode_text_1": "每期持續5分鐘。專業版解鎖了",
	"win.5M_Mode_text_2": "的全部投資方法。結果由Binance BTC/USDT現貨交易5分鐘K線收盤價的最後一位數字計算。",
	"win.Investment_Guide": "投資指南",
	"win.Investment_Rules": "投資規則",
	"win.title2": "如何證明結果是公正的？",
	"win.Investment_Info": "投資信息",
	"win.3M_Mode": "3M模式",
	"win.3M_Mode_text": "每期持續3分鐘。結果由Binance BTC/USDT現貨交易3分鐘區間的收盤價的最後一位數字計算。",
	"win.Investment_return": "投資回報",
	"win.Number": "數字",
	"win.Profit_Invested_amount": "收益: 投資金額",
	"win.guide1": "選擇時間模式。",
	"win.guide2": "調整您的投資金額。",
	"win.guide3": "選擇要投資的數字。",
	"win.guide4": "檢查您的組合並點擊投資。",
	"win.NEXT": "下一步",
	"win.Invest_Amount": "投資金額",
	"win.Total": "總額",
	"win.INVEST": "投資",
	"win.Portfolio": "投資組合",
	"win.Invest": "投資",
	"win.BINANCE": "Binance",
	"win.Bitcoin_Price": "比特幣價格",
	"win.InvestmentRecord": "投資記錄",
	"win.Best_Assistant": "投資助手",
	"win.last_100_rounds": "（最近100期）",
	"win.What_do_these_data_mean": "這些數據是什麼意思",
	"win.Missing": "缺少",
	"win.Missing_text": "這表示自上一個出現此數字的期號以來的期數。",
	"win.Frequency": "頻率",
	"win.Frequency_text": "指最新100期中相應數字出現的次數。",
	"win.Max_Continued": "最大連續",
	"win.Max_Continued_text": "指最新100期中相應數字的最長連續出現期。",
	"win.Rule": "規則",
	"win.Trend": "趨勢",
	"win.MyBalance": "我的餘額",
	"win.JoinGreen": "參加綠色",
	"win.JoinRed": "參加紅色",
	"win.JoinViolet": "參加紫色",
	"win.Parity": "單雙",
	"win.Sapre": "大小",
	"win.Bcone": "Bcone",
	"win.Emerd": "Emerd",
	"win.Record": "記錄",
	"win.more": "更多",
	"win.Period": "期數",
	"win.Price": "價格",
	"win.Result": "結果",
	"win.My": "我的",
	"win.Order": "訂單",
	"win.Buy": "買入",
	"win.Profit": "盈利",
	"win.Green": "綠色",
	"win.Red": "紅色",
	"win.Violet": "紫色",
	"win.tips": "交易費用為下注金額的{bet_fee*100}%",
	"win.tips1": "交易費用為",
	"win.tips2": "下注金額的",
	"win.OrderAmount": "下注金額",
	"win.Quantity": "數量",
	"win.TransactionFee": "交易費用",
	"win.TotalAmount": "總金額",
	"win.PresalePolicy": "隱私政策",
	"win.Iagree": "我同意",
	"win.err1": "請先同意預售管理規則",
	"historyPeriods.Orderrecord": "訂單記錄",
	"historyPeriods.record": "記錄",
	"historyOrderPeriods.WatingOrder": "待处理訂單",
	"historyOrderPeriods.HistoryOrder": "历史訂單",
	"Activity.added_to_account_balance": "已添加到帳戶餘額。",
	"Activity.contentText": "當受邀者完成首次存款時，算作一次有效邀請。邀請人將收到每一次有效邀請的邀請獎金。邀請獎金等級來自 {{aaaa}} 1 次有效邀請 {{ssssss}} {{ssss}} 有效邀請",
	"Activity.contentText_1": "當受邀者完成首次存款時，算作一次有效邀請。邀請人將收到每一次有效邀請的邀請獎金。邀請獎金等級來自",
	"Activity.contentText_2": " 1 次有效邀請",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"Activity.contentText_3_2": "有效邀請。",
	"Activity.contentText2": "邀請者將分享每個LV.1被邀請人交易手續費的{{LV.1%}}交易佣金。邀請者將分享每個LV.2被邀請人交易手續費的{{LV.2%}}交易佣金。被邀請人交易手續費獎勵的最大獎金為無限制美元USDT。",
	"Activity.contentText2_1": "邀請者將分享每個LV.1被邀請人交易手續費的",
	"Activity.contentText2_2": "％交易佣金。邀請者將分享每個LV.2被邀請人交易手續費的",
	"Activity.contentText2_3": "％交易佣金。被邀請人交易手續費獎勵的最大獎金為無限制美元。",
	"Activity.My_Rewards_History": "我的獎勵歷史",
	"Activity.Cumulative_Single_Number_Trading_Volumn": "累計單數交易量",
	"Activity.Cumulative_ODD_Number_Trading_Volumn": "累計單數交易量",
	"Activity.Cumulative_EVEN_Number_Trading_Volumn": "累計雙數交易量",
	"Activity.Cumulative_BIG_Number_Trading_Volumn": "累計大數交易量",
	"Activity.Cumulative_SMALL_Number_Trading_Volumn": "累計小數交易量",
	"Activity.Advanced_Tasks": "進階任務",
	"Activity.Invite_Friends_and_Earn_Up_to": "邀請好友可賺取高達",
	"Activity.Referral_Code": "推薦碼",
	"Activity.Referral_Link": "推薦鏈接",
	"Activity.INVITE_FRIENDS": "邀請好友",
	"Activity.Complete_tasks_to_earn_up_to": "完成任務最高可獲得",
	"Activity.MY_REWARDS": "我的獎勵",
	"Activity.SHARE": "分享",
	"Activity.Earn_with_your_friends_together": "和朋友一起賺錢",
	"Activity.Get_your_link": "獲取您的連結",
	"Activity.Claim_your_rewards": "領取獎勵",
	"Activity.Earn_together": "一起賺",
	"Activity.contentlist0": "加入{{gogobit}}並獲取您的推薦連結。透過此連結註冊的用戶將獲得獎勵。",
	"Activity.contentlist0_1": "加入",
	"Activity.contentlist0_2": "並獲取您的推薦連結。透過此連結註冊的用戶將獲得獎勵。",
	"Activity.contentlist1": "您的朋友完成首次存款，並完成所需的交易量後，您將獲得平均價值為{{XX}}美元的推薦獎勵。獎勵最高可達{{XX}}美元。",
	"Activity.contentlist1_1": "您的朋友完成首次存款，並完成所需的交易量後，您將獲得平均價值為",
	"Activity.contentlist1_2": "美元的推薦獎勵。獎勵最高可達",
	"Activity.contentlist1_3": "美元。",
	"Activity.contentlist2": "您的朋友創建帳戶後，可以領取高達5,050美元的交易獎金。",
	"Activity.Referral_Rewards": "推薦獎勵",
	"Activity.Detail_Rules": "詳細規則",
	"Activity.Invitation_Bonus": "邀請獎金",
	"Activity.Up_to": "最高",
	"Activity.content0": "完成任何有效邀請即可獲得平均價值高達10美元的邀請獎金。一百位被邀請者的最高獎金為",
	"Activity.content0_1": "完成任何有效邀請即可獲得平均價值高達",
	"Activity.content0_2": "一百位被邀請者的最高獎金為",
	"Activity.Trading_Bonus": "交易獎金",
	"Activity.content1": "邀請者可以獲得由他們的受邀者產生的交易手續費所對應的獎金。一個有效邀請的最高獎金為",
	"Activity.My_Referral_History": "我的推薦歷史",
	"Activity.Total_Invitees": "總受邀人數",
	"Activity.Valid_Invitees": "有效受邀人數",
	"Activity.Cumulative_Invitees": "累計受邀人數",
	"Activity.Trading_Volumn": "交易量",
	"Activity.Daily_Tasks": "每日任務",
	"Activity.Detail_Rules_title": "每日幸運抽獎888 USDT",
	"Activity.Detail_Rules_title1": "1.當天的期貨交易數據將在第二天計算。",
	"Activity.Detail_Rules_title2": "2.當天進行了20萬美元或更多期貨交易的用戶將有機會抽取888 USDT的獎勵。",
	"Activity.CLAIM_NOW": "立即領取",
	"Activity.INVEST_NOW": "立即投資",
	"Activity.title": "獎勵錢包",
	"Activity.BonusBalance": "獎勵余額",
	"Activity.Withdrawtobalance": "提現到余額",
	"Activity.BonusList": "獎勵列表",
	"Activity.Bonuswithdrawtobalance": "獎勵提現到余額",
	"Activity.WithdrawalAmount": "提現金額",
	"Activity.Pleaseentertheamount": "請輸入提現金額",
	"Activity.err1": "提現金額不能為0",
	"Activity.success": "成功",
	"Refer.title": "我的推薦",
	"Refer.ReferTotalBonus": "總推薦獎金",
	"Refer.InviteFriends": "邀請好友",
	"Refer.Level1": "第一級",
	"Refer.Level2": "第二級",
	"Refer.TotalPeople": "總人數",
	"Refer.Bonus": "獎金",
	"Refer.Myinvitationlink": "我的邀請連結",
	"Refer.Rebate_description": "返利說明",
	"Task.InviteBonusToday": "今日邀請獎勵",
	"Task.bonusrecord": "獎勵記錄",
	"Task.hint1": "1. 數據每10-30分鐘更新一次！",
	"Task.hint2": "2. 只有在過去3天內註冊並在今天完成充值的用戶才會被計算！",
	"Task.hint2_1": "2. 只有在過去",
	"Task.hint2_2": "天內註冊並在今天完成充值的用戶才會被計算！",
	"Task.hint3": "3. 今天需要最低20 USDT的充值！",
	"Task.hint3_1": "3. 今天需要最低",
	"Task.hint3_2": " USDT的充值！",
	"Task.hint4": "過去三天邀請的人數",
	"Task.hint4_1": "過去",
	"Task.hint4_2": "天邀請的人數",
	"Task.hint5": "今天已完成充值的人數",
	"Task.Invite": "邀請",
	"Task.friendstorecharge": "個好友充值",
	"Task.Earn": "賺取",
	"Task.Completed": "已完成",
	"Task.ExpireTime": "過期時間",
	"Invited.title": "獎勵記錄",
	"Invited.InviteTotalBonus": "邀請總獎勵",
	"Invited.InviteTime": "邀請時間",
	"Invited.Bonus": "獎勵",
	"Invited.NoRecored": "暫無記錄",
	"mine.ProvacyPolicy": "隱私政策",
	"mine.JOIN US": "加入我們",
	"mine.title": "個人中心",
	"mine.InviteCode": "邀請碼",
	"mine.AccountBalance": "帳戶余額",
	"mine.BonusBalance": "獎勵余額",
	"mine.Wallet": "錢包",
	"mine.Bank": "銀行",
	"mine.Invite": "邀請",
	"mine.Refer": "推薦",
	"mine.CustomerService": "客服",
	"mine.ComplaintsSuggestions": "投訴和建議",
	"mine.ChangePassword": "更改密碼",
	"mine.Activity": "活動",
	"mine.PresalePolicy": "隱私政策",
	"mine.DownloadApp": "下載App",
	"mine.AppVersion": "App版本",
	"mine.Logout": "登出",
	"mine.Logouttips": "您確定要退出嗎？",
	"App.title": "應用程式下載",
	"App.DownloadforAndroid": "下載安卓版",
	"App.DownloadforiOS": "下載iOS版",
	"Presale.title": "隱私政策",
	"Account.title2": "設置新密碼",
	"Account.title2_text": "為您的Google帳戶設置新密碼。您可以使用密碼登錄您的Google帳戶。",
	"Account.title_text": "您正在嘗試重設您的密碼。請準備好您的Google驗證器以進行重設。",
	"Account.err1": "兩次輸入的新密碼不同",
	"Account.success2": "密碼修改成功",
	"Account.title": "修改密碼",
	"Account.OldPassword": "舊密碼",
	"Account.OldPasswordmsg": "請輸入舊密碼",
	"Account.NewPassword": "新密碼",
	"Account.NewPasswordmsg": "請輸入新密碼",
	"Account.RetypeNewPassword": "確認新密碼",
	"Account.RetypeNewPasswordmsg": "請再次輸入新密碼",
	"Account.Continue": "繼續",
	"Service.title": "客服中心",
	"Service.CONTACTUS": "聯繫我們",
	"Service.tips": "客服正在休息中！",
	"Service.WhatsApp": "WhatsApp",
	"Service.Telegram": "Telegram",
	"Service.link": "鏈接",
	"Bank.BankName": "銀行名稱",
	"Bank.ChooseaCurrencyType": "選擇貨幣類型",
	"Bank.SelectWithdrawalMethod": "選擇提款方式",
	"Bank.Newbankcard": "新銀行卡",
	"Bank.Editbankcard": "編輯銀行卡",
	"Bank.title": "我的銀行卡",
	"Bank.Cannotbeempty": "不能為空",
	"Bank.BankHolderName": "銀行持卡人姓名",
	"Bank.BankAccountNumber": "銀行帳號",
	"Bank.IFSCCode": "IFSC 編碼",
	"Bank.Email": "電子郵件",
	"Bank.Phone": "電話",
	"Bank.OTPCode": "OTP 編碼",
	"Bank.tips1": "請使用您註冊時所使用的手機號碼接收 OTP 編碼",
	"Bank.tips2": "為了資金安全，銀行卡每天只能修改一次",
	"Bank.Add": "新增",
	"Bank.error1": "銀行持卡人姓名不能為空！",
	"Bank.error2": "請輸入正確的銀行持卡人姓名！",
	"Bank.error3": "銀行名稱不能為空！",
	"Bank.error4": "請輸入正確的銀行名稱！",
	"Bank.error5": "銀行帳號不能為空！",
	"Bank.error6": "請輸入正確的銀行帳號！",
	"Bank.error7": "IFSC 編碼不能為空！",
	"Bank.error8": "請輸入正確的 IFSC 編碼！",
	"Bank.error9": "電子郵件不能為空！",
	"Bank.error10": "請輸入正確的電子郵件地址！",
	"Bank.error11": "電話不能為空！",
	"Bank.error12": "OTP 編碼不能為空！",
	"Bank.Successfully": "銀行卡添加成功",
	"wallet.title": "我的錢包",
	"wallet.MyBalance": "我的餘額",
	"wallet.MyBank": "我的銀行卡",
	"wallet.RechargeRecords": "充值記錄",
	"wallet.WithdrawalRecords": "提現記錄",
	"Rechargepage.Tips": "溫馨提示：首次充值獎勵為充值金額的{{firstRPR*100}}% ",
	"Rechargepage.Tips_1": "溫馨提示：首次充值獎勵為充值金額的",
	"Rechargepage.Tips_2": "% .",
	"Rechargepage.Reminder": "提醒",
	"Rechargepage.Reminder_1": "1. 請勿向此地址存入 USDT 以外的其他貨幣。向此地址發送不支持的加密貨幣可能導致不可挽回的損失。",
	"Rechargepage.Reminder_2": "2. 最低存款金額為 30 USDT。",
	"Rechargepage.Reminder_2_1": "2. 最低存款金額為",
	"Rechargepage.Reminder_2_2": " USDT。",
	"Rechargepage.Reminder_3": "3. 存款將在15個網絡確認後到賬。",
	"Rechargepage.Minimum": "最低限額",
	"Rechargepage.title": "充值",
	"Rechargepage.History": "充值記錄",
	"Rechargepage.Mybalance": "我的餘額",
	"Rechargepage.RechargeAmount": "充值金額",
	"Rechargepage.RechargeAmountmsg": "請輸入充值金額",
	"Rechargepage.tips1": "支付渠道正在維護中！",
	"Rechargepage.tips2": "請選擇支付渠道",
	"Rechargepage.PayNow": "立即支付",
	"Rechargepage.err1": "支付渠道正在維護中",
	"Rechargepage.err2": "充值金額不能少於",
	"Rechargepage.err3": "請選擇支付渠道",
	"Rechargepage.Rechargemethod": "充值方式",
	"Rechargerecord.title": "充值記錄",
	"Rechargerecord.WatingOrder": "等待中訂單",
	"Rechargerecord.HistoryOrder": "歷史訂單",
	"Rechargerecord.Rupee": "美元",
	"Rechargerecord.Status": "狀態",
	"Rechargerecord.Time": "時間",
	"withdrawal.Google_Code": "Google驗證碼",
	"withdrawal.Tips": "提示",
	"withdrawal.Tips_1": " 提示: 提款低於",
	"withdrawal.Tips_2": " 美金，提款手續費為",
	"withdrawal.Tips_3": " 美金，提款 ",
	"withdrawal.Tips_4": "美金以上，提款手續費為",
	"withdrawal.Tips_5": "%",
	"withdrawal.WalletAddress": "錢包地址",
	"withdrawal.Addwalletaddress": "添加錢包地址",
	"withdrawal.Network_fee": "網絡手續費",
	"withdrawal.Receive_amount": "實際到賬",
	"withdrawal.Select Chains": "選擇鏈",
	"withdrawal.Bankcard": "銀行卡",
	"withdrawal.Nobankcardselected": "暫未添加銀行卡",
	"withdrawal.Withdrawalfee": "提款手續費",
	"withdrawal.title": "提款",
	"withdrawal.Mybalance": "我的餘額",
	"withdrawal.Withdrawablebalance": "可提款餘額",
	"withdrawal.tips1": "購買總額必須大於{{codeAmount}}才能全額提款",
	"withdrawal.tips1-1": "購買總額必須大於",
	"withdrawal.tips1-2": "才能全額提款",
	"withdrawal.tips2": "每日最多提款{{draw_num}}次",
	"withdrawal.tips2-1": "每日最多提款",
	"withdrawal.tips2-2": "次",
	"withdrawal.tips3": "每日最多提款金額為",
	"withdrawal.tips4": "最低單次提款金額為₹{{draw_min_money}}，最高單次提款金額為₹{{draw_max_money}}",
	"withdrawal.tips4-1": "最低單次提款金額為",
	"withdrawal.tips4-2": "，最高單次提款金額為",
	"withdrawal.tips5": "提款時間為{{draw_time}}，請在指定時間內提款",
	"withdrawal.tips5-1": "提款時間為",
	"withdrawal.tips5-2": "，請在指定時間內提款",
	"withdrawal.tips6": "提款將扣除{{draw_fee}}%的提款手續費",
	"withdrawal.tips6-1": "提款將扣除",
	"withdrawal.tips6-2": "的提款手續費",
	"withdrawal.tips7": "如果您的提款金額小於或等於₹{{solid_min_amount}}，將收取固定費用₹{{solid_feemoney}}",
	"withdrawal.tips7-1": "如果您的提款金額小於或等於",
	"withdrawal.tips7-2": "，將收取固定費用",
	"withdrawal.tips7-3": "",
	"withdrawal.WithdrawalAmount": "提款金額",
	"withdrawal.WithdrawalAmountmsg": "請輸入提款金額",
	"withdrawal.Withdrawal": "提款",
	"withdrawal.WithdrawalHistory": "提款記錄",
	"withdrawal.Tipsmsg": "提款申請已提交，等待審核結果！",
	"withdrawal.dangerMsg1": "請先添加銀行卡",
	"withdrawal.dangerMsg2": "提款金額不能少於",
	"withdrawal.dangerMsg3": "提款金額必須是100的倍數",
	"withdrawal.dangerMsg4": "提款金額不能大於",
	"withdrawal.dangerMsg5": "提款金額不能大於可提取餘額",
	"withdrawal.dangerMsg6": "請先添加您的銀行卡",
	"withdrawalrecord.title": "提現記錄",
	"withdrawalrecord.WatingOrder": "等待中",
	"withdrawalrecord.HistoryOrder": "歷史記錄",
	"withdrawalrecord.Rupee": "美元",
	"withdrawalrecord.Status": "狀態",
	"withdrawalrecord.Time": "時間",
	"withdrawalrecord.Nomore": "沒有更多了！",
	"Version.title": "版本",
	"Version.stableversion": "穩定版",
	"Version.Alreadythelatestversion": "已經是最新版本",
	"Version.Newversionfound": "發現新版本",
	"Version.DownloadUpdate": "下載更新",
	"Bonus.title": "獎勵活動",
	"Bonus.ReceiveBonus": "領取獎勵",
	"Bonus.Hi": "您好",
	"Bonus.tips1": "恭喜您獲得了獎勵",
	"Bonus.tips2": "您需要充值大於等於",
	"Bonus.tips3": "之後獎勵將自動添加到您的賬戶餘額中",
	"Bonus.Received": "已領取",
	"Bonus.Back": "返回",
	"Bonus.Rechargenow": "立即充值",
	"ComplaintsSuggestions.title": "投訴與建議",
	"ComplaintsSuggestions.Answered": "已回复",
	"ComplaintsSuggestions.AddNew": "新增",
	"ComplaintsSuggestions.Time": "時間",
	"ComplaintsSuggestions.Type": "類型",
	"ComplaintsSuggestions.WhatsApp": "WhatsApp",
	"ComplaintsSuggestions.Description": "描述",
	"ComplaintsSuggestions.Remark": "備註",
	"ComplaintsSuggestions.Waiting": "待處理",
	"ComplaintsSuggestions.Suggestion": "建議",
	"ComplaintsSuggestions.Consult": "諮詢",
	"ComplaintsSuggestions.RechargeProblem": "充值問題",
	"ComplaintsSuggestions.WithdrawProblem": "提現問題",
	"ComplaintsSuggestions.OrderProblem": "訂單問題",
	"ComplaintsSuggestions.Other": "其他",
	"ComplaintsSuggestions.Describeyourproblems": "請描述您的問題",
	"ComplaintsSuggestions.WhatsApptocontactyou": "WhatsApp 聯繫方式",
	"ComplaintsSuggestions.submit": "提交",
	"ComplaintsSuggestions.err1": "WhatsApp 不能為空！",
	"ComplaintsSuggestions.err2": "類型不能為空！",
	"ComplaintsSuggestions.err3": "描述不能為空！",
	"slotMachine.title": "水果機",
	"slotMachine.Myblance": "我的餘額",
	"slotMachine.Records": "水果機記錄",
	"slotMachine.titleRecord": "記錄",
	"bankrecharge.Orderamount": "訂單金額",
	"bankrecharge.Ordernumber": "訂單號",
	"bankrecharge.BankName": "銀行名稱",
	"bankrecharge.BankAccount": "銀行賬戶",
	"bankrecharge.IFSC": "IFSC編碼",
	"bankrecharge.Yourbankcardnumber": "您的銀行卡號",
	"bankrecharge.Enterthebanktransactionordernumber": "輸入銀行交易訂單號",
	"bankrecharge.submit": "提交",
	"bankrecharge.Tips": "提示",
	"bankrecharge.tips1": "1.系統正在處理您的訂單。",
	"bankrecharge.tips2": "2.如長時間未到賬，請聯繫客服。",
	"bankrecharge.err1": "請輸入您的銀行卡號",
	"bankrecharge.err2": "請輸入銀行訂單號",
	"javaCode.200": "成功",
	"javaCode.20001": "無效的訪問權杖",
	"javaCode.20002": "無效的登錄權杖",
	"javaCode.20003": "手機號碼錯誤",
	"javaCode.20004": "手機號碼已存在！",
	"javaCode.20005": "電子郵件錯誤！",
	"javaCode.20006": "電子郵件已存在！",
	"javaCode.20007": "密碼錯誤！",
	"javaCode.20010": "帳號或密碼錯誤！",
	"javaCode.20011": "銀行帳戶錯誤！",
	"javaCode.20012": "錯誤的銀行 IFSC（標準IFSC格式）- 長度11，前四個 IFSC 和第5個為0！",
	"javaCode.20013": "受益人名字錯誤，5 < 名字 <= 50！",
	"javaCode.20021": "帳號不存在！",
	"javaCode.20022": "帳戶被凍結！",
	"javaCode.20031": "層級限制為10！",
	"javaCode.20032": "更新關係角色錯誤！",
	"javaCode.20033": "餘額不足！",
	"javaCode.20034": "提款金額不正確！",
	"javaCode.30001": "Service temporarily closed!",
	"javaCode.30002": "limit bet money is between 10 and 50000!",
	"javaCode.30003": "Current issue has finished !",
	"javaCode.30005": "Checked in",
	"javaCode.31001": "Single transaction exceeds the limit !",
	"javaCode.31002": "Today's total amount has reached the upper limit !",
	"javaCode.31003": "The number of times today has reached the limit  !",
	"javaCode.31004": "Current Staff un config, pls contact admin or agent!",
	"javaCode.200081": "註冊錯誤！",
	"javaCode.200082": "帳戶已禁用！",
	"javaCode.200091": "添加銀行卡最多為10張！",
	"javaCode.200092": "輸入錯誤的登錄密碼次數太多，帳戶已被禁用！",
	"javaCode.300041": "All transaction amount of issue has limit !",
	"javaCode.300042": "All transaction amount of user has limit !",
	"javaCode.300043": "Bet item error !",
	"javaCode.300044": "You have already purchased it and cannot buy it again!",
	"javaCode.Servererror": "伺服器錯誤！",
	"javaCode.Handledataerror": "請登錄！",
	"javaCode.-91": "伺服器錯誤！",
	"javaCode.-92": "處理資料錯誤！",
	"javaCode.-1": "系統錯誤！",
	"javaCode.-2": "參數錯誤",
	"javaCode.-3": "沒有數據",
	"javaCode.-4": "短時間內頻繁操作",
	"javaCode.-5": "系統繁忙，請稍後再試！",
	"javaCode.-6": "目前人數過多，請稍後再試！",
	"javaCode.-7": "資料錯誤！",
	"javaCode.-8": "Google 驗證碼錯誤！",
	"javaCode.-9": "系統正在維護中！請稍後再試！",
	"javaCode.-10": "儲存錯誤！",
	"javaCode.-11": "刪除錯誤！",
	"javaCode.-12": "更新錯誤！",
	"javaCode.-13": "非法操作！",
	"javaCode.-14": "操作失敗！",
	"javaCode.-15": "操作已禁用！",
	"javaCode.-16": "操作完成！",
	"javaCode.-17": "存在錯誤",
	"javaCode.-18": "不存在！",
	"javaCode.-19": "已過期",
	"javaCode.-20": "已禁用",
	"javaCode.-21": "驗證碼錯誤",
	"javaCode.-22": "修改間隔必須大於1天",
	"javaCode.-23": "新密碼不能與原密碼相同",
	"javaCode.-100": "自定義錯誤！",
	"javaCode.-881": "System is being maintained! please try again later !",
	"javaCode.-882": "Amount params error",
	"javaCode.-883": "Channel Params error",
	"javaCode.-884": "Channel is maintained, pls try again later!",
	"javaCode.-885": "Channel is maintained, pls try again later!",
	"javaCode.-886": "Current recharge amount is less than the min limit amount!",
	"javaCode.-887": "Amount params error",
	"javaCode.-888": "Maximum number of withdrawals over a day",
	"javaCode.-889": "Maximum withdrawal amount exceeding one day",
	"javaCode.-8810": "Not enough code amount, also need code amount ",
	"javaCode.-8811": "Exceeded the maximum withdrawal amount",
	"javaCode.-8812": "Below the minimum withdrawal amount",
	"javaCode.-8813": "Current time is not service for withdraw !",
	"Management.TotalPurchaseAmount": "總購買金額",
	"Management.TotalRevenue": "總收益",
	"Management.AccountBalance": "帳戶餘額",
	"Management.Income": "收益",
	"Management.Transaction": "交易",
	"Management.Myhold": "我的持有",
	"Management.ProductList": "產品列表",
	"Management.Investmentperiod": "投資期限",
	"Management.days": "天",
	"Management.Buynow": "立即購買",
	"Management.Saletime": "銷售時間",
	"Management.Nomore": "沒有更多了",
	"Management.ProductDetails": "產品詳情",
	"Management.ForSale": "待售",
	"Management.Sale": "出售中",
	"Management.SoldOut": "已售罄",
	"Management.ProductInformation": "產品資訊",
	"Management.InvestmentPeriod": "投資期限",
	"Management.TotalShare": "總份額",
	"Management.ExpectedReturn": "預期回報",
	"Management.SaleTime": "銷售時間",
	"Management.PurchaseConditionsAndRules": "購買條件和規則",
	"Management.MinimumPurchaseAmount": "最低購買金額",
	"Management.MaximumSalesLimit": "最大銷售限制",
	"Management.AccountBalanceMustBeGreaterThan": "帳戶餘額必須大於",
	"Management.DayBetIsGreaterThan": "每日投資需大於",
	"Management.ProductDescription": "產品描述",
	"Management.MyHold": "我的持有",
	"Management.Ordernumber": "訂單號",
	"Management.PurchasingPrice": "購買價格",
	"Management.NoRecord": "無記錄",
	"Management.Expiredate": "到期日",
	"Management.Purchasetime": "購買時間",
	"Management.redemption": "贖回",
	"Management.redemptionTips": "提前贖回不會產生任何收益，確定要贖回嗎？",
	"Management.cancel": "取消",
	"Management.determine": "確定",
	"Management.Buyin": "購入",
	"Management.Buyamount": "購買金額",
	"Management.BuyinTips": "提前贖回不會產生任何收益 ",
	"Management.confirm": "確認",
	"Management.Minimumbuy": "最低購買金額",
	"Management.Maturity": "到期",
	"Management.EarlyRedemption": "提前贖回",
	"Management.Redemptiontime": "贖回時間",
	"Management.Amountofincome": "收益金額",
	"Management.prompt": "提示",
	"HelpHa.Help": "幫助",
	"HelpHa.HelpCenter": "幫助中心",
	"HelpHa.text": "有任何問題，請看這裡",
	"SetingHa.Seting": "設置",
	"SetingHa.SetingCenter": "設置中心",
	"SetingHa.text": "獲取更多獎勵",
	"coinjs.tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
	"coinjs.tips1_1": "Please switch the network to ",
	"coinjs.tips1_2": " and try again",
	"coinjs.trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees",
	"coinDefi.staking": "Staking Now",
	"coinDefi.All_Chains": "All Chains",
	"coinDefi.Annual_Percentage_Yield": "Annual Percentage Yield",
	"coinDefi.Daily_Yield_Rate": "Daily Yield Rate",
	"coinDefi.Mining_Period": "DeFi Period",
	"coinDefi.Flexible": "Flexible",
	"coinDefi.Wallet_Balance": "Wallet Balance",
	"coinDefi.Daily_Yield": "Daily Yield",
	"coinDefi.DeFi_Mining": "Join DeFi Mining Now",
	"coinDefi.Disconnect": "Disconnect",
	"coinDefi.Copy_Link": "Copy Link",
	"coinDefi.text1": "Mobile brower not supported",
	"coinDefi.text2": "TRON doesn't support mobile browser to connet to the wallet.",
	"coinDefi.text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
	"coinDefi.text3_1": "Please open it in ",
	"coinDefi.text3_2": "mobile wallet browser",
	"coinDefi.text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
	"coinDefi.text3_4": " etc.",
	"coinDefi.tips1": "Don't repeat DeFi Mining",
	"coinDefi.tips2": "Failed, please try again later",
	"coinDefi.tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
	"coinDefi.tips4": "Please log in to TronLink wallet first",
	"index.index.gayb21": "註冊獲取獎勵:",
	"index.index.72y628": "三個選擇理由 EPOCH EPT",
	"index.index.z6022y": "EPOCH EPT 遵循行業最高安全標準之一。我們將100％的加密貨幣存儲在冷藏庫中。我們保證至少為客戶資金提供1:1的儲備比例。我們的 3 億美元基金為安全威脅提供了額外的保護。",
	"index.index.574982": "1. 現在免費註冊 EPOCH EPT",
	"index.index.qdi1g4": "2. 完成谷歌驗證",
	"index.index.weko28": "3. 即時為您的 EPOCH EPT 帳戶充值",
	"index.index.sgkb35": "EPOCH EPT 邀請計劃",
	"index.index.2u17nf": "完成任何有效邀請即可獲得平均價值高達 5-15 USDT. 一百位被邀請者的最高獎金為",
	"index.index.e8is4a": "EPOCH EPT 推薦計劃",
	"index.index.47uevl": "EPOCH EPT 交易任務",
	"index.index.2516gq": "EPOCH EPT, Inc.已獲得紐約州金融服務部門頒發的虛擬貨幣業務活動許可證。",
	"invest.index.3aqi3d": "帳戶余額 :",
	"invest.index.ubk1j4": "停止投資！",
	"invest.prize1.4733u6": "投資組合:",
	"invest.prize1.t55a16": "總額:",
	"login.login.09m52q": "我已閱讀並同意 EPOCH EPT",
	"login.login.045csy": "用戶名",
	"login.login.r7rbm5": "請同意協議！",
	"rewards.activityb.4nvrq9": "當受邀者完成首次存款時，算作一次有效邀請。邀請人將收到每一次有效邀請的邀請獎金。邀請獎金等級來自 2USDT 1 次有效邀請 559USDT 100有效邀請。",
	"rewards.activityb.t10k6e": "邀請者將分享每個LV.1被邀請人交易手續費的 20% ％交易佣金。邀請者將分享每個LV.2被邀請人交易手續費的 10% ％交易佣金。被邀請人交易手續費獎勵的最大獎金為無限制美元。",
	"rewards.activityb.3d6850": "LV.1 有效受邀人數",
	"rewards.activityb.lvxo74": "LV.2 有效受邀人數",
	"rewards.activityb.zj26f9": "LV.1 累計受邀人數",
	"rewards.activityb.dkgxr5": "LV.2 累計受邀人數",
	"rewards.activityb.xjo077": "推薦獎勵:",
	"rewards.activityb.k8wv6v": "加入EPOCH EPT並獲取您的推薦連結。透過此連結註冊的用戶將獲得獎勵。",
	"rewards.activityb.4slpzg": "您的朋友完成首次存款，並完成所需的交易量後，您將獲得平均價值為5-15美元的推薦獎勵。獎勵最高可達559美元。",
	"rewards.index.993jwg": "复制成功",
	"rewards.index.7141ft": "该浏览器不支持自动复制",
	"wallet.index.9uz2gn": "網絡手續費 0 USDT",
	"wallet.index.ych3e1": "實際到賬 :",
	"wallet.index.3v558d": "提示: 提款低於 20 美金，提款手續費為 1 美金，提款 20 美金以上，提款手續費為 3%.",
	"wallet.index.255e30": "1.每日最多提款金額為 $8000000",
	"wallet.index.3g68m8": "2.最低單次提款金額為 $5 ，最高單次提款金額為 $8000000",
	"wallet.index.c6x3ti": "3.提款時間為 00:00-23:59, ，請在指定時間內提款",
	"wallet.index.h94rlg": "最低限額 5",
	"wallet.index.167862": "請輸入錢包地址！",
	"wallet.index.pi869h": "請輸入提現金額！",
	"wallet.index.ysa85i": "最高單次提款金額為",
	"components.changepass.68j9kh": "您正在嘗試重設您的密碼。",
	"components.changepass.ouxtp2": "原密碼",
	"components.languagebox.8no849": "日本語",
	"components.languagebox.oif20b": "繁體中文",
	"components.mycode.xyqr66": "完成任務最高可獲得 5000 USDT",
	"components.mymenu.277ss7": "用戶ID:",
	"components.mymenu.74l443": "註冊時間:",
	"components.myposter.36rw5e": "推薦碼:",
	"components.mytabbar.zw2bh2": "首页",
	"components.mytabbar.8x6c28": "趋势",
	"components.mytabbar.28s7qr": "投资",
	"components.mytabbar.4r75d9": "奖励",
	"components.xieyi.zqk7pe": "font-size: 16px; font-family: 宋体;",
	"components.help.6gpud8": "投資有2種模式:",
	"components.help.5qrq36": ": 每期持續1分鐘，僅提供單雙投資。結果由Binance BTC/USDT現貨交易1分鐘K線收盤價的最後一位數字計算。",
	"components.help.isl7r1": ": 每期持續5分鐘。專業版解鎖了 EPOCH EPT 的全部投資方法。結果由Binance BTC/USDT現貨交易5分鐘K線收盤價的最後一位數字計算。",
	"components.help.eai3nm": "投資回報:",
	"components.help.35xg82": "Single 數字:",
	"components.help.mpfg0s": "收益: 投資金額 X 9.4",
	"components.help.v2ziib": "Odd 數字:",
	"components.help.vfzxor": "收益: 投資金額 X 1.94",
	"components.help.73168b": "Even 數字:",
	"components.help.76wgv1": "Big 數字:",
	"components.help.05ed33": "Small 數字:",
	"components.changepass.2838g2": "請輸入原密碼！",
	"components.changepass.xme6my": "請輸入新密碼！",
	"components.changepass.u434f8": "兩次密碼不一致！",
	"components.changepass.574790": "修改成功！",
	"invest.index.9y8wg0": "未得奖",
	"invest.index.ql5nc1": "得奖",
	"login.login.76451t": "交易所差价A量化交易!",
	"login.login.915p36": "理财全球第一",
	"login.login.sen863": "Hello 登陆后更精彩",
	"login.login.nz6q9x": "电话登录",
	"login.login.w7p592": "邮箱登录",
	"login.login.g9y91k": "手机号",
	"login.login.456gbb": "邮箱号",
	"login.login.5ly8m8": "国家",
	"login.login.69h472": "请选择国家",
	"login.login.i4p378": "号码",
	"login.login.fl82xx": "示例 :",
	"wallet.index.urkoub": "充值二維碼",
	"wallet.index.25cocl": "複製地址",
	"wallet.index.t35fvg": "1.提款时间：请周1至周六24小时内，周日核算地区税！",
	"wallet.index.68xx61": "2.全球本国货币提款最迟48小时内到账，提款手续费8%（含地区税）。",
	"wallet.index.4ws3w9": "3.USDT提款最迟24小时到账，手续费2%（含地区税）",
	"wallet.index.9b7wq8": "4.最低提款12USDT，最高单次提款金额3000USDT。",
	"wallet.index.23y92a": "请知晓",
	"wallet.index.pl5473": "最低限额5",
	"wallet.index.nb3u72": "银行卡信息",
	"wallet.index.842so4": "添加银行卡号",
	"wallet.index.86j717": "添加所属银行",
	"wallet.index.763d7x": "添加银行卡姓名",
	"wallet.index.50j237": "所属银行",
	"wallet.index.hb6lun": "银行卡姓名",
	"wallet.index.62y2jc": "银行卡卡号",
	"login.register.666hp4": "Al差价量化交易",
	"login.register.9298b8": "邀请好友",
	"login.register.375g14": "新人首次加入",
	"login.register.01922f": "可进行1次",
	"login.register.527o4q": "日化收益AI",
	"login.register.c563gt": "邮箱注册",
	"login.register.2ls856": "手机号格式错误！",
	"login.register.4nu77e": "邮箱号格式错误！",
	"lang.zh.pu623z": "1 次有效邀請",
	"lang.zh.3hv80b": "USDT的充值！",
	"lang.zh.9tli5f": "提示: 提款低於",
	"lang.zh.62e882": "美金，提款手續費為",
	"lang.zh.m3818q": "美金，提款",
	"lang.zh.xs2f10": "提前贖回不會產生任何收益",
	"invest.index.hl86r1": "投资助手",
	"wallet.index.645d70": "请输入所属银行名称",
	"wallet.index.n21787": "请输入银行卡用户名称",
	"wallet.index.7s5j14": "请输入银行卡卡号",
	"components.recharge.l576h7": "余额变动",
	"components.recharge.3x6844": "备注",
	"components.appjump.u85nhy": "MoneyEasily 已成為我的新癮！",
	"components.appjump.543ht1": "我剛剛在 MoneyEasily 上賺了 50 美元，我終於可以自由地隨時隨地賺取額外的現金了。 我可以參加調查、玩遊戲或測試新應用程序並獲得報酬！ 立即註冊可獲得 50 美元的獎金！",
	"components.appjump.8k17w7": "分享成功",
	"components.appjump.648wav": "分享失败:",
	"components.appjump.7c2hl4": "当前浏览器不支持分享功能",
	"components.fuwu.1t2h7i": "内容",
	"components.fuwu.6t32g3": "市场背景。",
	"components.fuwu.4c8u7w": "智能量化交易系统",
	"components.fuwu.mw89jh": "自动请求重发",
	"components.fuwu.v5o60h": "出生。",
	"components.fuwu.12x554": "视野",
	"components.fuwu.d8kj48": "核心价值观。",
	"components.fuwu.sns568": "设计逻辑",
	"components.fuwu.131mc5": "基本原理。",
	"components.fuwu.76i3z7": "智能量化交易系统技术模型",
	"components.fuwu.253h82": "区块链技术。",
	"components.fuwu.64z717": "架构",
	"components.fuwu.14n9v4": "团队成员。",
	"components.fuwu.ewgac0": "历史和计划",
	"components.fuwu.yy18w5": "未来市场价值",
	"components.fuwu.h4l80q": "法律方面的考虑",
	"components.fuwu.1nvu89": "免责声明",
	"components.fuwu.yvo758": "市场背景",
	"components.fuwu.pgt1oc": "从",
	"components.fuwu.4vy2ub": "年到",
	"components.fuwu.sxj11f": "年初，价值型股票的表现明显落后于成长型股票，加剧了自全球金融危机以来价值因素长期表现不佳的状况。一些人将其归因于宏观利率因素&#8212;&#8212;低利率、债券收益率下降或收益率曲线平坦。",
	"components.fuwu.ihu32d": "研究了这些说法，发现价值和利率之间的关系在理论上是相当模糊和复杂的。从经验上看，在一定的参数下，利率变量与价值因子之间只有轻微的相关性。虽然在最近的数据样本中存在与债券收益率或收益率息差变化相关的显著模式，但这些相关性的经济意义在其他样本中较小且不一致。",
	"components.fuwu.ynfmrx": "的结论是，价值因子的表现不能用利率因子来解释，基于利率信号的因子择时策略难以有效实施。为了重新获得之前可观的投资回报曲线，必须关注数字经济。",
	"components.fuwu.041265": "是由",
	"components.fuwu.7ybglf": "和十名员工于",
	"components.fuwu.cv8q4k": "年在纽约创立的一家对冲基金公司，以专业的方式为客户解决问题学术研究和创新方法。",
	"components.fuwu.u18kd5": "在过去的",
	"components.fuwu.345nr1": "年中多年来，",
	"components.fuwu.uux4kt": "推出了多个国际股票策略和金融投资产品，帮助投资者在金融市场实现稳定的资产增值。到",
	"components.fuwu.2k2868": "年，",
	"components.fuwu.dfo204": "的对冲基金和金融产品管理的资产规模",
	"components.fuwu.rer5h5": "已超过",
	"components.fuwu.y62673": "亿美元，截至",
	"components.fuwu.mdq3mn": "年底，",
	"components.fuwu.944x0p": "在全球管理的基金规模超过",
	"components.fuwu.64sd2z": "亿美元",
	"components.fuwu.i2bs5g": "随着",
	"components.fuwu.34l46q": "计算能力、大数据、物联网",
	"components.fuwu.t8n5nh": "、云计算等领域的指数级发展，人工智能开始释放其力量，导致行业繁荣。",
	"components.fuwu.5765y1": "在短短几个月内席卷了互联网。它在数据挖掘、内容洞察、智能诊断、预测分析、智能进化等方面的应用逐渐为人们所熟知。然而，许多人不知道，人工智能应用，如面部识别和语音交互技术，已经融入了人们的日常生活。",
	"components.fuwu.138tt4": "据统计，从",
	"components.fuwu.jk42p7": "年，全球人工智能创业公司数量增加了",
	"components.fuwu.lu0nu3": "万家，总融资金额达到",
	"components.fuwu.edrz45": "万亿美元。许多科技公司都成立了人工智能部门，这表明人工智能时代已经到来。在过去的",
	"components.fuwu.0ty944": "年里，",
	"components.fuwu.267zhq": "一直走在技术、数据和行为金融交叉投资创新的前沿。它不断探索推动市场并将这些见解应用于客户投资组合，帮助全球客户实现稳定的财富增长。",
	"components.fuwu.768x8x": "随着云计算、边缘计算、大数据、",
	"components.fuwu.8hhz36": "和人工智能的迅速发展，",
	"components.fuwu.o32734": "的创始人",
	"components.fuwu.8hhu5s": "意识到产品需要与时代保持一致",
	"components.fuwu.jwkv4j": "否则，他们就会被市场淘汰。除了维持现有的股票和金融业务外，",
	"components.fuwu.4m7d39": "于",
	"components.fuwu.n48x5n": "年开始部署人工智能产品。通过利用人工智能技术来提高效率，预测应用趋势，整合各种数据，并利用波频交易模型，",
	"components.fuwu.4ukw28": "能够保持平均综合产品收益率在",
	"components.fuwu.ed1635": "左右。",
	"components.fuwu.o0516q": "深度涉足人工智能前沿，努力将数据挖掘、智能分析、智能进化等特点融入金融产品，使",
	"components.fuwu.7ognk6": "能够更加精准、轻松、稳健地推进金融市场。经过团队的不懈努力，",
	"components.fuwu.9ye2y0": "智能量化交易系统已经完成，并成功通过了两年的测试，我们认为是时候将其推向市场了。",
	"components.fuwu.hrwn57": "智能量化交易系统是继其股票市场金融产品之后部署在区块链上的数字金融产品。依托数字经济和人工智能，以更高效、精准、稳定、灵活、低成本、可追溯的方式管理用户的数字资产。这是经济学、行为金融学、数据和技术的新颖结合，使更多的有效地为客户资产增值。",
	"components.fuwu.jb545g": "年由",
	"components.fuwu.31h712": "名员工在纽约成立。它是一家对冲基金，利用专业的学术研究和创新的方法来解决客户的问题。在过去的",
	"components.fuwu.623c56": "已经推出了多个国际股票策略和金融投资产品，帮助投资者在金融市场实现稳健增长。到",
	"components.fuwu.o8270p": "管理的对冲基金和金融产品资产",
	"components.fuwu.j53w1u": "亿美元。截至",
	"components.fuwu.4fogox": "在全球管理着超过",
	"components.fuwu.h0al2w": "亿美元的基金，这得益于其巨大的全球影",
	"components.fuwu.202g6o": "响力。",
	"components.fuwu.85g7o1": "在澳大利亚开设了第一家国际办事处。",
	"components.fuwu.2ubf7n": "在英国设立了办事处。",
	"components.fuwu.ud1j47": "在伦敦商学院成立了资产管理研究所，以推进资产管理研究。",
	"components.fuwu.z45lgv": "在香港开设了办事处，扩大了亚洲业务。",
	"components.fuwu.4582f8": "在印度班加罗尔建立了一个工程中心。",
	"components.fuwu.m00yuh": "在德国设立了新的办事处，进一步扩大了其全球影响力。在",
	"components.fuwu.3r7069": "年。",
	"components.fuwu.jwwo48": "是公认的。",
	"components.fuwu.jnvf5m": "年至",
	"components.fuwu.l0bqqt": "年最佳养老和投资基金管理公司。",
	"components.fuwu.e777k5": "拥有",
	"components.fuwu.j0u235": "个合作伙伴和近千名员工，拥有一支拥有",
	"components.fuwu.8d8hnh": "多年研究经验的研究团队，并在顶级学术期刊上发表了大量论文。该公司将扎实的实践经验与量化工具相结合，以处理基本信息和管理风险。采用系统的研究驱动方式管理另类和传统战略，是一家罕见的&#8220;学术研究",
	"components.fuwu.y17m3k": "量化投资&#8221;双驱动公司。",
	"components.fuwu.59t85m": "出生",
	"components.fuwu.xd3f51": "自",
	"components.fuwu.p1o1m9": "年以来，",
	"components.fuwu.s6631u": "一直在思考人工智能在金融领域的应用，以解决人工交易的痛点。",
	"components.fuwu.654rrg": "正式发起成立人工智能量化交易系统研究中心，并起草了首份",
	"components.fuwu.3w3i3q": "智能量化交易系统白皮书。研究解决人工智能与交易模型相关问题，建立人工智能计算平台，构建人工智能量化交易系统。",
	"components.fuwu.315354": "成功将人工智能应用于交易并开发了",
	"components.fuwu.oef1gv": "智能量化交易系统。从数据收集开始，系统利用人工智能捕捉市场数据，包括历史价格、交易量、市场情绪等。然后对市场进行分析和探索，设定多种交易策略，建立不同的交易模型，并根据交易策略和预设的风控参数产生相应的交易信号。该系统通过自动交易操作。",
	"components.fuwu.kinmrh": "智能量化交易系统是由香港基金与德国顶级区块链技术团队共同开发并验证的智能交易平台。它利用人工智能、区块链、大数据等先进计算机技术，实现金融交易的去中心化管理。",
	"components.fuwu.1khwn2": "智能量化交易系统的核心技术算法利用区块链技术，实现不受地域限制的全球去中心化金融交易。",
	"components.fuwu.e5g3fa": "智能量化交易系统也将作为未来数字经济生态系统的",
	"components.fuwu.7ftmw3": "&#8220;助手&#8221;，为全球金融交易提供量化交易解决方案，实现高效区块链应用的快速部署。它简化了资产和数字资产的交易和交换。它还为主要金融应用程序提供",
	"components.fuwu.4646q8": "关键接口，连接各种应用场景，实现数字资产与各种行业应用的无缝集成。这为投资者提供了一个安全、快速、顺畅、稳定的投资环境。在",
	"components.fuwu.kh359j": "智能量化交易系统下，将利用人工智能的力量，构建具有超智能和超快能力的金融应用服务生态系统。这将使更多的人享受到数字时代带来的超级体验，速度、安全、稳定、去中心化等方向明确。它旨在创造一个新的生态系统，将人工智能应用于资产数字化，提供自由和创新。",
	"components.fuwu.wsp5m5": "智能定量交易系统的愿景是基于投资银行思维和风险投资模型。以人工智能技术为核心，依托大数据，为投资者提供性价比高、安全可靠的投资产品，实现长期稳定回报。",
	"components.fuwu.861380": "价值因素带来有吸引力的长期回报，但也会受到挑战时期的影响，这对投资者来说可能是痛苦的。然而，对于那些坚持不懈的人来说，随后的经济复苏是有利可图的。",
	"components.fuwu.igh8q8": "智能量化交易系统可以帮助投资者在困难时期克服情绪困扰实现长期价值回报。",
	"components.fuwu.u7cy1f": "回顾自",
	"components.fuwu.502xa6": "年以来发生的变化，许多资产类别的预期回报率较低并没有发生实质性变化。",
	"components.fuwu.yg0781": "智能量化交易系统可以有效地解决这些问题。",
	"components.fuwu.k7s41z": "大数据表明，实施直接指数采集的系统性策略在实施后的最初几年中所造成的净损失逐渐减少。这些策略也表现出高度多样化的净亏损结果。因此，由",
	"components.fuwu.pw7jg1": "智能量化交易系统驱动的因子投资多空策略可以显著优于直接指数策略。",
	"components.fuwu.9gya07": "投资于新兴市场和发达股市的预期溢价处于过去",
	"components.fuwu.yfiuur": "年观察到的区间的上端。与此同时，历史上与新兴市场相关的许多风险长期以来一直在减少。投资者有充分的理由增加对新兴市场的配置，其中包括数字资产。",
	"components.fuwu.j8nq18": "核心价值观",
	"components.fuwu.40fdvu": "在过去的二十年中，",
	"components.fuwu.w5kk35": "一直在不断探索市场的驱动因素，以及如何将其应用于经济学，行为金融学，数据和技术领域的客户投资组合。随着大数据和人工的兴起随着智能的发展，",
	"components.fuwu.pg3e4s": "的交易策略和模型变得越来越复杂。",
	"components.fuwu.kcjcom": "智能量化交易系统进入测试阶段，现已通过香港办事处正式向全球开放。",
	"components.fuwu.n21shs": "智能量化交易系统基于投资银行思维和风险投资模型，以人工智能技术为核心。它依靠大数据建立量化交易模型，旨在为投资者提供具有成本效益和安全的投资产品，并提供稳定的长期回报。",
	"components.fuwu.0l7u49": "利用区块链技术，",
	"components.fuwu.lgcse5": "智能量化交易系统开发了一个分布式、低成本、保护隐私的高性能计算网络，并提供全面的金融产品。",
	"components.fuwu.0t263a": "的高性能计算节点为交易模型提供强大的计算能力，包括人工智能训练与推理、数据抓取、区块链零知识计算等。",
	"components.fuwu.f5s17o": "的高性能计算节点可以有多种形式，包括运行在大型",
	"components.fuwu.u2615w": "或",
	"components.fuwu.a5en87": "服务器集群上的全功能节点",
	"components.fuwu.d4f145": "永久节点",
	"components.fuwu.29o1r5": "，以及来自中小型企业和个人",
	"components.fuwu.r54iix": "的空闲",
	"components.fuwu.5zx75g": "服务器计算节点。",
	"components.fuwu.6156mg": "智能量化交易系统在确保系统安全稳定运行的同时，让所有投资者都能从中受益。",
	"components.fuwu.p54y10": "在",
	"components.fuwu.o69yz3": "智能量化交易过程中，人工智能的处理和学习能力至关重要。它使交易系统变得更加智能、准确和自动化，从而实现更有效的交易决策和风险管理。",
	"components.fuwu.h1r7s5": "智能量化交易系统的设计原则如下",
	"components.fuwu.x27379": "可扩展性原则",
	"components.fuwu.rhqg19": "智能量化交易系统的各个模块应该是松耦合的，这样可以方便地添加新的模块，而不需要更改其他模块接口。",
	"components.fuwu.c7uqex": "客户产品用户对",
	"components.fuwu.46e7x1": "智能量化交易系统的访问是波动的。当大量用户访问单个节点时，可能导致业务崩溃。因此，节点的容器应该能够自动部署，当有用户请求的压力时，允许快速的水平扩展。",
	"components.fuwu.xc8u4k": "隐私原则",
	"components.fuwu.1n531t": "智能量化交易系统的投资者、计算节点、数据源等组成部分均享有隐私保护保障措施。",
	"components.fuwu.ed16w3": "基本原理",
	"components.fuwu.7h553b": "什么是",
	"components.fuwu.k4l2md": "智能量化交易",
	"components.fuwu.7nve0q": "智能量化交易是指利用人工智能技术和算法，自动执行投资决策，以达到交易效率最大化。它是一种基于大数据、机器学习、深度学习等技术的高频交易策略。通过对市场数据的快速分析，自动程序化。识别最佳交易机会并执行交易",
	"components.fuwu.g21385": "智能量化交易的优势在于能够快速分析市场数据，自动执行交易决策，避免人为因素的干扰和误判。此外，它可以在更广阔的市场和更大的规模上优化和扩展交易。此外，",
	"components.fuwu.v75es3": "智能交易系统将通过人工智能不断学习。通过长期的数据训练和模型优化，创造更好的交易模型，以适应市场变化，帮助用户实现长期盈利。",
	"components.fuwu.rcm80u": "交易流程在",
	"components.fuwu.2x15l9": "智能量化交易系统中，应用人工智能技术，实现智能交易自主决策能力。它可以根据预设的交易策略和参数自动执行交易，并根据市场情况和历史数据进行智能分析和预测，实现更加智能和自主的交易决策。具体来说，",
	"components.fuwu.xe1844": "智能量化交易系统利用大量的历史数据和市场信息进行数据挖掘和机器学习。它构建交易模型和算法，实时监控和分析市场，优化和调整交易信号和策略，以实现更有效的交易决策和风险管理。此外，人工智能驱动的量化交易系统通过人工智能技术和自学习能力，不断改进和增强自身的交易策略和算法，实现更智能和自主的交易决策。因此，",
	"components.fuwu.8jr1h7": "&#8220;智能&#8221;代表了人工智能量化交易系统的智能水平和自主决策能力。它可以为投资者实现更高效、准确、更大的投资回报。以及自动交易决策和风险管理，带来在",
	"components.fuwu.62r19x": "智能量化交易中，",
	"components.fuwu.1v4571": "&#8220;量化&#8221;代表对市场数据和交易策略的量化和分析。量化交易是建立在收集、分析、以及处理大量数据，将市场信息和交易策略转化为自动决策和交易的数字算法。具体来说，",
	"components.fuwu.35fgx0": "智能量化交易系统收集了大量的市场数据，包括历史价格、交易量、市场情绪等，对市场进行分析和探索。它根据这些数据构建各种交易策略。利用这些交易策略和预定义的风险控制参数，系统生成相应的交易信号并执行自动交易。在这个过程中，数据的量化和分析使交易系统更加智能、准确和自动化，从而实现更有效的交易决策和风险管理。因此，",
	"components.fuwu.ce4mr4": "&#8220;量化&#8221;代表了",
	"components.fuwu.cfq4pv": "量化交易的核心技术。它是数字化市场信息和交易策略的重要手段，可以实现更快、更精确和自动化的交易决策和风险控制。",
	"components.fuwu.6b0w31": "优势",
	"components.fuwu.7q677y": "效率",
	"components.fuwu.624ufa": "智能量化交易系统自动执行交易策略，能够快速准确地捕捉交易机会，同时避免人为错误和情绪干扰。",
	"components.fuwu.6k6y74": "精准",
	"components.fuwu.y23qq8": "智能量化交易系统利用大量历史数据和市场信息进行数据分析和模型预测，提高交易决策的准确性和可靠性。",
	"components.fuwu.s11cqu": "稳定性",
	"components.fuwu.3q6d66": "智能量化交易系统实施严格的风险管理和交易控制，确保投资组合的稳定性和长期回报。",
	"components.fuwu.w386vn": "灵活性",
	"components.fuwu.530y3q": "智能定量交易系统能够快速适应市场变化和新的交易机会，同时还允许根据投资者偏好定制交易策略和参数。",
	"components.fuwu.ph3l81": "可追溯性",
	"components.fuwu.7ap85x": "智能量化交易系统记录和跟踪每笔交易的细节和历史数据，便于投资者进行后续分析和优化。",
	"components.fuwu.v2jkcm": "生态系统",
	"components.fuwu.ics30f": "智能量化交易系统以",
	"components.fuwu.0nui8m": "为核心，实现了高性能计算的分散供给。该系统的重点是构建用于人工智能的基础设施即服务",
	"components.fuwu.n868ra": "层。所有需要高性能计算的平台即服务",
	"components.fuwu.ns02co": "和软件即服务",
	"components.fuwu.f94h07": "层服务都可以在",
	"components.fuwu.6ftu9v": "智能量化交易系统上自由运行。",
	"components.fuwu.b19msv": "区块链技术",
	"components.fuwu.864583": "智能量化交易系统的核心在于底层公链技术，将金融模型、人工智能、量化交易相结合，处理高交易吞吐量。因此，",
	"components.fuwu.61j6bp": "智能量化交易系统的主链采用了",
	"components.fuwu.q1j1n6": "共识机制，并结合了",
	"components.fuwu.30nl6v": "的实用性，提高了系统的容错能力、交易效率和交互频率。该网络由",
	"components.fuwu.3kqnle": "个超级节点组成，负责交易验证和区块生成。除了超级节点，还有常规节点与核心节点同步数据，管理钱包私钥和地址，发起链上交易。为满足人工智能建模和高频量化交易的需求，",
	"components.fuwu.i1g6e7": "智能量化交易系统不断推动底层公链技术的发展。通过优化共识机制，利用侧链技术，实现更高的",
	"components.fuwu.j3spal": "，处理更多的交易",
	"components.fuwu.d6r6r2": "以并发处理更多事务并支持增加的用户流量。",
	"components.fuwu.rfs8eb": "智能量化交易系统实现了跨链机制，引入了零知识证明隐私保护机制，确保用户信息不外泄。系统以统一的方式在区块链上注册",
	"components.fuwu.5l87r6": "应用程序，确保一旦通过智能合约确认资产，所有数据都是开放、透明和防篡改的。因此，使用",
	"components.fuwu.ci8bt7": "进行共享资产交易是基于完全可靠的数据，消除了虚假资产或虚假交易的风险。",
	"components.fuwu.li76g2": "总体架构",
	"components.fuwu.2881li": "智能量化交易系统的网络节点既可以是大型矿池等大型计算节点，也可以是",
	"components.fuwu.u15fc6": "、阿里云等平台提供的中型计算节点或云计算资源。",
	"components.fuwu.2w1u75": "智能量化交易系统所获得的云端和边缘计算能力，保证了人工智能运行环境的稳定性。一旦投资者将",
	"components.fuwu.6cu4ph": "存入",
	"components.fuwu.88fccf": "智能量化交易系统，人工智能将根据",
	"components.fuwu.4gfpl8": "的数量进行搜索、识别、分层、管理和保存。随后",
	"components.fuwu.5vdauc": "通过",
	"components.fuwu.0ud3oi": "网络中，对应的",
	"components.fuwu.8sm2by": "和计算节点为找到了适合这批",
	"components.fuwu.189o1y": "的交易模式区块链。该模型所需的计算能力为分配给相应的",
	"components.fuwu.y83nn2": "，实现量化交易。",
	"components.fuwu.78ik3b": "3.2.2计算节点架构",
	"components.fuwu.0wdoj7": "计算节点架构",
	"components.fuwu.25g0dk": "3.2.3大数据+人工智能",
	"components.fuwu.r2e1x7": "智能量化交易系统包含一个分布式的高性能计算网络，本质上是大数据与人工智能相结合的基础设施。它具有以下特点",
	"components.fuwu.n4ct1t": ":底层框架构建在Matrix平台上，采用基于Topic订阅、事件驱动处理和处理器的软件体系结构。通过高性能异步架构，它可以实现卓越的性能。多链结构由主链和工作链组成。主链包含所有工作链的模式定义，并且工作链的数量是不受限制的。每条工作链由分片区块链组成，支持无限分片。块数据基于模式定义，允许灵活的块大小，并支持块数据的压缩存储。",
	"components.fuwu.b9yr3c": "智能量化交易系统内部的通信和传输采用自编码压缩协议或加密传输协议。这样可以减少网络带宽，并且可以在同一链路内协商不同的消息包编码协议，提高传输安全性。",
	"components.fuwu.92179q": "三维网络",
	"components.fuwu.y24z29": ":建立在点对点(P2P)网络的基础上，它建立了一个分层网络，加速消息传播。SN节点具有地理上的多样性，可以动态调整网络拓扑结构，增强整体网络的鲁棒性，抵御DDoS攻击，降低安全风险。网络共识机制:采用DPOS&nbsp;(Delegated&nbsp;Proof&nbsp;of&nbsp;Stake)共识机制，结合用户权利和贡献来评估网络参与。它采用分层处理方法进行存储、共识和验证，增强了整个网络的可扩展性。",
	"components.fuwu.i5s5w9": "3.2.4网络结构",
	"components.fuwu.248t2x": "解决区块链可扩展性的主流解决方案可以分为两种类型",
	"components.fuwu.75e81d": ":垂直可扩展性和水平可扩展性。典型的垂直可扩展性解决方案包括优化共识算法、大块存储和系统性能增强，如块扩展、EOS&nbsp;DPOS和VRF+POS+PBFT。典型的横向可扩展性解决方案包括闪电网络、多链架构和分片架构，都旨在解决区块链的可扩展性和可扩展性问题。区块链4.0旨在在大规模去中心化网络上构建高性能分布式业务系统，重点关注实际业务实施。没有单一的技术改进可以完全解决整体的可伸缩性问题。任何区块链的整体架构设计都需要考虑四个关键要素:P2P网络架构、底层存储架构、计算/共识架构和业务流程设计。更进一步说，在区块链的世界里，去中心化、可扩展性和安全性的设计属性三者只能有两个，所有的可扩展性设计都是基于这个原则。",
	"components.fuwu.854i8h": "智能量化交易系统采用多链架构，增强了整个网络的可扩展性。通过利用多链提供的隔离，可以安全地隔离",
	"components.fuwu.kw1e7d": "DApp数据。随着多个链的并行执行，系统的性能可以线性增长。然而，在多链架构下，单个链仍然存在性能瓶颈。因此，分片技术的引入进一步增强了单个区块链的性能，实现了水平可扩展性。在多链之间引入跨链路由器和跨链中继层，支持同质子链和异构侧链的中继。通过锚定子链/侧链到建立了主链，多链之间的互联互通，从而创建了具有无限可扩展性的区块链架构。",
	"components.fuwu.y11qiw": "3.2.5分片架构在",
	"components.fuwu.q7u6kb": "智能量化交易系统中采用分片的目的主要是为了提高单个区块链的性能和整体网络的可扩展性。每个分片并行处理不同的事务子集，实现分片内共识，实现事务并行执行。",
	"components.fuwu.k61o0n": "3.2.5.1完整的分片架构由三部分组成:网络分片、计算分片(交易/共识)和存储分片(状态分片)。首先，",
	"components.fuwu.4t6qe4": "智能量化交易系统根据分片规则将网络中的节点划分为不同的虚拟分片",
	"components.fuwu.7yx4qo": "(也称为集群)。它保证了每个分片内的连通性以及分片内节点与主网络之间的网络连通性。分片内的事务仅在该分片内传播，以减少带宽消耗。其次，所有参与共识的节点都需要提供保证金并在智能合约上注册。基于分片规则，我们可以对共识节点进行分片发生在不同分片中的交易由相应分片的共识节点进行打包和验证。由于不同的分片事务是跨分片并行验证的，因此共识性能可以线性扩展。随着网络性能的提高，将整个分类帐数据存储在网络中的每个节点上变得不可行的。假设网络的整体性能为100万TPS，那么每个节点每天的分类账数据存储空间将超过20&nbsp;TB。为了解决这个问题，",
	"components.fuwu.2wb32r": "智能量化交易系统将存储层与计算层分离，使用账本文件系统存储账本数据，由",
	"components.fuwu.69229m": "智能量化交易系统网络共同维护。",
	"components.fuwu.l65907": "3.2.5.2可编程虚拟碎片(vShard)P2P网络中的每个节点都有一个独立的PeerID，与其他节点组成结构化或非结构化的P2P网络。基于不同的业务需求，网络节点在物理P2P网络之上使用智能合约进行虚拟分片。每个节点以编程方式计算自己的vShard&nbsp;ID，属于同一vShard&nbsp;ID的节点在逻辑上构成一个独立的网络分片。每个网络分片在物理上来源于统一的P2P网络。但是，它在逻辑上是隔离的，提供了所需的功能网络可扩展性设计的逻辑独立性。每个独立的P2P网络节点在逻辑上可以属于多个虚拟分片。分片节点之间需要建立网络连接，形成区域自治的分片网络。分片是动态和弹性的，允许网络灵活地增加分片的数量。主网仍然可以建立非结构化和结构化的P2P网络。",
	"components.fuwu.255q2n": "3.2.5.3计算分片共识节点:分为全局共识节点和内部共识节点。全球共识节点负责在主网络中达成共识，而分片内共识节点负责处理每个分片内的共识。共识可以采用插件机制，允许不同的分片支持不同的共识机制。分布式随机生成协议:随机协议需要具有公平性、不可预测性、第三方可验证性和可扩展性。主分片共识节点使用VRF算法分配分片，并随机负责不同分片的共识。不同分片的共识节点处理各自分片内的交易。见证节点选择:从中随机选择节点分片共识节点池参与实际的共识过程。共识节点通过定期选举来防止恶意攻击。每个分片的共识节点数量可以灵活增加。共识节点通过定期选举来防止恶意攻击。每个分片的共识节点数量可以灵活增加。",
	"components.fuwu.vr9w4q": "3.2.5.4事务分片",
	"components.fuwu.524h25": "智能量化交易系统将交易分为分片内交易和跨分片交易。",
	"components.fuwu.25ebl0": "shard事务地址由事务地址和vShard&nbsp;ID组成。如果一个事务的发送方地址和接收方地址属于同一个分片，则该事务为分片内事务。如果发送方和接收方地址属于不同的分片，则是跨分片交易。Insurance&nbsp;will&nbsp;be&nbsp;each&nbsp;to&nbsp;E&nbsp;workers&nbsp;the&nbsp;tsa分片内事务:事务仅在vShard分片内传播，并由分片共识节点打包和验证。跨分片交易:由主分片共识节点打包验证",
	"components.fuwu.737wf6": "3.2.6网络爬虫",
	"components.fuwu.6ub735": "智能量化交易系统的运行依赖于数据收集、分析和建模等能力。",
	"components.fuwu.3917n5": "智能定量交易系统利用人工智能，通过基于云的方法定期定量地从整个网络收集数据。它通过",
	"components.fuwu.540486": "集成与人工智能定量系统无缝集成。然后，系统执行筛选、过滤、分析和建模，为后端工作人员提供可视化信息，并为人工智能提供数据支持。",
	"components.fuwu.i576fn": "团队成员",
	"components.fuwu.8a523u": "Cliff&nbsp;Asness在著名经济学家、2013年诺贝尔经济学奖获得者Eugene&nbsp;Fama教授的指导下，获得宾夕法尼亚大学沃顿商学院和工程与应用科学学院的双学士学位。",
	"components.fuwu.clunb8": "资本管理公司是华尔街最著名的量化对冲基金之一。他曾担任",
	"components.fuwu.w47xsg": "Goldman,&nbsp;Sachs&nbsp;&&nbsp;Co.资产管理部的董事总经理兼定量研究总监。他是the&nbsp;Journal&nbsp;of&nbsp;Portfolio&nbsp;Management的编辑委员会成员，纽约大学数学金融学院的管理委员会成员，Q-Group的董事会成员，国际救援委员会的董事会成员和the&nbsp;Ntn的董事会成员",
	"components.fuwu.8v041z": "大卫",
	"components.fuwu.1yi18o": "&#183;卡比勒创始负责人",
	"components.fuwu.f126cm": "年金融投资经验，",
	"components.fuwu.551s9t": "持证人工商管理硕士，学士，西北大学，高盛副总裁，萨克斯公司",
	"components.fuwu.638xk0": "刘约翰EPOCH EPT",
	"components.fuwu.2d27j5": "主要",
	"components.fuwu.891s7f": "年金融投资经验博士，工商管理硕士，芝加哥大学芝加哥大学文学学士他在芝加哥大学获得了经济学学士学位，在那里，他被选为",
	"components.fuwu.khhc66": "的成员，并继续在芝加哥获得",
	"components.fuwu.qad15u": "和金融博士学位。",
	"components.fuwu.ummboy": "保罗",
	"components.fuwu.f1uc41": "&#183;雅各布斯",
	"components.fuwu.7du1a3": "年技术开发经验这位EPOCH EPT技术负责人曾在谷歌担任软件工程师，拥有印度理工学院",
	"components.fuwu.7964ma": "分校的学士学位、斯坦福大学的硕士学位和宾夕法尼亚大学沃顿商学院的",
	"components.fuwu.7m912c": "学位。负责",
	"components.fuwu.nj2151": "，谷歌工具栏，并参与谷歌操作系统的开发，个人也曾担任谷歌",
	"components.fuwu.itkrrj": "的总裁，熟悉这一应用程序。",
	"components.fuwu.c7gc6f": "米歇尔",
	"components.fuwu.27102u": "&#183;阿加西主要25年工作经验麻省理工学院博士布朗大学学士",
	"components.fuwu.5549r8": "职位：",
	"components.fuwu.qtx3m3": "EPOCH&nbsp;EPT&nbsp;3-5天短期量化AI师",
	"components.fuwu.xj5p65": "名言：市场变化很快，在短期时间把握机会，快进快出。",
	"components.fuwu.nbk6k7": "格雷戈",
	"components.fuwu.o11m0w": "&#183;m&#183;安德拉德主要21年经验芝加哥大学博士麻省理工学院学士学位",
	"components.fuwu.58snmu": "EPOCH&nbsp;EPT&nbsp;5-10天短期量化AI师",
	"components.fuwu.sdve3x": "名言：充足的市场经验配合永不停歇的",
	"components.fuwu.3no8us": "AI智能，收益如此简单。",
	"components.fuwu.8ru302": "布拉德利",
	"components.fuwu.6zk9gb": "&#183;d&#183;阿斯尼斯主要25年工作经验纽约法学院法学博士布兰代斯大学文学学士",
	"components.fuwu.748c4r": "EPOCH&nbsp;EPT&nbsp;15-20天短期量化AI师",
	"components.fuwu.ykoem3": "名言：充足的时间能带来更好的机会，让收益更稳定。",
	"components.fuwu.c8741k": "杰弗里",
	"components.fuwu.mnj8g5": "&#183;博尔杜克主要19年经验特许金融分析师贝茨学院文学士芝加哥大学工商管理硕士5.&nbsp;",
	"components.fuwu.pt4258": "EPOCH&nbsp;EPT&nbsp;30-50天短期量化AI师",
	"components.fuwu.yhx273": "名言：我非常看好长期而稳定的投资，没有什么比这更好。",
	"components.fuwu.l1axrb": "时间事件",
	"components.fuwu.425wcf": "/里程碑",
	"components.fuwu.n33tpy": "1998.成立并推出首个对冲基金产品。",
	"components.fuwu.hch6ip": "2000.推出了首个国际股票战略，其回报赢得了国际赞誉。",
	"components.fuwu.51k20j": "2016.在香港开设了办事处，以扩大其亚洲业务。",
	"components.fuwu.e7b8ez": "2016.第四季度EPOCH EPT在香港成立了人工智能研究中心",
	"components.fuwu.gk53k8": "2019.第二季",
	"components.fuwu.51y6x1": "1.0版白皮书发布",
	"components.fuwu.7p2h89": "2020.第一季度开发EPOCH EPT公共区块链",
	"components.fuwu.19t2i2": "2020.第四季度EPOCH EPT建立大数据中心",
	"components.fuwu.00bmfi": "2021.第一季度",
	"components.fuwu.812he4": "量化交易系统连接EPOCH EPT大数据中心。",
	"components.fuwu.7vb4bx": "2021.第三季",
	"components.fuwu.i5s411": "量化交易系统启动包测试",
	"components.fuwu.4rzg05": "2022.第二季",
	"components.fuwu.6f1vku": "量化交易系统实现了总计",
	"components.fuwu.7j4ksr": "13亿美元的定量利润。",
	"components.fuwu.oox35c": ".英国伯明翰",
	"components.fuwu.t13534": "注册成立",
	"components.fuwu.27p688": "EPOCH&nbsp;EPT并推出全球首个AI智能操盘系统。",
	"components.fuwu.0at9r7": "6.&nbsp;未来市场价值在不久的将来，可以预见数十亿人将以数字资产的形式存储他们的财富。这些资产不仅包括传统货币和实物财产，还包括虚拟数字资产。数字资产具有保值、可兑换和对冲等多重属性，可以减轻金融危机或冲突造成的贬值或损失的风险。它们提供了一种保护和保护个人资产的机制，使不同时期和领域之间的交互不受限制。随着越来越多的用户持有数字资产，他们寻求稳定的投资，以增加数字资产的价值，并应对实体经济和通货膨胀的变化。这就是",
	"components.fuwu.r4o4y5": "定量交易系统作为最佳选择的地方。目前，全球有超过",
	"components.fuwu.yt83vn": "3亿人参与区块链市场的投资活动，全球数字资产价值超过500亿美元。区块链数字资产交易产生的佣金超过5亿美元，市场潜力巨大。为了更好地保值和增值，采用",
	"components.fuwu.74n7mh": "量化交易系统彻底改变了传统的交易方式，让更多的数字资产投资者获得更高的回报和回报。它还为传统投资者提供了对冲和财富增长的机会。在未来世界，金融市场将是数字资产交易的领域，而",
	"components.fuwu.h335ga": "量化交易系统将引领这个时代的发展。放眼全球，",
	"components.fuwu.5173h3": "量化交易系统以最短的时间在金融投资领域确立了自己的地位。它创造了基于数字经济、数字资产和区块链技术的新应用模式。",
	"components.fuwu.jw5ueb": "7.&nbsp;法律方面的考虑目前，随着越来越多的加密货币被归类为证券，美国、新加坡、中国、瑞士和德国的金融当局已经收紧了对首次代币发行(ico)的政策或发出了警告。",
	"components.fuwu.r3jm21": "承认并将遵守几个主要司法管辖区的证券法规，并遵守",
	"components.fuwu.2a723k": "KYC(了解您的客户)和AML(反洗钱)法规。具体来说，这意味着:证券规则:请仔细阅读本节内容。如果您对应该采取的行动有任何疑问，我们建议您咨询您的法律、财务、税务或其他专业顾问。本文档中列出的信息可能不全面，也不代表合同关系的任何基本要素。本文件不构成投资、税务、法律、监管、财务、会计或任何其他建议，也不能成为参与恒生指数生态系统支持和发展的唯一原因。在做出任何决定之前，潜在买家必须咨询他们的法律、投资、税务、会计和其他顾问，以确定此类交易的潜在利益、限制和其他后果。本文件的任何部分并非发售、招股说明书或招标书，亦不拟作为在任何司法管辖区以证券形式投资的要求。本文件并非根据禁止或限制与数字代币或其使用相关的交易的任何司法管辖区的法律或法规编写的。KYC和AML:了解你的客户(KYC)和反洗钱(AML)法规的技术细节可能因国家/地区而异，但它们都需要根据各种恐怖分子、禁运和政治暴露个人(PEP)名单对客户身份进行验证和交叉检查。",
	"components.fuwu.n5ijvs": "正在与专业服务提供商合作实施这一过程。在大众投资的过程中，投资者凯特",
	"components.fuwu.y06wpn": "8.&nbsp;免责声明本文件不涉及法律管辖范围内的任何受规管产品。本文件是一份概念性文件(白皮书)用于项目说明，仅供参考，不用于销售或募集股票、证券或任何其他",
	"components.fuwu.5t61y5": "相关产品和关联公司的受监管产品。本文件不应被视为招股章程或任何标准化合约文件，亦不构成对任何司法管辖区内的证券或任何其他受规管产品的投资建议或招揽。本文档不应被用作销售、认购或邀请购买或认购任何证券或基于它们的任何合同、联系或承诺的建议。它不应被视为投资参与的建议。本文件中提供的任何信息或分析不构成参与代币投资的任何建议，也不会提出任何具体建议。您必须就相关事宜寻求所有必要的专业意见，例如税务和会计审查。本文档不构成任何声明或保证。用于解释我们提出的",
	"components.fuwu.j73g2y": "-&nbsp;al量化交易系统，但",
	"components.fuwu.3ip5we": "基金会明确说明如下",
	"components.fuwu.1x311y": "8.1对于本文档描述的任何内容或在本文档中发布的与项目相关的任何其他内容的准确性或完整性，不作任何声明或保证任何其他形式。",
	"components.fuwu.0c5427": "8.2在没有先决条件的情况下，不提供任何关于成就或可行性的前瞻性或概念性陈述或保证。",
	"components.fuwu.h6u91t": "8.3本文档中的任何内容不作为对未来的任何承诺或声明的基础。",
	"components.fuwu.if1i12": "8.4个人因本白皮书或其他方面造成的任何损失，本公司不承担任何责任。",
	"components.fuwu.m21co1": "8.5责任限于适用法律允许的最大范围，在不可避免的法律责任范围内。与相关地区和国家监管政策相关的风险:区块链技术在全球范围内获得支持和认可的同时，也受到各个监管机构的仔细审查。",
	"components.fuwu.t5i928": "量化交易系统的功能可能会受到某些监管政策的影响，包括但不限于限制使用或持有",
	"components.fuwu.77k38h": "USDT，这可能会阻碍或限制",
	"components.fuwu.sxf326": "量化交易系统的发展。其他未知风险",
	"components.fuwu.t62x6w": ":区块链技术及其对应的数字货币技术是相对较新的、未经充分验证的技术，可能以各种方式存在不可预见的风险。",
	"login.register.6l6651": "电话注册",
	"login.register.bsu16p": "确认密码",
	"login.register.8r578v": "两次输入的密码不一样",
	"components.xieyi.z4tui6": "欢迎您使用",
	"components.xieyi.985i12": "有限公司（下文简称“",
	"components.xieyi.k71g1d": "我们",
	"components.xieyi.6473ug": "”或“平台）”的产品和服务！为了向您提供平台项下的各项技术服务，我们将会在您的操作过程中处理您的个人信息。我们深知个人信息对您的重要性，您的信任对我们非常重要，我们将在现行有效的技术条件下，严格遵守法律法规的要求采取相应的安全保护措施，致力于保护您的个人信息安全。基于此，我们制定本《隐私权政策》（下称“本政策“），帮助您充分了解在您使用我们产品和服务的过程中，我们会如何收集、使用、共享、存储和保护您的个人信息以及您可以如何管理您的个人信息，以便您更好地作出适当的选择。",
	"components.xieyi.r997hr": "在您开始使用我们的产品和服务前，请您务必先仔细阅读和理解本政策，特别应重点阅读我们以",
	"components.xieyi.23i3k9": "粗体/粗体下划线",
	"components.xieyi.5jo3lp": "标识的条款，确保您充分理解和同意之后再开始使用。本政策中涉及的专业词汇，我们尽量以简明通俗的表述向您解释，以便于您理解。",
	"components.xieyi.sw2x3b": "如对本政策内容有任何疑问、意见或建议，您可通过",
	"components.xieyi.w1nc5l": "平台官方客服与我们联系。",
	"components.xieyi.u855jt": "您同意隐私政策仅代表您已了解应用提供的功能，以及功能运行所需的必要个人信息，并不代表您已同意我们可以收集非必要个人信息，非必要个人信息会根据您使用过程中的授权情况单独征求您的同意。",
	"components.xieyi.jiyuvt": "如您拒绝本《隐私政策》，您仍能够在无注册/登录状态下使用浏览基本功能。您也可以在后续使用服务过程中单独进行授权。",
	"components.xieyi.61890q": "本政策将帮助您了解以下内容：",
	"components.xieyi.5mt6j8": "一、定义及适用范围",
	"components.xieyi.41xpk4": "二、我们如何收集、使用您的信息",
	"components.xieyi.83j8rl": "三、我们如何使用Cookie和同类技术",
	"components.xieyi.8b639p": "四、我们如何共享、转让、公开披露您的信息",
	"components.xieyi.6dq28b": "五、我们如何保护您的信息",
	"components.xieyi.2ulrqx": "六、我们如何存储您的信息",
	"components.xieyi.3nz3ga": "七、您如何管理您的信息",
	"components.xieyi.9s39yt": "八、我们如何处理未成年人的信息",
	"components.xieyi.ew14nj": "九、本政策如何更新",
	"components.xieyi.12eq82": "十、您如何联系我们",
	"components.xieyi.b1sb46": "（一）定义",
	"components.xieyi.we1z6j": "指英国伯明翰",
	"components.xieyi.rw8pei": "注册EPOCH EPT有",
	"components.xieyi.idu1sg": "限公司。",
	"components.xieyi.m568up": "平台：",
	"components.xieyi.hvxy61": "指",
	"components.xieyi.73ijj4": "网站（域名为www.weipaitang.com）、APP客户端（",
	"components.xieyi.h0re24": "）以及微信公众号等服务媒介，又称“",
	"components.xieyi.ivy1b3": "”、“平台”。",
	"components.xieyi.ki07fh": "关联公司：",
	"components.xieyi.6c317m": "指与",
	"components.xieyi.wr8y6r": "之间存在直接或间接控制关系或重大影响关系的企业，包括持有",
	"components.xieyi.5865bv": "股权超过5%的股东、",
	"components.xieyi.hty2r8": "的子公司、",
	"components.xieyi.36ty5w": "实际控制人控制的其他企业等。",
	"components.xieyi.lsnc7x": "支付公司",
	"components.xieyi.47x242": "：指为您在",
	"components.xieyi.gmmfq4": "平台的购物提供支付服务的支付机构。",
	"components.xieyi.k10tpg": "个人信息：",
	"components.xieyi.69e87n": "指以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。",
	"components.xieyi.o1m0al": "个人敏感信息：",
	"components.xieyi.2h5e47": "指一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括",
	"components.xieyi.2ipl66": "生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹",
	"components.xieyi.8122dw": "等信息，以及",
	"components.xieyi.124tei": "不满十四周岁未成年人的个人信息",
	"components.xieyi.swn32o": "（我们将在本政策中对具体个人敏感信息以",
	"components.xieyi.704x6v": "粗体",
	"components.xieyi.274q3q": "进行显著标识）。",
	"components.xieyi.u2twjv": "个人信息处理：",
	"components.xieyi.9813ba": "指包括个人信息的收集、存储、使用、加工、传输、提供、公开、删除等。",
	"components.xieyi.rutsx1": "儿童",
	"components.xieyi.81twf5": "：指不满十四周岁的未成年人。",
	"components.xieyi.5jsxyn": "除另有约定外，本政策所用定义与",
	"components.xieyi.hu1431": "交易服务用户协议》",
	"components.xieyi.f61m93": "中的定义具有相同的涵义。",
	"components.xieyi.4kd4rv": "（二）适用范围",
	"components.xieyi.s893e8": "本政策适用于",
	"components.xieyi.p95qjo": "以",
	"components.xieyi.1d536g": "网站、客户端、小程序、公众号以及随技术发展出现的新形态向您提供的各项产品和服务。",
	"components.xieyi.d02u4n": "如我们及关联公司的产品或服务中使用了",
	"components.xieyi.vd5142": "的产品或服务（例如使用",
	"components.xieyi.2ifgyw": "平台账户登录），但未设独立隐私权政策的，则本政策同样适用于该部分产品或服务。我们及关联公司就其向您提供的产品或服务单独设立有隐私权政策的，则相应产品或服务适用相应隐私权政策。",
	"components.xieyi.lzne8r": "除本政策说明的相关信息收集使用活动外，",
	"components.xieyi.8o7eza": "本政策不适用于",
	"components.xieyi.c7i3mg": "平台卖家及其他第三方向您提供的其他服务，其他服务适用其向您另行说明的隐私政策等个人信息收集使用规则",
	"components.xieyi.f4n8lb": "。例如，当您参加",
	"components.xieyi.51p7u0": "EPOCH EPT操作的时候",
	"components.xieyi.9ngrl3": "，基于法律法规的要求向您说明其收集和适用您个人信息的规则。",
	"components.xieyi.62e3v6": "根据《常见类型移动互联网应用程序必要个人信息范围规定》、",
	"components.xieyi.3n4mh2": "APP属于“网上",
	"components.xieyi.8q81vo": "理财",
	"components.xieyi.57m6k2": "”，基本功能服务为“",
	"components.xieyi.6891w4": "AI智能",
	"components.xieyi.s2ji36": "”，此功能下必要个人信息包括：您的电话号码；收货人姓名（名称）、地址、联系电话；支付时间、支付金额、支付渠道等支付信息。",
	"components.xieyi.wyy6r5": "为您提供便捷的电子商务交易平台服务，为了实现服务的提供，我们会基于平台的不同功能分别处理您的个人信息：",
	"components.xieyi.t1c3k8": "1、基本功能：为实现向您提供我们产品及/或服务的基本功能，您须授权我们收集、使用的必要的信息。如您拒绝提供相应信息，否则您将无法正常使用我们的产品及/或服务",
	"components.xieyi.y7m0r2": "2、附加功能：为实现向您提供我们产品及/或服务的附加功能，您可选择是否授权我们收集、使用的信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果。",
	"components.xieyi.cwp3h6": "我们在此特别提醒您：",
	"components.xieyi.63078v": "1、我们致力于打造多样的产品和服务以满足您的需求。",
	"components.xieyi.11t174": "因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，故基本/附加功能及对应收集使用的个人信息类型、范围等会有所区别，请以具体的产品/服务功能为准；",
	"components.xieyi.654y1j": "为给您带来更好的产品和服务体验，我们持续努力改进我们的产品、服务及技术，随之我们可能会不时推出新的或优化后的功能，可能需要收集、使用新的个人信息或变更个人信息使用目的或方式。如发生上述情形，我们将通过更新本政策、弹窗、页面提示等方式另行向您详细说明，并为您提供自主选择同意的方式，且在征得您明示同意后再开始收集、使用。",
	"components.xieyi.228036": "在此过程中，如果您有任何疑问、意见或建议的，您可通过本政策规定的渠道与我们联系，我们会尽快为您作出解答。",
	"components.xieyi.3l69jv": "我们会为您提供的各项具体功能场景包括：",
	"components.xieyi.xq77st": "（一）用户注册及账户管理",
	"components.xieyi.hgd0m6": "1、基础用户服务",
	"components.xieyi.sy4rg3": "我们基于",
	"components.xieyi.3768od": "平台账户为您提供服务。为了创建",
	"components.xieyi.k5pm58": "平台账户您需要至少向我们提供您的",
	"components.xieyi.ijyhl0": "手机号码、拟使用的用户名和密码",
	"components.xieyi.k04bce": "。如果您拒绝提供上述信息，您将无法注册",
	"components.xieyi.4b0us6": "平台账户，但您依然可以使用浏览、搜索服务。",
	"components.xieyi.3k9d16": "您可以修改和补充您的昵称、性别、地区、联系人以及您的实名认证相关信息，这些信息均属于您的“账户信息”。",
	"components.xieyi.rqik5d": "其中，您设置的昵称和头像，将公开显示。",
	"components.xieyi.ilmlc1": "您补充的账户信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您使用网上购物的基本功能。",
	"components.xieyi.2d8htr": "为了确保我们是在为您本人提供服务，我们可能会根据您提供的上述信息校验您的身份。若存在依法需确定您用户身份的场景（包括依法为您扣缴税费、行政执法或司法诉讼中相关主体认定等）时，您授权我们可自我们的合作伙伴获取您对应的相关认证信息用于上述目的。",
	"components.xieyi.qimk2p": "我们会根据您的",
	"components.xieyi.z57g36": "平台账户使用情况及平台设置的账户等级计算规则确定您当前的等级，并为您提供相应账户等级所对应的基本权益。",
	"components.xieyi.21n38j": "2、附加服务",
	"components.xieyi.dh7o8w": "实人认证：您可以根据认证要求提供相应的",
	"components.xieyi.57s046": "身份信息（身份证、护照、户口簿及其他身份证件信息）、生物识别特征（静态或动态的面部特征）",
	"components.xieyi.eb3ena": "以完成实人认证。",
	"components.xieyi.2fnv9d": "授权登录：我们可能经您同意后向第三方共享您的账户信息（头像、昵称及其他页面提示的信息），使您可以便捷地实现第三方账户的注册或第三方处直接登录。此外，我们可能会根据您的授权从微信等第三方处获取您的第三方账户信息，并与您的",
	"components.xieyi.2586kj": "平台账户进行绑定，使您可在第三方处直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使用您的相关信息。",
	"components.xieyi.75o8vj": "其他用户角色服务：如果您申请注册成为卖家或其他存在额外身份认证要求的用户角色，您需主动向我们提供身份信息及/或企业相关信息，用于您身份及其他用户角色获取资格的核验、登记、公示及其他我们明确告知的目的。如”",
	"components.xieyi.ni3907": "授权理财",
	"components.xieyi.1168tn": "“，您同意将我们收集您的手机号、身份证号码用于直播账号实名制管理以及收集设备标识符、设备品牌型号、设备操作系统、地理位置用于直播功能匹配及直播安全管控。",
	"components.xieyi.k2wd14": "3、身份验证",
	"components.xieyi.1ns543": "为满足相关法律规定及监管要求、确保用户身份真实性（包括依法保护未成年人权益、打击电信网络诈骗、代扣代缴个人所得税、行政执法或司法诉讼中相关主体认定）、实现反欺诈等风险控制、保障系统和服务安全，在您使用特定服务/功能时，我们需要收集您的相关信息进行身份验证，如：【修改手机号】当您使用“修改手机号”功能找回账号情况下，个人用户我们可能会收集您的身份信息，包括您的",
	"components.xieyi.3lj887": "真实姓名、身份证件号码、人脸识别信息、订单信息",
	"components.xieyi.7y2f63": "用于证实您的身份，帮您找回平台账户。",
	"components.xieyi.y57u7o": "企业用户我们可能会收集您的法人身份信息，包括",
	"components.xieyi.82yye6": "企业营业证照信息、企业法人的真实姓名、身份证件号码、人脸识别信息",
	"components.xieyi.t6tsvp": "如果您已拥有",
	"components.xieyi.3coa6i": "平台账户，我们可能会在",
	"components.xieyi.4g34dh": "平台服务中显示您的上述个人信息，以及您在",
	"components.xieyi.i3lx1u": "平台上或与",
	"components.xieyi.cd0jo8": "平台账户相关联的产品和服务中执行的操作，包括通过",
	"components.xieyi.f5dq1g": "平台账户集中展示您的个人资料、优惠权益、交易订单",
	"components.xieyi.vo3428": "。我们会尊重您对",
	"components.xieyi.y3j2fo": "平台账户设置所做的选择。",
	"components.xieyi.pw3g77": "（二）向您展示商品、服务信息及消息推送",
	"components.xieyi.82aac2": "1、为向您展示商品或服务信息，包括您的访问足迹、历史搜索情况，我们会收集和使用您在访问或使用",
	"components.xieyi.h4s010": "平台时的浏览、搜索记录。我们会结合依法收集的设备信息、服务日志信息，以及其他取得您授权的信息，通过算法模型预测您的偏好特征。我们会基于您的偏好特征，向您推送您可能感兴趣的商业广告及其他信息，或者向您发送商业性短信息。",
	"components.xieyi.kf81h2": "个性化推荐与检索类算法会基于模型预测您的偏好特征，匹配您可能感兴趣的商品、服务或其他信息，对向您展示的商品、服务或其他信息进行排序。我们会根据您使用产品过程中的浏览行为，对推荐模型进行实时反馈，不断调整优化推荐结果。为满足您的多元需求，我们会在排序过程中引入多样化推荐技术，拓展推荐的内容，避免同类型内容过度集中。",
	"components.xieyi.182j2p": "设备信息：我们会根据您在访问及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、MAC地址及IMEI、IDFA、OAID、Android",
	"components.xieyi.1ns292": "Id设备标识符、设备环境、移动应用列表软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置以及WLAN接入点、蓝牙和基站传感器信息）。",
	"components.xieyi.y2hur5": "服务日志信息：当您访问或使用",
	"components.xieyi.2l7v45": "平台时，我们会自动收集和记录您的详细使用情况，作为服务日志保存，包括浏览记录、点击查看记录、搜索查询记录、围观、举报、交易、售后、关注、分享信息、发布信息以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。",
	"components.xieyi.i2q27g": "请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。",
	"components.xieyi.48iqgk": "如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。",
	"components.xieyi.2e8s23": "同时，我们通过以下措施努力保障您的隐私体验：",
	"components.xieyi.83qc71": "（1）如您要删除您的浏览和搜索记录，您可以在“我的-足迹”、“搜索框-最近搜索”中选择删除；",
	"components.xieyi.72qs2r": "（2）如您在使用我们提供的站内搜索服务时，需要查看不针对您个人特征的搜索结果，您可以在搜索结果页面点击“筛选”进行设置；",
	"components.xieyi.35ps8a": "（3）如您想管理我们给您发送的个性化广告，您可以在“",
	"components.xieyi.777kl1": "我的-设置（右上角）-隐私-个性化设置”",
	"components.xieyi.8026t2": "中进行设置。需注意的是，您可设置我们不使用某些类别信息向您展示相关广告，但是您仍然会看到广告，您看到的广告数量不会变化，只不过广告的相关性会下降。",
	"components.xieyi.5hx7h7": "如您想管理我们给您推送的个性化内容，您可以在“",
	"components.xieyi.5vxbqy": "我的-设置（右上角）-隐私-个性化设置",
	"components.xieyi.8wfq82": "”中进行设置。关闭后相关“推荐、为你推荐“等推荐模块内容为随机推荐非个性化推荐。",
	"components.xieyi.f98v89": "（4）如您想删除带有您个人特征的兴趣爱好标签、可通过",
	"components.xieyi.3ve6e7": "（5）如您对首页展示的商品不感兴趣，可以通过长按被推荐的商品图片，根据提示弹窗选择“拍品不感兴趣”，我们将减少相关商品的展现。",
	"components.xieyi.6mlx8c": "2、商业信息推送及活动提醒服务",
	"components.xieyi.1y06bp": "为了让您更好地了解和使用",
	"components.xieyi.t45916": "，您同意我们向您发送商业信息以及其他活动提醒等信息，",
	"components.xieyi.q2y74e": "及其服务提供商将基于上述服务目的，收集、使用您的姓名及联系方式。",
	"components.xieyi.84b8nh": "如您不想接收我们的推送消息，",
	"components.xieyi.eh6abn": "您可通过“我的-设置（右上角）-消息设置”中关闭",
	"components.xieyi.3drpe9": "如您不想接受我们给您发送的商业广告，",
	"components.xieyi.16389e": "您可通过短信提示回复退订或我们提供的其他方式进行退订或关闭；",
	"components.xieyi.i69xy9": "如您关闭或退订推送功能，您将无法获得商品或活动信息推送的服务",
	"components.xieyi.2s65pg": "3、传感器信息。",
	"components.xieyi.vn4s8x": "当您使用我们的产品/服务过程中，我们可能会收集您的传感器信息以适配您的设备状态或在您选择参与活动时以识别您的动作，不同场景下的传感器类型可能不一致，我们仅会在具体需要的场景中才会使用。例如，在拍摄时、用于判断用户拍摄时是否旋转屏幕，方便对拍摄视频进行校正优化。",
	"components.xieyi.opxu33": "（三）为您提供围观、关注与分享功能",
	"components.xieyi.46wa51": "在您浏览",
	"components.xieyi.sm4d35": "平台的过程中，您可以选择对感兴趣的商品、服务及/或其他用户进行围观、通过我们提供的功能组件向第三方分享商品/服务信息。在您使用上述功能的过程中，我们会收集包括您的",
	"components.xieyi.qt6mj3": "操作",
	"components.xieyi.k465t1": "记录、",
	"components.xieyi.13pdf3": "合作",
	"components.xieyi.j6413h": "关系、分享历史在内的服务日志信息用于实现上述功能及其他我们明确告知的目的。",
	"components.xieyi.g5yo4i": "您可以在“我的-围观”、“我的-关注”中删除您的围观和关注信息。",
	"components.xieyi.es68il": "（四）帮助您完成下单及订单管理",
	"components.xieyi.439hc7": "当您在我们的产品及/或服务中订购具体商品及/或服务时，我们会通过系统为您生成购买该商品及/或服务的订单。在下单过程中,您需至少提供您的",
	"components.xieyi.4zfg7p": "收货人姓名、收货地址、收货人联系电话",
	"components.xieyi.875811": "，对于部分特殊类型的商品和服务您还需要提供该商品或服务所必需的其他信息，例如涉及",
	"components.xieyi.sg3r7o": "文物交易时您需要提供您的真实姓名、身份证号以完成文物保护相关法律法规和文物监督管理机关要求的备案。",
	"components.xieyi.x52n3c": "同时，该订单中会载明您所购买的商品及/或服务信息、具体商品编号、您应支付的金额。我们收集这些信息是为了帮助您顺利完成交易、保障您的交易安全、便于您查询订单信息、提供客服与售后服务及其他我们明确告知的目的。",
	"components.xieyi.633jos": "您可以通过",
	"components.xieyi.s43d5n": "平台为其他人订购商品及/或服务，您需要提供该实际收货人的前述个人信息，并确保已取得该实际收货人的同意",
	"components.xieyi.q3y8ui": "为便于您了解、查询订单信息并对订单信息进行管理，我们会收集您在使用我们服务过程中产生的订单信息用于向您展示及便于您对订单进行管理。",
	"components.xieyi.prc15q": "您可以通过“我的-我的订单”查看和管理您的订单信息。",
	"components.xieyi.ncd26i": "（五）帮助您完成支付",
	"components.xieyi.3u52sy": "为完成订单支付，您需要在付款时选择支付方式和支付渠道。我们会将您的个人信息、订单支付相关信息、订单安全相关设备信息及其他反洗钱法律要求的必要信息通过在网站或客户端中嵌入的支付公司工具开发包（软件工具开发包在本政策中简称“SDK”）等方式与支付公司共享。如您选择由其他金融机构为您提供支付服务，我们还会将您的包括银行卡号、有效期在内的银行卡支付必要信息与您选择的相应金融机构共享。",
	"components.xieyi.m65845": "为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象、支付公司或您选择的其他金融机构处收集与支付进度相关的信息。",
	"components.xieyi.bydbtx": "您可以查看您的",
	"components.xieyi.rarv1g": "平台钱包账户所绑定的银行账户信息或申请更换绑定的银行账户。",
	"components.xieyi.73148a": "（六）帮助向您完成商品或服务的交付",
	"components.xieyi.f8ce6b": "为保证您购买的商品及/或服务能够顺利、安全、准确送达、提供，您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。",
	"components.xieyi.wi14jv": "为向您提供售后与争议解决服务之目的，我们需要及时获悉并确认交付进度及状态，您同意我们可自物流相关服务主体处收集与交付进度相关的信息。",
	"components.xieyi.h3txyu": "（七）客服及争议处理",
	"components.xieyi.75p62d": "当您与我们联系或提出售中、售后、争议纠纷处理申请时，为了保障您的账户及系统安全，",
	"components.xieyi.u3u59b": "我们需要您提供必要的个人信息以核验您的用户身份。",
	"components.xieyi.r9ucq4": "为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们可能会保存您与我们的沟通、通信/通话/视频记录及相关内容（包括账户信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账户信息和订单信息。",
	"components.xieyi.te87ps": "为了提供服务及改进服务质量的合理需要，我们还可能使用到您的其他信息，包括您与客服联系时您提供的相关信息、您参与问卷调查时向我们发送的问卷答复信息。",
	"components.xieyi.259477": "（八）为您提供评论、问答等信息公开发布功能",
	"components.xieyi.67h2ep": "您可通过我们为您提供的评论、社区、直播及其他信息发布功能公开发布信息，包括作为用户可发布图文/视频内容、发表评价内容等，以及作为卖家发布商品及/或服务相关信息、店铺相关信息。",
	"components.xieyi.194252": "我们可能会根据您的用户类型及网络日志信息判断您是否可享受对应功能权限（例如只有曾购买过某商品的用户才可以就该商品发布评价）。",
	"components.xieyi.x8rr9h": "请注意，您公开发布的信息中可能会涉及您或他人的个人信息甚至个人敏感信息，如您在评价时选择上传包含个人信息的图片，请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。若您公开发布的信息中涉及他人个人信息的，您需在发布前征得他人的同意。",
	"components.xieyi.h60ox8": "（九）为您提供安全保障",
	"components.xieyi.q466n3": "为履行保障电子商务交易安全的法定义务，提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入等安全风险，更准确地识别违反法律法规或",
	"components.xieyi.3p5627": "平台相关协议、规则的情况，我们在应用程序中嵌入我们关联公司开发的应用安全SDK收集您的设备信息",
	"components.xieyi.4n220b": "服务日志信息、设备传感器数据、常用的软件信息并可能使用或整合您的用户信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息（其中，我们可能会在应用后台运行期间收集MAC地址、IMEI设备相关信息进行风险检验），来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。",
	"components.xieyi.4ay5f9": "（十）APP自启动或关联启动",
	"components.xieyi.i2rhwf": "为了提升用户使用我们服务的体验和更好地保障交易安全，我们需要通过app自启动或关联启动功能，提示和帮助用户获取我们更新版的app应用服务。",
	"components.xieyi.46wk64": "（十一）为您提供的其他附加服务",
	"components.xieyi.9w14b7": "为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会收集和使用您的个人信息。",
	"components.xieyi.7jt388": "你可以自主选择是否提供这些信息，如果您不提供这些信息，不会影响您使用",
	"components.xieyi.1c6h45": "的基本服务，",
	"components.xieyi.035322": "但您可能无法获得这些附加服务给您带来的用户体验。",
	"components.xieyi.w541k9": "您可在您的设备“设置”或我们客户端“我的-设置-隐私—系统权限”中逐项查看上述权限的状态，可自行决定这些权限随时的开启或关闭。",
	"components.xieyi.t77ks8": "请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法继续为您提供该权限所对应的服务。您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。",
	"components.xieyi.q563dr": "您可",
	"components.xieyi.bs34gv": "点击此处",
	"components.xieyi.w6lbn0": "查看应用权限申请与使用情况说明",
	"components.xieyi.835h48": "（十二）收集和使用个人信息的其他规则",
	"components.xieyi.jy12wf": "若您提供的信息中含有其他用户的个人信息，在向",
	"components.xieyi.m6i6t5": "平台提供这些个人信息之前，您需确保您已经取得合法的授权。",
	"components.xieyi.a1o5v4": "若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，除法律法规规定的情形之外，均会事先征得您的同意。",
	"components.xieyi.41gslf": "3、若我们从第三方处间接获取您的信息，我们会在收集前明确以书面形式要求该第三方说明其个人信息来源，以及是否已经就其收集、处理以及向我们提供您的个人信息取得了您的合法授权。除法律明确允许的情形外，我们只会在确认第三方已经取得您的授权同意后再从第三方收集您的个人信息，如果第三方的授权范围无法涵盖我们的处理和使用目的时，我们会自行或者要求该第三方征得您的同意后再行处理您的个人信息。同时，我们对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制等）。我们会使用不低于我们对自身用户个人信息同等的保护手段与措施对间接获取的个人信息进行保护。",
	"components.xieyi.x3r3y6": "4、征得授权同意的例外",
	"components.xieyi.3jev4v": "根据相关法律法规规定，我们在以下情况下处理您的个人信息无需您的授权同意：",
	"components.xieyi.214uq6": "（1）为履行法定职责或法定义务所必需的；",
	"components.xieyi.81k4nu": "（2）与国家安全、国防安全、重大公共利益直接相关的；",
	"components.xieyi.j7fomu": "（3）为应对突发公共卫生事件的；",
	"components.xieyi.7806cy": "（4）为公共利益实施新闻报道、舆论监督等行为，在合理的范围内处理个人信息的；",
	"components.xieyi.754474": "（5）紧急情况下，为保护您或者他人的生命健康或者重大财产安全且无法及时或者有效的向您告知的，但在紧急情况消除后会及时向您告知；",
	"components.xieyi.i1yv2t": "（6）依相关法律规定，在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息；",
	"components.xieyi.5dz1kl": "（7）根据与您签订和履行相关协议或其他书面文件所必需的；",
	"components.xieyi.5bon72": "（8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，例如发现、处置产品及/或服务的故障；",
	"components.xieyi.cw60x0": "（9）法律法规规定的其他情形。",
	"components.xieyi.vyw19u": "请知悉，根据现行有效的相关法律，若我们",
	"components.xieyi.4t3bn8": "对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原",
	"components.xieyi.1c74f9": "，或我们可能会对收集的信息进行去标识化地研究、统计分析和预测，用于改善",
	"components.xieyi.1m85sf": "平台的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），",
	"components.xieyi.me4rhf": "则此类处理后数据的使用无需另行向您通知并征得您的同意",
	"components.xieyi.257c58": "三、我们如何使用",
	"components.xieyi.77f388": "和同类技术",
	"components.xieyi.759p76": "（一）Cookie的使用",
	"components.xieyi.48ze34": "为确保网站正常运转、为您获得更轻松的访问体验、向您推荐您可能感兴趣的内容，我们会在您的计算机或移动设备上存储Cookie、Flash Cookie，或浏览器（或关联应用程序）提供的其他通常包含标识符、站点名称以及一些号码和字符的本地存储（统称“Cookie”）。借助于\r\n                                    Cookie，网站能够存储您的偏好等数据。",
	"components.xieyi.s020rs": "如果您的浏览器或浏览器附加服务允许，您可修改对Cookie的接受程度或拒绝我们的Cookie。",
	"components.xieyi.3w12li": "有关详情，请参见 AboutCookies.org。但如果您执行本条所述操作，在某些情况下可能会影响您安全访问我们的网站，且可能需要在您每一次访问我们的网站时更改用户设置。",
	"components.xieyi.q45ikb": "（二）Cookie同类技术的使用",
	"components.xieyi.h550rh": "除",
	"components.xieyi.7248f8": "外，我们还会在网站上使用网站信标、像素标签、ETag等其他同类技术。",
	"components.xieyi.jvxl8f": "例如，我们向您发送的电子邮件可能含有链接至我们网站内容的地址链接，如果您点击该链接，我们则会跟踪此次点击，帮助我们了解您的产品或服务偏好，以便于我们主动改善用户体验。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，我们能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从我们的寄信名单中退订。",
	"components.xieyi.52wb6a": "ETag（实体标签）是在互联网浏览器与互联网服务器之间背后传送的HTTP协议标头，可代替Cookie。ETag可以帮助我们避免不必要的服务器负载，提高服务效率，节省资源、能源。同时，我们可能通过ETag来记录您的身份，以便我们可以更深入地了解和改善我们的产品或服务。",
	"components.xieyi.y92k4u": "大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。",
	"components.xieyi.o54yhy": "但请注意，如果停用ETag，您可能无法享受相对更佳的产品或服务体验。",
	"components.xieyi.37e045": "（一）共享",
	"components.xieyi.675822": "我们不会与",
	"components.xieyi.x1y6r8": "以外的公司、组织和个人共享您的个人信息，但以下情况除外：",
	"components.xieyi.8q0s05": "1、在法定情形下的共享：我们会根据法律法规规定对外共享您的个人信息。",
	"components.xieyi.9irym2": "2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。",
	"components.xieyi.371i1r": "3、为您提供您需要的产品或服务必要的情况下共享：您通过",
	"components.xieyi.75u39x": "平台购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易、配送及售后服务需求。",
	"components.xieyi.u6d81m": "4、与关联公司间共享：",
	"components.xieyi.30si92": "为便于我们基于",
	"components.xieyi.6pysc9": "平台账户向您提供产品和服务，推荐您可能感兴趣的信息，识别用户账户异常，保护",
	"components.xieyi.7vmrlb": "关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息可能会与我们的关联公司和/或其指定的服务提供商共享。",
	"components.xieyi.269i0k": "我们只会共享必要的个人信息，且受本政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。",
	"components.xieyi.5475kt": "5、委托授权合作伙伴：",
	"components.xieyi.u1sa8e": "我们可能委托授权合作伙伴为您提供某些服务或代表我们履行职能，",
	"components.xieyi.f1h4lc": "我们仅会出于本政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且我们将会通过协议要求其不得将此信息用于其他任何目的。",
	"components.xieyi.g700e1": "目前，我们的授权合作伙伴包括以下类型：",
	"components.xieyi.36nvwn": "（1）广告、分析服务类的授权合作伙伴。我们会委托这些合作伙伴处理与广告覆盖面和有效性相关的信息，但除非得到您的许可，否则我们不会将您的个人身份信息与提供广告、分析服务的合作伙伴共享，或者我们会将这些信息进行去标识化处理，以便授权合作伙伴无法识别您个人。这类合作伙伴可能将上述信息与他们合法获取的其他数据相结合，以执行我们委托的广告服务或决策建议。",
	"components.xieyi.5xh5u7": "（2）供应商、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、分析我们服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；或者我们需要将您的订单号及订单金额与第三方支付机构共享以实现其确认您的支付指令并完成支付等。",
	"components.xieyi.67e7b8": "（3）实名认证服务商。我们在向您提供实名认证服务时，您需要授权我们收集您的实名认证信息提供给第三方机构（包括但不限于腾讯云计算(北京)有限责任公司，以下简称“第三方机构”），由第三方机构自行向有关单位（包括但不限于中国人民银行个人信用信息基础数据库及其他有关政府机构、基础电信运营商、信息机构、公安机关、检察院、法院、金融机构、其他合作机构或公开渠道）查询、验证该类信息的真实性、准确性和完整性，对该类信息进行分析、比对、加工、整理，以及向我们反馈对于您个人信用信息的整体性评价报告。",
	"components.xieyi.12lv44": "为保障",
	"components.xieyi.3749fw": "平台的稳定运行、前述功能的实现，使您能够享受和使用更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他类似的应用程序。",
	"components.xieyi.3nos3g": "关于我们接入的合作伙伴等与第三方共享清单您可以",
	"components.xieyi.70yu7f": "进行查看。",
	"components.xieyi.89014s": "我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。",
	"components.xieyi.1l5529": "（二）转让",
	"components.xieyi.ls670h": "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：",
	"components.xieyi.u50832": "1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；",
	"components.xieyi.6snoud": "2、在",
	"components.xieyi.411611": "发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会及时告知您新的持有您个人信息的公司、组织的名称和联系方式并要求其继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。",
	"components.xieyi.783yjz": "（三）公开披露",
	"components.xieyi.zdq4b7": "我们仅会在以下情况下，公开披露您的个人信息：",
	"components.xieyi.3in5j6": "1、获得您明确同意或基于您的主动选择，我们可能会公开披露您的个人信息。",
	"components.xieyi.ey5b87": "2、基于法律法规规定的信息披露的要求，在规定的范围内披露您的个人信息，例如《网络交易监督管理办法》规定的卖家信息公示。",
	"components.xieyi.ok7p68": "如果我们确定您出现违反法律法规或严重违反",
	"components.xieyi.6kb306": "平台相关协议及规则的情况，或为保护",
	"components.xieyi.41el81": "平台用户或公众的人身财产安全免遭侵害，我们可能依据法律法规或征得您同意的情况下披露关于您的个人信息并停止向您提供服务，",
	"components.xieyi.4c16r3": "包括相关违规行为以及",
	"components.xieyi.7uq8p7": "平台已对您采取的措施。例如，若您因出售假冒商品而严重违反",
	"components.xieyi.kc0i6h": "平台规则，我们可能会公开披露您的店铺认证主体信息、违规情况及",
	"components.xieyi.100i6j": "的处理措施。",
	"components.xieyi.veqih2": "（四）共享、转让、公开披露个人信息时事先征得授权同意的例外",
	"components.xieyi.i5fma7": "根据相关法律法规规定，以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：",
	"components.xieyi.r82363": "1、为履行法定职责或法定义务所必需的；",
	"components.xieyi.ett47l": "2、与国家安全、国防安全、重大公共利益直接相关的；",
	"components.xieyi.nql27g": "3、为应对突发公共卫生事件的；",
	"components.xieyi.r2138s": "4、为公共利益实施新闻报道、舆论监督等修改为，在合理的范围内处理个人信息的；",
	"components.xieyi.i32523": "5、紧急情况下，为保护您或者他人的生命健康或者重大财产安全且无法及时或者有效的向您告知的，但在紧急情况消除后会及时向您告知；",
	"components.xieyi.4stqc6": "6、依相关法律法规规定，在合理的范围内处理个人自行公开或者其他已经合法公开的个人信息。",
	"components.xieyi.7k22k8": "请知悉，根据适用的法律，",
	"components.xieyi.7w6sng": "若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。",
	"components.xieyi.t8w9o9": "（一）数据保护技术措施",
	"components.xieyi.5k26r2": "我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。例如，我们会使用加密技术提高个人信息的安全性，在您的浏览器与服务器之间交换数据时受",
	"components.xieyi.824h5u": "SSL协议加密保护，我们同时对",
	"components.xieyi.rs1e76": "平台提供HTTPS协议安全浏览方式；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。",
	"components.xieyi.qj2xrx": "（二）数据保护管理合规制度体系",
	"components.xieyi.y1edur": "我们建立了行业内先进的以数据为核心、围绕数据生命周期进行的数据安全管理合规制度体系，从组织建设、制度设计、人员管理、产品技术等方面多维度提升个人信息的安全性。我们会成立个人信息保护机构对个人信息保护情况进行监督，并举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。",
	"components.xieyi.d7ix35": "（三）个人信息安全事件响应",
	"components.xieyi.2o454t": "如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损的，我们会及时启动应急预案，采取合理救济措施，以尽可能降低对您个人信息和其他权益的影响。如发生个人信息安全事件，我们还将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。",
	"components.xieyi.4706wi": "（四）社会监督",
	"components.xieyi.4ouosr": "我们会按照国家相关法律、行政规定的要求定期发布个人信息保护社会责任报告，接受社会监督。",
	"components.xieyi.6r5a4q": "（五）账户安全风险的自主防范",
	"components.xieyi.veukf5": "互联网并非绝对安全的环境，使用",
	"components.xieyi.ri9a0j": "平台时我们强烈建议您不要使用非",
	"components.xieyi.382lb2": "平台推荐的通信方式发送您的信息，同时请您使用复杂密码，协助我们保护您的账户安全。",
	"components.xieyi.s2h72a": "在使用",
	"components.xieyi.8mtgk8": "平台进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如",
	"components.xieyi.6ue558": "联络方式",
	"components.xieyi.5h8pl3": "联系地址",
	"components.xieyi.74n5cs": "。此外，您也可以通过我们的服务与第三方建立沟通、交流信息或分析内容。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络",
	"components.xieyi.1100gs": "平台官方客服以便我们根据您的申请采取相应措施。",
	"components.xieyi.p898io": "请注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。",
	"components.xieyi.pfhp6l": "（一）存储地点",
	"components.xieyi.id973y": "我们在中华人民共和国境内运营中收集和产生的个人信息，将存储在中国境内。",
	"components.xieyi.76w8ku": "在因业务或本政策涵盖目的范围之内，确需向境外提供您个人信息的，我们将会在事前依照相关法律规定进行个人信息保护影响评估、记录处理情况，并在征得您明确同意的基础上依照相关的法律法规的规定进行处理，同时会及时的向您告知境外信息接收方的名称、联系方式、处理目的、处理方式、个人信息的种类及您向境外接收方行使国家法律规定权利的方式和程序等事项。",
	"components.xieyi.7fciuv": "（二）存储期限",
	"components.xieyi.f1a2af": "我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，",
	"components.xieyi.42nn2k": "例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：",
	"components.xieyi.6hv739": "1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您可能的查询或投诉；",
	"components.xieyi.483k46": "2、保证我们为您提供服务的安全和质量；",
	"components.xieyi.v5a774": "3、您是否同意更长的留存期间；",
	"components.xieyi.7g6607": "根据诉讼时效的相关需要；",
	"components.xieyi.8iszne": "5、是否存在关于保留期限的其他特别约定或法律法规规定。",
	"components.xieyi.2xiglt": "在保留期间到期后，我们会根据相关法律法规的规定及时处理您的个人信息。",
	"components.xieyi.7rg77i": "您除了可以按照本政策约定的操作路径在",
	"components.xieyi.t2esz7": "平台上自行操作以外，您还可以通过以下方式访问及管理您的信息：",
	"components.xieyi.kb51e6": "（一）查询、更正和补充您的信息",
	"components.xieyi.nu7m3c": "您有权查询、更正或补充您的信息。您可以通过登录",
	"components.xieyi.bcd5d5": "平台，点击“我的-设置（右上角）”进入买家中心查询、更正个人资料及个人账户相关信息、账户安全及收货地址等信息。",
	"components.xieyi.043c4c": "（二）删除您的信息",
	"components.xieyi.2d2n9p": "您可以通过“（一）查询、更正和补充您的信息”中列明的方式删除您的部分信息。",
	"components.xieyi.8n2d00": "在以下情形中，您可以通过与",
	"components.xieyi.4zh20s": "平台客服联系向我们提出删除个人信息的请求：",
	"components.xieyi.25t31b": "1、如果我们处理个人信息的行为违反法律法规；",
	"components.xieyi.8tjokj": "2、如果我们收集、使用您的个人信息，却未征得您的明确同意；",
	"components.xieyi.4m1259": "3、如果我们处理个人信息的行为严重违反了与您的约定；",
	"components.xieyi.q81de3": "4、如果您的账户被注销。",
	"components.xieyi.xd43vl": "若我们决定响应您的删除请求，我们还将同时尽可能通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。",
	"components.xieyi.1c21t6": "当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以清除或实现匿名化。",
	"components.xieyi.43p4r8": "（三）注销您的账户",
	"components.xieyi.w2lnx4": "您可以通过以下方式申请注销您的账户：",
	"components.xieyi.d17ah0": "1、登录",
	"components.xieyi.113v7u": "平台，通过“我的-设置（右上角）-账号安全中心-注销账号”提交账户注销申请；",
	"components.xieyi.390iqo": "2、联系",
	"components.xieyi.n6o511": "平台客服，协助您申请注销您的账户",
	"components.xieyi.g4i68q": "在您主动注销账户之后，我们将停止为您提供产品或服务，并根据适用法律的要求删除您的个人信息，或对其进行匿名化处理。",
	"components.xieyi.i8g44k": "（四）改变您授权同意的范围",
	"components.xieyi.7162l2": "您可以通过以下方式改变或者撤回您授权我们收集和处理您的个人信息的范围：您可以在",
	"components.xieyi.83m5qy": "“我的",
	"components.xieyi.ih02m6": "－设置－隐私－系统权限”",
	"components.xieyi.3aq776": "中管理您已授权的应用。",
	"components.xieyi.uyy3k2": "对于您无法直接通过上述方式设置的授权，您可以通过与EPOCH EPT客服联系进行修改，或发送电子邮件至jubao@weipaitang.com。我们将在15个工作日内回复您的撤回请求。但请注意，对于部分类型的个人信息，例如实现EPOCH EPT基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能没有办法响应您改变授权范围的请求。",
	"components.xieyi.38w831": "当您收回授权后，我们将不再处理相应的个人信息。但您收回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。",
	"components.xieyi.o2932d": "如果您不想接受我们给您发送的个性化商业广告，您随时可通过以下方式取消：",
	"components.xieyi.7l39m6": "“我的EPOCH EPT－设置－关于EPOCH EPT－个性化设置”",
	"components.xieyi.uw6w84": "（五）约束信息系统自动决策",
	"components.xieyi.6ixh35": "在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害",
	"components.xieyi.8i0uc6": "商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。",
	"components.xieyi.sace84": "（六）个人信息副本",
	"components.xieyi.iz38g1": "如您需要您的个人信息副本，您可以通过本政策“如何联系我们”所列的反馈渠道联系我们，在核实您的身份后，我们将根据您的要求向您提供您的个人信息副本，但法律法规另有规定或者本政策另有规定的除外。",
	"components.xieyi.q0m5n8": "（七）响应您的上述请求",
	"components.xieyi.fqi536": "对于您或可能的您的监护人、近亲属及其他有权主体向我们提出上述请求，以及中华人民共和国法律与其他适用法律规定的您的相关个人信息权利，您可以通过客服联系我们或直接向我们的个人信息保护专职部门发起投诉。我们将在15天内做出答复。",
	"components.xieyi.a1304e": "为保障您的账户安全，您向我们提出上述请求时，我们可能会先要求验证您的身份（如要求您提供书面请求或其他方式），然后再处理您的请求。",
	"components.xieyi.31h37g": "在以下情形中，按照法律法规要求，我们将无法响应您的请求：",
	"components.xieyi.1udf40": "1、为履行法定职责或法定义务的；",
	"components.xieyi.0ro7r7": "3、有充分证据表明个人信息主体存在主观恶意或滥用权利的；",
	"components.xieyi.70z43w": "4、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；",
	"components.xieyi.335lfg": "5、涉及商业秘密的；",
	"components.xieyi.1eb324": "6、法律、行政法规规定的其他情形。",
	"components.xieyi.e0vv42": "在电子商务活动中我们推定您具有相应的民事行为能力。如您为未成年人，我们要求您请您的父母或其他监护人仔细阅读本政策，并在征得您的父母或其他监护人同意的前提下使用我们的服务或向我们提供信息。",
	"components.xieyi.jp5623": "如果您是14周岁以下儿童的，在您使用我们的服务前，您和您的监护人应仔细阅读我们专门制定的《儿童个人信息保护规则》，确保在取得监护人对",
	"components.xieyi.4637q9": "《儿童个人信息保护规则》",
	"components.xieyi.b7i17n": "的同意后，您才可在监护人的指导下使用我们的服务，确保您在使用我们的服务和进行交易时的安全。",
	"components.xieyi.63z56b": "若您是儿童的父母或其他监护人，请您关注儿童是否是在取得您的授权同意之后使用我们的服务。如您对儿童的个人信息有疑问，请与我们的个人信息保护专职部门联系。",
	"components.xieyi.064797": "我们的服务以及技术不断跟随您以及其他的用户的需求更新和优化，因此在新的服务及业务流程变更时，我们可能会更新我们的隐私权政策以告知您具体的变化。但未经您明确同意，我们不会限制您按照本政策所应享有的权利。我们会在",
	"components.xieyi.7xys1n": "专门页面",
	"components.xieyi.5ln786": "上发布对本政策所做的任何变更。",
	"components.xieyi.1i9q62": "对于重大变更，我们还会提供更为显著的通知（包括我们会通过",
	"components.xieyi.dg46h5": "平台公示的方式进行通知或向您提供弹窗提示）。",
	"components.xieyi.o3si3a": "本政策所指的重大变更包括但不限于：",
	"components.xieyi.sntd36": "1、我们的服务模式发生重大变化，如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；",
	"components.xieyi.x97b27": "2、我们在控制权等方面发生重大变化，如并购重组等引起的信息控制者变更等；",
	"components.xieyi.hc7g60": "3、个人信息共享、转让或公开披露的主要对象发生变化；",
	"components.xieyi.4icom0": "4、您拥有的个人信息处理方面的权利及其行使方式发生重大变化；",
	"components.xieyi.krv6jh": "5、我们负责处理个人信息保护的责任部门、联络方式及投诉渠道发生变化；",
	"components.xieyi.t6x4vu": "6、个人信息安全影响评估报告表明存在高风险。",
	"components.xieyi.oso4jc": "公司名称：杭州EPOCH EPT文化创意有限公司",
	"components.xieyi.0vvw2h": "注册地址：浙江省杭州市西湖区华星路19号创丰华星商务大厦 7楼",
	"components.xieyi.4j9bb7": "如果您对本政策有任何疑问、意见或建议，您可以通过以下方式与我们联系，我们将在15个工作日内回复您的请求：",
	"components.xieyi.5mz349": "1、EPOCH EPT在线客服",
	"components.xieyi.py8lg2": "2、我们还设立了个人信息保护专职部门，您可以联系我们的个人信息保护负责人邮箱shaktrysrtgscrtbslanderor5046@gmail.com。",
	"components.xieyi.nfphnk": "需注意的是，我们可能不会回复与本政策或与用户个人信息保护无关的问题。",
	"components.xieyi.6vag57": "如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，",
	"components.xieyi.iw8u70": "您还可以通过向EPOCH EPT平台所在地有管辖权的法院提起诉讼来寻求解决方案。",
	"components.xieyi.668kbh": "首次发布时间：2018年12月13日",
	"components.xieyi.1hqds4": "最近更新时间：2023年3月30日",
	"src.error.3if060": ">>>>>> 错误信息 >>>>>>",
	"src.error.f78hx4": ">>>>>> Vue 实例 >>>>>>",
	"libs.axios.3m3ozo": "服务器异常！",
	"libs.axios.u68235": "请求错误!",
	"libs.axios.8pvp71": "你没有权限！",
	"libs.axios.3e23l0": "请求地址出错",
	"libs.axios.ctl7o2": "请求超时,请刷新后重试！",
	"libs.axios.7f90de": "服务器内部错误",
	"libs.axios.6r1ua2": "服务未实现",
	"libs.axios.232in7": "网关错误",
	"libs.axios.ft8yo6": "服务不可用",
	"libs.axios.1o3gds": "网关超时,请刷新后重试！",
	"libs.axios.43w873": "HTTP版本不受支持",
	"goods.index.86p673": "购买",
	"goods.index.40o4np": "購買數量",
	"goods.index.r257np": "立即购买",
	"goods.index.6i5t88": "該產品暫不可購買！",
	"goods.index.263l56": "請選擇產品",
	"goods.index.e5yso2": "請選擇支付方式！",
	"rewards.activityb.t04rn4": "总佣金",
	"rewards.activityb.r5174s": "今日佣金",
	"rewards.activityb.q4544h": "昨日佣金",
	"rewards.activityb.uflar8": "直推人数",
	"rewards.activityb.fsyaor": "佣金",
	"rewards.activityb.xd17r7": "间接人数",
	"trending.index copy 2.891396": "跟单",
	"trending.index copy 2.rc6wik": "投入区间:",
	"trending.index copy 2.y4r77n": "量化周期:",
	"trending.index copy 2.m2v371": "小时",
	"trending.index copy 2.5vyoay": "量化收益:",
	"trending.index copy 2.rpeid0": "跟单者收益$1185.65",
	"trending.index copy 2.g0677s": "带单规模$15,120,184.00",
	"trending.index copy 2.8sgku2": "托管中",
	"trending.index copy 2.g2jni6": "托管金额",
	"trending.index copy 2.39cp6b": "托管期限",
	"trending.index copy 2.050n02": "日收益",
	"trending.index copy 2.380j4f": "托管开始时间",
	"trending.index copy 2.6en3x1": "托管结束时间",
	"trending.index copy 2.v761ws": "跟投周期:",
	"trending.index copy 2.8gow40": "跟投限制:",
	"trending.index copy 2.1ul440": "最少收益:",
	"trending.index copy 2.7fk35c": "最高收益:",
	"trending.index copy 2.3ys73k": "可用餘額",
	"trending.index copy 2.f615yk": "總價",
	"trending.index copy 2.11c26y": "立即跟投",
	"trending.index copy 2.ojxv4u": "全部",
	"trending.index copy 2.2v0ku3": "我的订单",
	"trending.index copy 2.9m6dh6": "下單成功！",
	"trending.index copy 3.7v38ky": "已取消",
	"trending.index copy 3.3ksfcn": "预购订单",
	"trending.index copy 3.u6n586": "待结算",
	"trending.index copy 3.7d8jnh": "标题",
	"trending.index copy 3.22f28b": "您确认要取消订单吗？",
	"trending.index copy 3.plw663": "确认",
	"trending.index copy 3.e4pcfx": "取消成功！",
	"trending.index copy.voov3i": "近30 天收益率",
	"trending.index copy.60fqu2": "近30天收益",
	"trending.index copy.38c2yt": "胜率",
	"trending.touzi1.30c92u": "订单号",
	"trending.touzi1.w3r6h6": "金额",
	"trending.touzi1.2q58dy": "状态",
	"trending.touzi1.830j6d": "时间",
	"wallet.index.ralw0t": "请输入手机号",
	"wallet.index.tme1u9": "电话号码",
	"wallet.index.h1wve2": "请输入电话号码",
	"login.login.qnpe05": "请输入电话",
	"login.login.8za2m1": "登录密码不能为空",
	"components.recharge.r79s4d": "已生效",
	"components.recharge.v93frd": "处理中",
	"wallet.index.bm205v": "最小金额不能低于0",
	"components.recharge.h1w232": "返利中",
	"components.recharge.wx5ntw": "返利成功",
	"components.recharge.42t1o3": "充值成功",
	"components.recharge.d03old": "提现失败",
	"components.recharge.cn2rpv": "提现成功"
}