module.exports = {
	"Language": {
		"title": "Language"
	},
	"Noproductsforsale": "No products for sale",
	"rechargePresentationText0_1": "Recharge 1000 reward  ",
	"rechargePresentationText0_2": ", Recharge 10000 reward ",
	"rechargePresentationText0_3": ", and so on... Click Me !",
	"rechargePresentationText0": "You will receive ",
	"rechargePresentationText1": "% of the recharge amount when you start the recharge now. The activity time is limited, and it will stop after the gift is over.",
	"rechargePresentationText2": "Reward End Time, CountDown !",
	"Online_Chat": "Online Chat",
	"gobindgoogle": "Please bin your Google Authenticator",
	"Order": "Order",
	"Record": "Record",
	"Assist": "Assist",
	"Market": "Market",
	"History": "History",
	"Confirm": "Confirm",
	"close": "Close",
	"Cancel": "Cancel",
	"Previous": "Previous",
	"Next": "Next",
	"Copy": "Copy",
	"Nomoredata": "no data",
	"moneychar": "₹",
	"DownloadApp": " Download App Now",
	"Yes": "Yes",
	"No": "No",
	"Wating": "pending",
	"Recharge": "Deposit",
	"Withdrawal": "Withdraw",
	"Success": "Success",
	"stop_investing": "stop investing",
	"OrderSuccessful": "Order Successful",
	"Handledataerror": "Handle data error",
	"Copysuccess": "Copy success",
	"MyBalanceLoding": "My Balance Loding",
	"UPDATE": "UPDATE",
	"New_version_available": "New version available",
	"Please,update_app_to_new_version": "Please, update app to new version",
	"tabbar": {
		"Home": "Home",
		"AndarBahar": "Andar Bahar",
		"Invest": "Invest",
		"Bonus": "Bonus",
		"Mine": "Wallet",
		"Trending": "Trending",
		"Wallet": "Wallet",
		"Rewards": "Rewards"
	},
	"homeTop": {
		"Sign_Up": "Sign Up",
		"Sign_up_for_rewards": "Sign up for rewards",
		"up_to": "up to",
		"TOTAL_ASSETS": "TOTAL ASSETS",
		"User_ID": "User ID",
		"Registration_time": "Registration time"
	},
	"PieChart": {
		"BIG&SMALL": "BIG&SMALL",
		"NUMBER": "NUMBER",
		"ODD&EVEN": "ODD&EVEN",
		"ALL_NUMBER_TRENDING": "ALL NO. TRENDING",
		"BIG&SMALL_NUMBER_TRENDING": "BIG&SMALL NO. TRENDING",
		"ODD&EVEN_NUMBER_TRENDING": "ODD&EVEN NO. TRENDING"
	},
	"register": {
		"checkerr": "Password: Use 8 or more characters (a combination of letters and numbers)",
		"tips_3": "If you didn't receive the code in your inbox, please check your spam folder.",
		"receiveCode": "Didn't receive the code?",
		"receiveCode_title": "Code sent to your email. If you have not received the code after several attempts, please try:",
		"receiveCode_text1": "1. Check if it is in your junk/spam mail.",
		"receiveCode_text2": "2. Make sure your email address is",
		"receiveCode_text3": "3. The message may be delayed for a few minutes. Try again after 20 minutes.",
		"receiveCode_text4": "4. If this email address already exists, we will not send you an authentication code. Please login or reset your password.",
		"Sign_up_for_Rewards": "Sign up for Rewards",
		"Email_Verification": "Email Verification",
		"tips": "Please enter the 6-digit verification code that was sent to {{formData.email}}.The code is valid for 30 minutes.",
		"tips_1": "Please enter the 6-digit verification code that was sent to ",
		"tips_2": "The code is valid for 30 minutes.",
		"Resend_code_in": "Resend code in",
		"Sent_again": "Sent again",
		"CREATE_ACCOUNT": "CREATE ACCOUNT",
		"inviteCodecheck": "inviteCode  cannot be empty!",
		"Mobile": "Mobile Number",
		"Mobilemsg": "Mobile number is required",
		"Password": "Password",
		"Passwordmsg": "Password is required",
		"OTPCode": "OTP Code",
		"OTPCodemsg": "OTP Code is required",
		"SendOTP": "Send OTP",
		"Email": "Email",
		"Emailmsg": "Email is required",
		"Invitecode": "Referral Code (optional)",
		"Invitecodemsg": "Referral code is required",
		"emailFormatter": "Please enter the correct email address",
		"usernameFormatter": "username must be character and number and range is 6<=length<=20",
		"phoneFormatter": "Please fill in the 10-digit mobile phone number",
		"msgsuccess": "registered successfully",
		"msgerr": "fail to register",
		"errorphonecheck": "Mobile phone number cannot be empty!",
		"phonecheck": "Please fill in the 10-digit mobile phone number!",
		"imgcodecheck": "OTP Code  cannot be empty!"
	},
	"login": {
		"Welcome_back": "Welcome back",
		"tips": "I have read and aggree to {{this.$LogoName}} Term of Service and Privacy Policy",
		"tips_1": "I have read and aggree to",
		"tips_2": "Term of Service",
		"tips_3": "and",
		"tips_4": "Privacy Policy",
		"Continue_with_Google": "Continue with Google",
		"Continue_with_Apple": "Continue with Apple",
		"text": "Don't have an account yet?",
		"text2": "Already have an account?",
		"Login": "Login",
		"Register": "Register",
		"username": "Username or phone number",
		"password": "Password",
		"imgcode": "Image code",
		"BackHome": "Back home",
		"Forgetpwd": "Forget Password"
	},
	"forgot": {
		"title": "Forget Password",
		"msgsuccess": "Password reset complete",
		"newpassword": "New Password",
		"newpasswordmsg": "New password is required",
		"Continue": "Continue"
	},
	"home": {
		"Products&Services": "Products & Services",
		"content11": "Inviters can get bonus proportional to the transaction volume generated by their invitees. The maximum bonus for a valid invitation is Unlimit USDT.",
		"Revolutionize_Trading": "Revolutionize Trading",
		"Shape_the_Future": "Shape the Future",
		"content0": "Let's Experience the New Era of Crypto Currency Exchange Together!",
		"3_Reasons_for_Choosing": "3 Reasons for Choosing",
		"Innovative_Trading_Features": "Innovative Trading Features",
		"content1": "Revolutionary trading experience that is unlike anything currently available in the market. Designed to give you a competitive edge with innovative trading features, advanced security measures, and lightning-fast transaction speeds.",
		"Lower_Fees_and_Better_Rewards": "Lower Fees and Better Rewards",
		"content2": "Our interface makes trading simple for beginners. You can create an account for free, trade crypto with low transaction fees from our marketplace.  Futhermore, you can share up to 20% transaction fees from exchange for all the users you invite. ",
		"Institutional-grade_Security": "Institutional-grade Security",
		"content3": "follows one of the industry's highest security standards. Also we store 100% of your crypto in cold storage. We guarantee at least a 1:1 reserve ratio of our customer funds. Our 300M USDT fund gives an extra layer of protection against security threats. ",
		"Start_investing_in_as_little_as_5_minutes": "Start investing in as little as 5 minutes",
		"content4": " Sign up with {{this.$LogoNames.toUpperCase()}} for free",
		"content4_1": "Sign up with ",
		"content4_2": " for free",
		"Complete_google_authentication": "Complete google authentication",
		"content5": "Enter and verify your email address to get started. There are no fees for opening an account.",
		"content7": "Unlock full functionality by verifiying your google authenticator in less than 5 minutes.",
		"content8": "Fund your {{this.$LogoNames.toUpperCase()}} account instantly",
		"content8_1": "Fund your",
		"content8_2": "account instantly",
		"content9": "Deposit USDT via select network and have instant access to your funds. No need to wait 3-5 days to start trading.",
		"Start_building_your_crypto_portfolio": "Start building your crypto portfolio",
		"Invitation_Program": "Invitation Program",
		"Referral_Program": "Referral Program",
		"content10": "Complete any valid invitation and receive an invitation bonus average value to 10 USDT. The maximum bonus for a single person is ",
		"TRADING_TASKS": "TRADING TASKS",
		"content12": "Users will receive rewards in USDT when trading, variety trading tasks for different trading mode. The rewards will be distributed instantly",
		"Trade_anytime_anywhere": "Trade anytime, anywhere",
		"content13": "USA, Inc. is licensed to engage in Virtual Currency Business Activity by the New York State Department of Financial Services.",
		"USA_Inc": "USA, Inc",
		"content6": "Download our app to stay connect 24/7",
		"Legal&Disclosures": "Legal & Disclosures",
		"Regulatory_License": "Regulatory License",
		"AML/KYC_Policies": "AML/KYC Policies",
		"Privacy_Policy": "Privacy Policy",
		"Terms_of_Use": "Terms of Use",
		"Legal_Statement": "Legal Statement",
		"Risk_Disclosure": "Risk Disclosure",
		"About_Us": "About Us",
		"Protection_Fund": "Protection Fund",
		"Proof_of_Reserves": "Proof of Reserves",
		"Twitter": "Twitter",
		"Bitget_Token": "Bitget Token",
		"Careers": "Careers",
		"Company": "Company",
		"Support_Service": "Support",
		"title": "Open with an app",
		"titlebtn": "Download",
		"Balance": "Balance",
		"ID": "ID",
		"Recharge": "Recharge",
		"Withdraw": "Withdraw",
		"Bonus": "Bonus",
		"Checkin": "Check in",
		"Eventclosed": "Event closed",
		"Ranking": "Ranking"
	},
	"Ranking": {
		"Today_s winning ranking": "Today's winning ranking",
		"tips1": "The bigger the winning amount = the higher the ranking!",
		"UserName": "UserName",
		"Winning Amount": "Winning Amount",
		"Today_s rebate ranking": "Today's rebate ranking",
		"tips2": "Higher rebates for lower-level users = higher ranking!",
		"Rebate Amount": "Rebate Amount",
		"Rebate ranking": "Top 20 rebate rankings"
	},
	"Checkin": {
		"Checkin": "Check in",
		"CheckinList": "Check in list"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Rule",
		"Record": "Record",
		"Records": "Andar Bahar  Records",
		"MyOrder": "My Order",
		"more": "more",
		"Period": "Period",
		"Order": "Order",
		"Buy": "Buy",
		"Result": "Result",
		"Profit": "Profit",
		"AndarBaharRule": "Andar Bahar Rule",
		"CountDown": "Count Down",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "result",
		"ok": "ok",
		"start": "start",
		"Point": "Point",
		"ContractPoint": "Contract Point",
		"Number": "Number",
		"Delivery": "Delivery",
		"Fee": "Fee",
		"Amount": "Amount"
	},
	"pokerOrder": {
		"OrderRecord": "Order Record",
		"WatingOrder": "Wating Order",
		"HistoryOrder": "History Order",
		"Nomoredata": "No more data",
		"Buy": "Buy",
		"Result": "Result",
		"Time": "Time",
		"Amount": "Amount",
		"Fee": "Fee",
		"Profit": "Profit"
	},
	"pokerRecord": {
		"title": "Andar Bahar Record",
		"Period": "Period",
		"Result": "Result",
		"OpenTime": "OpenTime",
		"Nomore": "No more"
	},
	"Google2FA": {
		"title": "Google Authenticator",
		"Authenticator_Instructions": "Authenticator Instructions",
		"Authenticator_Status": "Authenticator Status",
		"Google_Authenticator": "Google Authenticator",
		"Download_Google_Authenticator_App": "Download Google Authenticator App",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "Next",
		"Backup_key": "Backup key",
		"Backup_key_text": "Please save this Backup Key in a secure location. This Key will allow you to recover your Authenticator should you lose your phone. Otherwise, resetting your Authenticator will have to consult customer service.",
		"Verifiaction": "Verifiaction",
		"Verifiaction_text": "Enter Google verification code",
		"Google_verification_code": "Google verification code",
		"contentlist1": "1.Record the 16 digit Backup Key from +this.$LogoName+ and keep it in a safe.",
		"contentlist1_1": "1.Record the 16 digit Backup Key from",
		"contentlist1_2": "and keep it in a safe.",
		"contentlist2": "2.Open Google Authenticator.",
		"contentlist3": "3.Add a +this.$LogoName+ account and enter the copied 16-digit key.",
		"contentlist3_1": "3.Add a",
		"contentlist3_2": "account and enter the copied 16-digit key.",
		"contentlist4": "4.You will see a 6-digit verification code on the screen.",
		"contentlist5": "5.Copy the 6-digit code and paste it in +this.$LogoName+ .",
		"contentlist5_1": "5.Copy the 6-digit code and paste it in",
		"contentlist5_2": ".",
		"contentlist6": "6.Then,your Authenticator will be successfully linked."
	},
	"win": {
		"Live_Price": "Live Price",
		"title1": "Investment results are calculated by the last digit of the Binance BTC/USDT Spot Trade corresponding to UTC time. Investors can check the results on Binance at any time to ensure investment's fairness and security.",
		"title": "There are 2 modes in the investment",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Each round takes 1 minute. Lite mode only provides Odd&Even number investment. The result is calculated by the last digit of Binance BTC/USDT Spot trade 1M interval's close result.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Each round takes 5 minute. Pro mode unlocks full investment method of EPOCH EPT. The result is calculated by the last digit of Binance BTC/USDT Spot trade 5M interval's close result.",
		"5M_Mode_text_1": "Each round takes 5 minute. Pro mode unlocks full investment method of ",
		"5M_Mode_text_2": " . The result is calculated by the last digit of Binance BTC/USDT Spot trade 5M interval's close result.",
		"Investment_Guide": "Investment Guide",
		"Investment_Rules": "Investment Rules",
		"title2": "How to prove the result is fair?",
		"Investment_Info": "Investment Info",
		"3M_Mode": "3M Mode",
		"3M_Mode_text": "Each round takes 3 minute. The result is calculated by the last digit of Binance BTC/USDT Spot trade 3M interval's close result.",
		"Investment_return": "Investment return",
		"Number": "Number",
		"Profit_Invested_amount": "Profit: Invested amount",
		"guide1": "Choose a time mode.",
		"guide2": "Adjust your investment amount.",
		"guide3": "Choose numbers you want to invest in.",
		"guide4": "Check your portfolio and click invest.",
		"NEXT": "NEXT",
		"Invest_Amount": "Invest Amount",
		"Total": "Total",
		"INVEST": "INVEST",
		"Portfolio": "Portfolio",
		"Invest": "Invest",
		"BINANCE": "Binance",
		"Bitcoin_Price": "Bitcoin Price",
		"InvestmentRecord": "Investment Record",
		"Best_Assistant": "Investment Assistant",
		"last_100_rounds": "(last 100 rounds)",
		"What_do_these_data_mean": "What do these data mean",
		"Missing": "Missing",
		"Missing_text": "That means the number of rounds since the last round that produced this number.",
		"Frequency": "Frequency",
		"Frequency_text": "Refers to the times of the corresponding number that appears in the latest 100 rounds.",
		"Max_Continued": "Max Continued",
		"Max_Continued_text": "Refers to the maximum consecutive appearance period of the corresponding number in the lastest 100 rounds.",
		"Rule": "Rule",
		"Trend": "Trend",
		"MyBalance": "My Balance",
		"JoinGreen": "Join Green",
		"JoinRed": "Join Red",
		"JoinViolet": "Join Violet",
		"Parity": "Parity",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "Record",
		"more": "more",
		"Period": "Period",
		"Price": "Price",
		"Result": "Result",
		"My": "My",
		"Order": "Order",
		"Buy": "Buy",
		"Profit": "Profit",
		"Green": "Green",
		"Red": "Red",
		"Violet": "Violet",
		"tips": "The transaction fee is {bet_fee*100}% of the order amount",
		"tips1": "The transaction fee is ",
		"tips2": "of the order amount ",
		"OrderAmount": "Order Amount",
		"Quantity": "Quantity",
		"TransactionFee": "Transaction Fee",
		"TotalAmount": "Total Amount",
		"PresalePolicy": "Provacy Policy",
		"Iagree": "I agree",
		"err1": "Please agree to the pre-sale management rules first"
	},
	"historyPeriods": {
		"Orderrecord": "Order record",
		"record": "record"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Wating Order",
		"HistoryOrder": "History Order"
	},
	"Activity": {
		"added_to_account_balance": "added to account balance.",
		"contentText": "When invitees complete first time deposit count as an valid invitation.Inviter will receive an invitation bonus for every valid invitation.Invitation bonus tiers from {{aaaaaa}}USDT for 1 valid invitation to {{aaaaaa}}USDT for 500 valid invitation ",
		"contentText_1": "When invitees complete first time deposit count as an valid invitation.Inviter will receive an invitation bonus for every valid invitation.Invitation bonus tiers from",
		"contentText_2": "for 1 valid invitation to ",
		"contentText_3": " for 500 valid invitation",
		"contentText_3_1": " for ",
		"contentText_3_2": " valid invitation",
		"contentText2": "Inviters will share {{LV.1%}} trading fee from each LV.1 invitees' trading volume.Inviters will share {{LV.2%}}  trading fee from each LV.2 invitees' trading volume.The maximum bonus for an invitees' trading volume bonus is Unlimit USDT. ",
		"contentText2_1": "Inviters will share",
		"contentText2_2": "trading fee from each LV.1 invitees' trading volume.Inviters will share",
		"contentText2_3": "trading fee from each LV.2 invitees' trading volume.The maximum bonus for an invitees' trading volume bonus is Unlimit USDT.",
		"My_Rewards_History": "My Rewards History",
		"Cumulative_Single_Number_Trading_Volumn": "Cumulative  Single Number Trading Volumn",
		"Cumulative_ODD_Number_Trading_Volumn": "Cumulative ODD Number Trading Volumn",
		"Cumulative_EVEN_Number_Trading_Volumn": "Cumulative EVEN Number Trading Volumn",
		"Cumulative_BIG_Number_Trading_Volumn": "Cumulative BIG Number Trading Volumn",
		"Cumulative_SMALL_Number_Trading_Volumn": "Cumulative SMALL Number Trading Volumn",
		"Advanced_Tasks": "Advanced Tasks",
		"Invite_Friends_and_Earn_Up_to": "Invite Friends and Earn Up to",
		"Referral_Code": "Referral Code",
		"Referral_Link": "Referral Link",
		"INVITE_FRIENDS": "INVITE FRIENDS",
		"Complete_tasks_to_earn_up_to": "Complete tasks to earn up to",
		"MY_REWARDS": "MY REWARDS",
		"SHARE": "SHARE",
		"Earn_with_your_friends_together": "Earn with your friends together",
		"Get_your_link": "Get your link",
		"Claim_your_rewards": "Claim your rewards",
		"Earn_together": "Earn together",
		"contentlist0": "Join {{gogobit}} and get your referral link. Users who sign up through this link will receive rewards.",
		"contentlist0_1": "Join ",
		"contentlist0_2": " and get your referral link. Users who sign up through this link will receive rewards.",
		"contentlist1": "You will receive a referral reward average value of {{XX}} USDT after your friends makes their first deposit, and completes the required trading volume. Rewards can be up to {{XX}} USDT.",
		"contentlist1_1": "You will receive a referral reward average value of ",
		"contentlist1_2": " USDT after your friends makes their first deposit, and completes the required trading volume. Rewards can be up to ",
		"contentlist1_3": " USDT.",
		"contentlist2": "After your friend creates an account, he/she can claim a trading bonus of up to 5,050 USDT.",
		"Referral_Rewards": "Referral Rewards",
		"Detail_Rules": "Detail Rules",
		"Invitation_Bonus": "Invitation Bonus",
		"Up_to": "Up to",
		"content0": "Complete any valid invitation and receive an invitation bonus average value to 10 USDT.  The maximum bonus for a hundred people is ",
		"content0_1": "Complete any valid invitation and receive an invitation bonus average value to  ",
		"content0_2": "The maximum bonus for a hundred invitees is ",
		"Trading_Bonus": "Trading Bonus",
		"content1": "Inviters can get bonus proportional to the transaction volume generated by their invitees. The maximum bonus for a valid invitation is",
		"My_Referral_History": "My Referral History",
		"Total_Invitees": "Total Invitees",
		"Valid_Invitees": "Valid Invitees",
		"Cumulative_Invitees": "Invitees",
		"Trading_Volumn": "Trading Volumn",
		"Daily_Tasks": "Daily Tasks",
		"Detail_Rules_title": "Daily Lucky DRAW of 888 USDT",
		"Detail_Rules_title1": "1. The futures trading data of the day will be calculated the next day.",
		"Detail_Rules_title2": "2. Users who make a futures trading volume of 200,000USDT or more on the day will get a chance to draw once for the 888 USDT prize.",
		"CLAIM_NOW": "CLAIM NOW",
		"INVEST_NOW": "INVEST NOW",
		"title": "Bonus wallet",
		"BonusBalance": "Bonus Balance",
		"Withdrawtobalance": "Withdraw to balance",
		"BonusList": "Bonus List",
		"Bonuswithdrawtobalance": "Bonus withdraw to balance",
		"WithdrawalAmount": "Withdrawal Amount",
		"Pleaseentertheamount": "Please enter the amount",
		"err1": "The withdrawal amount cannot be 0",
		"success": "Transfer success"
	},
	"Refer": {
		"title": "My Refer",
		"ReferTotalBonus": "Refer Total Bonus",
		"InviteFriends": "Invite Friends",
		"Level1": "Level 1",
		"Level2": "Level 2",
		"TotalPeople": "Total People",
		"Bonus": "Bonus",
		"Myinvitationlink": "My invitation link",
		"Rebate_description": "Rebate description"
	},
	"Task": {
		"InviteBonusToday": "Invite Bonus Today",
		"bonusrecord": "bonus record",
		"hint1": "1. Data is updated every 10-30 minutes !",
		"hint2": "2. Only those who have registered in the last 3 days and completed the recharge today are counted !",
		"hint2_1": "2. Only those who have registered in the last ",
		"hint2_2": " days and completed the recharge today are counted !",
		"hint3": "3. 20u minimum deposit required today!",
		"hint3_1": "3. ",
		"hint3_2": " minimum deposit required today!",
		"hint4": "Number of people invited in the last three days",
		"hint4_1": "Number of people invited in the last  ",
		"hint4_2": " days",
		"hint5": "Number of people who have completed recharge today",
		"Invite": "Invite",
		"friendstorecharge": "friends to recharge",
		"Earn": "Earn",
		"Completed": "Completed",
		"ExpireTime": "Expire Time"
	},
	"Invited": {
		"title": "Bonus Recored",
		"InviteTotalBonus": "Invite Total Bonus",
		"InviteTime": "Invite Time",
		"Bonus": "Bonus",
		"NoRecored": "No Recored"
	},
	"mine": {
		"ProvacyPolicy": "Provacy Policy",
		"JOIN US": "JOIN US",
		"title": "Personal Center",
		"InviteCode": "Invite Code",
		"AccountBalance": "Account Balance",
		"BonusBalance": "Bonus Balance",
		"Wallet": "Wallet",
		"Bank": "Bank",
		"Invite": "Invite",
		"Refer": "Refer",
		"CustomerService": "Customer Service",
		"ComplaintsSuggestions": "Complaints&Suggestions",
		"ChangePassword": "Change Password",
		"Activity": "Activity",
		"PresalePolicy": "Provacy Policy",
		"DownloadApp": "Download App",
		"AppVersion": "App Version",
		"Logout": "Logout",
		"Logouttips": "Are you sure you want to log out ?"
	},
	"App": {
		"title": "App Download",
		"DownloadforAndroid": "Download for Android",
		"DownloadforiOS": "Download for iOS"
	},
	"Presale": {
		"title": "Privacy Policy"
	},
	"Account": {
		"title2": "Set new password",
		"title2_text": "Set a new password for your google account. You can login your google account using password.",
		"title_text": "You are trying to rest your password. Please have your Google Authenticator ready for reset.",
		"err1": "The two new passwords entered are different",
		"success2": "Password changed successfully",
		"title": "Change Password",
		"OldPassword": "Old Password",
		"OldPasswordmsg": "old password is required",
		"NewPassword": "New Password",
		"NewPasswordmsg": "new password is required",
		"RetypeNewPassword": "Retype New Password",
		"RetypeNewPasswordmsg": "Retype New Password",
		"Continue": "Continue"
	},
	"Service": {
		"title": "Service",
		"CONTACTUS": "CONTACT US",
		"tips": "Customer service is taking a break !",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "link"
	},
	"Bank": {
		"BankName": "Bank Name",
		"ChooseaCurrencyType": "Select Currency Type",
		"SelectWithdrawalMethod": "Select Withdrawal Method",
		"Newbankcard": "New bank card",
		"Editbankcard": "Edit bank card",
		"title": "My Bank",
		"Cannotbeempty": "Cannot be empty",
		"BankHolderName": "Bank Holder Name",
		"BankAccountNumber": "Bank Account Number",
		"IFSCCode": "IFSC Code",
		"Email": "Email",
		"Phone": "Phone",
		"OTPCode": "OTP Code",
		"tips1": "Please use the mobile phone number you registered to receive OTP",
		"tips2": "For the safety of funds, the bank card can only be modified once a day",
		"Add": "Add",
		"error1": "Bank Holder Name cannot be empty!",
		"error2": "Please enter the correct Bank Holder Name!",
		"error3": "Bank Name cannot be empty!",
		"error4": "Please enter the correct Bank Name!",
		"error5": "Bank Account Number cannot be empty!",
		"error6": "Please enter the correct Bank Account Number!",
		"error7": "IFSC Code cannot be empty!",
		"error8": "Please enter the correct IFSC Code!",
		"error9": "Email cannot be empty!",
		"error10": "Please enter the correct email address!",
		"error11": "phone  cannot be empty!",
		"error12": "OTP Code cannot be empty!",
		"Successfully": "Successfully add bank card"
	},
	"wallet": {
		"title": "My wallet",
		"MyBalance": "My Balance",
		"MyBank": "My Bank",
		"RechargeRecords": "Recharge Records",
		"WithdrawalRecords": "Withdrawal Records"
	},
	"Rechargepage": {
		"Tips": "Tips: The first recharge reward is {{firstRPR*100}}% of the recharge amount ",
		"Tips_1": "Tips: The first recharge reward is ",
		"Tips_2": "% of the recharge amount ",
		"Reminder": "Reminder",
		"Reminder_1": "1. Do not deposit any other currency except USDT to  the address. Sending unsupported coins or tokens   to this address may result irretrievable loss.",
		"Reminder_2": "2. The minimum deposit amount is 5USDT.",
		"Reminder_2_1": "2. The minimum deposit amount is ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Deposits will arrive after 15 network confirmation.",
		"Minimum": "Minimum",
		"title": "Recharge",
		"History": "Deposit History",
		"Mybalance": "My balance",
		"RechargeAmount": "Deposit Amount",
		"RechargeAmountmsg": "Please enter the amount",
		"tips1": "The payment channel is under maintenance !",
		"tips2": "Please select payment channel",
		"PayNow": "Pay Now",
		"err1": "The payment channel is under maintenance",
		"err2": "The amount cannot be less than ",
		"err3": "Please select payment channel",
		"Rechargemethod": "Deposit Method"
	},
	"Rechargerecord": {
		"title": "Recharge record",
		"WatingOrder": "Wating Order",
		"HistoryOrder": "History Order",
		"Rupee": "Dollar",
		"Status": "Status",
		"Time": "Time"
	},
	"withdrawal": {
		"Available_Withdraw_Balance": "Available Withdraw Balance",
		"Google_Code": "Google Code",
		"Tips": "Tips",
		"Tips_1": "Tips: Withdraw fee for under ",
		"Tips_2": " USDT is ",
		"Tips_3": " USDT, withdraw fee for ",
		"Tips_4": "USDT and more are",
		"Tips_5": "%",
		"WalletAddress": "Wallet Address",
		"Addwalletaddress": "Add wallet address",
		"Network_fee": "Network fee",
		"Receive_amount": "Receive amount",
		"Select Chains": "Select Chains",
		"Bankcard": "Bank card",
		"Nobankcardselected": "No bank card added",
		"Withdrawalfee": "Withdrawal fee",
		"title": "withdraw",
		"Mybalance": "My balance",
		"Withdrawablebalance": "Withdrawable balance",
		"tips1": "The buy total amount must be more than {{codeAmount}} can be withdrawn in full",
		"tips1-1": "The buy total amount must be more than",
		"tips1-2": "can be withdrawn in full",
		"tips2": "Withdrawals per day is {{draw_num}} times",
		"tips2-1": "Withdrawals per day is",
		"tips2-2": "times",
		"tips3": "The maximum daily withdrawal amount is",
		"tips4": "The minimum single withdrawal amount is ₹{{draw_min_money}} and the maximum single withdrawal amount is ₹{{draw_max_money}}",
		"tips4-1": "The minimum single withdrawal amount is",
		"tips4-2": "and the maximum single withdrawal amount is",
		"tips5": "The withdrawal time is {{draw_time}}, please withdraw at the specified time",
		"tips5-1": "The withdrawal time is",
		"tips5-2": "please withdraw at the specified time",
		"tips6": "Cash withdrawal will deduct {{draw_fee}}% of the cash withdrawal fee",
		"tips6-1": "Cash withdrawal will deduct",
		"tips6-2": "of the cash withdrawal fee",
		"tips7": "If your withdrawal amount is less than or equal to ₹{{solid_min_amount}}, a fixed fee of ₹{{solid_feemoney}} will be charged",
		"tips7-1": "If your withdrawal amount is less than or equal to",
		"tips7-2": "a fixed fee of",
		"tips7-3": "will be charged",
		"WithdrawalAmount": "Withdraw Amount",
		"WithdrawalAmountmsg": "Please enter the amount",
		"Withdrawal": "Withdraw",
		"WithdrawalHistory": "Withdraw History",
		"Tipsmsg": "Withdrawal application is successful, waiting for the audit result !",
		"dangerMsg1": "Please add your bank card first",
		"dangerMsg2": "Withdrawal amount cannot be less than",
		"dangerMsg3": "Withdrawal amount must be a multiple of 100",
		"dangerMsg4": "Withdrawal amount cannot be greater than",
		"dangerMsg5": "Withdrawal amount cannot be greater than Withdrawable balance",
		"dangerMsg6": "Please add your bank card first"
	},
	"withdrawalrecord": {
		"title": "withdrawal record",
		"WatingOrder": "Wating Order",
		"HistoryOrder": "History Order",
		"Rupee": "Dollar",
		"Status": "Status",
		"Time": "Time",
		"Nomore": "No more!"
	},
	"Version": {
		"title": "Version",
		"stableversion": "stable version",
		"Alreadythelatestversion": "Already the latest version",
		"Newversionfound": "New version found",
		"DownloadUpdate": "Download Update"
	},
	"Bonus": {
		"title": "Bonus Activity",
		"ReceiveBonus": "Receive Bonus",
		"Hi": "Hi",
		"tips1": "Congratulations on getting the bonus",
		"tips2": "You need to recharge greater than or equal to",
		"tips3": "and then the bonus will be automatically added to your account balance",
		"Received": "Received",
		"Back": "Back",
		"Rechargenow": "Recharge now"
	},
	"ComplaintsSuggestions": {
		"title": "Complaints&Suggestions",
		"Answered": "Answered",
		"AddNew": "AddNew",
		"Time": "Time",
		"Type": "Type",
		"WhatsApp": "WhatsApp",
		"Description": "Description",
		"Remark": "Remark",
		"Waiting": "Waiting",
		"Suggestion": "Suggestion",
		"Consult": "Consult",
		"RechargeProblem": "Recharge Problem",
		"WithdrawProblem": "Withdraw Problem",
		"OrderProblem": "Order Problem",
		"Other": "Other",
		"Describeyourproblems": "Describe your problems",
		"WhatsApptocontactyou": "WhatsApp to contact you",
		"submit": "submit",
		"err1": "WhatsApp cannot be empty!",
		"err2": "Type cannot be empty!",
		"err3": "Description cannot be empty!"
	},
	"slotMachine": {
		"title": "Fruit machine",
		"Myblance": "My blance",
		"Records": "Fruit machine records",
		"titleRecord": "Records"
	},
	"bankrecharge": {
		"Orderamount": "Order amount",
		"Ordernumber": "Order number",
		"BankName": "BankName",
		"BankAccount": "BankAccount",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Your bank card number",
		"Enterthebanktransactionordernumber": "Enter the bank transaction order number",
		"submit": "submit",
		"Tips": "Tips",
		"tips1": "1.The system is processing your order .",
		"tips2": "2.please contact customer service if the account has not arrived for a long time. ",
		"err1": "Please enter your bank card number",
		"err2": "Please enter the bank order number"
	},
	"javaCode": {
		"200": "success",
		"20001": "invalid access token",
		"20002": "invalid login token",
		"20003": "Phone error",
		"20004": "Phone has exists!",
		"20005": "Email error!",
		"20006": "Email exists!",
		"20007": "Password error!",
		"20010": "account or pwd error!",
		"20011": "bank account error!",
		"20012": "err bank ifsc, (standard IFSC format) - length 11, first four IFSC and 5th 0!",
		"20013": "beneficiary name error, 5 < name <= 50!",
		"20021": "Account not exists!",
		"20022": "Account is freeze!",
		"20031": "The layer is limit 10 !",
		"20032": "update relation role error !",
		"20033": "not enough balance!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Register Error!",
		"200082": "account has disable!",
		"200091": "add card is limit 10!",
		"200092": "input error login pwd too many times, and the account is disable!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Server error!",
		"Handledataerror": "Please Login!",
		"-91": "Server error!",
		"-92": "Handle data error!",
		"-1": "System error!",
		"-2": "params error",
		"-3": "no data",
		"-4": "frequent operation in a short time",
		"-5": "System busy, try again later!",
		"-6": " There are many people at present, please try again!",
		"-7": "data error!",
		"-8": "Google code error!",
		"-9": "System is being maintained! please try again later !",
		"-10": "save error!",
		"-11": "delete error!",
		"-12": "update error!",
		"-13": "Ilegal operation!",
		"-14": "Operation fail!",
		"-15": "Operation disabled!",
		"-16": "Operation Finished!",
		"-17": "Exists error",
		"-18": "Not exists!",
		"-19": "expires",
		"-20": "Has disabled",
		"-21": "err verify code",
		"-22": "The modification interval must be greater than 1 day",
		"-23": "The new password cannot be the same as the original password",
		"-100": "custom error!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Total Purchase Amount",
		"TotalRevenue": "Total Revenue",
		"AccountBalance": "Account Balance",
		"Income": "Income",
		"Transaction": "Transaction",
		"Myhold": "My hold",
		"ProductList": "Product List",
		"Investmentperiod": "Investment period",
		"days": "days",
		"Buynow": "Buy now",
		"Saletime": "Sale time",
		"Nomore": "No more",
		"ProductDetails": "Product Details",
		"ForSale": "For Sale",
		"Sale": "Sale",
		"SoldOut": "Sold Out",
		"ProductInformation": "Product Information",
		"InvestmentPeriod": "Investment Period",
		"TotalShare": "Total Share",
		"ExpectedReturn": "Expected Return",
		"SaleTime": "SaleTime",
		"PurchaseConditionsAndRules": "Purchase Conditions And Rules",
		"MinimumPurchaseAmount": "Minimum Purchase Amount",
		"MaximumSalesLimit": "Maximum Sales Limit",
		"AccountBalanceMustBeGreaterThan": "Account Balance Must Be Greater Than",
		"DayBetIsGreaterThan": "Day Bet Is Greater Than",
		"ProductDescription": "Product Description",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"Maturity": "Maturity",
		"EarlyRedemption": "Early Redemption",
		"Redemptiontime": "Redemption time",
		"Amountofincome": "Amount of income",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "Help",
		"HelpCenter": "Help Center",
		"text": "any questions,look here"
	},
	"SetingHa": {
		"Seting": "Setting",
		"SetingCenter": "Setting Center",
		"text": "Get More Rewards"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"index.index.gayb21": "Register to receive rewards:",
	"index.index.72y628": "Three Reasons to Choose EPOCH EPT",
	"index.index.z6022y": "EPOCH EPT follows one of the highest safety standards in the industry. We store 100% of our cryptocurrency in a refrigerated warehouse. We guarantee to provide at least a 1:1 reserve ratio for customer funds. Our $300 million fund provides additional protection against security threats.",
	"index.index.574982": "1. Register for EPOCH EPT for free now",
	"index.index.qdi1g4": "2. Complete Google Verification",
	"index.index.weko28": "3. Instantly recharge your EPOCH EPT account",
	"index.index.sgkb35": "EPOCH EPT Invitation Program",
	"index.index.2u17nf": "Completing any valid invitation can earn an average value of up to 5-15 USD The maximum bonus for one hundred invitees is",
	"index.index.e8is4a": "EPOCH EPT Recommendation Program",
	"index.index.47uevl": "EPOCH EPT transaction task",
	"index.index.2516gq": "EPOCH EPT, Inc. has obtained a virtual currency business activity license from the New York State Financial Services Department.",
	"invest.index.3aqi3d": "Account balance:",
	"invest.index.ubk1j4": "Stop investing!",
	"invest.prize1.4733u6": "Investment portfolio:",
	"invest.prize1.t55a16": "Total amount:",
	"login.login.09m52q": "I have read and agree with EPOCH EPT",
	"login.login.045csy": "User name",
	"login.login.r7rbm5": "Please agree to the agreement!",
	"rewards.activityb.4nvrq9": "When the invitee completes their first deposit, it is considered a valid invitation. The inviter will receive an invitation bonus for each valid invitation. The invitation bonus level comes from 2 USD 1 valid invitation, 559 USD 100 valid invitations.",
	"rewards.activityb.t10k6e": "The inviter will share 20% of the transaction commission for each LV.1 invitee's transaction fee. The inviter will share 10% of the transaction commission for each LV. 2 invitee's transaction fee. The maximum bonus for the transaction fee reward of the invitee is unlimited US dollars.",
	"rewards.activityb.3d6850": "LV.1 Effective number of invitees",
	"rewards.activityb.lvxo74": "LV.2 Effective number of invitees",
	"rewards.activityb.zj26f9": "LV.1 Accumulated number of invitees",
	"rewards.activityb.dkgxr5": "LV.2 Accumulated number of invitees",
	"rewards.activityb.xjo077": "Recommendation reward:",
	"rewards.activityb.k8wv6v": "Join EPOCH EPT and get your recommended link. Users registered through this link will receive rewards.",
	"rewards.activityb.4slpzg": "After your friend completes their first deposit and completes the required transaction volume, you will receive a referral reward with an average value of $5-15. The maximum reward can reach $559.",
	"rewards.index.993jwg": "Successfully copied",
	"rewards.index.7141ft": "This browser does not support automatic copying",
	"wallet.index.9uz2gn": "Network handling fee 0 USDT",
	"wallet.index.ych3e1": "Actual Receipt:",
	"wallet.index.3v558d": "Reminder: If the withdrawal is less than $20, the withdrawal handling fee is $1. If the withdrawal is more than $20, the withdrawal handling fee is 3%",
	"wallet.index.255e30": "1. The maximum daily withdrawal amount is $8000000",
	"wallet.index.3g68m8": "2. The minimum single withdrawal amount is $5, and the maximum single withdrawal amount is $8000000",
	"wallet.index.c6x3ti": "3. The withdrawal time is from 00:00 to 23:59, please make the withdrawal within the specified time",
	"wallet.index.h94rlg": "Minimum limit 5",
	"wallet.index.167862": "Please enter the wallet address!",
	"wallet.index.pi869h": "Please enter the withdrawal amount!",
	"wallet.index.ysa85i": "The maximum single withdrawal amount is",
	"components.changepass.68j9kh": "You are attempting to reset your password.",
	"components.changepass.ouxtp2": "Original password",
	"components.languagebox.8no849": "Japanese",
	"components.languagebox.oif20b": "Traditional Chinese",
	"components.mycode.xyqr66": "Completing tasks can earn up to 5000 USDT",
	"components.mymenu.277ss7": "User ID:",
	"components.mymenu.74l443": "Registration time:",
	"components.myposter.36rw5e": "Recommendation code:",
	"components.mytabbar.zw2bh2": "home page",
	"components.mytabbar.8x6c28": "trend",
	"components.mytabbar.28s7qr": "investment",
	"components.mytabbar.4r75d9": "reward",
	"components.xieyi.zqk7pe": "Font size: 16px; Font family: Tahoma;",
	"components.help.6gpud8": "There are two investment models:",
	"components.help.5qrq36": "Each period lasts for 1 minute and only offers single or double investments. The result is calculated by the last digit of the 1-minute K-line closing price of Binance BTC/USDT spot trading.",
	"components.help.isl7r1": "Each session lasts for 5 minutes. The professional version unlocks all investment methods of EPOCH EPT. The result is calculated by the last digit of the 5-minute K-line closing price of Binance BTC/USDT spot trading.",
	"components.help.eai3nm": "Investment return:",
	"components.help.35xg82": "Single Number:",
	"components.help.mpfg0s": "Income: Investment amount X 9.4",
	"components.help.v2ziib": "Odd Number:",
	"components.help.vfzxor": "Income: Investment amount X 1.94",
	"components.help.73168b": "Eve Number:",
	"components.help.76wgv1": "Big number:",
	"components.help.05ed33": "Small Number:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Please enter the original password!",
	"components.changepass.xme6my": "Please enter a new password!",
	"components.changepass.u434f8": "The two passwords do not match!",
	"components.changepass.574790": "Modified successfully!",
	"invest.index.9y8wg0": "Not winning a prize",
	"invest.index.ql5nc1": "Winning awards",
	"login.login.76451t": "Exchange price difference A quantitative trading!",
	"login.login.915p36": "Financial management ranks first globally",
	"login.login.sen863": "More exciting after Hello logs in",
	"login.login.nz6q9x": "Phone login",
	"login.login.w7p592": "Email login",
	"login.login.g9y91k": "Mobile phone number",
	"login.login.456gbb": "Email number",
	"login.login.5ly8m8": "country",
	"login.login.69h472": "Please select a country",
	"login.login.i4p378": "number",
	"login.login.fl82xx": "Example:",
	"wallet.index.urkoub": "Recharge QR code",
	"wallet.index.25cocl": "Copy Address",
	"wallet.index.t35fvg": "1. Withdrawal time: Please calculate regional tax on Sunday within 24 hours from Monday to Saturday!",
	"wallet.index.68xx61": "2. Global local currency withdrawals must be received within 48 hours at the latest, with a withdrawal fee of 8% (including regional tax).",
	"wallet.index.4ws3w9": "3. USDT withdrawal must be received within 24 hours at the latest, with a handling fee of 2% (including regional tax)",
	"wallet.index.9b7wq8": "4. The minimum withdrawal amount is 12USDT, and the maximum single withdrawal amount is 3000USDT.",
	"wallet.index.23y92a": "Please be aware",
	"wallet.index.pl5473": "Minimum limit 5",
	"wallet.index.nb3u72": "Bank card information",
	"wallet.index.842so4": "Add bank card number",
	"wallet.index.86j717": "Add affiliated bank",
	"wallet.index.763d7x": "Add Bank Card Name",
	"wallet.index.50j237": "Affiliated bank",
	"wallet.index.hb6lun": "Bank Card Name",
	"wallet.index.62y2jc": "Bank card number",
	"login.register.666hp4": "Quantitative trading of Al price difference",
	"login.register.9298b8": "Invite friends",
	"login.register.375g14": "Newcomers joining for the first time",
	"login.register.01922f": "Can be performed once",
	"login.register.527o4q": "Daily chemical revenue AI",
	"login.register.c563gt": "Email registration",
	"login.register.2ls856": "Phone number format error!",
	"login.register.4nu77e": "Email number format error!",
	"lang.zh.pu623z": "1 valid invitation",
	"lang.zh.3hv80b": "USDT recharge!",
	"lang.zh.47ttf6": "Kind reminder: The first recharge reward is {{firstRPR * 100}% of the recharge amount",
	"lang.zh.9tli5f": "Tip: Withdrawals below",
	"lang.zh.62e882": "USD, withdrawal fee is",
	"lang.zh.m3818q": "USD, withdrawal",
	"lang.zh.xs2f10": "Early redemption will not generate any returns",
	"invest.index.hl86r1": "Investment Assistant",
	"wallet.index.645d70": "Please enter the name of your bank",
	"wallet.index.n21787": "Please enter the bank card user name",
	"wallet.index.7s5j14": "Please enter your bank card number",
	"components.recharge.l576h7": "Balance changes",
	"components.recharge.3x6844": "Remarks",
	"components.appjump.u85nhy": "MoneyEasy has become my new addiction!",
	"components.appjump.543ht1": "I just made $50 on MoneyEasy, and finally I can freely earn extra cash anytime, anywhere. I can participate in surveys, play games, or test new applications and receive rewards! Register now to receive a bonus of $50!",
	"components.appjump.8k17w7": "Sharing successful",
	"components.appjump.648wav": "Sharing failed:",
	"components.appjump.7c2hl4": "The current browser does not support sharing function",
	"components.fuwu.1t2h7i": "content",
	"components.fuwu.6t32g3": "Market background.",
	"components.fuwu.4c8u7w": "Intelligent quantitative trading system",
	"components.fuwu.mw89jh": "Automatic request for resend",
	"components.fuwu.v5o60h": "Born.",
	"components.fuwu.12x554": "visual field",
	"components.fuwu.d8kj48": "Core values.",
	"components.fuwu.sns568": "Design logic",
	"components.fuwu.131mc5": "Basic principles.",
	"components.fuwu.76i3z7": "Intelligent Quantitative Trading System Technology Model",
	"components.fuwu.253h82": "Blockchain technology.",
	"components.fuwu.64z717": "architecture",
	"components.fuwu.14n9v4": "Team members.",
	"components.fuwu.ewgac0": "History and Plan",
	"components.fuwu.yy18w5": "Future market value",
	"components.fuwu.h4l80q": "Legal considerations",
	"components.fuwu.1nvu89": "Disclaimers",
	"components.fuwu.yvo758": "Market background",
	"components.fuwu.pgt1oc": "from",
	"components.fuwu.4vy2ub": "Year to",
	"components.fuwu.sxj11f": "At the beginning of the year, the performance of value stocks significantly lagged behind that of growth stocks, exacerbating the long-term underperformance of value factors since the global financial crisis. Some people attribute it to macroeconomic interest rate factors&# 8212; Low interest rates, declining bond yields, or flat yield curves.",
	"components.fuwu.ihu32d": "After studying these claims, it was found that the relationship between value and interest rate is theoretically quite vague and complex. From an empirical perspective, there is only a slight correlation between interest rate variables and value factors under certain parameters. Although there are significant patterns in recent data samples that are related to changes in bond yields or yield spreads, the economic significance of these correlations is small and inconsistent in other samples.",
	"components.fuwu.ynfmrx": "The conclusion is that the performance of value factors cannot be explained by interest rate factors, and factor timing strategies based on interest rate signals are difficult to effectively implement. In order to regain the previously significant investment return curve, attention must be paid to the digital economy.",
	"components.fuwu.041265": "It is caused by",
	"components.fuwu.7ybglf": "Together with ten employees",
	"components.fuwu.cv8q4k": "A hedge fund company founded in New York in, providing clients with professional problem-solving, academic research, and innovative methods.",
	"components.fuwu.u18kd5": "In the past",
	"components.fuwu.345nr1": "Over the years,",
	"components.fuwu.uux4kt": "We have launched multiple international stock strategies and financial investment products to help investors achieve stable asset appreciation in the financial market. reach",
	"components.fuwu.2k2868": "Year,",
	"components.fuwu.dfo204": "Asset size managed by hedge funds and financial products",
	"components.fuwu.rer5h5": "Exceeded",
	"components.fuwu.y62673": "Billion US dollars, as of",
	"components.fuwu.mdq3mn": "At the end of the year,",
	"components.fuwu.944x0p": "The scale of funds managed globally exceeds",
	"components.fuwu.64sd2z": "USD100mn",
	"components.fuwu.i2bs5g": "along with",
	"components.fuwu.34l46q": "Computing power, big data, Internet of Things",
	"components.fuwu.t8n5nh": "The exponential development of fields such as cloud computing and artificial intelligence has begun to unleash its power, leading to industry prosperity.",
	"components.fuwu.5765y1": "Swept the internet in just a few months. Its applications in data mining, content insights, intelligent diagnosis, predictive analysis, and intelligent evolution are gradually becoming well-known to people. However, many people are unaware that artificial intelligence applications, such as facial recognition and voice interaction technology, have been integrated into people's daily lives.",
	"components.fuwu.138tt4": "According to statistics, from",
	"components.fuwu.jk42p7": "In the year, the number of global artificial intelligence startups increased",
	"components.fuwu.lu0nu3": "Wanjia, the total financing amount has reached",
	"components.fuwu.edrz45": "Trillion dollars. Many technology companies have established artificial intelligence departments, indicating that the era of artificial intelligence has arrived. In the past",
	"components.fuwu.0ty944": "In the year,",
	"components.fuwu.267zhq": "Always at the forefront of cross investment innovation in technology, data, and behavioral finance. It continuously explores and drives the market and applies these insights to customer investment portfolios, helping global clients achieve stable wealth growth.",
	"components.fuwu.768x8x": "With cloud computing, edge computing, big data",
	"components.fuwu.8hhz36": "With the rapid development of artificial intelligence,",
	"components.fuwu.o32734": "Founder of",
	"components.fuwu.8hhu5s": "Realizing that the product needs to be consistent with the times",
	"components.fuwu.jwkv4j": "Otherwise, they will be eliminated by the market. In addition to maintaining existing stock and financial businesses,",
	"components.fuwu.4m7d39": "to",
	"components.fuwu.n48x5n": "The deployment of artificial intelligence products began in the year. By utilizing artificial intelligence technology to improve efficiency, predict application trends, integrate various data, and utilize wave frequency trading models,",
	"components.fuwu.4ukw28": "Being able to maintain an average comprehensive product return rate within",
	"components.fuwu.ed1635": "Left and right.",
	"components.fuwu.o0516q": "Deeply delving into the forefront of artificial intelligence, striving to integrate features such as data mining, intelligent analysis, and intelligent evolution into financial products, enabling",
	"components.fuwu.7ognk6": "Being able to advance the financial market more accurately, easily, and steadily. After the unremitting efforts of the team,",
	"components.fuwu.9ye2y0": "The intelligent quantitative trading system has been completed and successfully passed two years of testing. We believe it is time to push it to the market.",
	"components.fuwu.hrwn57": "The intelligent quantitative trading system is a digital financial product deployed on the blockchain, following its stock market financial products. Relying on the digital economy and artificial intelligence, manage users' digital assets in a more efficient, accurate, stable, flexible, low-cost, and traceable manner. This is a novel combination of economics, behavioral finance, data, and technology, enabling more effective appreciation of customer assets.",
	"components.fuwu.jb545g": "Year by",
	"components.fuwu.31h712": "An employee was established in New York. It is a hedge fund that utilizes professional academic research and innovative methods to solve customer problems. In the past",
	"components.fuwu.623c56": "Multiple international stock strategies and financial investment products have been launched to help investors achieve stable growth in the financial market. reach",
	"components.fuwu.o8270p": "Managed hedge fund and financial product assets",
	"components.fuwu.j53w1u": "USD100mn As of",
	"components.fuwu.4fogox": "Managing over",
	"components.fuwu.h0al2w": "A billion dollar fund, thanks to its huge global impact",
	"components.fuwu.202g6o": "Sound power.",
	"components.fuwu.85g7o1": "The first international office was opened in Australia.",
	"components.fuwu.2ubf7n": "An office has been established in the UK.",
	"components.fuwu.ud1j47": "The Asset Management Research Institute has been established at the London Business School to promote asset management research.",
	"components.fuwu.z45lgv": "We have opened an office in Hong Kong and expanded our business in Asia.",
	"components.fuwu.4582f8": "An engineering center has been established in Bangalore, India.",
	"components.fuwu.m00yuh": "A new office has been established in Germany, further expanding its global influence. stay",
	"components.fuwu.3r7069": "Year.",
	"components.fuwu.jwwo48": "It is recognized.",
	"components.fuwu.jnvf5m": "Year to",
	"components.fuwu.l0bqqt": "Best Pension and Investment Fund Management Company of the Year.",
	"components.fuwu.e777k5": "have",
	"components.fuwu.j0u235": "A team of partners and nearly a thousand employees, possessing",
	"components.fuwu.8d8hnh": "A research team with years of research experience, and has published a large number of papers in top academic journals. The company will combine solid practical experience with quantitative tools to handle basic information and manage risks. Adopting a systematic research driven approach to managing alternative and traditional strategies is a rare approach; academic research",
	"components.fuwu.y17m3k": "Quantitative investment; Dual Drive Company.",
	"components.fuwu.59t85m": "birth",
	"components.fuwu.xd3f51": "since",
	"components.fuwu.p1o1m9": "Since the beginning of this year,",
	"components.fuwu.s6631u": "I have been thinking about the application of artificial intelligence in the financial field to solve the pain points of manual transactions.",
	"components.fuwu.654rrg": "Formally initiated the establishment of the Artificial Intelligence Quantitative Trading System Research Center and drafted the first draft",
	"components.fuwu.3w3i3q": "White Paper on Intelligent Quantitative Trading Systems. Research and solve problems related to artificial intelligence and trading models, establish an artificial intelligence computing platform, and construct an artificial intelligence quantitative trading system.",
	"components.fuwu.315354": "Successfully applied artificial intelligence to trading and developed",
	"components.fuwu.oef1gv": "Intelligent quantitative trading system. Starting from data collection, the system utilizes artificial intelligence to capture market data, including historical prices, trading volume, market sentiment, etc. Then analyze and explore the market, set multiple trading strategies, establish different trading models, and generate corresponding trading signals based on trading strategies and preset risk control parameters. The system operates through automatic trading.",
	"components.fuwu.kinmrh": "The intelligent quantitative trading system is an intelligent trading platform jointly developed and validated by Hong Kong funds and top blockchain technology teams in Germany. It utilizes advanced computer technologies such as artificial intelligence, blockchain, and big data to achieve decentralized management of financial transactions.",
	"components.fuwu.1khwn2": "The core technology algorithm of intelligent quantitative trading system utilizes blockchain technology to achieve global decentralized financial transactions without geographical restrictions.",
	"components.fuwu.e5g3fa": "The intelligent quantitative trading system will also serve as the foundation for the future digital economy ecosystem",
	"components.fuwu.7ftmw3": "&#8220; Assistant;, Provide quantitative trading solutions for global financial transactions and achieve rapid deployment of efficient blockchain applications. It simplifies the transaction and exchange of assets and digital assets. It also provides for major financial applications",
	"components.fuwu.4646q8": "Key interfaces, connecting various application scenarios, achieving seamless integration of digital assets with various industry applications. This provides investors with a safe, fast, smooth, and stable investment environment. stay",
	"components.fuwu.kh359j": "Under the intelligent quantitative trading system, the power of artificial intelligence will be utilized to build a financial application service ecosystem with super intelligence and super fast capabilities. This will enable more people to enjoy the super experience brought by the digital age, with clear directions for speed, security, stability, and decentralization. It aims to create a new ecosystem that applies artificial intelligence to asset digitization, providing freedom and innovation.",
	"components.fuwu.wsp5m5": "The vision of an intelligent quantitative trading system is based on investment banking thinking and venture capital models. With artificial intelligence technology as the core and relying on big data, we provide investors with cost-effective, safe and reliable investment products to achieve long-term stable returns.",
	"components.fuwu.861380": "Value factors bring attractive long-term returns, but they can also be affected by challenging periods, which can be painful for investors. However, for those who persevere, the subsequent economic recovery is profitable.",
	"components.fuwu.igh8q8": "Intelligent quantitative trading systems can help investors overcome emotional distress during difficult times and achieve long-term value returns.",
	"components.fuwu.u7cy1f": "Review from",
	"components.fuwu.502xa6": "The changes that have occurred since the beginning of the year have not resulted in substantial changes in the expected return rates of many asset classes.",
	"components.fuwu.yg0781": "Intelligent quantitative trading systems can effectively solve these problems.",
	"components.fuwu.k7s41z": "Big data shows that the net loss caused by implementing a systematic strategy of direct index collection gradually decreases in the first few years after implementation. These strategies also exhibit highly diversified net loss outcomes. Therefore, by",
	"components.fuwu.pw7jg1": "The factor investment long short strategy driven by intelligent quantitative trading systems can significantly outperform the direct index strategy.",
	"components.fuwu.9gya07": "The expected premium for investing in emerging markets and developed stock markets is in the past",
	"components.fuwu.yfiuur": "The upper end of the interval observed annually. At the same time, many risks associated with emerging markets in history have been decreasing for a long time. Investors have ample reasons to increase their allocation to emerging markets, including digital assets.",
	"components.fuwu.j8nq18": "Core values",
	"components.fuwu.40fdvu": "In the past twenty years,",
	"components.fuwu.w5kk35": "We have been continuously exploring the driving factors of the market and how to apply them to customer investment portfolios in the fields of economics, behavioral finance, data, and technology. With the rise of big data and artificial intelligence,",
	"components.fuwu.pg3e4s": "Trading strategies and models are becoming increasingly complex.",
	"components.fuwu.kcjcom": "The intelligent quantitative trading system has entered the testing phase and is now officially open to the world through the Hong Kong office.",
	"components.fuwu.n21shs": "The intelligent quantitative trading system is based on investment banking thinking and venture capital models, with artificial intelligence technology as the core. It relies on big data to establish quantitative trading models, aiming to provide investors with cost-effective and safe investment products, as well as stable long-term returns.",
	"components.fuwu.0l7u49": "Utilizing blockchain technology,",
	"components.fuwu.lgcse5": "The intelligent quantitative trading system has developed a distributed, low-cost, privacy preserving high-performance computing network and provides comprehensive financial products.",
	"components.fuwu.0t263a": "The high-performance computing nodes provide powerful computing capabilities for transaction models, including artificial intelligence training and inference, data scraping, and blockchain zero knowledge computing.",
	"components.fuwu.f5s17o": "High performance computing nodes can take various forms, including running on large scales",
	"components.fuwu.u2615w": "or",
	"components.fuwu.a5en87": "Fully functional nodes on server clusters",
	"components.fuwu.d4f145": "Permanent node",
	"components.fuwu.29o1r5": ", as well as from small and medium-sized enterprises and individuals",
	"components.fuwu.r54iix": "Idle",
	"components.fuwu.5zx75g": "Server computing node.",
	"components.fuwu.6156mg": "The intelligent quantitative trading system ensures the safe and stable operation of the system, while allowing all investors to benefit from it.",
	"components.fuwu.p54y10": "stay",
	"components.fuwu.o69yz3": "In the process of intelligent quantitative trading, the processing and learning abilities of artificial intelligence are crucial. It makes trading systems more intelligent, accurate, and automated, enabling more effective trading decisions and risk management.",
	"components.fuwu.h1r7s5": "The design principles of an intelligent quantitative trading system are as follows",
	"components.fuwu.x27379": "Scalability principle",
	"components.fuwu.rhqg19": "The various modules of an intelligent quantitative trading system should be loosely coupled, so that new modules can be easily added without changing the interfaces of other modules.",
	"components.fuwu.c7uqex": "Customer product user feedback",
	"components.fuwu.46e7x1": "The access to intelligent quantitative trading systems is volatile. When a large number of users access a single node, it may lead to business crashes. Therefore, the container of the node should be able to be automatically deployed, allowing for rapid horizontal expansion when under pressure from user requests.",
	"components.fuwu.xc8u4k": "Privacy principle",
	"components.fuwu.1n531t": "The investors, computing nodes, data sources, and other components of the intelligent quantitative trading system all enjoy privacy protection measures.",
	"components.fuwu.ed16w3": "Basic Principles",
	"components.fuwu.7h553b": "What is",
	"components.fuwu.k4l2md": "Intelligent quantitative trading",
	"components.fuwu.7nve0q": "Intelligent quantitative trading refers to the use of artificial intelligence technology and algorithms to automatically execute investment decisions in order to maximize trading efficiency. It is a high-frequency trading strategy based on technologies such as big data, machine learning, and deep learning. Through rapid analysis of market data and automated programming. Identify the best trading opportunities and execute trades",
	"components.fuwu.g21385": "The advantage of intelligent quantitative trading lies in its ability to quickly analyze market data, automatically execute trading decisions, and avoid human interference and misjudgment. In addition, it can optimize and expand transactions on a wider market and larger scale. In addition,",
	"components.fuwu.v75es3": "The intelligent trading system will continuously learn through artificial intelligence. Through long-term data training and model optimization, create better trading models to adapt to market changes and help users achieve long-term profitability.",
	"components.fuwu.rcm80u": "The transaction process is",
	"components.fuwu.2x15l9": "In intelligent quantitative trading systems, artificial intelligence technology is applied to achieve autonomous decision-making ability in intelligent transactions. It can automatically execute transactions based on preset trading strategies and parameters, and intelligently analyze and predict based on market conditions and historical data, achieving more intelligent and autonomous trading decisions. Specifically,",
	"components.fuwu.xe1844": "The intelligent quantitative trading system utilizes a large amount of historical data and market information for data mining and machine learning. It constructs trading models and algorithms, monitors and analyzes the market in real-time, optimizes and adjusts trading signals and strategies to achieve more effective trading decisions and risk management. In addition, AI driven quantitative trading systems continuously improve and enhance their trading strategies and algorithms through AI technology and self-learning capabilities, achieving more intelligent and autonomous trading decisions. Therefore,",
	"components.fuwu.8jr1h7": "&#8220; Intelligent; It represents the intelligence level and autonomous decision-making ability of artificial intelligence quantitative trading systems. It can achieve more efficient, accurate, and greater investment returns for investors. And automated trading decisions and risk management bring about",
	"components.fuwu.62r19x": "In intelligent quantitative trading,",
	"components.fuwu.1v4571": "&#8220; Quantification; Represents the quantification and analysis of market data and trading strategies. Quantitative trading is a digital algorithm built upon the collection, analysis, and processing of large amounts of data, transforming market information and trading strategies into automatic decision-making and trading. Specifically,",
	"components.fuwu.35fgx0": "The intelligent quantitative trading system collects a large amount of market data, including historical prices, trading volume, market sentiment, etc., to analyze and explore the market. It constructs various trading strategies based on this data. By utilizing these trading strategies and predefined risk control parameters, the system generates corresponding trading signals and executes automatic trading. In this process, the quantification and analysis of data make the trading system more intelligent, accurate, and automated, thereby achieving more effective trading decisions and risk management. Therefore,",
	"components.fuwu.ce4mr4": "&#8220; Quantification; Represents",
	"components.fuwu.cfq4pv": "The core technology of quantitative trading. It is an important means of digitizing market information and trading strategies, which can achieve faster, more accurate, and automated trading decisions and risk control.",
	"components.fuwu.6b0w31": "advantage",
	"components.fuwu.7q677y": "efficiency",
	"components.fuwu.624ufa": "The intelligent quantitative trading system automatically executes trading strategies, which can quickly and accurately capture trading opportunities while avoiding human errors and emotional interference.",
	"components.fuwu.6k6y74": "accurate",
	"components.fuwu.y23qq8": "The intelligent quantitative trading system utilizes a large amount of historical data and market information for data analysis and model prediction, improving the accuracy and reliability of trading decisions.",
	"components.fuwu.s11cqu": "stability",
	"components.fuwu.3q6d66": "The intelligent quantitative trading system implements strict risk management and trading control to ensure the stability and long-term returns of investment portfolios.",
	"components.fuwu.w386vn": "flexibility",
	"components.fuwu.530y3q": "The intelligent quantitative trading system can quickly adapt to market changes and new trading opportunities, while also allowing for customized trading strategies and parameters based on investor preferences.",
	"components.fuwu.ph3l81": "Traceability",
	"components.fuwu.7ap85x": "The intelligent quantitative trading system records and tracks the details and historical data of each transaction, facilitating investors to conduct subsequent analysis and optimization.",
	"components.fuwu.v2jkcm": "Ecosystem",
	"components.fuwu.ics30f": "Intelligent quantitative trading system",
	"components.fuwu.0nui8m": "As the core, it has achieved decentralized supply of high-performance computing. The focus of this system is to build infrastructure as a service for artificial intelligence",
	"components.fuwu.n868ra": "Layer. All platforms as a service that require high-performance computing",
	"components.fuwu.ns02co": "And software as a service",
	"components.fuwu.f94h07": "All layer services can be accessed on",
	"components.fuwu.6ftu9v": "Free operation on intelligent quantitative trading systems.",
	"components.fuwu.b19msv": "Blockchain technology",
	"components.fuwu.864583": "The core of an intelligent quantitative trading system lies in the underlying public chain technology, which combines financial models, artificial intelligence, and quantitative trading to handle high transaction throughput. Therefore,",
	"components.fuwu.61j6bp": "The main chain of the intelligent quantitative trading system adopts",
	"components.fuwu.q1j1n6": "Consensus mechanism, combined with",
	"components.fuwu.30nl6v": "The practicality of the system has improved its fault tolerance, transaction efficiency, and interaction frequency. This network is composed of",
	"components.fuwu.3kqnle": "Composed of super nodes, responsible for transaction verification and block generation. In addition to super nodes, there are also regular nodes that synchronize data with core nodes, manage wallet private keys and addresses, and initiate on chain transactions. To meet the needs of artificial intelligence modeling and high-frequency quantitative trading,",
	"components.fuwu.i1g6e7": "The intelligent quantitative trading system continuously promotes the development of underlying public chain technology. By optimizing the consensus mechanism and utilizing side chain technology, higher levels of consensus can be achieved",
	"components.fuwu.j3spal": "Handling more transactions",
	"components.fuwu.d6r6r2": "Handle more transactions concurrently and support increased user traffic.",
	"components.fuwu.rfs8eb": "The intelligent quantitative trading system has implemented a cross chain mechanism and introduced a zero knowledge proof privacy protection mechanism to ensure that user information is not leaked. The system registers on the blockchain in a unified way",
	"components.fuwu.5l87r6": "Applications ensure that once assets are confirmed through smart contracts, all data is open, transparent, and tamper proof. Therefore, using",
	"components.fuwu.ci8bt7": "Conducting shared asset trading is based on completely reliable data, eliminating the risk of false assets or false transactions.",
	"components.fuwu.li76g2": "Overall architecture",
	"components.fuwu.2881li": "The network nodes of intelligent quantitative trading systems can be large computing nodes such as large mining pools, as well as",
	"components.fuwu.u15fc6": "Medium sized computing nodes or cloud computing resources provided by platforms such as Alibaba Cloud.",
	"components.fuwu.2w1u75": "The cloud and edge computing capabilities acquired by the intelligent quantitative trading system ensure the stability of the AI operating environment. Once investors",
	"components.fuwu.6cu4ph": "Deposit",
	"components.fuwu.88fccf": "Intelligent quantitative trading system, artificial intelligence will be based on",
	"components.fuwu.4gfpl8": "Search, identify, layer, manage, and save based on the quantity. subsequently",
	"components.fuwu.5vdauc": "adopt",
	"components.fuwu.0ud3oi": "In the network, corresponding",
	"components.fuwu.8sm2by": "And computing nodes have found suitable solutions for this batch",
	"components.fuwu.189o1y": "The transaction mode of blockchain. The required computing power for this model is allocated to the corresponding",
	"components.fuwu.y83nn2": "Realize quantitative trading.",
	"components.fuwu.78ik3b": "3.2.2 Computing Node Architecture",
	"components.fuwu.0wdoj7": "Computing node architecture",
	"components.fuwu.25g0dk": "3.2.3 Big Data+Artificial Intelligence",
	"components.fuwu.r2e1x7": "The intelligent quantitative trading system includes a distributed high-performance computing network, which is essentially an infrastructure that combines big data and artificial intelligence. It has the following characteristics",
	"components.fuwu.n4ct1t": "The underlying framework is built on the Matrix platform and adopts a software architecture based on Topic subscription, event driven processing, and processor. Through high-performance asynchronous architecture, it can achieve excellent performance. The multi chain structure consists of a main chain and a work chain. The main chain contains pattern definitions for all work chains, and the number of work chains is unlimited. Each work chain is composed of sharded blockchain and supports infinite sharding. Block data is defined based on patterns, allowing for flexible block sizes and supporting compressed storage of block data.",
	"components.fuwu.b9yr3c": "The communication and transmission within the intelligent quantitative trading system adopt self coding compression protocol or encrypted transmission protocol. This can reduce network bandwidth and negotiate different message packet encoding protocols within the same link, improving transmission security.",
	"components.fuwu.92179q": "3D network",
	"components.fuwu.y24z29": "Based on peer-to-peer (P2P) networks, it establishes a layered network to accelerate message propagation. SN nodes have geographical diversity and can dynamically adjust network topology to enhance overall network robustness, resist DDoS attacks, and reduce security risks. Network consensus mechanism: adopting DPOS; The (Delegated Proof of Stake) consensus mechanism evaluates network participation based on user rights and contributions. It adopts a hierarchical processing method for storage, consensus, and verification, enhancing the scalability of the entire network.",
	"components.fuwu.i5s5w9": "3.2.4 Network structure",
	"components.fuwu.248t2x": "The mainstream solutions for addressing blockchain scalability can be divided into two types",
	"components.fuwu.75e81d": "Vertical scalability and horizontal scalability. Typical vertical scalability solutions include optimizing consensus algorithms, bulk storage, and enhancing system performance, such as block expansion and EOS; DPOS and VRF+POS+PBFT. Typical horizontal scalability solutions include lightning networks, multi chain architectures, and shard architectures, all aimed at addressing the scalability and scalability issues of blockchain. Blockchain 4.0 aims to build high-performance distributed business systems on large-scale decentralized networks, with a focus on actual business implementation. No single technological improvement can completely solve the overall scalability problem. The overall architecture design of any blockchain needs to consider four key elements: P2P network architecture, underlying storage architecture, computing/consensus architecture, and business process design. Furthermore, in the world of blockchain, there can only be two design attributes for decentralization, scalability, and security, and all scalability designs are based on this principle.",
	"components.fuwu.854i8h": "The intelligent quantitative trading system adopts a multi chain architecture, enhancing the scalability of the entire network. By utilizing the isolation provided by multiple chains, safe isolation can be achieved",
	"components.fuwu.kw1e7d": "DApp data. With the parallel execution of multiple chains, the performance of the system can grow linearly. However, in a multi chain architecture, there are still performance bottlenecks for a single chain. Therefore, the introduction of sharding technology further enhances the performance of individual blockchains and achieves horizontal scalability. Introduce cross chain routers and cross chain relay layers between multiple chains, supporting relay of homogeneous sub chains and heterogeneous side chains. By anchoring the sub chain/side chain to establish the main chain and interconnecting multiple chains, a blockchain architecture with infinite scalability has been created.",
	"components.fuwu.y11qiw": "3.2.5 Fragmented architecture in",
	"components.fuwu.q7u6kb": "The main purpose of using sharding in intelligent quantitative trading systems is to improve the performance of individual blockchains and the scalability of the overall network. Each shard processes different subsets of transactions in parallel, achieving consensus within the shard and parallel execution of transactions.",
	"components.fuwu.k61o0n": "3.2.5.1 The complete sharding architecture consists of three parts: network sharding, computational sharding (transaction/consensus), and storage sharding (state sharding). Firstly,",
	"components.fuwu.4t6qe4": "The intelligent quantitative trading system divides nodes in the network into different virtual shards based on sharding rules",
	"components.fuwu.7yx4qo": "(also known as a cluster). It ensures connectivity within each shard and network connectivity between nodes within the shard and the main network. Transactions within a shard are only propagated within that shard to reduce bandwidth consumption. Secondly, all nodes participating in the consensus need to provide a deposit and register on the smart contract. Based on sharding rules, we can shard consensus nodes. Transactions that occur in different shards are packaged and validated by the corresponding sharded consensus nodes. Due to the fact that different sharded transactions are verified in parallel across shards, consensus performance can be linearly scaled. With the improvement of network performance, it becomes infeasible to store the entire ledger data on each node in the network. Assuming the overall performance of the network is 1 million TPS, the daily storage space of ledger data for each node will exceed 20%; TB. To solve this problem,",
	"components.fuwu.2wb32r": "The intelligent quantitative trading system separates the storage layer from the computing layer and uses a ledger file system to store ledger data",
	"components.fuwu.69229m": "Joint maintenance of intelligent quantitative trading system network.",
	"components.fuwu.l65907": "3.2.5.2 Each node in a programmable virtual shard P2P network has an independent PeerID, which forms a structured or unstructured P2P network with other nodes. Based on different business requirements, network nodes use smart contracts for virtual sharding on top of physical P2P networks. Each node computes its own vShard programmatically; ID, belonging to the same vShard&nbsp; The nodes with IDs logically form an independent network shard. Each network shard physically originates from a unified P2P network. However, it is logically isolated and provides the logical independence required for network scalability design. Each independent P2P network node can logically belong to multiple virtual shards. Network connections need to be established between sharding nodes to form a regional autonomous sharding network. Sharding is dynamic and elastic, allowing the network to flexibly increase the number of shards. The main network can still establish unstructured and structured P2P networks.",
	"components.fuwu.255q2n": "3.2.5.3 Calculate shard consensus nodes: divided into global consensus nodes and internal consensus nodes. Global consensus nodes are responsible for reaching consensus in the main network, while consensus nodes within shards are responsible for processing consensus within each shard. Consensus can adopt plugin mechanisms, allowing different shards to support different consensus mechanisms. Distributed Random Generation Protocol: Random protocols need to have fairness, unpredictability, third-party verifiability, and scalability. The main shard consensus node uses the VRF algorithm to allocate shards and is randomly responsible for consensus across different shards. The consensus nodes of different shards handle transactions within their respective shards. Witness node selection: Randomly select nodes from the shard consensus node pool to participate in the actual consensus process. Consensus nodes prevent malicious attacks through regular elections. The number of consensus nodes for each shard can be flexibly increased. Consensus nodes prevent malicious attacks through regular elections. The number of consensus nodes for each shard can be flexibly increased.",
	"components.fuwu.vr9w4q": "3.2.5.4 Transaction sharding",
	"components.fuwu.524h25": "The intelligent quantitative trading system divides transactions into intra shard transactions and cross shard transactions.",
	"components.fuwu.25ebl0": "The shard transaction address is composed of the transaction address and vShard; ID composition. If the sender address and receiver address of a transaction belong to the same shard, then the transaction is an intra shard transaction. If the sender and receiver addresses belong to different shards, it is a cross shard transaction. Insurance; Will&nbsp; Be&nbsp; Each; To&nbsp; E&nbsp; Workers; The&nbsp; TSA shard intra transaction: The transaction only propagates within the vShard shard and is packaged and validated by shard consensus nodes. Cross shard transaction: packaged and verified by the main shard consensus node",
	"components.fuwu.737wf6": "3.2.6 Web crawlers",
	"components.fuwu.6ub735": "The operation of intelligent quantitative trading systems relies on the ability to collect, analyze, and model data.",
	"components.fuwu.3917n5": "The intelligent quantitative trading system utilizes artificial intelligence to regularly and quantitatively collect data from the entire network through cloud based methods. It passes through",
	"components.fuwu.540486": "Seamless integration with artificial intelligence quantitative systems. Then, the system performs filtering, analysis, and modeling to provide visual information for backend staff and data support for artificial intelligence.",
	"components.fuwu.i576fn": "Team members",
	"components.fuwu.8a523u": "Cliff&nbsp; Asness is a renowned economist and 2013 Nobel laureate in economics, Eugene; Under the guidance of Professor Fama, he obtained dual bachelor's degrees from the Wharton School of Business and the School of Engineering and Applied Sciences at the University of Pennsylvania.",
	"components.fuwu.clunb8": "Capital management companies are one of the most famous quantitative hedge funds on Wall Street. He once served as",
	"components.fuwu.w47xsg": "Goldman,&nbsp; Sachs&nbsp&& Nbsp; The Managing Director and Director of Quantitative Research of the Asset Management Department of Co. He is the; Journal; Of; Portfolio&nbsp; Member of the Editorial Committee of Management, Member of the Management Committee of the School of Mathematics and Finance at New York University, Member of the Board of Directors of Q-Group, Member of the Board of Directors of the International Rescue Committee, and the; Board members of Ntn",
	"components.fuwu.8v041z": "David",
	"components.fuwu.1yi18o": "&#183; Founder of Kabila",
	"components.fuwu.f126cm": "Years of financial investment experience,",
	"components.fuwu.551s9t": "Certified Master of Business Administration, Bachelor's degree, Northwestern University, Vice President of Goldman Sachs, Sachs Company",
	"components.fuwu.638xk0": "Liu John EPOCH EPT",
	"components.fuwu.2d27j5": "main",
	"components.fuwu.891s7f": "With years of experience in financial investment, he holds a PhD in Business Administration and a Bachelor of Arts degree from the University of Chicago. He obtained a Bachelor of Economics degree from the University of Chicago, where he was selected as a",
	"components.fuwu.khhc66": "And continue to obtain membership in Chicago",
	"components.fuwu.qad15u": "And a doctoral degree in finance.",
	"components.fuwu.ummboy": "Paul",
	"components.fuwu.f1uc41": "&#183; Jacobs",
	"components.fuwu.7du1a3": "Years of technical development experience. This EPOCH EPT technical leader has previously worked as a software engineer at Google and owns the Indian Institute of Technology",
	"components.fuwu.7964ma": "Bachelor's degree from the campus, Master's degree from Stanford University, and Master's degree from the Wharton School of Business at the University of Pennsylvania",
	"components.fuwu.7m912c": "Degree. be responsible for",
	"components.fuwu.nj2151": ", Google toolbar, and participated in the development of Google operating system. I have also worked for Google personally",
	"components.fuwu.itkrrj": "The CEO is familiar with this application.",
	"components.fuwu.c7gc6f": "Michelle",
	"components.fuwu.27102u": "&#183; Agassi has 25 years of work experience, with a PhD from MIT and a Bachelor's degree from Brown University",
	"components.fuwu.5549r8": "Position:",
	"components.fuwu.qtx3m3": "EPOCH; EPT; 3-5 day short-term quantitative AI specialist",
	"components.fuwu.xj5p65": "Famous saying: The market changes quickly, seize opportunities in the short term, fast in and fast out.",
	"components.fuwu.nbk6k7": "Grego",
	"components.fuwu.o11m0w": "&#183; M; Andrade has 21 years of experience, with a PhD from the University of Chicago and a Bachelor's degree from the Massachusetts Institute of Technology",
	"components.fuwu.58snmu": "EPOCH; EPT; 5-10 day short-term quantitative AI specialist",
	"components.fuwu.sdve3x": "Famous quote: Abundant market experience combined with never-ending",
	"components.fuwu.3no8us": "AI intelligence, the benefits are so simple.",
	"components.fuwu.8ru302": "Bradley",
	"components.fuwu.6zk9gb": "&#183; D; Asnis has 25 years of work experience, with a PhD in Law from the New York Law School and a Bachelor of Arts degree from Brandeis University",
	"components.fuwu.748c4r": "EPOCH; EPT; 15-20 day short-term quantitative AI specialist",
	"components.fuwu.ykoem3": "Famous saying: Adequate time can bring better opportunities and make profits more stable.",
	"components.fuwu.c8741k": "Jeffrey",
	"components.fuwu.mnj8g5": "&#183; Borduke has 19 years of experience as a Chartered Financial Analyst with a Bachelor of Arts degree from Bates College and a Master of Business Administration degree from the University of Chicago;",
	"components.fuwu.pt4258": "EPOCH; EPT; 30-50 day short-term quantitative AI specialist",
	"components.fuwu.yhx273": "Famous saying: I am very optimistic about long-term and stable investments, and there is nothing better than this.",
	"components.fuwu.l1axrb": "Time events",
	"components.fuwu.425wcf": "/Milestones",
	"components.fuwu.n33tpy": "Established and launched the first hedge fund product in 1998.",
	"components.fuwu.hch6ip": "In 2000, the first international stock strategy was launched, and its returns earned international acclaim.",
	"components.fuwu.51k20j": "In 2016, an office was opened in Hong Kong to expand its Asian business.",
	"components.fuwu.e7b8ez": "In the fourth quarter of 2016, EPOCH EPT established an Artificial Intelligence Research Center in Hong Kong",
	"components.fuwu.gk53k8": "2019 Q2",
	"components.fuwu.51y6x1": "Version 1.0 White Paper Release",
	"components.fuwu.7p2h89": "Develop EPOCH EPT public blockchain in the first quarter of 2020",
	"components.fuwu.19t2i2": "In the fourth quarter of 2020, EPOCH EPT established a big data center",
	"components.fuwu.00bmfi": "Q1 2021",
	"components.fuwu.812he4": "The quantitative trading system is connected to the EPOCH EPT big data center.",
	"components.fuwu.7vb4bx": "Season 3, 2021",
	"components.fuwu.i5s411": "Quantitative trading system startup package testing",
	"components.fuwu.4rzg05": "Q2 2022",
	"components.fuwu.6f1vku": "The quantitative trading system has achieved a total",
	"components.fuwu.7j4ksr": "A quantitative profit of 1.3 billion US dollars.",
	"components.fuwu.oox35c": "Birmingham, UK",
	"components.fuwu.t13534": "Registration and establishment",
	"components.fuwu.27p688": "EPOCH; EPT has launched the world's first AI intelligent trading system.",
	"components.fuwu.0at9r7": "6.&nbsp; In the near future, it is foreseeable that billions of people will store their wealth in the form of digital assets. These assets include not only traditional currency and physical assets, but also virtual digital assets. Digital assets have multiple attributes such as value preservation, convertibility, and hedging, which can mitigate the risk of depreciation or losses caused by financial crises or conflicts. They provide a mechanism for protecting and protecting personal assets, allowing unrestricted interaction between different periods and fields. As more and more users hold digital assets, they seek stable investments to increase the value of digital assets and cope with changes in the real economy and inflation. This is",
	"components.fuwu.r4o4y5": "Quantitative trading systems are the best choice. Currently, there are over",
	"components.fuwu.yt83vn": "300 million people participate in investment activities in the blockchain market, with a global digital asset value exceeding 50 billion US dollars. The commission generated from blockchain digital asset trading exceeds 500 million US dollars, with huge market potential. In order to better preserve and increase value, we adopt",
	"components.fuwu.74n7mh": "The quantitative trading system has completely changed traditional trading methods, allowing more digital asset investors to obtain higher returns and returns. It also provides hedging and wealth growth opportunities for traditional investors. In the future world, financial markets will be the field of digital asset trading, and",
	"components.fuwu.h335ga": "Quantitative trading systems will lead the development of this era. Looking at the world,",
	"components.fuwu.5173h3": "The quantitative trading system has established its position in the field of financial investment in the shortest possible time. It has created new application models based on digital economy, digital assets, and blockchain technology.",
	"components.fuwu.jw5ueb": "7.&nbsp; Regarding legal considerations, as more and more cryptocurrencies are classified as securities, financial authorities in the United States, Singapore, China, Switzerland, and Germany have tightened their policies or issued warnings regarding initial token issuances (ICOs).",
	"components.fuwu.r3jm21": "Recognize and comply with securities regulations in several major jurisdictions, and comply with them",
	"components.fuwu.2a723k": "KYC (Know Your Customer) and AML (Anti Money Laundering) regulations. Specifically, this means: Securities rules: Please read this section carefully. If you have any questions about the actions that should be taken, we suggest that you consult your legal, financial, tax, or other professional advisors. The information listed in this document may not be comprehensive and does not represent any fundamental elements of the contractual relationship. This document does not constitute any investment, tax, legal, regulatory, financial, accounting or any other advice, nor can it be the sole reason for participating in the support and development of the Hang Seng Index ecosystem. Before making any decision, potential buyers must consult their legal, investment, tax, accounting, and other advisors to determine the potential benefits, limitations, and other consequences of such transactions. Any part of this document is not an offering, prospectus, or tender document, nor is it intended to be a requirement for investing in securities in any jurisdiction. This document is not written in accordance with the laws or regulations of any jurisdiction that prohibits or restricts transactions related to digital tokens or their use. KYC and AML: Understanding the technical details of your customer (KYC) and anti money laundering (AML) regulations may vary by country/region, but they both require verification and cross checking of customer identity based on various lists of terrorists, embargoes, and politically exposed individuals (PEPs).",
	"components.fuwu.n5ijvs": "We are collaborating with professional service providers to implement this process. In the process of public investment, investor Kate",
	"components.fuwu.y06wpn": "8.&nbsp; Disclaimer: This document does not involve any regulated products within the scope of legal jurisdiction. This document is a conceptual document (white paper) used for project description, for reference only, and is not intended for the sale or fundraising of stocks, securities, or any other purposes",
	"components.fuwu.5t61y5": "Related products and regulated products of affiliated companies. This document should not be considered as a prospectus or any standardized contractual document, nor should it constitute investment advice or solicitation for securities or any other regulated products in any jurisdiction. This document should not be used as advice for the sale, subscription, or invitation to purchase or subscribe to any securities or any contracts, contacts, or commitments based on them. It should not be seen as a suggestion for investment participation. Any information or analysis provided in this document does not constitute any recommendations for participating in token investments, nor does it provide any specific recommendations. You must seek all necessary professional advice on relevant matters, such as tax and accounting reviews. This document does not constitute any representation or warranty. Used to explain our proposed",
	"components.fuwu.j73g2y": "-&Nbsp; AL quantitative trading system, but",
	"components.fuwu.3ip5we": "The foundation clearly states as follows",
	"components.fuwu.1x311y": "8.1 No representation or warranty is made in any form regarding the accuracy or completeness of any content described in this document or any other content related to the project published in this document.",
	"components.fuwu.0c5427": "8.2 Without prior conditions, no forward-looking or conceptual statements or warranties regarding achievement or feasibility shall be provided.",
	"components.fuwu.h6u91t": "8.3 Nothing in this document shall serve as the basis for any promises or statements regarding the future.",
	"components.fuwu.if1i12": "8.4 The company shall not be liable for any losses caused by individuals due to this white paper or other aspects.",
	"components.fuwu.m21co1": "8.5 Liability is limited to the maximum extent permitted by applicable law, within the unavoidable scope of legal liability. Risks related to regulatory policies in relevant regions and countries: While blockchain technology is supported and recognized globally, it is also subject to careful scrutiny by various regulatory agencies.",
	"components.fuwu.t5i928": "The functionality of quantitative trading systems may be affected by certain regulatory policies, including but not limited to restrictions on use or possession",
	"components.fuwu.77k38h": "USDT, which may hinder or limit",
	"components.fuwu.sxf326": "The development of quantitative trading systems. Other unknown risks",
	"components.fuwu.t62x6w": "Blockchain technology and its corresponding digital currency technology are relatively new and insufficiently validated technologies, which may pose unforeseeable risks in various ways.",
	"login.register.6l6651": "Telephone registration",
	"login.register.bsu16p": "Confirm password",
	"login.register.8r578v": "The passwords entered twice are different",
	"components.xieyi.z4tui6": "Welcome to use",
	"components.xieyi.985i12": "Limited Company (hereinafter referred to as 'Limited Company')“",
	"components.xieyi.k71g1d": "We",
	"components.xieyi.6473ug": "”Or 'platform' products and services! In order to provide you with various technical services under the platform, we will process your personal information during your operation. We are well aware of the importance of personal information to you, and your trust is very important to us. We will strictly comply with the requirements of laws and regulations and take corresponding security measures under the current effective technical conditions, committed to protecting the security of your personal information. Based on this, we have formulated this Privacy Policy (hereinafter referred to as 'this Policy') to help you fully understand how we collect, use, share, store, and protect your personal information during your use of our products and services, as well as how you can manage your personal information, so that you can make appropriate choices.",
	"components.xieyi.r997hr": "Before you start using our products and services, please make sure to carefully read and understand this policy, especially focusing on our",
	"components.xieyi.23i3k9": "Bold/Bold underline",
	"components.xieyi.5jo3lp": "Please ensure that you fully understand and agree to the terms of the identification before starting to use it. We will try our best to explain the professional vocabulary involved in this policy to you in concise and easy to understand terms.",
	"components.xieyi.sw2x3b": "If you have any questions, opinions or suggestions regarding the content of this policy, you can contact us through",
	"components.xieyi.w1nc5l": "The official customer service of the platform is contacting us.",
	"components.xieyi.u855jt": "Your agreement to the privacy policy only means that you have understood the functions provided by the application and the necessary personal information required for the operation of the functions. It does not mean that you have agreed that we can collect non essential personal information. Non essential personal information will be separately sought for your consent based on your authorization during use.",
	"components.xieyi.jiyuvt": "If you refuse this Privacy Policy, you can still use the basic browsing functions without registration/login. You can also authorize separately during the subsequent use of the service.",
	"components.xieyi.61890q": "This policy will help you understand the following content:",
	"components.xieyi.5mt6j8": "1、 Definition and Scope of Application",
	"components.xieyi.41xpk4": "2、 How do we collect and use your information",
	"components.xieyi.83j8rl": "3、 How do we use cookies and similar technologies",
	"components.xieyi.8b639p": "4、 How do we share, transfer, and publicly disclose your information",
	"components.xieyi.6dq28b": "5、 How do we protect your information",
	"components.xieyi.2ulrqx": "6、 How do we store your information",
	"components.xieyi.3nz3ga": "7、 How do you manage your information",
	"components.xieyi.9s39yt": "8、 How do we handle information about minors",
	"components.xieyi.ew14nj": "9、 How to update this policy",
	"components.xieyi.12eq82": "10、 How do you contact us",
	"components.xieyi.b1sb46": "（1） Definition",
	"components.xieyi.we1z6j": "Refers to Birmingham, UK",
	"components.xieyi.rw8pei": "Registering EPOCH EPT has",
	"components.xieyi.idu1sg": "Limited company.",
	"components.xieyi.m568up": "Platform:",
	"components.xieyi.hvxy61": "finger",
	"components.xieyi.73ijj4": "Website (domain name www.weipaitang. com), APP client（",
	"components.xieyi.h0re24": "）And WeChat official account and other service media, also known as“",
	"components.xieyi.ivy1b3": "”'Platform'.",
	"components.xieyi.ki07fh": "Related companies:",
	"components.xieyi.6c317m": "Refers to and",
	"components.xieyi.wr8y6r": "Enterprises with direct or indirect control or significant influence relationships, including those holding",
	"components.xieyi.5865bv": "Shareholders with equity exceeding 5%",
	"components.xieyi.hty2r8": "Our subsidiary",
	"components.xieyi.36ty5w": "Other enterprises controlled by the actual controller.",
	"components.xieyi.lsnc7x": "Payment Company",
	"components.xieyi.47x242": ": Refers to your involvement in",
	"components.xieyi.gmmfq4": "The payment institution that provides payment services for shopping on the platform.",
	"components.xieyi.k10tpg": "Personal information:",
	"components.xieyi.69e87n": "Refers to various information recorded electronically or otherwise related to identified or identifiable natural persons, excluding information processed anonymously.",
	"components.xieyi.o1m0al": "Personal sensitive information:",
	"components.xieyi.2h5e47": "Personal information that, once leaked or illegally used, can easily lead to infringement of the personal dignity of natural persons or harm to personal and property safety, including",
	"components.xieyi.2ipl66": "Biometric identification, religious beliefs, specific identities, healthcare, financial accounts, travel trajectories",
	"components.xieyi.8122dw": "Waiting for information, and",
	"components.xieyi.124tei": "Personal information of minors under the age of fourteen",
	"components.xieyi.swn32o": "We will address specific personal sensitive information in this policy",
	"components.xieyi.704x6v": "bold",
	"components.xieyi.274q3q": "Significantly marked.",
	"components.xieyi.u2twjv": "Personal information processing:",
	"components.xieyi.9813ba": "Refers to the collection, storage, use, processing, transmission, provision, disclosure, deletion, etc. of personal information.",
	"components.xieyi.rutsx1": "children",
	"components.xieyi.81twf5": "Refers to minors under the age of fourteen.",
	"components.xieyi.5jsxyn": "Unless otherwise agreed, the definitions used in this policy are consistent with",
	"components.xieyi.hu1431": "Transaction Service User Agreement",
	"components.xieyi.f61m93": "The definitions in have the same meaning.",
	"components.xieyi.4kd4rv": "（2） Applicable scope",
	"components.xieyi.s893e8": "This policy applies to",
	"components.xieyi.p95qjo": "with",
	"components.xieyi.1d536g": "Websites, clients, applets, official account and various products and services provided to you in new forms with the development of technology.",
	"components.xieyi.d02u4n": "If used in our products or services and those of our affiliated companies",
	"components.xieyi.vd5142": "Products or services (such as using",
	"components.xieyi.2ifgyw": "If the platform account is logged in, but there is no independent privacy policy, then this policy also applies to that part of the product or service. If we and our affiliated companies have separate privacy policies for the products or services they provide to you, the corresponding products or services shall be subject to the corresponding privacy policies.",
	"components.xieyi.lzne8r": "In addition to the information collection and use activities described in this policy,",
	"components.xieyi.8o7eza": "This policy does not apply to",
	"components.xieyi.c7i3mg": "Other services provided to you by platform sellers and other third parties shall be subject to the privacy policy and other personal information collection and usage rules separately explained to you",
	"components.xieyi.f4n8lb": ". For example, when you participate in",
	"components.xieyi.51p7u0": "When operating EPOCH and EPT",
	"components.xieyi.9ngrl3": "Based on the requirements of laws and regulations, we will explain to you the rules for collecting and applying your personal information.",
	"components.xieyi.62e3v6": "According to the Regulations on the Scope of Necessary Personal Information for Common Types of Mobile Internet Applications",
	"components.xieyi.3n4mh2": "The APP belongs to the category of 'online'",
	"components.xieyi.8q81vo": "financing",
	"components.xieyi.57m6k2": "”The basic functional services are“",
	"components.xieyi.6891w4": "AI Intelligence",
	"components.xieyi.s2ji36": "”The necessary personal information under this function includes: your phone number; The recipient's name, address, and contact phone number; Payment information such as payment time, payment amount, and payment channel.",
	"components.xieyi.wyy6r5": "We provide you with convenient e-commerce transaction platform services. In order to achieve service provision, we will process your personal information based on the different functions of the platform:",
	"components.xieyi.t1c3k8": "1. Basic Function: In order to achieve the basic function of providing you with our products and/or services, you must authorize us to collect and use necessary information. If you refuse to provide the corresponding information, you will not be able to use our products and/or services normally",
	"components.xieyi.y7m0r2": "2. Additional features: In order to provide you with additional features of our products and/or services, you can choose whether to authorize us to collect and use the information. If you refuse to provide it, you will not be able to use the relevant additional functions normally or achieve the desired functional effects.",
	"components.xieyi.cwp3h6": "We would like to remind you that:",
	"components.xieyi.63078v": "1. We are committed to creating a diverse range of products and services to meet your needs.",
	"components.xieyi.11t174": "Due to the wide variety of products and services we provide to you, and the differences in the specific product/service scope that different users choose to use, the basic/additional functions and the corresponding types and scope of personal information collected and used may vary. Please refer to the specific product/service functions for accuracy;",
	"components.xieyi.654y1j": "To provide you with a better product and service experience, we continuously strive to improve our products, services, and technologies. As a result, we may occasionally introduce new or optimized features, which may require the collection and use of new personal information or changes in the purpose or method of personal information usage. If the above situation occurs, we will provide you with detailed explanations through updating this policy, pop ups, page prompts, and other means, and provide you with the option to choose and agree. We will only start collecting and using it after obtaining your explicit consent.",
	"components.xieyi.228036": "During this process, if you have any questions, opinions or suggestions, you can contact us through the channels specified in this policy, and we will provide you with answers as soon as possible.",
	"components.xieyi.3l69jv": "We will provide you with various specific functional scenarios, including:",
	"components.xieyi.xq77st": "（1） User registration and account management",
	"components.xieyi.hgd0m6": "1. Basic User Services",
	"components.xieyi.sy4rg3": "We are based on",
	"components.xieyi.3768od": "The platform account provides services for you. To create",
	"components.xieyi.k5pm58": "You need to provide us with at least your platform account",
	"components.xieyi.ijyhl0": "Mobile phone number, intended username and password to be used",
	"components.xieyi.k04bce": ". If you refuse to provide the above information, you will not be able to register",
	"components.xieyi.4b0us6": "Platform account, but you can still use browsing and search services.",
	"components.xieyi.3k9d16": "You can modify and supplement your nickname, gender, region, contact person, and your real name authentication related information, all of which belong to your 'account information'.",
	"components.xieyi.rqik5d": "Among them, the nickname and avatar you have set will be publicly displayed.",
	"components.xieyi.ilmlc1": "The additional account information you provide will help us provide you with personalized product recommendations and a better shopping experience, but if you do not provide these additional information, it will not affect your basic functions of online shopping.",
	"components.xieyi.2d8htr": "To ensure that we are providing services for you personally, we may verify your identity based on the information you provide. If there are scenarios where it is necessary to determine your user identity in accordance with the law (including withholding taxes and fees for you in accordance with the law, identifying relevant parties in administrative law enforcement or judicial litigation, etc.), you authorize us to obtain your corresponding authentication information from our partners for the above purposes.",
	"components.xieyi.qimk2p": "We will take into account your",
	"components.xieyi.z57g36": "The usage of platform accounts and the calculation rules for account levels set by the platform determine your current level and provide you with the basic benefits corresponding to the corresponding account level.",
	"components.xieyi.21n38j": "2. Additional services",
	"components.xieyi.dh7o8w": "Real person authentication: You can provide corresponding authentication requirements",
	"components.xieyi.57s046": "Identity information (ID card, passport, household registration book, and other identity document information), biometric features (static or dynamic facial features)",
	"components.xieyi.eb3ena": "To complete real person authentication.",
	"components.xieyi.2fnv9d": "Authorized login: With your consent, we may share your account information (avatar, nickname, and other page prompts) with third parties, allowing you to easily register for third-party accounts or log in directly from third parties. In addition, we may obtain your third-party account information from third parties such as WeChat based on your authorization and communicate it with your",
	"components.xieyi.2586kj": "Platform account binding allows you to directly log in and use our products and/or services from a third party. We will use your relevant information within the scope of your authorized consent.",
	"components.xieyi.75o8vj": "Other user role services: If you apply to register as a seller or other user role with additional identity authentication requirements, you need to proactively provide us with identity information and/or enterprise related information for the purpose of verifying, registering, publicizing your identity and other user role qualifications, and other purposes that we have clearly informed you. Like '",
	"components.xieyi.ni3907": "Authorized wealth management",
	"components.xieyi.1168tn": "'You agree to use our collection of your mobile phone number and ID card number for live broadcast account real name management, as well as collection of device identifiers, device brand models, device operating systems, and geographical locations for live broadcast function matching and live broadcast security control.",
	"components.xieyi.k2wd14": "3. Identity verification",
	"components.xieyi.1ns543": "In order to meet relevant legal and regulatory requirements, ensure the authenticity of user identity (including protecting the rights and interests of minors in accordance with the law, cracking down on telecommunications fraud, withholding and paying personal income tax, identifying relevant entities in administrative law enforcement or judicial litigation), achieve risk control such as anti fraud, and ensure the security of systems and services, we need to collect your relevant information for identity verification when you use specific services/functions, For example: [Change Phone Number] When you use the 'Change Phone Number' function to retrieve your account, as an individual user, we may collect your identity information, including your",
	"components.xieyi.3lj887": "Real name, ID number, facial recognition information, order information",
	"components.xieyi.7y2f63": "Used to verify your identity and help you retrieve your platform account.",
	"components.xieyi.y57u7o": "For enterprise users, we may collect your legal identity information, including",
	"components.xieyi.82yye6": "Enterprise business license information, real name of the legal representative, ID number, facial recognition information",
	"components.xieyi.t6tsvp": "If you already have",
	"components.xieyi.3coa6i": "Platform account, we may have access to",
	"components.xieyi.4g34dh": "Your personal information mentioned above is displayed in the platform services, as well as in your",
	"components.xieyi.i3lx1u": "On the platform or in collaboration with",
	"components.xieyi.cd0jo8": "The operations performed in the products and services associated with the platform account, including through",
	"components.xieyi.f5dq1g": "The platform account displays your personal information, discount benefits, and transaction orders in a centralized manner",
	"components.xieyi.vo3428": ". We will respect your respect for",
	"components.xieyi.y3j2fo": "The selection made for platform account settings.",
	"components.xieyi.pw3g77": "（2） Showcasing product, service information, and message push to you",
	"components.xieyi.82aac2": "1. To display product or service information to you, including your visit history and search history, we will collect and use information from you during your visit or use",
	"components.xieyi.h4s010": "Browsing and search history on the platform. We will combine legally collected device information, service log information, and other authorized information to predict your preference characteristics through an algorithm model. We will push you commercial advertisements and other information that you may be interested in based on your preference characteristics, or send you commercial short messages.",
	"components.xieyi.kf81h2": "Personalized recommendation and retrieval algorithms will predict your preference features based on the model, match products, services, or other information that you may be interested in, and sort the products, services, or other information displayed to you. We will provide real-time feedback on the recommendation model based on your browsing behavior during the use of the product, and continuously adjust and optimize the recommendation results. To meet your diverse needs, we will introduce diverse recommendation techniques in the sorting process to expand the recommended content and avoid excessive concentration of similar content.",
	"components.xieyi.182j2p": "Device Information: We will receive and record device related information (including device model, operating system version, device settings, MAC address, IMEI, IDFA, OAID, Android) based on your specific operations during access and/or use",
	"components.xieyi.1ns292": "Device identifier, device environment, mobile application list software and hardware feature information, and location related information of the device (including your authorized GPS location, WLAN access point, Bluetooth, and base station sensor information).",
	"components.xieyi.y2hur5": "Service log information: When you access or use",
	"components.xieyi.2l7v45": "When using the platform, we will automatically collect and record your detailed usage information as a service log, including browsing records, click to view records, search query records, onlookers, reports, transactions, after-sales, follow, share information, publish information, as well as IP address, browser type, telecommunications operator, language of use, access date and time.",
	"components.xieyi.i2q27g": "Please note that individual device information and service log information cannot identify the identity of a specific natural person.",
	"components.xieyi.48iqgk": "If we combine this type of non personal information with other information to identify a specific natural person's identity, or use it in combination with personal information, during the combined use period, this type of non personal information will be considered personal information. Unless authorized by you or otherwise required by laws and regulations, we will anonymize and de identify this type of information.",
	"components.xieyi.2e8s23": "Meanwhile, we strive to ensure your privacy experience through the following measures:",
	"components.xieyi.83qc71": "(1) If you want to delete your browsing and search records, you can choose to delete them in 'My Footprints' or 'Search Box Recent Searches';",
	"components.xieyi.72qs2r": "(2) If you need to view search results that are not specific to your personal characteristics when using our on-site search service, you can click 'Filter' on the search results page to set it up;",
	"components.xieyi.35ps8a": "(3) If you want to manage the personalized advertisements we send you, you can find them in the“",
	"components.xieyi.777kl1": "My - Settings (upper right corner) - Privacy - Personalization settings",
	"components.xieyi.8026t2": "Set it up in. It should be noted that you can set us to not display relevant advertisements to you using certain category information, but you will still see advertisements. The number of advertisements you see will not change, but the relevance of the advertisements will decrease.",
	"components.xieyi.5hx7h7": "If you want to manage the personalized content we push to you, you can click on the“",
	"components.xieyi.5vxbqy": "My - Settings (upper right corner) - Privacy - Personalization settings",
	"components.xieyi.8wfq82": "”Set it up in. After closing, the content of the recommendation modules such as 'recommendations' and 'recommendations for you' will be random recommendations and non personalized recommendations.",
	"components.xieyi.f98v89": "(4) If you want to delete interest and hobby tags with your personal characteristics, you can do so through",
	"components.xieyi.3ve6e7": "(5) If you are not interested in the products displayed on the homepage, you can reduce the display of related products by long pressing on the recommended product image and selecting 'Product not interested' in the pop-up prompt.",
	"components.xieyi.6mlx8c": "2. Business information push and event reminder services",
	"components.xieyi.1y06bp": "In order to help you better understand and use",
	"components.xieyi.t45916": "You agree that we will send you business information and other event reminders,",
	"components.xieyi.q2y74e": "The service provider will collect and use your name and contact information based on the above service objectives.",
	"components.xieyi.84b8nh": "If you do not want to receive our push messages,",
	"components.xieyi.eh6abn": "You can turn it off through 'My Settings (upper right corner) - Message Settings'",
	"components.xieyi.3drpe9": "If you do not want to accept the commercial advertisement we send you,",
	"components.xieyi.16389e": "You can unsubscribe or close it by replying to the SMS prompt or using other methods we provide;",
	"components.xieyi.i69xy9": "If you turn off or unsubscribe from the push feature, you will not be able to receive services for pushing product or event information",
	"components.xieyi.2s65pg": "3. Sensor information.",
	"components.xieyi.vn4s8x": "When you use our products/services, we may collect information about your sensors to adapt to your device status or to recognize your actions when you choose to participate in activities. The types of sensors may vary in different scenarios, and we will only use them in specific scenarios. For example, during shooting, it is used to determine whether the user rotates the screen during shooting, making it convenient to correct and optimize the captured video.",
	"components.xieyi.opxu33": "（3） Providing you with the functions of watching, following, and sharing",
	"components.xieyi.46wa51": "When you browse",
	"components.xieyi.sm4d35": "During the platform process, you can choose to observe products, services, and/or other users of interest, and share product/service information with third parties through the functional components we provide. During your use of the above features, we will collect information including your",
	"components.xieyi.qt6mj3": "operation",
	"components.xieyi.k465t1": "Record",
	"components.xieyi.13pdf3": "cooperation",
	"components.xieyi.j6413h": "The service log information, including relationships and sharing history, is used to achieve the above functions and other clearly stated purposes.",
	"components.xieyi.g5yo4i": "You can delete your viewers and follow information in 'My Watchers' and 'My Followers'.",
	"components.xieyi.es68il": "（4） Help you complete order placement and order management",
	"components.xieyi.439hc7": "When you order specific products and/or services in our products and/or services, we will generate an order for you to purchase that product and/or service through the system. During the ordering process, you need to provide at least your",
	"components.xieyi.4zfg7p": "Name, address, and contact phone number of the recipient",
	"components.xieyi.875811": "For some special types of goods and services, you also need to provide other necessary information for the goods or services, such as those related to",
	"components.xieyi.sg3r7o": "When dealing with cultural relics, you need to provide your real name and ID number to complete the filing required by laws and regulations related to cultural relics protection and cultural relics supervision and administration authorities.",
	"components.xieyi.x52n3c": "At the same time, the order will include information about the goods and/or services you purchased, the specific product number, and the amount you should pay. We collect this information to assist you in completing your transaction smoothly, ensure the safety of your transaction, facilitate your inquiry of order information, provide customer service and after-sales service, and other purposes that we have clearly stated.",
	"components.xieyi.633jos": "You can use",
	"components.xieyi.s43d5n": "The platform orders goods and/or services for others, and you need to provide the aforementioned personal information of the actual recipient and ensure that you have obtained the consent of the actual recipient",
	"components.xieyi.q3y8ui": "To facilitate your understanding, query, and management of order information, we will collect order information generated during your use of our services for display and to facilitate your order management.",
	"components.xieyi.prc15q": "You can view and manage your order information through 'My My Orders'.",
	"components.xieyi.ncd26i": "（5） Helping you complete payment",
	"components.xieyi.3u52sy": "To complete the order payment, you need to choose the payment method and payment channel when making the payment. We will share your personal information, order payment related information, order security related device information, and other necessary anti money laundering legal requirements with the payment company through embedded payment company tool development packages (referred to as 'SDKs' in this policy) on the website or client. If you choose to have other financial institutions provide payment services for you, we will also share your necessary bank card payment information, including bank card number and validity period, with the corresponding financial institution you choose.",
	"components.xieyi.m65845": "In order for us to promptly inform and confirm your payment progress and status, and provide you with after-sales and dispute resolution services, you agree that we may collect information related to payment progress from the trading partner, payment company, or other financial institution you choose.",
	"components.xieyi.bydbtx": "You can view your",
	"components.xieyi.rarv1g": "The bank account information bound to the platform wallet account or the application to replace the bound bank account.",
	"components.xieyi.73148a": "（6） Assist in delivering goods or services to you",
	"components.xieyi.f8ce6b": "To ensure the smooth, safe, and accurate delivery and provision of the goods and/or services you purchase, you acknowledge and agree that the corresponding logistics and distribution entity will inevitably be informed of and use your delivery information to complete the delivery purpose.",
	"components.xieyi.wi14jv": "For the purpose of providing after-sales and dispute resolution services to you, we need to be informed and confirm the delivery progress and status in a timely manner. You agree that we can collect information related to delivery progress from logistics related service providers.",
	"components.xieyi.h3txyu": "（7） Customer service and dispute resolution",
	"components.xieyi.75p62d": "When you contact us or apply for resolution of sales, after-sales, or dispute disputes, in order to ensure the security of your account and system,",
	"components.xieyi.u3u59b": "We need you to provide the necessary personal information to verify your user identity.",
	"components.xieyi.r9ucq4": "For the convenience of contacting you, helping you solve problems as soon as possible, or recording the solutions and results of related issues, we may save your communication, communication/call/video records and related content with us (including account information, order information, other information you provide to prove relevant facts, or contact information you leave). If you consult, complain or provide suggestions regarding specific orders, We will use your account information and order information.",
	"components.xieyi.te87ps": "In order to provide services and improve service quality as reasonably necessary, we may also use your other information, including the relevant information you provided when contacting customer service, and the questionnaire response information you sent us when participating in the survey.",
	"components.xieyi.259477": "（8） We provide you with the function of publicly publishing comments, Q&A, and other information",
	"components.xieyi.67h2ep": "You can publicly publish information through the comments, communities, live broadcasts, and other information publishing functions we provide for you, including the ability to publish graphic/video content, review content, etc. as a user, as well as the ability to publish product and/or service related information and store related information as a seller.",
	"components.xieyi.194252": "We may determine whether you are eligible for corresponding functional permissions based on your user type and network log information (for example, only users who have purchased a certain product can post reviews on it).",
	"components.xieyi.x8rr9h": "Please note that the information you publicly release may involve personal information or even sensitive personal information of yourself or others. If you choose to upload images containing personal information when evaluating, please be more cautious and consider whether to share or even publicly share relevant information when using our services. If the information you publicly release involves personal information of others, you need to obtain their consent before publishing.",
	"components.xieyi.h60ox8": "（9） Providing You with Security",
	"components.xieyi.q466n3": "To fulfill the legal obligation to ensure the security of e-commerce transactions, improve the security of your use of services provided by us and our affiliated companies and partners, protect your personal and property security from infringement, better prevent security risks such as phishing websites, fraud, network vulnerabilities, computer viruses, network attacks, and network intrusions, and more accurately identify violations of laws and regulations",
	"components.xieyi.3p5627": "We embed the application security SDK developed by our affiliated company in the application to collect your device information regarding platform related protocols and rules",
	"components.xieyi.4n220b": "Service log information, device sensor data, commonly used software information, and potential use or integration of your user information, transaction information, device information, service log information, as well as information authorized or legally shared by our affiliated companies and partners (where we may collect MAC addresses, IMEI device related information for risk testing during application backend operation), To comprehensively assess the risks of your account and transactions, conduct identity verification, detect and prevent security incidents, and take necessary recording, auditing, analysis, and disposal measures in accordance with the law.",
	"components.xieyi.4ay5f9": "（10） APP self start or associated start",
	"components.xieyi.i2rhwf": "In order to enhance the user experience of using our services and better ensure transaction security, we need to use the app's self start or associated start function to prompt and assist users in obtaining our updated version of the app application services.",
	"components.xieyi.46wk64": "（11） Other additional services provided to you",
	"components.xieyi.9w14b7": "To provide you with more convenient, high-quality, personalized products and/or services, and strive to improve your experience, we may collect and use your personal information in the following additional services provided to you.",
	"components.xieyi.7jt388": "You can choose whether to provide this information at your own discretion. If you do not provide this information, it will not affect your use",
	"components.xieyi.1c6h45": "Basic services,",
	"components.xieyi.035322": "But you may not be able to access the user experience that these additional services bring to you.",
	"components.xieyi.w541k9": "You can check the status of the above permissions item by item in your device's 'Settings' or our client's 'My Settings Privacy System Permissions', and you can decide whether to turn these permissions on or off at any time.",
	"components.xieyi.t77ks8": "Please note that by enabling any permission, you authorize us to collect and use relevant personal information to provide you with corresponding services. Once you close any permission, it means that you cancel the authorization. We will no longer continue to collect and use relevant personal information based on the corresponding permission, nor can we continue to provide you with the services corresponding to that permission. Your decision to close permissions will not affect the information collection and use based on your authorization.",
	"components.xieyi.q563dr": "You can",
	"components.xieyi.bs34gv": "Click here ",
	"components.xieyi.w6lbn0": "View application permission application and usage instructions",
	"components.xieyi.835h48": "（12） Other rules for collecting and using personal information",
	"components.xieyi.jy12wf": "If the information you provide contains personal information of other users, please contact",
	"components.xieyi.m6i6t5": "Before providing this personal information on the platform, you need to ensure that you have obtained legal authorization.",
	"components.xieyi.a1o5v4": "If we use the information for other purposes not specified in this policy, or collect information for specific purposes for other purposes, we will obtain your consent in advance, except as required by laws and regulations.",
	"components.xieyi.41gslf": "3. If we indirectly obtain your information from a third party, we will explicitly request in writing before collection that the third party explain the source of their personal information and whether they have obtained your legal authorization for the collection, processing, and provision of your personal information to us. Unless expressly permitted by law, we will only collect your personal information from a third party after confirming that they have obtained your authorization and consent. If the scope of the third party's authorization does not cover our processing and usage purposes, we will process your personal information on our own or require the third party to obtain your consent before proceeding. At the same time, we will strengthen the security of personal information (including sensitive information reporting, encrypted storage of sensitive information, access control, etc.). We will use no less than the same protection measures and measures as we do for our own user personal information to protect indirectly obtained personal information.",
	"components.xieyi.x3r3y6": "4. Exceptions to obtaining authorized consent",
	"components.xieyi.3jev4v": "According to relevant laws and regulations, we do not require your authorization or consent to process your personal information in the following situations:",
	"components.xieyi.214uq6": "(1) Necessary for fulfilling legal duties or obligations;",
	"components.xieyi.81k4nu": "(2) Directly related to national security, national defense security, and major public interests;",
	"components.xieyi.j7fomu": "(3) To respond to sudden public health emergencies;",
	"components.xieyi.7806cy": "(4) Implementing news reporting, public opinion supervision, and other behaviors for the public interest, and processing personal information within a reasonable range;",
	"components.xieyi.754474": "(5) In emergency situations, if it is not possible to inform you in a timely or effective manner to protect your or others' life, health, or significant property safety, but will inform you promptly after the emergency situation is resolved;",
	"components.xieyi.i1yv2t": "(6) According to relevant laws and regulations, handle personal information that has been publicly disclosed by oneself or other individuals that have already been legally disclosed within a reasonable range;",
	"components.xieyi.5dz1kl": "(7) According to the necessary agreements or other written documents signed and fulfilled with you;",
	"components.xieyi.5bon72": "(8) Necessary for maintaining the safe and stable operation of the provided products and/or services, such as discovering and dealing with faults in the products and/or services;",
	"components.xieyi.cw60x0": "(9) Other situations stipulated by laws and regulations.",
	"components.xieyi.vyw19u": "Please be informed that according to the current effective relevant laws, if we",
	"components.xieyi.4t3bn8": "Take technical and other necessary measures to process personal information, so that the data recipient cannot re identify specific individuals and cannot recover them",
	"components.xieyi.1c74f9": "Or we may conduct de identification research, statistical analysis, and prediction on the collected information to improve it",
	"components.xieyi.1m85sf": "The content and layout of the platform, providing product or service support for business decision-making, and improving our products and services (including using anonymous data for machine learning or model algorithm training),",
	"components.xieyi.me4rhf": "The use of such processed data does not require separate notice to you and your consent",
	"components.xieyi.257c58": "3、 How do we use it",
	"components.xieyi.77f388": "And similar technologies",
	"components.xieyi.759p76": "（1） The use of cookies",
	"components.xieyi.48ze34": "To ensure the normal operation of the website, provide you with an easier access experience, and recommend content that you may be interested in, we will store cookies, Flash cookies, or other local storage provided by the browser (or associated applications) that typically includes identifiers, site names, and some numbers and characters (collectively referred to as 'cookies') on your computer or mobile device. With the help of",
	"components.xieyi.s020rs": "If your browser or browser add-on service allows, you can modify the acceptance level of cookies or reject our cookies.",
	"components.xieyi.3w12li": "For more details, please refer to AboutCookies. org. But if you perform the actions described in this clause, it may in some cases affect your safe access to our website, and you may need to change user settings every time you visit our website.",
	"components.xieyi.q45ikb": "（2） The use of similar technologies in cookies",
	"components.xieyi.h550rh": "except",
	"components.xieyi.7248f8": "In addition, we will also use other similar technologies such as website beacons, pixel labels, ETags, etc. on the website.",
	"components.xieyi.jvxl8f": "For example, the email we send you may contain an address link to our website content. If you click on this link, we will track the click to help us understand your product or service preferences, so that we can proactively improve the user experience. A website beacon is usually a transparent image embedded in a website or email. By using pixel labels in emails, we can determine whether the email has been opened. If you do not want your activities to be tracked in this way, you can unsubscribe from our mailing list at any time.",
	"components.xieyi.52wb6a": "ETag (Entity Tag) is an HTTP protocol header that is transmitted between an internet browser and an internet server, and can replace cookies. ETag can help us avoid unnecessary server load, improve service efficiency, and save resources and energy. Meanwhile, we may use ETag to record your identity so that we can have a deeper understanding and improve our products or services.",
	"components.xieyi.y92k4u": "Most browsers provide users with the function of clearing browser cache data, and you can perform corresponding data clearing operations in the browser settings function.",
	"components.xieyi.o54yhy": "However, please note that if you deactivate ETag, you may not be able to enjoy a relatively better product or service experience.",
	"components.xieyi.37e045": "（1） Sharing",
	"components.xieyi.675822": "We will not interact with",
	"components.xieyi.x1y6r8": "Share your personal information with companies, organizations, and individuals outside of your organization, except for the following situations:",
	"components.xieyi.8q0s05": "1. Sharing under legal circumstances: We will share your personal information with the public in accordance with legal regulations.",
	"components.xieyi.9irym2": "2. Sharing with explicit consent: After obtaining your explicit consent, we will share your personal information with other parties.",
	"components.xieyi.371i1r": "3. If necessary, we will provide you with the products or services you need and share them with you through:",
	"components.xieyi.75u39x": "When purchasing goods or services on the platform, we will share the necessary transaction related information in your order information with the providers of the relevant goods or services based on your choice, in order to meet your transaction, delivery, and after-sales service needs.",
	"components.xieyi.u6d81m": "4. Sharing with affiliated companies:",
	"components.xieyi.30si92": "For the convenience of us based on",
	"components.xieyi.6pysc9": "The platform account provides you with products and services, recommends information that you may be interested in, identifies user account anomalies, and protects",
	"components.xieyi.7vmrlb": "The personal and property safety of affiliated companies, other users, or the public is protected from infringement, and your personal information may be shared with our affiliated companies and/or their designated service providers.",
	"components.xieyi.269i0k": "We will only share necessary personal information and are bound by the purposes stated in this policy. If we share your sensitive personal information or if affiliated companies change the use and processing purposes of personal information, we will seek your authorization and consent again.",
	"components.xieyi.5475kt": "5. Entrusted authorized partners:",
	"components.xieyi.u1sa8e": "We may entrust authorized partners to provide you with certain services or perform functions on our behalf,",
	"components.xieyi.f1h4lc": "We will only share your information for the legitimate, legitimate, necessary, specific, and clear purposes stated in this policy statement. Authorized partners are only allowed access to the information they need to fulfill their duties, and we will require them through an agreement not to use this information for any other purpose.",
	"components.xieyi.g700e1": "Currently, our authorized partners include the following types:",
	"components.xieyi.36nvwn": "(1) Authorized partners for advertising and analysis services. We will entrust these partners to handle information related to advertising coverage and effectiveness, but unless we obtain your permission, we will not share your personal identification information with partners who provide advertising and analysis services, or we will deidentify this information so that authorized partners cannot recognize you personally. These types of partners may combine the above information with other data they legally obtain to carry out our commissioned advertising services or decision-making recommendations.",
	"components.xieyi.5xh5u7": "(2) Suppliers, service providers, and other partners. We send information to suppliers, service providers, and other partners who support our business, including providing technical infrastructure services, analyzing the usage of our services, measuring the effectiveness of advertising and services, providing customer service, facilitating payment or conducting academic research and investigations, logistics and distribution services, payment services, data processing, etc. Our purpose in sharing this information is to enable the core shopping functions of our products and/or services, such as sharing your order information with logistics service providers to arrange delivery; Alternatively, we may need to share your order number and amount with a third-party payment institution to confirm your payment instructions and complete the payment.",
	"components.xieyi.67e7b8": "(3) Real name authentication service provider. When we provide you with real name authentication services, you need to authorize us to collect your real name authentication information and provide it to third-party organizations (including but not limited to Tencent Cloud Computing (Beijing) Co., Ltd., hereinafter referred to as 'third-party organizations'), Third party institutions shall independently inquire and verify the authenticity, accuracy, and completeness of such information from relevant units (including but not limited to the People's Bank of China Personal Credit Information Basic Database and other relevant government agencies, basic telecommunications operators, information institutions, public security organs, procuratorates, courts, financial institutions, other cooperative institutions, or public channels), analyze, compare, process, and organize such information, And provide us with a comprehensive evaluation report on your personal credit information.",
	"components.xieyi.12lv44": "To ensure safety",
	"components.xieyi.3749fw": "The stable operation of the platform and the implementation of the aforementioned functions enable you to enjoy and use more services and functions. Our application will embed authorized partner SDKs or other similar applications.",
	"components.xieyi.3nos3g": "You can share the list of our connected partners with third parties",
	"components.xieyi.70yu7f": "Review.",
	"components.xieyi.89014s": "We will conduct strict security checks on the application program interfaces (APIs) and software tool development kits (SDKs) used by authorized partners to obtain relevant information, and agree with authorized partners on strict data protection measures to ensure that they handle personal information in accordance with this policy and any other relevant confidentiality and security measures.",
	"components.xieyi.1l5529": "（2） Transfer",
	"components.xieyi.ls670h": "We will not transfer your personal information to any company, organization, or individual, except in the following circumstances:",
	"components.xieyi.u50832": "1. Transfer with explicit consent: After obtaining your explicit consent, we will transfer your personal information to other parties;",
	"components.xieyi.6snoud": "2. In the",
	"components.xieyi.411611": "When there is a merger, acquisition, or bankruptcy liquidation situation, or other situations involving the transfer of personal information, we will promptly inform you of the name and contact information of the new company or organization that holds your personal information and require them to continue to be bound by this policy. Otherwise, we will require the company, organization, and individual to seek your authorization and consent again.",
	"components.xieyi.783yjz": "（3） Public disclosure",
	"components.xieyi.zdq4b7": "We will only publicly disclose your personal information in the following circumstances:",
	"components.xieyi.3in5j6": "1. We may publicly disclose your personal information with your explicit consent or based on your voluntary choice.",
	"components.xieyi.ey5b87": "2. Based on the information disclosure requirements stipulated by laws and regulations, disclose your personal information within the prescribed scope, such as the seller information disclosure stipulated in the Measures for the Supervision and Administration of Online Transactions.",
	"components.xieyi.ok7p68": "If we determine that you have violated laws and regulations or committed serious violations",
	"components.xieyi.6kb306": "The situation of platform related agreements and rules, or for the purpose of protection",
	"components.xieyi.41el81": "The personal and property safety of platform users or the public is protected from infringement. We may disclose personal information about you and stop providing services to you in accordance with laws and regulations or with your consent,",
	"components.xieyi.4c16r3": "Including related violations and",
	"components.xieyi.7uq8p7": "The platform has taken measures against you. For example, if you seriously violate the law by selling counterfeit goods",
	"components.xieyi.kc0i6h": "According to platform rules, we may publicly disclose your store's authentication subject information, violations, and",
	"components.xieyi.100i6j": "Measures for handling.",
	"components.xieyi.veqih2": "（4） Exceptions to obtaining authorization and consent before sharing, transferring, or publicly disclosing personal information",
	"components.xieyi.i5fma7": "According to relevant laws and regulations, sharing, transferring, or publicly disclosing your personal information does not require your prior authorization and consent in the following situations:",
	"components.xieyi.r82363": "1. Necessary for fulfilling legal duties or obligations;",
	"components.xieyi.ett47l": "2. Directly related to national security, national defense security, and major public interests;",
	"components.xieyi.nql27g": "3. To respond to sudden public health emergencies;",
	"components.xieyi.r2138s": "4. Implementing news reporting, public opinion supervision, and other modifications for the public interest to process personal information within a reasonable range;",
	"components.xieyi.i32523": "5. In emergency situations, if it is not possible to inform you in a timely or effective manner to protect your or others' life, health, or significant property safety, but will inform you promptly after the emergency situation is resolved;",
	"components.xieyi.4stqc6": "6. According to relevant laws and regulations, handle personal information that has been publicly disclosed by oneself or other individuals that have already been legally disclosed within a reasonable range.",
	"components.xieyi.7k22k8": "Please be informed that according to applicable laws,",
	"components.xieyi.7w6sng": "If we take technical and other necessary measures to process personal information such that the data recipient cannot re identify a specific individual and cannot recover it, then the sharing, transfer, and public disclosure of such processed data do not require separate notice to you and your consent.",
	"components.xieyi.t8w9o9": "（1） Data protection technology measures",
	"components.xieyi.5k26r2": "We have taken reasonable and feasible security measures that comply with industry standards to protect your information, preventing unauthorized access, public disclosure, use, modification, damage or loss of personal information. For example, we will use encryption technology to enhance the security of personal information, which is protected when exchanging data between your browser and server",
	"components.xieyi.824h5u": "SSL protocol encryption protection, we also provide",
	"components.xieyi.rs1e76": "The platform provides a secure browsing method using the HTTPS protocol; We will use trusted protection mechanisms to prevent malicious attacks on personal information; We will deploy access control mechanisms to ensure that only authorized personnel can access personal information.",
	"components.xieyi.qj2xrx": "（2） Data protection management compliance system",
	"components.xieyi.y1edur": "We have established an advanced data security management compliance system centered around the data lifecycle in the industry, which enhances the security of personal information from multiple dimensions such as organizational construction, system design, personnel management, and product technology. We will establish a personal information protection agency to supervise the protection of personal information and hold security and privacy protection training courses to enhance employees' awareness of the importance of protecting personal information.",
	"components.xieyi.d7ix35": "（3） Response to personal information security incidents",
	"components.xieyi.2o454t": "If our physical, technical, or management protective facilities are damaged, resulting in unauthorized access, public disclosure, tampering, or destruction of information, which damages your legitimate rights and interests, we will promptly activate emergency plans and take reasonable remedial measures to minimize the impact on your personal information and other rights and interests. If a personal information security incident occurs, we will also inform you in accordance with legal requirements: the basic situation and possible impact of the security incident, the disposal measures we have taken or will take, suggestions for you to independently prevent and reduce risks, and remedial measures for you. We will inform you of the relevant situation of the event through email, letter, phone, push notifications, and other means. If it is difficult to inform the personal information subject one by one, we will take reasonable and effective measures to publish an announcement. At the same time, we will also report the handling of personal information security incidents in accordance with regulatory requirements.",
	"components.xieyi.4706wi": "（4） Social supervision",
	"components.xieyi.4ouosr": "We will regularly release personal information protection social responsibility reports in accordance with relevant national laws and administrative regulations, and accept social supervision.",
	"components.xieyi.6r5a4q": "（5） Autonomous prevention of account security risks",
	"components.xieyi.veukf5": "The Internet is not an absolutely secure environment, using it",
	"components.xieyi.ri9a0j": "We strongly recommend that you do not use non",
	"components.xieyi.382lb2": "The communication method recommended by the platform is to send your message, and please use a complex password to assist us in protecting the security of your account.",
	"components.xieyi.s2h72a": "in use",
	"components.xieyi.8mtgk8": "When conducting online transactions on the platform, it is inevitable for you to disclose your personal information to counterparties or potential counterparties, such as",
	"components.xieyi.6ue558": "Contact information",
	"components.xieyi.5h8pl3": "Contact address",
	"components.xieyi.74n5cs": ". In addition, you can also establish communication, exchange information, or analyze content with third parties through our services. Please protect your personal information properly and only provide it to others when necessary. If you find that your personal information, especially your account or password, has been leaked, please contact us immediately",
	"components.xieyi.1100gs": "The official customer service of the platform enables us to take corresponding measures based on your application.",
	"components.xieyi.p898io": "Please note that the information you voluntarily share or even publicly share when using our services may involve personal information or even sensitive personal information of you or others. Please be more cautious when considering whether to share or even publicly share relevant information when using our services.",
	"components.xieyi.pfhp6l": "（1） Storage location",
	"components.xieyi.id973y": "The personal information collected and generated during our operations within the territory of the People's Republic of China will be stored within China.",
	"components.xieyi.76w8ku": "If it is necessary to provide your personal information to overseas parties due to business or purposes covered by this policy, we will conduct a personal information protection impact assessment and record the processing situation in accordance with relevant laws and regulations in advance, and process it in accordance with relevant laws and regulations with your explicit consent. At the same time, we will promptly inform you of the name, contact information, processing purpose, and processing method of the overseas information recipient The types of personal information and the methods and procedures for exercising national legal rights to overseas recipients.",
	"components.xieyi.7fciuv": "（2） Storage period",
	"components.xieyi.f1a2af": "We will only retain your personal information for the period necessary to achieve the objectives stated in this policy, unless there are mandatory retention requirements by law,",
	"components.xieyi.42nn2k": "For example, the Electronic Commerce Law of the People's Republic of China requires that the storage time of commodity and service information and transaction information shall not be less than three years from the date of completion of the transaction. We mainly refer to the following standards to determine the storage period of personal information, and the longer one shall prevail:",
	"components.xieyi.6hv739": "1. Complete the transaction objectives related to you, maintain corresponding transaction and business records to respond to your possible inquiries or complaints;",
	"components.xieyi.483k46": "2. Ensure the safety and quality of our services provided to you;",
	"components.xieyi.v5a774": "3. Do you agree to a longer retention period;",
	"components.xieyi.7g6607": "According to the relevant requirements of the statute of limitations for litigation;",
	"components.xieyi.8iszne": "5. Is there any other special agreement or legal regulation regarding the retention period.",
	"components.xieyi.2xiglt": "After the retention period expires, we will promptly process your personal information in accordance with relevant laws and regulations.",
	"components.xieyi.7rg77i": "In addition to following the operation path agreed upon in this policy, you can",
	"components.xieyi.t2esz7": "In addition to self operation on the platform, you can also access and manage your information through the following methods:",
	"components.xieyi.kb51e6": "（1） Search, correct, and supplement your information",
	"components.xieyi.nu7m3c": "You have the right to inquire, correct or supplement your information. You can log in by",
	"components.xieyi.bcd5d5": "On the platform, click on 'My Settings (upper right corner)' to enter the buyer center to check and correct personal information, personal account related information, account security, and shipping address.",
	"components.xieyi.043c4c": "（2） Delete your information",
	"components.xieyi.2d2n9p": "You can delete some of your information through the methods listed in '(1) Query, correct, and supplement your information'.",
	"components.xieyi.8n2d00": "In the following situations, you can communicate with",
	"components.xieyi.4zh20s": "The platform customer service has contacted us to request the deletion of personal information:",
	"components.xieyi.25t31b": "1. If our handling of personal information violates laws and regulations;",
	"components.xieyi.8tjokj": "2. If we collect and use your personal information without your explicit consent;",
	"components.xieyi.4m1259": "3. If our handling of personal information seriously violates our agreement with you;",
	"components.xieyi.q81de3": "4. If your account is cancelled.",
	"components.xieyi.xd43vl": "If we decide to respond to your deletion request, we will also notify the parties who have obtained your personal information from us as much as possible and require them to delete it in a timely manner (unless otherwise provided by laws and regulations, or if these parties have independently obtained your authorization).",
	"components.xieyi.1c21t6": "When you or we assist you in deleting relevant information, due to applicable laws and security technology limitations, we may not be able to immediately delete the corresponding information from the backup system. We will securely store your personal information and restrict any further processing until the backup can be cleared or anonymized.",
	"components.xieyi.43p4r8": "（3） Cancel your account",
	"components.xieyi.w2lnx4": "You can apply to cancel your account through the following methods:",
	"components.xieyi.d17ah0": "1. Login",
	"components.xieyi.113v7u": "Platform, submit an account cancellation application through 'My - Settings (upper right corner) - Account Security Center - Cancel Account';",
	"components.xieyi.390iqo": "2. Contact",
	"components.xieyi.n6o511": "Platform customer service, assist you in applying to cancel your account",
	"components.xieyi.g4i68q": "After you voluntarily cancel your account, we will stop providing you with products or services and delete your personal information or anonymize it in accordance with applicable laws.",
	"components.xieyi.i8g44k": "（4） Change the scope of your authorized consent",
	"components.xieyi.7162l2": "You can change or revoke your authorization to collect and process your personal information by:",
	"components.xieyi.83m5qy": "My",
	"components.xieyi.ih02m6": "'Settings Privacy System Permissions'",
	"components.xieyi.3aq776": "Manage your authorized applications in.",
	"components.xieyi.uyy3k2": "For authorizations that you cannot set directly through the above methods, you can modify them by contacting EPOCH EPT customer service or sending an email to jubao@weipaitang.com . We will respond to your withdrawal request within 15 working days. However, please note that for certain types of personal information, such as information necessary to implement the basic functions of EPOCH EPT or information necessary for us to fulfill our obligations under laws and regulations, we may not be able to respond to your request to change the scope of authorization.",
	"components.xieyi.38w831": "After you revoke your authorization, we will no longer process the corresponding personal information. But your decision to revoke the authorization will not affect our previous personal information processing based on your authorization.",
	"components.xieyi.o2932d": "If you do not want to accept the personalized commercial advertisement we send you, you can cancel it at any time through the following methods:",
	"components.xieyi.7l39m6": "'My EPOCH EPT - Settings - About EPOCH EPT - Personalization Settings'",
	"components.xieyi.uw6w84": "（5） Constrained Information System Automatic Decision Making",
	"components.xieyi.6ixh35": "In certain business functions, we may only make decisions based on non manual automatic decision-making mechanisms such as information systems and algorithms. If these decisions significantly affect your legitimate rights and interests, you have the right to demand an explanation from us, and we will also ensure that they do not infringe upon you",
	"components.xieyi.8i0uc6": "Provide appeal methods under the premise of trade secrets or other user rights and social public interests.",
	"components.xieyi.sace84": "（6） Personal Information Copy",
	"components.xieyi.iz38g1": "If you need a copy of your personal information, you can contact us through the feedback channels listed in 'How to Contact Us' in this policy. After verifying your identity, we will provide you with a copy of your personal information according to your request, except as otherwise provided by laws and regulations or this policy.",
	"components.xieyi.q0m5n8": "（7） In response to your above request",
	"components.xieyi.fqi536": "For the above requests made by you or your potential guardians, close relatives, and other authorized parties to us, as well as your relevant personal information rights stipulated by the laws of the People's Republic of China and other applicable laws, you can contact us through customer service or directly file a complaint with our personal information protection department. We will provide a response within 15 days.",
	"components.xieyi.a1304e": "To ensure the security of your account, when you make the above request to us, we may first request verification of your identity (such as requesting a written request or other means) before processing your request.",
	"components.xieyi.31h37g": "In the following situations, according to legal requirements, we will not be able to respond to your request:",
	"components.xieyi.1udf40": "1. To fulfill legal duties or obligations;",
	"components.xieyi.0ro7r7": "3. There is sufficient evidence to indicate that the personal information subject has subjective malice or abuse of rights;",
	"components.xieyi.70z43w": "4. Responding to your request will result in serious damage to your or other individuals or organizations' legitimate rights and interests;",
	"components.xieyi.335lfg": "5. Involving trade secrets;",
	"components.xieyi.1eb324": "6. Other circumstances stipulated by laws and administrative regulations.",
	"components.xieyi.e0vv42": "We assume that you have corresponding civil capacity in e-commerce activities. If you are a minor, we request that you ask your parents or other guardians to carefully read this policy and use our services or provide information to us with the consent of your parents or other guardians.",
	"components.xieyi.jp5623": "If you are a child under the age of 14, before using our services, you and your guardian should carefully read our specially formulated 'Children's Personal Information Protection Rules' to ensure that you obtain the guardian's consent",
	"components.xieyi.4637q9": "Children's Personal Information Protection Rules",
	"components.xieyi.b7i17n": "Only with your consent can you use our services under the guidance of your guardian to ensure your safety when using our services and conducting transactions.",
	"components.xieyi.63z56b": "If you are the parent or other guardian of a child, please pay attention to whether the child used our services after obtaining your authorized consent. If you have any questions about children's personal information, please contact our dedicated department for personal information protection.",
	"components.xieyi.064797": "Our services and technology are constantly updated and optimized to meet the needs of you and other users. Therefore, when new services and business processes change, we may update our privacy policy to inform you of specific changes. But without your explicit consent, we will not limit your rights under this policy. We will",
	"components.xieyi.7xys1n": "Specialized page",
	"components.xieyi.5ln786": "Publish any changes made to this policy.",
	"components.xieyi.1i9q62": "For significant changes, we will also provide more significant notifications (including through",
	"components.xieyi.dg46h5": "Notify through platform public announcement or provide you with pop-up prompts.",
	"components.xieyi.o3si3a": "The significant changes referred to in this policy include but are not limited to:",
	"components.xieyi.sntd36": "1. Our service model has undergone significant changes, such as the purpose of processing personal information, the type of personal information processed, and the way personal information is used;",
	"components.xieyi.x97b27": "2. We have undergone significant changes in control rights and other aspects, such as changes in information controllers caused by mergers and acquisitions;",
	"components.xieyi.hc7g60": "3. The main objects of personal information sharing, transfer, or public disclosure have changed;",
	"components.xieyi.4icom0": "4. There has been a significant change in your rights and exercise of personal information processing rights;",
	"components.xieyi.krv6jh": "5. Our responsible department for handling personal information protection, contact information, and complaint channels have changed;",
	"components.xieyi.t6x4vu": "6. The personal information security impact assessment report indicates a high risk.",
	"components.xieyi.oso4jc": "Company Name: Hangzhou EPOCH EPT Cultural and Creative Co., Ltd",
	"components.xieyi.0vvw2h": "Registered address: 7th floor, Chuangfeng Huaxing Business Building, No. 19 Huaxing Road, Xihu District, Hangzhou City, Zhejiang Province",
	"components.xieyi.4j9bb7": "If you have any questions, opinions or suggestions about this policy, you can contact us through the following methods, and we will reply to your request within 15 working days:",
	"components.xieyi.5mz349": "1. EPOCH EPT online customer service",
	"components.xieyi.py8lg2": "2. We have also established a dedicated department for personal information protection, and you can contact our personal information protection responsible person's email address shaktrysrtgscrtbslanderor5046@gmail.com .",
	"components.xieyi.nfphnk": "It should be noted that we may not respond to questions unrelated to this policy or user personal information protection.",
	"components.xieyi.6vag57": "If you are not satisfied with our response, especially if you believe that our personal information processing behavior has harmed your legitimate rights and interests,",
	"components.xieyi.iw8u70": "You can also seek a solution by filing a lawsuit with a court of competent jurisdiction in the location of the EPOCH EPT platform.",
	"components.xieyi.668kbh": "First release date: December 13, 2018",
	"components.xieyi.1hqds4": "Latest update date: March 30, 2023",
	"src.error.3if060": ">>>>>>Error message>>>>>>",
	"src.error.f78hx4": ">>>>>>Vue instance>>>>>>",
	"libs.axios.3m3ozo": "Server exception!",
	"libs.axios.u68235": "Request error!",
	"libs.axios.8pvp71": "You don't have permission!",
	"libs.axios.3e23l0": "Request address error",
	"libs.axios.ctl7o2": "Request timed out, please refresh and try again!",
	"libs.axios.7f90de": "Internal server error",
	"libs.axios.6r1ua2": "Service not implemented",
	"libs.axios.232in7": "Bad Gateway ",
	"libs.axios.ft8yo6": "Service unavailable",
	"libs.axios.1o3gds": "Gateway timeout, please refresh and try again!",
	"libs.axios.43w873": "HTTP version not supported",
	"goods.index.86p673": "buy",
	"goods.index.40o4np": "Purchase quantity",
	"goods.index.r257np": "Buy Now",
	"goods.index.6i5t88": "This product is temporarily unavailable for purchase!",
	"goods.index.263l56": "Please select a product",
	"goods.index.e5yso2": "Please choose a payment method!",
	"rewards.activityb.t04rn4": "Total commission",
	"rewards.activityb.r5174s": "Today's",
	"rewards.activityb.q4544h": "Yesterday's ",
	"rewards.activityb.uflar8": "direct referrals",
	"rewards.activityb.fsyaor": "commission",
	"rewards.activityb.xd17r7": "Indirect people",
	"trending.index copy 2.891396": "documentary",
	"trending.index copy 2.rc6wik": "Investment range:",
	"trending.index copy 2.y4r77n": "Quantitative cycle:",
	"trending.index copy 2.m2v371": "hour",
	"trending.index copy 2.5vyoay": "Quantitative benefits:",
	"trending.index copy 2.rpeid0": "Tracker Revenue $1185.65",
	"trending.index copy 2.g0677s": "Order size $15120184.00",
	"trending.index copy 2.8sgku2": "In custody",
	"trending.index copy 2.g2jni6": "Custody amount",
	"trending.index copy 2.39cp6b": "Custody period",
	"trending.index copy 2.050n02": "Daily earnings",
	"trending.index copy 2.380j4f": "Hosting start time",
	"trending.index copy 2.6en3x1": "End time of custody",
	"trending.index copy 2.v761ws": "Follow up period:",
	"trending.index copy 2.8gow40": "Follow up restrictions:",
	"trending.index copy 2.1ul440": "Minimum return:",
	"trending.index copy 2.7fk35c": "Maximum return:",
	"trending.index copy 2.3ys73k": "Available balance",
	"trending.index copy 2.f615yk": "Total price",
	"trending.index copy 2.11c26y": "Follow up immediately",
	"trending.index copy 2.ojxv4u": "whole",
	"trending.index copy 2.2v0ku3": "My order",
	"trending.index copy 2.9m6dh6": "Order placed successfully!",
	"trending.index copy 3.7v38ky": "Canceled",
	"trending.index copy 3.3ksfcn": "Pre order",
	"trending.index copy 3.u6n586": "To be settled",
	"trending.index copy 3.7d8jnh": "title",
	"trending.index copy 3.22f28b": "Are you sure you want to cancel the order?",
	"trending.index copy 3.plw663": "confirm",
	"trending.index copy 3.e4pcfx": "Canceled successfully!",
	"trending.index copy.voov3i": "Yield over the past 30 days",
	"trending.index copy.60fqu2": "Revenue in the past 30 days",
	"trending.index copy.38c2yt": "winning probability",
	"trending.touzi1.30c92u": "Order number",
	"trending.touzi1.w3r6h6": "money",
	"trending.touzi1.2q58dy": "state",
	"trending.touzi1.830j6d": "time",
	"wallet.index.ralw0t": "Please enter your phone number",
	"wallet.index.tme1u9": "telephone number",
	"wallet.index.h1wve2": "Please enter a phone number",
	"login.login.qnpe05": "Please enter phone number",
	"login.login.8za2m1": "Login password cannot be empty",
	"components.recharge.r79s4d": "Effective",
	"components.recharge.v93frd": "Processing",
	"wallet.index.bm205v": "The minimum amount cannot be less than 0",
	"components.recharge.h1w232": "Rebate in progress",
	"components.recharge.wx5ntw": "Rebate successful",
	"components.recharge.42t1o3": "Recharged successfully",
	"components.recharge.d03old": "Withdrawal failed",
	"components.recharge.cn2rpv": "Withdrawal successful"
}