module.exports = {
	"Language": {
		"title": "언어"
	},
	"Noproductsforsale": "No products for sale",
	"rechargePresentationText0_1": "Recharge 1000 reward  ",
	"rechargePresentationText0_2": ", Recharge 10000 reward ",
	"rechargePresentationText0_3": ", and so on... Click Me !",
	"rechargePresentationText0": "You will receive ",
	"rechargePresentationText1": "% of the recharge amount when you start the recharge now. The activity time is limited, and it will stop after the gift is over.",
	"rechargePresentationText2": "Reward End Time, CountDown !",
	"Online_Chat": "온라인 채팅",
	"gobindgoogle": "Google OTP를 비우십시오",
	"Order": "주문",
	"Record": "기록",
	"Assist": "지원",
	"Market": "시장",
	"History": "기록",
	"Confirm": "확인하다",
	"close": "닫다",
	"Cancel": "취소",
	"Previous": "이전의",
	"Next": "다음",
	"Copy": "복사",
	"Nomoredata": "더 이상 데이터가 없습니다",
	"moneychar": "₹",
	"DownloadApp": " 지금 앱 다운로드",
	"Yes": "예",
	"No": "아니요",
	"Wating": "웨이팅",
	"Recharge": "재충전",
	"Withdrawal": "철수",
	"Success": "성공",
	"OrderSuccessful": "주문 성공",
	"Handledataerror": "데이터 오류 처리",
	"Copysuccess": "복사 성공",
	"MyBalanceLoding": "마이밸런스로딩",
	"UPDATE": "업데이트",
	"New_version_available": "새 버전 사용 가능",
	"Please,update_app_to_new_version": "앱을 새 버전으로 업데이트하십시오",
	"tabbar": {
		"Home": "집",
		"AndarBahar": "Andar Bahar",
		"Invest": "투자하다",
		"Bonus": "보너스",
		"Mine": "내 거",
		"Trending": "트랜드",
		"Wallet": "지갑",
		"Rewards": "보상"
	},
	"homeTop": {
		"Sign_Up": "회원 가입",
		"Sign_up_for_rewards": "보상을 받으려면 가입하세요",
		"up_to": "최대",
		"TOTAL_ASSETS": "총 자산",
		"User_ID": "사용자 ID",
		"Registration_time": "등록 시간"
	},
	"PieChart": {
		"BIG&SMALL": "빅 & 스몰",
		"NUMBER": "숫자",
		"ODD&EVEN": "홀수 & 짝수",
		"ALL_NUMBER_TRENDING": "모든 숫자 트렌드",
		"BIG&SMALL_NUMBER_TRENDING": "빅 & 스몰 숫자 트렌드",
		"ODD&EVEN_NUMBER_TRENDING": "홀수 & 짝수 숫자 트렌드"
	},
	"register": {
		"tips_3": "받은 편지함에 코드를 받지 못한 경우 스팸 폴더를 확인하십시오.",
		"receiveCode": "인증 코드를 받지 못하셨나요?",
		"receiveCode_title": "인증 코드가 이메일로 전송되었습니다. 여러 차례 시도해도 인증 코드를 받지 못하셨다면, 다음을 시도해 보세요.",
		"receiveCode_text1": "1. 스팸 메일함/휴지통을 확인해 보세요.",
		"receiveCode_text2": "2. 이메일 주소가 정확한지 확인하세요.",
		"receiveCode_text3": "3. 메시지가 몇 분 지연될 수 있습니다. 20분 후에 다시 시도해 보세요.",
		"receiveCode_text4": "4. 이미 등록된 이메일 주소인 경우, 인증 코드를 보내지 않습니다. 로그인하거나 비밀번호를 재설정하세요.",
		"Sign_up_for_Rewards": "보상에 가입하기",
		"Email_Verification": "이메일 인증",
		"tips": "{{formData.email}}로 전송된 6자리 인증 코드를 입력하십시오. 코드의 유효 기간은 30분입니다.",
		"tips_1": "다음에 전송된 6 자리 인증 코드를 입력하십시오 ",
		"tips_2": "코드의 유효 기간은 30 분입니다.",
		"Resend_code_in": "에 다시 보내기 코드",
		"Sent_again": "다시 보내기",
		"CREATE_ACCOUNT": "계정 만들기",
		"inviteCodecheck": "inviteCode는 비어 있을 수 없습니다!",
		"Mobile": "휴대폰 번호",
		"Mobilemsg": "휴대폰 번호가 필요합니다",
		"Password": "비밀번호",
		"Passwordmsg": "비밀번호가 필요합니다",
		"OTPCode": "OTP 코드",
		"OTPCodemsg": "OTP 코드가 필요합니다",
		"SendOTP": "OTP 보내기",
		"Email": "이메일",
		"Emailmsg": "이메일이 필요합니다",
		"Invitecode": "초대 코드",
		"Invitecodemsg": "초대 코드가 필요합니다",
		"emailFormatter": "정확한 이메일 주소를 입력해주세요",
		"usernameFormatter": "사용자 이름은 문자와 숫자여야 하며 범위는 6<=length<=20입니다.",
		"phoneFormatter": "휴대폰 번호 10자리를 입력해주세요.",
		"msgsuccess": "가입 성공",
		"msgerr": "등록 실패",
		"errorphonecheck": "휴대폰 번호는 비워둘 수 없습니다!",
		"phonecheck": "휴대폰 번호 10자리를 입력해주세요.",
		"imgcodecheck": "OTP 코드는 비워둘 수 없습니다!"
	},
	"login": {
		"Welcome_back": "환영합니다",
		"tips": "본인은 {{this.$LogoName}}의 이용약관과 개인정보 처리방침에 동의합니다.",
		"tips_1": "본인은",
		"tips_2": "이용약관",
		"tips_3": "과",
		"tips_4": "개인정보 처리방침",
		"Continue_with_Google": "Google 계정으로 시작하기",
		"Continue_with_Apple": "Apple 계정으로 시작하기",
		"text": "아직 계정이 없으신가요?",
		"text2": "계정이 이미 있으신가요?",
		"Login": "로그인",
		"Register": "등록하다",
		"username": "사용자 이름 또는 전화번호",
		"password": "비밀번호",
		"imgcode": "이미지 코드",
		"BackHome": "집으로 돌아가",
		"Forgetpwd": "비밀번호 분실"
	},
	"forgot": {
		"title": "비밀번호 분실",
		"msgsuccess": "비밀번호 재설정 완료",
		"newpassword": "새 비밀번호",
		"newpasswordmsg": "새 암호가 필요합니다",
		"Continue": "계속하다"
	},
	"home": {
		"Revolutionize_Trading": "거래혁명",
		"Shape_the_Future": "미래를 선도하세요",
		"content0": "함께하는 새로운 가상화폐 거래 시대를 경험해보세요!",
		"3_Reasons_for_Choosing": "선택 이유 3가지",
		"Innovative_Trading_Features": "혁신적인 거래 기능",
		"content1": "현재 시장에서 이용 가능한 것과는 다른 혁신적인 거래 경험을 제공합니다. 혁신적인 거래 기능, 고급 보안 기능 및 초고속 거래 속도로 경쟁 우위를 확보할 수 있습니다.",
		"Lower_Fees_and_Better_Rewards": "낮은 수수료와 더 나은 보상",
		"content2": "초보자에게도 쉽게 거래를 할 수 있는 사용자 인터페이스입니다. 무료로 계정을 생성하고 마켓플레이스에서 적은 수수료로 가상화폐를 거래할 수 있습니다. 또한, 초대한 모든 사용자의 거래 수수료 최대 20%를 공유할 수 있습니다.",
		"Institutional-grade_Security": "기관급 보안",
		"content3": "{{this.$LogoNames.toUpperCase()}}는 업계에서 가장 높은 보안 기준 중 하나를 따르고 있습니다. 모든 가상화폐는 콜드 스토리지에 안전하게 보관됩니다. 고객 자금의 최소 1 대 1 보유 비율을 보장하며 3억 달러의 USDT 기금을 보유하여 보안 위협에 대한 추가적인 보호를 제공합니다.",
		"Start_investing_in_as_little_as_5_minutes": "5분 만에 투자를 시작하세요",
		"content4": "{{this.$LogoNames.toUpperCase()}}에 무료로 가입하세요",
		"content4_1": "",
		"content4_2": "에 무료로 가입하세요",
		"Complete_google_authentication": "구글 인증 완료",
		"content5": "이메일 주소를 입력하고 인증하여 시작하세요. 계정 개설 수수료는 없습니다.",
		"content7": "5분 이내에 구글 인증을 완료하여 모든 기능을 사용하세요.",
		"content8": "{{this.$LogoNames.toUpperCase()}} 계정에 즉시 자금을 입금하세요",
		"content8_1": "",
		"content8_2": " 계정에 즉시 자금을 입금하세요",
		"content9": "선택한 네트워크를 통해 USDT를 입금하면 즉시 자금을 사용할 수 있습니다. 거래를 시작하기 위해 3-5일을 기다릴 필요가 없습니다.",
		"Start_building_your_crypto_portfolio": "암호화폐 포트폴리오 구축 시작하기",
		"Invitation_Program": "초대 프로그램",
		"Referral_Program": "추천 프로그램",
		"content10": "유효한 초대를 완료하면 초대 보너스를 최대 10 USDT까지 받을 수 있습니다. 하나의 계정에 대한 최대 보너스는 ",
		"content11": "초대한 사용자가 생성하는 거래 볼륨에 비례하여 초대 보너스를 받을 수 있습니다. 유효한 초대에 대한 최대 보너스는 Unlimit USDT입니다.",
		"TRADING_TASKS": "거래 작업",
		"content12": "사용자들은 거래 시 USDT 보상을 받게 되며, 다른 거래 모드에 대한 다양한 거래 작업이 제공됩니다. 보상은 즉시 분배됩니다.",
		"Trade_anytime_anywhere": "언제 어디서나 거래하세요",
		"content13": "USA, Inc.은 뉴욕 주 금융서비스 부서에 의해 가상화폐 비즈니스 활동을 수행할 수 있는 라이선스를 받았습니다.",
		"USA_Inc": "USA, Inc",
		"content6": "저희 앱을 다운로드하여 24시간 연결 유지하세요",
		"Legal&Disclosures": "법률 및 공시",
		"Regulatory_License": "규제 라이선스",
		"AML/KYC_Policies": "AML/KYC 정책",
		"Privacy_Policy": "개인 정보 보호 정책",
		"Terms_of_Use": "이용 약관",
		"Legal_Statement": "법적 고지",
		"Risk_Disclosure": "리스크 공시",
		"About_Us": "회사 소개",
		"Proof_of_Reserves": "예치금 증빙",
		"Twitter": "트위터",
		"Bitget_Token": "Bitget 토큰",
		"Careers": "채용 정보",
		"Company": "회사",
		"Support_Service": "지원 서비스",
		"title": "앱으로 열기",
		"titlebtn": "다운로드",
		"Balance": "균형",
		"ID": "ID",
		"Recharge": "재충전",
		"Withdraw": "철회하다",
		"Bonus": "보너스",
		"Checkin": "체크인",
		"Eventclosed": "이벤트 종료",
		"Ranking": "순위"
	},
	"Ranking": {
		"Today_s winning ranking": "오늘의 승리 순위",
		"tips1": "당첨 금액이 클수록 순위가 높아집니다!",
		"UserName": "사용자 이름",
		"Winning Amount": "당첨 금액",
		"Today_s rebate ranking": "오늘의 리베이트 순위",
		"tips2": "낮은 레벨 사용자를 위한 더 높은 리베이트 = 더 높은 순위!",
		"Rebate Amount": "리베이트 금액",
		"Rebate ranking": "상위 20 리베이트 순위"
	},
	"Checkin": {
		"Checkin": "체크인",
		"CheckinList": "체크인 목록"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "규칙",
		"Record": "기록",
		"Records": "Andar Bahar 기록",
		"MyOrder": "내 주문",
		"more": "더",
		"Period": "기간",
		"Order": "주문하다",
		"Buy": "구입하다",
		"Result": "결과",
		"Profit": "이익",
		"AndarBaharRule": "Andar Bahar 규칙",
		"CountDown": "카운트다운",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "결과",
		"ok": "좋아요",
		"start": "시작",
		"Point": "가리키다",
		"ContractPoint": "계약 포인트",
		"Number": "숫자",
		"Delivery": "배달",
		"Fee": "요금",
		"Amount": "양"
	},
	"pokerOrder": {
		"OrderRecord": "주문 기록",
		"WatingOrder": "먹는 순서",
		"HistoryOrder": "역사 순서",
		"Nomoredata": "더 이상 데이터가 없습니다",
		"Buy": "구입하다",
		"Result": "결과",
		"Time": "시간",
		"Amount": "양",
		"Fee": "요금",
		"Profit": "이익"
	},
	"pokerRecord": {
		"title": "Andar Bahar 기록",
		"Period": "기간",
		"Result": "결과",
		"OpenTime": "영업시간",
		"Nomore": "더 이상은 없어"
	},
	"Google2FA": {
		"title": "구글 2FA 인증",
		"Authenticator_Instructions": "인증자 지침",
		"Authenticator_Status": "인증자 상태",
		"Google_Authenticator": "구글 OTP",
		"Download_Google_Authenticator_App": "Google OTP 앱 다운로드",
		"App_Store": "앱 스토어",
		"Google_Play": "구글 플레이",
		"Next": "다음",
		"Backup_key": "백업 키",
		"Backup_key_text": "이 백업 키를 안전한 위치에 저장하십시오. 이 키를 사용하면 휴대폰을 분실한 경우 인증기를 복구할 수 있습니다. 그렇지 않으면 인증기를 재설정하려면 고객 서비스에 문의해야 합니다.",
		"Verifiaction": "확인",
		"Verifiaction_text": "구글 인증코드 입력",
		"Google_verification_code": "구글 인증 코드",
		"contentlist1": "1. +this.$LogoName+의 16자리 백업 키를 기록하여 안전한 곳에 보관하십시오.",
		"contentlist1_1": "1.",
		"contentlist1_2": "의 16자리 백업 키를 기록하여 안전한 곳에 보관하십시오.",
		"contentlist2": "2.Google OTP를 엽니다.",
		"contentlist3": "3. +this.$LogoName+ 계정을 추가하고 복사한 16자리 키를 입력합니다.",
		"contentlist3_1": "3.",
		"contentlist3_2": "계정을 추가하고 복사한 16자리 키를 입력합니다.",
		"contentlist4": "4. 화면에 6자리 인증 코드가 표시됩니다.",
		"contentlist5": "5. 6자리 코드를 복사하여 +this.$LogoName+에 붙여넣습니다.",
		"contentlist5_1": "5. 6자리 코드를 복사하여",
		"contentlist5_2": "에 붙여넣습니다.",
		"contentlist6": "6. 그러면 인증기가 성공적으로 연결됩니다."
	},
	"win": {
		"Live_Price": "실시간 가격",
		"title1": "Binance BTC/USDT 스팟 거래의 마지막 자릿수에 따라 투자 결과가 계산됩니다. 투자자는 언제든지 Binance BTC/USDT 에서 결과를 확인하여 투자의 공정성과 보안을 보장할 수 있습니다.",
		"title": "투자에는 2가지 모드가 있습니다.",
		"1M_Mode": "라이트 1M",
		"1M_Mode_text": "각 라운드는 1분이 걸립니다. 라이트 모드는 홀수 및 짝수 숫자 투자만 제공합니다. 결과는 Binance BTC/USDT 마진 거래 1M 간격의 종가 결과의 마지막 자릿수에 따라 계산됩니다.",
		"5M_Mode": "프로 5M",
		"5M_Mode_text": "각 라운드는 5분이 소요됩니다. Pro 모드는 EPOCH EPT의 전체 투자 방법을 잠금 해제합니다. 결과는 Binance BTC/USDT 마진 거래 5M 간격의 마감 결과의 마지막 자릿수로 계산됩니다.",
		"5M_Mode_text_1": "각 라운드는 5분이 소요됩니다. Pro 모드는",
		"5M_Mode_text_2": " 의 전체 투자 방법을 잠금 해제합니다. 결과는 Binance BTC/USDT 마진 거래 5M 간격의 마감 결과의 마지막 자릿수로 계산됩니다.",
		"Investment_Guide": "투자 안내",
		"Investment_Rules": "투자 규칙",
		"title2": "결과가 공정하다는 것을 어떻게 증명합니까?",
		"Investment_Info": "투자 정보",
		"3M_Mode": "3M 모드",
		"3M_Mode_text": "각 라운드는 3 분이 소요됩니다. 결과는 Binance BTC/USDT 마진 거래 3M 간격의 종가 결과의 마지막 자릿수로 계산됩니다.",
		"Investment_return": "투자 수익",
		"Number": "숫자",
		"Profit_Invested_amount": "수익 : 투자 금액",
		"guide1": "시간 모드를 선택하세요.",
		"guide2": "투자 금액을 조정하세요.",
		"guide3": "투자하고자하는 숫자를 선택하세요.",
		"guide4": "포트폴리오를 확인하고 투자를 클릭하세요.",
		"NEXT": "다음",
		"Invest_Amount": "투자 금액",
		"Total": "합계",
		"INVEST": "투자",
		"Portfolio": "포트폴리오",
		"Invest": "투자",
		"BINANCE": "BINANCE",
		"Bitcoin_Price": "비트코인 가격",
		"InvestmentRecord": "투자 기록",
		"Best_Assistant": "최고의 조수",
		"last_100_rounds": "(마지막 100라운드)",
		"What_do_these_data_mean": "이 데이터는 무엇을 의미합니까",
		"Missing": "없어진",
		"Missing_text": "이는 이 숫자를 생성한 마지막 라운드 이후의 라운드 수를 의미합니다.",
		"Frequency": "빈도",
		"Frequency_text": "최근 100라운드에 나타나는 해당 숫자의 시간을 의미합니다.",
		"Max_Continued": "최대 계속",
		"Max_Continued_text": "최근 100라운드에서 해당 번호의 최대 연속 출연 기간을 의미합니다.",
		"Rule": "규칙",
		"Trend": "경향",
		"MyBalance": "내 잔액",
		"JoinGreen": "조인 그린",
		"JoinRed": "레드 가입",
		"JoinViolet": "바이올렛 가입",
		"Parity": "동등",
		"Sapre": "사프레",
		"Bcone": "비콘",
		"Emerd": "에메르드",
		"Record": "기록",
		"more": "더",
		"Period": "기간",
		"Price": "가격",
		"Result": "결과",
		"My": "나의",
		"Order": "주문하다",
		"Buy": "구입하다",
		"Profit": "이익",
		"Green": "녹색",
		"Red": "빨간색",
		"Violet": "제비꽃",
		"tips": "거래 수수료는 주문 금액의 {bet_fee*100}%입니다.",
		"tips1": "거래 수수료는 주문 금액의 ",
		"tips2": "입니다.",
		"OrderAmount": "주문량",
		"Quantity": "수량",
		"TransactionFee": "거래 수수료",
		"TotalAmount": "총액",
		"PresalePolicy": "사전 판매 정책",
		"Iagree": "나는 동의한다",
		"err1": "분양관리규정에 먼저 동의해 주세요"
	},
	"historyPeriods": {
		"Orderrecord": "주문 기록",
		"record": "기록"
	},
	"historyOrderPeriods": {
		"WatingOrder": "먹는 순서",
		"HistoryOrder": "역사 순서"
	},
	"Activity": {
		"added_to_account_balance": "계정 잔액에 추가됩니다.",
		"contentText": "초대받은 사람이 처음으로 예금을 완료하면 유효한 초대로 간주됩니다. 초대자는 각 유효한 초대에 대해 초대 보너스를 받게 됩니다. 초대 보너스는 1개의 유효한 초대에 대해 {{aaaaaa}} USDT에서 500개의 유효한 초대에 대해 {{aaaaaa}} USDT까지 있습니다.",
		"contentText_1": "초대받은 사람이 처음으로 예금을 완료하면 유효한 초대로 간주됩니다. 초대자는 각 유효한 초대에 대해 초대 보너스를 받게 됩니다. 초대 보너스는",
		"contentText_2": "1개의 유효한 초대에 대해",
		"contentText_3": "500개의 유효한 초대에 대해 {{aaaaaa}} USDT까지 있습니다.",
		"contentText_3_1": " ",
		"contentText_3_2": " 개의 유효한 초대에 대해 {{aaaaaa}} USDT까지 있습니다.",
		"contentText2": "초대자는 각 LV.1 초대자의 거래 금액에서 {{LV.1}}%의 거래 수수료를 공유합니다. 초대자는 각 LV.2 초대자의 거래 금액에서 {{LV.2}}%의 거래 수수료를 공유합니다. 초대자는 초대받은 사람의 거래 금액 보너스의 최대 한도가 무제한인 USDT를 받게됩니다.",
		"contentText2_1": "초대자는",
		"contentText2_2": "각 LV.1 초대자의 거래 금액에서 거래 수수료를 공유합니다. 초대자는",
		"contentText2_3": "각 LV.2 초대자의 거래 금액에서 거래 수수료를 공유합니다. 초대받은 사람의 거래 금액 보너스의 최대 한도는 무제한 USDT입니다.",
		"Invite_Friends_and_Earn_Up_to": "친구를 초대하고 최대",
		"Referral_Code": "추천 코드",
		"Referral_Link": "추천 링크",
		"INVITE_FRIENDS": "친구 초대",
		"Complete_tasks_to_earn_up_to": "작업 완료 후 최대 수입",
		"MY_REWARDS": "나의 보상",
		"SHARE": "공유",
		"Earn_with_your_friends_together": "친구와 함께 수입",
		"Get_your_link": "링크 가져오기",
		"Claim_your_rewards": "보상 청구",
		"Earn_together": "함께 수입",
		"contentlist0": "{{gogobit}}에 가입하여 추천 링크를 받으세요. 이 링크를 통해 가입한 사용자는 보상을 받게 됩니다.",
		"contentlist0_1": "가입하기 ",
		"contentlist0_2": " 및 추천 링크 받기. 이 링크를 통해 가입한 사용자는 보상을 받게 됩니다.",
		"contentlist1": "친구가 첫 입금을 하고 필요한 거래 금액을 완료하면 {{XX}} USDT의 평균 추천 보상을 받게 됩니다. 보상은 최대 {{XX}} USDT까지 가능합니다.",
		"contentlist1_1": "친구가 첫 입금을 하고 필요한 거래 금액을 완료하면 평균 {{XX}} USDT의 추천 보상을 받게 됩니다.",
		"contentlist1_2": " USDT의 추천 보상을 받게 됩니다. 친구가 첫 입금을 하고 필요한 거래 금액을 완료하면 보상은 최대 ",
		"contentlist1_3": " USDT까지 가능합니다.",
		"contentlist2": "친구가 계정을 만든 후 최대 5,050 USDT의 거래 보너스를 받을 수 있습니다.",
		"Referral_Rewards": "추천 보상",
		"Detail_Rules": "세부 규칙",
		"Invitation_Bonus": "초대 보너스",
		"Up_to": "최대",
		"content0": "유효한 초대를 완료하고 초대 보너스의 평균 가치를 최대 10 USDT까지 받으세요. 한 사람당 최대 보너스는 ",
		"Trading_Bonus": "거래 보너스",
		"content1": "초대자는 초대받은 사람이 생성한 거래량에 비례하는 보너스를 받을 수 있습니다. 유효한 초대의 최대 보너스는",
		"My_Referral_History": "내 추천 이력",
		"Total_Invitees": "전체 초대받은 사람",
		"Valid_Invitees": "유효한 초대받은 사람",
		"Cumulative_Invitees": "누적 초대받은 사람",
		"Trading_Volumn": "거래량",
		"Detail_Rules_title": "매일 888 USDT 럭키",
		"Detail_Rules_title1": "1. 하루 선물 선물 거래 데이터는 다음 날 계산됩니다.",
		"Detail_Rules_title2": "2. 하루 선물 거래 금액이 200,000 USDT 이상인 사용자는 888 USDT 상금을 추첨할 수 있는 기회를 한 번 얻습니다.",
		"CLAIM_NOW": "지금 청구",
		"INVEST_NOW": "지금 투자하기",
		"title": "보너스 지갑",
		"BonusBalance": "보너스 잔액",
		"Withdrawtobalance": "잔액으로 인출",
		"BonusList": "보너스 목록",
		"Bonuswithdrawtobalance": "잔액으로 보너스 인출",
		"WithdrawalAmount": "출금 금액",
		"Pleaseentertheamount": "금액을 입력하세요.",
		"err1": "인출 금액은 0이 될 수 없습니다.",
		"success": "전송 성공"
	},
	"Refer": {
		"title": "내 추천",
		"ReferTotalBonus": "추천 총 보너스",
		"InviteFriends": "친구를 초대",
		"Level1": "레벨 1",
		"Level2": "레벨 2",
		"TotalPeople": "총 인원",
		"Bonus": "보너스",
		"Myinvitationlink": "내 초대 링크",
		"Rebate_description": "리베이트 설명"
	},
	"Task": {
		"InviteBonusToday": "오늘 초대 보너스",
		"bonusrecord": "보너스 기록",
		"hint1": "1. 데이터는 10~30분 간격으로 업데이트 됩니다!",
		"hint2": "2. 최근 3일 이내 등록하고 오늘 충전 완료한 분만 카운트!",
		"hint2_1": "2. 최근 ",
		"hint2_2": " 일 이내 등록하고 오늘 충전 완료한 분만 카운트!",
		"hint3": "3. 오늘 최소 보증금 20u 필요!",
		"hint3_1": "3. 오늘 최소 보증금 ",
		"hint3_2": " 필요!",
		"hint4": "지난 3일 동안 초대된 사람 수",
		"hint4_1": "지난  ",
		"hint4_2": " 일 동안 초대된 사람 수",
		"hint5": "오늘 충전 완료 인원",
		"Invite": "초대하다",
		"friendstorecharge": "재충전하는 친구들",
		"Earn": "벌다",
		"Completed": "완전한",
		"ExpireTime": "만료 시간"
	},
	"Invited": {
		"title": "보너스 기록",
		"InviteTotalBonus": "초대 총 보너스",
		"InviteTime": "초대 시간",
		"Bonus": "보너스",
		"NoRecored": "어떤 기록도 없다"
	},
	"mine": {
		"JOIN US": "우리와 함께",
		"title": "개인 센터",
		"InviteCode": "코드 초대",
		"AccountBalance": "계정 잔액",
		"BonusBalance": "보너스 잔액",
		"Wallet": "지갑",
		"Bank": "은행",
		"Invite": "초대하다",
		"Refer": "나타내다",
		"CustomerService": "고객 서비스",
		"ComplaintsSuggestions": "불만 및 제안",
		"ChangePassword": "비밀번호 변경",
		"Activity": "활동",
		"PresalePolicy": "사전 판매 정책",
		"DownloadApp": "앱 다운로드",
		"AppVersion": "앱 버전",
		"Logout": "로그 아웃",
		"Logouttips": "로그아웃하시겠습니까?"
	},
	"App": {
		"title": "앱 다운로드",
		"DownloadforAndroid": "안드로이드용 다운로드",
		"DownloadforiOS": "iOS용 다운로드"
	},
	"Presale": {
		"title": "사전 판매 정책"
	},
	"Account": {
		"title2": "새 암호 설정",
		"title2_text": "Google 계정에 대한 새 암호를 설정하십시오. 암호를 사용하여 Google 계정에 로그인할 수 있습니다.",
		"title_text": "비밀번호를 재설정하려고 합니다. Google 인증자를 사용하여 재설정을 준비하십시오.",
		"err1": "입력한 두 개의 새 비밀번호가 다릅니다",
		"success2": "비밀번호가 성공적으로 변경되었습니다",
		"title": "비밀번호 변경",
		"OldPassword": "기존 비밀번호",
		"OldPasswordmsg": "이전 암호가 필요합니다",
		"NewPassword": "새 비밀번호",
		"NewPasswordmsg": "새 암호가 필요합니다",
		"RetypeNewPassword": "새 비밀번호를 다시 입력하세요",
		"RetypeNewPasswordmsg": "새 비밀번호를 다시 입력하세요",
		"Continue": "계속하다"
	},
	"Service": {
		"title": "서비스",
		"CONTACTUS": "문의하기",
		"tips": "고객센터 휴무입니다!",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "링크"
	},
	"Bank": {
		"BankName": "은행 이름",
		"ChooseaCurrencyType": "통화 유형 선택",
		"SelectWithdrawalMethod": "출금 방법 선택",
		"Newbankcard": "새 은행 카드",
		"Editbankcard": "은행 카드 수정",
		"title": "내 은행",
		"Cannotbeempty": "비워 둘 수 없습니다.",
		"BankHolderName": "은행 소유주 이름",
		"BankAccountNumber": "은행 계좌 번호",
		"IFSCCode": "IFSC 코드",
		"Email": "이메일",
		"Phone": "핸드폰",
		"OTPCode": "OTP 코드",
		"tips1": "1. OTP를 받으려면 등록한 휴대폰 번호를 사용하세요.",
		"tips2": "2. 자금의 안전을 위해 은행 카드는 하루에 한 번만 수정할 수 있습니다.",
		"Add": "추가하다",
		"error1": "은행 소유자 이름은 비워둘 수 없습니다!",
		"error2": "올바른 은행 소유자 이름을 입력하십시오!",
		"error3": "은행 이름은 비워둘 수 없습니다!",
		"error4": "올바른 은행 이름을 입력하세요!",
		"error5": "은행 계좌 번호는 비워둘 수 없습니다!",
		"error6": "올바른 은행 계좌 번호를 입력하세요!",
		"error7": "IFSC 코드는 비워둘 수 없습니다!",
		"error8": "올바른 IFSC 코드를 입력하십시오!",
		"error9": "이메일은 비워둘 수 없습니다!",
		"error10": "정확한 이메일 주소를 입력해주세요!",
		"error11": "전화는 비워 둘 수 없습니다!",
		"error12": "OTP 코드는 비워둘 수 없습니다!",
		"Successfully": "은행 카드를 추가했습니다."
	},
	"wallet": {
		"title": "내 지갑",
		"MyBalance": "내 잔액",
		"MyBank": "내 은행",
		"RechargeRecords": "충전 기록",
		"WithdrawalRecords": "출금기록"
	},
	"Rechargepage": {
		"Reminder": "알림",
		"Reminder_1": "1. 해당 주소로는 USDT 이외의 다른 코인 또는 토큰을 보내지 마십시오. 지원되지 않는 코인이나 토큰을 이 주소로 보내는 것은 복구할 수 없는 손실을 초래할 수 있습니다.",
		"Reminder_2": "2. 최소 입금 금액은 5 USDT입니다.",
		"Reminder_2_1": "2. 최소 입금 금액은 ",
		"Reminder_2_2": " USDT입니다.",
		"Reminder_3": "3. 입금은 15개의 네트워크 확인 후 도착합니다.",
		"Minimum": "최소",
		"title": "재충전",
		"History": "역사",
		"Mybalance": "내 잔액",
		"RechargeAmount": "충전 금액",
		"RechargeAmountmsg": "금액을 입력하세요.",
		"tips1": "결제 채널 점검 중입니다!",
		"tips2": "결제 채널을 선택하세요.",
		"PayNow": "지금 지불하세요",
		"err1": "결제 채널 점검 중입니다.",
		"err2": "금액은 다음보다 작을 수 없습니다. ",
		"err3": "결제 채널을 선택하세요.",
		"Rechargemethod": "충전 방법"
	},
	"Rechargerecord": {
		"title": "충전 기록",
		"WatingOrder": "대기 순서",
		"HistoryOrder": "역사 순서",
		"Rupee": "달러",
		"Status": "상태",
		"Time": "시간"
	},
	"withdrawal": {
		"Tips": "팁",
		"Tips_1": "팁: ",
		"Tips_2": " USDT 이하의 인출 수수료는 ",
		"Tips_3": " USDT, ",
		"Tips_4": " USDT 이상의 인출 수수료는 ",
		"Tips_5": "%입니다.",
		"WalletAddress": "지갑 주소",
		"Addwalletaddress": "지갑 주소 추가",
		"Network_fee": "네트워크 수수료",
		"Receive_amount": "수령 금액",
		"Select Chains": "체인 선택",
		"Bankcard": "은행 카드",
		"Nobankcardselected": "추가된 은행 카드 없음",
		"Withdrawalfee": "인출 수수료",
		"title": "철수",
		"Mybalance": "내 잔액",
		"Withdrawablebalance": "출금 잔액",
		"tips1": "구매 총액은 {{codeAmount}} 이상이어야 전액 출금 가능",
		"tips1-1": "구매 총액은",
		"tips1-2": "이상이어야 전액 출금 가능",
		"tips2": "일일 출금은 {{draw_num}}회입니다.",
		"tips2-1": "일일 출금은",
		"tips2-2": "회입니다.",
		"tips3": "일일 최대 인출 금액은",
		"tips4": "최소 단일 인출 금액은 ₹{{draw_min_money}}이고 최대 단일 인출 금액은 ₹{{draw_max_money}}입니다.",
		"tips4-1": "최소 단일 인출 금액은",
		"tips4-2": "이고 최대 단일 인출 금액은",
		"tips5": "출금시간은 {{draw_time}}이며, 지정된 시간에 출금해주세요.",
		"tips5-1": "v",
		"tips5-2": "이며, 지정된 시간에 출금해주세요.",
		"tips6": "현금 인출 시 현금 인출 수수료의 {{draw_fee}}% 차감",
		"tips6-1": "현금 인출 시 현금 인출 수수료의",
		"tips6-2": "차감",
		"tips7": "출금 금액이 ₹{{solid_min_amount}} 이하인 경우 ₹{{solid_feemoney}}의 고정 수수료가 부과됩니다.",
		"tips7-1": "출금 금액이",
		"tips7-2": "이하인 경우",
		"tips7-3": "의 고정 수수료가 부과됩니다.",
		"WithdrawalAmount": "출금 금액",
		"WithdrawalAmountmsg": "금액을 입력하세요.",
		"Withdrawal": "철수",
		"WithdrawalHistory": "출금 내역",
		"Tipsmsg": "출금 신청이 성공적으로 완료되어 감사 결과를 기다리고 있습니다!",
		"dangerMsg1": "먼저 은행 카드를 추가하세요.",
		"dangerMsg2": "인출 금액은 다음보다 작을 수 없습니다.",
		"dangerMsg3": "출금 금액은 100의 배수여야 합니다.",
		"dangerMsg4": "인출 금액은 다음보다 클 수 없습니다.",
		"dangerMsg5": "인출 금액은 인출 가능 잔액보다 클 수 없습니다.",
		"dangerMsg6": "먼저 은행 카드를 추가하세요."
	},
	"withdrawalrecord": {
		"title": "인출 기록",
		"WatingOrder": "대기 순서",
		"HistoryOrder": "역사 순서",
		"Rupee": "달러",
		"Status": "상태",
		"Time": "시간",
		"Nomore": "더 이상은 없어!"
	},
	"Version": {
		"title": "버전",
		"stableversion": "안정적인 버전",
		"Alreadythelatestversion": "이미 최신 버전",
		"Newversionfound": "새 버전 발견",
		"DownloadUpdate": "업데이트 다운로드"
	},
	"Bonus": {
		"title": "보너스 활동",
		"ReceiveBonus": "보너스 받기",
		"Hi": "안녕",
		"tips1": "보너스 받으신거 축하드려요",
		"tips2": "이상을 재충전해야 합니다.",
		"tips3": "그러면 보너스가 계정 잔액에 자동으로 추가됩니다.",
		"Received": "받았다",
		"Back": "뒤쪽에",
		"Rechargenow": "지금 충전하세요"
	},
	"ComplaintsSuggestions": {
		"title": "불만 및 제안",
		"Answered": "대답했다",
		"AddNew": "새로운 걸 더하다",
		"Time": "시간",
		"Type": "유형",
		"WhatsApp": "WhatsApp",
		"Description": "설명",
		"Remark": "주목",
		"Waiting": "대기 중",
		"Suggestion": "제안",
		"Consult": "찾다",
		"RechargeProblem": "충전 문제",
		"WithdrawProblem": "인출 문제",
		"OrderProblem": "주문 문제",
		"Other": "다른",
		"Describeyourproblems": "문제를 설명하십시오",
		"WhatsApptocontactyou": "연락할 WhatsApp",
		"submit": "제출하다",
		"err1": "WhatsApp은 비워 둘 수 없습니다!",
		"err2": "유형은 비워둘 수 없습니다!",
		"err3": "설명은 비워 둘 수 없습니다!"
	},
	"slotMachine": {
		"title": "과일 기계",
		"Myblance": "내 잔액",
		"Records": "과일 기계 기록",
		"titleRecord": "기록"
	},
	"bankrecharge": {
		"Orderamount": "주문량",
		"Ordernumber": "주문 번호",
		"BankName": "은행 이름",
		"BankAccount": "은행 계좌",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "은행 카드 번호",
		"Enterthebanktransactionordernumber": "은행 거래 주문 번호를 입력하세요.",
		"submit": "제출하다",
		"Tips": "팁",
		"tips1": "1. 시스템이 주문을 처리하고 있습니다.",
		"tips2": "2. 계좌가 장기간 도착하지 않을 경우 고객 서비스에 문의하십시오. ",
		"err1": "은행 카드 번호를 입력하세요.",
		"err2": "은행 주문 번호를 입력하세요."
	},
	"javaCode": {
		"200": "성공",
		"20001": "invalid access token",
		"20002": "invalid login token",
		"20003": "Phone error",
		"20004": "Phone has exists!",
		"20005": "Email error!",
		"20006": "Email exists!",
		"20007": "Password error!",
		"20010": "account or pwd error!",
		"20011": "bank account error!",
		"20012": "err bank ifsc, (standard IFSC format) - length 11, first four IFSC and 5th 0!",
		"20013": "beneficiary name error, 5 < name <= 50!",
		"20021": "Account not exists!",
		"20022": "Account is freeze!",
		"20031": "The layer is limit 10 !",
		"20032": "update relation role error !",
		"20033": "not enough balance!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Register Error!",
		"200082": "account has disable!",
		"200091": "add card is limit 10!",
		"200092": "input error login pwd too many times, and the account is disable!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "서버 오류!",
		"Handledataerror": "로그인 해주세요!",
		"-91": "서버 오류!",
		"-92": "데이터 오류 처리!",
		"-1": "시스템 오류!",
		"-2": "매개변수 오류",
		"-3": "데이터 없음",
		"-4": "짧은 시간에 빈번한 작동",
		"-5": "시스템이 사용 중입니다. 나중에 다시 시도하십시오!",
		"-6": " 현재 많은 사람들이 있습니다. 다시 시도하십시오!",
		"-7": "데이터 오류!",
		"-8": "Google code error!",
		"-9": "System is being maintained! please try again later !",
		"-10": "save error!",
		"-11": "delete error!",
		"-12": "update error!",
		"-13": "Ilegal operation!",
		"-14": "Operation fail!",
		"-15": "Operation disabled!",
		"-16": "Operation Finished!",
		"-17": "Exists error",
		"-18": "Not exists!",
		"-19": "expires",
		"-20": "Has disabled",
		"-21": "err verify code",
		"-22": "The modification interval must be greater than 1 day",
		"-23": "The new password cannot be the same as the original password",
		"-100": "custom error!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Total Purchase Amount",
		"TotalRevenue": "Total Revenue",
		"AccountBalance": "Account Balance",
		"Income": "Income",
		"Transaction": "Transaction",
		"Myhold": "My hold",
		"ProductList": "Product List",
		"Investmentperiod": "Investment period",
		"days": "days",
		"Buynow": "Buy now",
		"Saletime": "Sale time",
		"Nomore": "No more",
		"ProductDetails": "Product Details",
		"ForSale": "For Sale",
		"Sale": "Sale",
		"SoldOut": "Sold Out",
		"ProductInformation": "Product Information",
		"InvestmentPeriod": "Investment Period",
		"TotalShare": "Total Share",
		"ExpectedReturn": "Expected Return",
		"SaleTime": "SaleTime",
		"PurchaseConditionsAndRules": "Purchase Conditions And Rules",
		"MinimumPurchaseAmount": "Minimum Purchase Amount",
		"MaximumSalesLimit": "Maximum Sales Limit",
		"AccountBalanceMustBeGreaterThan": "Account Balance Must Be Greater Than",
		"DayBetIsGreaterThan": "Day Bet Is Greater Than",
		"ProductDescription": "Product Description",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"Maturity": "Maturity",
		"EarlyRedemption": "Early Redemption",
		"Redemptiontime": "Redemption time",
		"Amountofincome": "Amount of income",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "돕다",
		"HelpCenter": "지원 센터",
		"text": "질문이 있으시면 여기를 보세요"
	},
	"SetingHa": {
		"Seting": "설정",
		"SetingCenter": "설정 센터",
		"text": "더 많은 보상 받기"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"stop_investing": "투자를 중지하다",
	"register.checkerr": "암호: 8자리 이상의 문자 (문자 및 숫자 조합) 사용",
	"home.Products&Services": "제품 및 서비스",
	"home.Protection_Fund": "보호 기금",
	"Activity.My_Rewards_History": "나의 보상 역사",
	"Activity.Cumulative_Single_Number_Trading_Volumn": "누적 홀수 거래량",
	"Activity.Cumulative_ODD_Number_Trading_Volumn": "누적 홀수 거래량",
	"Activity.Cumulative_EVEN_Number_Trading_Volumn": "누적 쌍수 거래량",
	"Activity.Cumulative_BIG_Number_Trading_Volumn": "누적 대수 거래량",
	"Activity.Cumulative_SMALL_Number_Trading_Volumn": "누적 소수 거래량",
	"Activity.Advanced_Tasks": "진급 임무",
	"Activity.content0_1": "모든 유효한 초대를 완료하면 최대 평균 가치 제공",
	"Activity.content0_2": "초청자 100명의 최고 상금은",
	"Activity.Daily_Tasks": "일일 작업",
	"mine.ProvacyPolicy": "개인 정보 보호 정책",
	"Rechargepage.Tips": "훈훈한 힌트: 첫 충전 보상은 충전금액의 {{firstRPR*100}}%",
	"Rechargepage.Tips_1": "훈훈한 힌트: 첫 충전 보상은 충전 금액의",
	"Rechargepage.Tips_2": "% .",
	"withdrawal.Google_Code": "Google 인증 코드",
	"index.index.gayb21": "등록 획득 보상:",
	"index.index.72y628": "세 가지 선택 이유. 고고빗.",
	"index.index.z6022y": "EPOCH EPT은 업계에서 가장 높은 보안 기준 중 하나를 따릅니다.우리는 100% 의 암호화폐를 냉동고에 저장한다.우리는 적어도 고객 자금에 1: 1의 비축 비율을 제공할 것을 보장합니다.3억 달러의 기금은 보안 위협에 대한 추가적인 보호를 제공합니다.",
	"index.index.574982": "1. 지금 EPOCH EPT 무료 등록",
	"index.index.qdi1g4": "2. 구글 검증 완료",
	"index.index.weko28": "3. EPOCH EPT 계정에 즉시 충전",
	"index.index.sgkb35": "EPOCH EPT 초대 프로그램",
	"index.index.2u17nf": "유효한 초대를 완료하면 평균 5-15 USDT의 가치를 얻을 수 있습니다.초청자 100명의 최고 상금은",
	"index.index.e8is4a": "고고빗 추천 프로젝트.",
	"index.index.47uevl": "EPOCH EPT 거래 임무",
	"index.index.2516gq": "EPOCH EPT, Inc.는 뉴욕 주 금융 서비스 부서로부터 가상화폐 사업 활동 라이센스를 받았습니다.",
	"invest.index.3aqi3d": "계정 잔액:",
	"invest.index.ubk1j4": "투자 중단!",
	"invest.prize1.4733u6": "포트폴리오:",
	"invest.prize1.t55a16": "총액:",
	"login.login.09m52q": "EPOCH EPT을 읽고 동의합니다.",
	"login.login.045csy": "사용자 이름",
	"login.login.r7rbm5": "협의에 동의하십시오!",
	"rewards.activityb.4nvrq9": "초대받은 사람이 첫 번째 예금을 완료하면 유효한 초대로 간주됩니다.초대인은 각 유효한 초대에 대한 초대 보너스를 받게 됩니다.초대 보너스 레벨은 2USDT 1회 유효 초대 559USDT 100 유효 초대에서 가져옵니다.",
	"rewards.activityb.t10k6e": "초대자는 각 LV.1 초대자 거래 수수료의 20%% 거래 수수료를 공유합니다.초대자는 각 LV.2 초대자 거래 수수료의 10%% 거래 수수료를 공유합니다.초청자 거래 수수료 인센티브의 최대 상금은 무제한 달러다.",
	"rewards.activityb.3d6850": "LV.1 유효 초대 인원",
	"rewards.activityb.lvxo74": "LV.2 유효 초대 인원",
	"rewards.activityb.zj26f9": "LV.1 누적 초대 인원",
	"rewards.activityb.dkgxr5": "LV.2 누적 초대 인원",
	"rewards.activityb.xjo077": "추천 보상:",
	"rewards.activityb.k8wv6v": "EPOCH EPT에 가입하고 추천 링크를 받으십시오.이 링크를 통해 등록한 사용자는 보상을 받을 수 있습니다.",
	"rewards.activityb.4slpzg": "친구가 첫 예금을 완료하고 필요한 거래량을 완료하면 평균 5-15달러의 추천 보상을 받게 됩니다.보상은 최대 559달러입니다.",
	"rewards.index.993jwg": "복제 성공",
	"rewards.index.7141ft": "이 브라우저는 자동 복사를 지원하지 않습니다.",
	"wallet.index.9uz2gn": "네트워크 수수료 0 USDT",
	"wallet.index.ych3e1": "실제 입금:",
	"wallet.index.3v558d": "제시: 인출금이 20달러보다 낮고 인출 수수료는 1달러, 인출 20달러 이상, 인출 수수료는 3%입니다.",
	"wallet.index.255e30": "1. 일일 최대 인출 금액 8000000달러",
	"wallet.index.3g68m8": "2. 최소 1회 인출 금액은 5달러, 최대 1회 인출 금액은 8000000달러",
	"wallet.index.c6x3ti": "3. 인출 시간은 00: 00-23: 59입니다. 지정된 시간 내에 인출하십시오.",
	"wallet.index.h94rlg": "최소 한도액 5",
	"wallet.index.167862": "지갑 주소를 입력하세요!",
	"wallet.index.pi869h": "현금 인출 금액을 입력하세요!",
	"wallet.index.ysa85i": "최대 1회 인출 금액은",
	"components.changepass.68j9kh": "암호를 재설정하려고 시도하고 있습니다.",
	"components.changepass.ouxtp2": "원래 암호",
	"components.languagebox.8no849": "일본어",
	"components.languagebox.oif20b": "중국어 번체",
	"components.mycode.xyqr66": "작업을 완료하면 최대 5000 USDT",
	"components.mymenu.277ss7": "사용자 ID:",
	"components.mymenu.74l443": "등록 시간:",
	"components.myposter.36rw5e": "추천 코드:",
	"components.mytabbar.zw2bh2": "첫 페이지",
	"components.mytabbar.8x6c28": "추세",
	"components.mytabbar.28s7qr": "투자",
	"components.mytabbar.4r75d9": "보상",
	"components.xieyi.zqk7pe": "font-size: 16px; font-family: 송체;",
	"components.help.6gpud8": "투자에는 두 가지 모델이 있습니다.",
	"components.help.5qrq36": ": 회당 1 분 동안 지속되며 단일 듀얼 투자만 제공됩니다.결과는 Binance BTC/USDT 현물 거래 1 분 K라인 종가의 마지막 숫자로 계산됩니다.",
	"components.help.isl7r1": ": 매회 5 분 지속.Professional은 EPOCH EPT의 모든 투자 방법을 잠금 해제합니다.결과는 Binance BTC/USDT 현물 거래 5 분 K라인 종가의 마지막 숫자로 계산됩니다.",
	"components.help.eai3nm": "투자 수익:",
	"components.help.35xg82": "Single 숫자:",
	"components.help.mpfg0s": "수익: 투자 금액 X 9.4",
	"components.help.v2ziib": "Odd 숫자:",
	"components.help.vfzxor": "수익: 투자 금액 X 1.94",
	"components.help.73168b": "Even 숫자:",
	"components.help.76wgv1": "Big 숫자:",
	"components.help.05ed33": "Small 숫자:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "원래 암호를 입력하십시오!",
	"components.changepass.xme6my": "새 암호를 입력하십시오!",
	"components.changepass.u434f8": "두 번의 비밀번호가 일치하지 않습니다!",
	"components.changepass.574790": "수정 성공!",
	"invest.index.9y8wg0": "미수상",
	"invest.index.ql5nc1": "수상",
	"login.login.76451t": "거래소 차익 A 양적 거래!",
	"login.login.915p36": "재테크 세계 1위",
	"login.login.sen863": "Hello 로그인 후 더욱 흥미진진",
	"login.login.nz6q9x": "전화 로그인",
	"login.login.w7p592": "메일박스 로그인",
	"login.login.g9y91k": "핸드폰 번호",
	"login.login.456gbb": "메일박스 번호",
	"login.login.5ly8m8": "국가",
	"login.login.69h472": "국가를 선택하세요",
	"login.login.i4p378": "번호",
	"login.login.fl82xx": "예:",
	"wallet.index.urkoub": "충전 QR코드",
	"wallet.index.25cocl": "주소 복사",
	"wallet.index.t35fvg": "1.인출 시간: 주 1 ~ 토요일 24 시간 내에 일요일에 지역세를 계산하세요!",
	"wallet.index.68xx61": "2.전 세계 자국 화폐 인출 늦어도 48시간 이내에 입금, 인출 수수료 8% (지역세 포함).",
	"wallet.index.4ws3w9": "3. USDT 출금 늦어도 24시간 입금, 수수료 2% (지역세 포함)",
	"wallet.index.9b7wq8": "4.최저 인출 12USDT, 최고 1회 인출 금액 3000USDT.",
	"wallet.index.23y92a": "알아두세요",
	"wallet.index.pl5473": "최소 한도액 5",
	"wallet.index.nb3u72": "은행 카드 정보",
	"wallet.index.842so4": "은행카드 번호 추가",
	"wallet.index.86j717": "소속 은행 추가",
	"wallet.index.763d7x": "카드 이름 추가",
	"wallet.index.50j237": "소속 은행",
	"wallet.index.hb6lun": "은행 카드 이름",
	"wallet.index.62y2jc": "은행카드 번호",
	"login.register.666hp4": "Al 가격 차이 계량화 거래",
	"login.register.9298b8": "친구 초대",
	"login.register.375g14": "신인 최초 가입",
	"login.register.01922f": "1회 진행 가능",
	"login.register.527o4q": "일화 수익 AI",
	"login.register.c563gt": "메일박스 등록",
	"login.register.2ls856": "핸드폰 번호 형식이 잘못되었습니다!",
	"login.register.4nu77e": "메일박스 번호 형식이 잘못되었습니다!",
	"lang.zh.pu623z": "유효한 초대 1회",
	"lang.zh.3hv80b": "USDT 충전!",
	"lang.zh.9tli5f": "팁: 낮은 인출",
	"lang.zh.62e882": "달러, 인출 수수료는",
	"lang.zh.m3818q": "달러, 인출",
	"lang.zh.xs2f10": "미리 환매하면 아무런 수익도 생기지 않는다"
}