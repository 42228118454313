module.exports = {
	"Language": {
		"title": "Lingua"
	},
	"Noproductsforsale": "Nessun prodotto in vendita",
	"rechargePresentationText0_1": "Ricarica 1000, premio ",
	"rechargePresentationText0_2": ", Ricarica 10000, premio ",
	"rechargePresentationText0_3": ", e così via... Clicca qui!",
	"rechargePresentationText0": "Riceverai il ",
	"rechargePresentationText1": "% dell'importo della ricarica quando inizi la ricarica ora. L'attività è limitata nel tempo e terminerà dopo la scadenza del premio.",
	"rechargePresentationText2": "Fine del premio, Countdown!",
	"Online_Chat": "Chat online",
	"gobindgoogle": "Per favore, associa il tuo autenticatore Google",
	"Order": "Ordine",
	"Record": "Registro",
	"Assist": "Assistenza",
	"Market": "Mercato",
	"History": "Storia",
	"Confirm": "Conferma",
	"close": "Chiudi",
	"Cancel": "Annulla",
	"Previous": "Precedente",
	"Next": "Successivo",
	"Copy": "Copia",
	"Nomoredata": "Nessun dato disponibile",
	"moneychar": "₹",
	"DownloadApp": "Scarica l'app ora",
	"Yes": "Sì",
	"No": "No",
	"Wating": "in attesa",
	"Recharge": "Ricarica",
	"Withdrawal": "Prelievo",
	"Success": "Successo",
	"stop_investing": "smetti di investire",
	"OrderSuccessful": "Ordine effettuato con successo",
	"Handledataerror": "Errore nell'elaborazione dei dati",
	"Copysuccess": "Copia riuscita",
	"MyBalanceLoding": "Il mio saldo sta caricando",
	"UPDATE": "AGGIORNAMENTO",
	"New_version_available": "Nuova versione disponibile",
	"Please,update_app_to_new_version": "Aggiorna l'app alla nuova versione",
	"tabbar": {
		"Home": "Home",
		"AndarBahar": "Andar Bahar",
		"Invest": "Invest",
		"Bonus": "Bonus",
		"Mine": "Mio Portafoglio",
		"Trending": "Tendenze",
		"Wallet": "Portafoglio",
		"Rewards": "Ricompense"
	},
	"homeTop": {
		"Sign_Up": "Registrati",
		"Sign_up_for_rewards": "Registrati per ottenere ricompense",
		"up_to": "fino a",
		"TOTAL_ASSETS": "TOTALE ATTIVI",
		"User_ID": "ID utente",
		"Registration_time": "Data di registrazione"
	},
	"PieChart": {
		"BIG&SMALL": "GRANDE&PICCOLO",
		"NUMBER": "NUMERO",
		"ODD&EVEN": "DISPARI&PARI",
		"ALL_NUMBER_TRENDING": "TUTTI I NUMERI IN TENDENZA",
		"BIG&SMALL_NUMBER_TRENDING": "GRANDI E PICCOLI NUMERI IN TENDENZA",
		"ODD&EVEN_NUMBER_TRENDING": "DISPARI E PARI NUMERI IN TENDENZA"
	},
	"register": {
		"checkerr": "Password: utilizzare 8 o più caratteri (una combinazione di lettere e numeri)",
		"tips_3": "Se non hai ricevuto il codice nella tua casella di posta in arrivo, controlla la cartella spam.",
		"receiveCode": "Non hai ricevuto il codice?",
		"receiveCode_title": "Codice inviato al tuo indirizzo email. Se non hai ricevuto il codice dopo diversi tentativi, prova:",
		"receiveCode_text1": "1. Controlla se si trova nella tua cartella posta indesiderata.",
		"receiveCode_text2": "2. Assicurati che il tuo indirizzo email sia corretto.",
		"receiveCode_text3": "3. Il messaggio potrebbe essere in ritardo di alcuni minuti. Riprova dopo 20 minuti.",
		"receiveCode_text4": "4. Se questo indirizzo email esiste già, non ti invieremo un codice di autenticazione. Effettua l'accesso o reimposta la tua password.",
		"Sign_up_for_Rewards": "Registrati per le ricompense",
		"Email_Verification": "Verifica email",
		"tips": "Inserisci il codice di verifica di 6 cifre inviato a {{formData.email}}. Il codice è valido per 30 minuti.",
		"tips_1": "Inserisci il codice di verifica di 6 cifre inviato a",
		"tips_2": "Il codice è valido per 30 minuti.",
		"Resend_code_in": "Riinvia il codice in",
		"Sent_again": "Invia di nuovo",
		"CREATE_ACCOUNT": "CREA ACCOUNT",
		"inviteCodecheck": "Il codice invito non può essere vuoto!",
		"Mobile": "Numero di cellulare",
		"Mobilemsg": "Il numero di cellulare è obbligatorio",
		"Password": "Password",
		"Passwordmsg": "La password è obbligatoria",
		"OTPCode": "Codice OTP",
		"OTPCodemsg": "Il codice OTP è obbligatorio",
		"SendOTP": "Invia OTP",
		"Email": "E-mail",
		"Emailmsg": "L'e-mail è obbligatoria",
		"Invitecode": "Codice di invito (opzionale)",
		"Invitecodemsg": "Il codice di invito è obbligatorio",
		"emailFormatter": "Si prega di inserire un indirizzo email corretto",
		"usernameFormatter": "Il nome utente deve essere composto da lettere e numeri e deve avere una lunghezza compresa tra 6 e 20 caratteri",
		"phoneFormatter": "Si prega di inserire un numero di cellulare di 10 cifre",
		"msgsuccess": "Registrazione completata con successo",
		"msgerr": "Registrazione fallita",
		"errorphonecheck": "Il numero di cellulare non può essere vuoto!",
		"phonecheck": "Si prega di inserire un numero di cellulare di 10 cifre!",
		"imgcodecheck": "Il codice OTP non può essere vuoto!"
	},
	"login": {
		"Welcome_back": "Bentornato",
		"tips": "Ho letto e accetto i Termini di servizio e la Politica sulla privacy di {{this.$LogoName}}",
		"tips_1": "Ho letto e accetto i",
		"tips_2": "Termini di servizio",
		"tips_3": "e",
		"tips_4": "Politica sulla privacy",
		"Continue_with_Google": "Continua con Google",
		"Continue_with_Apple": "Continua con Apple",
		"text": "Non hai ancora un account?",
		"text2": "Hai già un account?",
		"Login": "Accedi",
		"Register": "Registrati",
		"username": "Nome utente o numero di telefono",
		"password": "Password",
		"imgcode": "Codice immagine",
		"BackHome": "Torna alla home",
		"Forgetpwd": "Password dimenticata"
	},
	"forgot": {
		"title": "Password dimenticata",
		"msgsuccess": "Ripristino della password completato",
		"newpassword": "Nuova password",
		"newpasswordmsg": "La nuova password è obbligatoria",
		"Continue": "Continua"
	},
	"home": {
		"content11": "Gli invitanti possono ottenere un bonus proporzionale al volume di transazioni generate dai loro invitati. Il bonus massimo per un'invito valido è Unlimit USDT.",
		"Revolutionize_Trading": "Rivoluziona il Trading",
		"Shape_the_Future": "Dai forma al Futuro",
		"content0": "Viviamo insieme la nuova era degli scambi di criptovaluta!",
		"3_Reasons_for_Choosing": "3 Motivi per Sceglierci",
		"Innovative_Trading_Features": "Funzioni di Trading Innovative",
		"content1": "Un'esperienza di trading rivoluzionaria che è diversa da qualsiasi altra attualmente disponibile sul mercato. Progettata per offrirti un vantaggio competitivo con funzionalità di trading innovative, misure di sicurezza avanzate e velocità di transazione incredibilmente veloci.",
		"Lower_Fees_and_Better_Rewards": "Commissioni più Basse e Migliori Ricompense",
		"content2": "La nostra interfaccia rende il trading semplice anche per i principianti. Puoi creare un account gratuitamente, scambiare criptovalute con basse commissioni di transazione nel nostro mercato. Inoltre, puoi condividere fino al 20% delle commissioni di transazione dallo scambio per tutti gli utenti che inviti.",
		"Institutional-grade_Security": "Sicurezza di Livello Istituzionale",
		"content3": "segue uno degli standard di sicurezza più elevati del settore. Inoltre, conserviamo il 100% della tua criptovaluta in archiviazione a freddo. Garantiamo un rapporto di riserva di almeno 1:1 dei fondi dei nostri clienti. Il nostro fondo di 300M USDT fornisce un ulteriore livello di protezione contro le minacce alla sicurezza.",
		"Start_investing_in_as_little_as_5_minutes": "Inizia a investire in soli 5 minuti",
		"content4": "Registrati gratuitamente con {{this.$LogoNames.toUpperCase()}}",
		"content4_1": "Registrati gratuitamente con",
		"content4_2": "",
		"Complete_google_authentication": "Completa l'autenticazione Google",
		"content5": "Inserisci e verifica il tuo indirizzo email per iniziare. Non ci sono commissioni per aprire un account.",
		"content7": "Sblocca tutte le funzionalità verificando il tuo autenticatore Google in meno di 5 minuti.",
		"content8": "Finanzia il tuo account {{this.$LogoNames.toUpperCase()}} istantaneamente",
		"content8_1": "Finanzia il tuo account",
		"content8_2": "istantaneamente",
		"content9": "Deposita USDT tramite una rete selezionata e accedi istantaneamente ai tuoi fondi. Non è necessario attendere 3-5 giorni per iniziare a fare trading.",
		"Start_building_your_crypto_portfolio": "Inizia a costruire il tuo portfolio di criptovalute",
		"Invitation_Program": "Programma di Invito",
		"Referral_Program": "Programma di Referral",
		"content10": "Completa qualsiasi invito valido e ricevi un bonus di invito di valore medio pari a 10 USDT. Il bonus massimo per una singola persona è di ",
		"TRADING_TASKS": "COMPITI DI TRADING",
		"content12": "Gli utenti riceveranno premi in USDT durante il trading, con vari compiti di trading per diverse modalità di trading. I premi saranno distribuiti istantaneamente",
		"Trade_anytime_anywhere": "Scambia in qualsiasi momento, ovunque",
		"content13": "USA, Inc. è autorizzata ad operare in attività di business di valuta virtuale dal Dipartimento dei Servizi Finanziari dello Stato di New York.",
		"USA_Inc": "USA, Inc",
		"content6": "Scarica la nostra app per rimanere connesso 24/7",
		"Legal&Disclosures": "Legale e divulgazioni",
		"Regulatory_License": "Licenza Regolamentare",
		"AML/KYC_Policies": "Politiche AML/KYC",
		"Privacy_Policy": "Informativa sulla Privacy",
		"Terms_of_Use": "Termini di Utilizzo",
		"Legal_Statement": "Dichiarazione Legale",
		"Risk_Disclosure": "Informativa sui Rischi",
		"About_Us": "Chi siamo",
		"Protection_Fund": "Fondo di Protezione",
		"Proof_of_Reserves": "Proof of Reserves",
		"Twitter": "Twitter",
		"Bitget_Token": "Token Bitget",
		"Careers": "Carriere",
		"Company": "Azienda",
		"Support_Service": "Supporto",
		"title": "Apri con un'app",
		"titlebtn": "Scarica",
		"Balance": "Saldo",
		"ID": "ID",
		"Recharge": "Ricarica",
		"Withdraw": "Prelievo",
		"Bonus": "Bonus",
		"Checkin": "Check-in",
		"Eventclosed": "Evento chiuso",
		"Ranking": "Classifica"
	},
	"Ranking": {
		"Today_s winning ranking": "Classifica delle vincite di oggi",
		"tips1": "Più grande è l'importo della vincita, più alta è la posizione nella classifica!",
		"UserName": "Nome utente",
		"Winning Amount": "Importo della vincita",
		"Today_s rebate ranking": "Classifica degli sconti di oggi",
		"tips2": "Più alti gli sconti per gli utenti di livello inferiore = posizione più alta!",
		"Rebate Amount": "Importo dello sconto",
		"Rebate ranking": "Classifica dei primi 20 sconti"
	},
	"Checkin": {
		"Checkin": "Registrati",
		"CheckinList": "Elenco delle registrazioni"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Regola",
		"Record": "Record",
		"Records": "Record di Andar Bahar",
		"MyOrder": "Il mio Ordine",
		"more": "altro",
		"Period": "Periodo",
		"Order": "Ordine",
		"Buy": "Acquista",
		"Result": "Risultato",
		"Profit": "Profitto",
		"AndarBaharRule": "Regole di Andar Bahar",
		"CountDown": "Conto alla rovescia",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Pareggio",
		"result": "risultato",
		"ok": "ok",
		"start": "inizio",
		"Point": "Punto",
		"ContractPoint": "Punto di contratto",
		"Number": "Numero",
		"Delivery": "Consegna",
		"Fee": "Commissione",
		"Amount": "Importo"
	},
	"pokerOrder": {
		"OrderRecord": "Registro dell'ordine",
		"WaitingOrder": "Ordine in attesa",
		"HistoryOrder": "Storico ordini",
		"Nomoredata": "Nessun dato aggiuntivo",
		"Buy": "Acquista",
		"Result": "Risultato",
		"Time": "Tempo",
		"Amount": "Importo",
		"Fee": "Commissioni",
		"Profit": "Profitto"
	},
	"pokerRecord": {
		"title": "Registro di Andar Bahar",
		"Period": "Periodo",
		"Result": "Risultato",
		"OpenTime": "Orario di apertura",
		"Nomore": "Non ci sono più record"
	},
	"Google2FA": {
		"title": "Google Authenticator",
		"Authenticator_Instructions": "Istruzioni per l'Authenticator",
		"Authenticator_Status": "Stato dell'Authenticator",
		"Google_Authenticator": "Google Authenticator",
		"Download_Google_Authenticator_App": "Scarica l'app Google Authenticator",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "Avanti",
		"Backup_key": "Chiave di backup",
		"Backup_key_text": "Si prega di salvare questa chiave di backup in un luogo sicuro. Questa chiave ti permetterà di recuperare il tuo Authenticator in caso di perdita del tuo telefono. In caso contrario, per reimpostare il tuo Authenticator dovrai consultare il servizio clienti.",
		"Verifiaction": "Verifica",
		"Verifiaction_text": "Inserisci il codice di verifica di Google",
		"Google_verification_code": "Codice di verifica di Google",
		"contentlist1": "1. Registra la chiave di backup a 16 cifre di +this.$LogoName+ e conservala in un luogo sicuro.",
		"contentlist1_1": "1. Registra la chiave di backup a 16 cifre di",
		"contentlist1_2": "e conservala in un luogo sicuro.",
		"contentlist2": "2. Apri Google Authenticator.",
		"contentlist3": "3. Aggiungi un account +this.$LogoName+ e inserisci la chiave a 16 cifre copiata.",
		"contentlist3_1": "3. Aggiungi un account",
		"contentlist3_2": "e inserisci la chiave a 16 cifre copiata.",
		"contentlist4": "4. Vedrai un codice di verifica a 6 cifre sullo schermo.",
		"contentlist5": "5. Copia il codice a 6 cifre e incollalo in +this.$LogoName+ .",
		"contentlist5_1": "5. Copia il codice a 6 cifre e incollalo in",
		"contentlist5_2": ".",
		"contentlist6": "6. Il tuo Authenticator sarà collegato con successo."
	},
	"win": {
		"Live_Price": "Prezzo Live",
		"title1": "I risultati degli investimenti sono calcolati sull'ultimo numero del Binance BTC/USDT Spot Trade corrispondente all'ora UTC. Gli investitori possono verificare i risultati su Binance in qualsiasi momento per garantire la correttezza e la sicurezza degli investimenti.",
		"title": "Ci sono 2 modalità di investimento",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Ogni round dura 1 minuto. La modalità Lite fornisce solo l'investimento in numeri pari e dispari. Il risultato viene calcolato sull'ultimo numero del trade spot Binance BTC/USDT di intervallo 1M.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Ogni round dura 5 minuti. La modalità Pro sblocca il metodo di investimento completo di EPOCH EPT. Il risultato viene calcolato sull'ultimo numero del trade spot Binance BTC/USDT di intervallo 5M.",
		"5M_Mode_text_1": "Ogni round dura 5 minuti. La modalità Pro sblocca il metodo di investimento completo di ",
		"5M_Mode_text_2": " . Il risultato viene calcolato sull'ultimo numero del trade spot Binance BTC/USDT di intervallo 5M.",
		"Investment_Guide": "Guida all'Investimento",
		"Investment_Rules": "Regole di Investimento",
		"title2": "Come dimostrare che il risultato è equo?",
		"Investment_Info": "Informazioni di Investimento",
		"3M_Mode": "Modalità 3M",
		"3M_Mode_text": "Ogni round dura 3 minuti. Il risultato viene calcolato sull'ultimo numero del trade spot Binance BTC/USDT di intervallo 3M.",
		"Investment_return": "Ritorno d'investimento",
		"Number": "Numero",
		"Profit_Invested_amount": "Profitto: Importo Investito",
		"guide1": "Scegli una modalità di tempo.",
		"guide2": "Regola l'importo dell'investimento.",
		"guide3": "Scegli i numeri in cui desideri investire.",
		"guide4": "Controlla il tuo portfolio e fai clic su investi.",
		"NEXT": "AVANTI",
		"Invest_Amount": "Importo di Investimento",
		"Total": "Totale",
		"INVEST": "INVESTI",
		"Portfolio": "Portfolio",
		"Invest": "Investi",
		"BINANCE": "BINANCE",
		"Bitcoin_Price": "Prezzo Bitcoin",
		"InvestmentRecord": "Registro di Investimento",
		"Best_Assistant": "Assistente d'Investimento",
		"last_100_rounds": "(ultimi 100 round)",
		"What_do_these_data_mean": "Cosa significano questi dati",
		"Missing": "Mancante",
		"Missing_text": "Ciò significa il numero di round dall'ultimo round che ha prodotto questo numero.",
		"Frequency": "Frequenza",
		"Frequency_text": "Si riferisce al numero di volte in cui il numero corrispondente appare negli ultimi 100 round.",
		"Max_Continued": "Massimo continuo",
		"Max_Continued_text": "Si riferisce al periodo di apparizione consecutivo massimo del numero corrispondente negli ultimi 100 round.",
		"Rule": "Regola",
		"Trend": "Tendenza",
		"MyBalance": "Il mio saldo",
		"JoinGreen": "Unisciti a Green",
		"JoinRed": "Unisciti a Red",
		"JoinViolet": "Unisciti a Violet",
		"Parity": "Parità",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "Registro",
		"more": "di più",
		"Period": "Periodo",
		"Price": "Prezzo",
		"Result": "Risultato",
		"My": "Il mio",
		"Order": "Ordine",
		"Buy": "Acquista",
		"Profit": "Profitto",
		"Green": "Verde",
		"Red": "Rosso",
		"Violet": "Viola",
		"tips": "La commissione di transazione è del {bet_fee*100}% dell'importo dell'ordine",
		"tips1": "La commissione di transazione è del ",
		"tips2": "dell'importo dell'ordine ",
		"OrderAmount": "Importo dell'ordine",
		"Quantity": "Quantità",
		"TransactionFee": "Commissione di transazione",
		"TotalAmount": "Importo totale",
		"PresalePolicy": "Politica sulla privacy",
		"Iagree": "Sono d'accordo",
		"err1": "Si prega di accettare prima le regole di gestione della pre-vendita"
	},
	"historyPeriods": {
		"Orderrecord": "Registro ordini",
		"record": "registrazione"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Ordine in attesa",
		"HistoryOrder": "Storico degli ordini"
	},
	"Activity": {
		"added_to_account_balance": "aggiunto al saldo dell'account.",
		"contentText": "Quando i destinatari completano il primo deposito, viene conteggiata come un'invito valido. L'invitante riceverà un bonus di invito per ogni invito valido. I livelli di bonus di invito vanno da {{aaaaaa}}USDT per 1 invito valido a {{aaaaaa}}USDT per 500 inviti validi.",
		"contentText_1": "Quando i destinatari completano il primo deposito, viene conteggiata come un'invito valido. L'invitante riceverà un bonus di invito per ogni invito valido. I livelli di bonus di invito vanno da",
		"contentText_2": "per 1 invito valido a",
		"contentText_3": " per 500 inviti validi",
		"contentText_3_1": " per ",
		"contentText_3_2": " inviti validi",
		"contentText2": "Gli invitanti condivideranno il {{LV.1%}} della commissione di trading dal volume di trading di ogni invitato di LV.1. Gli invitanti condivideranno il {{LV.2%}} della commissione di trading dal volume di trading di ogni invitato di LV.2. Il bonus massimo per il volume di trading degli invitati è illimitato in USDT.",
		"contentText2_1": "Gli invitanti condivideranno il",
		"contentText2_2": "della commissione di trading dal volume di trading di ogni invitato di LV.1. Gli invitanti condivideranno il",
		"contentText2_3": "della commissione di trading dal volume di trading di ogni invitato di LV.2. Il bonus massimo per il volume di trading degli invitati è illimitato in USDT.",
		"My_Rewards_History": "La mia cronologia delle ricompense",
		"Cumulative_Single_Number_Trading_Volumn": "Volume di trading cumulativo di numeri singoli",
		"Cumulative_ODD_Number_Trading_Volumn": "Volume di trading cumulativo di numeri dispari",
		"Cumulative_EVEN_Number_Trading_Volumn": "Volume di trading cumulativo di numeri pari",
		"Cumulative_BIG_Number_Trading_Volumn": "Volume di trading cumulativo di numeri grandi",
		"Cumulative_SMALL_Number_Trading_Volumn": "Volume di trading cumulativo di numeri piccoli",
		"Advanced_Tasks": "Attività avanzate",
		"Invite_Friends_and_Earn_Up_to": "Invita amici e guadagna fino a",
		"Referral_Code": "Codice di riferimento",
		"Referral_Link": "Link di riferimento",
		"INVITE_FRIENDS": "INVITA AMICI",
		"Complete_tasks_to_earn_up_to": "Completa le attività per guadagnare fino a",
		"MY_REWARDS": "LE MIE RICOMPENSE",
		"SHARE": "CONDIVIDI",
		"Earn_with_your_friends_together": "Guadagna insieme ai tuoi amici",
		"Get_your_link": "Ottieni il tuo link",
		"Claim_your_rewards": "Richiedi le tue ricompense",
		"Earn_together": "Guadagnate insieme",
		"contentlist0": "Iscriviti a {{gogobit}} e ottieni il tuo link di riferimento. Gli utenti che si registrano tramite questo link riceveranno ricompense.",
		"contentlist0_1": "Iscriviti a",
		"contentlist0_2": "e ottieni il tuo link di riferimento. Gli utenti che si registrano tramite questo link riceveranno ricompense.",
		"contentlist1": "Riceverai una ricompensa per ogni amico che completa il primo deposito e raggiunge il volume di trading richiesto. La ricompensa media è di {{XX}} USDT, con un massimo di {{XX}} USDT.",
		"contentlist1_1": "Riceverai una ricompensa per ogni amico che completa il primo deposito e raggiunge il volume di trading richiesto. La ricompensa media è di",
		"contentlist1_2": "USDT, con un massimo di",
		"contentlist1_3": " USDT.",
		"contentlist2": "Dopo aver creato un account, l'amico può richiedere un bonus di trading fino a 5.050 USDT.",
		"Referral_Rewards": "Ricompense di riferimento",
		"Detail_Rules": "Regole dettagliate",
		"Invitation_Bonus": "Bonus di invito",
		"Up_to": "Fino a",
		"content0": "Completa qualsiasi invito valido e ricevi un bonus di invito con una media di 10 USDT. La ricompensa massima per cento invitati è",
		"content0_1": "Completa qualsiasi invito valido e ricevi un bonus di invito con una media di",
		"content0_2": " La ricompensa massima per cento invitati è",
		"Trading_Bonus": "Bonus di trading",
		"content1": "Gli invitanti possono ottenere un bonus proporzionale al volume di transazioni generato dai loro invitati. Il bonus massimo per un'invito valido è",
		"My_Referral_History": "La mia storia di invito",
		"Total_Invitees": "Totale Invitati",
		"Valid_Invitees": "Invitati Validi",
		"Cumulative_Invitees": "Invitati",
		"Trading_Volumn": "Volume di trading",
		"Daily_Tasks": "Compiti giornalieri",
		"Detail_Rules_title": "ESTRAZIONE FORTUNATA GIORNALIERA DI 888 USDT",
		"Detail_Rules_title1": "1. I dati di trading futures del giorno verranno calcolati il ​​giorno successivo.",
		"Detail_Rules_title2": "2. Gli utenti che effettuano un volume di trading futures di 200.000 USDT o più nel giorno avranno la possibilità di partecipare all'estrazione fortunata di 888 USDT.",
		"CLAIM_NOW": "RICHIEDI ADESSO",
		"INVEST_NOW": "INVESTI ADESSO",
		"title": "Portafoglio bonus",
		"BonusBalance": "Saldo bonus",
		"Withdrawtobalance": "Prelievo al saldo",
		"BonusList": "Elenco bonus",
		"Bonuswithdrawtobalance": "Bonus prelevato al saldo",
		"WithdrawalAmount": "Importo del prelievo",
		"Pleaseentertheamount": "Inserisci l'importo",
		"err1": "L'importo del prelievo non può essere 0",
		"success": "Trasferimento riuscito"
	},
	"Refer": {
		"title": "Il mio referente",
		"ReferTotalBonus": "Bonus totale referente",
		"InviteFriends": "Invita amici",
		"Level1": "Livello 1",
		"Level2": "Livello 2",
		"TotalPeople": "Totale persone",
		"Bonus": "Bonus",
		"Myinvitationlink": "Il mio link di invito",
		"Rebate_description": "Descrizione rimborso"
	},
	"Task": {
		"InviteBonusToday": "Bonus Invito Oggi",
		"bonusrecord": "Registro bonus",
		"hint1": "1. I dati vengono aggiornati ogni 10-30 minuti!",
		"hint2": "2. Sono conteggiati solo coloro che si sono registrati negli ultimi 3 giorni e hanno completato la ricarica oggi!",
		"hint2_1": "2. Sono conteggiati solo coloro che si sono registrati negli ultimi ",
		"hint2_2": " giorni e hanno completato la ricarica oggi!",
		"hint3": "3. Deposito minimo di 20u richiesto oggi!",
		"hint3_1": "3. Deposito minimo di ",
		"hint3_2": " richiesto oggi!",
		"hint4": "Numero di persone invitate negli ultimi tre giorni",
		"hint4_1": "Numero di persone invitate negli ultimi ",
		"hint4_2": " giorni",
		"hint5": "Numero di persone che hanno completato la ricarica oggi",
		"Invite": "Invita",
		"friendstorecharge": "amici a ricaricare",
		"Earn": "Guadagna",
		"Completed": "Completato",
		"ExpireTime": "Tempo di scadenza"
	},
	"Invited": {
		"title": " Bonus registrato",
		"InviteTotalBonus": "Bonus totale invito",
		"InviteTime": "Tempo di invito",
		"Bonus": "Bonus",
		"NoRecored": "Nessun record"
	},
	"mine": {
		"ProvacyPolicy": "Politica sulla Privacy",
		"JOIN US": "UNISCITI A NOI",
		"title": "Centro Personale",
		"InviteCode": "Codice di Invito",
		"AccountBalance": "Saldo Conto",
		"BonusBalance": "Saldo Bonus",
		"Wallet": "Portafoglio",
		"Bank": "Banca",
		"Invite": "Invita",
		"Refer": "Refer",
		"CustomerService": "Servizio Clienti",
		"ComplaintsSuggestions": "Reclami e Suggerimenti",
		"ChangePassword": "Cambia Password",
		"Activity": "Attività",
		"PresalePolicy": "Politica sulla Privacy",
		"DownloadApp": "Scarica l'App",
		"AppVersion": "Versione App",
		"Logout": "Logout",
		"Logouttips": "Sei sicuro di voler effettuare il logout?"
	},
	"App": {
		"title": "Download dell'app",
		"DownloadforAndroid": "Scarica per Android",
		"DownloadforiOS": "Scarica per iOS"
	},
	"Presale": {
		"title": "politica sulla riservatezza"
	},
	"Account": {
		"title2": "Imposta una nuova password",
		"title2_text": "Imposta una nuova password per il tuo account Google. Puoi accedere al tuo account Google utilizzando la password.",
		"title_text": "Stai cercando di reimpostare la password. Assicurati di avere il tuo Google Authenticator pronto per la reimpostazione.",
		"err1": "Le due nuove password inserite sono diverse",
		"success2": "Password cambiata con successo",
		"title": "Cambia password",
		"OldPassword": "Vecchia password",
		"OldPasswordmsg": "La vecchia password è richiesta",
		"NewPassword": "Nuova password",
		"NewPasswordmsg": "La nuova password è richiesta",
		"RetypeNewPassword": "Ridigita la nuova password",
		"RetypeNewPasswordmsg": "La ridigitazione della nuova password è richiesta",
		"Continue": "Continua"
	},
	"Service": {
		"title": "Servizio",
		"CONTACTUS": "CONTATTACI",
		"tips": "Il servizio clienti è in pausa!",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "link"
	},
	"Bank": {
		"BankName": "Nome della banca",
		"ChooseaCurrencyType": "Scegli il tipo di valuta",
		"SelectWithdrawalMethod": "Seleziona il metodo di prelievo",
		"Newbankcard": "Nuova carta bancaria",
		"Editbankcard": "Modifica carta bancaria",
		"title": "La mia banca",
		"Cannotbeempty": "Non può essere vuoto",
		"BankHolderName": "Nome del titolare del conto",
		"BankAccountNumber": "Numero del conto bancario",
		"IFSCCode": "Codice IFSC",
		"Email": "Email",
		"Phone": "Telefono",
		"OTPCode": "Codice OTP",
		"tips1": "1. Si prega di utilizzare il numero di cellulare registrato per ricevere il codice OTP",
		"tips2": "2. Per la sicurezza dei fondi, la carta bancaria può essere modificata solo una volta al giorno",
		"Add": "Aggiungi",
		"error1": "Il nome del titolare del conto non può essere vuoto!",
		"error2": "Si prega di inserire il corretto nome del titolare del conto!",
		"error3": "Il nome della banca non può essere vuoto!",
		"error4": "Si prega di inserire il corretto nome della banca!",
		"error5": "Il numero del conto bancario non può essere vuoto!",
		"error6": "Si prega di inserire il corretto numero del conto bancario!",
		"error7": "Il codice IFSC non può essere vuoto!",
		"error8": "Si prega di inserire il corretto codice IFSC!",
		"error9": "L'email non può essere vuota!",
		"error10": "Si prega di inserire l'indirizzo email corretto!",
		"error11": "Il telefono non può essere vuoto!",
		"error12": "Il codice OTP non può essere vuoto!",
		"Successfully": "Carta bancaria aggiunta con successo"
	},
	"wallet": {
		"title": "Il mio portafoglio",
		"MyBalance": "Il mio saldo",
		"MyBank": "La mia banca",
		"RechargeRecords": "Storico Ricariche",
		"WithdrawalRecords": "Storico Prelievi"
	},
	"Rechargepage": {
		"Reminder": "Promemoria",
		"Reminder_1": "1. Non depositare nessuna criptovaluta diversa da USDT all'indirizzo. L'invio di monete o token non supportati a questo indirizzo potrebbe causare una perdita irreversibile.",
		"Reminder_2": "2. L'importo minimo di deposito è di 5USDT.",
		"Reminder_2_1": "2. L'importo minimo di deposito è di ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. I depositi arriveranno dopo 15 conferme di rete.",
		"Minimum": "Minimo",
		"title": "Deposito",
		"History": "Cronologia depositi",
		"Mybalance": "Il mio saldo",
		"RechargeAmount": "Importo deposito",
		"RechargeAmountmsg": "Inserisci l'importo",
		"tips1": "Il canale di pagamento è in manutenzione!",
		"tips2": "Seleziona un canale di pagamento",
		"PayNow": "Paga adesso",
		"err1": "Il canale di pagamento è in manutenzione",
		"err2": "L'importo non può essere inferiore a ",
		"err3": "Seleziona un canale di pagamento",
		"Rechargemethod": "Metodo di deposito"
	},
	"Rechargerecord": {
		"title": "Storico Ricariche",
		"WatingOrder": "Ordini in attesa",
		"HistoryOrder": "Storico ordini",
		"Rupee": "Dollaro",
		"Status": "Stato",
		"Time": "Tempo"
	},
	"withdrawal": {
		"Tips": "Consigli",
		"Tips_1": "Consigli: La commissione per il prelievo inferiore a ",
		"Tips_2": " USDT è di ",
		"Tips_3": " USDT, la commissione per il prelievo di ",
		"Tips_4": "USDT o più è del",
		"Tips_5": "%.",
		"WalletAddress": "Indirizzo del portafoglio",
		"Addwalletaddress": "Aggiungi indirizzo del portafoglio",
		"Network_fee": "Commissione di rete",
		"Receive_amount": "Importo ricevuto",
		"Select Chains": "Seleziona catene",
		"Bankcard": "Carta bancaria",
		"Nobankcardselected": "Nessuna carta bancaria aggiunta",
		"Withdrawalfee": "Commissione di prelievo",
		"title": "Prelievo",
		"Mybalance": "Il mio saldo",
		"Withdrawablebalance": "Saldo prelevabile",
		"tips1": "L'importo totale di acquisto deve essere superiore a {{codeAmount}} può essere prelevato interamente",
		"tips1-1": "L'importo totale di acquisto deve essere superiore a",
		"tips1-2": "può essere prelevato interamente",
		"tips2": "I prelievi giornalieri sono di {{draw_num}} volte",
		"tips2-1": "I prelievi giornalieri sono di",
		"tips2-2": "volte",
		"tips3": "L'importo massimo di prelievo giornaliero è",
		"tips4": "L'importo minimo di prelievo singolo è ₹{{draw_min_money}} e l'importo massimo di prelievo singolo è ₹{{draw_max_money}}",
		"tips4-1": "L'importo minimo di prelievo singolo è",
		"tips4-2": "e l'importo massimo di prelievo singolo è",
		"tips5": "L'orario di prelievo è {{draw_time}}, si prega di prelevare nell'orario specificato",
		"tips5-1": "L'orario di prelievo è",
		"tips5-2": "si prega di prelevare nell'orario specificato",
		"tips6": "Il prelievo in contanti comporterà una commissione di {{draw_fee}}% sulla commissione di prelievo",
		"tips6-1": "Il prelievo in contanti comporterà una commissione di",
		"tips6-2": "sulla commissione di prelievo",
		"tips7": "Se l'importo del prelievo è inferiore o uguale a ₹{{solid_min_amount}}, verrà addebitata una commissione fissa di ₹{{solid_feemoney}}",
		"tips7-1": "Se l'importo del prelievo è inferiore o uguale a",
		"tips7-2": "verrà addebitata una commissione fissa di",
		"tips7-3": ".",
		"WithdrawalAmount": "Importo del prelievo",
		"WithdrawalAmountmsg": "Inserisci l'importo",
		"Withdrawal": "Prelievo",
		"WithdrawalHistory": "Cronologia del prelievo",
		"Tipsmsg": "La richiesta di prelievo è stata effettuata con successo, in attesa del risultato dell'audit!",
		"dangerMsg1": "Si prega di aggiungere prima la carta bancaria",
		"dangerMsg2": "L'importo di prelievo non può essere inferiore a",
		"dangerMsg3": "L'importo di prelievo deve essere un multiplo di 100",
		"dangerMsg4": "L'importo di prelievo non può essere superiore a",
		"dangerMsg5": "L'importo di prelievo non può essere superiore al saldo prelevabile",
		"dangerMsg6": "Aggiungi prima la tua carta bancaria"
	},
	"withdrawalrecord": {
		"title": "registro di prelievo",
		"WatingOrder": "Ordine in attesa",
		"HistoryOrder": "Storia Ordine",
		"Rupee": "Dollaro",
		"Status": "Stato",
		"Time": "Tempo",
		"Nomore": "Nessun altro!"
	},
	"Version": {
		"title": "Versione",
		"stableversion": "versione stabile",
		"Alreadythelatestversion": "Già l'ultima versione",
		"Newversionfound": "Nuova versione trovata",
		"DownloadUpdate": "Scarica Aggiornamento"
	},
	"Bonus": {
		"title": "Attività Bonus",
		"ReceiveBonus": "Ricevi bonus",
		"Hi": "Ciao",
		"tips1": "Congratulazioni, hai ottenuto il bonus",
		"tips2": "Devi effettuare una ricarica maggiore o uguale a",
		"tips3": "e il bonus verrà automaticamente aggiunto al saldo del tuo account",
		"Received": "Ricevuto",
		"Back": "Indietro",
		"Rechargenow": "Ricarica ora"
	},
	"ComplaintsSuggestions": {
		"title": "Lamentele e Suggerimenti",
		"Answered": "Risposto",
		"AddNew": "Aggiungi Nuovo",
		"Time": "Tempo",
		"Type": "Tipo",
		"WhatsApp": "WhatsApp",
		"Description": "Descrizione",
		"Remark": "Nota",
		"Waiting": "In attesa",
		"Suggestion": "Suggerimento",
		"Consult": "Consulenza",
		"RechargeProblem": "Problema Ricarica",
		"WithdrawProblem": "Problema Prelievo",
		"OrderProblem": "Problema Ordine",
		"Other": "Altro",
		"Describeyourproblems": "Descrivi i tuoi problemi",
		"WhatsApptocontactyou": "WhatsApp per contattarti",
		"submit": "invia",
		"err1": "WhatsApp non può essere vuoto!",
		"err2": "Il tipo non può essere vuoto!",
		"err3": "La descrizione non può essere vuota!"
	},
	"slotMachine": {
		"title": "Macchina della frutta",
		"Myblance": "Il mio saldo",
		"Records": "Record della macchina della frutta",
		"titleRecord": "Registrazioni"
	},
	"bankrecharge": {
		"Orderamount": "Importo dell'ordine",
		"Ordernumber": "Numero dell'ordine",
		"BankName": "Nome della banca",
		"BankAccount": "Conto bancario",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Il tuo numero di carta bancaria",
		"Enterthebanktransactionordernumber": "Inserisci il numero di ordine di transazione bancaria",
		"submit": "Invia",
		"Tips": "Consigli",
		"tips1": "1. Il sistema sta elaborando il tuo ordine.",
		"tips2": "2. Si prega di contattare il servizio clienti se l'importo non arriva per molto tempo.",
		"err1": "Inserisci il tuo numero di carta bancaria",
		"err2": "Inserisci il numero dell'ordine bancario"
	},
	"javaCode": {
		"200": "successo",
		"20001": "Token di accesso non valido",
		"20002": "Token di accesso non valido",
		"20003": "Errore del numero di telefono",
		"20004": "Il numero di telefono esiste già!",
		"20005": "Errore dell'indirizzo e-mail!",
		"20006": "L'indirizzo e-mail esiste già!",
		"20007": "Errore di password!",
		"20010": "Errore di account o password!",
		"20011": "Errore dell'account bancario!",
		"20012": "Err bank ifsc, (standard formato IFSC) - lunghezza 11, primo IFSC quattro e 5 ° 0!",
		"20013": "Errore del nome del beneficiario, 5 < nome <= 50!",
		"20021": "L'account non esiste!",
		"20022": "L'account è bloccato!",
		"20031": "Il livello è limitato a 10!",
		"20032": "Errore di aggiornamento del ruolo di relazione!",
		"20033": "Saldo insufficiente!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Errore di registrazione!",
		"200082": "L'account è disabilitato!",
		"200091": "La carta di aggiunta è limitata a 10!",
		"200092": "input error login pwd too many times, and the account is disable!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Errore del server!",
		"Handledataerror": "Effettua il login!",
		"-91": "Errore del server!",
		"-92": "Errore nella gestione dei dati!",
		"-1": "Errore di sistema!",
		"-2": "Errore dei parametri",
		"-3": "nessun dato",
		"-4": "operazione frequente in breve tempo",
		"-5": "Sistema occupato, riprova più tardi!",
		"-6": "Ci sono molte persone al momento, riprova!",
		"-7": "errore dati!",
		"-8": "Errore codice Google!",
		"-9": "Il sistema è in manutenzione! riprova più tardi!",
		"-10": "errore di salvataggio!",
		"-11": "Errore di eliminazione!",
		"-12": "Errore di aggiornamento!",
		"-13": "Operazione non valida!",
		"-14": "Fallimento dell'operazione!",
		"-15": "Operazione disabilitata!",
		"-16": "Operazione completata!",
		"-17": "Errore di esistenza",
		"-18": "Non esiste!",
		"-19": "Scade",
		"-20": "È disabilitato",
		"-21": "Errore di verifica del codice",
		"-22": "L'intervallo di modifica deve essere maggiore di 1 giorno",
		"-23": "La nuova password non può essere uguale alla password originale",
		"-100": "errore personalizzato!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Totale importo acquistato",
		"TotalRevenue": "Ricavo totale",
		"AccountBalance": "Saldo dell'account",
		"Income": "Entrate",
		"Transaction": "Transazione",
		"Myhold": "La mia quota",
		"ProductList": "Elenco prodotti",
		"Investmentperiod": "Periodo di investimento",
		"days": "giorni",
		"Buynow": "Acquista ora",
		"Saletime": "Tempo di vendita",
		"Nomore": "Nessun altro",
		"ProductDetails": "Dettagli prodotto",
		"ForSale": "In vendita",
		"Sale": "Vendita",
		"SoldOut": "Esaurito",
		"ProductInformation": "Informazioni sul prodotto",
		"InvestmentPeriod": "Periodo di investimento",
		"TotalShare": "Totale quote",
		"ExpectedReturn": "Rendimento previsto",
		"SaleTime": "Tempo di vendita",
		"PurchaseConditionsAndRules": "Condizioni e regole di acquisto",
		"MinimumPurchaseAmount": "Importo minimo di acquisto",
		"MaximumSalesLimit": "Limite massimo di vendita",
		"AccountBalanceMustBeGreaterThan": "Il saldo dell'account deve essere maggiore di",
		"DayBetIsGreaterThan": "La scommessa giornaliera è maggiore di",
		"ProductDescription": "Descrizione del prodotto",
		"MyHold": "La mia quota",
		"Ordernumber": "Numero d'ordine",
		"PurchasingPrice": "Prezzo d'acquisto",
		"NoRecord": "Nessun record",
		"Expiredate": "Data di scadenza",
		"Purchasetime": "Orario di acquisto",
		"redemption": "riscatto",
		"redemptionTips": "Il riscatto anticipato non genererà alcun reddito, confermi il riscatto?",
		"cancel": "annullare",
		"determine": "determinare",
		"Buyin": "Acquista",
		"Buyamount": "Importo di acquisto",
		"BuyinTips": "Il riscatto anticipato non genererà alcun reddito",
		"confirm": "confermare",
		"Minimumbuy": "Acquisto minimo",
		"Maturity": "Maturità",
		"EarlyRedemption": "Riscatto anticipato",
		"Redemptiontime": "Orario di riscatto",
		"Amountofincome": "Importo del reddito",
		"prompt": "promemoria"
	},
	"HelpHa": {
		"Help": "Aiuto",
		"HelpCenter": "Centro assistenza",
		"text": "Hai domande? Guarda qui"
	},
	"SetingHa": {
		"Seting": "Impostazioni",
		"SetingCenter": "Centro Impostazioni",
		"text": "Ottieni più ricompense"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"home.Products&Services": "Prodotti e servizi",
	"pokerOrder.WatingOrder": "Ordini in attesa",
	"Rechargepage.Tips": "Gentile promemoria: La prima ricompensa di ricarica è {{firstRPR * 100}% dell'importo della ricarica",
	"Rechargepage.Tips_1": "Gentile promemoria: La prima ricompensa di ricarica è basata sulla quantità di ricarica",
	"Rechargepage.Tips_2": "%",
	"withdrawal.Google_Code": "Codice di verifica Google",
	"index.index.gayb21": "Registrati per ricevere premi:",
	"index.index.72y628": "Tre buoni motivi per scegliere EPOCH EPT",
	"index.index.z6022y": "EPOCH EPT segue uno dei più alti standard di sicurezza del settore. Immagazziniamo il 100% della nostra criptovaluta in un magazzino refrigerato. Garantiamo di fornire almeno un rapporto di riserva 1:1 per i fondi dei clienti. Il nostro fondo da 300 milioni di dollari fornisce ulteriore protezione contro le minacce alla sicurezza.",
	"index.index.574982": "1. Registrati ora gratuitamente a EPOCH EPT",
	"index.index.qdi1g4": "2. Verifica completa di Google",
	"index.index.weko28": "3. Ricarica istantaneamente il tuo account EPOCH EPT",
	"index.index.sgkb35": "Programma di invito EPOCH EPT",
	"index.index.2u17nf": "Completando un invito valido puoi guadagnare un valore medio fino a 5,59 USD Il bonus massimo per cento invitati è",
	"index.index.e8is4a": "Programma di raccomandazione EPOCH EPT",
	"index.index.47uevl": "Operazione transazione EPOCH EPT",
	"index.index.2516gq": "EPOCH EPT, Inc. ha ottenuto una licenza di attività commerciale in valuta virtuale dal Dipartimento dei Servizi Finanziari dello Stato di New York.",
	"invest.index.3aqi3d": "Saldo del conto:",
	"invest.index.ubk1j4": "Smettila di investire!",
	"invest.prize1.4733u6": "Portafoglio d'investimento:",
	"invest.prize1.t55a16": "Importo totale:",
	"login.login.09m52q": "Ho letto e accetto EPOCH EPT",
	"login.login.045csy": "Nome utente",
	"login.login.r7rbm5": "Si prega di accettare l'accordo!",
	"rewards.activityb.4nvrq9": "Quando l'invitato completa il suo primo deposito, è considerato un invito valido. L'invitato riceverà un bonus di invito per ogni invito valido. Il livello bonus dell'invito proviene da 2 USD 1 invito valido, 559 USD 100 inviti validi.",
	"rewards.activityb.t10k6e": "L'invitato condividerà il 20% della commissione di transazione per ogni invitato LV.1. L'invitato condividerà il 10% della commissione di transazione per ogni LV. 2 invitato. Il bonus massimo per la ricompensa della commissione di transazione dell'invitato è di dollari USA illimitati.",
	"rewards.activityb.3d6850": "LV.1 Numero effettivo di invitati",
	"rewards.activityb.lvxo74": "LV.2 Numero effettivo di invitati",
	"rewards.activityb.zj26f9": "LV.1 Numero accumulato di invitati",
	"rewards.activityb.dkgxr5": "LV.2 Numero accumulato di invitati",
	"rewards.activityb.xjo077": "Recompensa di raccomandazione:",
	"rewards.activityb.k8wv6v": "Unisciti a EPOCH EPT e ottieni il tuo link consigliato. Gli utenti registrati tramite questo link riceveranno ricompense.",
	"rewards.activityb.4slpzg": "Dopo che il tuo amico ha completato il suo primo deposito e completato il volume di transazione richiesto, riceverai una ricompensa di referral con un valore medio di $ 5,59. La ricompensa massima può raggiungere $559.",
	"rewards.index.993jwg": "Copiato con successo",
	"rewards.index.7141ft": "Questo browser non supporta la copia automatica",
	"wallet.index.9uz2gn": "Tassa di gestione della rete 0 USDT",
	"wallet.index.ych3e1": "Ricevuta effettiva:",
	"wallet.index.3v558d": "Promemoria: Se il prelievo è inferiore a $ 20, la commissione di gestione del prelievo è $ 1. Se il prelievo è superiore a $ 20, la commissione di gestione del prelievo è del 3%",
	"wallet.index.255e30": "1. L'importo massimo giornaliero di prelievo è di $ 8000000",
	"wallet.index.3g68m8": "2. L'importo minimo di prelievo singolo è di $ 5, e l'importo massimo di prelievo singolo è di $ 8000000",
	"wallet.index.c6x3ti": "3. Il tempo di recesso è dalle 00:00 alle 23:59, si prega di effettuare il prelievo entro il tempo specificato",
	"wallet.index.h94rlg": "Limite minimo 5",
	"wallet.index.167862": "Inserisci l'indirizzo del portafoglio!",
	"wallet.index.pi869h": "Inserisci l'importo del prelievo!",
	"wallet.index.ysa85i": "L'importo massimo di prelievo singolo è",
	"components.changepass.68j9kh": "Stai tentando di reimpostare la password.",
	"components.changepass.ouxtp2": "Password originale",
	"components.languagebox.8no849": "Giapponese",
	"components.languagebox.oif20b": "Cinese tradizionale",
	"components.mycode.xyqr66": "Completare le attività può guadagnare fino a 5000 USDT",
	"components.mymenu.277ss7": "ID utente:",
	"components.mymenu.74l443": "Orario di registrazione:",
	"components.myposter.36rw5e": "Codice della raccomandazione:",
	"components.mytabbar.zw2bh2": "home page",
	"components.mytabbar.8x6c28": "trend",
	"components.mytabbar.28s7qr": "investimenti",
	"components.mytabbar.4r75d9": "ricompensa",
	"components.xieyi.zqk7pe": "Dimensione del carattere: 16px; Famiglia di caratteri: Tahoma;",
	"components.help.6gpud8": "Esistono due modelli di investimento:",
	"components.help.5qrq36": "Ogni periodo dura 1 minuto e offre solo investimenti singoli o doppi. Il risultato è calcolato dall'ultima cifra del prezzo di chiusura K-line di 1 minuto del trading spot Binance BTC/USDT.",
	"components.help.isl7r1": "Ogni seduta dura 5 minuti. La versione professionale sblocca tutti i metodi di investimento di EPOCH EPT. Il risultato è calcolato dall'ultima cifra del prezzo di chiusura della K-line di 5 minuti del trading spot Binance BTC/USDT.",
	"components.help.eai3nm": "Rendimento degli investimenti:",
	"components.help.35xg82": "Numero singolo:",
	"components.help.mpfg0s": "Redditi: Importo degli investimenti X 9,4",
	"components.help.v2ziib": "Numero dispare:",
	"components.help.vfzxor": "Redditi: Importo degli investimenti X 1,94",
	"components.help.73168b": "Numero Eve:",
	"components.help.76wgv1": "Grande numero:",
	"components.help.05ed33": "Numero piccolo:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Inserisci la password originale!",
	"components.changepass.xme6my": "Inserisci una nuova password!",
	"components.changepass.u434f8": "Le due password non corrispondono!",
	"components.changepass.574790": "Modificato con successo!",
	"invest.index.9y8wg0": "Non vincere un premio",
	"invest.index.ql5nc1": "Premi vincenti",
	"login.login.76451t": "Differenza di prezzo di scambio Un trading quantitativo!",
	"login.login.915p36": "La gestione finanziaria è al primo posto a livello mondiale",
	"login.login.sen863": "Più eccitante dopo l'accesso Hello",
	"login.login.nz6q9x": "Accesso telefonico",
	"login.login.w7p592": "Accesso via email",
	"login.login.g9y91k": "Numero di cellulare",
	"login.login.456gbb": "Numero di posta elettronica",
	"login.login.5ly8m8": "paese",
	"login.login.69h472": "Seleziona un paese",
	"login.login.i4p378": "numero",
	"login.login.fl82xx": "Esempio:",
	"wallet.index.urkoub": "Ricarica codice QR",
	"wallet.index.25cocl": "Copia indirizzo",
	"wallet.index.t35fvg": "1. Tempo di prelievo: Si prega di calcolare la tassa regionale la domenica entro 24 ore dal lunedì al sabato!",
	"wallet.index.68xx61": "2. I prelievi in valuta nazionale globale devono essere ricevuti entro 48 ore al più tardi, con una tassa di prelievo dell'8% (inclusa la tassa regionale).",
	"wallet.index.4ws3w9": "3. Il prelievo USDT deve essere accreditato sul conto entro 24 ore al più tardi, con una commissione di gestione del 2% (tassa regionale inclusa)",
	"wallet.index.9b7wq8": "4. L'importo minimo di prelievo è 12 USDT e l'importo massimo singolo di prelievo è 3000 USDT.",
	"wallet.index.23y92a": "Si prega di essere consapevoli che",
	"wallet.index.pl5473": "Limite minimo di 5",
	"wallet.index.nb3u72": "Informazioni sulla carta di credito",
	"wallet.index.842so4": "Aggiungi il numero della carta di credito",
	"wallet.index.86j717": "Aggiungi banca affiliata",
	"wallet.index.763d7x": "Aggiungi nome della carta bancaria",
	"wallet.index.50j237": "Banca affiliata",
	"wallet.index.hb6lun": "Nome della carta bancaria",
	"wallet.index.62y2jc": "Numero della carta di credito",
	"login.register.666hp4": "Scambio quantitativo della differenza di prezzo Al",
	"login.register.9298b8": "Invita amici",
	"login.register.375g14": "I nuovi arrivati si uniscono per la prima volta",
	"login.register.01922f": "Può essere eseguito una volta sola",
	"login.register.527o4q": "Entrate chimiche giornaliere AI",
	"login.register.c563gt": "Registrazione via e-mail",
	"login.register.2ls856": "Errore di formato del numero di telefono!",
	"login.register.4nu77e": "Errore di formato del numero di posta elettronica!",
	"lang.zh.pu623z": "1 invito valido",
	"lang.zh.3hv80b": "Deposita USDT!",
	"lang.zh.47ttf6": "Promemoria: il premio per la prima ricarica è pari al {{firstRPR*100}}% dell&#39;importo della ricarica",
	"lang.zh.9tli5f": "Suggerimento: il prelievo è inferiore a",
	"lang.zh.62e882": "USD, la commissione di prelievo è",
	"lang.zh.m3818q": "USD, ritiro",
	"lang.zh.xs2f10": "Il rimborso anticipato non genererà alcun profitto",
	"invest.index.hl86r1": "Assistente agli investimenti",
	"wallet.index.645d70": "Inserisci il nome della tua banca",
	"wallet.index.n21787": "Inserisci il nome utente della carta di credito",
	"wallet.index.7s5j14": "Inserisci il numero della tua carta di credito",
	"components.recharge.l576h7": "Variazioni del saldo",
	"components.recharge.3x6844": "Osservazioni",
	"components.appjump.u85nhy": "MoneyEasy è diventato la mia nuova dipendenza!",
	"components.appjump.543ht1": "Ho appena fatto $50 su MoneyEasy, e finalmente posso guadagnare liberamente denaro extra in qualsiasi momento, ovunque. Posso partecipare a sondaggi, giocare o testare nuove applicazioni e ricevere ricompense! Registrati ora per ricevere un bonus di $50!",
	"components.appjump.8k17w7": "Condivisione riuscita",
	"components.appjump.648wav": "Condivisione non riuscita:",
	"components.appjump.7c2hl4": "Il browser corrente non supporta la funzione di condivisione",
	"components.fuwu.1t2h7i": "contenuto",
	"components.fuwu.6t32g3": "Background del mercato.",
	"components.fuwu.4c8u7w": "Sistema di scambio quantitativo intelligente",
	"components.fuwu.mw89jh": "Richiesta automatica di rispedizione",
	"components.fuwu.v5o60h": "Nato.",
	"components.fuwu.12x554": "campo visivo",
	"components.fuwu.d8kj48": "Valori fondamentali.",
	"components.fuwu.sns568": "Logica progettuale",
	"components.fuwu.131mc5": "Principi fondamentali.",
	"components.fuwu.76i3z7": "Modello tecnologico del sistema di scambio quantitativo intelligente",
	"components.fuwu.253h82": "Tecnologia blockchain.",
	"components.fuwu.64z717": "architettura",
	"components.fuwu.14n9v4": "Membri della squadra.",
	"components.fuwu.ewgac0": "Storia e piano",
	"components.fuwu.yy18w5": "Valore di mercato futuro",
	"components.fuwu.h4l80q": "Considerazioni giuridiche",
	"components.fuwu.1nvu89": "Disclaimer",
	"components.fuwu.yvo758": "Contesto del mercato",
	"components.fuwu.pgt1oc": "da",
	"components.fuwu.4vy2ub": "Anno a",
	"components.fuwu.sxj11f": "All'inizio dell'anno, l'andamento dei titoli di valore è rimasto significativamente inferiore a quello dei titoli di crescita, aggravando la sottoperformance a lungo termine dei fattori di valore a partire dalla crisi finanziaria globale. Alcune persone lo attribuiscono a fattori macroeconomici dei tassi d'interesse&# 8212; Bassi tassi di interesse, rendimenti obbligazionari in calo o curve flat yield.",
	"components.fuwu.ihu32d": "Dopo aver studiato queste affermazioni, si è scoperto che il rapporto tra valore e tasso d'interesse è teoricamente piuttosto vago e complesso. Da una prospettiva empirica, vi è solo una leggera correlazione tra variabili di tasso d'interesse e fattori di valore in determinati parametri. Sebbene ci siano modelli significativi nei recenti campioni di dati che sono correlati alle variazioni dei rendimenti obbligazionari o degli spread di rendimento, il significato economico di queste correlazioni è piccolo e incoerente in altri campioni.",
	"components.fuwu.ynfmrx": "La conclusione è che la performance dei fattori di valore non può essere spiegata da fattori di tasso di interesse e le strategie di temporizzazione dei fattori basate su segnali di tasso di interesse sono difficili da attuare efficacemente. Per recuperare la curva di rendimento degli investimenti precedentemente significativa, occorre prestare attenzione all'economia digitale.",
	"components.fuwu.041265": "È causato da",
	"components.fuwu.7ybglf": "Insieme a dieci dipendenti",
	"components.fuwu.cv8q4k": "Una società di hedge fund fondata a New York in, che fornisce ai clienti soluzioni professionali di problemi, ricerca accademica e metodi innovativi.",
	"components.fuwu.u18kd5": "Nel passato",
	"components.fuwu.345nr1": "Nel corso degli anni,",
	"components.fuwu.uux4kt": "Abbiamo lanciato diverse strategie azionarie internazionali e prodotti di investimento finanziario per aiutare gli investitori a raggiungere un apprezzamento stabile degli asset nel mercato finanziario. reach",
	"components.fuwu.2k2868": "Anno,",
	"components.fuwu.dfo204": "Dimensione delle attività gestite da hedge fund e prodotti finanziari",
	"components.fuwu.rer5h5": "Superato",
	"components.fuwu.y62673": "Miliardi di dollari USA",
	"components.fuwu.mdq3mn": "Alla fine dell'anno,",
	"components.fuwu.944x0p": "La portata dei fondi gestiti a livello globale supera",
	"components.fuwu.64sd2z": "USD100mn",
	"components.fuwu.i2bs5g": "insieme a",
	"components.fuwu.34l46q": "Potenza di calcolo, big data, Internet of Things",
	"components.fuwu.t8n5nh": "Lo sviluppo esponenziale di settori come il cloud computing e l'intelligenza artificiale ha iniziato a liberare la sua potenza, portando alla prosperità dell'industria.",
	"components.fuwu.5765y1": "Ho cercato internet in pochi mesi. Le sue applicazioni nel data mining, nel content insight, nella diagnosi intelligente, nell'analisi predittiva e nell'evoluzione intelligente stanno gradualmente diventando ben note alle persone. Tuttavia, molte persone non sanno che le applicazioni di intelligenza artificiale, come il riconoscimento facciale e la tecnologia di interazione vocale, sono state integrate nella vita quotidiana delle persone.",
	"components.fuwu.138tt4": "Secondo le statistiche, da",
	"components.fuwu.jk42p7": "Nel corso dell'anno, il numero di startup globali di intelligenza artificiale è aumentato",
	"components.fuwu.lu0nu3": "Wanjia, l'importo totale del finanziamento ha raggiunto",
	"components.fuwu.edrz45": "Trillioni di dollari. Molte aziende tecnologiche hanno istituito dipartimenti di intelligenza artificiale, indicando che l'era dell'intelligenza artificiale è arrivata. Nel passato",
	"components.fuwu.0ty944": "Nell'anno,",
	"components.fuwu.267zhq": "Sempre all'avanguardia nell'innovazione cross investment in tecnologia, dati e finanza comportamentale. Esplora e guida continuamente il mercato e applica queste informazioni ai portafogli di investimento dei clienti, aiutando i clienti globali a raggiungere una crescita patrimoniale stabile.",
	"components.fuwu.768x8x": "Con cloud computing, edge computing, big data",
	"components.fuwu.8hhz36": "Con il rapido sviluppo dell'intelligenza artificiale,",
	"components.fuwu.o32734": "Fondatore di",
	"components.fuwu.8hhu5s": "Rendersi conto che il prodotto deve essere coerente con i tempi",
	"components.fuwu.jwkv4j": "Altrimenti, saranno eliminati dal mercato. Oltre a mantenere le attività finanziarie e finanziarie esistenti,",
	"components.fuwu.4m7d39": "a",
	"components.fuwu.n48x5n": "La distribuzione di prodotti di intelligenza artificiale è iniziata nell'anno. Utilizzando la tecnologia di intelligenza artificiale per migliorare l'efficienza, prevedere le tendenze delle applicazioni, integrare i vari dati e utilizzare modelli di trading di frequenza delle onde,",
	"components.fuwu.4ukw28": "Essere in grado di mantenere un tasso medio di restituzione globale del prodotto entro",
	"components.fuwu.ed1635": "Sinistra e destra.",
	"components.fuwu.o0516q": "Approfondire l'avanguardia dell'intelligenza artificiale, sforzandosi di integrare funzionalità come data mining, analisi intelligente e evoluzione intelligente nei prodotti finanziari, consentendo",
	"components.fuwu.7ognk6": "Essere in grado di avanzare il mercato finanziario in modo più accurato, facile e costante. Dopo gli sforzi instancabili della squadra,",
	"components.fuwu.9ye2y0": "Il sistema di trading quantitativo intelligente è stato completato e superato con successo due anni di test.",
	"components.fuwu.hrwn57": "Il sistema di trading quantitativo intelligente è un prodotto finanziario digitale distribuito sulla blockchain, seguendo i suoi prodotti finanziari del mercato azionario. Affidandosi all'economia digitale e all'intelligenza artificiale, gestire le risorse digitali degli utenti in modo più efficiente, preciso, stabile, flessibile, a basso costo e tracciabile. Si tratta di una nuova combinazione di economia, finanza comportamentale, dati e tecnologia, consentendo un apprezzamento più efficace delle risorse dei clienti.",
	"components.fuwu.jb545g": "Anno per",
	"components.fuwu.31h712": "Un dipendente è stato stabilito a New York. Si tratta di un hedge fund che utilizza la ricerca accademica professionale e metodi innovativi per risolvere i problemi dei clienti. Nel passato",
	"components.fuwu.623c56": "Per aiutare gli investitori a raggiungere una crescita stabile nel mercato finanziario sono state lanciate numerose strategie azionarie internazionali e prodotti di investimento finanziario. reach",
	"components.fuwu.o8270p": "Fondi hedge gestiti e attività di prodotti finanziari",
	"components.fuwu.j53w1u": "USD100mn A partire da",
	"components.fuwu.4fogox": "Gestione",
	"components.fuwu.h0al2w": "Un fondo miliardario, grazie al suo enorme impatto globale",
	"components.fuwu.202g6o": "Potenza sonora.",
	"components.fuwu.85g7o1": "Il primo ufficio internazionale è stato aperto in Australia.",
	"components.fuwu.2ubf7n": "Nel Regno Unito è stato istituito un ufficio.",
	"components.fuwu.ud1j47": "L'Asset Management Research Institute è stato istituito presso la London Business School per promuovere la ricerca sulla gestione patrimoniale.",
	"components.fuwu.z45lgv": "Abbiamo aperto un ufficio a Hong Kong e ampliato la nostra attività in Asia.",
	"components.fuwu.4582f8": "Un centro di ingegneria è stato istituito a Bangalore, India.",
	"components.fuwu.m00yuh": "Un nuovo ufficio è stato istituito in Germania, espandendo ulteriormente la sua influenza globale. soggiorno",
	"components.fuwu.3r7069": "Anno.",
	"components.fuwu.jwwo48": "E' riconosciuto.",
	"components.fuwu.jnvf5m": "Anno a",
	"components.fuwu.l0bqqt": "Migliore società di gestione dei fondi pensione e di investimento dell'anno.",
	"components.fuwu.e777k5": "hanno",
	"components.fuwu.j0u235": "Un team di partner e quasi un migliaio di dipendenti, che possiedono",
	"components.fuwu.8d8hnh": "Un team di ricerca con anni di esperienza nella ricerca, e ha pubblicato un gran numero di articoli in top riviste accademiche. L'azienda combinerà una solida esperienza pratica con strumenti quantitativi per gestire le informazioni di base e gestire i rischi. Adottare un approccio sistematico guidato dalla ricerca per gestire strategie alternative e tradizionali è un approccio raro; ricerca accademica",
	"components.fuwu.y17m3k": "investimenti quantitativi; Compagnia Dual Drive.",
	"components.fuwu.59t85m": "nascita",
	"components.fuwu.xd3f51": "da quando",
	"components.fuwu.p1o1m9": "Dall'inizio di quest'anno,",
	"components.fuwu.s6631u": "Ho pensato all'applicazione dell'intelligenza artificiale in campo finanziario per risolvere i punti dolorosi delle transazioni manuali.",
	"components.fuwu.654rrg": "Avviato formalmente l'istituzione del Centro di Ricerca sul Sistema di Trading Quantitativo di Intelligenza Artificiale e redatto il primo progetto",
	"components.fuwu.3w3i3q": "Libro bianco sui sistemi di scambio quantitativo intelligenti. Ricerca e risolvere i problemi relativi all'intelligenza artificiale e ai modelli di trading, stabilire una piattaforma di calcolo dell'intelligenza artificiale e costruire un sistema di trading quantitativo di intelligenza artificiale.",
	"components.fuwu.315354": "Intelligenza artificiale applicata con successo al trading e sviluppata",
	"components.fuwu.oef1gv": "Sistema di scambio quantitativo intelligente. Partendo dalla raccolta dei dati, il sistema utilizza l'intelligenza artificiale per acquisire i dati di mercato, inclusi i prezzi storici, il volume di trading, il sentiment del mercato, ecc. Quindi analizzare ed esplorare il mercato, impostare più strategie di trading, stabilire diversi modelli di trading e generare segnali di trading corrispondenti basati su strategie di trading e parametri di controllo del rischio preimpostati. Il sistema opera attraverso il trading automatico.",
	"components.fuwu.kinmrh": "Il sistema di trading quantitativo intelligente è una piattaforma di trading intelligente sviluppata e convalidata congiuntamente dai fondi di Hong Kong e dai migliori team tecnologici blockchain in Germania. Utilizza tecnologie informatiche avanzate come intelligenza artificiale, blockchain e big data per ottenere una gestione decentralizzata delle transazioni finanziarie.",
	"components.fuwu.1khwn2": "L'algoritmo tecnologico di base del sistema di trading quantitativo intelligente utilizza la tecnologia blockchain per ottenere transazioni finanziarie decentralizzate globali senza restrizioni geografiche.",
	"components.fuwu.e5g3fa": "Il sistema di scambio quantitativo intelligente servirà anche da base per il futuro ecosistema dell'economia digitale",
	"components.fuwu.7ftmw3": "&#8220; Assistente;, Fornire soluzioni di trading quantitative per transazioni finanziarie globali e ottenere una rapida implementazione di applicazioni blockchain efficienti. Semplifica la transazione e lo scambio di asset e asset digitali. Esso prevede inoltre importanti applicazioni finanziarie",
	"components.fuwu.4646q8": "Interfacce chiave, collegando vari scenari applicativi, ottenendo una perfetta integrazione degli asset digitali con varie applicazioni industriali. Ciò fornisce agli investitori un ambiente di investimento sicuro, veloce, fluido e stabile. soggiorno",
	"components.fuwu.kh359j": "Nell'ambito del sistema di trading quantitativo intelligente, il potere dell'intelligenza artificiale sarà utilizzato per costruire un ecosistema di servizi di applicazione finanziaria con super intelligenza e capacità super veloci. Questo permetterà a più persone di godere della super esperienza portata dall'era digitale, con indicazioni chiare per velocità, sicurezza, stabilità e decentramento. Mira a creare un nuovo ecosistema che applichi l'intelligenza artificiale alla digitalizzazione degli asset, fornendo libertà e innovazione.",
	"components.fuwu.wsp5m5": "La visione di un sistema di trading quantitativo intelligente si basa sul pensiero dell'investment banking e sui modelli di venture capital. Con la tecnologia dell'intelligenza artificiale come base e facendo affidamento sui big data, forniamo agli investitori prodotti di investimento convenienti, sicuri e affidabili per ottenere rendimenti stabili a lungo termine.",
	"components.fuwu.861380": "I fattori di valore portano rendimenti interessanti a lungo termine, ma possono anche essere influenzati da periodi difficili, che possono essere dolorosi per gli investitori. Tuttavia, per chi persevera, la successiva ripresa economica è redditizia.",
	"components.fuwu.igh8q8": "Sistemi di trading quantitativi intelligenti possono aiutare gli investitori a superare il disagio emotivo nei momenti difficili e ottenere rendimenti di valore a lungo termine.",
	"components.fuwu.u7cy1f": "Revisione di",
	"components.fuwu.502xa6": "I cambiamenti verificatisi dall'inizio dell'anno non hanno determinato cambiamenti sostanziali nei tassi di rendimento attesi di molte classi di attività.",
	"components.fuwu.yg0781": "Sistemi di scambio quantitativi intelligenti possono risolvere efficacemente questi problemi.",
	"components.fuwu.k7s41z": "I big data mostrano che la perdita netta causata dall'implementazione di una strategia sistematica di raccolta diretta degli indici diminuisce gradualmente nei primi anni successivi all'implementazione. Queste strategie mostrano anche risultati di perdita netta altamente diversificati. Pertanto, da",
	"components.fuwu.pw7jg1": "La strategia long short di investimento basato su sistemi di trading quantitativi intelligenti può superare significativamente la strategia dell'indice diretto.",
	"components.fuwu.9gya07": "Il premio previsto per gli investimenti nei mercati emergenti e nei mercati azionari sviluppati è passato",
	"components.fuwu.yfiuur": "L'estremità superiore dell'intervallo osservato annualmente. Allo stesso tempo, molti rischi associati ai mercati emergenti nella storia sono diminuiti da molto tempo. Gli investitori hanno molte ragioni per aumentare la loro allocazione ai mercati emergenti, compresi gli asset digitali.",
	"components.fuwu.j8nq18": "Valori fondamentali",
	"components.fuwu.40fdvu": "Negli ultimi vent'anni,",
	"components.fuwu.w5kk35": "Abbiamo esplorato continuamente i fattori trainanti del mercato e come applicarli ai portafogli di investimento dei clienti nei settori dell'economia, della finanza comportamentale, dei dati e della tecnologia. Con l'ascesa dei big data e dell'intelligenza artificiale,",
	"components.fuwu.pg3e4s": "Le strategie e i modelli di trading stanno diventando sempre più complessi.",
	"components.fuwu.kcjcom": "Il sistema di trading quantitativo intelligente è entrato nella fase di test ed è ora ufficialmente aperto al mondo attraverso l'ufficio di Hong Kong.",
	"components.fuwu.n21shs": "Il sistema di trading quantitativo intelligente si basa sul pensiero dell'investment banking e sui modelli di capitale di rischio, con la tecnologia dell'intelligenza artificiale come fulcro. Si basa sui big data per stabilire modelli di trading quantitativi, con l'obiettivo di fornire agli investitori prodotti di investimento sicuri e convenienti, nonché rendimenti stabili a lungo termine.",
	"components.fuwu.0l7u49": "Utilizzando la tecnologia blockchain,",
	"components.fuwu.lgcse5": "Il sistema di trading quantitativo intelligente ha sviluppato una rete di calcolo distribuita, a basso costo, che preserva la privacy ad alte prestazioni e fornisce prodotti finanziari completi.",
	"components.fuwu.0t263a": "I nodi di calcolo ad alte prestazioni forniscono potenti capacità di calcolo per i modelli di transazione, tra cui formazione e inferenza sull'intelligenza artificiale, scraping dei dati e zero knowledge computing blockchain.",
	"components.fuwu.f5s17o": "I nodi di calcolo ad alte prestazioni possono assumere varie forme, tra cui l'esecuzione su larga scala",
	"components.fuwu.u2615w": "o",
	"components.fuwu.a5en87": "Nodi completamente funzionali sui cluster server",
	"components.fuwu.d4f145": "Nodo permanente",
	"components.fuwu.29o1r5": "nonché da piccole e medie imprese e privati",
	"components.fuwu.r54iix": "Idle",
	"components.fuwu.5zx75g": "Nodo di calcolo del server.",
	"components.fuwu.6156mg": "Il sistema di trading quantitativo intelligente garantisce il funzionamento sicuro e stabile del sistema, consentendo a tutti gli investitori di trarne beneficio.",
	"components.fuwu.p54y10": "soggiorno",
	"components.fuwu.o69yz3": "Nel processo di trading quantitativo intelligente, le capacità di elaborazione e apprendimento dell'intelligenza artificiale sono cruciali. Rende i sistemi di trading più intelligenti, accurati e automatizzati, consentendo decisioni di trading più efficaci e gestione del rischio.",
	"components.fuwu.h1r7s5": "I principi di progettazione di un sistema di trading quantitativo intelligente sono i seguenti:",
	"components.fuwu.x27379": "Principio di scalabilità",
	"components.fuwu.rhqg19": "I vari moduli di un sistema di trading quantitativo intelligente dovrebbero essere accoppiati liberamente, in modo che nuovi moduli possano essere facilmente aggiunti senza cambiare le interfacce di altri moduli.",
	"components.fuwu.c7uqex": "Feedback degli utenti dei prodotti dei clienti",
	"components.fuwu.46e7x1": "L'accesso a sistemi di trading quantitativi intelligenti è volatile. Quando un gran numero di utenti accede a un singolo nodo, può portare a crash aziendali. Pertanto, il contenitore del nodo dovrebbe essere in grado di essere distribuito automaticamente, consentendo una rapida espansione orizzontale quando è sotto pressione dalle richieste degli utenti.",
	"components.fuwu.xc8u4k": "Principio della privacy",
	"components.fuwu.1n531t": "Gli investitori, i nodi informatici, le fonti di dati e altre componenti del sistema di trading quantitativo intelligente godono di misure di protezione della privacy.",
	"components.fuwu.ed16w3": "Principi fondamentali",
	"components.fuwu.7h553b": "Che cosa è",
	"components.fuwu.k4l2md": "Scambio quantitativo intelligente",
	"components.fuwu.7nve0q": "Il trading quantitativo intelligente si riferisce all'uso di tecnologie e algoritmi di intelligenza artificiale per eseguire automaticamente le decisioni di investimento al fine di massimizzare l'efficienza del trading. Si tratta di una strategia di trading ad alta frequenza basata su tecnologie come big data, machine learning e deep learning. Attraverso l'analisi rapida dei dati di mercato e la programmazione automatizzata. Identificare le migliori opportunità di trading ed eseguire operazioni",
	"components.fuwu.g21385": "Il vantaggio del trading quantitativo intelligente sta nella sua capacità di analizzare rapidamente i dati di mercato, eseguire automaticamente le decisioni di trading ed evitare interferenze umane e errori di giudizio. Inoltre, può ottimizzare ed espandere le transazioni su un mercato più ampio e su larga scala. Inoltre,",
	"components.fuwu.v75es3": "Il sistema di trading intelligente imparerà continuamente attraverso l'intelligenza artificiale. Attraverso la formazione dei dati a lungo termine e l'ottimizzazione dei modelli, creare modelli di trading migliori per adattarsi ai cambiamenti del mercato e aiutare gli utenti a raggiungere una redditività a lungo termine.",
	"components.fuwu.rcm80u": "Il processo di transazione è",
	"components.fuwu.2x15l9": "Nei sistemi di trading quantitativo intelligenti, la tecnologia di intelligenza artificiale viene applicata per raggiungere la capacità decisionale autonoma nelle transazioni intelligenti. Può eseguire automaticamente transazioni basate su strategie e parametri di trading preimpostati e analizzare e prevedere in modo intelligente in base alle condizioni di mercato e ai dati storici, ottenendo decisioni di trading più intelligenti e autonome. Nello specifico,",
	"components.fuwu.xe1844": "Il sistema di trading quantitativo intelligente utilizza una grande quantità di dati storici e informazioni di mercato per data mining e machine learning. Costruisce modelli di trading e algoritmi, monitora e analizza il mercato in tempo reale, ottimizza e regola i segnali di trading e le strategie per ottenere decisioni di trading più efficaci e gestione del rischio. Inoltre, i sistemi di trading quantitativo guidati dall'IA migliorano e migliorano continuamente le loro strategie e algoritmi di trading attraverso la tecnologia AI e le capacità di auto-apprendimento, ottenendo decisioni di trading più intelligenti e autonome. Pertanto,",
	"components.fuwu.8jr1h7": "&#8220; Intelligente; Rappresenta il livello di intelligenza e la capacità decisionale autonoma dei sistemi di trading quantitativi di intelligenza artificiale. Può ottenere rendimenti più efficienti, accurati e maggiori per gli investitori. E le decisioni di trading automatizzate e la gestione del rischio portano",
	"components.fuwu.62r19x": "Nel commercio quantitativo intelligente,",
	"components.fuwu.1v4571": "&#8220; Quantificazione; Rappresenta la quantificazione e l'analisi dei dati di mercato e delle strategie di trading. Il trading quantitativo è un algoritmo digitale basato sulla raccolta, l'analisi e l'elaborazione di grandi quantità di dati, trasformando le informazioni di mercato e le strategie di trading in processi decisionali e trading automatici. Nello specifico,",
	"components.fuwu.35fgx0": "Il sistema di trading quantitativo intelligente raccoglie una grande quantità di dati di mercato, inclusi i prezzi storici, il volume di trading, il sentiment del mercato, ecc., per analizzare ed esplorare il mercato. Costruisce varie strategie di trading basate su questi dati. Utilizzando queste strategie di trading e parametri predefiniti di controllo del rischio, il sistema genera segnali di trading corrispondenti ed esegue il trading automatico. In questo processo, la quantificazione e l'analisi dei dati rendono il sistema di trading più intelligente, accurato e automatizzato, ottenendo così decisioni di trading più efficaci e una gestione del rischio. Pertanto,",
	"components.fuwu.ce4mr4": "&#8220; Quantificazione; Rappresenta",
	"components.fuwu.cfq4pv": "La tecnologia di base del commercio quantitativo. È un mezzo importante per digitalizzare le informazioni di mercato e le strategie di trading, che possono ottenere decisioni di trading più veloci, accurate e automatizzate e il controllo del rischio.",
	"components.fuwu.6b0w31": "vantaggio",
	"components.fuwu.7q677y": "efficienza",
	"components.fuwu.624ufa": "Il sistema di trading quantitativo intelligente esegue automaticamente strategie di trading, che possono catturare rapidamente e accuratamente le opportunità di trading evitando errori umani e interferenze emotive.",
	"components.fuwu.6k6y74": "accurato",
	"components.fuwu.y23qq8": "Il sistema di trading quantitativo intelligente utilizza una grande quantità di dati storici e informazioni di mercato per l'analisi dei dati e la previsione del modello, migliorando l'accuratezza e l'affidabilità delle decisioni di trading.",
	"components.fuwu.s11cqu": "stabilità",
	"components.fuwu.3q6d66": "Il sistema di trading quantitativo intelligente implementa una gestione rigorosa del rischio e un controllo del trading per garantire la stabilità e i rendimenti a lungo termine dei portafogli di investimento.",
	"components.fuwu.w386vn": "flessibilità",
	"components.fuwu.530y3q": "Il sistema di trading quantitativo intelligente può adattarsi rapidamente ai cambiamenti del mercato e alle nuove opportunità di trading, consentendo allo stesso tempo strategie di trading personalizzate e parametri basati sulle preferenze degli investitori.",
	"components.fuwu.ph3l81": "Tracciabilità",
	"components.fuwu.7ap85x": "Il sistema di trading quantitativo intelligente registra e traccia i dettagli e i dati storici di ogni transazione, facilitando gli investitori a condurre analisi e ottimizzazione successive.",
	"components.fuwu.v2jkcm": "Ecosistema",
	"components.fuwu.ics30f": "Sistema di scambio quantitativo intelligente",
	"components.fuwu.0nui8m": "Come nucleo principale, ha raggiunto la fornitura decentralizzata di calcolo ad alte prestazioni. L'obiettivo di questo sistema è quello di costruire infrastrutture come servizio per l'intelligenza artificiale",
	"components.fuwu.n868ra": "Strato. Tutte le piattaforme come servizio che richiedono un calcolo ad alte prestazioni",
	"components.fuwu.ns02co": "E il software come servizio",
	"components.fuwu.f94h07": "Tutti i servizi layer possono essere consultati su",
	"components.fuwu.6ftu9v": "Funzionamento libero su sistemi intelligenti di scambio quantitativo.",
	"components.fuwu.b19msv": "Tecnologia blockchain",
	"components.fuwu.864583": "Il nucleo di un sistema di trading quantitativo intelligente risiede nella tecnologia della catena pubblica sottostante, che combina modelli finanziari, intelligenza artificiale e trading quantitativo per gestire un elevato throughput delle transazioni. Pertanto,",
	"components.fuwu.61j6bp": "La catena principale del sistema di scambio quantitativo intelligente adotta",
	"components.fuwu.q1j1n6": "Meccanismo di consenso, combinato con",
	"components.fuwu.30nl6v": "La praticità del sistema ha migliorato la tolleranza ai guasti, l'efficienza delle transazioni e la frequenza di interazione. Questa rete è composta da",
	"components.fuwu.3kqnle": "Composto da super nodi, responsabile della verifica delle transazioni e della generazione dei blocchi. Oltre ai super nodi, ci sono anche nodi regolari che sincronizzano i dati con i nodi core, gestiscono chiavi e indirizzi privati del portafoglio e avviano transazioni a catena. Per soddisfare le esigenze di modellazione di intelligenza artificiale e commercio quantitativo ad alta frequenza,",
	"components.fuwu.i1g6e7": "Il sistema di scambio quantitativo intelligente promuove continuamente lo sviluppo della tecnologia della catena pubblica sottostante. Ottimizzando il meccanismo di consenso e utilizzando la tecnologia della catena laterale, è possibile raggiungere livelli più elevati di consenso",
	"components.fuwu.j3spal": "Gestione di più transazioni",
	"components.fuwu.d6r6r2": "Gestisci più transazioni contemporaneamente e supporta un aumento del traffico utente.",
	"components.fuwu.rfs8eb": "Il sistema di trading quantitativo intelligente ha implementato un meccanismo cross chain e introdotto un meccanismo di protezione della privacy a prova di conoscenza zero per garantire che le informazioni degli utenti non siano trapelate. Il sistema si registra sulla blockchain in modo unificato",
	"components.fuwu.5l87r6": "Le applicazioni assicurano che, una volta confermate le risorse tramite smart contract, tutti i dati siano aperti, trasparenti e a prova di manomissione. Pertanto, utilizzando",
	"components.fuwu.ci8bt7": "La negoziazione di asset condivisi si basa su dati completamente affidabili, eliminando il rischio di falsi asset o transazioni false.",
	"components.fuwu.li76g2": "Architettura generale",
	"components.fuwu.2881li": "I nodi di rete dei sistemi di trading quantitativi intelligenti possono essere grandi nodi di calcolo come grandi pool minerari, così come",
	"components.fuwu.u15fc6": "Nodi di calcolo di medie dimensioni o risorse di cloud computing fornite da piattaforme come Alibaba Cloud.",
	"components.fuwu.2w1u75": "Le capacità di cloud e edge computing acquisite dal sistema di trading quantitativo intelligente garantiscono la stabilità dell'ambiente operativo AI. Una volta investitori",
	"components.fuwu.6cu4ph": "Deposito",
	"components.fuwu.88fccf": "Sistema di scambio quantitativo intelligente, l'intelligenza artificiale sarà basata su",
	"components.fuwu.4gfpl8": "Cerca, identifica, stratifica, gestisci e salva in base alla quantità. successivamente",
	"components.fuwu.5vdauc": "adottare",
	"components.fuwu.0ud3oi": "Nella rete, corrispondente",
	"components.fuwu.8sm2by": "E i nodi di calcolo hanno trovato soluzioni adatte per questo lotto",
	"components.fuwu.189o1y": "La modalità di transazione della blockchain. La potenza di calcolo richiesta per questo modello è assegnata al corrispondente",
	"components.fuwu.y83nn2": "Realizzare scambi quantitativi.",
	"components.fuwu.78ik3b": "3.2.2 Architettura dei nodi informatici",
	"components.fuwu.0wdoj7": "Calcolo dell'architettura dei nodi",
	"components.fuwu.25g0dk": "3.2.3 Big Data+Intelligenza Artificiale",
	"components.fuwu.r2e1x7": "Il sistema di trading quantitativo intelligente include una rete di calcolo distribuita ad alte prestazioni, che è essenzialmente un'infrastruttura che combina big data e intelligenza artificiale. Presenta le seguenti caratteristiche",
	"components.fuwu.n4ct1t": "Il framework sottostante è costruito sulla piattaforma Matrix e adotta un'architettura software basata su abbonamento Topic, elaborazione guidata da eventi e processore. Attraverso l'architettura asincrona ad alte prestazioni, può raggiungere prestazioni eccellenti. La struttura multi catena è costituita da una catena principale e da una catena di lavoro. La catena principale contiene definizioni di modelli per tutte le catene di lavoro e il numero di catene di lavoro è illimitato. Ogni catena di lavoro è composta da blockchain sharded e supporta lo sharding infinito. I dati dei blocchi sono definiti in base ai modelli, consentendo dimensioni flessibili dei blocchi e supportando l'archiviazione compressa dei dati dei blocchi.",
	"components.fuwu.b9yr3c": "La comunicazione e la trasmissione all'interno del sistema di scambio quantitativo intelligente adottano il protocollo di compressione di auto codifica o il protocollo di trasmissione crittografato. Ciò può ridurre la larghezza di banda della rete e negoziare diversi protocolli di codifica dei pacchetti di messaggi all'interno dello stesso collegamento, migliorando la sicurezza della trasmissione.",
	"components.fuwu.92179q": "Rete 3D",
	"components.fuwu.y24z29": "Basato su reti peer-to-peer (P2P), stabilisce una rete stratificata per accelerare la propagazione dei messaggi. I nodi SN hanno diversità geografica e possono regolare dinamicamente la topologia della rete per migliorare la robustezza complessiva della rete, resistere agli attacchi DDoS e ridurre i rischi per la sicurezza. Meccanismo di consenso della rete: adozione del DPOS; Il meccanismo di consenso (Delegated Proof of Stake) valuta la partecipazione alla rete in base ai diritti e ai contributi degli utenti. Adotta un metodo di elaborazione gerarchico per l'archiviazione, il consenso e la verifica, migliorando la scalabilità dell'intera rete.",
	"components.fuwu.i5s5w9": "3.2.4 Struttura della rete",
	"components.fuwu.248t2x": "Le soluzioni mainstream per affrontare la scalabilità blockchain possono essere suddivise in due tipi",
	"components.fuwu.75e81d": "Scalabilità verticale e scalabilità orizzontale. Le tipiche soluzioni di scalabilità verticale includono l'ottimizzazione degli algoritmi di consenso, lo storage di massa e il miglioramento delle prestazioni del sistema, come l'espansione dei blocchi e EOS; DPOS e VRF+POS+PBFT. Le soluzioni di scalabilità orizzontale tipiche includono reti lampo, architetture multi-chain e architetture shard, tutte volte ad affrontare i problemi di scalabilità e scalabilità della blockchain. Blockchain 4.0 mira a costruire sistemi di business distribuiti ad alte prestazioni su reti decentralizzate su larga scala, con particolare attenzione all'effettiva implementazione aziendale. Nessun singolo miglioramento tecnologico può risolvere completamente il problema complessivo di scalabilità. Il design complessivo dell'architettura di qualsiasi blockchain deve considerare quattro elementi chiave: architettura di rete P2P, architettura di storage sottostante, architettura di calcolo/consenso e progettazione dei processi aziendali. Inoltre, nel mondo della blockchain, ci possono essere solo due attributi di progettazione per decentramento, scalabilità e sicurezza, e tutti i progetti di scalabilità si basano su questo principio.",
	"components.fuwu.854i8h": "Il sistema di trading quantitativo intelligente adotta un'architettura multi catena, migliorando la scalabilità dell'intera rete. Utilizzando l'isolamento fornito da più catene, è possibile ottenere un isolamento sicuro",
	"components.fuwu.kw1e7d": "Dati DApp. Con l'esecuzione parallela di catene multiple, le prestazioni del sistema possono crescere linearmente. Tuttavia, in un'architettura multi-catena, esistono ancora strozzature prestazionali per una singola catena. Pertanto, l'introduzione della tecnologia di sharding migliora ulteriormente le prestazioni delle singole blockchain e raggiunge una scalabilità orizzontale. Introdurre router a catena trasversale e strati di relè a catena trasversale tra catene multiple, sostenendo il relè di sottocatene omogenee e catene laterali eterogenee. Ancorando la sub catena/catena laterale per stabilire la catena principale e interconnettendo più catene, è stata creata un'architettura blockchain con scalabilità infinita.",
	"components.fuwu.y11qiw": "3.2.5 Architettura frammentata in",
	"components.fuwu.q7u6kb": "Lo scopo principale dell'utilizzo dello sharding in sistemi di trading quantitativi intelligenti è quello di migliorare le prestazioni delle singole blockchain e la scalabilità della rete globale. Ogni shard elabora diversi sottoinsiemi di transazioni in parallelo, raggiungendo il consenso all'interno dello shard e l'esecuzione parallela delle transazioni.",
	"components.fuwu.k61o0n": "3.2.5.1 L'architettura completa dello sharing si compone di tre parti: sharing di rete, sharing computazionale (transaction/consensus) e sharing di storage (sharing di stato). In primo luogo,",
	"components.fuwu.4t6qe4": "Il sistema di trading quantitativo intelligente divide i nodi della rete in diversi shard virtuali in base alle regole di sharding",
	"components.fuwu.7yx4qo": "(noto anche come cluster). Assicura la connettività all'interno di ogni shard e la connettività di rete tra i nodi all'interno dello shard e la rete principale. Le transazioni all'interno di uno shard vengono propagate solo all'interno di tale shard per ridurre il consumo di larghezza di banda. In secondo luogo, tutti i nodi che partecipano al consenso devono fornire un deposito e registrarsi sul contratto intelligente. Le transazioni che si verificano in shard diversi sono confezionate e convalidate dai corrispondenti nodi di consenso shard. A causa del fatto che diverse transazioni con shard vengono verificate in parallelo tra shard, le prestazioni di consenso possono essere scalate linearmente. Con il miglioramento delle prestazioni di rete, diventa impossibile memorizzare l'intero registro dei dati su ogni nodo della rete. Supponendo che le prestazioni complessive della rete siano di 1 milione di TPS, lo spazio di archiviazione giornaliero dei dati del registro per ogni nodo supererà il 20%; TB. Per risolvere questo problema,",
	"components.fuwu.2wb32r": "Il sistema di trading quantitativo intelligente separa il livello di archiviazione dal livello di calcolo e utilizza un file system di registro per memorizzare i dati del registro",
	"components.fuwu.69229m": "Manutenzione congiunta della rete intelligente del sistema di scambio quantitativo.",
	"components.fuwu.l65907": "3.2.5.2 Ogni nodo di una rete P2P shard virtuale programmabile ha un PeerID indipendente, che forma una rete P2P strutturata o non strutturata con altri nodi. In base ai diversi requisiti aziendali, i nodi di rete utilizzano contratti intelligenti per lo sharing virtuale in cima alle reti P2P fisiche. Ogni nodo calcola il proprio vShard programmaticamente; ID, appartenente allo stesso vShard&nbsp; I nodi con ID formano logicamente uno shard di rete indipendente. Ogni shard di rete proviene fisicamente da una rete P2P unificata. Tuttavia, è logicamente isolato e fornisce l'indipendenza logica necessaria per la progettazione della scalabilità della rete. Ogni nodo di rete P2P indipendente può logicamente appartenere a più shard virtuali. È necessario stabilire connessioni di rete tra nodi di sharing per formare una rete regionale autonoma di sharing. Lo sharding è dinamico ed elastico, consentendo alla rete di aumentare in modo flessibile il numero di schegge. La rete principale può ancora stabilire reti P2P non strutturate e strutturate.",
	"components.fuwu.255q2n": "3.2.5.3 Calcolare nodi di consenso shard: suddivisi in nodi di consenso globale e nodi di consenso interni. I nodi di consenso globale sono responsabili del raggiungimento del consenso nella rete principale, mentre i nodi di consenso all'interno degli shard sono responsabili dell'elaborazione del consenso all'interno di ogni shard. Il consenso può adottare meccanismi plugin, consentendo a diversi shard di supportare diversi meccanismi di consenso. Protocollo di generazione casuale distribuito: i protocolli casuali devono avere equità, imprevedibilità, verificabilità di terze parti e scalabilità. Il nodo principale di consenso degli shard utilizza l'algoritmo VRF per allocare gli shard ed è casualmente responsabile del consenso tra diversi shard. I nodi di consenso di diversi shard gestiscono le transazioni all'interno dei rispettivi shard. Selezione dei nodi testimoni: seleziona casualmente i nodi dal pool di nodi di consenso shard per partecipare al processo di consenso effettivo. I nodi di consenso prevengono attacchi dannosi attraverso elezioni regolari. Il numero di nodi di consenso per ogni frammento può essere aumentato in modo flessibile. I nodi di consenso prevengono attacchi dannosi attraverso elezioni regolari. Il numero di nodi di consenso per ogni frammento può essere aumentato in modo flessibile.",
	"components.fuwu.vr9w4q": "3.2.5.4 Suddivisione delle operazioni",
	"components.fuwu.524h25": "Il sistema di trading quantitativo intelligente divide le transazioni in transazioni intra shard e cross shard.",
	"components.fuwu.25ebl0": "L'indirizzo della transazione shard è composto dall'indirizzo della transazione e vShard; Composizione identificativa. Se l'indirizzo del mittente e l'indirizzo del destinatario di una transazione appartengono allo stesso shard, la transazione è una transazione intra shard. Se gli indirizzi del mittente e del destinatario appartengono a diversi shard, si tratta di una transazione cross shard. Assicurazione; Will&nbsp; Be&nbsp; Ciascuno; A&nbsp; E&nbsp; lavoratori; The&nbsp; Transazione intra shard TSA: la transazione si propaga solo all'interno dello shard vShard ed è confezionata e convalidata da nodi di consenso shard. Transazione cross shard: confezionata e verificata dal nodo principale di consenso shard",
	"components.fuwu.737wf6": "3.2.6 Crawler web",
	"components.fuwu.6ub735": "Il funzionamento di sistemi di trading quantitativi intelligenti si basa sulla capacità di raccogliere, analizzare e modellare i dati.",
	"components.fuwu.3917n5": "Il sistema di trading quantitativo intelligente utilizza l'intelligenza artificiale per raccogliere regolarmente e quantitativamente dati dall'intera rete attraverso metodi basati su cloud. Passa attraverso",
	"components.fuwu.540486": "Integrazione senza soluzione di continuità con sistemi quantitativi di intelligenza artificiale. Successivamente, il sistema esegue filtraggio, analisi e modellazione per fornire informazioni visive per il personale backend e supporto dati per l'intelligenza artificiale.",
	"components.fuwu.i576fn": "Membri del team",
	"components.fuwu.8a523u": "Cliff&nbsp; Asness è un rinomato economista e premio Nobel 2013 in economia, Eugene; Sotto la guida del professor Fama, ha conseguito una doppia laurea presso la Wharton School of Business e la School of Engineering and Applied Sciences presso l'Università della Pennsylvania.",
	"components.fuwu.clunb8": "Le società di gestione del capitale sono uno dei più famosi hedge fund quantitativi di Wall Street. Una volta ha servito come",
	"components.fuwu.w47xsg": "Goldman,&nbsp; Sachs&nbsp&& Nbsp; L'Amministratore Delegato e Direttore della Ricerca Quantitativa del Dipartimento Asset Management della Co. Lui è il; Gazzetta; di; Portfolio&nbsp; Membro del Comitato Editoriale di Gestione, Membro del Comitato Direttivo della Scuola di Matematica e Finanza dell'Università di New York, Membro del Consiglio Direttivo di Q-Group, Membro del Consiglio Direttivo dell'International Rescue Committee, e Membri del consiglio di amministrazione di Ntn",
	"components.fuwu.8v041z": "David",
	"components.fuwu.1yi18o": "&#183; Fondatore di Kabila",
	"components.fuwu.f126cm": "Anni di esperienza in investimenti finanziari,",
	"components.fuwu.551s9t": "Master Certificato in Business Administration, Laurea triennale, Northwestern University, Vice Presidente di Goldman Sachs, Sachs Company",
	"components.fuwu.638xk0": "Liu John EPOCH EPT",
	"components.fuwu.2d27j5": "principale",
	"components.fuwu.891s7f": "Con anni di esperienza in investimenti finanziari, ha conseguito un dottorato di ricerca in Business Administration e un Bachelor of Arts presso l'Università di Chicago. Ha conseguito un Bachelor of Economics presso l'Università di Chicago, dove è stato selezionato come",
	"components.fuwu.khhc66": "E continuare ad ottenere l'adesione a Chicago",
	"components.fuwu.qad15u": "E un dottorato in finanza.",
	"components.fuwu.ummboy": "Paul",
	"components.fuwu.f1uc41": "&#183; Jacobs",
	"components.fuwu.7du1a3": "Questo leader tecnico EPOCH EPT ha precedentemente lavorato come ingegnere software presso Google e possiede l'Indian Institute of Technology.",
	"components.fuwu.7964ma": "Laurea triennale presso il campus, master presso la Stanford University e master presso la Wharton School of Business presso l'Università della Pennsylvania",
	"components.fuwu.7m912c": "Laurea. essere responsabile di",
	"components.fuwu.nj2151": "Ho anche lavorato per Google personalmente e ho partecipato allo sviluppo del sistema operativo Google.",
	"components.fuwu.itkrrj": "Il CEO ha familiarità con questa applicazione.",
	"components.fuwu.c7gc6f": "Michelle",
	"components.fuwu.27102u": "&#183; Agassi ha 25 anni di esperienza lavorativa, con un dottorato al MIT e una laurea alla Brown University",
	"components.fuwu.5549r8": "Posizione:",
	"components.fuwu.qtx3m3": "EPOCH; EPT; Specialista di IA quantitativa a breve termine di 3-5 giorni",
	"components.fuwu.xj5p65": "Famoso detto: Il mercato cambia rapidamente, coglie le opportunità a breve termine, entra velocemente e esce velocemente.",
	"components.fuwu.nbk6k7": "Grego",
	"components.fuwu.o11m0w": "&#183; M; Andrade ha 21 anni di esperienza, con un dottorato di ricerca presso l'Università di Chicago e una laurea presso il Massachusetts Institute of Technology",
	"components.fuwu.58snmu": "EPOCH; EPT; Specialista di IA quantitativa a breve termine di 5-10 giorni",
	"components.fuwu.sdve3x": "Quotazione famosa: Esperienza di mercato abbondante combinata con infinito",
	"components.fuwu.3no8us": "Intelligenza artificiale, i benefici sono così semplici.",
	"components.fuwu.8ru302": "Bradley",
	"components.fuwu.6zk9gb": "&#183; D; Asnis ha 25 anni di esperienza lavorativa, con un dottorato in giurisprudenza presso la New York Law School e un Bachelor of Arts presso la Brandeis University",
	"components.fuwu.748c4r": "EPOCH; EPT; 15-20 giorni specialista di IA quantitativa a breve termine",
	"components.fuwu.ykoem3": "Famoso detto: un tempo adeguato può portare migliori opportunità e rendere i profitti più stabili.",
	"components.fuwu.c8741k": "Jeffrey",
	"components.fuwu.mnj8g5": "&#183; Borduke ha 19 anni di esperienza come analista finanziario chartered con un Bachelor of Arts presso il Bates College e un Master in Business Administration presso l'Università di Chicago;",
	"components.fuwu.pt4258": "EPOCH; EPT; 30-50 giorni specialista di IA quantitativa a breve termine",
	"components.fuwu.yhx273": "Famoso detto: sono molto ottimista sugli investimenti stabili e a lungo termine, e non c'è niente di meglio di questo.",
	"components.fuwu.l1axrb": "Eventi temporali",
	"components.fuwu.425wcf": "/Pietre miliari",
	"components.fuwu.n33tpy": "Istituito e lanciato il primo prodotto di hedge fund nel 1998.",
	"components.fuwu.hch6ip": "Nel 2000 è stata lanciata la prima strategia azionaria internazionale, e i suoi rendimenti hanno ottenuto il successo internazionale.",
	"components.fuwu.51k20j": "Nel 2016 è stato aperto un ufficio a Hong Kong per espandere il suo business asiatico.",
	"components.fuwu.e7b8ez": "Nel quarto trimestre del 2016, EPOCH EPT ha istituito un Centro di Ricerca sull'Intelligenza Artificiale a Hong Kong",
	"components.fuwu.gk53k8": "Q2 2019",
	"components.fuwu.51y6x1": "Versione 1.0 White Paper Release",
	"components.fuwu.7p2h89": "Sviluppare la blockchain pubblica EPOCH EPT nel primo trimestre del 2020",
	"components.fuwu.19t2i2": "Nel quarto trimestre del 2020, EPOCH EPT ha istituito un big data center",
	"components.fuwu.00bmfi": "Q1 2021",
	"components.fuwu.812he4": "Il sistema di trading quantitativo è collegato al big data center EPOCH EPT.",
	"components.fuwu.7vb4bx": "Stagione 3, 2021",
	"components.fuwu.i5s411": "Test del pacchetto di avvio del sistema di trading quantitativo",
	"components.fuwu.4rzg05": "Q2 2022",
	"components.fuwu.6f1vku": "Il sistema di scambio quantitativo ha raggiunto un totale di",
	"components.fuwu.7j4ksr": "Un utile quantitativo di 1,3 miliardi di dollari USA.",
	"components.fuwu.oox35c": "Birmingham, UK",
	"components.fuwu.t13534": "Registrazione e stabilimento",
	"components.fuwu.27p688": "EPOCH; EPT ha lanciato il primo sistema di trading intelligente AI al mondo.",
	"components.fuwu.0at9r7": "6.&nbsp; Nel prossimo futuro, è prevedibile che miliardi di persone conserveranno la loro ricchezza sotto forma di asset digitali. Questi asset includono non solo la valuta tradizionale e le risorse fisiche, ma anche le risorse digitali virtuali. Gli asset digitali hanno molteplici attributi come la conservazione del valore, la convertibilità e la copertura, che possono mitigare il rischio di ammortamento o perdite causate da crisi o conflitti finanziari. Essi forniscono un meccanismo per proteggere e proteggere i beni personali, consentendo un'interazione illimitata tra periodi e campi diversi. Poiché sempre più utenti possiedono asset digitali, cercano investimenti stabili per aumentare il valore degli asset digitali e far fronte ai cambiamenti dell'economia reale e dell'inflazione. Questo è",
	"components.fuwu.r4o4y5": "I sistemi di trading quantitativi sono la scelta migliore. Attualmente, ci sono oltre",
	"components.fuwu.yt83vn": "300 milioni di persone partecipano ad attività di investimento nel mercato blockchain, con un valore globale di asset digitali superiore a 50 miliardi di dollari USA. La commissione generata dal trading di asset digitali blockchain supera i 500 milioni di dollari USA, con un enorme potenziale di mercato. Al fine di preservare meglio e aumentare il valore, adottiamo",
	"components.fuwu.74n7mh": "Il sistema di trading quantitativo ha completamente cambiato i metodi di trading tradizionali, consentendo a più investitori di asset digitali di ottenere rendimenti e rendimenti più elevati. Offre inoltre opportunità di copertura e crescita patrimoniale per gli investitori tradizionali. Nel mondo futuro, i mercati finanziari saranno il campo del trading di asset digitali, e",
	"components.fuwu.h335ga": "I sistemi quantitativi di trading guideranno lo sviluppo di questa era. Guardando il mondo,",
	"components.fuwu.5173h3": "Il sistema di trading quantitativo ha stabilito la sua posizione nel campo degli investimenti finanziari nel più breve tempo possibile. Ha creato nuovi modelli applicativi basati su economia digitale, asset digitali e tecnologia blockchain.",
	"components.fuwu.jw5ueb": "7.&nbsp; Per quanto riguarda le considerazioni legali, poiché sempre più criptovalute sono classificate come titoli, le autorità finanziarie degli Stati Uniti, Singapore, Cina, Svizzera e Germania hanno inasprito le loro politiche o emesso avvisi sulle emissioni iniziali di token (ICO).",
	"components.fuwu.r3jm21": "Riconoscere e rispettare le normative sui valori mobiliari in diverse giurisdizioni principali e rispettarle",
	"components.fuwu.2a723k": "Regolamenti KYC (Know Your Customer) e AML (Anti Money Laundering). Nello specifico, questo significa: Regole sui titoli: Si prega di leggere attentamente questa sezione. Se avete domande sulle azioni da intraprendere, vi consigliamo di consultare i vostri consulenti legali, finanziari, fiscali o altri professionisti. Le informazioni elencate nel presente documento potrebbero non essere complete e non rappresentare elementi fondamentali del rapporto contrattuale. Il presente documento non costituisce alcuna consulenza di investimento, fiscale, legale, regolamentare, finanziaria, contabile o di altro tipo, né può essere l'unico motivo per partecipare al supporto e allo sviluppo dell'ecosistema Hang Seng Index. Prima di prendere qualsiasi decisione, i potenziali acquirenti devono consultare i loro consulenti legali, di investimento, fiscali, contabili e altri per determinare i potenziali benefici, limitazioni e altre conseguenze di tali transazioni. Qualsiasi parte del presente documento non costituisce un documento di offerta, di prospetto o di gara, né è destinata ad essere un requisito per investire in titoli in qualsiasi giurisdizione. Questo documento non è scritto in conformità con le leggi o i regolamenti di qualsiasi giurisdizione che vieta o limita le transazioni relative ai token digitali o al loro utilizzo. KYC e AML: Comprendere i dettagli tecnici del cliente (KYC) e le normative antiriciclaggio (AML) può variare a seconda del paese/regione, ma entrambi richiedono la verifica e il controllo incrociato dell'identità del cliente sulla base di vari elenchi di terroristi, embarghi e persone politicamente esposte (PEP).",
	"components.fuwu.n5ijvs": "Stiamo collaborando con fornitori di servizi professionali per implementare questo processo. Nel processo di investimento pubblico, l'investitore Kate",
	"components.fuwu.y06wpn": "8.&nbsp; Disclaimer: Questo documento non coinvolge alcun prodotto regolamentato nell'ambito della giurisdizione legale. Questo documento è un documento concettuale (white paper) utilizzato per la descrizione del progetto, solo per riferimento, e non è destinato alla vendita o raccolta fondi di azioni, titoli o altri scopi",
	"components.fuwu.5t61y5": "Prodotti correlati e prodotti regolamentati di società affiliate. Il presente documento non deve essere considerato come un prospetto o qualsiasi documento contrattuale standardizzato, né dovrebbe costituire consulenza in materia di investimenti o sollecitazione per titoli o altri prodotti regolamentati in qualsiasi giurisdizione. Il presente documento non deve essere utilizzato come consulenza per la vendita, la sottoscrizione, l'invito ad acquistare o sottoscrivere titoli o contratti, contatti o impegni basati su di essi. Non deve essere vista come un suggerimento di partecipazione agli investimenti. Qualsiasi informazione o analisi fornita nel presente documento non costituisce alcuna raccomandazione per la partecipazione agli investimenti token, né fornisce alcuna raccomandazione specifica. È necessario richiedere tutta la consulenza professionale necessaria su questioni rilevanti, come le revisioni fiscali e contabili. Questo documento non costituisce alcuna dichiarazione o garanzia. Utilizzato per spiegare la nostra proposta",
	"components.fuwu.j73g2y": "-&Nbsp; AL sistema commerciale quantitativo, ma",
	"components.fuwu.3ip5we": "La fondazione afferma chiaramente quanto segue",
	"components.fuwu.1x311y": "8.1 Non viene fornita alcuna dichiarazione o garanzia in alcuna forma circa l'accuratezza o la completezza di qualsiasi contenuto descritto in questo documento o di qualsiasi altro contenuto relativo al progetto pubblicato in questo documento.",
	"components.fuwu.0c5427": "8.2 Senza condizioni preliminari, non saranno fornite dichiarazioni o garanzie previsionali o concettuali riguardanti il conseguimento o la fattibilità.",
	"components.fuwu.h6u91t": "8.3 Nulla in questo documento servirà come base per promesse o dichiarazioni riguardanti il futuro.",
	"components.fuwu.if1i12": "8.4 La società non è responsabile per eventuali perdite causate da individui a causa del presente Libro bianco o di altri aspetti.",
	"components.fuwu.m21co1": "8.5 La responsabilità è limitata nella misura massima consentita dalla legge applicabile, nell'ambito inevitabile della responsabilità legale. Rischi legati alle politiche normative nelle regioni e nei paesi interessati: la tecnologia blockchain è supportata e riconosciuta a livello globale, ma è anche soggetta a un attento controllo da parte di varie agenzie di regolamentazione.",
	"components.fuwu.t5i928": "La funzionalità dei sistemi di trading quantitativi può essere influenzata da alcune politiche normative, tra cui, a titolo esemplificativo ma non esaustivo, restrizioni all'uso o al possesso",
	"components.fuwu.77k38h": "USDT, che può ostacolare o limitare",
	"components.fuwu.sxf326": "Lo sviluppo di sistemi di scambio quantitativi. Altri rischi sconosciuti",
	"components.fuwu.t62x6w": "La tecnologia blockchain e la relativa tecnologia di valuta digitale sono tecnologie relativamente nuove e non sufficientemente convalidate, che possono comportare rischi imprevedibili in vari modi.",
	"login.register.6l6651": "Registrazione telefonica",
	"login.register.bsu16p": "Conferma password",
	"login.register.8r578v": "Le password inserite due volte sono diverse",
	"components.xieyi.z4tui6": "Benvenuti all'uso",
	"components.xieyi.985i12": "Società a responsabilità limitata (di seguito denominata 'Società a responsabilità limitata')'",
	"components.xieyi.k71g1d": "Noi",
	"components.xieyi.6473ug": "'O 'piattaforma' prodotti e servizi! Al fine di fornirti vari servizi tecnici sotto la piattaforma, tratteremo le tue informazioni personali durante la tua attività. Siamo ben consapevoli dell'importanza delle informazioni personali per voi e la vostra fiducia è molto importante per noi. Rispetteremo rigorosamente i requisiti di leggi e regolamenti e prenderemo le misure di sicurezza corrispondenti nelle attuali condizioni tecniche efficaci, impegnandoci a proteggere la sicurezza dei vostri dati personali. Sulla base di ciò, abbiamo formulato la presente Informativa sulla privacy (di seguito denominata 'questa Informativa') per aiutarti a comprendere appieno come raccogliamo, utilizziamo, condividiamo, archiviamo e proteggiamo le tue informazioni personali durante l'utilizzo dei nostri prodotti e servizi, nonché come puoi gestire le tue informazioni personali, in modo che tu possa fare scelte appropriate.",
	"components.xieyi.r997hr": "Prima di iniziare a utilizzare i nostri prodotti e servizi, si prega di leggere attentamente e comprendere questa politica, concentrandosi in particolare sui nostri prodotti e servizi.",
	"components.xieyi.23i3k9": "Sottolineamento grassetto/grassetto",
	"components.xieyi.5jo3lp": "Si prega di assicurarsi di comprendere appieno e accettare i termini dell'identificazione prima di iniziare a usarlo. Faremo del nostro meglio per spiegarti il vocabolario professionale coinvolto in questa politica in termini concisi e di facile comprensione.",
	"components.xieyi.sw2x3b": "Se avete domande, opinioni o suggerimenti riguardanti il contenuto di questa politica, potete contattarci tramite",
	"components.xieyi.w1nc5l": "Il servizio clienti ufficiale della piattaforma ci sta contattando.",
	"components.xieyi.u855jt": "Il tuo consenso all'informativa sulla privacy significa solo che hai compreso le funzioni fornite dall'applicazione e le informazioni personali necessarie per il funzionamento delle funzioni. Ciò non significa che hai accettato che possiamo raccogliere informazioni personali non essenziali. I dati personali non essenziali saranno richiesti separatamente per il tuo consenso sulla base della tua autorizzazione durante l'uso.",
	"components.xieyi.jiyuvt": "Se rifiuti questa Informativa sulla privacy, puoi comunque utilizzare le funzioni di navigazione di base senza registrazione/login. È inoltre possibile autorizzare separatamente durante il successivo utilizzo del servizio.",
	"components.xieyi.61890q": "Questa politica ti aiuterà a comprendere i seguenti contenuti:",
	"components.xieyi.5mt6j8": "1,Definizione e ambito di applicazione",
	"components.xieyi.41xpk4": "2,Come raccogliamo e utilizziamo le tue informazioni",
	"components.xieyi.83j8rl": "3,Come utilizziamo i cookie e tecnologie simili",
	"components.xieyi.8b639p": "4,Come condividiamo, trasferiamo e divulghiamo pubblicamente le tue informazioni",
	"components.xieyi.6dq28b": "5,Come proteggiamo le vostre informazioni",
	"components.xieyi.2ulrqx": "6,Come conserviamo le vostre informazioni",
	"components.xieyi.3nz3ga": "7,Come si fa a gestire le informazioni",
	"components.xieyi.9s39yt": "8,Come gestiamo le informazioni sui minori",
	"components.xieyi.ew14nj": "9,Come aggiornare questa politica",
	"components.xieyi.12eq82": "10,Come si fa a contattarci",
	"components.xieyi.b1sb46": "(1) Definizione",
	"components.xieyi.we1z6j": "Si riferisce a Birmingham, UK",
	"components.xieyi.rw8pei": "La registrazione EPOCH EPT ha",
	"components.xieyi.idu1sg": "Società per azioni.",
	"components.xieyi.m568up": "Piattaforma:",
	"components.xieyi.hvxy61": "dito",
	"components.xieyi.73ijj4": "Sito web (nome di dominio www.weipaitang. com), APP client(",
	"components.xieyi.h0re24": ") E WeChat account ufficiale e altri media di servizio, noti anche come '",
	"components.xieyi.ivy1b3": "'Platform'.",
	"components.xieyi.ki07fh": "Società collegate:",
	"components.xieyi.6c317m": "Si riferisce a e",
	"components.xieyi.wr8y6r": "Imprese con controllo diretto o indiretto o con relazioni di influenza significative, comprese quelle che detengono",
	"components.xieyi.5865bv": "Azionisti con capitale proprio superiore al 5%",
	"components.xieyi.hty2r8": "La nostra filiale",
	"components.xieyi.36ty5w": "Altre imprese controllate dal controllore effettivo.",
	"components.xieyi.lsnc7x": "Società di pagamento",
	"components.xieyi.47x242": "Si riferisce al suo coinvolgimento in",
	"components.xieyi.gmmfq4": "L'istituto di pagamento che fornisce servizi di pagamento per lo shopping sulla piattaforma.",
	"components.xieyi.k10tpg": "Informazioni personali:",
	"components.xieyi.69e87n": "Si riferisce a varie informazioni registrate elettronicamente o comunque relative a persone fisiche identificate o identificabili, escluse le informazioni trattate in forma anonima.",
	"components.xieyi.o1m0al": "Informazioni personali sensibili:",
	"components.xieyi.2h5e47": "Informazioni personali che, una volta trapelate o utilizzate illegalmente, possono facilmente portare a violazione della dignità personale delle persone fisiche o a danno della sicurezza personale e della proprietà, tra cui:",
	"components.xieyi.2ipl66": "Identificazione biometrica, credenze religiose, identità specifiche, sanità, conti finanziari, traiettorie di viaggio",
	"components.xieyi.8122dw": "In attesa di informazioni, e",
	"components.xieyi.124tei": "Dati personali di minori di 14 anni",
	"components.xieyi.swn32o": "Indirizzeremo specifiche informazioni personali sensibili in questa politica",
	"components.xieyi.704x6v": "grassetto",
	"components.xieyi.274q3q": "Significativamente marcato.",
	"components.xieyi.u2twjv": "Trattamento dei dati personali:",
	"components.xieyi.9813ba": "Si riferisce alla raccolta, conservazione, uso, elaborazione, trasmissione, fornitura, divulgazione, cancellazione, ecc. di informazioni personali.",
	"components.xieyi.rutsx1": "bambini",
	"components.xieyi.81twf5": "Si riferisce a minori di 14 anni.",
	"components.xieyi.5jsxyn": "Se non diversamente concordato, le definizioni utilizzate in questa politica sono coerenti con",
	"components.xieyi.hu1431": "Accordo utente del servizio di transazione",
	"components.xieyi.f61m93": "Le definizioni hanno lo stesso significato.",
	"components.xieyi.4kd4rv": "(2) Campo di applicazione applicabile",
	"components.xieyi.s893e8": "Questa politica si applica a",
	"components.xieyi.p95qjo": "con",
	"components.xieyi.1d536g": "Siti web, clienti, applet, account ufficiale e vari prodotti e servizi forniti in nuove forme con lo sviluppo della tecnologia.",
	"components.xieyi.d02u4n": "Se utilizzati nei nostri prodotti o servizi e quelli delle nostre società affiliate",
	"components.xieyi.vd5142": "Prodotti o servizi (come l'utilizzo di",
	"components.xieyi.2ifgyw": "Se l'account della piattaforma è connesso, ma non esiste una politica sulla privacy indipendente, questa politica si applica anche a quella parte del prodotto o servizio. Se noi e le nostre società affiliate abbiamo politiche sulla privacy separate per i prodotti o servizi che forniscono all'utente, i prodotti o servizi corrispondenti saranno soggetti alle politiche sulla privacy corrispondenti.",
	"components.xieyi.lzne8r": "Oltre alle attività di raccolta e utilizzo delle informazioni descritte in questa politica,",
	"components.xieyi.8o7eza": "Questa politica non si applica a",
	"components.xieyi.c7i3mg": "Altri servizi forniti dall'utente dai venditori della piattaforma e da altre terze parti saranno soggetti all'informativa sulla privacy e alle altre regole di raccolta e utilizzo delle informazioni personali spiegate separatamente all'utente.",
	"components.xieyi.f4n8lb": ". Ad esempio, quando partecipi a",
	"components.xieyi.51p7u0": "Durante l'utilizzo di EPOCH e EPT",
	"components.xieyi.9ngrl3": "In base ai requisiti di leggi e regolamenti, ti spiegheremo le regole per la raccolta e l'applicazione dei tuoi dati personali.",
	"components.xieyi.62e3v6": "Secondo il Regolamento sull'ambito di applicazione delle informazioni personali necessarie per tipi comuni di applicazioni Internet mobili",
	"components.xieyi.3n4mh2": "L'APP appartiene alla categoria 'online'",
	"components.xieyi.8q81vo": "finanziamento",
	"components.xieyi.57m6k2": "'I servizi funzionali di base sono'",
	"components.xieyi.6891w4": "Intelligenza artificiale",
	"components.xieyi.s2ji36": "'Le informazioni personali necessarie sotto questa funzione includono: il tuo numero di telefono; nome, indirizzo e numero di telefono del destinatario; Informazioni di pagamento come tempo di pagamento, importo di pagamento e canale di pagamento.",
	"components.xieyi.wyy6r5": "Al fine di ottenere la fornitura del servizio, elaboreremo i tuoi dati personali in base alle diverse funzioni della piattaforma:",
	"components.xieyi.t1c3k8": "1. Funzione di base: Al fine di raggiungere la funzione di base di fornire i nostri prodotti e / o servizi, è necessario autorizzare noi a raccogliere e utilizzare le informazioni necessarie. Se l'utente rifiuta di fornire le informazioni corrispondenti, non sarà in grado di utilizzare i nostri prodotti e/o servizi normalmente",
	"components.xieyi.y7m0r2": "2. Caratteristiche aggiuntive: Al fine di fornire funzionalità aggiuntive dei nostri prodotti e/o servizi, è possibile scegliere se autorizzare la raccolta e l'utilizzo delle informazioni. Se si rifiuta di fornirlo, non sarà in grado di utilizzare normalmente le funzioni aggiuntive pertinenti o di ottenere gli effetti funzionali desiderati.",
	"components.xieyi.cwp3h6": "Vi ricordiamo che:",
	"components.xieyi.63078v": "1. ci impegniamo a creare una gamma diversificata di prodotti e servizi per soddisfare le vostre esigenze.",
	"components.xieyi.11t174": "A causa dell'ampia varietà di prodotti e servizi che forniamo all'utente e delle differenze nell'ambito specifico di prodotto/servizio che diversi utenti scelgono di utilizzare, le funzioni di base/aggiuntive e i tipi e la portata corrispondenti di informazioni personali raccolte e utilizzate possono variare.",
	"components.xieyi.654y1j": "Per offrirti una migliore esperienza di prodotto e servizio, ci sforziamo continuamente di migliorare i nostri prodotti, servizi e tecnologie. Di conseguenza, potremmo occasionalmente introdurre funzionalità nuove o ottimizzate, che potrebbero richiedere la raccolta e l'uso di nuove informazioni personali o modifiche allo scopo o al metodo di utilizzo delle informazioni personali. Se si verifica la situazione di cui sopra, ti forniremo spiegazioni dettagliate attraverso l'aggiornamento della presente politica, pop up, richieste di pagina e altri mezzi, e ti forniremo la possibilità di scegliere e accettare. Inizieremo a raccoglierli e utilizzarli solo dopo aver ottenuto il tuo esplicito consenso.",
	"components.xieyi.228036": "Durante questo processo, se avete domande, opinioni o suggerimenti, potete contattarci attraverso i canali specificati nella presente informativa e vi forniremo risposte il prima possibile.",
	"components.xieyi.3l69jv": "Vi forniremo vari scenari funzionali specifici, tra cui:",
	"components.xieyi.xq77st": "(1) Registrazione degli utenti e gestione degli account",
	"components.xieyi.hgd0m6": "1. Servizi di base per l'utente",
	"components.xieyi.sy4rg3": "Siamo basati su",
	"components.xieyi.3768od": "L'account della piattaforma fornisce servizi per voi. Come creare",
	"components.xieyi.k5pm58": "Devi fornirci almeno il tuo account sulla piattaforma",
	"components.xieyi.ijyhl0": "Numero di cellulare, nome utente e password da utilizzare",
	"components.xieyi.k04bce": ". Se si rifiuta di fornire le informazioni di cui sopra, non sarà possibile registrarsi",
	"components.xieyi.4b0us6": "Account della piattaforma, ma è comunque possibile utilizzare i servizi di navigazione e ricerca.",
	"components.xieyi.3k9d16": "Puoi modificare e integrare il tuo nickname, sesso, regione, persona di contatto e le informazioni relative all'autenticazione del tuo nome reale, tutte appartenenti alle tue 'informazioni account'.",
	"components.xieyi.rqik5d": "Tra questi, il nickname e l'avatar che hai impostato saranno visualizzati pubblicamente.",
	"components.xieyi.ilmlc1": "Le informazioni aggiuntive sull'account fornite ci aiuteranno a fornirti consigli personalizzati sui prodotti e una migliore esperienza di acquisto, ma se non fornisci queste informazioni aggiuntive, non influenzeranno le tue funzioni di base dello shopping online.",
	"components.xieyi.2d8htr": "Per garantire che forniamo servizi per voi personalmente, possiamo verificare la vostra identità in base alle informazioni fornite. Se ci sono scenari in cui è necessario determinare l'identità dell'utente in conformità con la legge (comprese le ritenute alla fonte e le tasse per l'utente in conformità con la legge, l'identificazione delle parti interessate nelle forze dell'ordine amministrative o nei contenziosi giudiziari, ecc.), ci autorizzi a ottenere le informazioni di autenticazione corrispondenti dai nostri partner per gli scopi sopra indicati.",
	"components.xieyi.qimk2p": "Prenderemo in considerazione il vostro",
	"components.xieyi.z57g36": "L'utilizzo degli account della piattaforma e le regole di calcolo per i livelli di account impostate dalla piattaforma determinano il tuo livello attuale e ti forniscono i vantaggi di base corrispondenti al livello di account corrispondente.",
	"components.xieyi.21n38j": "2. Servizi aggiuntivi",
	"components.xieyi.dh7o8w": "Autenticazione di persona reale: è possibile fornire i requisiti di autenticazione corrispondenti",
	"components.xieyi.57s046": "Informazioni d'identità (carta d'identità, passaporto, libretto di immatricolazione domestica e altre informazioni sul documento d'identità), elementi biometrici (tratti facciali statici o dinamici)",
	"components.xieyi.eb3ena": "Per completare l'autenticazione della persona reale.",
	"components.xieyi.2fnv9d": "Accesso autorizzato: Con il tuo consenso, potremmo condividere le informazioni del tuo account (avatar, nickname e altre richieste di pagina) con terze parti, permettendoti di registrarti facilmente per account di terze parti o accedere direttamente da terze parti. Inoltre, potremmo ottenere le informazioni del tuo account di terze parti da terze parti come WeChat sulla base della tua autorizzazione e comunicarle con il tuo account.",
	"components.xieyi.2586kj": "L'associazione dell'account della piattaforma consente di accedere direttamente e utilizzare i nostri prodotti e/o servizi di terze parti. Utilizzeremo le tue informazioni pertinenti nell'ambito del tuo consenso autorizzato.",
	"components.xieyi.75o8vj": "Altri servizi di ruolo utente: se si richiede di registrarsi come venditore o altro ruolo utente con requisiti aggiuntivi di autenticazione dell'identità, è necessario fornirci proattivamente informazioni di identità e/o informazioni relative all'azienda allo scopo di verificare, registrare, pubblicizzare la propria identità e altre qualifiche di ruolo utente e altri scopi che abbiamo chiaramente informato l'utente. Tipo '",
	"components.xieyi.ni3907": "Gestione patrimoniale autorizzata",
	"components.xieyi.1168tn": "'L'utente accetta di utilizzare la nostra raccolta del proprio numero di telefono cellulare e numero di carta d'identità per la gestione del nome reale dell'account broadcast in diretta, nonché la raccolta di identificativi del dispositivo, modelli di marca del dispositivo, sistemi operativi del dispositivo e posizioni geografiche per la corrispondenza delle funzioni di trasmissione in diretta e il controllo della sicurezza delle trasmissioni in diretta.",
	"components.xieyi.k2wd14": "3. Verifica dell'identità",
	"components.xieyi.1ns543": "Al fine di soddisfare i requisiti legali e normativi pertinenti, garantire l'autenticità dell'identità dell'utente (compresa la protezione dei diritti e degli interessi dei minori in conformità con la legge, la repressione delle frodi nel settore delle telecomunicazioni, la ritenuta alla fonte e il pagamento dell'imposta sul reddito personale, l'identificazione delle entità pertinenti nelle forze dell'ordine amministrative o nei contenziosi giudiziari), ottenere il controllo dei rischi come la lotta antifrode e garantire la sicurezza dei sistemi e dei servizi, abbiamo bisogno di raccogliere le informazioni pertinenti per la verifica dell'identità quando si utilizzano servizi/funzioni specifici, Ad esempio: [Cambia numero di telefono] Quando utilizzi la funzione 'Cambia numero di telefono' per recuperare il tuo account, come utente individuale, potremmo raccogliere le informazioni sulla tua identità, tra cui",
	"components.xieyi.3lj887": "Nome reale, numero di identificazione, informazioni sul riconoscimento facciale, informazioni sull'ordine",
	"components.xieyi.7y2f63": "Utilizzato per verificare la tua identità e aiutarti a recuperare il tuo account della piattaforma.",
	"components.xieyi.y57u7o": "Per gli utenti aziendali, potremmo raccogliere informazioni sulla tua identità legale, tra cui",
	"components.xieyi.82yye6": "Informazioni sulla licenza aziendale, nome reale del rappresentante legale, numero di identificazione, informazioni sul riconoscimento facciale",
	"components.xieyi.t6tsvp": "Se hai già",
	"components.xieyi.3coa6i": "Account della piattaforma, potremmo avere accesso a",
	"components.xieyi.4g34dh": "Le tue informazioni personali di cui sopra sono visualizzate nei servizi della piattaforma, così come nel tuo",
	"components.xieyi.i3lx1u": "Sulla piattaforma o in collaborazione con",
	"components.xieyi.cd0jo8": "Le operazioni eseguite nei prodotti e servizi associati all'account della piattaforma, anche attraverso",
	"components.xieyi.f5dq1g": "L'account della piattaforma visualizza le informazioni personali, i vantaggi di sconto e gli ordini di transazione in modo centralizzato",
	"components.xieyi.vo3428": ". Rispetteremo il vostro rispetto per",
	"components.xieyi.y3j2fo": "La selezione effettuata per le impostazioni dell'account della piattaforma.",
	"components.xieyi.pw3g77": "(2) Mostrando il prodotto, le informazioni di servizio e il messaggio push a voi",
	"components.xieyi.82aac2": "Per visualizzare le informazioni sui prodotti o sui servizi, tra cui la cronologia delle visite e la cronologia delle ricerche, raccoglieremo e utilizzeremo le informazioni da te durante la visita o l'utilizzo.",
	"components.xieyi.h4s010": "Cronologia di navigazione e ricerca sulla piattaforma. Combineremo informazioni sul dispositivo raccolte legalmente, informazioni sul registro dei servizi e altre informazioni autorizzate per prevedere le caratteristiche delle preferenze dell'utente attraverso un modello di algoritmo. Ti invieremo pubblicità commerciali e altre informazioni a cui potresti essere interessato in base alle tue caratteristiche di preferenza, o ti invieremo brevi messaggi commerciali.",
	"components.xieyi.kf81h2": "Gli algoritmi personalizzati di raccomandazione e recupero predicono le caratteristiche delle preferenze dell'utente in base al modello, ai prodotti, ai servizi o ad altre informazioni che potrebbero interessarti e ordinano i prodotti, i servizi o altre informazioni visualizzate. Forniremo feedback in tempo reale sul modello di raccomandazione basato sul comportamento di navigazione durante l'uso del prodotto, e regolare e ottimizzare continuamente i risultati delle raccomandazioni. Per soddisfare le vostre diverse esigenze, introdurremo diverse tecniche di raccomandazione nel processo di selezione per espandere il contenuto raccomandato ed evitare un'eccessiva concentrazione di contenuti simili.",
	"components.xieyi.182j2p": "Informazioni sul dispositivo: riceveremo e registreremo informazioni relative al dispositivo (tra cui modello del dispositivo, versione del sistema operativo, impostazioni del dispositivo, indirizzo MAC, IMEI, IDFA, OAID, Android) in base alle operazioni specifiche dell'utente durante l'accesso e/o l'utilizzo",
	"components.xieyi.1ns292": "Identificatore del dispositivo, ambiente del dispositivo, informazioni sul software e sulle caratteristiche hardware dell'elenco delle applicazioni mobili e informazioni relative alla posizione del dispositivo (incluse la posizione GPS autorizzata, il punto di accesso WLAN, il Bluetooth e le informazioni sul sensore della stazione base).",
	"components.xieyi.y2hur5": "Informazioni sul registro dei servizi: quando accedi o utilizzi",
	"components.xieyi.2l7v45": "Quando si utilizza la piattaforma, raccoglieremo e registreremo automaticamente le informazioni dettagliate dell'utente come registro di servizio, inclusi record di navigazione, clic per visualizzare record, record di query di ricerca, spettatori, report, transazioni, post-vendita, seguire, condividere informazioni, pubblicare informazioni, nonché indirizzo IP, tipo di browser, operatore di telecomunicazioni, lingua di utilizzo, data e ora di accesso.",
	"components.xieyi.i2q27g": "Si prega di notare che le informazioni sui singoli dispositivi e le informazioni sul registro di servizio non possono identificare l'identità di una persona fisica specifica.",
	"components.xieyi.48iqgk": "Se combiniamo questo tipo di informazioni non personali con altre informazioni per identificare l'identità di una persona fisica specifica, o le utilizziamo in combinazione con informazioni personali, durante il periodo di utilizzo combinato, questo tipo di informazioni non personali saranno considerate informazioni personali.",
	"components.xieyi.2e8s23": "Nel frattempo, ci sforziamo di garantire la tua esperienza sulla privacy attraverso le seguenti misure:",
	"components.xieyi.83qc71": "(1) Se si desidera eliminare i record di navigazione e ricerca, è possibile scegliere di eliminarli in 'My Footprints' o 'Search Box Ricerche recenti';",
	"components.xieyi.72qs2r": "(2) Se è necessario visualizzare risultati di ricerca non specifici per le proprie caratteristiche personali quando si utilizza il nostro servizio di ricerca on-site, è possibile fare clic su 'Filtro' nella pagina dei risultati di ricerca per configurarlo;",
	"components.xieyi.35ps8a": "(3) Se vuoi gestire gli annunci personalizzati che ti inviamo, puoi trovarli nella sezione '",
	"components.xieyi.777kl1": "My - Impostazioni (angolo in alto a destra) - Privacy - Impostazioni di personalizzazione",
	"components.xieyi.8026t2": "Sistemalo dentro. Si noti che è possibile impostare noi per non visualizzare annunci pubblicitari pertinenti utilizzando determinate informazioni di categoria, ma si vedrà comunque annunci pubblicitari. Il numero di annunci visualizzati non cambierà, ma la rilevanza degli annunci pubblicitari diminuirà.",
	"components.xieyi.5hx7h7": "Se vuoi gestire i contenuti personalizzati che ti inviamo, puoi cliccare sul pulsante '",
	"components.xieyi.5vxbqy": "My - Impostazioni (angolo in alto a destra) - Privacy - Impostazioni di personalizzazione",
	"components.xieyi.8wfq82": "'Mettilo dentro. Dopo la chiusura, il contenuto dei moduli di raccomandazione come 'raccomandazioni' e 'raccomandazioni per te' saranno raccomandazioni casuali e raccomandazioni non personalizzate.",
	"components.xieyi.f98v89": "(4) Se vuoi eliminare i tag di interesse e hobby con le tue caratteristiche personali, puoi farlo attraverso",
	"components.xieyi.3ve6e7": "(5) Se non siete interessati ai prodotti visualizzati sulla homepage, è possibile ridurre la visualizzazione dei prodotti correlati premendo a lungo l'immagine del prodotto raccomandato e selezionando 'Prodotto non interessato' nel prompt pop-up.",
	"components.xieyi.6mlx8c": "2. Servizi push di informazioni commerciali e promemoria di eventi",
	"components.xieyi.1y06bp": "Al fine di aiutarvi a capire meglio e utilizzare",
	"components.xieyi.t45916": "Accetti che ti invieremo informazioni commerciali e altri promemoria di eventi,",
	"components.xieyi.q2y74e": "Il fornitore di servizi raccoglierà e utilizzerà il tuo nome e le informazioni di contatto sulla base degli obiettivi di servizio sopra indicati.",
	"components.xieyi.84b8nh": "Se non vuoi ricevere i nostri messaggi push,",
	"components.xieyi.eh6abn": "Puoi disattivarlo tramite 'Le mie impostazioni (angolo in alto a destra) - Impostazioni messaggio'",
	"components.xieyi.3drpe9": "Se non volete accettare l'annuncio commerciale che vi inviamo,",
	"components.xieyi.16389e": "È possibile annullare l'iscrizione o chiuderla rispondendo al messaggio di richiesta SMS o utilizzando altri metodi da noi forniti;",
	"components.xieyi.i69xy9": "Se disattivi o annulla l'iscrizione alla funzione push, non sarai in grado di ricevere servizi per l'invio di informazioni su prodotti o eventi",
	"components.xieyi.2s65pg": "3. Informazioni sul sensore.",
	"components.xieyi.vn4s8x": "Quando utilizzi i nostri prodotti/servizi, potremmo raccogliere informazioni sui tuoi sensori per adattarsi allo stato del tuo dispositivo o per riconoscere le tue azioni quando scegli di partecipare alle attività. I tipi di sensori possono variare in diversi scenari e li utilizzeremo solo in scenari specifici. Ad esempio, durante le riprese, viene utilizzato per determinare se l'utente ruota lo schermo durante le riprese, rendendo conveniente correggere e ottimizzare il video catturato.",
	"components.xieyi.opxu33": "(3) Fornire all'utente le funzioni di guardare, seguire e condividere",
	"components.xieyi.46wa51": "Quando si naviga",
	"components.xieyi.sm4d35": "Durante il processo della piattaforma, l'utente può scegliere di osservare prodotti, servizi e/o altri utenti di interesse e condividere informazioni sui prodotti/servizi con terze parti attraverso i componenti funzionali che forniamo. Durante l'utilizzo delle funzionalità di cui sopra, raccoglieremo informazioni tra cui",
	"components.xieyi.qt6mj3": "operazione",
	"components.xieyi.k465t1": "Registra",
	"components.xieyi.13pdf3": "cooperazione",
	"components.xieyi.j6413h": "Le informazioni del registro di servizio, incluse le relazioni e la cronologia di condivisione, vengono utilizzate per raggiungere le funzioni di cui sopra e per altri scopi chiaramente indicati.",
	"components.xieyi.g5yo4i": "Puoi eliminare i tuoi spettatori e seguire le informazioni in 'I miei osservatori' e 'I miei seguaci'.",
	"components.xieyi.es68il": "(4) Aiutare a completare il posizionamento degli ordini e la gestione degli ordini",
	"components.xieyi.439hc7": "Quando ordinate prodotti e/o servizi specifici nei nostri prodotti e/o servizi, genereremo un ordine per l'acquisto di quel prodotto e/o servizio attraverso il sistema. Durante il processo di ordinazione, è necessario fornire almeno il vostro",
	"components.xieyi.4zfg7p": "Nome, indirizzo e numero di telefono di contatto del destinatario",
	"components.xieyi.875811": "Per alcuni tipi speciali di beni e servizi, è inoltre necessario fornire altre informazioni necessarie per i beni o servizi, come quelle relative a",
	"components.xieyi.sg3r7o": "Quando si tratta di reliquie culturali, è necessario fornire il proprio vero nome e numero d'identità per completare il deposito richiesto dalle leggi e dai regolamenti relativi alla protezione dei reliquie culturali e alle autorità di supervisione e amministrazione dei reliquie culturali.",
	"components.xieyi.x52n3c": "Allo stesso tempo, l'ordine includerà informazioni sui beni e/o servizi acquistati, il numero specifico del prodotto e l'importo da pagare. Raccogliamo queste informazioni per aiutarvi a completare la transazione senza intoppi, garantire la sicurezza della transazione, facilitare la vostra richiesta di informazioni sull'ordine, fornire assistenza clienti e assistenza post-vendita e altri scopi che abbiamo chiaramente dichiarato.",
	"components.xieyi.633jos": "Puoi usare",
	"components.xieyi.s43d5n": "La piattaforma ordina beni e/o servizi per altri ed è necessario fornire le suddette informazioni personali del destinatario effettivo e assicurarsi di aver ottenuto il consenso del destinatario effettivo",
	"components.xieyi.q3y8ui": "Per facilitare la comprensione, la query e la gestione delle informazioni sugli ordini, raccoglieremo le informazioni sugli ordini generate durante l'utilizzo dei nostri servizi per la visualizzazione e per facilitare la gestione degli ordini.",
	"components.xieyi.prc15q": "Puoi visualizzare e gestire le informazioni del tuo ordine tramite 'I miei ordini'.",
	"components.xieyi.ncd26i": "(5) Aiutarti a completare il pagamento",
	"components.xieyi.3u52sy": "Per completare il pagamento dell'ordine, è necessario scegliere il metodo di pagamento e il canale di pagamento al momento del pagamento. Condivideremo le tue informazioni personali, le informazioni relative ai pagamenti degli ordini, le informazioni relative ai dispositivi relativi alla sicurezza degli ordini e altri requisiti legali necessari contro il riciclaggio di denaro con la società di pagamento attraverso pacchetti di sviluppo di strumenti integrati della società di pagamento (denominati 'SDK' in questa politica) sul sito web o sul cliente. Se scegli che altri istituti finanziari forniscano servizi di pagamento per te, condivideremo anche le informazioni necessarie per il pagamento con carta bancaria, inclusi il numero di carta di credito e il periodo di validità, con l'istituto finanziario corrispondente scelto.",
	"components.xieyi.m65845": "Al fine di informare e confermare tempestivamente l'avanzamento e lo stato dei pagamenti e fornire servizi post-vendita e risoluzione delle controversie, l'utente accetta che possiamo raccogliere informazioni relative all'avanzamento dei pagamenti dal partner commerciale, dalla società di pagamento o da un altro istituto finanziario scelto.",
	"components.xieyi.bydbtx": "È possibile visualizzare il",
	"components.xieyi.rarv1g": "Le informazioni sul conto bancario legate al conto portafoglio della piattaforma o all'applicazione per sostituire il conto bancario vincolato.",
	"components.xieyi.73148a": "(6) Assistere nella consegna di beni o servizi all'utente",
	"components.xieyi.f8ce6b": "Per garantire la consegna e la fornitura regolare, sicura e accurata dei beni e/o dei servizi acquistati, l'utente riconosce e accetta che la corrispondente entità logistica e distribuzione sarà inevitabilmente informata e utilizzerà le informazioni di consegna per completare lo scopo della consegna.",
	"components.xieyi.wi14jv": "Al fine di fornire servizi post-vendita e risoluzione delle controversie, abbiamo bisogno di essere informati e confermare l'avanzamento e lo stato della consegna in modo tempestivo.",
	"components.xieyi.h3txyu": "(7) Servizio clienti e risoluzione delle controversie",
	"components.xieyi.75p62d": "Quando ci contattate o richiedete la risoluzione di controversie di vendita, post-vendita o controversie, al fine di garantire la sicurezza del vostro account e sistema,",
	"components.xieyi.u3u59b": "Abbiamo bisogno che tu fornisca le informazioni personali necessarie per verificare la tua identità utente.",
	"components.xieyi.r9ucq4": "Per comodità di contattarti, aiutarti a risolvere i problemi il prima possibile, o registrare le soluzioni e i risultati di problemi correlati, potremmo salvare le tue comunicazioni, comunicazioni/chiamate/registrazioni video e contenuti correlati con noi (incluse le informazioni sull'account, informazioni sugli ordini, altre informazioni fornite per dimostrare fatti rilevanti o informazioni di contatto lasciate). Useremo le informazioni del tuo account e le informazioni sugli ordini.",
	"components.xieyi.te87ps": "Al fine di fornire servizi e migliorare la qualità del servizio se ragionevolmente necessario, possiamo anche utilizzare le altre informazioni dell'utente, incluse le informazioni pertinenti fornite al momento del contatto con il servizio clienti e le informazioni sulla risposta al questionario che ci ha inviato durante la partecipazione al sondaggio.",
	"components.xieyi.259477": "(8) Ti forniamo la funzione di pubblicare pubblicamente commenti, domande e risposte e altre informazioni",
	"components.xieyi.67h2ep": "Puoi pubblicare pubblicamente informazioni attraverso i commenti, le comunità, le trasmissioni in diretta e altre funzioni di pubblicazione di informazioni che forniamo per te, inclusa la possibilità di pubblicare contenuti grafici/video, rivedere contenuti, ecc. come utente, nonché la possibilità di pubblicare informazioni relative a prodotti e/o servizi e memorizzare informazioni correlate come venditore.",
	"components.xieyi.194252": "Possiamo determinare se l'utente è idoneo per le autorizzazioni funzionali corrispondenti in base al tipo di utente e alle informazioni sul registro di rete (ad esempio, solo gli utenti che hanno acquistato un determinato prodotto possono pubblicare recensioni su di esso).",
	"components.xieyi.x8rr9h": "Se si sceglie di caricare immagini contenenti informazioni personali durante la valutazione, si prega di essere più cauti e considerare se condividere o anche condividere pubblicamente informazioni rilevanti quando si utilizza i nostri servizi. Se le informazioni rilasciate pubblicamente riguardano informazioni personali di altri, è necessario ottenere il loro consenso prima di pubblicare.",
	"components.xieyi.h60ox8": "(9) Fornire sicurezza",
	"components.xieyi.q466n3": "Per adempiere all'obbligo legale di garantire la sicurezza delle transazioni di e-commerce, migliorare la sicurezza dell'utilizzo dei servizi forniti da noi e dalle nostre società affiliate e partner, proteggere la sicurezza personale e della proprietà da violazioni, prevenire meglio rischi per la sicurezza come siti Web di phishing, frodi, vulnerabilità di rete, virus informatici, attacchi di rete e intrusioni di rete e identificare più accuratamente violazioni di leggi e regolamenti",
	"components.xieyi.3p5627": "Incorporamo l'SDK di sicurezza dell'applicazione sviluppato dalla nostra società affiliata nell'applicazione per raccogliere le informazioni sul dispositivo relative ai protocolli e alle regole relative alla piattaforma",
	"components.xieyi.4n220b": "Informazioni sul registro di servizio, dati sui sensori del dispositivo, informazioni software comunemente utilizzate e potenziale utilizzo o integrazione delle informazioni utente, informazioni sulle transazioni, informazioni sul dispositivo, informazioni sul registro di servizio, nonché informazioni autorizzate o legalmente condivise dalle nostre società affiliate e partner (dove possiamo raccogliere indirizzi MAC, informazioni relative al dispositivo IMEI per test di rischio durante il funzionamento del backend dell'applicazione), Per valutare in modo completo i rischi del tuo account e delle tue transazioni, condurre la verifica dell'identità, rilevare e prevenire gli incidenti di sicurezza e adottare le necessarie misure di registrazione, audit, analisi e smaltimento in conformità con la legge.",
	"components.xieyi.4ay5f9": "(10) Auto avvio APP o avvio associato",
	"components.xieyi.i2rhwf": "Al fine di migliorare l'esperienza dell'utente nell'utilizzo dei nostri servizi e garantire meglio la sicurezza delle transazioni, è necessario utilizzare la funzione di avvio automatico dell'app o associata per sollecitare e assistere gli utenti ad ottenere la nostra versione aggiornata dei servizi di applicazione app.",
	"components.xieyi.46wk64": "(11) Altri servizi aggiuntivi forniti all'utente",
	"components.xieyi.9w14b7": "Per fornirti prodotti e/o servizi più convenienti, di alta qualità e personalizzati e sforzarci di migliorare la tua esperienza, potremmo raccogliere e utilizzare le tue informazioni personali nei seguenti servizi aggiuntivi forniti all'utente.",
	"components.xieyi.7jt388": "L'utente può scegliere se fornire queste informazioni a propria discrezione.",
	"components.xieyi.1c6h45": "Servizi di base,",
	"components.xieyi.035322": "Tuttavia, potresti non essere in grado di accedere all'esperienza utente che questi servizi aggiuntivi ti offrono.",
	"components.xieyi.w541k9": "Puoi controllare lo stato delle autorizzazioni di cui sopra voce per voce nelle 'Impostazioni' del tuo dispositivo o nelle 'Autorizzazioni del sistema di privacy delle mie impostazioni' del nostro cliente e puoi decidere se attivare o disattivare queste autorizzazioni in qualsiasi momento.",
	"components.xieyi.t77ks8": "Si prega di notare che abilitando qualsiasi autorizzazione, ci si autorizza a raccogliere e utilizzare le informazioni personali pertinenti per fornire servizi corrispondenti. Una volta chiuso qualsiasi autorizzazione, significa che si annulla l'autorizzazione. Non continueremo più a raccogliere e utilizzare le informazioni personali pertinenti sulla base del permesso corrispondente, né possiamo continuare a fornire i servizi corrispondenti a tale autorizzazione. La decisione di chiudere le autorizzazioni non influenzerà la raccolta e l'utilizzo delle informazioni in base all'autorizzazione.",
	"components.xieyi.q563dr": "Si può",
	"components.xieyi.bs34gv": "Clicca qui",
	"components.xieyi.w6lbn0": "Visualizza le istruzioni per l'applicazione e l'uso dei permessi dell'applicazione",
	"components.xieyi.835h48": "(12) Altre norme per la raccolta e l'utilizzo delle informazioni personali",
	"components.xieyi.jy12wf": "Se le informazioni fornite contengono informazioni personali di altri utenti, si prega di contattare",
	"components.xieyi.m6i6t5": "Prima di fornire queste informazioni personali sulla piattaforma, è necessario assicurarsi di aver ottenuto l'autorizzazione legale.",
	"components.xieyi.a1o5v4": "Se utilizziamo le informazioni per altri scopi non specificati nella presente informativa, o raccogliamo informazioni per scopi specifici per altri scopi, otterremo il tuo consenso in anticipo, salvo quanto richiesto da leggi e regolamenti.",
	"components.xieyi.41gslf": "3. Se otteniamo indirettamente le informazioni dell'utente da una terza parte, prima della raccolta, chiederemo esplicitamente per iscritto che la terza parte spieghi l'origine delle sue informazioni personali e se ha ottenuto l'autorizzazione legale dell'utente per la raccolta, l'elaborazione e la fornitura dei suoi dati personali. Se non espressamente consentito dalla legge, raccogliamo i tuoi dati personali da una terza parte solo dopo aver confermato che hanno ottenuto l'autorizzazione e il consenso dell'utente. Allo stesso tempo, rafforzeremo la sicurezza delle informazioni personali (tra cui la segnalazione di informazioni sensibili, la memorizzazione crittografata di informazioni sensibili, il controllo degli accessi, ecc.). Useremo non meno delle stesse misure e misure di protezione che facciamo per le informazioni personali dei nostri utenti per proteggere le informazioni personali ottenute indirettamente.",
	"components.xieyi.x3r3y6": "4. Eccezioni all'ottenimento del consenso autorizzato",
	"components.xieyi.3jev4v": "In base alle leggi e ai regolamenti pertinenti, non richiediamo l'autorizzazione o il consenso dell'utente per trattare i suoi dati personali nelle seguenti situazioni:",
	"components.xieyi.214uq6": "(1) Necessario per adempiere obblighi o obblighi legali;",
	"components.xieyi.81k4nu": "(2) direttamente collegato alla sicurezza nazionale, alla sicurezza della difesa nazionale e ai principali interessi pubblici;",
	"components.xieyi.j7fomu": "(3) rispondere a improvvise emergenze sanitarie;",
	"components.xieyi.7806cy": "(4) Implementare la reportistica delle notizie, la supervisione dell'opinione pubblica e altri comportamenti per l'interesse pubblico e il trattamento delle informazioni personali entro un intervallo ragionevole;",
	"components.xieyi.754474": "(5) In situazioni di emergenza, se non è possibile informarvi in modo tempestivo o efficace per proteggere la vita, la salute o la sicurezza significativa della proprietà, ma vi informerà tempestivamente dopo la risoluzione della situazione di emergenza;",
	"components.xieyi.i1yv2t": "(6) In base alle leggi e ai regolamenti pertinenti, trattare le informazioni personali che sono state divulgate pubblicamente da sé o da altre persone che sono già state legalmente divulgate entro un intervallo ragionevole;",
	"components.xieyi.5dz1kl": "(7) secondo i necessari accordi o altri documenti scritti firmati e adempiuti con voi;",
	"components.xieyi.5bon72": "(8) necessario per mantenere il funzionamento sicuro e stabile dei prodotti e/o servizi forniti, come l'individuazione e la gestione di difetti nei prodotti e/o servizi;",
	"components.xieyi.cw60x0": "(9) Altre situazioni previste da leggi e regolamenti.",
	"components.xieyi.vyw19u": "Si prega di essere informati che secondo le leggi vigenti in materia, se",
	"components.xieyi.4t3bn8": "Adottare misure tecniche e di altro tipo necessarie per elaborare le informazioni personali, in modo che il destinatario dei dati non possa identificare determinate persone e non possa recuperarle",
	"components.xieyi.1c74f9": "Oppure possiamo condurre ricerche di identificazione, analisi statistiche e previsioni sulle informazioni raccolte per migliorarle",
	"components.xieyi.1m85sf": "Il contenuto e il layout della piattaforma, fornendo supporto di prodotti o servizi per il processo decisionale aziendale e migliorando i nostri prodotti e servizi (incluso l'utilizzo di dati anonimi per l'apprendimento automatico o la formazione di algoritmi di modello),",
	"components.xieyi.me4rhf": "L'utilizzo di tali dati trattati non richiede una comunicazione separata all'utente e il suo consenso",
	"components.xieyi.257c58": "3,Come lo usiamo",
	"components.xieyi.77f388": "E tecnologie simili",
	"components.xieyi.759p76": "(1) L'uso dei cookie",
	"components.xieyi.48ze34": "Per garantire il normale funzionamento del sito web, offrirti un'esperienza di accesso più facile e consigliare contenuti che potrebbero interessarti, memorizzeremo cookie, Flash cookie o altra memorizzazione locale fornita dal browser (o applicazioni associate) che in genere include identificatori, nomi di siti e alcuni numeri e caratteri (collettivamente denominati 'cookie') sul tuo computer o dispositivo mobile. Con l'aiuto di",
	"components.xieyi.s020rs": "Se il tuo browser o il servizio aggiuntivo del browser lo consente, puoi modificare il livello di accettazione dei cookie o rifiutare i nostri cookie.",
	"components.xieyi.3w12li": "Per maggiori dettagli, fare riferimento a AboutCookies. org. Tuttavia, se si eseguono le azioni descritte in questa clausola, in alcuni casi potrebbe influire sul vostro accesso sicuro al nostro sito web e potrebbe essere necessario modificare le impostazioni dell'utente ogni volta che visitate il nostro sito web.",
	"components.xieyi.q45ikb": "(2) L'uso di tecnologie simili nei cookie",
	"components.xieyi.h550rh": "eccetto",
	"components.xieyi.7248f8": "Inoltre, utilizzeremo anche altre tecnologie simili come beacon del sito web, etichette pixel, ETag, ecc. sul sito web.",
	"components.xieyi.jvxl8f": "Ad esempio, l'e-mail che ti inviamo potrebbe contenere un link di indirizzo al contenuto del nostro sito web. Se fai clic su questo link, terremo traccia del clic per aiutarci a capire le tue preferenze di prodotto o servizio, in modo da poter migliorare proattivamente l'esperienza utente. Un sito web beacon è solitamente un'immagine trasparente incorporata in un sito web o in un'email. Utilizzando etichette pixel nelle email, possiamo determinare se l'email è stata aperta. Se non desideri che le tue attività vengano monitorate in questo modo, puoi annullare l'iscrizione alla nostra mailing list in qualsiasi momento.",
	"components.xieyi.52wb6a": "ETag (Entity Tag) è un'intestazione del protocollo HTTP che viene trasmessa tra un browser Internet e un server Internet e può sostituire i cookie. ETag può aiutarci a evitare inutili carichi di server, migliorare l'efficienza del servizio e risparmiare risorse ed energia. Nel frattempo, potremmo utilizzare ETag per registrare la tua identità in modo da poter avere una comprensione più approfondita e migliorare i nostri prodotti o servizi.",
	"components.xieyi.y92k4u": "La maggior parte dei browser fornisce agli utenti la funzione di cancellare i dati della cache del browser ed è possibile eseguire le corrispondenti operazioni di cancellazione dei dati nella funzione delle impostazioni del browser.",
	"components.xieyi.o54yhy": "Tuttavia, si prega di notare che se si disattiva ETag, potrebbe non essere in grado di godere di un'esperienza relativamente migliore di prodotto o servizio.",
	"components.xieyi.37e045": "(1) Condivisione",
	"components.xieyi.675822": "Non interagiremo con",
	"components.xieyi.x1y6r8": "Condividi le tue informazioni personali con aziende, organizzazioni e individui esterni all'organizzazione, ad eccezione delle seguenti situazioni:",
	"components.xieyi.8q0s05": "Condivisione in circostanze legali: condivideremo le tue informazioni personali con il pubblico in conformità con le normative di legge.",
	"components.xieyi.9irym2": "Condivisione con consenso esplicito: Dopo aver ottenuto il tuo consenso esplicito, condivideremo le tue informazioni personali con altre parti.",
	"components.xieyi.371i1r": "3. Se necessario, ti forniremo i prodotti o i servizi di cui hai bisogno e li condivideremo con te attraverso:",
	"components.xieyi.75u39x": "Al momento dell'acquisto di beni o servizi sulla piattaforma, condivideremo le informazioni relative alle transazioni necessarie nelle informazioni dell'ordine con i fornitori dei beni o servizi pertinenti in base alla vostra scelta, al fine di soddisfare le vostre esigenze di transazione, consegna e servizio post-vendita.",
	"components.xieyi.u6d81m": "4. Condivisione con società affiliate:",
	"components.xieyi.30si92": "Per la comodità di noi basata su",
	"components.xieyi.6pysc9": "L'account della piattaforma fornisce prodotti e servizi, consiglia informazioni che potrebbero interessarti, identifica anomalie dell'account utente e protegge",
	"components.xieyi.7vmrlb": "La sicurezza personale e della proprietà delle società affiliate, di altri utenti o del pubblico è protetta da violazioni e le informazioni personali dell'utente possono essere condivise con le nostre società affiliate e/o i loro fornitori di servizi designati.",
	"components.xieyi.269i0k": "Condivideremo solo le informazioni personali necessarie e siamo vincolati dagli scopi indicati in questa politica. Se condividiamo le informazioni personali sensibili o se società affiliate modificano le finalità di utilizzo e trattamento dei dati personali, chiederemo nuovamente l'autorizzazione e il consenso dell'utente.",
	"components.xieyi.5475kt": "5. Partner autorizzati incaricati:",
	"components.xieyi.u1sa8e": "Possiamo affidarci a partner autorizzati per fornirti determinati servizi o svolgere funzioni per nostro conto,",
	"components.xieyi.f1h4lc": "Condivideremo le tue informazioni solo per gli scopi legittimi, legittimi, necessari, specifici e chiari indicati nella presente informativa. Ai partner autorizzati è consentito l'accesso solo alle informazioni di cui hanno bisogno per adempiere ai loro doveri e richiederemo loro attraverso un accordo di non utilizzare queste informazioni per altri scopi.",
	"components.xieyi.g700e1": "Attualmente, i nostri partner autorizzati includono i seguenti tipi:",
	"components.xieyi.36nvwn": "(1) Partner autorizzati per servizi pubblicitari e di analisi. Affideremo questi partner a gestire le informazioni relative alla copertura e all'efficacia della pubblicità, ma a meno che non otteniamo il vostro consenso, non condivideremo le vostre informazioni personali di identificazione con partner che forniscono servizi pubblicitari e di analisi, o deidentificheremo queste informazioni in modo che i partner autorizzati non possano riconoscervi personalmente. Questi tipi di partner possono combinare le informazioni di cui sopra con altri dati che ottengono legalmente per eseguire i nostri servizi pubblicitari commissionati o raccomandazioni decisionali.",
	"components.xieyi.5xh5u7": "(2) Fornitori, fornitori di servizi e altri partner. Inviamo informazioni a fornitori, fornitori di servizi e altri partner che supportano la nostra attività, tra cui fornire servizi di infrastruttura tecnica, analizzare l'utilizzo dei nostri servizi, misurare l'efficacia della pubblicità e dei servizi, fornire un servizio clienti, facilitare i pagamenti o condurre ricerche accademiche e indagini, servizi di logistica e distribuzione, servizi di pagamento, elaborazione dati, ecc. Il nostro scopo nel condividere queste informazioni è quello di abilitare le funzioni di acquisto principali dei nostri prodotti e/o servizi, come la condivisione delle informazioni sugli ordini con i fornitori di servizi logistici per organizzare la consegna; In alternativa, potremmo dover condividere il numero e l'importo dell'ordine con un istituto di pagamento di terze parti per confermare le istruzioni di pagamento e completare il pagamento.",
	"components.xieyi.67e7b8": "(3) Fornitore di servizi di autenticazione del nome reale. Quando forniamo servizi di autenticazione del nome reale, è necessario autorizzarci a raccogliere le informazioni di autenticazione del nome reale e fornirle a organizzazioni terze (incluse, a titolo esemplificativo ma non esaustivo, Tencent Cloud Computing (Beijing) Co., Ltd., di seguito denominate 'organizzazioni terze'), Le istituzioni terze devono informarsi e verificare in modo indipendente l'autenticità, l'accuratezza e la completezza di tali informazioni dalle unità pertinenti (inclusi, a titolo esemplificativo ma non esaustivo, il Database di base delle informazioni sul credito personale della Banca popolare cinese e altre agenzie governative pertinenti, operatori di telecomunicazioni di base, istituzioni di informazione, organi di pubblica sicurezza, procuratori, tribunali, istituzioni finanziarie, altre istituzioni cooperative o canali pubblici), analizzare, confrontare, elaborare e organizzare tali informazioni, E fornirci un rapporto di valutazione completo sulle vostre informazioni personali sul credito.",
	"components.xieyi.12lv44": "Per garantire la sicurezza",
	"components.xieyi.3749fw": "Il funzionamento stabile della piattaforma e l'implementazione delle funzioni di cui sopra consentono di usufruire e utilizzare più servizi e funzioni. La nostra applicazione incorpora SDK partner autorizzati o altre applicazioni simili.",
	"components.xieyi.3nos3g": "Puoi condividere l'elenco dei nostri partner collegati con terze parti",
	"components.xieyi.70yu7f": "Revisione.",
	"components.xieyi.89014s": "Condurremo rigorosi controlli di sicurezza sulle interfacce dei programmi applicativi (API) e sui kit di sviluppo di strumenti software (SDK) utilizzati dai partner autorizzati per ottenere informazioni pertinenti e concorderemo con i partner autorizzati su misure rigorose di protezione dei dati per garantire che gestiscano le informazioni personali in conformità con questa politica e qualsiasi altra misura pertinente di riservatezza e sicurezza.",
	"components.xieyi.1l5529": "(2) Trasferimento",
	"components.xieyi.ls670h": "Non trasferiremo le tue informazioni personali a nessuna società, organizzazione o individuo, tranne nelle seguenti circostanze:",
	"components.xieyi.u50832": "Trasferimento con consenso esplicito: dopo aver ottenuto il tuo consenso esplicito, trasferiremo le tue informazioni personali ad altre parti;",
	"components.xieyi.6snoud": "2. In the",
	"components.xieyi.411611": "In caso di fusione, acquisizione o liquidazione fallimentare, o altre situazioni che comportano il trasferimento di dati personali, vi informeremo tempestivamente del nome e delle informazioni di contatto della nuova società o organizzazione che detiene i vostri dati personali e richiederemo loro di continuare ad essere vincolati da questa politica. In caso contrario, richiederemo alla società, all'organizzazione e alla persona fisica di chiedere nuovamente l'autorizzazione e il consenso dell'utente.",
	"components.xieyi.783yjz": "(3) Comunicazione al pubblico",
	"components.xieyi.zdq4b7": "Divulgheremo pubblicamente le tue informazioni personali solo nelle seguenti circostanze:",
	"components.xieyi.3in5j6": "Possiamo divulgare pubblicamente le tue informazioni personali con il tuo esplicito consenso o sulla base della tua scelta volontaria.",
	"components.xieyi.ey5b87": "2. Sulla base dei requisiti di divulgazione delle informazioni previsti dalle leggi e dai regolamenti, divulgare le informazioni personali dell'utente nell'ambito prescritto, come la divulgazione delle informazioni del venditore prevista nelle misure per la supervisione e l'amministrazione delle transazioni online.",
	"components.xieyi.ok7p68": "Se determiniamo che hai violato leggi e regolamenti o commesso gravi violazioni",
	"components.xieyi.6kb306": "La situazione degli accordi e delle regole connessi alla piattaforma o ai fini della protezione",
	"components.xieyi.41el81": "La sicurezza personale e della proprietà degli utenti della piattaforma o del pubblico è protetta da violazioni.Possiamo divulgare informazioni personali su di te e smettere di fornire servizi a te in conformità con leggi e regolamenti o con il tuo consenso,",
	"components.xieyi.4c16r3": "Comprese le violazioni connesse e",
	"components.xieyi.7uq8p7": "La piattaforma ha preso delle misure contro di te. Ad esempio, se si viola gravemente la legge vendendo merci contraffatte",
	"components.xieyi.kc0i6h": "Secondo le regole della piattaforma, potremmo divulgare pubblicamente le informazioni relative all'autenticazione del tuo negozio, le violazioni e",
	"components.xieyi.100i6j": "Misure di manipolazione.",
	"components.xieyi.veqih2": "(4) Eccezioni all'ottenimento dell'autorizzazione e del consenso prima di condividere, trasferire o divulgare pubblicamente informazioni personali",
	"components.xieyi.i5fma7": "Secondo le leggi e i regolamenti pertinenti, la condivisione, il trasferimento o la divulgazione pubblica delle informazioni personali non richiede la previa autorizzazione e il consenso dell'utente nelle seguenti situazioni:",
	"components.xieyi.r82363": "1. Necessario per adempiere obblighi o obblighi legali;",
	"components.xieyi.ett47l": "2. direttamente collegato alla sicurezza nazionale, alla sicurezza della difesa nazionale e ai principali interessi pubblici;",
	"components.xieyi.nql27g": "3. Rispondere a improvvise emergenze sanitarie;",
	"components.xieyi.r2138s": "4. Implementare la segnalazione di notizie, la supervisione dell'opinione pubblica e altre modifiche per l'interesse pubblico al trattamento delle informazioni personali entro un intervallo ragionevole;",
	"components.xieyi.i32523": "5. in situazioni di emergenza, se non è possibile informarLa in modo tempestivo o efficace per proteggere la Sua vita, la salute o la sicurezza significativa della proprietà, ma La informerà tempestivamente dopo la risoluzione della situazione di emergenza;",
	"components.xieyi.4stqc6": "6. In base alle leggi e ai regolamenti pertinenti, gestire le informazioni personali che sono state divulgate pubblicamente da sé o da altre persone che sono già state legalmente divulgate entro un intervallo ragionevole.",
	"components.xieyi.7k22k8": "Si prega di essere informati che secondo le leggi applicabili,",
	"components.xieyi.7w6sng": "Se adottiamo misure tecniche e di altro tipo necessarie per elaborare le informazioni personali in modo che il destinatario dei dati non possa identificare una persona specifica e non possa recuperarle, la condivisione, il trasferimento e la divulgazione pubblica di tali dati trattati non richiedono una notifica separata all'utente e il suo consenso.",
	"components.xieyi.t8w9o9": "(1) Misure tecnologiche di protezione dei dati",
	"components.xieyi.5k26r2": "Abbiamo adottato misure di sicurezza ragionevoli e fattibili conformi agli standard del settore per proteggere le informazioni dell'utente, prevenire l'accesso non autorizzato, la divulgazione pubblica, l'uso, la modifica, il danneggiamento o la perdita di informazioni personali. Ad esempio, utilizzeremo la tecnologia di crittografia per migliorare la sicurezza delle informazioni personali, che sono protette durante lo scambio di dati tra il browser e il server",
	"components.xieyi.824h5u": "Protezione di crittografia del protocollo SSL, forniamo anche",
	"components.xieyi.rs1e76": "La piattaforma fornisce un metodo di navigazione sicuro utilizzando il protocollo HTTPS; Useremo meccanismi di protezione affidabili per prevenire attacchi dannosi alle informazioni personali; Implementeremo meccanismi di controllo degli accessi per garantire che solo il personale autorizzato possa accedere alle informazioni personali.",
	"components.xieyi.qj2xrx": "(2) Sistema di conformità alla gestione della protezione dei dati",
	"components.xieyi.y1edur": "Abbiamo istituito un sistema avanzato di conformità alla gestione della sicurezza dei dati incentrato sul ciclo di vita dei dati nel settore, che migliora la sicurezza delle informazioni personali da molteplici dimensioni come la costruzione organizzativa, la progettazione del sistema, la gestione del personale e la tecnologia dei prodotti. Istituiremo un'agenzia di protezione dei dati personali per supervisionare la protezione delle informazioni personali e tenere corsi di formazione sulla sicurezza e sulla protezione della privacy per aumentare la consapevolezza dei dipendenti sull'importanza della protezione dei dati personali.",
	"components.xieyi.d7ix35": "(3) Risposta ad incidenti di sicurezza delle informazioni personali",
	"components.xieyi.2o454t": "Se le nostre strutture di protezione fisiche, tecniche o gestionali vengono danneggiate, con conseguente accesso non autorizzato, divulgazione pubblica, manomissione o distruzione di informazioni, che danneggiano i vostri legittimi diritti e interessi, attiveremo prontamente piani di emergenza e prenderemo misure correttive ragionevoli per ridurre al minimo l'impatto sulle vostre informazioni personali e altri diritti e interessi. Se si verifica un incidente di sicurezza dei dati personali, ti informeremo anche in conformità con i requisiti legali: la situazione di base e il possibile impatto dell'incidente di sicurezza, le misure di smaltimento che abbiamo adottato o prenderemo, suggerimenti per prevenire e ridurre autonomamente i rischi e misure correttive per te. Vi informeremo della situazione rilevante dell'evento tramite e-mail, lettera, telefono, notifiche push e altri mezzi. Se è difficile informare l'interessato una ad una, prenderemo misure ragionevoli ed efficaci per pubblicare un annuncio. Allo stesso tempo, segnaleremo anche la gestione degli incidenti di sicurezza delle informazioni personali in conformità con i requisiti normativi.",
	"components.xieyi.4706wi": "(4) Vigilanza sociale",
	"components.xieyi.4ouosr": "Pubblicheremo regolarmente rapporti sulla responsabilità sociale sulla protezione dei dati personali in conformità con le leggi e le normative amministrative nazionali pertinenti e accettiamo la supervisione sociale.",
	"components.xieyi.6r5a4q": "(5) Prevenzione autonoma dei rischi per la sicurezza dei conti",
	"components.xieyi.veukf5": "Internet non è un ambiente assolutamente sicuro, utilizzandolo",
	"components.xieyi.ri9a0j": "Si consiglia vivamente di non utilizzare non",
	"components.xieyi.382lb2": "Il metodo di comunicazione raccomandato dalla piattaforma è quello di inviare il tuo messaggio, e ti preghiamo di utilizzare una password complessa per aiutarci a proteggere la sicurezza del tuo account.",
	"components.xieyi.s2h72a": "in uso",
	"components.xieyi.8mtgk8": "Quando effettui transazioni online sulla piattaforma, è inevitabile che comunichi le tue informazioni personali a controparti o potenziali controparti, come ad esempio",
	"components.xieyi.6ue558": "Informazioni di contatto",
	"components.xieyi.5h8pl3": "Indirizzo di contatto",
	"components.xieyi.74n5cs": ". Inoltre, è possibile stabilire comunicazioni, scambiare informazioni o analizzare contenuti con terze parti attraverso i nostri servizi. Si prega di proteggere correttamente le informazioni personali e di fornirle ad altri solo quando necessario. Se riscontri che le tue informazioni personali, in particolare il tuo account o la password, sono trapelate, contattaci immediatamente",
	"components.xieyi.1100gs": "Il servizio clienti ufficiale della piattaforma ci consente di adottare misure corrispondenti in base alla tua applicazione.",
	"components.xieyi.p898io": "Si prega di notare che le informazioni che si condividono volontariamente o anche pubblicamente quando si utilizzano i nostri servizi possono includere informazioni personali o anche informazioni personali sensibili di voi o di altri. Si prega di essere più cauti quando si considera se condividere o anche condividere pubblicamente informazioni rilevanti quando si utilizzano i nostri servizi.",
	"components.xieyi.pfhp6l": "(1) Luogo di stoccaggio",
	"components.xieyi.id973y": "Le informazioni personali raccolte e generate durante le nostre operazioni all'interno del territorio della Repubblica popolare cinese saranno conservate all'interno della Cina.",
	"components.xieyi.76w8ku": "Se è necessario fornire i vostri dati personali a parti estere a causa di attività o scopi coperti dalla presente informativa, effettueremo una valutazione d'impatto sulla protezione dei dati personali e registreremo in anticipo la situazione del trattamento in conformità con le leggi e i regolamenti pertinenti e li tratteremo in conformità con le leggi e i regolamenti pertinenti con il vostro esplicito consenso. Allo stesso tempo, vi informeremo tempestivamente del nome, delle informazioni di contatto, delle finalità di trattamento e del metodo di trattamento del destinatario delle informazioni estere. I tipi di informazioni personali e le modalità e le procedure per l'esercizio dei diritti legali nazionali nei confronti dei destinatari stranieri.",
	"components.xieyi.7fciuv": "(2) Periodo di conservazione",
	"components.xieyi.f1a2af": "Conserveremo le tue informazioni personali solo per il periodo necessario a raggiungere gli obiettivi indicati in questa politica, a meno che non ci siano requisiti obbligatori di conservazione per legge,",
	"components.xieyi.42nn2k": "Ad esempio, la legge sul commercio elettronico della Repubblica popolare cinese prevede che il tempo di conservazione delle informazioni sulle materie prime e sui servizi e delle informazioni sulle transazioni non sia inferiore a tre anni dalla data di completamento della transazione. Ci riferiamo principalmente ai seguenti standard per determinare il periodo di conservazione delle informazioni personali, e quello più lungo prevarrà:",
	"components.xieyi.6hv739": "1. completare gli obiettivi della transazione relativi all'utente, mantenere i corrispondenti registri delle transazioni e degli affari per rispondere alle sue possibili richieste o reclami;",
	"components.xieyi.483k46": "2. Garantire la sicurezza e la qualità dei nostri servizi forniti all'utente;",
	"components.xieyi.v5a774": "3. Accetti un periodo di conservazione più lungo;",
	"components.xieyi.7g6607": "Secondo i pertinenti requisiti del termine di prescrizione per il contenzioso;",
	"components.xieyi.8iszne": "5. Esiste un altro accordo speciale o regolamento legale relativo al periodo di conservazione.",
	"components.xieyi.2xiglt": "Dopo la scadenza del periodo di conservazione, elaboreremo tempestivamente le tue informazioni personali in conformità con le leggi e i regolamenti pertinenti.",
	"components.xieyi.7rg77i": "Oltre a seguire il percorso operativo concordato in questa politica, è possibile",
	"components.xieyi.t2esz7": "Oltre al self-operation sulla piattaforma, puoi anche accedere e gestire le tue informazioni attraverso i seguenti metodi:",
	"components.xieyi.kb51e6": "(1) Cerca, corregge e integra le tue informazioni",
	"components.xieyi.nu7m3c": "Hai il diritto di informarti, correggere o integrare le tue informazioni. Puoi accedere tramite",
	"components.xieyi.bcd5d5": "Sulla piattaforma, fai clic su 'Le mie impostazioni (angolo in alto a destra)' per accedere al centro acquisti per controllare e correggere le informazioni personali, le informazioni relative all'account personale, la sicurezza dell'account e l'indirizzo di spedizione.",
	"components.xieyi.043c4c": "(2) Cancellare le informazioni",
	"components.xieyi.2d2n9p": "È possibile eliminare alcune delle informazioni attraverso i metodi elencati in '(1) Interrogare, correggere e integrare le informazioni'.",
	"components.xieyi.8n2d00": "Nelle seguenti situazioni, è possibile comunicare con",
	"components.xieyi.4zh20s": "Il servizio clienti della piattaforma ci ha contattato per richiedere la cancellazione dei dati personali:",
	"components.xieyi.25t31b": "1. se il nostro trattamento dei dati personali viola leggi e regolamenti;",
	"components.xieyi.8tjokj": "2. se raccogliamo e utilizziamo le tue informazioni personali senza il tuo esplicito consenso;",
	"components.xieyi.4m1259": "3. se il nostro trattamento dei dati personali viola gravemente il nostro accordo con voi;",
	"components.xieyi.q81de3": "4. Se il tuo account viene cancellato.",
	"components.xieyi.xd43vl": "Se decidiamo di rispondere alla tua richiesta di cancellazione, informeremo anche le parti che hanno ottenuto i tuoi dati personali da noi per quanto possibile e richiederemo loro di cancellarli in modo tempestivo (salvo diversamente previsto da leggi e regolamenti, o se queste parti hanno ottenuto autonomamente la tua autorizzazione).",
	"components.xieyi.1c21t6": "Quando l'utente o noi l'assistiamo nell'eliminazione delle informazioni rilevanti, a causa delle leggi applicabili e delle limitazioni tecnologiche di sicurezza, potremmo non essere in grado di eliminare immediatamente le informazioni corrispondenti dal sistema di backup. Conserveremo in modo sicuro le informazioni personali e limiteremo qualsiasi ulteriore elaborazione fino a quando il backup non sarà cancellato o reso anonimo.",
	"components.xieyi.43p4r8": "(3) Annulla il tuo account",
	"components.xieyi.w2lnx4": "Puoi richiedere di annullare il tuo account utilizzando i seguenti metodi:",
	"components.xieyi.d17ah0": "1. Login",
	"components.xieyi.113v7u": "Piattaforma, inviare una domanda di cancellazione dell'account tramite 'My - Impostazioni (angolo in alto a destra) - Account Security Center - Annulla account';",
	"components.xieyi.390iqo": "2. Contatto",
	"components.xieyi.n6o511": "Servizio clienti della piattaforma, assisterti nella richiesta di cancellazione del tuo account",
	"components.xieyi.g4i68q": "Dopo aver cancellato volontariamente il tuo account, smetteremo di fornirti prodotti o servizi e cancelleremo le tue informazioni personali o le renderemo anonime in conformità con le leggi applicabili.",
	"components.xieyi.i8g44k": "(4) Modificare la portata del consenso autorizzato",
	"components.xieyi.7162l2": "Puoi modificare o revocare l'autorizzazione alla raccolta e al trattamento dei tuoi dati personali:",
	"components.xieyi.83m5qy": "Il mio",
	"components.xieyi.ih02m6": "'Impostazioni Autorizzazioni del sistema di privacy'",
	"components.xieyi.3aq776": "Gestisci le tue applicazioni autorizzate.",
	"components.xieyi.uyy3k2": "Per le autorizzazioni che non è possibile impostare direttamente attraverso i metodi sopra indicati, è possibile modificarle contattando il servizio clienti EPOCH EPT o inviando una mail a jubao@weipaitang.com . Risponderemo alla tua richiesta di prelievo entro 15 giorni lavorativi. Tuttavia, si prega di notare che per alcuni tipi di informazioni personali, come le informazioni necessarie per implementare le funzioni di base di EPOCH EPT o le informazioni necessarie per adempiere ai nostri obblighi ai sensi di leggi e regolamenti, potremmo non essere in grado di rispondere alla richiesta dell'utente di modificare l'ambito di autorizzazione.",
	"components.xieyi.38w831": "Dopo la revoca dell'autorizzazione, non elaboreremo più le informazioni personali corrispondenti. Ma la tua decisione di revocare l'autorizzazione non influirà sul nostro precedente trattamento dei dati personali basato sulla tua autorizzazione.",
	"components.xieyi.o2932d": "Se non desideri accettare l'annuncio commerciale personalizzato che ti inviamo, puoi cancellarlo in qualsiasi momento attraverso i seguenti metodi:",
	"components.xieyi.7l39m6": "'My EPOCH EPT - Settings - About EPOCH EPT - Personalizzazione Settings'",
	"components.xieyi.uw6w84": "(5) Sistema di informazione vincolato Processo decisionale automatico",
	"components.xieyi.6ixh35": "In alcune funzioni aziendali, possiamo prendere decisioni basate solo su meccanismi decisionali automatici non manuali come sistemi informativi e algoritmi. Se queste decisioni incidono in modo significativo sui vostri diritti e interessi legittimi, avete il diritto di chiederci una spiegazione e ci assicureremo anche che non vi violino",
	"components.xieyi.8i0uc6": "Fornire metodi di ricorso sotto la premessa di segreti commerciali o altri diritti dell'utente e interessi pubblici sociali.",
	"components.xieyi.sace84": "(6) Copia delle informazioni personali",
	"components.xieyi.iz38g1": "Se hai bisogno di una copia dei tuoi dati personali, puoi contattarci attraverso i canali di feedback elencati in 'Come contattarci' nella presente informativa. Dopo aver verificato la tua identità, ti forniremo una copia dei tuoi dati personali in base alla tua richiesta, salvo quanto diversamente previsto da leggi e regolamenti o dalla presente informativa.",
	"components.xieyi.q0m5n8": "(7) In risposta alla vostra richiesta di cui sopra",
	"components.xieyi.fqi536": "Per le richieste di cui sopra fatte da te o dai tuoi potenziali tutori, parenti stretti e altre parti autorizzate a noi, nonché i tuoi diritti relativi ai dati personali previsti dalle leggi della Repubblica popolare cinese e da altre leggi applicabili, puoi contattarci tramite il servizio clienti o presentare direttamente un reclamo al nostro dipartimento di protezione dei dati personali. Forniremo una risposta entro 15 giorni.",
	"components.xieyi.a1304e": "Per garantire la sicurezza del tuo account, quando ci fai la richiesta di cui sopra, potremmo prima richiedere la verifica della tua identità (ad esempio richiedere una richiesta scritta o altri mezzi) prima di elaborare la tua richiesta.",
	"components.xieyi.31h37g": "Nelle seguenti situazioni, in base ai requisiti di legge, non saremo in grado di rispondere alla tua richiesta:",
	"components.xieyi.1udf40": "1. per adempiere a obblighi o obblighi legali;",
	"components.xieyi.0ro7r7": "3. vi sono prove sufficienti per indicare che l'interessato ha dolo soggettivo o abuso di diritti;",
	"components.xieyi.70z43w": "4. Rispondere alla vostra richiesta comporterà gravi danni ai vostri diritti e interessi legittimi o di altre persone o organizzazioni;",
	"components.xieyi.335lfg": "5) il coinvolgimento di segreti commerciali;",
	"components.xieyi.1eb324": "6. Altre circostanze previste da leggi e regolamenti amministrativi.",
	"components.xieyi.e0vv42": "Presumiamo che tu abbia corrispondente capacità civile nelle attività di e-commerce. Se sei minorenne, ti chiediamo di chiedere ai tuoi genitori o ad altri tutori di leggere attentamente questa politica e utilizzare i nostri servizi o di fornirci informazioni con il consenso dei tuoi genitori o di altri tutori.",
	"components.xieyi.jp5623": "Se sei un bambino di età inferiore ai 14 anni, prima di utilizzare i nostri servizi, tu e il tuo tutore dovresti leggere attentamente le nostre 'Regole sulla protezione dei dati personali dei bambini' appositamente formulate per assicurarti di ottenere il consenso del tutore",
	"components.xieyi.4637q9": "Norme sulla protezione dei dati personali dei bambini",
	"components.xieyi.b7i17n": "Solo con il tuo consenso puoi utilizzare i nostri servizi sotto la guida del tuo tutore per garantire la tua sicurezza quando utilizzi i nostri servizi e conduci transazioni.",
	"components.xieyi.63z56b": "Se sei il genitore o altro tutore di un bambino, ti preghiamo di prestare attenzione al fatto che il bambino abbia utilizzato i nostri servizi dopo aver ottenuto il tuo consenso autorizzato. Se hai domande sulle informazioni personali dei bambini, contatta il nostro dipartimento dedicato per la protezione delle informazioni personali.",
	"components.xieyi.064797": "I nostri servizi e la nostra tecnologia sono costantemente aggiornati e ottimizzati per soddisfare le esigenze dell'utente e degli altri utenti. Pertanto, quando nuovi servizi e processi aziendali cambiano, potremmo aggiornare la nostra politica sulla privacy per informarvi di cambiamenti specifici. Ma senza il tuo esplicito consenso, non limiteremo i tuoi diritti ai sensi di questa politica. Lo faremo",
	"components.xieyi.7xys1n": "Pagina specializzata",
	"components.xieyi.5ln786": "Pubblicare eventuali modifiche apportate a questa politica.",
	"components.xieyi.1i9q62": "Per modifiche significative, forniremo anche notifiche più significative (anche attraverso",
	"components.xieyi.dg46h5": "Notifica tramite annuncio pubblico della piattaforma o fornisci istruzioni pop-up.",
	"components.xieyi.o3si3a": "Le modifiche significative menzionate in questa politica includono, a titolo esemplificativo e non esaustivo:",
	"components.xieyi.sntd36": "1. Il nostro modello di servizio ha subito cambiamenti significativi, come lo scopo del trattamento dei dati personali, il tipo di dati personali trattati e il modo in cui vengono utilizzati i dati personali;",
	"components.xieyi.x97b27": "2. abbiamo subito cambiamenti significativi nei diritti di controllo e in altri aspetti, come cambiamenti nei titolari del trattamento causati da fusioni e acquisizioni;",
	"components.xieyi.hc7g60": "3. Gli obiettivi principali della condivisione, del trasferimento o della divulgazione di informazioni personali sono cambiati;",
	"components.xieyi.4icom0": "4. vi è stato un cambiamento significativo nei vostri diritti e nell'esercizio dei diritti di trattamento dei dati personali;",
	"components.xieyi.krv6jh": "5. Il nostro dipartimento responsabile per la gestione della protezione dei dati personali, le informazioni di contatto e i canali di reclamo sono cambiati;",
	"components.xieyi.t6x4vu": "6. La relazione sulla valutazione d'impatto sulla sicurezza delle informazioni personali indica un rischio elevato.",
	"components.xieyi.oso4jc": "Nome della Società: Hangzhou EPOCH EPT Cultural and Creative Co., Ltd",
	"components.xieyi.0vvw2h": "7th floor, Chuangfeng Huaxing Business Building, No. 19 Huaxing Road, distretto di Xihu, città di Hangzhou, provincia di Zhejiang",
	"components.xieyi.4j9bb7": "Se hai domande, opinioni o suggerimenti su questa politica, puoi contattarci attraverso i seguenti metodi e risponderemo alla tua richiesta entro 15 giorni lavorativi:",
	"components.xieyi.5mz349": "1. EPOCH EPT servizio clienti online",
	"components.xieyi.py8lg2": "2. Abbiamo anche istituito un dipartimento dedicato per la protezione dei dati personali, e puoi contattare l'indirizzo email della nostra persona responsabile della protezione dei dati personali shaktrysrtgscrtbslanderor5046@gmail.com .",
	"components.xieyi.nfphnk": "Si noti che potremmo non rispondere a domande non correlate a questa politica o alla protezione delle informazioni personali degli utenti.",
	"components.xieyi.6vag57": "Se non siete soddisfatti della nostra risposta, specialmente se ritenete che il nostro comportamento di trattamento dei dati personali abbia danneggiato i vostri legittimi diritti e interessi,",
	"components.xieyi.iw8u70": "Puoi anche cercare una soluzione presentando una causa legale presso un tribunale competente nella sede della piattaforma EPOCH EPT.",
	"components.xieyi.668kbh": "Data di pubblicazione: 13 dicembre 2018",
	"components.xieyi.1hqds4": "Data dell'ultimo aggiornamento: 30 marzo 2023",
	"src.error.3if060": ">>>>>Messaggio di errore>>>>>",
	"src.error.f78hx4": ">>>>>istanza Vue>>>>>",
	"libs.axios.3m3ozo": "Eccezione server!",
	"libs.axios.u68235": "Richiesta errore!",
	"libs.axios.8pvp71": "Non hai il permesso!",
	"libs.axios.3e23l0": "Errore dell'indirizzo di richiesta",
	"libs.axios.ctl7o2": "Richiesta timeout, si prega di aggiornare e riprovare!",
	"libs.axios.7f90de": "Errore server interno",
	"libs.axios.6r1ua2": "Servizio non implementato",
	"libs.axios.232in7": "Gateway errato",
	"libs.axios.ft8yo6": "Servizio non disponibile",
	"libs.axios.1o3gds": "Timeout Gateway, si prega di aggiornare e riprovare!",
	"libs.axios.43w873": "Versione HTTP non supportata",
	"goods.index.86p673": "acquistare",
	"goods.index.40o4np": "Quantità di acquisto",
	"goods.index.r257np": "Acquista ora",
	"goods.index.6i5t88": "Questo prodotto è temporaneamente non disponibile per l'acquisto!",
	"goods.index.263l56": "Seleziona un prodotto",
	"goods.index.e5yso2": "Si prega di scegliere un metodo di pagamento!",
	"rewards.activityb.t04rn4": "Commissioni totali",
	"rewards.activityb.r5174s": "La commissione di oggi",
	"rewards.activityb.q4544h": "La commissione di ieri",
	"rewards.activityb.uflar8": "Numero di rinvii diretti",
	"rewards.activityb.fsyaor": "commissione",
	"rewards.activityb.xd17r7": "Numero indiretto di persone",
	"trending.index copy 2.891396": "documentario",
	"trending.index copy 2.rc6wik": "Intervallo di investimento:",
	"trending.index copy 2.y4r77n": "Ciclo quantitativo:",
	"trending.index copy 2.m2v371": "ora",
	"trending.index copy 2.5vyoay": "Prestazioni quantitative:",
	"trending.index copy 2.rpeid0": "Ricavi Tracker 1185,65 dollari",
	"trending.index copy 2.g0677s": "Dimensione ordine $15120184.00",
	"trending.index copy 2.8sgku2": "In custodia",
	"trending.index copy 2.g2jni6": "Importo della custodia",
	"trending.index copy 2.39cp6b": "Periodo di custodia",
	"trending.index copy 2.050n02": "Redditi giornalieri",
	"trending.index copy 2.380j4f": "Orario di inizio hosting",
	"trending.index copy 2.6en3x1": "Termine di fine custodia",
	"trending.index copy 2.v761ws": "Periodo di follow-up:",
	"trending.index copy 2.8gow40": "Restrizioni di follow-up:",
	"trending.index copy 2.1ul440": "Rendimento minimo:",
	"trending.index copy 2.7fk35c": "Rendimento massimo:",
	"trending.index copy 2.3ys73k": "Saldo disponibile",
	"trending.index copy 2.f615yk": "Prezzo totale",
	"trending.index copy 2.11c26y": "Seguite immediatamente",
	"trending.index copy 2.ojxv4u": "intero",
	"trending.index copy 2.2v0ku3": "Il mio ordine",
	"trending.index copy 2.9m6dh6": "Ordine effettuato con successo!",
	"trending.index copy 3.7v38ky": "Annullato",
	"trending.index copy 3.3ksfcn": "Pre-ordine",
	"trending.index copy 3.u6n586": "Da risolvere",
	"trending.index copy 3.7d8jnh": "titolo",
	"trending.index copy 3.22f28b": "Sei sicuro di voler annullare l'ordine?",
	"trending.index copy 3.plw663": "conferma",
	"trending.index copy 3.e4pcfx": "Annullato con successo!",
	"trending.index copy.voov3i": "Rendimento negli ultimi 30 giorni",
	"trending.index copy.60fqu2": "Entrate negli ultimi 30 giorni",
	"trending.index copy.38c2yt": "probabilità di vincita",
	"trending.touzi1.30c92u": "Numero d'ordine",
	"trending.touzi1.w3r6h6": "denaro",
	"trending.touzi1.2q58dy": "stato",
	"trending.touzi1.830j6d": "tempo",
	"wallet.index.ralw0t": "Inserisci il tuo numero di telefono",
	"wallet.index.tme1u9": "Numero di telefono",
	"wallet.index.h1wve2": "Inserisci un numero di telefono",
	"login.login.qnpe05": "Inserisci il numero di telefono",
	"login.login.8za2m1": "La password di accesso non può essere vuota",
	"components.recharge.r79s4d": "Efficace",
	"components.recharge.v93frd": "Lavorazione",
	"wallet.index.bm205v": "L'importo minimo non può essere inferiore a 0",
	"components.recharge.h1w232": "Riduzione in corso",
	"components.recharge.wx5ntw": "Rimborso riuscito",
	"components.recharge.42t1o3": "Ricaricato con successo",
	"components.recharge.d03old": "Ritiro fallito",
	"components.recharge.cn2rpv": "Ritiro riuscito"
}