import i18n from '../lang/index.js';
/*
 * @Date: 2023-08-03 16:19:32
 * @LastEditors: Hs
 * @LastEditTime: 2023-08-03 16:43:57
 * @FilePath: \dx1\src\utils\base.js
 */
export default {
    /**
     * 判断一个值是否为空
     * @param obj			任意类型
     * @returns {boolean}	是否为空
     */
    isNull(obj) {
        if (
            obj == undefined ||
            obj == 'undefined' ||
            obj == null ||
            obj == 'null' ||
            obj == '' ||
            obj.length == 0 ||
            JSON.stringify(obj) == '{}'
        )
            return true;
        else return false;
    },

    /**
     * json序列化
     * @param {Object:json}[json:true]
     * @returns Str
     */
    serializeStr(json) {
        let arr = [];
        for (let i in json) {
            arr.push(i + '=' + json[i]);
        }
        let str = arr.join('&');
        return str;
    },

    /**
     * 时间戳转时间格式
     */
    formatDate(iDate) {
        let date = new Date(iDate);
        let YY = date.getFullYear() + '-';
        let MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        let DD = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        let mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        let ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return YY + MM + DD + ' ' + hh + mm + ss;
    },

    // 合并JSON
    extend(target, source) {
        for (var obj in source) {
            target[obj] = source[obj];
        }
        return target;
    },

    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 乘法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    accMul(arg1, arg2) {
        let m = 0,
            s1 = arg1.toString(),
            s2 = arg2.toString();
        try {
            m += s1.split('.')[1].length;
        } catch (e) {}
        try {
            m += s2.split('.')[1].length;
        } catch (e) {}
        return (Number(s1.replace('.', '')) * Number(s2.replace('.', ''))) / Math.pow(10, m);
    },
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 除法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    accDiv(arg1, arg2) {
        let t1 = 0,
            t2 = 0,
            r1,
            r2;
        try {
            t1 = arg1.toString().split('.')[1].length;
        } catch (e) {}
        try {
            t2 = arg2.toString().split('.')[1].length;
        } catch (e) {}
        r1 = Number(arg1.toString().replace('.', ''));
        r2 = Number(arg2.toString().replace('.', ''));
        return this.accMul(r1 / r2, Math.pow(10, t2 - t1));
    },
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 加法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    accAdd(arg1, arg2) {
        let r1, r2, m;
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        return (arg1 * m + arg2 * m) / m;
    },
    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 减法
     * @Params: { number ： arg1 - true } [数字1]
     * @Params: { number ： arg1 - true } [数字2]
     * @Return: number
     */
    subtr(arg1, arg2) {
        let r1, r2, m, n;
        try {
            r1 = arg1.toString().split('.')[1].length;
        } catch (e) {
            r1 = 0;
        }
        try {
            r2 = arg2.toString().split('.')[1].length;
        } catch (e) {
            r2 = 0;
        }
        m = Math.pow(10, Math.max(r1, r2));
        n = r1 >= r2 ? r1 : r2;
        return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },

    /**
     * @Author HS
     * @Date 2021/7/16 10:55 上午
     * @Description: 获取当前时间
     * @Params: null
     * @Return: string
     */
    getTime() {
        let myDate = new Date();
        let y = myDate.getFullYear();
        let m = myDate.getMonth() + 1;
        let d = myDate.getDate();
        let h = myDate.getHours();
        let min = myDate.getMinutes() + 1;
        let s = myDate.getSeconds() + 1;
        let time = `${y}-${m}-${d} ${h}:${min}:${s}`;
        return time;
    },

    /**
     * @Author HS
     * @Date 2021/8/6 2:31 下午
     * @Description: 时间戳转倒计时
     * @Params: { number ： endTimeStamp - true } [时间戳]
     * @Return: object
     */
    countDown(endTimeStamp) {
        let nowTimeStamp = new Date().getTime();
        let time = {};
        if (endTimeStamp > nowTimeStamp) {
            let mss = endTimeStamp - nowTimeStamp;
            let days = Math.floor(mss / (1000 * 60 * 60 * 24));
            let hours = Math.floor((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((mss % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((mss % (1000 * 60)) / 1000);
            time = {
                day: days < 10 ? '0' + days : days,
                hour: hours < 10 ? '0' + hours : hours,
                minute: minutes < 10 ? '0' + minutes : minutes,
                second: seconds < 10 ? '0' + seconds : seconds,
                mss: mss,
                end: false
            };
        } else {
            time = {
                day: '00',
                hour: '00',
                minute: '00',
                second: '00',
                mss: '00',
                end: true
            };
        }
        return time;
    },

    /**
     * @Author HS
     * @Date 2021/8/6 2:31 下午
     * @Description: 保留小数
     * @Params: { number ： endTimeStamp - true } [时间戳]
     * @Return: object
     */
    numSubstrTwo(num, index = 3) {
        return num.substr(0, num.indexOf('.') + index);
    },

    /**
     * @Author HS
     * @Date 2021/8/6 2:31 下午
     * @Description: 时间戳转时分秒
     * @Params: { number ： endTimeStamp - true } [时间戳]
     * @Return: object
     */
    timestampToHMS(timestamp) {
        const date = new Date(timestamp); // 注意：确保时间戳是以毫秒为单位的

        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');

        return `${hours}:${minutes}:${seconds}`;
    },

    /**
     * @Author HS
     * @Date 2021/8/6 2:31 下午
     * @Description: 获取年月日
     * @Params: { number ： endTimeStamp - true } [时间戳]
     * @Return: object
     */
    getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0'); // 月份从0开始，所以需要加1
        const day = currentDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
};