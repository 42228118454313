import i18n from './lang/index.js';
import Vue from 'vue';

Vue.config.errorHandler = function (err, vm, info) {
  Vue.nextTick(() => {
    if (process.env.NODE_ENV === 'development') {
      console.group(i18n.t('src.error.3if060'))
      console.error(info)
      console.groupEnd();
      console.group(i18n.t('src.error.f78hx4'))
      console.log(vm)
      console.groupEnd();
      console.group('>>>>>> Error >>>>>>')
      console.log(err)
      console.groupEnd();
    }
  })
}