import i18n from '../lang/index.js';
/**
 * 全站http配置
 * axios参数说明
 * isSerialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import * as qs from 'qs';
// import store from '@/store/';
import { baseUrl } from '@/config/env';
import router from '@/router/index'
// import { serialize } from '@/util/util'
// import { getToken } from '@/util/auth'
import { Toast } from 'vant'
// import NProgress from 'nprogress' // progress bar
// import 'nprogress/nprogress.css' // progress bar style
import Cache from 'store2'
// import {
//     setStore,
//     getStore,
//     removeStore
// } from '@/util/store'

let loading;

axios.defaults.timeout = 60000;

//返回其他状态吗
axios.defaults.validateStatus = function (status) {
    return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
// axios.defaults.withCredentials = true;

//默认请求地址
axios.defaults.baseURL = baseUrl

// NProgress Configuration
// NProgress.configure({
//     showSpinner: false
// });
//HTTPrequest拦截
axios.interceptors.request.use(config => {
    // NProgress.start() // start progress bar
    const meta = (config.meta || {});
    //默认参数设置
    let lang = Cache.get('lang') || '';
    let token = Cache.get('token') || '';
    config.headers['Api-Name'] = 'wap'
    config.headers['Api-Token'] = token

    if (config.data && config.data.loading) {
        loading = Toast.loading({
            duration: 0, // 持续展示 toast
            overlay: true,
            forbidClick: true,
            className: "full-loading",
        });
    }


    if (config.method == 'post') {
        //上传图片
        if (config.data instanceof FormData) {
            //上传图片
        } else {
            config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
            if (!config.data) {
                config.data = {
                    lang
                }
            } else {
                config.data.lang = lang
            }
            config.data = qs.stringify(config.data)
            // console.log(config.data);
        }
    }
    return config
}, error => {
    loading && loading.clear();
    return Promise.reject(error)
});
//HTTPresponse拦截
axios.interceptors.response.use(res => {
    loading && loading.clear();
    // NProgress.done();
    //正常请求
    if (res.data.code === 1) {
        return res.data;
    } else {
        Toast.fail(res.data.info)
        if (res.data.code == 401) {
            Cache.remove('userinfo')
            Cache.remove('token')
            router.push('/login')
        }
        return Promise.reject(res.data.info)
    }
}, error => {
    loading && loading.clear();
    // NProgress.done();
    return Promise.reject(new Error(error));
})

export default axios;