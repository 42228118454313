module.exports = {
	"Language": {
		"title": "Wika"
	},
	"Noproductsforsale": "Walang produkto para sa pagbebenta",
	"rechargePresentationText0_1": "Mag-recharge ng 1000 at makakuha ng gantimpala ",
	"rechargePresentationText0_2": ", Mag-recharge ng 10000 at makakuha ng gantimpala ",
	"rechargePresentationText0_3": ", at iba pa... I-click Ako!",
	"rechargePresentationText0": "Makakatanggap ka ng ",
	"rechargePresentationText1": "% ng halaga ng iyong pag-recharge kung mag-recharge ka na ngayon. Mayroong limitadong oras para sa aktibidad at matatapos ito pagkatapos ng pagbibigay ng mga regalo.",
	"rechargePresentationText2": "Pagtatapos ng Gantimpala, Bilang!",
	"Online_Chat": "Online Chat",
	"gobindgoogle": "Mangyaring ikabit ang iyong Google Authenticator",
	"Order": "Order",
	"Record": "Record",
	"Assist": "Assist",
	"Market": "Market",
	"History": "History",
	"Confirm": "Kumpirmahin",
	"close": "Isara",
	"Cancel": "Kanselahin",
	"Previous": "Nakaraan",
	"Next": "Susunod",
	"Copy": "Kopyahin",
	"Nomoredata": "walang data",
	"moneychar": "₱",
	"DownloadApp": "I-download ang App Ngayon",
	"Yes": "Oo",
	"No": "Hindi",
	"Wating": "naghihintay",
	"Recharge": "Mag-recharge",
	"Withdrawal": "Mag-withdraw",
	"Success": "Tagumpay",
	"stop_investing": "itigil ang pag-iinvest",
	"OrderSuccessful": "Matagumpay na Order",
	"Handledataerror": "Error sa Pagproseso ng Data",
	"Copysuccess": "Matagumpay na kopya",
	"MyBalanceLoding": "Ang aking balance ay nasa proseso pa",
	"UPDATE": "UPDATE",
	"New_version_available": "May bagong bersyon na magagamit",
	"Please,update_app_to_new_version": "Mangyaring i-update ang app sa bagong bersyon",
	"tabbar": {
		"Home": "Bahay",
		"AndarBahar": "Andar Bahar",
		"Invest": "Mag-invest",
		"Bonus": "Bonus",
		"Mine": "Wallet",
		"Trending": "Trending",
		"Wallet": "Wallet",
		"Rewards": "Mga Reward"
	},
	"homeTop": {
		"Sign_Up": "Mag-sign Up",
		"Sign_up_for_rewards": "Mag-sign up para sa mga rewards",
		"up_to": "hanggang sa",
		"TOTAL_ASSETS": "KABUUANG ASET",
		"User_ID": "User ID",
		"Registration_time": "Oras ng pagpaparehistro"
	},
	"PieChart": {
		"BIG&SMALL": "MALAKI & MALIIT",
		"NUMBER": "NUMERO",
		"ODD&EVEN": "TUWIRAN & HINDI TUWIRAN",
		"ALL_NUMBER_TRENDING": "TRENDING NG LAHAT NG NUMERO",
		"BIG&SMALL_NUMBER_TRENDING": "TRENDING NG MALAKI AT MALIIT NA NUMERO",
		"ODD&EVEN_NUMBER_TRENDING": "TRENDING NG TUWIRAN AT HINDI TUWIRANG NUMERO"
	},
	"register": {
		"checkerr": "Password: Gumamit ng 8 o higit pang mga character (isang kombinasyon ng mga letra at numero)",
		"tips_3": "Kung hindi mo nakatanggap ng code sa iyong inbox, mangyaring tingnan ang iyong spam folder.",
		"receiveCode": "Hindi nakatanggap ng code?",
		"receiveCode_title": "Code naipadala sa iyong email. Kung hindi ka nakatanggap ng code matapos ang ilang mga pagtatangka, mangyaring subukan ang:",
		"receiveCode_text1": "1. Tingnan kung nasa iyong spam / spam mail.",
		"receiveCode_text2": "2. Siguraduhin na ang iyong email address ay",
		"receiveCode_text3": "3. Ang mensahe ay maaaring maantala sa ilang minuto. Subukan muli pagkatapos ng 20 minuto.",
		"receiveCode_text4": "4. Kung ang email address na ito ay umiiral na, hindi namin ito ipadadala sa iyo ng code ng pagpapatunay. Mangyaring mag-login o mag-reset ng iyong password.",
		"Sign_up_for_Rewards": "Mag-sign up para sa mga Reward",
		"Email_Verification": "Pagpapatunay ng Email",
		"tips": "Mangyaring ipasok ang 6-digit code ng pagpapatunay na ipinadala sa {{formData.email}}. Ang code ay balido sa loob ng 30 minuto.",
		"tips_1": "Mangyaring ipasok ang 6-digit code ng pagpapatunay na ipinadala sa ",
		"tips_2": "Ang code ay balido sa loob ng 30 minuto.",
		"Resend_code_in": "Muling ipadala ang code sa",
		"Sent_again": "Ipadala ulit",
		"CREATE_ACCOUNT": "GUMAWA NG ACCOUNT",
		"inviteCodecheck": "Hindi maaaring maging walang laman ang inviteCode!",
		"Mobile": "Mobile Number",
		"Mobilemsg": "Kinakailangan ang mobile number",
		"Password": "Password",
		"Passwordmsg": "Kinakailangan ang password",
		"OTPCode": "OTP Code",
		"OTPCodemsg": "Kinakailangan ang OTP Code",
		"SendOTP": "Ipadala ang OTP",
		"Email": "Email",
		"Emailmsg": "Kinakailangan ang email",
		"Invitecode": "Kodigong Pangtukoy (opsyonal)",
		"Invitecodemsg": "Kinakailangan ang referral code",
		"emailFormatter": "Mangyaring magpasok ng tamang email address",
		"usernameFormatter": "ang pangalan ng user ay dapat na mga character at numero at nasa range ng 6<=haba<=20",
		"phoneFormatter": "Mangyaring punan ang 10-digit mobile phone number",
		"msgsuccess": "matagumpay na naka-rehistro",
		"msgerr": "nabigo sa pagpaparehistro",
		"errorphonecheck": "Hindi maaaring maging walang laman ang Mobile phone number!",
		"phonecheck": "Mangyaring punan ang 10-digit mobile phone number!",
		"imgcodecheck": "Hindi maaaring maging walang laman ang OTP Code!"
	},
	"login": {
		"Welcome_back": "Maligayang pagbabalik",
		"tips": "Nabasa ko at sumasang-ayon sa {{this.$LogoName}} Term of Service at Privacy Policy",
		"tips_1": "Nabasa ko at sumasang-ayon sa",
		"tips_2": "Term of Service",
		"tips_3": "at",
		"tips_4": "Privacy Policy",
		"Continue_with_Google": "Magpatuloy gamit ang Google",
		"Continue_with_Apple": "Magpatuloy gamit ang Apple",
		"text": "Walang account pa?",
		"text2": "Mayroon nang account?",
		"Login": "Mag-login",
		"Register": "Magrehistro",
		"username": "Username o numero ng telepono",
		"password": "Password",
		"imgcode": "Kodigong imahe",
		"BackHome": "Bumalik sa bahay",
		"Forgetpwd": "Nakalimutan ang Password"
	},
	"forgot": {
		"title": "Kalimutan ang Password",
		"msgsuccess": "Tagumpay na naibalik ang iyong password",
		"newpassword": "Bagong Password",
		"newpasswordmsg": "Kinakailangan ang bagong password",
		"Continue": "Magpatuloy"
	},
	"home": {
		"content11": "Ang mga nag-imbita ay makakakuha ng bonus na proporsyonal sa halaga ng transaksyon na ginawa ng kanilang inimbitahan. Ang maximum na bonus para sa isang wastong imbitasyon ay walang hanggang USDT.",
		"Revolutionize_Trading": "Revolutionize Trading",
		"Shape_the_Future": "Shape the Future",
		"content0": "Samahan natin ang bagong era ng Crypto Currency Exchange!",
		"3_Reasons_for_Choosing": "3 Dahilan para Pumili",
		"Innovative_Trading_Features": "Innovative Trading Features",
		"content1": "Ang revolutionary trading experience na iba sa kahit anong available sa merkado ngayon. Binuo ito upang magbigay ng kumpetitibong kalamangan sa pamamagitan ng mga innovative trading features, advanced security measures, at lightning-fast transaction speeds.",
		"Lower_Fees_and_Better_Rewards": "Lower Fees and Better Rewards",
		"content2": "Ang aming interface ay nagpapadali sa trading para sa mga nagsisimula pa lang. Maaari kang mag-create ng libreng account, mag-trade ng crypto na may mababang transaction fees mula sa aming marketplace. Bukod dito, maaring magbahagi ng hanggang 20% transaction fees mula sa exchange para sa lahat ng users na inimbitahan mo.",
		"Institutional-grade_Security": "Institutional-grade Security",
		"content3": "Sumusunod ang {{this.$LogoNames.toUpperCase()}} sa isa sa mga pinakamataas na security standards sa industry. Nagtatago rin kami ng 100% ng iyong crypto sa cold storage. Garantisado namin ang hindi bababa sa 1:1 reserve ratio ng aming customer funds. Ang aming 300M USDT fund ay nagbibigay ng karagdagang proteksyon laban sa mga security threats.",
		"Start_investing_in_as_little_as_5_minutes": "Magsimulang mag-invest sa loob ng 5 minuto lamang",
		"content4": " Mag-sign up sa {{this.$LogoNames.toUpperCase()}} nang libre",
		"content4_1": "Mag-sign up sa ",
		"content4_2": " nang libre",
		"Complete_google_authentication": "Kumpletohin ang google authentication",
		"content5": "Maglagay at kumpirmahin ang iyong email address upang makapagsimula. Walang bayad ang pagbubukas ng account.",
		"content7": "Buksan ang buong kakayahan sa pamamagitan ng pagpapatunay sa iyong google authenticator sa loob ng 5 minuto lamang.",
		"content8": "Mag-fund ng iyong {{this.$LogoNames.toUpperCase()}} account sa sandaling panahon",
		"content8_1": "Mag-fund ng iyong",
		"content8_2": "account sa sandaling panahon",
		"content9": "Magdeposito ng USDT sa pamamagitan ng select network at magkaroon ng instant access sa iyong mga pondo. Hindi na kailangang maghintay ng 3-5 araw upang mag-trade.",
		"Start_building_your_crypto_portfolio": "Simulan ang pagbuo ng iyong crypto portfolio",
		"Invitation_Program": "Invitation Program",
		"Referral_Program": "Referral Program",
		"content10": "Kumpletohin ang anumang wastong imbitasyon at matanggap ang bonus na halagang katumbas ng 10 USDT. Ang maximum bonus para sa isang tao ay ",
		"TRADING_TASKS": "Mga Gawain sa Pagtitinda",
		"content12": "Matatanggap ng mga user ang mga premyo sa USDT sa pagtitinda, mayroong iba't-ibang gawain sa pagtitinda para sa iba't-ibang uri ng trading mode. Ang mga premyo ay agad na ipapamahagi.",
		"Trade_anytime_anywhere": "Mag-trade anumang oras, kahit saan",
		"content13": "Ang USA, Inc. ay lisensyado na mag-engage sa Virtual Currency Business Activity ng New York State Department of Financial Services.",
		"USA_Inc": "USA, Inc",
		"content6": "I-download ang aming app upang manatiling konektado 24/7",
		"Legal&Disclosures": "Legal & Disclosures",
		"Regulatory_License": "Regulatory License",
		"AML/KYC_Policies": "AML/KYC Policies",
		"Privacy_Policy": "Privacy Policy",
		"Terms_of_Use": "Terms of Use",
		"Legal_Statement": "Legal Statement",
		"Risk_Disclosure": "Risk Disclosure",
		"About_Us": "About Us",
		"Protection_Fund": "Protection Fund",
		"Proof_of_Reserves": "Proof of Reserves",
		"Twitter": "Twitter",
		"Bitget_Token": "Bitget Token",
		"Careers": "Careers",
		"Company": "Company",
		"Support_Service": "Support",
		"title": "Buksan gamit ang app",
		"titlebtn": "I-download",
		"Balance": "Balanse",
		"ID": "ID",
		"Recharge": "Magdeposito",
		"Withdraw": "Mag-withdraw",
		"Bonus": "Bonus",
		"Checkin": "Check-in",
		"Eventclosed": "Tapos na ang event",
		"Ranking": "Ranking"
	},
	"Ranking": {
		"Today_s winning ranking": "Pang-araw-araw na ranking ng nanalong manlalaro",
		"tips1": "Mas malaki ang halagang panalo = mas mataas ang ranking!",
		"UserName": "Username",
		"Winning Amount": "Halagang Panalo",
		"Today_s rebate ranking": "Pang-araw-araw na ranking ng rebate",
		"tips2": "Mas mataas na rebate para sa mas mababang antas ng gumagamit = mas mataas na ranking!",
		"Rebate Amount": "Halagang Rebate",
		"Rebate ranking": "Mga nangungunang 20 ranking sa rebate"
	},
	"Checkin": {
		"Checkin": "Mag-sign in",
		"CheckinList": "Listahan ng Pag-sign in"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Patakaran",
		"Record": "Rekord",
		"Records": "Mga Rekord ng Andar Bahar",
		"MyOrder": "Aking Order",
		"more": "higit pa",
		"Period": "Panahon",
		"Order": "Order",
		"Buy": "Bumili",
		"Result": "Resulta",
		"Profit": "Kita",
		"AndarBaharRule": "Patakaran ng Andar Bahar",
		"CountDown": "Count Down",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "resulta",
		"ok": "ok",
		"start": "simulan",
		"Point": "Point",
		"ContractPoint": "Kontrata Point",
		"Number": "Numero",
		"Delivery": "Delivery",
		"Fee": "Bayad",
		"Amount": "Halaga"
	},
	"pokerOrder": {
		"OrderRecord": "Order Record",
		"WatingOrder": "Naghihintay na Order",
		"HistoryOrder": "Kasaysayan ng Order",
		"Nomoredata": "Walang karagdagang data",
		"Buy": "Bumili",
		"Result": "Resulta",
		"Time": "Oras",
		"Amount": "Halaga",
		"Fee": "Bayad",
		"Profit": "Kita"
	},
	"pokerRecord": {
		"title": "Rekord ng Andar Bahar",
		"Period": "Panahon",
		"Result": "Resulta",
		"OpenTime": "Oras ng Pagbubukas",
		"Nomore": "Wala nang iba pa"
	},
	"Google2FA": {
		"title": "Google Authenticator",
		"Authenticator_Instructions": "Mga Tagubilin sa Google Authenticator",
		"Authenticator_Status": "Katayuan ng Google Authenticator",
		"Google_Authenticator": "Google Authenticator",
		"Download_Google_Authenticator_App": "I-download ang Google Authenticator App",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "Susunod",
		"Backup_key": "Susugan na Key",
		"Backup_key_text": "Mangyaring i-save ang Backup Key na ito sa isang ligtas na lugar. Ang susugan na Key na ito ay magbibigay sa iyo ng kakayahang i-recover ang iyong Authenticator sakaling mawala ang iyong telepono. Kung hindi, kailangan mo kumunsulta sa customer service para i-reset ang iyong Authenticator.",
		"Verifiaction": "Pag-verify",
		"Verifiaction_text": "Ilagay ang Google verification code",
		"Google_verification_code": "Google verification code",
		"contentlist1": "1. Isulat ang 16 na digit Backup Key mula sa +this.$LogoName+ at itago ito sa ligtas na lugar.",
		"contentlist1_1": "1. Isulat ang 16 na digit Backup Key mula sa",
		"contentlist1_2": "at itago ito sa ligtas na lugar.",
		"contentlist2": "2. Buksan ang Google Authenticator.",
		"contentlist3": "3. Idagdag ang +this.$LogoName+ account at ilagay ang kopyadong 16 na digit key.",
		"contentlist3_1": "3. Idagdag ang",
		"contentlist3_2": "account at ilagay ang kopyadong 16 na digit key.",
		"contentlist4": "4. Makikita mo ang 6 na digit na verification code sa screen.",
		"contentlist5": "5. I-kopya ang 6 na digit code at ilagay ito sa +this.$LogoName+.",
		"contentlist5_1": "5. I-kopya ang 6 na digit code at ilagay ito sa",
		"contentlist5_2": ".",
		"contentlist6": "6. Matapos, magiging tagumpay na na nakapag-link na ang iyong Authenticator."
	},
	"win": {
		"Live_Price": "Live na Presyo",
		"title1": "Ang resulta ng investment ay kinakalkula sa pamamagitan ng huling digit ng Binance BTC/USDT Spot Trade na may kaugnayan sa UTC time. Maaaring tingnan ng mga investor ang resulta sa BINANCE anumang oras upang matiyak ang kahusayan at seguridad ng kanilang investment.",
		"title": "Mayroong 2 na mode sa investment",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Bawat round ay tumatagal ng 1 minuto. Ang Lite mode ay nagbibigay ng Odd & Even number investment lamang. Ang resulta ay kinakalkula sa pamamagitan ng huling digit ng Binance BTC/USDT Spot trade 1M interval's close result.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Bawat round ay tumatagal ng 5 minutong. Ang Pro mode ay nag-unlock sa buong investment method ng EPOCH EPT. Ang resulta ay kinakalkula sa pamamagitan ng huling digit ng Binance BTC/USDT Spot trade 5M interval's close result.",
		"5M_Mode_text_1": "Bawat round ay tumatagal ng 5 minutong. Ang Pro mode ay nag-unlock sa buong investment method ng ",
		"5M_Mode_text_2": " . Ang resulta ay kinakalkula sa pamamagitan ng huling digit ng Binance BTC/USDT Spot trade 5M interval's close result.",
		"Investment_Guide": "Gabay sa Investment",
		"Investment_Rules": "Mga Patakaran sa Investment",
		"title2": "Paano patunayan ang patas na resulta?",
		"Investment_Info": "Investment Impormasyon",
		"3M_Mode": "3M Mode",
		"3M_Mode_text": "Bawat round ay tumatagal ng 3 minuto. Ang resulta ay kinakalkula sa pamamagitan ng huling digit ng Binance BTC/USDT Spot trade 3M interval's close result.",
		"Investment_return": "Investment return",
		"Number": "Numero",
		"Profit_Invested_amount": "Kita: Invested amount",
		"guide1": "Pumili ng time mode.",
		"guide2": "I-adjust ang iyong investment amount.",
		"guide3": "Pumili ng numero na gustong i-invest.",
		"guide4": "Suriin ang iyong portfolio at i-click ang invest.",
		"NEXT": "SUSUNOD",
		"Invest_Amount": "Halaga ng Investment",
		"Total": "Kabuuan",
		"INVEST": "INVEST",
		"Portfolio": "Portfolio",
		"Invest": "Invest",
		"BINANCE": "BINANCE",
		"Bitcoin_Price": "Presyo ng Bitcoin",
		"InvestmentRecord": "Investment Record",
		"Best_Assistant": "Investment Assistant",
		"last_100_rounds": "(last 100 rounds)",
		"What_do_these_data_mean": "Ano ang ibig sabihin ng data na ito?",
		"Missing": "Nawawala",
		"Missing_text": "Ito ay nangangahulugan na bilang ng mga round mula sa huling round na nag-produce ng numero na ito.",
		"Frequency": "Frequency",
		"Frequency_text": "Tumutukoy sa mga beses na lumalabas ang koresponding na numero sa huling 100 rounds.",
		"Max_Continued": "Max Continued",
		"Max_Continued_text": "Tumutukoy sa maximum na sunud-sunod na panahon ng lumalabas na numero sa huling 100 rounds.",
		"Rule": "Patakaran",
		"Trend": "Trend",
		"MyBalance": "Aking Balance",
		"JoinGreen": "Sumali sa Green",
		"JoinRed": "Sumali sa Red",
		"JoinViolet": "Sumali sa Violet",
		"Parity": "Paridad",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "Rekord",
		"more": "higit pa",
		"Period": "Panahon",
		"Price": "Presyo",
		"Result": "Resulta",
		"My": "Akin",
		"Order": "Order",
		"Buy": "Bumili",
		"Profit": "Kita",
		"Green": "Green",
		"Red": "Red",
		"Violet": "Violet",
		"tips": "Ang bayad sa transaksyon ay {bet_fee * 100}% ng halaga ng order",
		"tips1": "Ang bayad sa transaksyon ay ",
		"tips2": "ng halaga ng order",
		"OrderAmount": "Halaga ng Order",
		"Quantity": "Dami",
		"TransactionFee": "Bayad sa Transaksyon",
		"TotalAmount": "Kabuuang Halaga",
		"PresalePolicy": "Patakaran sa Privacy",
		"Iagree": "Nag-aagree ako",
		"err1": "Mangyaring sumang-ayon sa mga patakaran sa pamamahala ng pre-sale una."
	},
	"historyPeriods": {
		"Orderrecord": "Talaan ng Order",
		"record": "rekord"
	},
	"historyOrderPeriods": {
		"Wating Order": "Naghihintay na Order",
		"History Order": "Kasaysayan ng Order"
	},
	"Activity": {
		"added_to_account_balance": "idinagdag sa account balance.",
		"contentText": "Kapag ang inimbitahang gumawa ng unang deposito ay naging wastong imbitasyon. Makakatanggap ang imbitador ng bonus para sa bawat wastong imbitasyon. Ang bonus ng imbitasyon ay nagmula sa {{aaaaaa}}USDT para sa 1 wastong imbitasyon hanggang {{aaaaaa}}USDT para sa 500 wastong imbitasyon.",
		"contentText_1": "Kapag ang inimbitahang gumawa ng unang deposito ay naging wastong imbitasyon. Makakatanggap ang imbitador ng bonus para sa bawat wastong imbitasyon. Ang bonus ng imbitasyon ay nagmula sa",
		"contentText_2": "para sa 1 wastong imbitasyon hanggang",
		"contentText_3": " para sa 500 wastong imbitasyon",
		"contentText_3_1": " para sa ",
		"contentText_3_2": " wastong imbitasyon",
		"contentText2": "Magkakaroon ang imbitador ng {{LV.1%}} ng trading fee mula sa bawat trading volume ng bawat LV.1 na inimbita. Makakatanggap din ang imbitador ng {{LV.2%}} ng trading fee mula sa bawat trading volume ng bawat LV.2 na inimbita. Ang maximum bonus para sa bawat trading volume bonus ay walang limitasyon sa USDT.",
		"contentText2_1": "Magkakaroon ang imbitador ng ",
		"contentText2_2": "ng trading fee mula sa bawat trading volume ng bawat LV.1 na inimbita. Makakatanggap din ang imbitador ng",
		"contentText2_3": "ng trading fee mula sa bawat trading volume ng bawat LV.2 na inimbita. Ang maximum bonus para sa bawat trading volume bonus ay walang limitasyon sa USDT.",
		"My_Rewards_History": "Aking Kasaysayan ng Rewards",
		"Cumulative_Single_Number_Trading_Volumn": "Kumulatibong Volume ng Single Number Trading",
		"Cumulative_ODD_Number_Trading_Volumn": "Kumulatibong Volume ng ODD Number Trading",
		"Cumulative_EVEN_Number_Trading_Volumn": "Kumulatibong Volume ng EVEN Number Trading",
		"Cumulative_BIG_Number_Trading_Volumn": "Kumulatibong Volume ng BIG Number Trading",
		"Cumulative_SMALL_Number_Trading_Volumn": "Kumulatibong Volume ng SMALL Number Trading",
		"Advanced_Tasks": "Advanced na mga gawain",
		"Invite_Friends_and_Earn_Up_to": "Mag-imbita ng mga kaibigan at kumita ng hanggang sa",
		"Referral_Code": "Kodigo ng Pag-imbita",
		"Referral_Link": "Link ng Pag-imbita",
		"INVITE_FRIENDS": "IMBITAHAN ANG MGA KAIBIGAN",
		"Complete_tasks_to_earn_up_to": "Tapusin ang mga gawain para kumita ng hanggang sa",
		"MY_REWARDS": "AKING MGA REWARDS",
		"SHARE": "MAG-SHARE",
		"Earn_with_your_friends_together": "Kumita kasama ang iyong mga kaibigan",
		"Get_your_link": "Kumuha ng iyong link",
		"Claim_your_rewards": "Kunin ang iyong mga reward",
		"Earn_together": "Kumita ng sabay-sabay",
		"contentlist0": "Sumali sa {{gogobit}} at kumuha ng iyong referral link. Ang mga gumawa ng account gamit ang link na ito ay makatatanggap ng mga reward.",
		"contentlist0_1": "Sumali sa ",
		"contentlist0_2": " at kumuha ng iyong referral link. Ang mga gumawa ng account gamit ang link na ito ay makatatanggap ng mga reward.",
		"contentlist1": "Makatatanggap ka ng average na halaga ng reward na {{XX}} USDT kapag gumawa ng first deposit ang iyong kaibigan at nag-comply sa kinakailangang trading volume. Ang maximum reward ay maaaring umabot ng {{XX}} USDT.",
		"contentlist1_1": "Makatatanggap ka ng average na halaga ng reward na ",
		"contentlist1_2": " USDT kapag gumawa ng first deposit ang iyong kaibigan at nag-comply sa kinakailangang trading volume. Ang maximum reward ay maaaring umabot ng ",
		"contentlist1_3": " USDT.",
		"contentlist2": "Pagkatapos gumawa ng account, maaaring mag-claim ang iyong kaibigan ng trading bonus na umabot sa 5,050 USDT.",
		"Referral_Rewards": "Mga Reward sa Referral",
		"Detail_Rules": "Mga Detalye ng Patakaran",
		"Invitation_Bonus": "Invitation Bonus",
		"Up_to": "Hanggang sa",
		"content0": "Mag-complete ng kahit anong valid invitation at makatanggap ng invitation bonus na may average na halagang 10 USDT. Ang maximum bonus para sa isang daang tao ay",
		"content0_1": "Mag-complete ng kahit anong valid invitation at makatanggap ng invitation bonus na may average na halagang ",
		"content0_2": "Ang maximum bonus para sa isang daang invitee ay",
		"Trading_Bonus": "Trading Bonus",
		"content1": "Makakatanggap ang inviter ng bonus base sa transaction volume na ginawa ng invitee. Ang maximum bonus para sa isang valid invitation ay",
		"My_Referral_History": "Aking Kasaysayan sa Referral",
		"Total_Invitees": "Kabuuang Invitees",
		"Valid_Invitees": "Valid Invitees",
		"Cumulative_Invitees": "Invitees",
		"Trading_Volumn": "Volume ng Trading",
		"Daily_Tasks": "Daily Tasks",
		"Detail_Rules_title": "Daily Lucky DRAW ng 888 USDT",
		"Detail_Rules_title1": "1. Ang data ng futures trading ng araw ay kukunin kinabukasan.",
		"Detail_Rules_title2": "2. Ang mga user na gumawa ng futures trading volume na 200,000 USDT o higit pa sa isang araw ay makakatanggap ng isang pagkakataong mag-draw para sa 888 USDT na premyo.",
		"CLAIM_NOW": "KUNIN NA",
		"INVEST_NOW": "MAG-INVEST NA",
		"title": "Bonus wallet",
		"BonusBalance": "Bonus Balance",
		"Withdrawtobalance": "I-withdraw sa balanse",
		"BonusList": "Listahan ng Bonus",
		"Bonuswithdrawtobalance": "I-withdraw ang Bonus sa Bawat Balanse",
		"WithdrawalAmount": "Halaga ng I-withdraw",
		"Pleaseentertheamount": "Pakipasok ang halaga",
		"err1": "Ang halaga ng pag-withdraw ay hindi pwedeng 0",
		"success": "Matagumpay na Napadala"
	},
	"Refer": {
		"title": "Ang Aking Refer",
		"ReferTotalBonus": "Kabuuang Bonus ng Refer",
		"InviteFriends": "Mag-Invite ng mga Kaibigan",
		"Level1": "Antas 1",
		"Level2": "Antas 2",
		"TotalPeople": "Kabuuang Bilang ng mga Tao",
		"Bonus": "Bonus",
		"Myinvitationlink": "Ang Aking Link sa Pag-Invite",
		"Rebate_description": "Paglalarawan ng Rebate"
	},
	"Task": {
		"InviteBonusToday": "Bonus sa Pag-Anyaya Ngayon",
		"bonusrecord": "rekord ng bonus",
		"hint1": "1. Ang data ay na-update kada 10-30 minuto!",
		"hint2": "2. Tanging ang mga nakarehistro sa nakaraang 3 araw at nakumpleto ang pag-recharge ngayon ang bibilangin!",
		"hint2_1": "2. Tanging ang mga nakarehistro sa nakaraang ",
		"hint2_2": " araw at nakumpleto ang pag-recharge ngayon ang bibilangin!",
		"hint3": "3. Kinakailangan ng minimum na deposito na 20u ngayong araw!",
		"hint3_1": "3. Kinakailangan ng minimum na deposito na ",
		"hint3_2": " ngayong araw!",
		"hint4": "Bilang ng mga inimbita sa nakaraang tatlong araw",
		"hint4_1": "Bilang ng mga inimbita sa nakaraang ",
		"hint4_2": " araw",
		"hint5": "Bilang ng mga taong nakumpleto ang pag-recharge ngayong araw",
		"Invite": "Mag-anyaya",
		"friendstorecharge": "kaibigan na magre-recharge",
		"Earn": "Kitaan",
		"Completed": "Nakumpleto",
		"ExpireTime": "Oras ng Pag-expire"
	},
	"Invited": {
		"title": "Bonus Record",
		"InviteTotalBonus": "Kabuuang Bonus ng Pag-imbita",
		"InviteTime": "Oras ng Pag-imbita",
		"Bonus": "Bonus",
		"NoRecored": "Walang Rekord"
	},
	"mine": {
		"ProvacyPolicy": "Patakaran sa Pagkapribado",
		"JOIN US": "SUMALI SA AMIN",
		"title": "Personal Center",
		"InviteCode": "Invite Code",
		"AccountBalance": "Account Balance",
		"BonusBalance": "Bonus Balance",
		"Wallet": "Wallet",
		"Bank": "Bank",
		"Invite": "Mag-Invite",
		"Refer": "Rekomenda",
		"CustomerService": "Serbisyong Pangkustomer",
		"ComplaintsSuggestions": "Reklamo at Mungkahi",
		"ChangePassword": "Baguhin ang Password",
		"Activity": "Aktibidad",
		"PresalePolicy": "Patakaran sa Pagkapribado",
		"DownloadApp": "I-download ang App",
		"AppVersion": "App Version",
		"Logout": "Mag-logout",
		"Logouttips": "Sigurado ka bang gusto mong mag-logout?"
	},
	"App": {
		"title": "Pag-download ng App",
		"DownloadforAndroid": "I-download para sa Android",
		"DownloadforiOS": "I-download para sa iOS"
	},
	"Presale": {
		"title": "Patakaran sa Pagkapribado"
	},
	"Account": {
		"title2": "Maglagay ng bagong password",
		" title2_text ": "Maglagay ng bagong password para sa iyong google account. Maaari mong gamitin ang password upang mag-login sa iyong google account.",
		" title_text ": "Sinusubukan mong i-reset ang iyong password. Mangyaring maghanda ng iyong Google Authenticator para sa pag-reset.",
		" err1 ": " Ang dalawang bagong password na ipinasok ay magkaiba",
		" success2 ": " Password na binago nang matagumpay",
		" title ": " Palitan ang Password ",
		" OldPassword ": " Lumang Password ",
		" OldPasswordmsg ": " Ang lumang password ay kinakailangan ",
		" NewPassword ": " Bagong Password ",
		" NewPasswordmsg ": " kinakailangan ang bagong password ",
		" RetypeNewPassword ": " I-type ulit ang Bagong Password ",
		" RetypeNewPasswordmsg ": " I-type ulit ang Bagong Password ",
		" Continue ": " Magpatuloy"
	},
	"Service": {
		"title": "Serbisyo",
		"CONTACTUS": "MAKIPAG-UGNAY SA AMIN",
		"tips": "Nagpapahinga ang customer service ngayon!",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "link"
	},
	"Bank": {
		"BankName": "Pangalan ng Bangko",
		"ChooseaCurrencyType": "Pumili ng Uri ng Pera",
		"SelectWithdrawalMethod": "Pumili ng Paraan ng Pag-Widro",
		"Newbankcard": "Bagong bank card",
		"Editbankcard": "I-edit ang bank card",
		"title": "Aking Banko",
		"Cannotbeempty": "Hindi pwedeng blangko",
		"BankHolderName": "Pangalan ng may-ari ng Bank Account",
		"BankAccountNumber": "Numero ng Bank Account",
		"IFSCCode": "IFSC Code",
		"Email": "Email",
		"Phone": "Telepono",
		"OTPCode": "OTP Code",
		"tips1": "1. Gamitin ang mobile phone number na nirehistro para makatanggap ng OTP",
		"tips2": "2. Para sa seguridad ng pera, maaari lamang baguhin ang bank card isang beses sa isang araw",
		"Add": "Idagdag",
		"error1": "Ang Pangalan ng may-ari ng Bank Account ay hindi pwedeng blangko!",
		"error2": "Mangyaring maglagay ng tamang Pangalan ng may-ari ng Bank Account!",
		"error3": "Ang Pangalan ng Bangko ay hindi pwedeng blangko!",
		"error4": "Mangyaring maglagay ng tamang Pangalan ng Bangko!",
		"error5": "Ang Numero ng Bank Account ay hindi pwedeng blangko!",
		"error6": "Mangyaring maglagay ng tamang Numero ng Bank Account!",
		"error7": "Ang IFSC Code ay hindi pwedeng blangko!",
		"error8": "Mangyaring maglagay ng tamang IFSC Code!",
		"error9": "Ang Email ay hindi pwedeng blangko!",
		"error10": "Mangyaring maglagay ng tamang email address!",
		"error11": "Ang Telepono ay hindi pwedeng blangko!",
		"error12": "Ang OTP Code ay hindi pwedeng blangko!",
		"Successfully": "Matagumpay na nadagdagan ang bank card"
	},
	"wallet": {
		"title": "Aking wallet",
		"MyBalance": "Aking Balanse",
		"MyBank": "Aking Banko",
		"RechargeRecords": "Mga Recharge na Talaan",
		"WithdrawalRecords": "Mga Talaan ng Pag-Withdraw"
	},
	"Rechargepage": {
		"Reminder": "Paalala",
		"Reminder_1": "1. Huwag magdeposit ng ibang uri ng pera maliban sa USDT sa address. Ang pagpapadala ng mga hindi suportadong coin o token sa address na ito ay maaaring magresulta sa hindi na maibabalik na pagkawala.",
		"Reminder_2": "2. Ang minimum deposit amount ay 5 USDT.",
		"Reminder_2_1": "2. Ang minimum deposit amount ay ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Ang deposits ay darating matapos ang 15 network confirmation.",
		"Minimum": "Minimum",
		"title": "Magdeposit",
		"History": "Kasaysayan ng Deposit",
		"Mybalance": "Aking balanse",
		"RechargeAmount": "Halaga ng deposit",
		"RechargeAmountmsg": "Mangyaring maglagay ng halaga",
		"tips1": "Ang payment channel ay nasa ilalim ng maintenance!",
		"tips2": "Mangyaring pumili ng payment channel",
		"PayNow": "Magbayad Ngayon",
		"err1": "Ang payment channel ay nasa ilalim ng maintenance",
		"err2": "Ang halaga ay hindi dapat mas mababa sa ",
		"err3": "Mangyaring pumili ng payment channel",
		"Rechargemethod": "Deposit Method"
	},
	"Rechargerecord": {
		"title": "Rekord ng Pagpapadagdag ng Pondo",
		"WatingOrder": "Naghihintay na Order",
		"HistoryOrder": "Kasaysayan ng Order",
		"Rupee": "Dolyar",
		"Status": "Katayuan",
		"Time": "Oras"
	},
	"withdrawal": {
		"Tips": "Mga Tip:",
		"Tips_1": "Tip: Ang bayad na singil sa pagwithdraw ng balanse na nasa ilalim ng {{solid_min_amount}} USDT ay {{solid_feemoney}} USDT, ang bayad naman sa pagwithdraw ng {{solid_min_amount}} USDT at higit pa ay {{draw_fee}}%.",
		"Tips_2": "Tip: Ang bayad na singil sa pagwithdraw ng balanse na nasa ilalim ng ",
		"Tips_3": " USDT ay ",
		"Tips_4": "USDT at ang bayad naman sa pagwithdraw ng ",
		"Tips_5": " USDT at higit pa ay ",
		"WalletAddress": "Wallet Address",
		"Addwalletaddress": "Magdagdag ng wallet address",
		"Network_fee": "Network fee",
		"Receive_amount": "Halaga ng pagtanggap",
		"Select Chains": "Pumili ng Chains",
		"Bankcard": "Bank card",
		"Nobankcardselected": "Walang bank card na idinagdag",
		"Withdrawalfee": "Bayad sa pagwithdraw",
		"title": "Pagwithdraw",
		"Mybalance": "Aking balanse",
		"Withdrawablebalance": "Puwedeng iwithdraw na balanse",
		"tips1": "Ang total na halaga ng binili ay dapat higit sa {{codeAmount}} at maaaring iwithdraw ng buo",
		"tips1-1": "Ang total na halaga ng binili ay dapat higit sa",
		"tips1-2": "maaaring iwithdraw ng buo",
		"tips2": "{{draw_num}} beses lamang pwedeng magwithdraw sa isang araw",
		"tips2-1": "",
		"tips2-2": "beses lamang pwedeng magwithdraw sa isang araw",
		"tips3": "Ang pinakamalaking halaga ng pwedeng iwithdraw sa isang araw ay",
		"tips4": "Ang minimum na halaga ng pagwiwithdraw ay ₹{{draw_min_money}} at ang maximum na halaga ay ₹{{draw_max_money}}",
		"tips4-1": "Ang minimum na halaga ng pagwiwithdraw ay",
		"tips4-2": "at ang maximum na halaga ay",
		"tips5": "Ang oras ng pagwiwithdraw ay nasa {{draw_time}}, mangyaring magwithdraw sa tamang oras",
		"tips5-1": "Ang oras ng pagwiwithdraw ay nasa",
		"tips5-2": "mangyaring magwithdraw sa tamang oras",
		"tips6": "Magkakaltas ng {{draw_fee}}% sa bayad sa pagwithdraw",
		"tips6-1": "Magkakaltas ng",
		"tips6-2": "sa bayad sa pagwithdraw",
		"tips7": "Kung ang iyong halaga ng pagwiwithdraw ay hindi hihigit sa ₹{{solid_min_amount}}, mayroong fixed fee na ₹{{solid_feemoney}} ang babayaran",
		"tips7-1": "Kung ang iyong halaga ng pagwiwithdraw ay hindi hihigit sa",
		"tips7-2": "mayroong fixed fee na",
		"tips7-3": "ang babayaran",
		"WithdrawalAmount": "Halaga ng Pagwithdraw",
		"WithdrawalAmountmsg": "Mangyaring magpasok ng halaga",
		"Withdrawal": "Magwithdraw",
		"WithdrawalHistory": "Kasaysayan ng Pag-Widro",
		"TIPS": "Mga Tips",
		"TIPSmsg": "Ang aplikasyon ng pag-widro ay matagumpay, naghihintay sa resulta ng audit!",
		"dangerMsg1": "Mangyaring magdagdag ng iyong bank card muna",
		"dangerMsg2": "Hindi maaaring maging mas mababa sa halagang",
		"dangerMsg3": "Dapat na multiple ng 100 ang halaga ng pag-widro",
		"dangerMsg4": "Hindi maaaring maging mas malaki sa halagang",
		"dangerMsg5": "Hindi maaaring lumampas sa Withdrawable balance ang halaga ng pag-widro",
		"dangerMsg6": "Mangyaring magdagdag ng iyong bank card muna"
	},
	"withdrawalrecord": {
		"title": "rekord ng pag-withdraw",
		"WatingOrder": "Naghihintay na Order",
		"HistoryOrder": "Kasaysayan ng Order",
		"Rupee": "Dolyar",
		"Status": "Kalagayan",
		"Time": "Oras",
		"Nomore": "Wala na!"
	},
	"Version": {
		"title": "Bersyon",
		"stableversion": "Stable na bersyon",
		"Alreadythelatestversion": "Nasa pinakabagong bersyon na",
		"Newversionfound": "May bagong bersyon na",
		"DownloadUpdate": "I-download ang Update"
	},
	"Bonus": {
		"title": "Aktibidad ng Bonus",
		"ReceiveBonus": "Matanggap ang Bonus",
		"Hi": "Hi",
		"tips1": "Maligayang pagbati sa pagkuha ng bonus",
		"tips2": "Kailangan mong mag-recharge ng mas malaki o pantay sa",
		"tips3": "at pagkatapos ay awtomatikong idaragdag ang bonus sa iyong account balance",
		"Received": "Natanggap na",
		"Back": "Bumalik",
		"Rechargenow": "Mag-recharge ngayon"
	},
	"ComplaintsSuggestions": {
		"title": "Mga reklamo at mga Mungkahi",
		"Answered": "Nasagot na",
		"AddNew": "Magdagdag ng Bago",
		"Time": "Oras",
		"Type": "Uri",
		"WhatsApp": "WhatsApp",
		"Description": "Paglalarawan",
		"Remark": "Pansin",
		"Waiting": "Naghihintay",
		"Suggestion": "Mungkahi",
		"Consult": "Makipag-usap",
		"RechargeProblem": "Problema sa Pagdedeposito",
		"WithdrawProblem": "Problema sa Pagwiwithdraw",
		"OrderProblem": "Problema sa Pag-order",
		"Other": "Iba pa",
		"Describeyourproblems": "Ilarawan ang iyong problema",
		"WhatsApptocontactyou": "WhatsApp upang makipag-ugnay sa iyo",
		"submit": "Ipasa",
		"err1": "WhatsApp ay hindi maaaring blangko!",
		"err2": "Ang Uri ay hindi maaaring blangko!",
		"err3": "Ang Paglalarawan ay hindi maaaring blangko!"
	},
	"slotMachine": {
		"title": "Makinang Prutas",
		"Myblance": "Aking Balanse",
		"Records": "Mga rekord ng Makinang Prutas",
		"titleRecord": "Mga Rekord"
	},
	"bankrecharge": {
		"Orderamount": "Halaga ng Order",
		"Ordernumber": "Numero ng Order",
		"BankName": "Pangalan ng Bank",
		"BankAccount": "Bank Account",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Numero ng iyong bank card",
		"Enterthebanktransactionordernumber": "Ilagay ang numero ng bank transaction order",
		"submit": "ipasa",
		"Tips": "Mga Tip",
		"tips1": "1. Ang sistema ay nagpoproseso ng iyong order.",
		"tips2": "2. Mangyaring makipag-ugnay sa customer service kung hindi pa dumadating ang account ng matagal na panahon.",
		"err1": "Mangyaring ipasok ang numero ng iyong bank card",
		"err2": "Mangyaring ilagay ang numero ng order ng banko"
	},
	"javaCode": {
		"200": "tagumpay",
		"20001": "Hindi wastong access token",
		"20002": "Hindi wastong login token",
		"20003": "Mali ang numero ng telepono",
		"20004": "Mayroon ng numero ng telepono!",
		"20005": "Mali ang email!",
		"20006": "Mayroon ng email!",
		"20007": "Mali ang password!",
		"20010": "Mali ang account o password!",
		"20011": "Mali ang bank account!",
		"20012": "Mali ang bank ifsc, (standard na format ng IFSC) - haba na 11, una'y apat na IFSC at 5th 0!",
		"20013": "Mali ang pangalan ng benepisyaryo, 5 < pangalan <= 50!",
		"20021": "Ang account ay hindi umiiral!",
		"20022": "Ang account ay nakatigil!",
		"20031": "Ang layer ay limitado sa 10!",
		"20032": "Error sa pag-update ng role ng relasyon!",
		"20033": "Hindi sapat na balanse!",
		"20034": "Maling halaga ng pagkakawithdraw!",
		"30001": "Ang serbisyo ay pansamantalang sarado!",
		"30002": "Ang limitasyon ng pusta ay nasa pagitan ng 10 at 50000!",
		"30003": "Ang kasalukuyang isyu ay tapos na!",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Pagkakamali sa pagrerehistro!",
		"200082": "Nakatikim ng disable ang account!",
		"200091": "Ang pagdagdag ng card ay limitado sa 10!",
		"200092": "Masyadong maraming pagkakamali sa pag-login, at disable na ang account!",
		"300041": "Ang lahat ng halaga ng transaksyon ng isyu ay may limitasyon!",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Server error!",
		"Handledataerror": "Please Login!",
		"-91": "Server error!",
		"-92": "Handle data error!",
		"-1": "System error!",
		"-2": "Mali ang mga pamamaraan",
		"-3": "Walang data",
		"-4": "Masyadong madalas na operasyon sa maikling panahon",
		"-5": "System busy, subukan ulit mamaya!",
		"-6": "Maraming tao sa kasalukuyan, subukan ulit!",
		"-7": "mali ang data!",
		"-8": "Google code error!",
		"-9": "Ang sistema ay nasa ilalim ng maintenance! subukan ulit mamaya!",
		"-10": "Error sa pag-save!",
		"-11": "Error sa pag-alis!",
		"-12": "Error sa pag-update!",
		"-13": "Ilegal na operasyon!",
		"-14": "Nabigo ang operasyon!",
		"-15": "Pinatay ang operasyon!",
		"-16": "Tapos na ang operasyon!",
		"-17": "Mali ang pagkakasulat",
		"-18": "Hindi umiiral!",
		"-19": "Nauubos na",
		"-20": "Na-disable na",
		"-21": "mali ang verify code",
		"-22": "Ang interval ng pagbabago ay dapat na mas malaki sa 1 araw",
		"-23": "Ang bagong password ay hindi dapat pareho sa dati",
		"-100": "custom error!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Kabuuang Halaga ng Pagbili",
		"TotalRevenue": "Kabuuang Kitang Kinuha",
		"AccountBalance": "Balance ng Account",
		"Income": "Kita",
		"Transaction": "Transaksyon",
		"Myhold": "Aking Hinawakan",
		"ProductList": "Listahan ng Produkto",
		"Investmentperiod": "Panahon ng Pamumuhunan",
		"days": "araw",
		"Buynow": "Bumili na",
		"Saletime": "Oras ng Pagbebenta",
		"Nomore": "Wala na",
		"ProductDetails": "Mga Detalye ng Produkto",
		"ForSale": "Para sa Pagbebenta",
		"Sale": "Pagbebenta",
		"SoldOut": "Sold Out",
		"ProductInformation": "Impormasyon sa Produkto",
		"InvestmentPeriod": "Panahon ng Pamumuhunan",
		"TotalShare": "Kabuuang Bahagi",
		"ExpectedReturn": "Inaasahang Kita",
		"SaleTime": "Oras ng Pagbebenta",
		"PurchaseConditionsAndRules": "Mga Kondisyon at Alituntunin sa Pagbili",
		"MinimumPurchaseAmount": "Minimum na Halaga ng Pagbili",
		"MaximumSalesLimit": "Maximum na Limit sa Pagbebenta",
		"AccountBalanceMustBeGreaterThan": "Ang Balance ng Account ay Dapat na Mas Malaki sa",
		"DayBetIsGreaterThan": "Day Bet ay Mas Malaki sa",
		"ProductDescription": "Paglalarawan sa Produkto",
		"MyHold": "Aking Hinawakan",
		"Ordernumber": "Numero ng Order",
		"PurchasingPrice": "Presyong Binayaran sa Pagbili",
		"NoRecord": "Walang Nakarehistrong Mga Detalye",
		"Expiredate": "Petsa ng Pagkakatapos",
		"Purchasetime": "Oras ng Pagbili",
		"redemption": "pagsapit ng katapusan",
		"redemptionTips": "Ang pag-redemption ng maaga ay hindi magbibigay ng anumang kita, nakumpirma na ba ang redemption?",
		"cancel": "kanselahin",
		"determine": "tiyakin",
		"Buyin": "Bumili",
		"Buyamount": "Halaga ng Pagbili",
		"BuyinTips": "Ang maagang redemption ay hindi magbibigay ng anumang kita",
		"confirm": "kumpirmahin",
		"Minimumbuy": "Minimum na Halaga ng Pagbili",
		"Maturity": "Pagtatapos",
		"EarlyRedemption": "Maagang Redemption",
		"Redemptiontime": "Oras ng Redemption",
		"Amountofincome": "Halaga ng Kita",
		"prompt": "paalala"
	},
	"HelpHa": {
		"Help": "Tulong",
		"HelpCenter": "Sentro ng Tulong",
		"text": "Mayroon kang katanungan? Tingnan dito."
	},
	"SetingHa": {
		"Seting": "Pagtatakda",
		"SetingCenter": "Sentro ng Pagtatakda",
		"text": "Magkaroon ng Mas Maraming Rewards"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"home.Products&Services": "Mga Producto at Servisyo",
	"historyOrderPeriods.WatingOrder": "Hinihintay na order",
	"historyOrderPeriods.HistoryOrder": "Historical orders",
	"Account.title2_text": "Ilagay ang bagong password para sa iyong Google account. Maaari mong gamitin ang password para log in sa iyong Google account.",
	"Account.title_text": "Sinusubukan mong i-reset ang iyong password. Mangyaring maghanda ng iyong Google validator para sa pag-reset.",
	"Account.err1": "Ang bagong password na ipinasok dalawang beses ay naiiba",
	"Account.success2": "Ang pagbabago ng kontrasenyas ay matagumpay",
	"Account.title": "Baguhin ang password",
	"Account.OldPassword": "Lumang kontrasenyas",
	"Account.OldPasswordmsg": "Pakiusap ipasok ang iyong lumang password",
	"Account.NewPassword": "Bagong kontrasenyas",
	"Account.NewPasswordmsg": "Pakiusap ipasok ang bagong password",
	"Account.RetypeNewPassword": "Patvirtin ang bagong password",
	"Account.RetypeNewPasswordmsg": "Pakiusap ipasok muli ang bagong password",
	"Account.Continue": "Magpatuloy",
	"Rechargepage.Tips": "Kind reminder: The first recharge reward is {{first RPR * 100}% of the recharge amount",
	"Rechargepage.Tips_1": "Mabuti na paalala: Ang unang tagumpay ay batay sa halaga ng tagumpay",
	"Rechargepage.Tips_2": "%",
	"withdrawal.Google_Code": "Google verification code",
	"withdrawal.Tipsmsg": "Ipinadala na ang hiling sa pag-withdraw, naghihintay sa resulta ng pagsusulit!",
	"index.index.gayb21": "Mag-register para makatanggap ng mga nagpapakita:",
	"index.index.72y628": "Tatlong dahilan upang pumili ng EPOCH EPT",
	"index.index.z6022y": "Sinusunod ni EPOCH EPT ang isa sa pinakamataas na pamantayan ng kaligtasan sa industriya. Maglagay tayo ng 100% ng cryptocurrency sa refrigerated warehouse. Garantiya namin na magbigay ng hindi bababa sa 1:1 reserve ratio para sa mga pondo ng mga customer. Ang aming $300 milyong pondo ay nagbibigay ng karagdagang proteksyon laban sa mga banta sa seguridad.",
	"index.index.574982": "1. Magregister para sa EPOCH EPT na libre na ngayon",
	"index.index.qdi1g4": "2. kumpletong Google Verification",
	"index.index.weko28": "3. Instantly reload your EPOCH EPT account",
	"index.index.sgkb35": "EPOCH EPT Invitation Program",
	"index.index.2u17nf": "Ang pagkumpleto ng anumang tamang imbitasyon ay maaaring makakuha ng karaniwang halaga hanggang sa 5-15 USD The maximum bonus for one hundred invitees is",
	"index.index.e8is4a": "Program ng Rekomendasyon ng EPOCH EPT",
	"index.index.47uevl": "EPOCH EPT transaction task",
	"index.index.2516gq": "Nakatanggap ni EPOCH EPT, Inc. ang isang virtual currency business license mula sa New York State Financial Services Department.",
	"invest.index.3aqi3d": "Account balance:",
	"invest.index.ubk1j4": "Itigil ang pag-invest!",
	"invest.prize1.4733u6": "Portfolio ng pag-invest:",
	"invest.prize1.t55a16": "Kabilang na halaga:",
	"login.login.09m52q": "Nabasa ko at sumasang-ayon sa EPOCH EPT",
	"login.login.045csy": "Pangalan ng gumagamit",
	"login.login.r7rbm5": "Mangyaring sumasang-ayon sa kasunduan!",
	"rewards.activityb.4nvrq9": "Kapag ang imbitasyon ay kumpleto ang kanilang unang deposit, ito ay itinuturing na tunay na imbitasyon. Ang imbitador ay makakakuha ng bonus ng imbitasyon para sa bawat tamang imbitasyon. The invitation bonus level comes from 2 USD 1 valid invitation, 559 USD 100 valid invitations.",
	"rewards.activityb.t10k6e": "Ang imbitador ay magbabahagi ng 20% ng komisyon ng transaksyon para sa bawat imbitador ng LV.1. Ang imbitador ay magbabahagi ng 10% ng komisyon ng transaksyon para sa bawat LV. 2 imbitador ng bayad ng transaksyon. Ang pinakamalaking bonus para sa bayaran ng transaksyon ng imbitado ay walang hanggan na dolyares ng US.",
	"rewards.activityb.3d6850": "LV.1 Ang epektibong bilang ng mga imbitado",
	"rewards.activityb.lvxo74": "LV.2 Effective number of invitees",
	"rewards.activityb.zj26f9": "LV.1 Accumulated number of invitees",
	"rewards.activityb.dkgxr5": "LV.2 Binubuo na bilang ng mga imbitado",
	"rewards.activityb.xjo077": "Rekomendasyon:",
	"rewards.activityb.k8wv6v": "Magsumali kayo sa EPOCH EPT at makuha ang iyong inirereklamong link. Ang mga gumagamit na naka-register sa pamamagitan ng link na ito ay makakakuha ng rewards.",
	"rewards.activityb.4slpzg": "Pagkatapos ng iyong kaibigan ay kumpleto ang kanilang unang deposit at kumpleto ang kinakailangang dami ng transaksyon, makakakuha ka ng isang referral reward na may karaniwang halaga ng $5-15. The maximum reward can reach $559.",
	"rewards.index.993jwg": "Successfully copied",
	"rewards.index.7141ft": "Hindi suportahan ng browser na ito ang awtomatikong pagkopya",
	"wallet.index.9uz2gn": "0 USDT",
	"wallet.index.ych3e1": "Aktwal na tanggapan:",
	"wallet.index.3v558d": "Ipinaalala: Kung ang pag-withdrawal ay mas mababa sa $20, ang withdrawal handling fee ay $1. Kung ang withdrawal ay higit sa $20, ang withdrawal handling fee ay 3%",
	"wallet.index.255e30": "1. Ang pinakamalaking halaga ng araw-araw na pagtanggal ay $8000000",
	"wallet.index.3g68m8": "2. Ang pinakamababang sukatan ng isang withdrawal ay $5, at ang pinakamababang sukatan ng isang withdrawal ay $8000000",
	"wallet.index.c6x3ti": "3. Ang oras ng pagtanggal ay mula 00:00 hanggang 23:59, mangyaring gumawa ng pagtanggal sa nabanggit na oras",
	"wallet.index.h94rlg": "Minimum limit 5",
	"wallet.index.167862": "Paki-enter ang address ng wallet!",
	"wallet.index.pi869h": "Pakiusap ipasok ang halaga ng pag-withdrawal!",
	"wallet.index.ysa85i": "Ang pinakamalaking iisang halaga ng pag-withdraw ay",
	"components.changepass.68j9kh": "Sinusubukan mong i-reset ang iyong password.",
	"components.changepass.ouxtp2": "Orihinal na password",
	"components.languagebox.8no849": "Japanese",
	"components.languagebox.oif20b": "Tsino",
	"components.mycode.xyqr66": "Ang pagkumpleto ng mga gawain ay maaaring kumita ng hanggang sa 5000 USDT",
	"components.mymenu.277ss7": "User ID:",
	"components.mymenu.74l443": "Oras ng rehistro:",
	"components.myposter.36rw5e": "Recommendation code:",
	"components.mytabbar.zw2bh2": "home page",
	"components.mytabbar.8x6c28": "trend",
	"components.mytabbar.28s7qr": "investasyon",
	"components.mytabbar.4r75d9": "reward",
	"components.xieyi.zqk7pe": "sukat ng Font: 16px; Family of Fonts: Tahoma;",
	"components.help.6gpud8": "May dalawang modelo ng pag-invest:",
	"components.help.5qrq36": "Ang bawat panahon ay magtatagal ng 1 minuto at nagbibigay lamang ng isang o double na investasyon. Ang resulta ay kinakalkula sa pamamagitan ng huling numero ng 1-minutong presyo ng pagsasara ng Binance BTC/USDT spot trading.",
	"components.help.isl7r1": "Ang bawat sesyon ay magtatagal ng 5 minuto. Ang propesyonal na bersyon ay nagpapalabas sa lahat ng paraan ng pag-invest ng EPOCH EPT. Ang resulta ay kinakalkula sa pamamagitan ng huling numero ng 5-minutong presyo ng pagsasara ng Binance BTC/USDT spot trading.",
	"components.help.eai3nm": "Inversiyon return:",
	"components.help.35xg82": "Isang numero:",
	"components.help.mpfg0s": "Ingatan: halaga ng pag-invest X 9.4",
	"components.help.v2ziib": "Kakaibang numero:",
	"components.help.vfzxor": "Ingatan: halaga ng pag-invest X 1.94",
	"components.help.73168b": "numero ng gabi:",
	"components.help.76wgv1": "Malaking numero:",
	"components.help.05ed33": "Maliliit na numero:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Pakiusap ipasok ang orihinal na password!",
	"components.changepass.xme6my": "Pakiusap ipasok ang bagong password!",
	"components.changepass.u434f8": "Ang dalawang kontrasenyas ay hindi tumutugma!",
	"components.changepass.574790": "Binago matagumpay!",
	"invest.index.9y8wg0": "Not winning a prize",
	"invest.index.ql5nc1": "Nagtatagumpay",
	"login.login.76451t": "Exchange price difference A quantitative trading!",
	"login.login.915p36": "Ang pinakamahalagang pang-pinansiyal na pamahalaan ay pinakamalaking pandaigdigan",
	"login.login.sen863": "Mas nakakatuwa pagkatapos ng Hello ay nag-login",
	"login.login.nz6q9x": "Phone login",
	"login.login.w7p592": "Email login",
	"login.login.g9y91k": "numero ng mobile phone",
	"login.login.456gbb": "Email number",
	"login.login.5ly8m8": "bansa",
	"login.login.69h472": "Mangyaring pumili ng bansa",
	"login.login.i4p378": "numero",
	"login.login.fl82xx": "Halimbawa:",
	"wallet.index.urkoub": "Reload QR code",
	"wallet.index.25cocl": "Kopyahin ang address",
	"wallet.index.t35fvg": "1. oras ng pagpapaalis: Mangyaring magkalkula ng regional tax sa Linggo sa loob ng 24 oras mula Lunes hanggang Sabado!",
	"wallet.index.68xx61": "2.",
	"wallet.index.4ws3w9": "3.",
	"wallet.index.9b7wq8": "4. Ang pinakamababang halaga ng pag-withdraw ay 12 USDT, at ang pinakamababang halaga ng pag-withdraw ay 3000 USDT.",
	"wallet.index.23y92a": "Mangyaring malaman na",
	"wallet.index.pl5473": "Minimum limit ng 5",
	"wallet.index.nb3u72": "Mga impormasyon sa bank card",
	"wallet.index.842so4": "Idagdag ang numero ng bank card",
	"wallet.index.86j717": "Idagdag ang affiliated bank",
	"wallet.index.763d7x": "Idagdag ang pangalan ng bank card",
	"wallet.index.50j237": "Affiliated bank",
	"wallet.index.hb6lun": "card name",
	"wallet.index.62y2jc": "Bank card number",
	"login.register.666hp4": "Ang kwantitativong negosyo ng pagkakaiba sa presyo Al",
	"login.register.9298b8": "Invite friends",
	"login.register.375g14": "Mga bagong sumali sa unang pagkakataon",
	"login.register.01922f": "Maaaring gumawa ng isang beses",
	"login.register.527o4q": "Araw-araw na chemical revenue AI",
	"login.register.c563gt": "Rejestrasyon ng e-mail",
	"login.register.2ls856": "May pagkakamali sa format ng numero ng telepono!",
	"login.register.4nu77e": "May pagkakamali sa format ng numero ng e-mail!",
	"lang.zh.pu623z": "1 tamang imbitasyon",
	"lang.zh.3hv80b": "USDT recharge!",
	"lang.zh.9tli5f": "Tip: Mga Gumamatay sa ibaba",
	"lang.zh.62e882": "USD, withdrawal fee ay",
	"lang.zh.m3818q": "USD, pagtanggal",
	"lang.zh.xs2f10": "Ang maagang pagbabayad ay hindi magtatagumawa ng anumang pagbabalik"
}