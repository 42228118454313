module.exports = {
	"Language": {
		"title": "ภาษา"
	},
	"Noproductsforsale": "ไม่มีสินค้าขาย",
	"rechargePresentationText0_1": "เติมเงิน 1000 รับของสมนาคุณภาพ ",
	"rechargePresentationText0_2": ", เติมเงิน 10000 รับของสมนาคุณภาพ ",
	"rechargePresentationText0_3": ", และอื่นๆ... คลิกที่นี่!",
	"rechargePresentationText0": "คุณจะได้รับ ",
	"rechargePresentationText1": "% ของจำนวนเงินเติมเงินเมื่อคุณเริ่มเติมเงินตอนนี้ ช่วงเวลาของกิจกรรมจำกัดเวลาและจะหยุดเมื่อของขวัญหมด",
	"rechargePresentationText2": "เวลาสิ้นสุดของของขวัญ, นับถอยหลัง !",
	"Online_Chat": "แชทออนไลน์",
	"gobindgoogle": "กรุณาผูก Google Authenticator ของคุณ",
	"Order": "สั่งซื้อ",
	"Record": "บันทึก",
	"Assist": "ช่วยเหลือ",
	"Market": "ตลาด",
	"History": "ประวัติ",
	"Confirm": "ยืนยัน",
	"close": "ปิด",
	"Cancel": "ยกเลิก",
	"Previous": "ก่อนหน้า",
	"Next": "ถัดไป",
	"Copy": "คัดลอก",
	"Nomoredata": "ไม่มีข้อมูล",
	"moneychar": "฿",
	"DownloadApp": " ดาวน์โหลดแอพตอนนี้",
	"Yes": "ใช่",
	"No": "ไม่ใช่",
	"Wating": "รอดำเนินการ",
	"Recharge": "เติมเงิน",
	"Withdrawal": "ถอนเงิน",
	"Success": "สำเร็จ",
	"stop_investing": "หยุดการลงทุน",
	"OrderSuccessful": "สั่งซื้อสำเร็จ",
	"Handledataerror": "จัดการข้อมูลผิดพลาด",
	"Copysuccess": "คัดลอกสำเร็จ",
	"MyBalanceLoding": "กำลังโหลดยอดเงินคงเหลือของฉัน",
	"UPDATE": "อัปเดต",
	"New_version_available": "มีเวอร์ชั่นใหม่",
	"Please,update_app_to_new_version": "โปรดอัปเดตแอปเป็นเวอร์ชันใหม่",
	"tabbar": {
		"Home": "หน้าหลัก",
		"AndarBahar": "เกม Andar Bahar",
		"Invest": "ลงทุน",
		"Bonus": "โบนัส",
		"Mine": "กระเป๋าเงิน",
		"Trending": "เทรนด์",
		"Wallet": "กระเป๋าเงิน",
		"Rewards": "รางวัล"
	},
	"homeTop": {
		"Sign_Up": "สมัครสมาชิก",
		"Sign_up_for_rewards": "สมัครรับรางวัล",
		"up_to": "สูงสุดถึง",
		"TOTAL_ASSETS": "สินทรัพย์รวม",
		"User_ID": "รหัสผู้ใช้",
		"Registration_time": "เวลาที่ลงทะเบียน"
	},
	"PieChart": {
		"BIG&SMALL": "ใหญ่&เล็ก",
		"NUMBER": "เลข",
		"ODD&EVEN": "คี่&คู่",
		"ALL_NUMBER_TRENDING": "เลขย้อนหลังทั้งหมด",
		"BIG&SMALL_NUMBER_TRENDING": "เลขใหญ่&เล็กย้อนหลัง",
		"ODD&EVEN_NUMBER_TRENDING": "เลขคี่&เลขคู่ย้อนหลัง"
	},
	"register": {
		"checkerr": "รหัสผ่าน: ใช้ตัวอักษรและตัวเลขรวมกันอย่างน้อย 8 ตัวอักษร",
		"tips_3": "หากคุณไม่ได้รับรหัสผ่านในกล่องจดหมายของคุณ โปรดตรวจสอบในโฟลเดอร์สแปมของคุณ",
		"receiveCode": "ยังไม่ได้รับรหัสผ่าน?",
		"receiveCode_title": "รหัสผ่านถูกส่งไปยังอีเมลของคุณ หากคุณยังไม่ได้รับรหัสผ่านหลังจากหลายครั้ง โปรดลอง:",
		"receiveCode_text1": "1. ตรวจสอบในกล่องจดหมายขยะ / สแปมของคุณ",
		"receiveCode_text2": "2. ตรวจสอบให้แน่ใจว่าที่อยู่อีเมลของคุณถูกต้อง",
		"receiveCode_text3": "3. ข้อความอาจล่าช้าได้หลายนาที ลองอีกครั้งหลังจาก 20 นาที",
		"receiveCode_text4": "4. หากที่อยู่อีเมลนี้มีอยู่แล้วเราจะไม่ส่งรหัสยืนยันให้คุณ โปรดเข้าสู่ระบบหรือรีเซ็ตรหัสผ่านของคุณ",
		"Sign_up_for_Rewards": "ลงทะเบียนรับรางวัล",
		"Email_Verification": "การตรวจสอบอีเมล",
		"tips": "โปรดป้อนรหัสยืนยัน 6 หลักที่ถูกส่งไปที่ {{formData.email}} รหัสมีอายุการใช้งาน 30 นาที",
		"tips_1": "โปรดป้อนรหัสยืนยัน 6 หลักที่ถูกส่งไปที่ ",
		"tips_2": "รหัสมีอายุการใช้งาน 30 นาที",
		"Resend_code_in": "ส่งรหัสอีกครั้งใน",
		"Sent_again": "ส่งอีกครั้ง",
		"CREATE_ACCOUNT": "สร้างบัญชีผู้ใช้",
		"inviteCodecheck": "inviteCode ไม่สามารถเว้นว่างได้!",
		"Mobile": "หมายเลขโทรศัพท์มือถือ",
		"Mobilemsg": "ต้องระบุหมายเลขโทรศัพท์มือถือ",
		"Password": "รหัสผ่าน",
		"Passwordmsg": "ต้องระบุรหัสผ่าน",
		"OTPCode": "รหัส OTP",
		"OTPCodemsg": "ต้องระบุรหัส OTP",
		"SendOTP": "ส่ง OTP",
		"Email": "อีเมล",
		"Emailmsg": "ต้องระบุอีเมล",
		"Invitecode": "รหัสอ้างอิง (ไม่จำเป็น)",
		"Invitecodemsg": "ต้องระบุรหัสอ้างอิง",
		"emailFormatter": "กรุณาระบุอีเมลให้ถูกต้อง",
		"usernameFormatter": "ชื่อผู้ใช้ต้องเป็นตัวอักษรและตัวเลขระหว่าง 6 ถึง 20 ตัวอักษร",
		"phoneFormatter": "กรุณาระบุหมายเลขโทรศัพท์มือถือ 10 หลัก",
		"msgsuccess": "สมัครสมาชิกสำเร็จ",
		"msgerr": "ไม่สามารถสมัครสมาชิกได้",
		"errorphonecheck": "หมายเลขโทรศัพท์มือถือไม่สามารถเว้นว่างได้!",
		"phonecheck": "กรุณาระบุหมายเลขโทรศัพท์มือถือ 10 หลัก!",
		"imgcodecheck": "OTP Code ไม่สามารถเว้นว่างได้!"
	},
	"login": {
		"Welcome_back": "ยินดีต้อนรับกลับมา",
		"tips": "ฉันได้อ่านและยอมรับ {{this.$LogoName}} เงื่อนไขและนโยบายความเป็นส่วนตัว",
		"tips_1": "ฉันได้อ่านและยอมรับ",
		"tips_2": "เงื่อนไขการให้บริการ",
		"tips_3": "และ",
		"tips_4": "นโยบายความเป็นส่วนตัว",
		"Continue_with_Google": "ดำเนินการต่อด้วย Google",
		"Continue_with_Apple": "ดำเนินการต่อด้วย Apple",
		"text": "ยังไม่มีบัญชี?",
		"text2": "มีบัญชีอยู่แล้วหรือยัง?",
		"Login": "เข้าสู่ระบบ",
		"Register": "ลงทะเบียน",
		"username": "ชื่อผู้ใช้หรือหมายเลขโทรศัพท์",
		"password": "รหัสผ่าน",
		"imgcode": "รหัสภาพ",
		"BackHome": "กลับไปที่หน้าหลัก",
		"Forgetpwd": "ลืมรหัสผ่าน"
	},
	"forgot": {
		"title": "ลืมรหัสผ่าน",
		"msgsuccess": "ตั้งค่ารหัสผ่านใหม่เรียบร้อยแล้ว",
		"newpassword": "รหัสผ่านใหม่",
		"newpasswordmsg": "กรุณากรอกรหัสผ่านใหม่",
		"Continue": "ต่อไป"
	},
	"home": {
		"Products&Services": "ผลิตภัณฑ์และบริการ",
		"content11": "ผู้เชิญสามารถรับโบนัสเทียบร้อยค่ามูลค่าการซื้อขายที่เกิดขึ้นจากผู้เชิญของพวกเขา โบนัสสูงสุดสำหรับการเชิญที่ถูกต้องคือ Unlimit USDT",
		"Revolutionize_Trading": "ปฏิวัติการซื้อขาย",
		"Shape_the_Future": "เป็นส่วนหนึ่งในการกำหนดแนวทางอนาคต",
		"content0": "มาสัมผัสประสบการณ์ใหม่ของการซื้อขายเงินดิจิทัลไปพร้อมกัน!",
		"3_Reasons_for_Choosing": "3 เหตุผลที่ควรเลือก",
		"Innovative_Trading_Features": "คุณสมบัติการซื้อขายนวัตกรรม",
		"content1": "ประสบการณ์การซื้อขายที่ปฏิวัติไปจากสิ่งที่มีอยู่ในตลาดในขณะนี้ ออกแบบเพื่อให้คุณมีความได้เปรียบกับคุณสมบัติการซื้อขายที่น่าสนใจ มาตรฐานความปลอดภัยที่ดี และความเร็วในการทำธุรกรรมอย่างรวดเร็ว",
		"Lower_Fees_and_Better_Rewards": "ค่าธรรมเนียมต่ำและรางวัลที่ดีกว่า",
		"content2": "อินเทอร์เฟซของเราทำให้การซื้อขายง่ายสำหรับผู้เริ่มต้น คุณสามารถสร้างบัญชีได้ฟรี และซื้อขายเหรียญ crypto โดยมีค่าธรรมเนียมการทำธุรกรรมต่ำจากตลาดของเรา นอกจากนี้ คุณยังสามารถแบ่งปันค่าธรรมเนียมการทำธุรกรรมระหว่าง 10% สำหรับผู้ใช้ที่คุณเชิญเข้าร่วม",
		"Institutional-grade_Security": "ความปลอดภัยในระดับสถาบัน",
		"content3": "ปฏิบัติตามมาตรฐานความปลอดภัยที่สูงที่สุดในวงการ และเราจัดเก็บ crypto ของคุณในรูปแบบ cold storage 100% เรารับประกันอัตราส่วนสำรองทุนของลูกค้าอย่างน้อย 1:1 กองทุนของเรา 300M USDT ให้ชั้นความปลอดภัยเพิ่มเติมต่อการขโมย",
		"Start_investing_in_as_little_as_5_minutes": "เริ่มลงทุนได้ในเวลาไม่เกิน 5 นาที",
		"content4": "ลงทะเบียนกับ {{this.$LogoNames.toUpperCase()}} ได้ฟรี",
		"content4_1": "ลงทะเบียนกับ",
		"content4_2": "ได้ฟรี",
		"Complete_google_authentication": "เสร็จสิ้นการรับรองตัวตนของ Google",
		"content5": "กรอกและยืนยันที่อยู่อีเมลของคุณเพื่อเริ่มต้น ไม่มีค่าธรรมเนียมในการเปิดบัญชี",
		"content7": "ปลดล็อคฟังก์ชันเต็มรูปแบบโดยการตรวจสอบ google authenticator ของคุณในเวลาไม่เกิน 5 นาที",
		"content8": "เติมเงินบัญชี {{this.$LogoNames.toUpperCase()}} ของคุณได้ทันที",
		"content8_1": "เติมเงินบัญชีของคุณทันทีกับ",
		"content8_2": "",
		"content9": "ฝาก USDT ผ่านเครือข่ายที่เลือกได้และเข้าถึงเงินของคุณได้ทันที ไม่ต้องรอ 3-5 วันเพื่อเริ่มซื้อขาย",
		"Start_building_your_crypto_portfolio": "เริ่มสร้างพอร์ตคริปโตของคุณ",
		"Invitation_Program": "โปรแกรมเชิญเพื่อน",
		"Referral_Program": "โปรแกรมอ้างอิง",
		"content10": "ทำการเชิญเพื่อนที่ถูกต้อง และรับโบนัสเชิญเฉลี่ยมูลค่า 10 USDT โบนัสสูงสุดสำหรับบุคคลเดียวคือ",
		"TRADING_TASKS": "งานซื้อขาย",
		"content12": "ผู้ใช้จะได้รับรางวัลใน USDT เมื่อทำการซื้อขาย มีงานซื้อขายหลากหลายสำหรับโหมดการซื้อขายต่างๆ รางวัลจะถูกจัดส่งทันที",
		"Trade_anytime_anywhere": "ซื้อขายได้ตลอดเวลาทุกที่",
		"content13": "บริษัท USA ได้รับอนุญาตให้เข้าสู่ธุรกิจสกุลเสมือนในระดับ Virtual Currency จาก New York State Department of Financial Services.",
		"USA_Inc": "USA, บริษัท",
		"content6": "ดาวน์โหลดแอปของเราเพื่อเชื่อมต่อตลอด 24 ชั่วโมง",
		"Legal&Disclosures": "กฎหมายและการเปิดเผยข้อมูล",
		"Regulatory_License": "ใบอนุญาตการกำกับดูแล",
		"AML/KYC_Policies": "นโยบาย AML/KYC",
		"Privacy_Policy": "นโยบายความเป็นส่วนตัว",
		"Terms_of_Use": "ข้อกำหนดการใช้งาน",
		"Legal_Statement": "คำแถลงการณ์ทางกฎหมาย",
		"Risk_Disclosure": "การเปิดเผยความเสี่ยง",
		"About_Us": "เกี่ยวกับเรา",
		"Protection_Fund": "กองทุนการป้องกัน",
		"Proof_of_Reserves": "การพิสูจน์เงินสำรอง",
		"Twitter": "ทวิตเตอร์",
		"Bitget_Token": "โทเค็น Bitget",
		"Careers": "อาชีพ",
		"Company": "บริษัท",
		"Support_Service": "บริการสนับสนุน",
		"title": "เปิดด้วยแอป",
		"titlebtn": "ดาวน์โหลด",
		"Balance": "ยอดเงินคงเหลือ",
		"ID": "รหัส",
		"Recharge": "เติมเงิน",
		"Withdraw": "ถอนเงิน",
		"Bonus": "โบนัส",
		"Checkin": "เช็คอิน",
		"Eventclosed": "เหตุการณ์ปิด",
		"Ranking": "อันดับ"
	},
	"Ranking": {
		"Today_s winning ranking": "อันดับการชนะรางวัลวันนี้",
		"tips1": "ยิ่งมีจำนวนเงินที่ชนะมาก = อันดับที่สูงขึ้น!",
		"UserName": "ชื่อผู้ใช้",
		"Winning Amount": "จำนวนเงินที่ชนะ",
		"Today_s rebate ranking": "อันดับการคืนเงินสดวันนี้",
		"tips2": "การคืนเงินสดที่สูงกว่าสำหรับผู้ใช้ระดับต่ำ = อันดับที่สูงขึ้น!",
		"Rebate Amount": "จำนวนเงินคืน",
		"Rebate ranking": "อันดับการคืนเงินสด 20 อันดับแรก"
	},
	"Checkin": {
		"Checkin": "ลงชื่อเข้าใช้",
		"CheckinList": "รายการลงชื่อเข้าใช้"
	},
	"AndarBahar": {
		"title": "เกม Andar Bahar",
		"Rule": "กฎเกม",
		"Record": "บันทึก",
		"Records": "บันทึกการเล่นเกม Andar Bahar",
		"MyOrder": "คำสั่งของฉัน",
		"more": "เพิ่มเติม",
		"Period": "รอบ",
		"Order": "คำสั่งซื้อ",
		"Buy": "ซื้อ",
		"Result": "ผล",
		"Profit": "กำไร",
		"AndarBaharRule": "กฎเกม Andar Bahar",
		"CountDown": "นับถอยหลัง",
		"Andar": "อันดาร์",
		"Bahar": "บาฮาร์",
		"Tie": "เสมอ",
		"result": "ผลลัพธ์",
		"ok": "ตกลง",
		"start": "เริ่ม",
		"Point": "จุด",
		"ContractPoint": "จุดสัญญา",
		"Number": "จำนวน",
		"Delivery": "ส่ง",
		"Fee": "ค่าธรรมเนียม",
		"Amount": "จำนวนเงิน"
	},
	"pokerOrder": {
		"OrderRecord": "บันทึกการสั่งซื้อ",
		"WatingOrder": "การสั่งซื้อที่รอดำเนินการ",
		"HistoryOrder": "ประวัติการสั่งซื้อ",
		"Nomoredata": "ไม่มีข้อมูลเพิ่มเติม",
		"Buy": "ซื้อ",
		"Result": "ผลลัพธ์",
		"Time": "เวลา",
		"Amount": "จำนวน",
		"Fee": "ค่าธรรมเนียม",
		"Profit": "กำไร"
	},
	"pokerRecord": {
		"title": "Andar Bahar Record",
		"Period": "รอบ",
		"Result": "ผล",
		"OpenTime": "เวลาเปิด",
		"Nomore": "ไม่มีข้อมูลเพิ่มเติม"
	},
	"Google2FA": {
		"title": "Google Authenticator",
		"Authenticator_Instructions": "คำแนะนำในการใช้ Google Authenticator",
		"Authenticator_Status": "สถานะของ Google Authenticator",
		"Google_Authenticator": "Google Authenticator",
		"Download_Google_Authenticator_App": "ดาวน์โหลดแอป Google Authenticator",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "ถัดไป",
		"Backup_key": "คีย์การสำรอง",
		"Backup_key_text": "โปรดบันทึกคีย์การสำรอง 16 หลักนี้ไว้ในที่ปลอดภัย คีย์นี้จะช่วยคุณกู้คืน Authenticator ของคุณหากคุณสูญเสียโทรศัพท์ มิฉะนั้น การรีเซ็ต Authenticator ของคุณต้องขอความช่วยเหลือจากฝ่ายบริการลูกค้า",
		"Verifiaction": "การยืนยัน",
		"Verifiaction_text": "กรอกรหัสยืนยันของ Google",
		"Google_verification_code": "รหัสยืนยันของ Google",
		"contentlist1": "1.บันทึกคีย์การสำรอง 16 หลักจาก +this.$LogoName+ และเก็บไว้ในที่ปลอดภัย",
		"contentlist1_1": "1.บันทึกคีย์การสำรอง 16 หลักจาก",
		"contentlist1_2": "และเก็บไว้ในที่ปลอดภัย",
		"contentlist2": "2. เปิด Google Authenticator",
		"contentlist3": "3. เพิ่มบัญชี +this.$LogoName+ และใส่ 16 หลักที่คัดลอกไว้",
		"contentlist3_1": "3. เพิ่มบัญชี",
		"contentlist3_2": "และใส่ 16 หลักที่คัดลอกไว้",
		"contentlist4": "4. จะปรากฏรหัสยืนยัน 6 หลักบนหน้าจอ",
		"contentlist5": "5. คัดลอกรหัสยืนยัน 6 หลักแล้ววางไว้ใน +this.$LogoName+",
		"contentlist5_1": "5. คัดลอกรหัสยืนยัน 6 หลักแล้ววางไว้ใน",
		"contentlist5_2": "",
		"contentlist6": "6. จากนั้น Authenticator ของคุณก็จะเชื่อมโยงสำเร็จแล้ว"
	},
	"win": {
		"Live_Price": "ราคาสด",
		"title1": "ผลลัพธ์การลงทุนคำนวณจากตัวเลขสุดท้ายของ Binance BTC/USDT Spot Trade ที่สอดคล้องกับเวลา UTC นักลงทุนสามารถตรวจสอบผลลัพธ์ได้ที่ Binance เมื่อใดก็ได้เพื่อให้การลงทุนเป็นฟองที่มั่นคงและปลอดภัย",
		"title": "การลงทุนมี 2 โหมด",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "รอบละ 1 นาที โหมด Lite มีเพียงการลงทุนเลขคู่และเลขคี่เท่านั้น ผลลัพธ์คำนวณจากตัวเลขสุดท้ายของ Binance BTC/USDT Spot trade ช่วง 1 นาที",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "รอบละ 5 นาที โหมด Pro ปลดล็อกวิธีการลงทุนเต็มรูปแบบของ EPOCH EPT ผลลัพธ์คำนวณจากตัวเลขสุดท้ายของ Binance BTC/USDT Spot trade ช่วง 5 นาที",
		"5M_Mode_text_1": "รอบละ 5 นาที โหมด Pro ปลดล็อกวิธีการลงทุนเต็มรูปแบบของ ",
		"5M_Mode_text_2": " ผลลัพธ์คำนวณจากตัวเลขสุดท้ายของ Binance BTC/USDT Spot trade ช่วง 5 นาที",
		"Investment_Guide": "คู่มือการลงทุน",
		"Investment_Rules": "กฎการลงทุน",
		"title2": "การพิสูจน์ผลลัพธ์ที่ถูกต้อง",
		"Investment_Info": "ข้อมูลการลงทุน",
		"3M_Mode": "โหมด 3M",
		"3M_Mode_text": "รอบละ 3 นาที ผลลัพธ์คำนวณจากตัวเลขสุดท้ายของ Binance BTC/USDT Spot trade ช่วง 3 นาที",
		"Investment_return": "ผลตอบแทนจากการลงทุน",
		"Number": "เลข",
		"Profit_Invested_amount": "กำไร: จำนวนเงินที่ลงทุน",
		"guide1": "เลือกโหมดเวลา",
		"guide2": "ปรับจำนวนเงินลงทุนของคุณ",
		"guide3": "เลือกตัวเลขที่คุณต้องการลงทุน",
		"guide4": "ตรวจสอบ Portfolios ของคุณและคลิก 'ลงทุน'",
		"NEXT": "ต่อไป",
		"Invest_Amount": "จำนวนเงินลงทุน",
		"Total": "รวม",
		"INVEST": "ลงทุน",
		"Portfolio": "Portfolios",
		"Invest": "ลงทุน",
		"BINANCE": "Binance",
		"Bitcoin_Price": "ราคาบิตคอยน์",
		"InvestmentRecord": "บันทึกการลงทุน",
		"Best_Assistant": "ผู้ช่วยการลงทุน",
		"last_100_rounds": "(100 รอบล่าสุด)",
		"What_do_these_data_mean": "ความหมายของข้อมูลเหล่านี้",
		"Missing": "ไม่มี",
		"Missing_text": "หมายถึงจำนวนรอบตั้งแต่รอบล่าสุดที่สร้างตัวเลขนี้",
		"Frequency": "ความถี่",
		"Frequency_text": "หมายถึงจำนวนครั้งของตัวเลขที่เกิดขึ้นใน 100 รอบล่าสุด",
		"Max_Continued": "ความต่อเนื่องสูงสุด",
		"Max_Continued_text": "หมายถึงระยะเวลาต่อเนื่องสูงสุดของตัวเลขที่เกิดขึ้นใน 100 รอบล่าสุด",
		"Rule": "กฎ",
		"Trend": "แนวโน้ม",
		"MyBalance": "ยอดคงเหลือของฉัน",
		"JoinGreen": "เข้าร่วมสีเขียว",
		"JoinRed": "เข้าร่วมสีแดง",
		"JoinViolet": "เข้าร่วมสีม่วง",
		"Parity": "ตัวเลขคู่-คี่",
		"Sapre": "ตัวเลขมาก-น้อย",
		"Bcone": "ตัวเลข 1-7",
		"Emerd": "ตัวเลข 8-14",
		"Record": "บันทึก",
		"more": "ดูเพิ่มเติม",
		"Period": "รอบ",
		"Price": "ราคา",
		"Result": "ผลลัพธ์",
		"My": "ของฉัน",
		"Order": "คำสั่งซื้อ",
		"Buy": "ซื้อ",
		"Profit": "กำไร",
		"Green": "สีเขียว",
		"Red": "สีแดง",
		"Violet": "สีม่วง",
		"tips": "ค่าธรรมเนียมการทำธุรกรรมคือ {bet_fee*100}% ของจำนวนคำสั่งซื้อ",
		"tips1": "ค่าธรรมเนียมการทำธุรกรรมคือ ",
		"tips2": "ของจำนวนคำสั่งซื้อ",
		"OrderAmount": "จำนวนคำสั่งซื้อ",
		"Quantity": "ปริมาณ",
		"TransactionFee": "ค่าธรรมเนียมการทำธุรกรรม",
		"TotalAmount": "จำนวนเงินทั้งหมด",
		"PresalePolicy": "นโยบายความเป็นส่วนตัว",
		"Iagree": "ฉันยินยอม",
		"err1": "กรุณายินยอมตามกฎการจัดการการขายล่วงหน้าก่อน"
	},
	"historyPeriods": {
		"Orderrecord": "บันทึกการสั่งซื้อ",
		"record": "บันทึก"
	},
	"historyOrderPeriods": {
		"WatingOrder": "คำสั่งที่รอดำเนินการ",
		"HistoryOrder": "คำสั่งย้อนหลัง"
	},
	"Activity": {
		"added_to_account_balance": "เพิ่มเข้าสู่ยอดเงินในบัญชี",
		"contentText": "เมื่อผู้ถูกเชิญทำการฝากเงินครั้งแรก จะนับเป็นการเชิญที่ถูกต้อง ผู้เชิญจะได้รับโบนัสการเชิญสำหรับการเชิญที่ถูกต้องทุกครั้ง โบนัสการเชิญแบ่งเป็นระดับ ตั้งแต่ {{aaaaaa}}USDT สำหรับการเชิญที่ถูกต้อง 1 ครั้ง ถึง {{aaaaaa}}USDT สำหรับการเชิญที่ถูกต้อง 500 ครั้ง",
		"contentText_1": "เมื่อผู้ถูกเชิญทำการฝากเงินครั้งแรก จะนับเป็นการเชิญที่ถูกต้อง ผู้เชิญจะได้รับโบนัสการเชิญสำหรับการเชิญที่ถูกต้องทุกครั้ง โบนัสการเชิญแบ่งเป็นระดับ ตั้งแต่",
		"contentText_2": "สำหรับการเชิญที่ถูกต้อง 1 ครั้ง ถึง ",
		"contentText_3": "สำหรับการเชิญที่ถูกต้อง 500 ครั้ง",
		"contentText_3_1": "สำหรับ ",
		"contentText_3_2": "การเชิญที่ถูกต้อง",
		"contentText2": "ผู้เชิญจะได้แบ่งปันค่าธรรมเนียมการซื้อขาย {{LV.1%}} จากปริมาณการซื้อขายรายตัวของผู้เชิญระดับ LV.1 และแบ่งปันค่าธรรมเนียมการซื้อขาย {{LV.2%}} จากปริมาณการซื้อขายรายตัวของผู้เชิญระดับ LV.2 สูงสุดของโบนัสการซื้อขายในแต่ละรอบขึ้นอยู่กับจำนวนไม่ จำกัด ในหนึ่งวัน",
		"contentText2_1": "ผู้เชิญจะได้แบ่งปันค่าธรรมเนียมการซื้อขาย",
		"contentText2_2": "จากปริมาณการซื้อขายรายตัวของผู้เชิญระดับ LV.1 และแบ่งปันค่าธรรมเนียมการซื้อขาย",
		"contentText2_3": "จากปริมาณการซื้อขายรายตัวของผู้เชิญระดับ LV.2 สูงสุดของโบนัสการซื้อขายในแต่ละรอบขึ้นอยู่กับจำนวนไม่ จำกัด ในหนึ่งวัน.",
		"My_Rewards_History": "ประวัติของรางวัลของฉัน",
		"Cumulative_Single_Number_Trading_Volumn": "ปริมาณการซื้อขายตัวเลขเดี่ยวสะสม",
		"Cumulative_ODD_Number_Trading_Volumn": "ปริมาณการซื้อขายตัวเลขคี่สะสม",
		"Cumulative_EVEN_Number_Trading_Volumn": "ปริมาณการซื้อขายตัวเลขคู่สะสม",
		"Cumulative_BIG_Number_Trading_Volumn": "ปริมาณการซื้อขายตัวเลขมากสะสม",
		"Cumulative_SMALL_Number_Trading_Volumn": "ปริมาณการซื้อขายตัวเลขน้อยสะสม",
		"Advanced_Tasks": "งานขั้นสูง",
		"Invite_Friends_and_Earn_Up_to": "ชวนเพื่อนและรับรางวัลได้สูงสุด",
		"Referral_Code": "รหัสอ้างอิง",
		"Referral_Link": "ลิงก์อ้างอิง",
		"INVITE_FRIENDS": "ชวนเพื่อน",
		"Complete_tasks_to_earn_up_to": "ทำงานเพื่อรับรางวัลได้สูงสุด",
		"MY_REWARDS": "รางวัลของฉัน",
		"SHARE": "แชร์",
		"Earn_with_your_friends_together": "ร่วมกันหารายได้กับเพื่อนของคุณ",
		"Get_your_link": "รับลิงก์ของคุณ",
		"Claim_your_rewards": "รับรางวัลของคุณ",
		"Earn_together": "ร่วมกันหารายได้",
		"contentlist0": "เข้าร่วม {{gogobit}} และรับลิงก์อ้างอิงของคุณ ผู้ใช้ที่ลงทะเบียนผ่านลิงก์นี้จะได้รับรางวัล",
		"contentlist0_1": "เข้าร่วม",
		"contentlist0_2": " และรับลิงก์อ้างอิงของคุณ ผู้ใช้ที่ลงทะเบียนผ่านลิงก์นี้จะได้รับรางวัล",
		"contentlist1": "คุณจะได้รับรางวัลการอ้างอิงค่าเฉลี่ยมูลค่า {{XX}} USDT เมื่อเพื่อนของคุณฝากเงินครั้งแรกและทำปริมาณการซื้อขายที่จำเป็น รางวัลสามารถได้รับได้สูงสุดถึง {{XX}} USDT",
		"contentlist1_1": "คุณจะได้รับรางวัลการอ้างอิงค่าเฉลี่ยมูลค่า ",
		"contentlist1_2": " USDT เมื่อเพื่อนของคุณฝากเงินครั้งแรกและทำปริมาณการซื้อขายที่จำเป็น รางวัลสามารถได้รับได้สูงสุดถึง ",
		"contentlist1_3": " USDT",
		"contentlist2": "หลังจากเพื่อนของคุณสร้างบัญชีแล้ว เขา/เธอสามารถขอรับโบนัสการซื้อขายได้สูงสุดถึง 5,050 USDT",
		"Referral_Rewards": "รางวัลการแนะนำ",
		"Detail_Rules": "กฎเกณฑ์อย่างละเอียด",
		"Invitation_Bonus": "โบนัสการเชิญ",
		"Up_to": "สูงสุดถึง",
		"content0": "ทำการเชิญสมาชิกใหม่ใดๆ และรับโบนัสการเชิญโดยมีมูลค่าเฉลี่ย 10 USDT โบนัสสูงสุดสำหรับ 100 คน คือ",
		"content0_1": "ทำการเชิญสมาชิกใหม่ใดๆ และรับโบนัสการเชิญโดยมีมูลค่าเฉลี่ย",
		"content0_2": "โบนัสสูงสุดสำหรับ 100 คน คือ",
		"Trading_Bonus": "โบนัสการซื้อขาย",
		"content1": "ผู้เชิญสามารถรับโบนัสแบบสัมพันธ์กับปริมาณการซื้อขายที่เกิดขึ้นจากผู้ถูกเชิญ โบนัสสูงสุดสำหรับการเชิญที่ถูกต้องคือ",
		"My_Referral_History": "ประวัติการแนะนำของฉัน",
		"Total_Invitees": "จำนวนเชิญทั้งหมด",
		"Valid_Invitees": "จำนวนเชิญที่ถูกต้อง",
		"Cumulative_Invitees": "ผู้ถูกเชิญ",
		"Trading_Volumn": "ปริมาณการซื้อขาย",
		"Daily_Tasks": "งานประจำวัน",
		"Detail_Rules_title": "แจกโชค รางวัลลุ้นโชค 888 USDT ทุกวัน",
		"Detail_Rules_title1": "1. ข้อมูลการซื้อขายสินค้าฟิวเจอร์ของวันนั้น จะถูกคำนวณในวันถัดไป",
		"Detail_Rules_title2": "2. ผู้ใช้ที่ทำซื้อขายสินค้าฟิวเจอร์มูลค่า 200,000 USDT ขึ้นไปในวันนั้น จะได้รับสิทธิ์ลุ้นรางวัลลุ้นโชค 1 ครั้ง เพื่อรับรางวัล 888 USDT",
		"CLAIM_NOW": "เรียกรับตอนนี้",
		"INVEST_NOW": "ลงทุนตอนนี้",
		"title": "กระเป๋าโบนัส",
		"BonusBalance": "ยอดคงเหลือโบนัส",
		"Withdrawtobalance": "ถอนเข้ากระเป๋าเงิน",
		"BonusList": "รายการโบนัส",
		"Bonuswithdrawtobalance": "ถอนโบนัสเข้ากระเป๋าเงิน",
		"WithdrawalAmount": "จำนวนที่ถอน",
		"Pleaseentertheamount": "โปรดกรอกจำนวนเงิน",
		"err1": "จำนวนการถอนไม่สามารถเป็น 0 ได้",
		"success": "โอนเงินสำเร็จ"
	},
	"Refer": {
		"title": "ผู้อ้างอิงของฉัน",
		"ReferTotalBonus": "รางวัลแนะนำรวม",
		"InviteFriends": "เชิญเพื่อน",
		"Level1": "ระดับ 1",
		"Level2": "ระดับ 2",
		"TotalPeople": "จำนวนทั้งหมด",
		"Bonus": "โบนัส",
		"Myinvitationlink": "ลิงก์เชิญของฉัน",
		"Rebate_description": "คำอธิบายการคืนเงิน"
	},
	"Task": {
		"InviteBonusToday": "โบนัสการเชิญวันนี้",
		"bonusrecord": "บันทึกโบนัส",
		"hint1": "1. ข้อมูลอัพเดททุก 10-30 นาที!",
		"hint2": "2. เฉพาะผู้ที่ลงทะเบียนในช่วง 3 วันที่ผ่านมาและเติมเงินวันนี้เท่านั้นที่นับได้!",
		"hint2_1": "2. เฉพาะผู้ที่ลงทะเบียนในช่วง ",
		"hint2_2": " วันที่ผ่านมาและเติมเงินวันนี้เท่านั้นที่นับได้!",
		"hint3": "3. ต้องมีการฝากเงินขั้นต่ำ 20u วันนี้!",
		"hint3_1": "3. ต้องมีการฝากเงินขั้นต่ำ ",
		"hint3_2": " วันนี้!",
		"hint4": "จำนวนคนที่เชิญในช่วง 3 วันที่ผ่านมา",
		"hint4_1": "จำนวนคนที่เชิญในช่วง ",
		"hint4_2": " วันที่ผ่านมา",
		"hint5": "จำนวนคนที่เติมเงินวันนี้",
		"Invite": "เชิญชวน",
		"friendstorecharge": "เพื่อนในการเติมเงิน",
		"Earn": "ได้รับ",
		"Completed": "เติมเงินเรียบร้อยแล้ว",
		"ExpireTime": "เวลาหมดอายุ"
	},
	"Invited": {
		"title": "บันทึกโบนัส",
		"InviteTotalBonus": "โบนัสรวมจากการเชิญ",
		"InviteTime": "เวลาที่เชิญ",
		"Bonus": "โบนัส",
		"NoRecored": "ไม่มีบันทึก"
	},
	"mine": {
		"ProvacyPolicy": "นโยบายความเป็นส่วนตัว",
		"JOIN US": "ร่วมเป็นส่วนหนึ่งของเรา",
		"title": "ศูนย์ส่วนบุคคล",
		"InviteCode": "รหัสเชิญชวน",
		"AccountBalance": "ยอดเงินในบัญชี",
		"BonusBalance": "ยอดเงินโบนัส",
		"Wallet": "กระเป๋าเงิน",
		"Bank": "บัญชีธนาคาร",
		"Invite": "เชิญชวน",
		"Refer": "แนะนำ",
		"CustomerService": "บริการลูกค้า",
		"ComplaintsSuggestions": "ร้องเรียนและแนะนำ",
		"ChangePassword": "เปลี่ยนรหัสผ่าน",
		"Activity": "กิจกรรม",
		"PresalePolicy": "นโยบายความเป็นส่วนตัว",
		"DownloadApp": "ดาวน์โหลดแอปพลิเคชัน",
		"AppVersion": "เวอร์ชันแอปพลิเคชัน",
		"Logout": "ออกจากระบบ",
		"Logouttips": "คุณแน่ใจหรือไม่ว่าต้องการออกจากระบบ?"
	},
	"App": {
		"title": "ดาวน์โหลดแอปพลิเคชัน",
		"DownloadforAndroid": "ดาวน์โหลดสำหรับ Android",
		"DownloadforiOS": "ดาวน์โหลดสำหรับ iOS"
	},
	"Presale": {
		"title": "นโยบายความเป็นส่วนตัว"
	},
	"Account": {
		"title2": "ตั้งรหัสผ่านใหม่",
		"title2_text": "ตั้งรหัสผ่านใหม่สำหรับบัญชี Google ของคุณ คุณสามารถเข้าสู่ระบบบัญชี Google โดยใช้รหัสผ่าน",
		"title_text": "คุณกำลังพยายามรีเซ็ตรหัสผ่านของคุณ โปรดเตรียม Google Authenticator ของคุณสำหรับการรีเซ็ต",
		"err1": "รหัสผ่านใหม่สองตัวที่ป้อนไม่เหมือนกัน",
		"success2": "เปลี่ยนรหัสผ่านเรียบร้อยแล้ว",
		"title": "เปลี่ยนรหัสผ่าน",
		"OldPassword": "รหัสผ่านเดิม",
		"OldPasswordmsg": "ต้องใส่รหัสผ่านเดิม",
		"NewPassword": "รหัสผ่านใหม่",
		"NewPasswordmsg": "ต้องใส่รหัสผ่านใหม่",
		"RetypeNewPassword": "ยืนยันรหัสผ่านใหม่อีกครั้ง",
		"RetypeNewPasswordmsg": "ต้องใส่รหัสผ่านใหม่อีกครั้ง",
		"Continue": "ดำเนินการต่อ"
	},
	"Service": {
		"title": "บริการ",
		"CONTACTUS": "ติดต่อเรา",
		"tips": "บริการลูกค้าพักการให้บริการชั่วคราว !",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "ลิงก์"
	},
	"Bank": {
		"BankName": "ชื่อธนาคาร",
		"ChooseaCurrencyType": "เลือกประเภทสกุลเงิน",
		"SelectWithdrawalMethod": "เลือกวิธีถอนเงิน",
		"Newbankcard": "บัตรธนาคารใหม่",
		"Editbankcard": "แก้ไขบัตรธนาคาร",
		"title": "ธนาคารของฉัน",
		"Cannotbeempty": "ไม่สามารถเว้นว่างได้",
		"BankHolderName": "ชื่อเจ้าของบัญชีธนาคาร",
		"BankAccountNumber": "หมายเลขบัญชีธนาคาร",
		"IFSCCode": "รหัส IFSC",
		"Email": "อีเมล",
		"Phone": "โทรศัพท์",
		"OTPCode": "รหัส OTP",
		"tips1": "โปรดใช้หมายเลขโทรศัพท์มือถือที่คุณได้ลงทะเบียนเพื่อรับรหัส OTP",
		"tips2": "เพื่อความปลอดภัยของเงินทุน บัตรธนาคารสามารถแก้ไขได้เพียงครั้งเดียวต่อวัน",
		"Add": "เพิ่ม",
		"error1": "ชื่อเจ้าของบัญชีธนาคารต้องไม่เว้นว่าง!",
		"error2": "โปรดป้อนชื่อเจ้าของบัญชีธนาคารที่ถูกต้อง!",
		"error3": "ชื่อธนาคารต้องไม่เว้นว่าง!",
		"error4": "โปรดป้อนชื่อธนาคารที่ถูกต้อง!",
		"error5": "หมายเลขบัญชีธนาคารต้องไม่เว้นว่าง!",
		"error6": "โปรดป้อนหมายเลขบัญชีธนาคารที่ถูกต้อง!",
		"error7": "รหัส IFSC ต้องไม่เว้นว่าง!",
		"error8": "โปรดป้อนรหัส IFSC ที่ถูกต้อง!",
		"error9": "อีเมลต้องไม่เว้นว่าง!",
		"error10": "โปรดป้อนที่อยู่อีเมลที่ถูกต้อง!",
		"error11": "หมายเลขโทรศัพท์ต้องไม่เว้นว่าง!",
		"error12": "รหัส OTP ต้องไม่ว่างเปล่า!",
		"Successfully": "เพิ่มบัตรธนาคารสำเร็จแล้ว"
	},
	"wallet": {
		"title": "กระเป๋าเงินของฉัน",
		"MyBalance": "ยอดเงินคงเหลือของฉัน",
		"MyBank": "ธนาคารของฉัน",
		"RechargeRecords": "บันทึกการเติมเงิน",
		"WithdrawalRecords": "บันทึกการถอนเงิน"
	},
	"Rechargepage": {
		"Tips": "ทิป: รางวัลการเติมเงินครั้งแรกคือ {{firstRPR*100}}% ของยอดเงินเติมเงิน",
		"Tips_1": "ทิป: รางวัลการเติมเงินครั้งแรกคือ ",
		"Tips_2": "% ของยอดเงินเติมเงิน",
		"Reminder": "เตือน",
		"Reminder_1": "1. โปรดอย่าฝากสกุลเงินอื่นๆนอกจาก USDT ที่ที่อยู่นี้ การส่งเหรียญหรือโทเค็นที่ไม่รองรับไปยังที่อยู่นี้อาจทำให้เสียหายอย่างไม่สามารถกู้คืนได้",
		"Reminder_2": "2. จำนวนเงินฝากขั้นต่ำคือ 5 USDT",
		"Reminder_2_1": "2. จำนวนเงินฝากขั้นต่ำคือ ",
		"Reminder_2_2": " USDT",
		"Reminder_3": "3. เงินฝากจะมาถึงหลังจากการยืนยันแบบเครือข่าย 15 รอบ",
		"Minimum": "ขั้นต่ำ",
		"Recharge": "เติมเงิน",
		"title": "เติมเงิน",
		"History": "ประวัติการเติมเงิน",
		"Mybalance": "ยอดเงินคงเหลือของฉัน",
		"RechargeAmount": "จำนวนเงินที่ต้องการเติม",
		"RechargeAmountmsg": "โปรดกรอกจำนวนเงิน",
		"tips1": "ช่องทางการชำระเงินอยู่ระหว่างการบำรุงรักษา!",
		"tips2": "โปรดเลือกช่องทางการชำระเงิน",
		"PayNow": "ชำระเงินตอนนี้",
		"err1": "ช่องทางการชำระเงินอยู่ระหว่างการบำรุงรักษา",
		"err2": "จำนวนเงินต้องไม่น้อยกว่า",
		"err3": "โปรดเลือกช่องทางการชำระเงิน",
		"Rechargemethod": "วิธีการเติมเงิน"
	},
	"Rechargerecord": {
		"title": "บันทึกการเติมเงิน",
		"WatingOrder": "คำสั่งที่รอดำเนินการ",
		"HistoryOrder": "ประวัติการสั่งซื้อ",
		"Rupee": "ดอลลาร์",
		"Status": "สถานะ",
		"Time": "เวลา"
	},
	"withdrawal": {
		"Google_Code": "รหัส Google",
		"Tips": "เคล็ดลับ",
		"Tips_1": "เคล็ดลับ: ค่าธรรมเนียมถอนสำหรับจำนวนต่ำกว่า",
		"Tips_2": " USDT คือ ",
		"Tips_3": " USDT, ค่าธรรมเนียมถอนสำหรับจำนวน ",
		"Tips_4": "USDT หรือมากกว่านั้นคือ",
		"Tips_5": "%",
		"WalletAddress": "ที่อยู่กระเป๋าเงิน",
		"Addwalletaddress": "เพิ่มที่อยู่กระเป๋าเงิน",
		"Network_fee": "ค่าธรรมเนียมเครือข่าย",
		"Receive_amount": "จำนวนที่ได้รับ",
		"Select Chains": "เลือกเชือก",
		"Bankcard": "บัตรธนาคาร",
		"Nobankcardselected": "ไม่มีการเพิ่มบัตรธนาคาร",
		"Withdrawalfee": "ค่าธรรมเนียมการถอน",
		"title": "ถอน",
		"Mybalance": "ยอดคงเหลือของฉัน",
		"Withdrawablebalance": "ยอดคงเหลือที่ถอนได้",
		"tips1": "จำนวนเงินที่ซื้อต้องมากกว่า {{codeAmount}} สามารถถอนได้ทั้งหมด",
		"tips1-1": "จำนวนเงินที่ซื้อต้องมากกว่า",
		"tips1-2": "สามารถถอนได้ทั้งหมด",
		"tips2": "สามารถถอนได้ {{draw_num}} ครั้งต่อวัน",
		"tips2-1": "สามารถถอนได้",
		"tips2-2": "ครั้งต่อวัน",
		"tips3": "จำนวนเงินถอนสูงสุดต่อวันคือ",
		"tips4": "จำนวนถอนขั้นต่ำคือ ₹{{draw_min_money}} และจำนวนถอนสูงสุดคือ ₹{{draw_max_money}}",
		"tips4-1": "จำนวนถอนขั้นต่ำคือ",
		"tips4-2": "และจำนวนถอนสูงสุดคือ",
		"tips5": "เวลาถอนเงินคือ {{draw_time}} โปรดถอนเงินในเวลาที่กำหนด",
		"tips5-1": "เวลาถอนเงินคือ",
		"tips5-2": "โปรดถอนเงินในเวลาที่กำหนด",
		"tips6": "การถอนเงินสดจะหักค่าธรรมเนียมถอนเงิน {{draw_fee}}%",
		"tips6-1": "การถอนเงินสดจะหักค่าธรรมเนียมถอนเงิน",
		"tips6-2": "%",
		"tips7": "หากยอดถอนของคุณน้อยกว่าหรือเท่ากับ ₹{{solid_min_amount}} จะมีค่าธรรมเนียมคงที่ ₹{{solid_feemoney}}",
		"tips7-1": "หากยอดถอนของคุณน้อยกว่าหรือเท่ากับ",
		"tips7-2": "จะมีค่าธรรมเนียมคงที่",
		"tips7-3": "₹",
		"WithdrawalAmount": "จำนวนเงินที่ถอน",
		"WithdrawalAmountmsg": "โปรดป้อนจำนวนเงิน",
		"Withdrawal": "ถอน",
		"WithdrawalHistory": "ประวัติการถอน",
		"Tipsmsg": "การยื่นขอถอนเงินสำเร็จ รอการตรวจสอบผล!",
		"dangerMsg1": "โปรดเพิ่มบัตรธนาคารของคุณก่อน",
		"dangerMsg2": "จำนวนเงินถอนไม่สามารถน้อยกว่า",
		"dangerMsg3": "จำนวนเงินถอนต้องเป็นเลขคู่ของ 100",
		"dangerMsg4": "จำนวนเงินถอนไม่สามารถมากกว่า",
		"dangerMsg5": "จำนวนเงินถอนไม่สามารถมากกว่า ยอดเงินที่สามารถถอนได้",
		"dangerMsg6": "โปรดเพิ่มบัตรธนาคารของคุณก่อน"
	},
	"withdrawalrecord": {
		"Title": "บันทึกการถอน",
		"WatingOrder": "รอการยืนยัน",
		"HistoryOrder": "ประวัติการถอน",
		"Rupee": "ดอลลาร์",
		"Status": "สถานะ",
		"Time": "เวลา",
		"Nomore": "ไม่มีเพิ่มเติม!"
	},
	"Version": {
		"title": "เวอร์ชัน",
		"stableversion": "เวอร์ชันที่เสถียร",
		"Alreadythelatestversion": "เป็นเวอร์ชันล่าสุดแล้ว",
		"Newversionfound": "พบเวอร์ชันใหม่",
		"DownloadUpdate": "ดาวน์โหลดอัปเดต"
	},
	"Bonus": {
		"title": "กิจกรรมโบนัส",
		"ReceiveBonus": "รับโบนัส",
		"Hi": "สวัสดี",
		"tips1": "ยินดีด้วย! คุณได้รับโบนัสแล้ว",
		"tips2": "คุณต้องฝากเงินมากกว่าหรือเท่ากับ",
		"tips3": "จากนั้นโบนัสจะถูกเพิ่มโดยอัตโนมัติในยอดเงินคงเหลือของบัญชีของคุณ",
		"Received": "ได้รับแล้ว",
		"Back": "ย้อนกลับ",
		"Rechargenow": "ฝากเงินตอนนี้"
	},
	"ComplaintsSuggestions": {
		"title": "ร้องเรียนและข้อเสนอแนะ",
		"Answered": "ตอบแล้ว",
		"AddNew": "เพิ่มรายการ",
		"Time": "เวลา",
		"Type": "ประเภท",
		"WhatsApp": "WhatsApp",
		"Description": "ลักษณะ",
		"Remark": "หมายเหตุ",
		"Waiting": "รอ",
		"Suggestion": "ข้อเสนอแนะ",
		"Consult": "สอบถาม",
		"RechargeProblem": "ปัญหาการเติมเงิน",
		"WithdrawProblem": "ปัญหาการถอนเงิน",
		"OrderProblem": "ปัญหาการสั่งซื้อ",
		"Other": "อื่น ๆ",
		"Describeyourproblems": "อธิบายปัญหาของคุณ",
		"WhatsApptocontactyou": "WhatsApp เพื่อติดต่อคุณ",
		"submit": "เสนอ",
		"err1": "WhatsApp ต้องไม่ว่างเปล่า!",
		"err2": "ประเภทต้องไม่ว่างเปล่า!",
		"err3": "คำอธิบายต้องไม่ว่างเปล่า!"
	},
	"slotMachine": {
		"title": "เครื่องสล็อตผลไม้",
		"Myblance": "ยอดเงินของฉัน",
		"Records": "บันทึกเครื่องสล็อตผลไม้",
		"titleRecord": "บันทึก"
	},
	"bankrecharge": {
		"Orderamount": "จำนวนการสั่งซื้อ",
		"Ordernumber": "หมายเลขการสั่งซื้อ",
		"BankName": "ชื่อธนาคาร",
		"BankAccount": "หมายเลขบัญชีธนาคาร",
		"IFSC": "รหัส IFSC",
		"Yourbankcardnumber": "หมายเลขบัตรธนาคารของคุณ",
		"Enterthebanktransactionordernumber": "กรอกหมายเลขออเดอร์ธนาคาร",
		"submit": "ส่ง",
		"Tips": "เคล็ดลับ",
		"tips1": "1.ระบบกำลังดำเนินการในการสั่งซื้อของคุณ",
		"tips2": "2.โปรดติดต่อฝ่ายบริการลูกค้าหากยังไม่ได้รับเงินมานาน",
		"err1": "กรุณากรอกหมายเลขบัตรธนาคารของคุณ",
		"err2": "กรุณากรอกหมายเลขออเดอร์ธนาคาร"
	},
	"javaCode": {
		"200": "สำเร็จ",
		"20001": "invalid access token",
		"20002": "invalid login token",
		"20003": "Phone error",
		"20004": "Phone has exists!",
		"20005": "Email error!",
		"20006": "Email exists!",
		"20007": "Password error!",
		"20010": "account or pwd error!",
		"20011": "bank account error!",
		"20012": "err bank ifsc, (standard IFSC format) - length 11, first four IFSC and 5th 0!",
		"20013": "beneficiary name error, 5 < name <= 50!",
		"20021": "Account not exists!",
		"20022": "Account is freeze!",
		"20031": "The layer is limit 10 !",
		"20032": "update relation role error !",
		"20033": "not enough balance!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Register Error!",
		"200082": "account has disable!",
		"200091": "add card is limit 10!",
		"200092": "input error login pwd too many times, and the account is disable!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "ข้อผิดพลาดของเซิร์ฟเวอร์!",
		"Handledataerror": "กรุณาเข้าสู่ระบบ!",
		"-91": "ข้อผิดพลาดของเซิร์ฟเวอร์!",
		"-92": "ข้อผิดพลาดในการดำเนินการ!",
		"-1": "ข้อผิดพลาดของระบบ!",
		"-2": "พารามิเตอร์ผิดพลาด",
		"-3": "ไม่มีข้อมูล",
		"-4": "การดำเนินการบ่อยครั้งในช่วงเวลาสั้นๆ",
		"-5": "ระบบไม่สามารถทำงานได้ในขณะนี้ โปรดลองอีกครั้งในภายหลัง!",
		"-6": "มีผู้คนมากเกินไปในขณะนี้ โปรดลองใหม่อีกครั้ง!",
		"-7": "ข้อผิดพลาดของข้อมูล!",
		"-8": "รหัส Google ผิดพลาด!",
		"-9": "ระบบกำลังปรับปรุง! โปรดลองอีกครั้งในภายหลัง!",
		"-10": "การบันทึกข้อมูลผิดพลาด!",
		"-11": "การลบข้อมูลผิดพลาด!",
		"-12": "การปรับปรุงข้อมูลผิดพลาด!",
		"-13": "การดำเนินการที่ไม่ถูกต้อง!",
		"-14": "การดำเนินการล้มเหลว!",
		"-15": "การดำเนินการถูกปิดใช้งาน!",
		"-16": "การดำเนินการเสร็จสิ้น!",
		"-17": "ข้อผิดพลาดของการมีอยู่แล้ว",
		"-18": "ไม่มีอยู่!",
		"-19": "หมดอายุ",
		"-20": "ถูกปิดใช้งานแล้ว",
		"-21": "รหัสยืนยันผิดพลาด",
		"-22": "ช่วงเวลาการแก้ไขต้องมากกว่า 1 วัน",
		"-23": "รหัสผ่านใหม่ต้องไม่เหมือนกับรหัสผ่านเดิม",
		"-100": "ข้อผิดพลาดที่กำหนดเอง!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Total Purchase Amount",
		"TotalRevenue": "Total Revenue",
		"AccountBalance": "Account Balance",
		"Income": "Income",
		"Transaction": "Transaction",
		"Myhold": "My hold",
		"ProductList": "Product List",
		"Investmentperiod": "Investment period",
		"days": "days",
		"Buynow": "Buy now",
		"Saletime": "Sale time",
		"Nomore": "No more",
		"ProductDetails": "Product Details",
		"ForSale": "For Sale",
		"Sale": "Sale",
		"SoldOut": "Sold Out",
		"ProductInformation": "Product Information",
		"InvestmentPeriod": "Investment Period",
		"TotalShare": "Total Share",
		"ExpectedReturn": "Expected Return",
		"SaleTime": "SaleTime",
		"PurchaseConditionsAndRules": "Purchase Conditions And Rules",
		"MinimumPurchaseAmount": "Minimum Purchase Amount",
		"MaximumSalesLimit": "Maximum Sales Limit",
		"AccountBalanceMustBeGreaterThan": "Account Balance Must Be Greater Than",
		"DayBetIsGreaterThan": "Day Bet Is Greater Than",
		"ProductDescription": "Product Description",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"Maturity": "Maturity",
		"EarlyRedemption": "Early Redemption",
		"Redemptiontime": "Redemption time",
		"Amountofincome": "Amount of income",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "ช่วยเหลือ",
		"HelpCenter": "ศูนย์ช่วยเหลือ",
		"text": "มีคำถามอะไรบอกเราได้ที่นี่"
	},
	"SetingHa": {
		"Seting": "การตั้งค่า",
		"SetingCenter": "ศูนย์การตั้งค่า",
		"text": "รับรางวัลเพิ่มเติม"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"withdrawalrecord.title": "บันทึกการถอนเงิน",
	"index.index.gayb21": "ลงทะเบียนเพื่อรับรางวัล:",
	"index.index.72y628": "สามเหตุผลในการเลือก EPOCH EPT",
	"index.index.z6022y": "EPOCH EPT ปฏิบัติตามมาตรฐานความปลอดภัยสูงสุดของอุตสาหกรรม เราเก็บ cryptocurrency 100% ไว้ในห้องเย็น เรารับประกันอัตราส่วนเงินสำรองอย่างน้อย 1: 1 ของเงินทุนของลูกค้า กองทุน 300 ล้านดอลลาร์ของเราให้ความคุ้มครองเพิ่มเติมสําหรับภัยคุกคามด้านความปลอดภัย",
	"index.index.574982": "1. ลงทะเบียนฟรี EPOCH EPT ตอนนี้",
	"index.index.qdi1g4": "2. เสร็จสิ้นการตรวจสอบ Google",
	"index.index.weko28": "3. เติมเงินในบัญชี EPOCH EPT ของคุณได้ทันที",
	"index.index.sgkb35": "โปรแกรมคำเชิญ EPOCH EPT",
	"index.index.2u17nf": "รับค่าเฉลี่ยสูงสุดถึง 5-15 USDT เมื่อเสร็จสิ้นคำเชิญที่ถูกต้องใด ๆ โบนัสสูงสุดสำหรับผู้ได้รับเชิญ 100 คน คือ",
	"index.index.e8is4a": "โปรแกรมแนะนำ EPOCH EPT",
	"index.index.47uevl": "ภารกิจการเทรด EPOCH EPT",
	"index.index.2516gq": "EPOCH EPT สหรัฐอเมริกา, Inc. ได้รับใบอนุญาตสำหรับกิจกรรมทางธุรกิจสกุลเงินเสมือนที่ออกโดยหน่วยงานบริการทางการเงินของรัฐนิวยอร์ก",
	"invest.index.3aqi3d": "ยอดเงินในบัญชี:",
	"invest.index.ubk1j4": "หยุดการลงทุน!",
	"invest.prize1.4733u6": "ผลงาน:",
	"invest.prize1.t55a16": "รวม:",
	"login.login.09m52q": "ฉันได้อ่านและยอมรับ EPOCH EPT",
	"login.login.045csy": "ชื่อผู้ใช้",
	"login.login.r7rbm5": "กรุณายอมรับข้อตกลง!",
	"rewards.activityb.4nvrq9": "เมื่อผู้ได้รับเชิญทำการฝากเงินครั้งแรกเสร็จสมบูรณ์ ให้นับเป็นคำเชิญที่ถูกต้อง ผู้เชิญชวนจะได้รับโบนัสสำหรับการเชิญชวนที่ถูกต้องทุกครั้ง ระดับโบนัสเชิญมาจาก 2USDT 1 คำเชิญที่ถูกต้อง 559USDT 100 คำเชิญที่ถูกต้อง",
	"rewards.activityb.t10k6e": "ผู้เชิญชวนจะแบ่งปันค่าคอมมิชชั่นการทำธุรกรรม 20% ของค่าธรรมเนียมการทำธุรกรรมของผู้เชิญชวนแต่ละราย LV.1 ผู้เชิญชวนจะแบ่งปันค่าคอมมิชชั่นการทำธุรกรรม 10% ของค่าธรรมเนียมการทำธุรกรรมของผู้เชิญชวนแต่ละราย LV.2 โบนัสสูงสุดสำหรับค่าธรรมเนียมการซื้อขายของผู้ที่ได้รับเชิญคือ USD ไม่ จำกัด",
	"rewards.activityb.3d6850": "LV.1 จำนวนผู้เชิญที่ถูกต้อง",
	"rewards.activityb.lvxo74": "LV.2 จำนวนผู้เชิญที่ถูกต้อง",
	"rewards.activityb.zj26f9": "LV.1 จำนวนผู้รับเชิญสะสม",
	"rewards.activityb.dkgxr5": "LV.2 จำนวนผู้รับเชิญสะสม",
	"rewards.activityb.xjo077": "รางวัลแนะนำ:",
	"rewards.activityb.k8wv6v": "เข้าร่วม EPOCH EPT และรับการเชื่อมต่อการอ้างอิงของคุณ ผู้ใช้ที่ลงทะเบียนผ่านการเชื่อมต่อนี้จะได้รับรางวัล",
	"rewards.activityb.4slpzg": "เมื่อเพื่อนของคุณเสร็จสิ้นการฝากเงินครั้งแรกและทำการเทรดครบตามจำนวนที่ต้องการ คุณจะได้รับโบนัสอ้างอิงที่มีมูลค่าเฉลี่ย $5-15 รางวัลสูงสุดถึง $559",
	"rewards.index.993jwg": "คัดลอกสำเร็จ",
	"rewards.index.7141ft": "เบราว์เซอร์นี้ไม่สนับสนุนการคัดลอกอัตโนมัติ",
	"wallet.index.9uz2gn": "ค่าธรรมเนียมการจัดการเครือข่าย 0 USDT",
	"wallet.index.ych3e1": "บัญชีจริง:",
	"wallet.index.3v558d": "เคล็ดลับ: การถอนเงินต่ำ $ 20 ค่าธรรมเนียมการถอนเงิน $ 1 การถอนเงินมากกว่า $ 20 ค่าธรรมเนียมการถอนเงิน 3%",
	"wallet.index.255e30": "1. การถอนเงินสูงสุดต่อวันคือ $8,000,000",
	"wallet.index.3g68m8": "2. การถอนเงินครั้งเดียวขั้นต่ำคือ $5 การถอนเงินครั้งเดียวสูงสุดคือ $8,000,000",
	"wallet.index.c6x3ti": "3. ถอนเงินได้ตั้งแต่เวลา 00:00-23:59 น.",
	"wallet.index.h94rlg": "ขั้นต่ำ 5",
	"wallet.index.167862": "กรุณาใส่ที่อยู่กระเป๋าสตางค์!",
	"wallet.index.pi869h": "กรุณาใส่จำนวนเงินที่ถอนออก!",
	"wallet.index.ysa85i": "การถอนเงินครั้งเดียวสูงสุดคือ",
	"components.changepass.68j9kh": "คุณกำลังพยายามรีเซ็ตรหัสผ่านของคุณ",
	"components.changepass.ouxtp2": "รหัสผ่านเดิม",
	"components.languagebox.8no849": "ญี่ปุ่น",
	"components.languagebox.oif20b": "ภาษาจีนดั้งเดิม",
	"components.mycode.xyqr66": "รับสูงสุด 5,000 USDT เมื่อเสร็จสิ้นภารกิจ",
	"components.mymenu.277ss7": "รหัสผู้ใช้",
	"components.mymenu.74l443": "เวลาลงทะเบียน:",
	"components.myposter.36rw5e": "รหัสอ้างอิง:",
	"components.mytabbar.zw2bh2": "หน้าหลัก",
	"components.mytabbar.8x6c28": "แนวโน้ม",
	"components.mytabbar.28s7qr": "การลงทุน",
	"components.mytabbar.4r75d9": "รางวัล",
	"components.xieyi.zqk7pe": "font-size: 16px;  font-family: ร่างกายสน;",
	"components.help.6gpud8": "การลงทุนมี 2 รูปแบบ คือ",
	"components.help.5qrq36": ": งวดละ 1 นาที ลงทุนเพียงคู่เดียว ผลลัพธ์จะถูกคำนวณโดยตัวเลขหลักสุดท้ายของราคาปิดของ Binance BTC/USDT spot trading 1 นาที K-line",
	"components.help.isl7r1": ": งวดละ 5 นาที รุ่น Pro ปลดล็อกวิธีการลงทุนทั้งหมดของ EPOCH EPT ผลลัพธ์จะถูกคำนวณโดยตัวเลขหลักสุดท้ายของราคาปิดของ Binance BTC/USDT spot trading 5 นาที K-line",
	"components.help.eai3nm": "ผลตอบแทนจากการลงทุน:",
	"components.help.35xg82": "หมายเลขเดี่ยว:",
	"components.help.mpfg0s": "จำนวนเงินลงทุน X 9.4",
	"components.help.v2ziib": "หมายเลข Odd:",
	"components.help.vfzxor": "จำนวนเงินลงทุน X 1.94",
	"components.help.73168b": "หมายเลขเหตุการณ์:",
	"components.help.76wgv1": "หมายเลขขนาดใหญ่:",
	"components.help.05ed33": "ตัวเลขขนาดเล็ก:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "กรุณาใส่รหัสผ่านเดิม!",
	"components.changepass.xme6my": "กรุณาใส่รหัสผ่านใหม่!",
	"components.changepass.u434f8": "รหัสผ่านไม่ตรงกันสองครั้ง!",
	"components.changepass.574790": "แก้ไขสำเร็จ!",
	"invest.index.9y8wg0": "ไม่ได้รับรางวัล",
	"invest.index.ql5nc1": "ได้รับรางวัล",
	"login.login.76451t": "แลกเปลี่ยน Spread A ซื้อขายเชิงปริมาณ!",
	"login.login.915p36": "อันดับ 1 ของโลก",
	"login.login.sen863": "ไฮไลต์ยิ่งขึ้นหลังจากลงจอด Hello",
	"login.login.nz6q9x": "เข้าสู่ระบบโทรศัพท์",
	"login.login.w7p592": "เข้าสู่ระบบกล่องจดหมาย",
	"login.login.g9y91k": "หมายเลขโทรศัพท์มือถือ",
	"login.login.456gbb": "หมายเลขกล่องจดหมาย",
	"login.login.5ly8m8": "ประเทศ",
	"login.login.69h472": "กรุณาเลือกประเทศ",
	"login.login.i4p378": "หมายเลข",
	"login.login.fl82xx": "ตัวอย่าง:",
	"wallet.index.urkoub": "เติมเงิน QR Code",
	"wallet.index.25cocl": "คัดลอกที่อยู่",
	"wallet.index.t35fvg": "1. การถอนเงิน: โปรดเก็บภาษีเขตในวันอาทิตย์ภายใน 24 ชั่วโมงตั้งแต่วันจันทร์ถึงวันเสาร์!",
	"wallet.index.68xx61": "2. การถอนเงินสกุลท้องถิ่นทั่วโลกจะถูกโอนเข้าบัญชีภายใน 48 ชั่วโมง ค่าธรรมเนียมการถอนเงิน 8% (รวมภาษีภูมิภาค)",
	"wallet.index.4ws3w9": "3. การถอนเงิน USDT เข้าบัญชีไม่เกิน 24 ชั่วโมงโดยมีค่าธรรมเนียม 2% (รวมภาษีภูมิภาค)",
	"wallet.index.9b7wq8": "4. การถอนเงินขั้นต่ำ 12USDT สูงสุด 3000USDT สำหรับการถอนครั้งเดียว",
	"wallet.index.23y92a": "โปรดทราบ",
	"wallet.index.pl5473": "ขั้นต่ำ 5",
	"wallet.index.nb3u72": "ข้อมูลบัตรธนาคาร",
	"wallet.index.842so4": "เพิ่มหมายเลขบัตรธนาคาร",
	"wallet.index.86j717": "เพิ่มธนาคารของคุณ",
	"wallet.index.763d7x": "เพิ่มชื่อบัตรธนาคาร",
	"wallet.index.50j237": "ธนาคารในเครือ",
	"wallet.index.hb6lun": "ชื่อบัตรธนาคาร",
	"wallet.index.62y2jc": "หมายเลขบัตรธนาคาร",
	"login.register.666hp4": "การซื้อขายเชิงปริมาณ Al Spread",
	"login.register.9298b8": "เชิญเพื่อน",
	"login.register.375g14": "คนใหม่เข้ามาเป็นครั้งแรก",
	"login.register.01922f": "สามารถทำได้ 1 ครั้ง",
	"login.register.527o4q": "รายได้รายวัน AI",
	"login.register.c563gt": "ลงทะเบียนกล่องจดหมาย",
	"login.register.2ls856": "รูปแบบหมายเลขโทรศัพท์มือถือไม่ถูกต้อง!",
	"login.register.4nu77e": "รูปแบบหมายเลขกล่องจดหมายไม่ถูกต้อง!",
	"lang.zh.pu623z": "1 คำเชิญที่ถูกต้อง",
	"lang.zh.3hv80b": "เติมเงิน USDT!",
	"lang.zh.9tli5f": "เคล็ดลับ: ถอนเงินต่ำ",
	"lang.zh.62e882": "USD ค่าธรรมเนียมการถอนเงินคือ",
	"lang.zh.m3818q": "USD, ถอนเงิน",
	"lang.zh.xs2f10": "การแลกคืนก่อนกำหนดจะไม่ได้รับผลประโยชน์"
}