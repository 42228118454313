module.exports = {
	"Language": {
		"title": "Ngôn ngữ"
	},
	"Noproductsforsale": "Không có sản phẩm để bán",
	"rechargePresentationText0_1": "Nạp 1000 thưởng ",
	"rechargePresentationText0_2": ", Nạp 10000 thưởng ",
	"rechargePresentationText0_3": ", và cứ tiếp tục thêm... Nhấp vào đây !",
	"rechargePresentationText0": "Bạn sẽ nhận được ",
	"rechargePresentationText1": "% số tiền nạp khi bạn bắt đầu nạp ngay bây giờ. Thời gian hoạt động giới hạn và sẽ dừng sau khi quà tặng hết.",
	"rechargePresentationText2": "Thời gian kết thúc phần thưởng, đếm ngược!",
	"Online_Chat": "Trò chuyện trực tuyến",
	"gobindgoogle": "Vui lòng ghép nối Google Authenticator của bạn",
	"Order": "Đặt hàng",
	"Record": "Hồ sơ",
	"Assist": "Hỗ trợ",
	"Market": "Thị trường",
	"History": "Lịch sử",
	"Confirm": "Xác nhận",
	"close": "Đóng",
	"Cancel": "Hủy",
	"Previous": "Trước đó",
	"Next": "Kế tiếp",
	"Copy": "Sao chép",
	"Nomoredata": "không có dữ liệu",
	"moneychar": "₹",
	"DownloadApp": "Tải ứng dụng ngay",
	"Yes": "Có",
	"No": "Không",
	"Wating": "Đang chờ xử lý",
	"Recharge": "Nạp tiền",
	"Withdrawal": "Rút tiền",
	"Success": "Thành công",
	"stop_investing": "Ngừng đầu tư",
	"OrderSuccessful": "Đặt hàng thành công",
	"Handledataerror": "Xử lý lỗi dữ liệu",
	"Copysuccess": "Sao chép thành công",
	"MyBalanceLoding": "Đang tải số dư của tôi",
	"UPDATE": "CẬP NHẬT",
	"New_version_available": "Phiên bản mới đã có sẵn",
	"Please,update_app_to_new_version": "Vui lòng cập nhật ứng dụng lên phiên bản mới",
	"tabbar": {
		"Home": "Trang chủ",
		"AndarBahar": "Andar Bahar",
		"Invest": "Đầu tư",
		"Bonus": "Khuyến mãi",
		"Mine": "Ví của tôi",
		"Trending": "Xu hướng",
		"Wallet": "Ví",
		"Rewards": "Phần thưởng"
	},
	"homeTop": {
		"Sign_Up": "Đăng ký",
		"Sign_up_for_rewards": "Đăng ký để nhận phần thưởng",
		"up_to": "lên đến",
		"TOTAL_ASSETS": "TỔNG TÀI SẢN",
		"User_ID": "ID Người dùng",
		"Registration_time": "Thời gian đăng ký"
	},
	"PieChart": {
		"BIG&SMALL": "Lớn & Nhỏ",
		"NUMBER": "Số",
		"ODD&EVEN": "Lẻ & Chẵn",
		"ALL_NUMBER_TRENDING": "Tất cả số đang thịnh hành",
		"BIG&SMALL_NUMBER_TRENDING": "Số lớn & nhỏ đang thịnh hành",
		"ODD&EVEN_NUMBER_TRENDING": "Số lẻ & chẵn đang thịnh hành"
	},
	"register": {
		"checkerr": "Mật khẩu: Sử dụng ít nhất 8 ký tự (kết hợp chữ và số)",
		"tips_3": "Nếu bạn không nhận được mã trong hộp thư đến của mình, vui lòng kiểm tra thư rác.",
		"receiveCode": "Không nhận được mã?",
		"receiveCode_title": "Mã đã được gửi đến email của bạn. Nếu sau vài lần thử vẫn chưa nhận được mã, vui lòng thử:",
		"receiveCode_text1": "1. Kiểm tra xem nó có trong thư rác không.",
		"receiveCode_text2": "2. Đảm bảo địa chỉ email của bạn là",
		"receiveCode_text3": "3. Tin nhắn có thể bị chậm vài phút. Thử lại sau 20 phút.",
		"receiveCode_text4": "4. Nếu địa chỉ email này đã tồn tại, chúng tôi sẽ không gửi cho bạn mã xác thực. Vui lòng đăng nhập hoặc đặt lại mật khẩu.",
		"Sign_up_for_Rewards": "Đăng ký để nhận thưởng",
		"Email_Verification": "Xác nhận email",
		"tips": "Vui lòng nhập mã xác nhận 6 chữ số đã được gửi đến {{formData.email}}. Mã có hiệu lực trong 30 phút.",
		"tips_1": "Vui lòng nhập mã xác nhận 6 chữ số đã được gửi đến ",
		"tips_2": "Mã có hiệu lực trong 30 phút.",
		"Resend_code_in": "Gửi lại mã trong",
		"Sent_again": "Gửi lại",
		"CREATE_ACCOUNT": "TẠO TÀI KHOẢN",
		"inviteCodecheck": "inviteCode không được để trống!",
		"Mobile": "Số điện thoại di động",
		"Mobilemsg": "Số điện thoại di động là bắt buộc",
		"Password": "Mật khẩu",
		"Passwordmsg": "Mật khẩu là bắt buộc",
		"OTPCode": "Mã OTP",
		"OTPCodemsg": "Mã OTP là bắt buộc",
		"SendOTP": "Gửi OTP",
		"Email": "Email",
		"Emailmsg": "Email là bắt buộc",
		"Invitecode": "Mã giới thiệu (tùy chọn)",
		"Invitecodemsg": "Mã giới thiệu là bắt buộc",
		"emailFormatter": "Vui lòng nhập địa chỉ email chính xác",
		"usernameFormatter": "Tên người dùng phải là ký tự và số và trong phạm vi 6 <= độ dài <= 20",
		"phoneFormatter": "Vui lòng điền số điện thoại di động 10 chữ số",
		"msgsuccess": "Đăng ký thành công",
		"msgerr": "Đăng ký thất bại",
		"errorphonecheck": "Số điện thoại di động không thể để trống!",
		"phonecheck": "Vui lòng điền số điện thoại di động 10 chữ số!",
		"imgcodecheck": "Mã OTP không thể để trống!"
	},
	"login": {
		"Welcome_back": "Chào mừng trở lại",
		"tips": "Tôi đã đọc và đồng ý với {{this.$LogoName}} Điều khoản dịch vụ và Chính sách bảo mật",
		"tips_1": "Tôi đã đọc và đồng ý với",
		"tips_2": "Điều khoản dịch vụ",
		"tips_3": "và",
		"tips_4": "Chính sách bảo mật",
		"Continue_with_Google": "Tiếp tục bằng Google",
		"Continue_with_Apple": "Tiếp tục bằng Apple",
		"text": "Chưa có tài khoản?",
		"text2": "Đã có tài khoản?",
		"Login": "Đăng nhập",
		"Register": "Đăng ký",
		"username": "Tên đăng nhập hoặc số điện thoại",
		"password": "Mật khẩu",
		"imgcode": "Mã hình ảnh",
		"BackHome": "Trở về trang chủ",
		"Forgetpwd": "Quên mật khẩu"
	},
	"forgot": {
		"title": "Quên mật khẩu",
		"msgsuccess": "Thay đổi mật khẩu thành công",
		"newpassword": "Mật khẩu mới",
		"newpasswordmsg": "Vui lòng nhập mật khẩu mới",
		"Continue": "Tiếp tục"
	},
	"home": {
		"content11": "Người mời có thể nhận được thưởng tỷ lệ với khối lượng giao dịch được tạo ra bởi người mời của họ. Thưởng tối đa cho một lời mời hợp lệ là Unlimit USDT.",
		"Revolutionize_Trading": "Cách mạng hóa giao dịch",
		"Shape_the_Future": "Hình thành Tương lai",
		"content0": "Hãy trải nghiệm cùng nhau kỷ nguyên mới của giao dịch tiền điện tử!",
		"3_Reasons_for_Choosing": "3 Lý do để lựa chọn",
		"Innovative_Trading_Features": "Tính năng Giao dịch đột phá",
		"content1": "Trải nghiệm giao dịch cách mạng không giống bất cứ điều gì hiện có trên thị trường. Được thiết kế để mang lại lợi thế cạnh tranh với tính năng giao dịch đột phá, các biện pháp bảo mật tiên tiến và tốc độ giao dịch nhanh chóng.",
		"Lower_Fees_and_Better_Rewards": "Phí thấp và Phần thưởng tốt hơn",
		"content2": "Giao diện của chúng tôi làm cho việc giao dịch trở nên đơn giản đối với người mới bắt đầu. Bạn có thể tạo tài khoản miễn phí, giao dịch tiền điện tử với phí giao dịch thấp từ thị trường của chúng tôi. Hơn nữa, bạn có thể chia sẻ lên đến 20% phí giao dịch từ sàn giao dịch cho tất cả người dùng bạn mời.",
		"Institutional-grade_Security": "Bảo mật cấp độ tổ chức",
		"content3": "tuân theo một trong những tiêu chuẩn bảo mật cao nhất trong ngành. Chúng tôi lưu trữ 100% tiền điện tử của bạn trong kho lạnh. Chúng tôi đảm bảo tỷ lệ dự trữ ít nhất là 1: 1 của quỹ khách hàng của chúng tôi. Quỹ 300 triệu USDT của chúng tôi cung cấp một lớp bảo vệ bổ sung chống lại các mối đe dọa về an ninh.",
		"Start_investing_in_as_little_as_5_minutes": "Bắt đầu đầu tư chỉ trong 5 phút",
		"content4": " Đăng ký với {{this.$LogoNames.toUpperCase()}} miễn phí",
		"content4_1": "Đăng ký với",
		"content4_2": " miễn phí",
		"Complete_google_authentication": "Hoàn thành xác thực Google",
		"content5": "Nhập và xác minh địa chỉ email của bạn để bắt đầu. Không có phí mở tài khoản.",
		"content7": "Mở khóa đầy đủ chức năng bằng cách xác minh Google Authenticator trong vòng 5 phút.",
		"content8": "Nạp tài khoản {{this.$LogoNames.toUpperCase()}} của bạn ngay lập tức",
		"content8_1": "Nạp tài khoản",
		"content8_2": "ngay lập tức",
		"content9": "Nạp USDT qua mạng lưới được chọn và truy cập dự trữ của bạn ngay lập tức. Không cần phải chờ đợi 3-5 ngày để bắt đầu giao dịch.",
		"Start_building_your_crypto_portfolio": "Bắt đầu xây dựng danh mục tiền điện tử của bạn",
		"Invitation_Program": "Chương trình mời gọi",
		"Referral_Program": "Chương trình giới thiệu",
		"content10": "Hoàn thành bất kỳ lời mời hợp lệ nào và nhận được tiền thưởng lời mời trung bình là 10 USDT. Tiền thưởng tối đa cho một người là ",
		"TRADING_TASKS": "NHIỆM VỤ GIAO DỊCH",
		"content12": "Người dùng sẽ nhận được phần thưởng trong USDT khi giao dịch, nhiều nhiệm vụ giao dịch cho các chế độ giao dịch khác nhau. Phần thưởng sẽ được phân phối ngay lập tức",
		"Trade_anytime_anywhere": "Giao dịch bất cứ lúc nào, bất cứ nơi đâu",
		"content13": "USA, Inc. được cấp phép tham gia Hoạt động Kinh doanh Tiền ảo bởi Sở Dịch vụ Tài chính Tiểu bang New York.",
		"USA_Inc": "USA, Inc",
		"content6": "Tải ứng dụng của chúng tôi để kết nối liên tục 24/7",
		"Legal&Disclosures": "Pháp lý và tiết lộ",
		"Regulatory_License": "Giấy phép quy định",
		"AML/KYC_Policies": "Chính sách AML / KYC",
		"Privacy_Policy": "Chính sách bảo mật",
		"Terms_of_Use": "Điều khoản sử dụng",
		"Legal_Statement": "Tuyên bố pháp lý",
		"Risk_Disclosure": "Tiết lộ rủi ro",
		"About_Us": "Về chúng tôi",
		"Protection_Fund": "Quỹ bảo vệ",
		"Proof_of_Reserves": "Chứng minh dự trữ",
		"Twitter": "Twitter",
		"Bitget_Token": "Token Bitget",
		"Careers": "Nghề nghiệp",
		"Company": "Công ty",
		"Support_Service": "Hỗ trợ",
		"title": "Mở bằng ứng dụng",
		"titlebtn": "Tải xuống",
		"Balance": "Số dư",
		"ID": "ID",
		"Recharge": "Nạp tiền",
		"Withdraw": "Rút tiền",
		"Bonus": "Phần thưởng",
		"Checkin": "Check in",
		"Eventclosed": "Sự kiện đã kết thúc",
		"Ranking": "Xếp hạng"
	},
	"Ranking": {
		"Today_s winning ranking": "Bảng xếp hạng chiến thắng hôm nay",
		"tips1": "Số tiền chiến thắng càng lớn = xếp hạng càng cao!",
		"UserName": "Tên người dùng",
		"Winning Amount": "Số tiền chiến thắng",
		"Today_s rebate ranking": "Bảng xếp hạng hoàn trả hôm nay",
		"tips2": "Hoàn trả cao hơn cho người dùng cấp thấp = xếp hạng cao hơn!",
		"Rebate Amount": "Số tiền hoàn trả",
		"Rebate ranking": "Top 20 bảng xếp hạng hoàn trả"
	},
	"Checkin": {
		"Checkin": "Kiểm tra",
		"CheckinList": "Danh sách kiểm tra"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Quy tắc",
		"Record": "Bản ghi",
		"Records": "Bản ghi Andar Bahar",
		"MyOrder": "Đơn hàng của tôi",
		"more": "thêm",
		"Period": "Kỳ hạn",
		"Order": "Đặt hàng",
		"Buy": "Mua",
		"Result": "Kết quả",
		"Profit": "Lợi nhuận",
		"AndarBaharRule": "Quy tắc Andar Bahar",
		"CountDown": "Đếm ngược",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Hòa",
		"result": "Kết quả",
		"ok": "ok",
		"start": "Bắt đầu",
		"Point": "Điểm",
		"ContractPoint": "Điểm HĐ",
		"Number": "Số",
		"Delivery": "Giao hàng",
		"Fee": "Phí",
		"Amount": "Số tiền"
	},
	"pokerOrder": {
		"OrderRecord": "Lịch sử giao dịch",
		"WatingOrder": "Đang chờ giao dịch",
		"HistoryOrder": "Lịch sử giao dịch",
		"Nomoredata": "Không có dữ liệu nữa",
		"Buy": "Mua",
		"Result": "Kết quả",
		"Time": "Thời gian",
		"Amount": "Số tiền",
		"Fee": "Phí",
		"Profit": "Lợi nhuận"
	},
	"pokerRecord": {
		"title": "Hồ sơ Andar Bahar",
		"Period": "Kỳ",
		"Result": "Kết quả",
		"OpenTime": "Thời gian mở",
		"Nomore": "Không có nữa"
	},
	"Google2FA": {
		"title": "Ứng dụng xác thực Google",
		"Authenticator_Instructions": "Hướng dẫn xác thực",
		"Authenticator_Status": "Trạng thái xác thực",
		"Google_Authenticator": "Ứng dụng xác thực Google",
		"Download_Google_Authenticator_App": "Tải ứng dụng xác thực Google",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "Tiếp theo",
		"Backup_key": "Mã sao lưu",
		"Backup_key_text": "Vui lòng lưu mã sao lưu này ở một nơi an toàn. Mã này sẽ giúp bạn phục hồi ứng dụng xác thực của mình nếu bạn mất điện thoại. Nếu không, bạn sẽ phải liên hệ với dịch vụ khách hàng để đặt lại ứng dụng xác thực.",
		"Verifiaction": "Xác thực",
		"Verifiaction_text": "Nhập mã xác thực Google",
		"Google_verification_code": "Mã xác thực Google",
		"contentlist1": "1. Ghi lại mã sao lưu 16 chữ số từ +this.$LogoName+ và giữ nó ở một nơi an toàn.",
		"contentlist1_1": "1. Ghi lại mã sao lưu 16 chữ số từ",
		"contentlist1_2": "và giữ nó ở một nơi an toàn.",
		"contentlist2": "2. Mở ứng dụng xác thực Google.",
		"contentlist3": "3. Thêm tài khoản +this.$LogoName+ và nhập mã 16 chữ số đã sao chép.",
		"contentlist3_1": "3. Thêm tài khoản",
		"contentlist3_2": "và nhập mã 16 chữ số đã sao chép.",
		"contentlist4": "4. Bạn sẽ thấy mã xác thực 6 chữ số trên màn hình.",
		"contentlist5": "5. Sao chép mã xác thực 6 chữ số và dán vào +this.$LogoName+ .",
		"contentlist5_1": "5. Sao chép mã xác thực 6 chữ số và dán vào",
		"contentlist5_2": ".",
		"contentlist6": "6. Sau đó, ứng dụng xác thực của bạn sẽ được liên kết thành công."
	},
	"win": {
		"Live_Price": "Giá trực tiếp",
		"title1": "Kết quả đầu tư được tính bằng chữ số cuối cùng của Giao dịch Spot Binance BTC/USDT tương ứng với thời gian UTC. Nhà đầu tư có thể kiểm tra kết quả trên Binance bất kỳ lúc nào để đảm bảo tính công bằng và an toàn cho đầu tư.",
		"title": "Có 2 chế độ trong đầu tư",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Mỗi vòng kéo dài 1 phút. Chế độ Lite chỉ cung cấp đầu tư số chẵn và số lẻ. Kết quả được tính bằng chữ số cuối cùng của kết quả đóng của giao dịch đòn bẩy Binance BTC/USDT 1 phút.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Mỗi vòng kéo dài 5 phút. Chế độ Pro mở khóa phương pháp đầu tư đầy đủ của EPOCH EPT. Kết quả được tính bằng chữ số cuối cùng của kết quả đóng của giao dịch đòn bẩy Binance BTC/USDT 5 phút.",
		"5M_Mode_text_1": "Mỗi vòng kéo dài 5 phút. Chế độ Pro mở khóa phương pháp đầu tư đầy đủ của ",
		"5M_Mode_text_2": " . Kết quả được tính bằng chữ số cuối cùng của kết quả đóng của giao dịch đòn bẩy Binance BTC/USDT 5 phút.",
		"Investment_Guide": "Hướng dẫn đầu tư",
		"Investment_Rules": "Quy tắc đầu tư",
		"title2": "Làm thế nào để chứng minh kết quả là công bằng?",
		"Investment_Info": "Thông tin đầu tư",
		"3M_Mode": "3M Mode",
		"3M_Mode_text": "Mỗi vòng kéo dài 3 phút. Kết quả được tính bằng chữ số cuối cùng của kết quả đóng của giao dịch đòn bẩy Binance BTC/USDT 3 phút.",
		"Investment_return": "Lợi nhuận đầu tư",
		"Number": "Số",
		"Profit_Invested_amount": "Lợi nhuận: Số tiền đầu tư",
		"guide1": "Chọn chế độ thời gian.",
		"guide2": "Điều chỉnh số tiền đầu tư của bạn.",
		"guide3": "Chọn số bạn muốn đầu tư.",
		"guide4": "Kiểm tra danh mục đầu tư của bạn và nhấp vào đầu tư.",
		"NEXT": "TIẾP THEO",
		"Invest_Amount": "Số tiền đầu tư",
		"Total": "Tổng",
		"INVEST": "ĐẦU TƯ",
		"Portfolio": "Quỹ đầu tư",
		"Invest": "Đầu tư",
		"BINANCE": "Binance",
		"Bitcoin_Price": "Giá Bitcoin",
		"InvestmentRecord": "Lịch sử đầu tư",
		"Best_Assistant": "Trợ lý đầu tư",
		"last_100_rounds": "(100 vòng cuối)",
		"What_do_these_data_mean": "Ý nghĩa của các dữ liệu này",
		"Missing": "Thiếu số lần xuất hiện",
		"Missing_text": "Điều đó có nghĩa là số vòng đã trôi qua kể từ lần xuất hiện cuối cùng của số đó.",
		"Frequency": "Tần suất",
		"Frequency_text": "Chỉ số lần xuất hiện của số tương ứng trong 100 vòng gần đây.",
		"Max_Continued": "Liên tiếp lớn nhất",
		"Max_Continued_text": "Thời gian xuất hiện liên tiếp lớn nhất của số tương ứng trong 100 vòng gần đây.",
		"Rule": "Quy định",
		"Trend": "Xu hướng",
		"MyBalance": "Số dư tài khoản",
		"JoinGreen": "Tham gia màu xanh lá",
		"JoinRed": "Tham gia màu đỏ",
		"JoinViolet": "Tham gia màu tím",
		"Parity": "Độ chẵn lẻ",
		"Sapre": "Độ sát phạt",
		"Bcone": "Mã Bcone",
		"Emerd": "Mã Emerd",
		"Record": "Ghi chép",
		"more": "Thêm",
		"Period": "Thời gian",
		"Price": "Giá",
		"Result": "Kết quả",
		"My": "Của tôi",
		"Order": "Đặt hàng",
		"Buy": "Mua",
		"Profit": "Lợi nhuận",
		"Green": "Xanh lá",
		"Red": "Đỏ",
		"Violet": "Tím",
		"tips": "Phí giao dịch là {bet_fee*100}% số tiền đặt cược",
		"tips1": "Phí giao dịch là ",
		"tips2": "số tiền đặt cược",
		"OrderAmount": "Số tiền đặt hàng",
		"Quantity": "Số lượng",
		"TransactionFee": "Phí giao dịch",
		"TotalAmount": "Tổng số tiền",
		"PresalePolicy": "Chính sách bảo mật",
		"Iagree": "Tôi đồng ý",
		"err1": "Vui lòng đồng ý với các quy tắc quản lý bán hàng trước"
	},
	"historyPeriods": {
		"Orderrecord": "Hồ sơ đặt hàng",
		"record": "hồ sơ"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Đang chờ xử lý",
		"HistoryOrder": "Lịch sử đặt hàng"
	},
	"Activity": {
		"added_to_account_balance": "đã được thêm vào số dư tài khoản.",
		"contentText": "Khi người được mời hoàn thành lần nạp tiền lần đầu, đó được tính là một lời mời hợp lệ. Người mời sẽ nhận được tiền thưởng mời dành cho mỗi lời mời hợp lệ. Các mức tiền thưởng mời từ {{aaaaaa}} USDT cho một lời mời hợp lệ đến {{aaaaaa}} USDT cho 500 lời mời hợp lệ.",
		"contentText_1": "Khi người được mời hoàn thành lần nạp tiền lần đầu, đó được tính là một lời mời hợp lệ. Người mời sẽ nhận được tiền thưởng mời dành cho mỗi lời mời hợp lệ. Các mức tiền thưởng mời từ",
		"contentText_2": "cho một lời mời hợp lệ đến ",
		"contentText_3": " cho 500 lời mời hợp lệ",
		"contentText_3_1": " cho ",
		"contentText_3_2": " lời mời hợp lệ",
		"contentText2": "Người mời sẽ chia sẻ {{LV.1%}} phí giao dịch từ khối lượng giao dịch của mỗi người được mời cấp độ 1. Người mời sẽ chia sẻ {{LV.2%}} phí giao dịch từ khối lượng giao dịch của mỗi người được mời cấp độ 2. Tiền thưởng tối đa cho mỗi khối lượng giao dịch của người được mời là Không giới hạn USDT.",
		"contentText2_1": "Người mời sẽ chia sẻ",
		"contentText2_2": " phí giao dịch từ khối lượng giao dịch của mỗi người được mời cấp độ 1. Người mời sẽ chia sẻ",
		"contentText2_3": " phí giao dịch từ khối lượng giao dịch của mỗi người được mời cấp độ 2. Tiền thưởng tối đa cho mỗi khối lượng giao dịch của người được mời là Không giới hạn USDT.",
		"My_Rewards_History": "Lịch sử Tiền thưởng của tôi",
		"Cumulative_Single_Number_Trading_Volumn": "Khối lượng giao dịch Đơn số tích lũy",
		"Cumulative_ODD_Number_Trading_Volumn": "Khối lượng giao dịch số LẺ tích lũy",
		"Cumulative_EVEN_Number_Trading_Volumn": "Tổng khối lượng giao dịch số CHẴN tích lũy",
		"Cumulative_BIG_Number_Trading_Volumn": "Tổng khối lượng giao dịch số LỚN tích lũy",
		"Cumulative_SMALL_Number_Trading_Volumn": "Tổng khối lượng giao dịch số NHỎ tích lũy",
		"Advanced_Tasks": "Nhiệm vụ nâng cao",
		"Invite_Friends_and_Earn_Up_to": "Mời bạn bè và nhận lên đến",
		"Referral_Code": "Mã giới thiệu",
		"Referral_Link": "Liên kết giới thiệu",
		"INVITE_FRIENDS": "MỜI BẠN BÈ",
		"Complete_tasks_to_earn_up_to": "Hoàn thành nhiệm vụ để kiếm lên đến",
		"MY_REWARDS": "PHẦN THƯỞNG CỦA TÔI",
		"SHARE": "CHIA SẺ",
		"Earn_with_your_friends_together": "Kiếm tiền cùng bạn bè của bạn",
		"Get_your_link": "Lấy liên kết của bạn",
		"Claim_your_rewards": "Yêu cầu phần thưởng của bạn",
		"Earn_together": "Kiếm cùng nhau",
		"contentlist0": "Tham gia {{gogobit}} và lấy liên kết giới thiệu của bạn. Người dùng đăng ký qua liên kết này sẽ nhận được phần thưởng.",
		"contentlist0_1": "Tham gia",
		"contentlist0_2": "và lấy liên kết giới thiệu của bạn. Người dùng đăng ký qua liên kết này sẽ nhận được phần thưởng.",
		"contentlist1": "Bạn sẽ nhận được phần thưởng giới thiệu trung bình {{XX}} USDT sau khi bạn bè của bạn thực hiện khoản tiền gửi đầu tiên và hoàn thành khối lượng giao dịch yêu cầu. Phần thưởng có thể lên đến {{XX}} USDT.",
		"contentlist1_1": "Bạn sẽ nhận được phần thưởng giới thiệu trung bình ",
		"contentlist1_2": "USDT sau khi bạn bè của bạn thực hiện khoản tiền gửi đầu tiên và hoàn thành khối lượng giao dịch yêu cầu. Phần thưởng có thể lên đến ",
		"contentlist1_3": " USDT.",
		"contentlist2": "Sau khi bạn bè của bạn tạo tài khoản, anh ta / cô ta có thể yêu cầu tiền thưởng giao dịch lên đến 5.050 USDT.",
		"Referral_Rewards": "Phần thưởng giới thiệu",
		"Detail_Rules": "Quy định chi tiết",
		"Invitation_Bonus": "Phần thưởng giới thiệu",
		"Up_to": "Lên đến",
		"content0": "Hoàn thành bất kỳ lời mời hợp lệ và nhận thưởng mời trung bình lên đến 10 USDT. Thưởng tối đa cho một trăm người là",
		"content0_1": "Hoàn thành bất kỳ lời mời hợp lệ và nhận thưởng mời trung bình lên đến ",
		"content0_2": "Thưởng tối đa cho một trăm người mời là ",
		"Trading_Bonus": "Thưởng giao dịch",
		"content1": "Người giới thiệu có thể nhận được tiền thưởng tỷ lệ với khối lượng giao dịch được tạo ra bởi người được giới thiệu. Thưởng tối đa cho một lời mời hợp lệ là",
		"My_Referral_History": "Lịch sử giới thiệu của tôi",
		"Total_Invitees": "Tổng số người được mời",
		"Valid_Invitees": "Người được mời hợp lệ",
		"Cumulative_Invitees": "Người được mời",
		"Trading_Volumn": "Khối lượng giao dịch",
		"Daily_Tasks": "Nhiệm vụ hàng ngày",
		"Detail_Rules_title": "Vòng quay may mắn hàng ngày trị giá 888 USDT",
		"Detail_Rules_title1": "1. Dữ liệu giao dịch tương lai của ngày sẽ được tính toán vào ngày tiếp theo.",
		"Detail_Rules_title2": "2. Người dùng có khối lượng giao dịch tương lai từ 200.000 USDT trở lên trong ngày sẽ có cơ hội quay một lần để nhận giải thưởng 888 USDT.",
		"CLAIM_NOW": "NHẬN NGAY",
		"INVEST_NOW": "ĐẦU TƯ NGAY",
		"title": "Ví thưởng",
		"BonusBalance": "Số dư thưởng",
		"Withdrawtobalance": "Rút về số dư",
		"BonusList": "Danh sách thưởng",
		"Bonuswithdrawtobalance": "Rút thưởng về số dư",
		"WithdrawalAmount": "Số tiền rút",
		"Pleaseentertheamount": "Vui lòng nhập số tiền",
		"err1": "Số tiền rút không thể là 0",
		"success": "Chuyển khoản thành công"
	},
	"Refer": {
		"title": "Giới thiệu của tôi",
		"ReferTotalBonus": "Tổng thưởng giới thiệu",
		"InviteFriends": "Mời bạn bè",
		"Level1": "Cấp 1",
		"Level2": "Cấp 2",
		"TotalPeople": "Tổng số người",
		"Bonus": "Thưởng",
		"Myinvitationlink": "Liên kết giới thiệu của tôi",
		"Rebate_description": "Mô tả hoàn tiền"
	},
	"Task": {
		"InviteBonusToday": "Tiền thưởng giới thiệu hôm nay",
		"bonusrecord": "Lịch sử tiền thưởng",
		"hint1": "1. Dữ liệu được cập nhật mỗi 10-30 phút!",
		"hint2": "2. Chỉ tính các tài khoản đã đăng ký trong vòng 3 ngày và hoàn thành việc nạp tiền hôm nay!",
		"hint2_1": "2. Chỉ tính các tài khoản đã đăng ký trong vòng ",
		"hint2_2": " ngày và hoàn thành việc nạp tiền hôm nay!",
		"hint3": "3. Yêu cầu nạp tiền tối thiểu là 20u hôm nay!",
		"hint3_1": "3. Yêu cầu nạp tiền tối thiểu là ",
		"hint3_2": "u hôm nay!",
		"hint4": "Số người được mời trong ba ngày qua",
		"hint4_1": "Số người được mời trong vòng ",
		"hint4_2": " ngày qua",
		"hint5": "Số người đã hoàn thành việc nạp tiền hôm nay",
		"Invite": "Mời",
		"friendstorecharge": "bạn bè nạp tiền",
		"Earn": "Kiếm",
		"Completed": "Đã hoàn thành",
		"ExpireTime": "Thời gian hết hạn"
	},
	"Invited": {
		"title": "Lịch sử thưởng",
		"InviteTotalBonus": "Tổng số tiền thưởng từ lời mời",
		"InviteTime": "Thời gian lời mời",
		"Bonus": "Thưởng",
		"NoRecored": "Không có dữ liệu"
	},
	"mine": {
		"ProvacyPolicy": "Chính sách bảo mật",
		"JOIN US": "THAM GIA CÙNG CHÚNG TÔI",
		"title": "Trung tâm cá nhân",
		"InviteCode": "Mã giới thiệu",
		"AccountBalance": "Số dư tài khoản",
		"BonusBalance": "Số dư thưởng",
		"Wallet": "Ví",
		"Bank": "Ngân hàng",
		"Invite": "Mời bạn bè",
		"Refer": "Giới thiệu",
		"CustomerService": "Dịch vụ khách hàng",
		"ComplaintsSuggestions": "Phản hồi",
		"ChangePassword": "Đổi mật khẩu",
		"Activity": "Hoạt động",
		"PresalePolicy": "Chính sách bảo mật",
		"DownloadApp": "Tải ứng dụng",
		"AppVersion": "Phiên bản ứng dụng",
		"Logout": "Đăng xuất",
		"Logouttips": "Bạn có chắc chắn muốn đăng xuất không?"
	},
	"App": {
		"title": "Tải ứng dụng",
		"DownloadforAndroid": "Tải về cho Android",
		"DownloadforiOS": "Tải về cho iOS"
	},
	"Presale": {
		"title": "Chính sách bảo mật"
	},
	"Account": {
		"title2": "Thiết lập mật khẩu mới",
		"title2_text": "Thiết lập mật khẩu mới cho tài khoản Google của bạn. Bạn có thể đăng nhập vào tài khoản Google của mình bằng mật khẩu mới.",
		"title_text": "Bạn đang cố gắng đặt lại mật khẩu của mình. Vui lòng chuẩn bị mã xác thực Google của bạn để đặt lại.",
		"err1": "Hai mật khẩu mới đã nhập khác nhau",
		"success2": "Thay đổi mật khẩu thành công",
		"title": "Thay đổi mật khẩu",
		"OldPassword": "Mật khẩu cũ",
		"OldPasswordmsg": "yêu cầu mật khẩu cũ",
		"NewPassword": "Mật khẩu mới",
		"NewPasswordmsg": "yêu cầu mật khẩu mới",
		"RetypeNewPassword": "Nhập lại mật khẩu mới",
		"RetypeNewPasswordmsg": "yêu cầu nhập lại mật khẩu mới",
		"Continue": "Tiếp tục"
	},
	"Service": {
		"title": "Dịch vụ",
		"CONTACTUS": "LIÊN HỆ CHÚNG TÔI",
		"tips": "Dịch vụ khách hàng đang nghỉ ngơi !",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "liên kết"
	},
	"Bank": {
		"BankName": "Tên Ngân hàng",
		"ChooseaCurrencyType": "Chọn loại tiền tệ",
		"SelectWithdrawalMethod": "Chọn phương thức rút tiền",
		"Newbankcard": "Thẻ ngân hàng mới",
		"Editbankcard": "Sửa thông tin thẻ ngân hàng",
		"title": "Ngân hàng của tôi",
		"Cannotbeempty": "Không thể để trống",
		"BankHolderName": "Tên chủ thẻ",
		"BankAccountNumber": "Số tài khoản ngân hàng",
		"IFSCCode": "Mã IFSC",
		"Email": "Email",
		"Phone": "Điện thoại",
		"OTPCode": "Mã OTP",
		"tips1": "1. Vui lòng sử dụng số điện thoại mà bạn đã đăng ký để nhận OTP",
		"tips2": "2. Để đảm bảo an toàn cho các khoản tiền, thẻ ngân hàng chỉ có thể sửa thông tin một lần mỗi ngày",
		"Add": "Thêm",
		"error1": "Tên chủ thẻ không được để trống!",
		"error2": "Vui lòng nhập đúng Tên chủ thẻ!",
		"error3": "Tên Ngân hàng không được để trống!",
		"error4": "Vui lòng nhập đúng Tên Ngân hàng!",
		"error5": "Số tài khoản ngân hàng không được để trống!",
		"error6": "Vui lòng nhập đúng số tài khoản ngân hàng!",
		"error7": "Mã IFSC không được để trống!",
		"error8": "Vui lòng nhập đúng mã IFSC!",
		"error9": "Email không được để trống!",
		"error10": "Vui lòng nhập đúng địa chỉ email!",
		"error11": "Số điện thoại không được để trống!",
		"error12": "Mã OTP không được để trống!",
		"Successfully": "Thêm thẻ ngân hàng thành công"
	},
	"wallet": {
		"Tiêu đề": "Ví của tôi",
		"Số dư của tôi": "Số dư của tôi",
		"Ngân hàng của tôi": "Ngân hàng của tôi",
		"Hồ sơ nạp tiền": "Hồ sơ nạp tiền",
		"Hồ sơ rút tiền": "Hồ sơ rút tiền"
	},
	"Rechargepage": {
		"Reminder": "Nhắc nhở",
		"Reminder_1": "1. Không nạp bất kỳ loại tiền tệ nào khác ngoài USDT vào địa chỉ này. Gửi đồng coin hoặc mã thông báo không được hỗ trợ đến địa chỉ này có thể dẫn đến mất mát không thể lấy lại.",
		"Reminder_2": "2. Số tiền nạp tối thiểu là 5 USDT.",
		"Reminder_2_1": "2. Số tiền nạp tối thiểu là ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Tiền sẽ được chuyển sau 15 lần xác nhận mạng.",
		"title": "Nạp tiền",
		"History": "Lịch sử nạp tiền",
		"Mybalance": "Số dư tài khoản",
		"RechargeAmount": "Số tiền nạp",
		"RechargeAmountmsg": "Vui lòng nhập số tiền",
		"tips1": "Kênh thanh toán đang bảo trì!",
		"tips2": "Vui lòng chọn kênh thanh toán",
		"PayNow": "Thanh toán ngay",
		"err1": "Kênh thanh toán đang bảo trì",
		"err2": "Số tiền nạp không được nhỏ hơn ",
		"err3": "Vui lòng chọn kênh thanh toán",
		"Rechargemethod": "Phương thức nạp tiền"
	},
	"Rechargerecord": {
		"title": "Lịch sử nạp tiền",
		"WatingOrder": "Đang chờ",
		"HistoryOrder": "Lịch sử",
		"Rupee": "Đô la",
		"Status": "Trạng thái",
		"Time": "Thời gian"
	},
	"withdrawal": {
		"Tips": "Lưu ý",
		"Tips_1": "Lời khuyên: Phí rút tiền cho số tiền dưới ",
		"Tips_2": " USDT là ",
		"Tips_3": " USDT, phí rút tiền cho số tiền từ ",
		"Tips_4": "USDT trở lên là ",
		"Tips_5": "%",
		"WalletAddress": "Địa chỉ Ví",
		"Addwalletaddress": "Thêm địa chỉ ví",
		"Network_fee": "Phí mạng",
		"Receive_amount": "Số tiền nhận được",
		"Select Chains": "Chọn chuỗi",
		"Bankcard": "Thẻ ngân hàng",
		"Nobankcardselected": "Không có thẻ ngân hàng được thêm",
		"Withdrawalfee": "Phí rút tiền",
		"title": "Rút Tiền",
		"Mybalance": "Số dư của tôi",
		"Withdrawablebalance": "Số dư có thể rút được",
		"tips1": "Tổng số tiền mua phải lớn hơn {{codeAmount}} để rút được toàn bộ",
		"tips1-1": "Tổng số tiền mua phải lớn hơn",
		"tips1-2": "để rút được toàn bộ",
		"tips2": "Số lần rút trong ngày là {{draw_num}} lần",
		"tips2-1": "Số lần rút trong ngày là",
		"tips2-2": "lần",
		"tips3": "Số tiền rút tối đa mỗi ngày là",
		"tips4": "Số tiền rút tối thiểu mỗi lần là ₹{{draw_min_money}} và số tiền rút tối đa mỗi lần là ₹{{draw_max_money}}",
		"tips4-1": "Số tiền rút tối thiểu mỗi lần là",
		"tips4-2": "và số tiền rút tối đa mỗi lần là",
		"tips5": "Thời gian rút tiền là {{draw_time}}, vui lòng rút tiền đúng thời gian quy định",
		"tips5-1": "Thời gian rút tiền là",
		"tips5-2": "vui lòng rút tiền đúng thời gian quy định",
		"tips6": "Khi rút tiền, {{draw_fee}}% phí rút tiền sẽ bị trừ",
		"tips6-1": "Khi rút tiền,",
		"tips6-2": "phí rút tiền sẽ bị trừ",
		"tips7": "Nếu số tiền rút của bạn nhỏ hơn hoặc bằng ₹{{solid_min_amount}}, một khoản phí cố định là ₹{{solid_feemoney}} sẽ được tính",
		"tips7-1": "Nếu số tiền rút của bạn nhỏ hơn hoặc bằng",
		"tips7-2": "một khoản phí cố định là",
		"tips7-3": "sẽ được tính",
		"WithdrawalAmount": "Số tiền rút",
		"WithdrawalAmountmsg": "Vui lòng nhập số tiền",
		"Withdrawal": "Rút",
		"WithdrawalHistory": "Lịch sử rút tiền",
		"Tipsmsg": "Đăng ký rút tiền thành công, đang chờ kết quả xét duyệt !",
		"dangerMsg1": "Vui lòng thêm thẻ ngân hàng của bạn trước",
		"dangerMsg2": "Số tiền rút không được ít hơn",
		"dangerMsg3": "Số tiền rút phải là bội số của 100",
		"dangerMsg4": "Số tiền rút không được lớn hơn",
		"dangerMsg5": "Số tiền rút không được lớn hơn số dư khả dụng",
		"dangerMsg6": "Vui lòng thêm thẻ ngân hàng của bạn trước"
	},
	"withdrawalrecord": {
		"Tiêu đề": "Ghi nhận rút tiền",
		"WatingOrder": "Đơn hàng chờ",
		"HistoryOrder": "Lịch sử đơn hàng",
		"Rupee": "Đô la",
		"Status": "Trạng thái",
		"Time": "Thời gian",
		"Nomore": "Không có thêm!"
	},
	"Version": {
		"title": "Phiên bản",
		"stableversion": "phiên bản ổn định",
		"Alreadythelatestversion": "Đã là phiên bản mới nhất",
		"Newversionfound": "Tìm thấy phiên bản mới",
		"DownloadUpdate": "Tải xuống cập nhật"
	},
	"Bonus": {
		"title": "Hoạt động thưởng",
		"ReceiveBonus": "Nhận thưởng",
		"Hi": "Xin chào",
		"tips1": "Xin chúc mừng bạn đã nhận được thưởng",
		"tips2": "Bạn cần nạp tiền lớn hơn hoặc bằng",
		"tips3": "và sau đó thưởng sẽ được tự động thêm vào số dư tài khoản của bạn",
		"Received": "Đã nhận",
		"Back": "Quay lại",
		"Rechargenow": "Nạp tiền ngay"
	},
	"ComplaintsSuggestions": {
		"title": "Khiếu nại & Đề nghị",
		"Answered": "Đã trả lời",
		"AddNew": "Thêm mới",
		"Time": "Thời gian",
		"Type": "Loại",
		"WhatsApp": "WhatsApp",
		"Description": "Mô tả",
		"Remark": "Ghi chú",
		"Waiting": "Đang chờ",
		"Suggestion": "Đề xuất",
		"Consult": "Tư vấn",
		"RechargeProblem": "Vấn đề nạp tiền",
		"WithdrawProblem": "Vấn đề rút tiền",
		"OrderProblem": "Vấn đề đặt hàng",
		"Other": "Khác",
		"Describeyourproblems": "Mô tả vấn đề của bạn",
		"WhatsApptocontactyou": "Số điện thoại WhatsApp để liên hệ với bạn",
		"submit": "Gửi",
		"err1": "WhatsApp không thể để trống!",
		"err2": "Loại không thể để trống!",
		"err3": "Mô tả không thể để trống!"
	},
	"slotMachine": {
		"title": "Máy trái cây",
		"Myblance": "Số dư của tôi",
		"Records": "Lịch sử máy trái cây",
		"titleRecord": "Lịch sử"
	},
	"bankrecharge": {
		"Orderamount": "Số tiền đơn hàng",
		"Ordernumber": "Số đơn hàng",
		"BankName": "Tên ngân hàng",
		"BankAccount": "Số tài khoản ngân hàng",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Số thẻ ngân hàng của bạn",
		"Enterthebanktransactionordernumber": "Nhập số đơn giao dịch ngân hàng",
		"submit": "Gửi",
		"Tips": "Lời khuyên",
		"tips1": "1. Hệ thống đang xử lý đơn hàng của bạn.",
		"tips2": "2. Vui lòng liên hệ với dịch vụ khách hàng nếu tài khoản chưa được ghi nhận trong thời gian dài.",
		"err1": "Vui lòng nhập số thẻ ngân hàng của bạn",
		"err2": "Vui lòng nhập số đơn giao dịch ngân hàng"
	},
	"javaCode": {
		"200": "Thành công",
		"20001": "Mã truy cập không hợp lệ",
		"20002": "Mã đăng nhập không hợp lệ",
		"20003": "Số điện thoại không hợp lệ",
		"20004": "Số điện thoại đã tồn tại!",
		"20005": "Email không hợp lệ!",
		"20006": "Email đã tồn tại!",
		"20007": "Mật khẩu không hợp lệ!",
		"20010": "Tài khoản hoặc mật khẩu không đúng!",
		"20011": "Lỗi tài khoản ngân hàng!",
		"20012": "Lỗi IFSC ngân hàng, (định dạng IFSC chuẩn) - độ dài 11, 4 ký tự đầu tiên của IFSC và ký tự thứ 5 là 0!",
		"20013": "Lỗi tên người hưởng lợi, 5 <tên <= 50!",
		"20021": "Tài khoản không tồn tại!",
		"20022": "Account is freeze!",
		"20031": "The layer is limit 10 !",
		"20032": "update relation role error !",
		"20033": "not enough balance!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Lỗi đăng ký!",
		"200082": "Tài khoản đã bị vô hiệu hóa!",
		"200091": "Thêm thẻ được giới hạn là 10!",
		"200092": "Lỗi đăng nhập quá nhiều lần với mật khẩu đăng nhập, và tài khoản bị vô hiệu hóa!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Lỗi máy chủ!",
		"Handledataerror": "Vui lòng đăng nhập!",
		"-91": "Lỗi máy chủ!",
		"-92": "Lỗi xử lý dữ liệu!",
		"-1": "Lỗi hệ thống!",
		"-2": "Lỗi tham số",
		"-3": "Không có dữ liệu",
		"-4": "Hoạt động thường xuyên trong một thời gian ngắn",
		"-5": "Hệ thống bận, vui lòng thử lại sau!",
		"-6": "Hiện có quá nhiều người, vui lòng thử lại!",
		"-7": "Lỗi dữ liệu!",
		"-8": "Lỗi mã Google!",
		"-9": "Hệ thống đang được bảo trì! Vui lòng thử lại sau!",
		"-10": "Lỗi lưu!",
		"-11": "Lỗi xóa!",
		"-12": "Lỗi cập nhật!",
		"-13": "Hoạt động không hợp lệ!",
		"-14": "Hoạt động thất bại!",
		"-15": "Hoạt động bị vô hiệu hóa!",
		"-16": "Hoạt động đã kết thúc!",
		"-17": "Lỗi tồn tại",
		"-18": "Không tồn tại!",
		"-19": "Hết hạn",
		"-20": "Đã vô hiệu hóa",
		"-21": "Mã xác minh không đúng",
		"-22": "Khoảng thời gian sửa đổi phải lớn hơn 1 ngày",
		"-23": "Mật khẩu mới không thể giống với mật khẩu gốc",
		"-100": "Lỗi tùy chỉnh!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Tổng số tiền đã mua",
		"TotalRevenue": "Tổng doanh thu",
		"AccountBalance": "Số dư tài khoản",
		"Income": "Thu nhập",
		"Transaction": "Giao dịch",
		"Myhold": "Cổ phần của tôi",
		"ProductList": "Danh sách sản phẩm",
		"Investmentperiod": "Kỳ hạn đầu tư",
		"days": "ngày",
		"Buynow": "Mua ngay",
		"Saletime": "Thời gian bán hàng",
		"Nomore": "Không còn nữa",
		"ProductDetails": "Chi tiết sản phẩm",
		"ForSale": "Bán ra",
		"Sale": "Giảm giá",
		"SoldOut": "Hết hàng",
		"ProductInformation": "Thông tin sản phẩm",
		"InvestmentPeriod": "Kỳ hạn đầu tư",
		"TotalShare": "Tổng số cổ phần",
		"ExpectedReturn": "Lợi nhuận dự kiến",
		"SaleTime": "Thời gian bán hàng",
		"PurchaseConditionsAndRules": "Điều kiện và quy định mua hàng",
		"MinimumPurchaseAmount": "Số tiền tối thiểu khi mua hàng",
		"MaximumSalesLimit": "Giới hạn bán hàng tối đa",
		"AccountBalanceMustBeGreaterThan": "Số dư tài khoản phải lớn hơn",
		"DayBetIsGreaterThan": "Cược ngày lớn hơn",
		"ProductDescription": "Mô tả sản phẩm",
		"MyHold": "Sở hữu của tôi",
		"Ordernumber": "Số đơn hàng",
		"PurchasingPrice": "Giá mua",
		"NoRecord": "Không có dữ liệu",
		"Expiredate": "Ngày đáo hạn",
		"Purchasetime": "Thời gian mua",
		"redemption": "Đổi lại",
		"redemptionTips": "Đổi lại trước hạn sẽ không tạo ra bất kỳ thu nhập nào, bạn có chắc chắn muốn đổi lại?",
		"cancel": "hủy bỏ",
		"determine": "xác định",
		"Buyin": "Mua vào",
		"Buyamount": "Số tiền mua",
		"BuyinTips": "Đổi trước sẽ không sinh ra bất kỳ thu nhập nào",
		"confirm": "Xác nhận",
		"Minimumbuy": "Số tiền mua tối thiểu",
		"Maturity": "Đến hạn",
		"EarlyRedemption": "Đổi trước",
		"Redemptiontime": "Thời gian đổi",
		"Amountofincome": "Số tiền thu nhập",
		"prompt": "Nhắc nhở"
	},
	"HelpHa": {
		"Help": "Trợ giúp",
		"HelpCenter": "Trung tâm trợ giúp",
		"text": "Có câu hỏi gì không? Hãy xem ở đây"
	},
	"SetingHa": {
		"Seting": "Cài đặt",
		"SetingCenter": "Trung tâm cài đặt",
		"text": "Nhận nhiều phần thưởng hơn"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"home.Products&Services": "Sản phẩm&Dịch vụ",
	"wallet.title": "Ví của tôi",
	"wallet.MyBalance": "Số dư của tôi",
	"wallet.MyBank": "Thẻ ngân hàng của tôi",
	"wallet.RechargeRecords": "Hồ sơ nạp tiền",
	"wallet.WithdrawalRecords": "Hồ sơ rút tiền",
	"Rechargepage.Tips": "Mẹo: Phần thưởng cho lần nạp tiền đầu tiên là {{firstRPR*100}}%",
	"Rechargepage.Tips_1": "Gợi ý ấm áp: Phần thưởng nạp tiền lần đầu là số tiền nạp tiền",
	"Rechargepage.Tips_2": "% .",
	"Rechargepage.Minimum": "Tối thiểu",
	"withdrawal.Google_Code": "Mã xác minh Google",
	"withdrawalrecord.title": "Hồ sơ rút tiền",
	"index.index.gayb21": "Đăng ký nhận thưởng:",
	"index.index.72y628": "3 lý do để chọn GoGoBit",
	"index.index.z6022y": "EPOCH EPT tuân thủ một trong những tiêu chuẩn bảo mật cao nhất trong ngành. Chúng tôi lưu trữ 100% tiền điện tử trong kho lạnh. Chúng tôi đảm bảo tỷ lệ dự trữ tối thiểu 1: 1 cho quỹ của khách hàng. Quỹ 300 triệu đô la của chúng tôi cung cấp thêm sự bảo vệ chống lại các mối đe dọa an ninh.",
	"index.index.574982": "1. Đăng ký EPOCH EPT MIỄN PHÍ",
	"index.index.qdi1g4": "2. Hoàn thành xác minh Google",
	"index.index.weko28": "3. Nạp tiền vào tài khoản GoGoBIT của bạn ngay lập tức",
	"index.index.sgkb35": "Chương trình mời EPOCH EPT",
	"index.index.2u17nf": "Hoàn thành bất kỳ lời mời hợp lệ nào để nhận được giá trị trung bình lên tới 5,59 USDT. Phần thưởng tối đa cho 100 người được mời là",
	"index.index.e8is4a": "Chương trình giới thiệu GoGoBIT",
	"index.index.47uevl": "Nhiệm vụ giao dịch GoGoBIT",
	"index.index.2516gq": "EPOCH EPT, Inc. đã nhận được giấy phép hoạt động kinh doanh tiền ảo từ Dịch vụ Tài chính Tiểu bang New York.",
	"invest.index.3aqi3d": "Số dư tài khoản:",
	"invest.index.ubk1j4": "Ngừng đầu tư!",
  "wallet.index.645d70":"",
	"invest.prize1.4733u6": "Danh mục đầu tư:",
	"invest.prize1.t55a16": "Tổng cộng:",
	"login.login.09m52q": "Tôi đã đọc và đồng ý với GoGoBit",
	"login.login.045csy": "Tên người dùng",
	"login.login.r7rbm5": "Hãy đồng ý với thỏa thuận!",
	"rewards.activityb.4nvrq9": "Khi người được mời hoàn thành khoản tiền gửi đầu tiên, nó được tính là một lời mời hợp lệ. Người mời sẽ nhận được tiền thưởng lời mời cho mỗi lời mời hợp lệ. Mức thưởng lời mời đến từ 2USDT 1 Lời mời hợp lệ 559USDT 100 Lời mời hợp lệ.",
	"rewards.activityb.t10k6e": "Khách mời sẽ chia sẻ 20% phí giao dịch cho mỗi người được mời LV.1. Người mời sẽ chia sẻ 10% phí giao dịch của mỗi người được mời LV.2. Tiền thưởng tối đa cho phần thưởng phí giao dịch của người được mời là đô la Mỹ không giới hạn.",
	"rewards.activityb.3d6850": "LV.1 Số lượng khách mời hợp lệ",
	"rewards.activityb.lvxo74": "LV.2 Số lượng khách mời hợp lệ",
	"rewards.activityb.zj26f9": "V5-0602 (1) Trần Quốc Tuấn",
	"rewards.activityb.dkgxr5": "LV.2 Tổng số khách mời",
	"rewards.activityb.xjo077": "Phần thưởng giới thiệu:",
	"rewards.activityb.k8wv6v": "Tham gia EPOCH EPT và nhận liên kết giới thiệu của bạn. Người dùng đăng ký qua liên kết này sẽ được thưởng.",
	"rewards.activityb.4slpzg": "Sau khi bạn bè của bạn hoàn thành khoản tiền gửi đầu tiên và hoàn thành khối lượng giao dịch mong muốn, bạn sẽ nhận được phần thưởng giới thiệu có giá trị trung bình là 5,59 đô la. Phần thưởng có thể lên tới 559 USD.",
	"rewards.index.993jwg": "Sao chép thành công",
	"rewards.index.7141ft": "Trình duyệt không hỗ trợ sao chép tự động",
	"wallet.index.9uz2gn": "Phí mạng 0 USDT",
	"wallet.index.ych3e1": "Thực tế đến sổ sách:",
	"wallet.index.3v558d": "Mẹo: Rút tiền dưới 20 USD, phí rút tiền là 1 USD, rút tiền trên 20 USD, phí rút tiền là 3%.",
	"wallet.index.255e30": "1. Số tiền rút tối đa hàng ngày là $8,000,000",
	"wallet.index.3g68m8": "2. Số tiền rút tối thiểu là $5 và số tiền rút tối đa là $8,000,000",
	"wallet.index.c6x3ti": "3. Thời gian rút tiền là 00:00-23:59, vui lòng rút tiền trong thời gian quy định",
	"wallet.index.h94rlg": "Tối thiểu 5",
	"wallet.index.167862": "Vui lòng nhập địa chỉ ví!",
	"wallet.index.pi869h": "Vui lòng nhập số tiền rút tiền!",
	"wallet.index.ysa85i": "Số tiền rút tối đa là",
	"components.changepass.68j9kh": "Bạn đang cố gắng đặt lại mật khẩu của mình.",
	"components.changepass.ouxtp2": "Mật khẩu gốc",
	"components.languagebox.8no849": "Nhật Bản",
	"components.languagebox.oif20b": "Trung Quốc truyền thống",
	"components.mycode.xyqr66": "Nhận tối đa 5000 USDT khi hoàn thành nhiệm vụ",
	"components.mymenu.277ss7": "ID người dùng:",
	"components.mymenu.74l443": "Thời gian đăng ký:",
	"components.myposter.36rw5e": "Mã giới thiệu:",
	"components.mytabbar.zw2bh2": "Trang chủ",
	"components.mytabbar.8x6c28": "Xu hướng",
	"components.mytabbar.28s7qr": "Đầu tư",
	"components.mytabbar.4r75d9": "Phần thưởng",
	"components.xieyi.zqk7pe": "font-size: 16px;  font-family: Tống thể",
	"components.help.6gpud8": "Có 2 mô hình đầu tư:",
	"components.help.5qrq36": "Mỗi kỳ kéo dài 1 phút, chỉ cung cấp một khoản đầu tư đôi. Kết quả được tính bằng chữ số cuối cùng của giá đóng cửa của 1 phút K Line trên Binance BTC/USDT Spot Trade.",
	"components.help.isl7r1": ": Mỗi kỳ kéo dài 5 phút. Phiên bản Pro mở khóa toàn bộ phương thức đầu tư của EPOCH EPT. Kết quả được tính bằng chữ số cuối cùng của giá đóng cửa 5 phút của dòng K trên Binance BTC/USDT Spot Trade.",
	"components.help.eai3nm": "Lợi tức đầu tư:",
	"components.help.35xg82": "Single số:",
	"components.help.mpfg0s": "Thu nhập: Số tiền đầu tư X 9.4",
	"components.help.v2ziib": "Odd số:",
	"components.help.vfzxor": "Thu nhập: Số tiền đầu tư X 1,94",
	"components.help.73168b": "Even số:",
	"components.help.76wgv1": "Big số:",
	"components.help.05ed33": "Số nhỏ:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Vui lòng nhập mật khẩu gốc!",
	"components.changepass.xme6my": "Vui lòng nhập mật khẩu mới!",
	"components.changepass.u434f8": "Hai lần mật khẩu không đồng nhất!",
	"components.changepass.574790": "Sửa đổi thành công!",
	"invest.index.9y8wg0": "Giải thưởng",
	"invest.index.ql5nc1": "Giải thưởng",
	"login.login.76451t": "Trao đổi chênh lệch A định lượng giao dịch!",
	"login.login.915p36": "Quản lý tài chính số 1 thế giới",
	"login.login.sen863": "Hello hạ cánh hay hơn",
	"login.login.nz6q9x": "Đăng nhập điện thoại",
	"login.login.w7p592": "Đăng nhập hộp thư",
	"login.login.g9y91k": "Số điện thoại",
	"login.login.456gbb": "Số hộp thư",
	"login.login.5ly8m8": "Quốc gia",
	"login.login.69h472": "Vui lòng chọn quốc gia",
	"login.login.i4p378": "Số",
	"login.login.fl82xx": "Ví dụ:",
	"wallet.index.urkoub": "Nạp mã QR",
	"wallet.index.25cocl": "Sao chép địa chỉ",
	"wallet.index.t35fvg": "1. Thời gian rút tiền: Trong vòng 24 giờ từ 1 đến 7 tuần, chủ nhật hạch toán thuế khu vực!",
	"wallet.index.68xx61": "2. Rút tiền nội tệ trên toàn thế giới chậm nhất 48 giờ, phí thủ tục rút tiền là 8% (bao gồm thuế khu vực).",
	"wallet.index.4ws3w9": "3. Rút tiền bằng USDT đến muộn nhất 24 giờ với phí xử lý 2% (đã bao gồm thuế khu vực)",
	"wallet.index.9b7wq8": "4. Rút tiền tối thiểu 12USDT, rút tiền tối đa 3000USDT.",
	"wallet.index.23y92a": "Xin vui lòng biết",
	"wallet.index.pl5473": "Tối thiểu 5",
	"wallet.index.nb3u72": "Thông tin thẻ ngân hàng",
	"wallet.index.842so4": "Thêm số thẻ ngân hàng",
	"wallet.index.86j717": "Thêm ngân hàng sở hữu",
	"wallet.index.763d7x": "Thêm tên thẻ ngân hàng",
	"wallet.index.50j237": "Ngân hàng sở hữu",
	"wallet.index.hb6lun": "Tên thẻ ngân hàng",
	"wallet.index.62y2jc": "Số thẻ ngân hàng",
	"login.register.666hp4": "Al Giao dịch định lượng chênh lệch",
	"login.register.9298b8": "Mời bạn bè",
	"login.register.375g14": "Người mới lần đầu tham gia",
	"login.register.01922f": "Có thể thực hiện 1 lần",
	"login.register.527o4q": "Thu nhập hàng ngày AI",
	"login.register.c563gt": "Đăng ký hộp thư",
	"login.register.2ls856": "Định dạng số điện thoại di động sai!",
	"login.register.4nu77e": "Số hộp thư bị định dạng sai!",
	"lang.zh.pu623z": "Một lần thực hiện",
	"lang.zh.3hv80b": "Quyền lực của USDT!",
	"lang.zh.9tli5f": "Dòng nhắc: dấu kiểm tra thấp",
	"lang.zh.62e882": "Đồng đô la.",
	"lang.zh.m3818q": "Đồng đô la, rút tiền.",
	"lang.zh.xs2f10": "Chúng ta sẽ không bao giờ lấy lại được bất cứ lợi nhuận nào."
}