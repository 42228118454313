module.exports = {
	"Language": {
		"title": "Languaje"
	},
	"Noproductsforsale": "No products for sale",
	"rechargePresentationText0_1": "Recharge 1000 get  ",
	"rechargePresentationText0_2": ", Recharge 10000 get ",
	"rechargePresentationText0_3": ", and so on... Click Me !",
	"rechargePresentationText0": "If you recharge now, you will get ",
	"rechargePresentationText1": "% of the recharge amount, limited time event, while stock lasts",
	"rechargePresentationText2": "Reward End Time, CountDown !",
	"Online_Chat": "Charla en línea",
	"gobindgoogle": "Por favor, deseche su Autenticador de Google",
	"Order": "orden",
	"Record": "Grabar",
	"Assist": "Asistencia",
	"Market": "Mercado",
	"History": "Historia",
	"Confirm": "Confirmar",
	"close": "cerrar",
	"Cancel": "Cancelar",
	"Previous": "Anterior",
	"Next": "Proximo",
	"Copy": "Copiar",
	"Nomoredata": "No mas datos",
	"moneychar": "COP: ",
	"DownloadApp": " Descargar la apicacion ahora",
	"Yes": "Si",
	"No": "No",
	"Wating": "Esperando",
	"Recharge": "Recarga",
	"Withdrawal": "Retiro",
	"Success": "Exito",
	"OrderSuccessful": "Pedido exitoso",
	"Handledataerror": "Manejar error datos",
	"Copysuccess": "Copiado exitoso",
	"MyBalanceLoding": "Mi equilibrio se aloja",
	"UPDATE": "Actualizar",
	"New_version_available": "Nueva versión disponible",
	"Please,update_app_to_new_version": "Por favor, actualice la aplicación a la nueva versión",
	"tabbar": {
		"Home": "Hogar",
		"AndarBahar": "Andar Bahar",
		"Invest": "Investir",
		"Bonus": "Bonificacion",
		"Mine": "Mia",
		"Trending": "Tendencias",
		"Wallet": "Billetera",
		"Rewards": "Recompensas"
	},
	"homeTop": {
		"Sign_Up": "Registrarse",
		"Sign_up_for_rewards": "Regístrate para recompensas",
		"up_to": "hasta",
		"TOTAL_ASSETS": "TOTAL DE ACTIVOS",
		"User_ID": "ID de usuario",
		"Registration_time": "Tiempo de registro"
	},
	"PieChart": {
		"BIG&SMALL": "GRANDE Y PEQUEÑO",
		"NUMBER": "NÚMERO",
		"ODD&EVEN": "IMPAR Y PAR",
		"ALL_NUMBER_TRENDING": "TENDENCIAS DE TODOS LOS NÚMEROS",
		"BIG&SMALL_NUMBER_TRENDING": "TENDENCIAS DE GRANDES Y PEQUEÑOS NÚMEROS",
		"ODD&EVEN_NUMBER_TRENDING": "TENDENCIAS DE NÚMEROS IMPARES Y PARES"
	},
	"register": {
		"tips_3": "Si no recibió el código en su bandeja de entrada, verifique su carpeta de correo no deseado.",
		"receiveCode": "¿No recibiste el código?",
		"receiveCode_title": "Código enviado a tu correo electrónico. Si no recibiste el código después de varios intentos, por favor intenta lo siguiente:",
		"receiveCode_text1": "1. Revisa tu carpeta de spam/correo no deseado.",
		"receiveCode_text2": "2. Asegúrate de que tu dirección de correo electrónico sea correcta.",
		"receiveCode_text3": "3. El mensaje puede tardar unos minutos en llegar. Intenta de nuevo después de 20 minutos.",
		"receiveCode_text4": "4. Si esta dirección de correo electrónico ya existe, no te enviaremos un código de autenticación. Por favor inicia sesión o restablece tu contraseña.",
		"Sign_up_for_Rewards": "Registrarse para Recompensas",
		"Email_Verification": "Verificación de correo electrónico",
		"tips": "Ingrese el código de verificación de 6 dígitos que se envió a {{formData.email}}. El código es válido por 30 minutos.",
		"tips_1": "Ingrese el código de verificación de 6 dígitos que se envió a ",
		"tips_2": "El código es válido por 30 minutos.",
		"Resend_code_in": "Reenviar código en",
		"Sent_again": "Enviar de nuevo",
		"CREATE_ACCOUNT": "CREAR CUENTA",
		"inviteCodecheck": "¡El código de invitación no puede estar vacío!",
		"Mobile": "Numero movil",
		"Mobilemsg": "Numero movil requerido",
		"Password": "Contraseña",
		"Passwordmsg": "Contraseñamsg",
		"OTPCode": "Codigo OTP",
		"OTPCodemsg": "Codigo OTP requerido",
		"SendOTP": "Enviar OTP",
		"Email": "Correo",
		"Emailmsg": "correo requerido",
		"Invitecode": "codigo invitacion",
		"Invitecodemsg": "Codigo de invitacion requerido",
		"emailFormatter": "Por favor ingrese correctamente el correo electronico",
		"usernameFormatter": "El nombre de usuario debe ser un cracater y un numero y el rango es 6<=length<=20",
		"phoneFormatter": "Ingrese el numero de telefono movil de 10 digitos",
		"msgsuccess": "Registro exitoso",
		"msgerr": "No registrarse",
		"errorphonecheck": "El numero de telefono movil no puede estar vacio!",
		"phonecheck": "Ingrese el numero de telefono de 10 digitos!",
		"imgcodecheck": "El codigo OTP no puede estar vacio!"
	},
	"login": {
		"Welcome_back": "Bienvenido de nuevo",
		"tips": "He leído y acepto los Términos de servicio y la Política de privacidad de {{this.$LogoName}}",
		"tips_1": "He leído y acepto los",
		"tips_2": "Términos de servicio",
		"tips_3": "y la",
		"tips_4": "Política de privacidad",
		"Continue_with_Google": "Continuar con Google",
		"Continue_with_Apple": "Continuar con Apple",
		"text": "¿Todavía no tienes una cuenta?",
		"text2": "¿Ya tienes una cuenta?",
		"Login": "Acceso",
		"Register": "Registrarse",
		"username": "Numero de usuario o numero de telefono",
		"password": "Contraseña",
		"imgcode": "Codigo imagen",
		"BackHome": "Volver a casa",
		"Forgetpwd": "Contraseña olvidada"
	},
	"forgot": {
		"title": "Contraseña olvidada",
		"msgsuccess": "Restablecimiento de contraseña completa",
		"newpassword": "Nueva contraseña",
		"newpasswordmsg": "Nueva contraseña requerida",
		"Continue": "Continuar"
	},
	"home": {
		"Revolutionize_Trading": "Revolutionar el trading",
		"Shape_the_Future": "Dar forma al futuro",
		"content0": "¡Experimentemos juntos la nueva era del intercambio de criptomonedas!",
		"3_Reasons_for_Choosing": "3 razones para elegirnos",
		"Innovative_Trading_Features": "Características innovadoras de trading",
		"content1": "Experiencia revolucionaria de trading que es diferente a cualquier cosa disponible en el mercado actualmente. Diseñada para brindarte una ventaja competitiva con características de trading innovadoras, medidas de seguridad avanzadas y velocidades de transacción ultrarrápidas.",
		"Lower_Fees_and_Better_Rewards": "Tarifas más bajas y mejores recompensas",
		"content2": "Nuestra interfaz hace que el trading sea sencillo para principiantes. Puedes crear una cuenta de forma gratuita, comerciar con cripto con bajas tarifas de transacción desde nuestro mercado. Además, puedes compartir hasta el 20% de las tarifas de transacción de intercambio para todos los usuarios que invites.",
		"Institutional-grade_Security": "Seguridad de grado institucional",
		"content3": "cumple con uno de los estándares de seguridad más altos de la industria. También almacenamos el 100% de tus cripto en almacenamiento en frío. Garantizamos una relación de reserva de al menos 1:1 de los fondos de nuestros clientes. Nuestro fondo de 300M USDT brinda una capa adicional de protección contra amenazas de seguridad.",
		"Start_investing_in_as_little_as_5_minutes": "Comienza a invertir en tan solo 5 minutos",
		"content4": " Regístrate en {{this.$LogoNames.toUpperCase()}} de forma gratuita",
		"content4_1": "Regístrate en ",
		"content4_2": " de forma gratuita",
		"Complete_google_authentication": "Completa la autenticación de Google",
		"content5": "Ingresa y verifica tu dirección de correo electrónico para empezar. No hay cargos por abrir una cuenta.",
		"content7": "Desbloquea la funcionalidad completa verificando tu autenticador de Google en menos de 5 minutos.",
		"content8": "Fondea tu cuenta de {{this.$LogoNames.toUpperCase()}} al instante",
		"content8_1": "Fondea tu cuenta de",
		"content8_2": "al instante",
		"content9": "Deposita USDT a través de una red selecta y accede instantáneamente a tus fondos. No necesitas esperar de 3 a 5 días para empezar a hacer trading.",
		"Start_building_your_crypto_portfolio": "Empieza a construir tu portafolio de criptomonedas",
		"Invitation_Program": "Programa de invitación",
		"Referral_Program": "Programa de referencia",
		"content10": "Completa cualquier invitación válida y recibe una recompensa de invitación con un valor promedio de 10 USDT. La recompensa máxima para una sola persona es de",
		"content11": "Los invitadores pueden obtener una recompensa proporcional al volumen de transacciones generado por sus invitados. La recompensa máxima para una invitación válida es de Unlimit USDT.",
		"TRADING_TASKS": "TAREAS DE TRADING",
		"content12": "Los usuarios recibirán recompensas en USDT al hacer trading, hay diferentes tareas de trading para cada modo de trading. Las recompensas se distribuirán instantáneamente",
		"Trade_anytime_anywhere": "Haz trading en cualquier momento y lugar",
		"content13": "USA, Inc. tiene licencia para realizar Actividades de Negociación de Moneda Virtual por el Departamento de Servicios Financieros del Estado de Nueva York.",
		"USA_Inc": "USA, Inc",
		"content6": "Descarga nuestra aplicación para estar conectado 24/7",
		"Legal&Disclosures": "Legal y divulgaciones",
		"Regulatory_License": "Licencia regulatoria",
		"AML/KYC_Policies": "Políticas AML/KYC",
		"Privacy_Policy": "Política de privacidad",
		"Terms_of_Use": "Términos de uso",
		"Legal_Statement": "Declaración legal",
		"Risk_Disclosure": "Divulgación de riesgos",
		"About_Us": "Sobre nosotros",
		"Proof_of_Reserves": "Prueba de reservas",
		"Twitter": "Twitter",
		"Bitget_Token": "Token Bitget",
		"Careers": "Carreras",
		"Company": "Empresa",
		"Support_Service": "Servicio de soporte",
		"title": "Abrir con la aplicacion",
		"titlebtn": "Descargar",
		"Balance": "Balance",
		"ID": "Identificacion",
		"Recharge": "Recargar",
		"Withdraw": "Retirar",
		"Bonus": "Bonificacion",
		"Checkin": "Registrarse",
		"Eventclosed": "Evento cerrado"
	},
	"Checkin": {
		"Checkin": "Registrarse",
		"CheckinList": "Registrase en la lista"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Regla",
		"Record": "Registro",
		"Records": "Andar Bahar  Registro",
		"MyOrder": "Mi pedido",
		"more": "mas",
		"Period": "Periodo",
		"Order": "Pedido",
		"Buy": "Comprar",
		"Result": "Resultado",
		"Profit": "Lucro",
		"AndarBaharRule": "Andar Bahar reglas",
		"CountDown": "Cuenta regresiva",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "resultado",
		"ok": "ok",
		"start": "Comienzo",
		"Point": "Punto",
		"ContractPoint": "Punto de contrato",
		"Number": "Numero",
		"Delivery": "Entrega",
		"Fee": "Tarifa",
		"Amount": "Monto"
	},
	"pokerOrder": {
		"OrderRecord": "Registrar pedido",
		"WatingOrder": "Orden de comer",
		"HistoryOrder": "Orden historia",
		"Nomoredata": "No mas datos",
		"Buy": "Comprar",
		"Result": "Resultado",
		"Time": "Tiempo",
		"Amount": "Monto",
		"Fee": "Tarifa",
		"Profit": "Lucro"
	},
	"pokerRecord": {
		"title": "Andar Bahar Registro",
		"Period": "Periodo",
		"Result": "Resultado",
		"OpenTime": "AbrirTiempo",
		"Nomore": "No mas"
	},
	"Google2FA": {
		"title": "Autenticación 2FA de Google",
		"Authenticator_Instructions": "Instrucciones del autenticador",
		"Authenticator_Status": "Estado del autenticador",
		"Google_Authenticator": "Google Autenticador",
		"Download_Google_Authenticator_App": "Descargar la aplicación de autenticación de Google",
		"App_Store": "Tienda de aplicaciones",
		"Google_Play": "juego de google",
		"Next": "Próximo",
		"Backup_key": "Clave de respaldo",
		"Backup_key_text": "Guarde esta clave de respaldo en un lugar seguro. Esta clave le permitirá recuperar su autenticador en caso de que pierda su teléfono. De lo contrario, para restablecer su Autenticador tendrá que consultar con el servicio de atención al cliente.",
		"Verifiaction": "Verificación",
		"Verifiaction_text": "Introduce el código de verificación de Google",
		"Google_verification_code": "Código de verificación de Google",
		"contentlist1": "1.Registre la clave de copia de seguridad de 16 dígitos de +this.$LogoName+ y guárdela en un lugar seguro.",
		"contentlist1_1": "1.Registre la clave de copia de seguridad de 16 dígitos de",
		"contentlist1_2": "y guárdela en un lugar seguro.",
		"contentlist2": "2.Abra el Autenticador de Google.",
		"contentlist3": "3.Agregue una cuenta +this.$LogoName+ e ingrese la clave de 16 dígitos copiada.",
		"contentlist3_1": "3.Agregue una cuenta",
		"contentlist3_2": "e ingrese la clave de 16 dígitos copiada.",
		"contentlist4": "4. Verá un código de verificación de 6 dígitos en la pantalla.",
		"contentlist5": "5.Copie el código de 6 dígitos y péguelo en +this.$LogoName+ .",
		"contentlist5_1": "5.Copie el código de 6 dígitos y péguelo en",
		"contentlist5_2": ".",
		"contentlist6": "6. Luego, su autenticador se vinculará con éxito."
	},
	"win": {
		"Live_Price": "Precio en vivo",
		"title1": "Los resultados de la inversión se calculan por el último dígito del intercambio spot de Binance BTC/USDT correspondiente a la hora UTC. Los inversores pueden verificar los resultados en Binance en cualquier momento para garantizar la equidad y seguridad de la inversión.",
		"title": "Hay 2 modos en la inversión",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "cada ronda dura 1 minuto. El modo Lite solo proporciona inversión en números pares e impares. El resultado se calcula por el último dígito del resultado de cierre del intervalo de intercambio de spot de 1M de Binance BTC/USDT.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Cada ronda dura 5 minutos. El modo Pro desbloquea el método completo de inversión de EPOCH EPT. El resultado se calcula por el último dígito del resultado de cierre del intervalo de 5 minutos del comercio de spot de Binance BTC/USDT.",
		"5M_Mode_text_1": "Cada ronda dura 5 minutos. El modo Pro desbloquea el método completo de inversión de ",
		"5M_Mode_text_2": ". El resultado se calcula por el último dígito del resultado de cierre del intervalo de 5 minutos del comercio de spot de Binance BTC/USDT.",
		"Investment_Guide": "Guía de inversión",
		"Investment_Rules": "Reglas de inversión",
		"title2": "¿Cómo demostrar que el resultado es justo?",
		"Investment_Info": "Información de inversión",
		"3M_Mode": "Modo 3M",
		"3M_Mode_text": "Cada ronda dura 3 minutos. El resultado se calcula mediante el último dígito del resultado de cierre del trading de spot de Binance BTC/USDT en un intervalo de 3M.",
		"Investment_return": "Retorno de inversión",
		"Number": "Número",
		"Profit_Invested_amount": "Beneficio: cantidad invertida",
		"guide1": "Elija un modo de tiempo.",
		"guide2": "Ajuste la cantidad de inversión.",
		"guide3": "Elija los números en los que desea invertir.",
		"guide4": "Verifique su portafolio y haga clic en invertir.",
		"NEXT": "SIGUIENTE",
		"Invest_Amount": "Cantidad de inversión",
		"Total": "Total",
		"INVEST": "INVERTIR",
		"Portfolio": "Portafolio",
		"Invest": "Invertir",
		"BINANCE": "BINANCE",
		"Bitcoin_Price": "Precio de Bitcoin",
		"InvestmentRecord": "Registro de inversión",
		"Best_Assistant": "mejor asistente",
		"last_100_rounds": "(últimas 100 rondas)",
		"What_do_these_data_mean": "Que significan estos datos",
		"Missing": "Desaparecido",
		"Missing_text": "Eso significa el número de rondas desde la última ronda que produjo este número.",
		"Frequency": "Frecuencia",
		"Frequency_text": "Se refiere a los tiempos del número correspondiente que aparece en las últimas 100 rondas.",
		"Max_Continued": "Continuación máxima",
		"Max_Continued_text": "Se refiere al período máximo de aparición consecutiva del número correspondiente en las últimas 100 rondas.",
		"Rule": "Regla",
		"Trend": "Tendencia",
		"MyBalance": "Mi Balance",
		"JoinGreen": "Entrar Verde",
		"JoinRed": "Entrar Rojo",
		"JoinViolet": "Entrar Violeta",
		"Parity": "Paridad",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Esmeralda",
		"Record": "Registro",
		"more": "Mas",
		"Period": "Periodo",
		"Price": "Precio",
		"Result": "Resultado",
		"My": "Mi",
		"Order": "Pedido",
		"Buy": "Comprar",
		"Profit": "Lucro",
		"Green": "Verde",
		"Red": "Rojo",
		"Violet": "Violeta",
		"tips": "La tarifa de transacion es {bet_fee*100}% del monto del pedido",
		"tips1": "La tarifa de transacion es ",
		"tips2": "Del monto del pedido ",
		"OrderAmount": "Total de la orden",
		"Quantity": "Cantidad",
		"TransactionFee": "Tarifa de transacion",
		"TotalAmount": "Cantidad total",
		"PresalePolicy": "Politica de preventa",
		"Iagree": "Estoy de acuerdo",
		"err1": "Estoy de acuerdo"
	},
	"historyPeriods": {
		"Orderrecord": "Registro de pedidos",
		"record": "pedidos"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Orden de comer",
		"HistoryOrder": "Orden de historia"
	},
	"Activity": {
		"added_to_account_balance": "Agregado al saldo de la cuenta.",
		"contentText": "Cuando los invitados completen su primer depósito, se considerará una invitación válida. El invitador recibirá un bono de invitación por cada invitación válida. Los niveles de bonificación de invitación van desde {{aaaaaa}} USDT para 1 invitación válida hasta {{aaaaaa}} USDT para 500 invitaciones válidas.",
		"contentText_1": "Cuando los invitados completen su primer depósito, se considerará una invitación válida. El invitador recibirá un bono de invitación por cada invitación válida. Los niveles de bonificación de invitación van desde",
		"contentText_2": "para 1 invitación válida hasta",
		"contentText_3": " para 500 invitaciones válidas",
		"contentText_3_1": " para ",
		"contentText_3_2": " invitaciones válidas",
		"contentText2": "Los invitadores compartirán el {{LV.1%}} de la comisión de negociación de cada volumen de negociación de los invitados de nivel 1. Los invitadores compartirán el {{LV.2%}} de la comisión de negociación de cada volumen de negociación de los invitados de nivel 2. El bono máximo para el volumen de negociación de los invitados es ilimitado en USDT.",
		"contentText2_1": "Los invitadores compartirán",
		"contentText2_2": "de la comisión de negociación de cada volumen de negociación de los invitados de nivel 1. Los invitadores compartirán",
		"contentText2_3": "de la comisión de negociación de cada volumen de negociación de los invitados de nivel 2. El bono máximo para el volumen de negociación de los invitados es ilimitado en USDT.",
		"My_Rewards_History": "Mi historial de recompensas",
		"Cumulative_Single_Number_Trading_Volumn": "Volumen de trading acumulado de número único",
		"Cumulative_ODD_Number_Trading_Volumn": "Volumen de trading acumulado de número impar",
		"Cumulative_EVEN_Number_Trading_Volumn": "Volumen de trading acumulado de número par",
		"Cumulative_BIG_Number_Trading_Volumn": "Volumen de trading acumulado de número grande",
		"Cumulative_SMALL_Number_Trading_Volumn": "Volumen de trading acumulado de número pequeño",
		"Advanced_Tasks": "Tareas Avanzadas",
		"Invite_Friends_and_Earn_Up_to": "Invita a tus amigos y gana hasta",
		"Referral_Code": "Código de referido",
		"Referral_Link": "Enlace de referido",
		"INVITE_FRIENDS": "INVITA A TUS AMIGOS",
		"Complete_tasks_to_earn_up_to": "Completa tareas para ganar hasta",
		"MY_REWARDS": "MIS RECOMPENSAS",
		"SHARE": "COMPARTIR",
		"Earn_with_your_friends_together": "Gana junto a tus amigos",
		"Get_your_link": "Obtén tu enlace",
		"Claim_your_rewards": "Reclama tus recompensas",
		"Earn_together": "Ganen juntos",
		"contentlist0": "Únase a {{gogobit}} y obtenga su enlace de referencia. Los usuarios que se registren a través de este enlace recibirán recompensas.",
		"contentlist0_1": "Únase ",
		"contentlist0_2": " y obtenga su enlace de referencia. Los usuarios que se registren a través de este enlace recibirán recompensas.",
		"contentlist1": "Recibirá una recompensa de referencia con un valor promedio de {{XX}} USDT después de que sus amigos hagan su primer depósito y completen el volumen de negociación requerido. Las recompensas pueden ser de hasta {{XX}} USDT.",
		"contentlist1_1": "Recibirá una recompensa de referencia con un valor promedio de ",
		"contentlist1_2": " USDT después de que sus amigos hagan su primer depósito y completen el volumen de negociación requerido. Las recompensas pueden ser de hasta ",
		"contentlist1_3": " USDT.",
		"contentlist2": "Después de que tu amigo cree una cuenta, podrá reclamar una bonificación de negociación de hasta 5,050 USDT.",
		"Referral_Rewards": "Recompensas por referidos",
		"Detail_Rules": "Reglas Detalladas",
		"Invitation_Bonus": "Bono de Invitación",
		"Up_to": "Hasta",
		"content0": "Completa cualquier invitación válida y recibe un bono de invitación con un valor promedio de 10 USDT. El bono máximo para una sola persona es",
		"Trading_Bonus": "Bono de Trading",
		"content1": "Los invitadores pueden obtener un bono proporcional al volumen de transacción generado por sus invitados. El bono máximo para una invitación válida es",
		"My_Referral_History": "Mi Historial de Referidos",
		"Total_Invitees": "Total de Invitados",
		"Valid_Invitees": "Invitados Válidos",
		"Cumulative_Invitees": "Invitados Acumulados",
		"Trading_Volumn": "Volumen de Trading",
		"Detail_Rules_title": "SORTEO DIARIO de 888 USDT",
		"Detail_Rules_title1": "1. Los datos de negociación de futuros del día se calcularán al día siguiente.",
		"Detail_Rules_title2": "2. Los usuarios que realicen un volumen de negociación de futuros de 200,000 USDT o más en el día tendrán la oportunidad de participar en el sorteo una vez por el premio de 888 USDT.",
		"CLAIM_NOW": "RECLAMAR AHORA",
		"INVEST_NOW": "INVERTIR AHORA",
		"title": "Billetera de bonificacion",
		"BonusBalance": "Saldo de bonificacion",
		"Withdrawtobalance": "Retirar para equilibrar",
		"BonusList": "Lista de bonificacion",
		"Bonuswithdrawtobalance": "Retiro de bonificacion para equilibrar",
		"WithdrawalAmount": "Cantidad de retiro",
		"Pleaseentertheamount": "Por favor ingrese la cantidad",
		"err1": "El monto del retiro no debe ser 0",
		"success": "Transferencia exitosa"
	},
	"Refer": {
		"title": "Mi referido",
		"ReferTotalBonus": "Recomiende la bonificacion total",
		"InviteFriends": "Invite amigos",
		"Level1": "Nivel 1",
		"Level2": "Nivel 2",
		"TotalPeople": "Total Personas",
		"Bonus": "Bonificacion",
		"Myinvitationlink": "mi enlace de invitacion"
	},
	"Task": {
		"InviteBonusToday": "Bono de invitacion hoy",
		"bonusrecord": "Registro de bono",
		"hint1": "1. Los datos se actualizan cada 10-30 minutos !",
		"hint2": "2. Solo se cuentan aquellos que se registraron los 3 ultimos dias y completaron la recarga hoy !",
		"Invite": "Invitacion",
		"friendstorecharge": "Recarga de amigos",
		"Earn": "Ganar",
		"Completed": "Completado",
		"ExpireTime": "Termino el tiempo"
	},
	"Invited": {
		"title": "Registrar bonificacion",
		"InviteTotalBonus": "Invitacion total de bonificacion",
		"InviteTime": "Invitacion tiempo",
		"Bonus": "Bonificacion",
		"NoRecored": "No grabado"
	},
	"mine": {
		"title": "Centro personal",
		"InviteCode": "Codigo invitacion",
		"AccountBalance": "Saldo de la cuenta",
		"BonusBalance": "Saldo de bonificacion",
		"Wallet": "Cartera",
		"Bank": "Banco",
		"Invite": "Invitacion",
		"Refer": "Referir",
		"CustomerService": "Servicio al cliente",
		"ChangePassword": "Cambiar la contraseña",
		"Activity": "Actividad",
		"PresalePolicy": "Politica preventa",
		"DownloadApp": "Descarga la aplicacion",
		"AppVersion": "Version aplicacion",
		"Logout": "Cerrar sesion",
		"Logouttips": "Esta seguro que quiere cerrar sesion ?"
	},
	"App": {
		"title": "Descargue la aplicacion",
		"DownloadforAndroid": "Descarga para android"
	},
	"Presale": {
		"title": "Politica de preventa"
	},
	"Account": {
		"title2": "Establecer nueva contraseña",
		"title2_text": "Establezca una nueva contraseña para su cuenta de Google. Puede iniciar sesión en su cuenta de Google usando una contraseña.",
		"title_text": "Está intentando restablecer su contraseña. Tenga listo su Google Authenticator para la restauración.",
		"err1": "Las dos nuevas contraseñas ingresadas son diferentes",
		"success2": "Contraseña cambiada exitosamente",
		"title": "Cambiar contraseña",
		"OldPassword": "Contraseña anterior",
		"OldPasswordmsg": "Contraseña anterior requerida",
		"NewPassword": "Nueva contraseña",
		"NewPasswordmsg": "nueva contraseña requerida",
		"RetypeNewPassword": "Reescriba la nueva contraseña",
		"RetypeNewPasswordmsg": "Reescriba la nueva contraseña",
		"Continue": "Continuar"
	},
	"Service": {
		"title": "Servicio",
		"CONTACTUS": "CONTACTENOS",
		"tips": "El servicio al cliente se esta tomando un contacto !",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "enlace"
	},
	"Bank": {
		"BankName": "Nombre del banco",
		"ChooseaCurrencyType": "Seleccionar tipo de moneda",
		"SelectWithdrawalMethod": "Seleccione el método de retiro",
		"Newbankcard": "New bank card",
		"Editbankcard": "Edit bank card",
		"title": "Mi banco",
		"Cannotbeempty": "No puede estar vacia",
		"BankHolderName": "Nombre del titular del banco",
		"BankAccountNumber": "Numero de el titular del banco",
		"IFSCCode": "codigo IFSC ",
		"Email": "Correo",
		"Phone": "telefono",
		"OTPCode": "codigo OTP ",
		"tips1": "1.Utilice el numero de telefono movil que registro para recibir OTP",
		"tips2": "2. Por la seguridad de los fondos la targeta bancaria se actualiza una vez al dia",
		"Add": "Agregar",
		"error1": "El nombre del titular del banco no puede estar vacio!",
		"error2": "Ingrese el nombre correcto del titular del banco!",
		"error3": "El nombre del banco no puede estar vacio!",
		"error4": "Ingrese el nombre del banco correcto!",
		"error5": "El numero de cuenta bancaria no puede estar vacio!",
		"error6": "por favor ingresee l numero de cuena bancaria correcto!",
		"error7": "el codifo IFSC no puede estar vacio!",
		"error8": "Por favor ingrese correctamente el codigo IFSC!",
		"error9": "El correo electronico no puede estar vacio!",
		"error10": "Por facor ingrese correctamente la direccion de correo electronico!",
		"error11": "El telefono no puede estar vacio!",
		"error12": "El codigo OTP no puede estar vacio!",
		"Successfully": "Agregar targeta bancaria correctamente"
	},
	"wallet": {
		"title": "Mi billetera",
		"MyBalance": "Mi Balance",
		"MyBank": "Mi Banco",
		"RechargeRecords": "Registro de recarga",
		"WithdrawalRecords": "Registro de retiro"
	},
	"Rechargepage": {
		"Reminder": "Recordatorio",
		"Reminder_1": "1. No deposite ninguna otra moneda que no sea USDT en la dirección. Enviar monedas o tokens no compatibles a esta dirección puede resultar en una pérdida irrecuperable.",
		"Reminder_2": "2. El monto mínimo de depósito es de 5 USDT.",
		"Reminder_2_1": "2. El monto mínimo de depósito es de ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Los depósitos llegarán después de 15 confirmaciones en la red.",
		"Minimum": "Mínimo",
		"title": "Recarga",
		"History": "Historia",
		"Mybalance": "Mi balance",
		"RechargeAmount": "Cantidad de recarga",
		"RechargeAmountmsg": "Por favor ingrese la cantidad",
		"tips1": "El canal de pago esta en mantenimiento !",
		"tips2": "Seleccione el canal de pago",
		"PayNow": "Pagar ahora",
		"err1": "El canal de pago esta en mantenimiento",
		"err2": "La cantidad no puede ser menor ",
		"err3": "Seleccione el canal de pago",
		"Rechargemethod": "Método de recarga"
	},
	"Rechargerecord": {
		"title": "Registro de recarga",
		"WatingOrder": "Orden de comer",
		"HistoryOrder": "Orden de historia",
		"Rupee": "Dólar",
		"Status": "Estado",
		"Time": "Tiempo"
	},
	"withdrawal": {
		"Tips": "consejos",
		"Tips_1": "Consejos: la tarifa de retiro por ",
		"Tips_2": " USDT y menos es ",
		"Tips_3": " USDT, la tarifa de retiro por más de ",
		"Tips_4": "USDT es",
		"Tips_5": "%.",
		"Dirección_de_cartera": "Dirección de cartera",
		"Agregar_dirección_de_cartera": "Agregar dirección de cartera",
		"Tarifa_de_red": "Tarifa de red",
		"Monto_recibido": "Monto recibido",
		"Select Chains": "Seleccionar cadenas",
		"Bankcard": "Tarjeta bancaria",
		"Nobankcardselected": "No se ha agregado ninguna tarjeta bancaria",
		"Withdrawalfee": "Tarifa de retiro",
		"title": "Retiro",
		"Mybalance": "Mi balance",
		"Withdrawablebalance": "Saldo extraible",
		"tips1": "El importe total de la compra debe ser superior a {{codeAmount}} Se puede retirar en su totalidad",
		"tips1-1": "El importe total de la compra debe ser superior a",
		"tips1-2": "se puede retirar en su totalidad",
		"tips2": "Los retiros por dia son {{draw_num}} tiempo",
		"tips2-1": "Los retiros por dias son",
		"tips2-2": "tiempo",
		"tips3": "La cantidad maxima diaria de retiro es",
		"tips4": "la cantidad minima de retiro unico es ₹{{draw_min_money}} y la cantidad maxima de retiro unico es ₹{{draw_max_money}}",
		"tips4-1": "El monto minimo de retiro unico es",
		"tips4-2": "Y el monto maximo de retiro unico es",
		"tips5": "El tiempo de retiro es {{draw_time}}, por favor no exceda el tiempo de retiro",
		"tips5-1": "El tiempo de retiro es",
		"tips5-2": "por favor no exceda el tiempo de retiro",
		"tips6": "El retiro de efectivo se deducira {{draw_fee}}% De la tarifa de registro de efcetivo",
		"tips6-1": "El retiro es efectivo ",
		"tips6-2": "De la tarifa de retiro efectivo",
		"tips7": "Si el monto de su retiro es igual o menor a ₹{{solid_min_amount}}, Una tarifa fija de  ₹{{solid_feemoney}}se cobrara",
		"tips7-1": "Si el monto de su retiro es menor o igual a",
		"tips7-2": "Una tarifa fija de",
		"tips7-3": "Se cobrara",
		"WithdrawalAmount": "Cantidad de retiro",
		"WithdrawalAmountmsg": "Por favor ingrese la cantidad",
		"Withdrawal": "Retiro",
		"WithdrawalHistory": "Historia retiro",
		"Tipsmsg": "La solicitud de retiro es exitosa esperando la auditoria !",
		"dangerMsg1": "Primero agrege su targeta bancaria",
		"dangerMsg2": "El monto de retiro no puede ser menor que",
		"dangerMsg3": "El monto de retiro debe ser multiplo de 100",
		"dangerMsg4": "El monto de retiro no puede ser mayor que",
		"dangerMsg5": "El monto de retiro no puede ser mayor al saldo a retirar",
		"dangerMsg6": "Primero agrege su targeta bancaria"
	},
	"withdrawalrecord": {
		"title": "Registro de retiro",
		"WatingOrder": "Orden de retiro",
		"HistoryOrder": "orden de historia",
		"Rupee": "Dólar",
		"Status": "Estado",
		"Time": "Tiempo",
		"Nomore": "No mas!"
	},
	"Version": {
		"title": "Version",
		"stableversion": "Version estable",
		"Alreadythelatestversion": "Ya es la ultima version",
		"Newversionfound": "Nueva version encontrada",
		"DownloadUpdate": "Descarga de actualizacion"
	},
	"Bonus": {
		"title": "Actividad adicional",
		"ReceiveBonus": "Recibir bonificacion",
		"Hi": "Hola",
		"tips1": "Felicitaciones por obtener el bono",
		"tips2": "Necesita recargar mayor o igual a",
		"tips3": "Y luego el bono se agregara automaticamente a su saldo",
		"Received": "Recibida",
		"Back": "Atras",
		"Rechargenow": "Recarga ahora"
	},
	"ComplaintsSuggestions": {
		"title": "Quejas&Sugerencias",
		"Answered": "Respondida",
		"AddNew": "AgregarNueva",
		"Time": "Tiempo",
		"Type": "Tipo",
		"WhatsApp": "WhatsApp",
		"Description": "Descripción",
		"Remark": "Respuesta",
		"Waiting": "Esperando",
		"Suggestion": "Sugerencia",
		"Consult": "Consultar",
		"RechargeProblem": "Recargar Problema",
		"WithdrawProblem": "Retirar Problema",
		"OrderProblem": "Pedido Problema",
		"Other": "otro",
		"Describeyourproblems": "Describe tus problemas",
		"WhatsApptocontactyou": "WhatsApp para contactarte",
		"submit": "enviar",
		"err1": "WhatsApp no ​​puede estar vacío!",
		"err2": "El tipo no puede estar vacío!",
		"err3": "La descripción no puede estar vacía!"
	},
	"slotMachine": {
		"title": "Maquina de frutas",
		"Myblance": "Mi balance",
		"Records": "Maquina de frutas registros",
		"titleRecord": "Registros"
	},
	"bankrecharge": {
		"Orderamount": "Total de la orden",
		"Ordernumber": "Número de orden",
		"BankName": "Nombre del banco",
		"BankAccount": "Cuenta bancaria",
		"IFSC": "codigo Swift",
		"Yourbankcardnumber": "Su número de tarjeta bancaria",
		"Enterthebanktransactionordernumber": "Ingrese el número de orden de la transacción bancaria",
		"submit": "enviar",
		"Tips": "Consejos",
		"tips1": "1.El sistema está procesando su pedido.",
		"tips2": "2.Póngase en contacto con el servicio de atención al cliente si la cuenta no ha llegado durante mucho tiempo. ",
		"err1": "Ingrese su número de tarjeta bancaria",
		"err2": "Ingrese el número de orden bancaria"
	},
	"javaCode": {
		"200": "Exito",
		"20001": "Token de acceso invalido",
		"20002": "Token de inicio de sesion no valido",
		"20003": "Error telefono",
		"20004": "El telefono existe!",
		"20005": "Eror correo!",
		"20006": "El correo existe!",
		"20007": "Error contraseña!",
		"20010": "Error de cuenta o contraseña!",
		"20011": "Error de cuenta bancaria!",
		"20012": "Error banco IFSC, (Formato IFSC estandar) - longitud 11, primeros cuartos IFSC y quinto 0!",
		"20013": "error nombre beneficiario, 5 < nombre <= 50!",
		"20021": "La cuenta no existe!",
		"20022": "La cuenta esta congelada!",
		"20031": "La capa es limite 10 !",
		"20032": "Error del rol de actualizacion !",
		"20033": "no hay suficiente equilibrio!",
		"20034": "Monto de retiro incorrecto!",
		"30001": "Servicio cerrado temporalmente!",
		"30002": "El dinero de la apuesta limite esta entre 10 y 50000!",
		"30003": "El problema actual ha terminado !",
		"30005": "Registrado",
		"31001": "Una sola transacción supera el límite !",
		"31002": "El monto total de hoy ha alcanzado el límite superior !",
		"31003": "El número de veces que hoy ha alcanzado el límite !",
		"31004": "Personal actual un config, póngase en contacto con el administrador o el agente!",
		"200081": "Registrar error!",
		"200082": "La cuenta se ha desctivado!",
		"200091": "Añadir targeta el limite es 10!",
		"200092": "Error de entrada PWD demasiadas veces y la cuenta esta inhabilitada!",
		"300041": "Toda la cantidad de transacion de la emision tiene limite !",
		"300042": "Toda la cantidad de transaciones del usuario tienen limite !",
		"300043": "Error de articulo de apuesta !",
		"300044": "Ya lo ha comprado y no puede volverlo a comprar!",
		"Servererror": "Error del Servidor!",
		"Handledataerror": "Manejar error de datos !",
		"-91": "Error del Servidor!",
		"-92": "Manejar error de datos!",
		"-1": "Error del sistema!",
		"-2": "Error de parametros",
		"-3": "Sin datos",
		"-4": "Operacion frecuente en poco tiempo",
		"-5": "Sistema ocupado intento de nuevo mas tarde!",
		"-6": "Hay muchas personas en este momento intentelo de nuevo!",
		"-7": "error de datos!",
		"-8": "Error codigo Google !",
		"-9": "El sistema esta en mantenimiento por favor intentelo mas tarde !",
		"-10": "Guardar error!",
		"-11": "borrar error!",
		"-12": "Error de actualizacion!",
		"-13": "Operacion ilegal!",
		"-14": "Operacion fallida!",
		"-15": "Operacion inhabilitada!",
		"-16": "Operacion finalizada!",
		"-17": "Existe un error",
		"-18": "No existe!",
		"-19": "expiro",
		"-20": "Ha inhabilitado",
		"-21": "error en la verificacion del codigo",
		"-22": "El intervalo de modificacion debe swr superior a un dia",
		"-23": "La nueva contraseña no puede ser la misma que la contraseña original",
		"-100": "Error personalizado!",
		"-881": "El sistema se está manteniendo! Inténtelo de nuevo más tarde !",
		"-882": "Error de parámetros de cantidad",
		"-883": "Error de parámetros de canal",
		"-884": "El canal se mantiene, inténtalo de nuevo más tarde!",
		"-885": "El canal se mantiene, inténtalo de nuevo más tarde!",
		"-886": "La cantidad de recarga actual es menor que la cantidad límite mínima !",
		"-887": "Error de parámetros de cantidad",
		"-888": "Número máximo de retiros en un día",
		"-889": "Monto máximo de retiro superior a un día",
		"-8810": "Cantidad de código insuficiente, también se necesita cantidad de código",
		"-8811": "Excedió la cantidad máxima de retiro",
		"-8812": "Por debajo del monto mínimo de retiro",
		"-8813": "La hora actual no es un servicio para retirar !"
	},
	"Management": {
		"TotalPurchaseAmount": "Importe total de la compra",
		"TotalRevenue": "Los ingresos totales",
		"AccountBalance": "Saldo de la cuenta",
		"Income": "Ingreso",
		"Transaction": "Transacción",
		"Myhold": "mi bodega",
		"ProductList": "Lista de productos",
		"Investmentperiod": "Período de inversión",
		"days": "días",
		"Buynow": "Comprar ahora",
		"Saletime": "tiempo de venta",
		"Nomore": "No más",
		"ProductDetails": "Detalles de producto",
		"ForSale": "En venta",
		"Sale": "Rebaja",
		"SoldOut": "Vendido",
		"ProductInformation": "Información del Producto",
		"InvestmentPeriod": "Período de inversión",
		"TotalShare": "Participación total",
		"ExpectedReturn": "Rendimiento esperado",
		"SaleTime": "Tiempo de venta",
		"PurchaseConditionsAndRules": "Condiciones y reglas de compra",
		"MinimumPurchaseAmount": "Importe mínimo de compra",
		"MaximumSalesLimit": "Límite máximo de ventas",
		"AccountBalanceMustBeGreaterThan": "El saldo de la cuenta debe ser mayor que",
		"DayBetIsGreaterThan": "La apuesta diaria es mayor que",
		"ProductDescription": "Descripción del producto",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "Ayuda",
		"HelpCenter": "Centro de ayuda",
		"text": "cualquier duda mira aqui"
	},
	"SetingHa": {
		"Seting": "Ajuste",
		"SetingCenter": "Centro de configuración",
		"text": "Obtenga más recompensas"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Cambie la red a ",
		"tips1_2": " y vuelva a intentarlo",
		"trxErr": "No tienes suficiente TRX. Necesitas 10 TRX para pagar las tarifas de los mineros"
	},
	"coinDefi": {
		"staking": "Replantear ahora",
		"All_Chains": "Todas las cadenas",
		"Annual_Percentage_Yield": "Porcentaje de rendimiento anual",
		"Daily_Yield_Rate": "Tasa de rendimiento diario",
		"Mining_Period": "Período DeFi",
		"Flexible": "Flexible",
		"Wallet_Balance": "Saldo de la cartera",
		"Daily_Yield": "Rendimiento diario",
		"DeFi_Mining": "Únase a la minería DeFi ahora",
		"Disconnect": "Desconectar",
		"Copy_Link": "Copiar link",
		"text1": "Navegador móvil no compatible",
		"text2": "TRON no es compatible con el navegador móvil para conectarse a la billetera.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Ábralo en un ",
		"text3_2": "navegador de billetera móvil",
		"text3_3": " compatible con la cadena TRON(TRX) y asegúrese de que la cadena actual sea TRON(TRX). me gusta",
		"text3_4": " etc.",
		"tips1": "No repita la minería DeFi",
		"tips2": "Error, inténtalo de nuevo más tarde",
		"tips3": "No se ha detectado el complemento de la billetera, instale la billetera TronLink",
		"tips4": "Primero inicie sesión en la billetera TronLink"
	},
	"stop_investing": "Detener la inversión",
	"register.checkerr": "Contraseña: uso de 8 caracteres o más (una combinación de letras y números)",
	"home.Products&Services": "Productos y servicios",
	"home.Protection_Fund": "Fondo de protección",
	"home.Ranking": "Clasificación",
	"Ranking.Today_s winning ranking": "Clasificación de ganancias de hoy",
	"Ranking.tips1": "¡Cuanto mayor sea la cantidad ganada  \n  mayor será la clasificación!",
	"Ranking.UserName": "Nombre de usuario",
	"Ranking.Winning Amount": "Cantidad ganadora",
	"Ranking.Today_s rebate ranking": "Clasificación de descuentos de hoy",
	"Ranking.tips2": "¡Los usuarios de nivel inferior obtienen un mayor descuento  \n  clasificación más alta!",
	"Ranking.Rebate Amount": "Importe del descuento",
	"Ranking.Rebate ranking": "Clasificación de los 20 mejores descuentos",
	"Activity.content0_1": "Completar cualquier invitación válida puede obtener un valor promedio de hasta",
	"Activity.content0_2": "El Premio máximo para cien invitados es",
	"Activity.Daily_Tasks": "Tareas diarias",
	"Refer.Rebate_description": "Instrucciones de descuento",
	"Task.hint2_1": "2. solo en el pasado",
	"Task.hint2_2": "¡¡ los usuarios registrados en el día y recargados hoy no se calcularán!",
	"Task.hint3": "¡3. ¡ hoy se necesita una recarga mínima de 20 usdt!",
	"Task.hint3_1": "3. hoy se necesita un mínimo",
	"Task.hint3_2": "¡¡ recarga de usdt!",
	"Task.hint4": "Número de personas invitadas en los últimos tres días",
	"Task.hint4_1": "Pasado",
	"Task.hint4_2": "Número de personas invitadas por el día",
	"Task.hint5": "Número de personas que han completado la recarga hoy",
	"mine.ProvacyPolicy": "Política de privacidad",
	"mine.JOIN US": "Únete a nosotros",
	"mine.ComplaintsSuggestions": "Quejas y recomendaciones",
	"App.DownloadforiOS": "Descargar la versión iOS",
	"Rechargepage.Tips": "Recordatorio cálido: (...) firstrpr * 100%) con la primera recompensa de recarga como cantidad de recarga",
	"Rechargepage.Tips_1": "Recordatorio cálido: la primera recompensa de recarga es la cantidad de recarga",
	"Rechargepage.Tips_2": ".",
	"withdrawal.Google_Code": "Código de verificación de Google",
	"withdrawal.WalletAddress": "Dirección de la billetera",
	"withdrawal.Addwalletaddress": "Añadir dirección de billetera",
	"withdrawal.Network_fee": "Tarifas de la red",
	"withdrawal.Receive_amount": "Llegada real a la cuenta",
	"Management.Maturity": "Vencimiento",
	"Management.EarlyRedemption": "Rescate anticipado",
	"Management.Redemptiontime": "Tiempo de rescate",
	"Management.Amountofincome": "Importe de los ingresos",
	"index.index.gayb21": "Registro para obtener recompensas:",
	"index.index.72y628": "Tres razones para elegir gogobit",
	"index.index.z6022y": "EPOCH EPT sigue uno de los más altos estándares de Seguridad de la industria. Almacenamos el 100% de las criptomonedas en el almacén frigorífico. Garantizamos proporcionar al menos una proporción de reserva de 1: 1 para los fondos de los clientes. Nuestro Fondo de 300 millones de dólares proporciona protección adicional contra las amenazas a la seguridad.",
	"index.index.574982": "1. ahora inscripción gratuita en gogobit",
	"index.index.qdi1g4": "2. completar la verificación de Google",
	"index.index.weko28": "3. recargar su cuenta de gogobit de inmediato",
	"index.index.sgkb35": "Programa de invitación gogobit",
	"index.index.2u17nf": "Completar cualquier invitación válida puede obtener un valor promedio de hasta 5,59 usdt. El Premio máximo para cien invitados es",
	"index.index.e8is4a": "Programa de recomendaciones gogobit",
	"index.index.47uevl": "Tareas de transacción de gogobit",
	"index.index.2516gq": "EPOCH EPT usa, Inc. ha obtenido una licencia de actividad comercial de moneda virtual emitida por los servicios financieros del Estado de Nueva York.",
	"invest.index.3aqi3d": "Saldo de la cuenta:",
	"invest.index.ubk1j4": "¡¡ deja de invertir!",
	"invest.prize1.4733u6": "Cartera:",
	"invest.prize1.t55a16": "Total:",
	"login.login.09m52q": "He leído y estoy de acuerdo con gogobit",
	"login.login.045csy": "Nombre de usuario",
	"login.login.r7rbm5": "¡Por favor, acepte el acuerdo!",
	"rewards.activityb.4nvrq9": "Cuando el invitado completa el primer depósito, se cuenta como una invitación válida. Los invitados recibirán un bono de invitación por cada invitación válida. El nivel de bonificación de invitación proviene de 2usdt 1 invitación válida 559usdt 100 invitación válida.",
	"rewards.activityb.t10k6e": "Los invitados compartirán una Comisión de transacción del 20% de la tarifa de transacción de cada invitado lv.1. Los invitados compartirán una Comisión de transacción del 10% de la tarifa de transacción de cada invitado lv.2. El Premio máximo para la recompensa de la tarifa de transacción del invitado es de dólares estadounidenses ilimitados.",
	"rewards.activityb.3d6850": "Lv.1 número efectivo de invitados",
	"rewards.activityb.lvxo74": "Lv.2 número efectivo de invitados",
	"rewards.activityb.zj26f9": "Lv.1 número acumulado de invitados",
	"rewards.activityb.dkgxr5": "Lv.2 número acumulado de invitados",
	"rewards.activityb.xjo077": "Recompensas recomendadas:",
	"rewards.activityb.k8wv6v": "Únete a gogobit y Consigue tu enlace de recomendación. Los usuarios registrados a través de este enlace recibirán recompensas.",
	"rewards.activityb.4slpzg": "Después de que su amigo complete el primer depósito y complete el volumen de transacciones requerido, recibirá una recompensa recomendada por un valor promedio de $5-15. La recompensa puede alcanzar hasta $559.",
	"rewards.index.993jwg": "Copia exitosa",
	"rewards.index.7141ft": "El navegador no admite la copia automática",
	"wallet.index.9uz2gn": "Tarifa de red 0 usdt",
	"wallet.index.ych3e1": "Llegada real:",
	"wallet.index.3v558d": "Consejo: el retiro es inferior a $20, la tarifa de retiro es de $1, el retiro es superior a $20 y la tarifa de retiro es del 3%.",
	"wallet.index.255e30": "1. retiro máximo diario de 8 millones de dólares",
	"wallet.index.3g68m8": "2. el monto mínimo de retiro único es de $5 y el monto máximo de retiro único es de $8 millones.",
	"wallet.index.c6x3ti": "3. el tiempo de retiro es de 00: 00 a 23: 59, por favor retire dentro del tiempo designado.",
	"wallet.index.h94rlg": "Límite mínimo 5",
	"wallet.index.167862": "¡¡ ingrese la dirección de su billetera, por favor!",
	"wallet.index.pi869h": "¡¡ por favor, introduzca el monto del retiro!",
	"wallet.index.ysa85i": "El monto máximo de un solo retiro es",
	"components.changepass.68j9kh": "Está tratando de restablecer su contraseña.",
	"components.changepass.ouxtp2": "Contraseña original",
	"components.languagebox.8no849": "Japonés",
	"components.languagebox.oif20b": "Chino tradicional",
	"components.mycode.xyqr66": "Se puede obtener hasta 5000 usdt completando la tarea",
	"components.mymenu.277ss7": "ID de usuario:",
	"components.mymenu.74l443": "Tiempo de registro:",
	"components.myposter.36rw5e": "Código de recomendación:",
	"components.mytabbar.zw2bh2": "Página de inicio",
	"components.mytabbar.8x6c28": "Tendencias",
	"components.mytabbar.28s7qr": "Inversión",
	"components.mytabbar.4r75d9": "Recompensa",
	"components.xieyi.zqk7pe": "Tamaño de fuente: 16px; Familia de fuentes: estilo song;",
	"components.help.6gpud8": "Hay dos modelos de inversión:",
	"components.help.5qrq36": ": cada período dura 1 minuto y solo se ofrece una inversión única y doble. El resultado fue calculado por la última cifra del precio de cierre de la línea k en el minuto 1 de la transacción al contado binance BTC / usdt.",
	"components.help.isl7r1": ": cada período dura 5 minutos. La versión profesional desbloquea todos los métodos de inversión de gogobit. El resultado fue calculado por la última cifra del precio de cierre de la línea k en los 5 minutos de negociación al contado de binance BTC / usdt.",
	"components.help.eai3nm": "Retorno de la inversión:",
	"components.help.35xg82": "Número único:",
	"components.help.mpfg0s": "Ingresos: importe de la inversión x 9,4",
	"components.help.v2ziib": "Cifras de odd:",
	"components.help.vfzxor": "Ingresos: importe de la inversión x 1,94",
	"components.help.73168b": "Número even:",
	"components.help.76wgv1": "Big digital:",
	"components.help.05ed33": "Números pequeños:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "¡Por favor, introduzca la contraseña original!",
	"components.changepass.xme6my": "¡Por favor, introduzca una nueva contraseña!",
	"components.changepass.u434f8": "¡¡ las dos contraseñas son inconsistentes!",
	"components.changepass.574790": "¡Modificación exitosa!",
	"invest.index.9y8wg0": "No ganó el Premio",
	"invest.index.ql5nc1": "Ganar el Premio",
	"login.login.76451t": "¡¡ la diferencia de intercambio a se cuantifica!",
	"login.login.915p36": "La gestión financiera es la primera en el mundo.",
	"login.login.sen863": "Hello es más emocionante después de aterrizar",
	"login.login.nz6q9x": "Inicio de sesión telefónico",
	"login.login.w7p592": "Inicio de sesión del buzón",
	"login.login.g9y91k": "Número de teléfono móvil",
	"login.login.456gbb": "Número de buzón",
	"login.login.5ly8m8": "País",
	"login.login.69h472": "Por favor, elija el país",
	"login.login.i4p378": "Número",
	"login.login.fl82xx": "Ejemplo:",
	"wallet.index.urkoub": "Recargar el Código QR",
	"wallet.index.25cocl": "Copiar dirección",
	"wallet.index.t35fvg": "¡1. tiempo de retiro: ¡ por favor, contabilice el impuesto regional dentro de las 24 horas de la semana del 1 al sábado y el domingo!",
	"wallet.index.68xx61": "2. los retiros en moneda nacional mundial llegarán a más tardar en 48 horas, con una tarifa de retiro del 8% (incluidos los impuestos regionales).",
	"wallet.index.4ws3w9": "3. el retiro de usdt llegará a más tardar 24 horas, con una tarifa del 2% (incluidos los impuestos regionales)",
	"wallet.index.9b7wq8": "4. retiro mínimo de 12 usdt y retiro único máximo de 3.000 usdt.",
	"wallet.index.23y92a": "Por favor, sepa",
	"wallet.index.pl5473": "Límite mínimo 5",
	"wallet.index.nb3u72": "Información de la tarjeta bancaria",
	"wallet.index.842so4": "Añadir número de tarjeta bancaria",
	"wallet.index.86j717": "Añadir Banco afiliado",
	"wallet.index.763d7x": "Añadir el nombre de la tarjeta bancaria",
	"wallet.index.50j237": "Banco afiliado",
	"wallet.index.hb6lun": "Nombre de la tarjeta bancaria",
	"wallet.index.62y2jc": "Número de tarjeta bancaria",
	"login.register.666hp4": "Transacciones cuantitativas por diferencia de precio al",
	"login.register.9298b8": "Invitar a amigos",
	"login.register.375g14": "Los recién llegados se unen por primera vez",
	"login.register.01922f": "Se puede realizar una vez",
	"login.register.527o4q": "Ingresos diarios de la Ia",
	"login.register.c563gt": "Registro de buzón",
	"login.register.2ls856": "¡El formato del número de teléfono móvil es incorrecto!",
	"login.register.4nu77e": "¡El formato del número de buzón es incorrecto!",
	"lang.zh.pu623z": "1 invitación válida",
	"lang.zh.3hv80b": "¡¡ recarga de usdt!",
	"lang.zh.47ttf6": "Recordatorio cálido: (...) firstrpr * 100%) con la primera recompensa de recarga como cantidad de recarga",
	"lang.zh.9tli5f": "Consejo: retiro inferior",
	"lang.zh.62e882": "Dólares estadounidenses, la tarifa de retiro es",
	"lang.zh.m3818q": "Dólares, retiro",
	"lang.zh.xs2f10": "El rescate anticipado no genera ningún beneficio",
	"invest.index.hl86r1": "Asistente de inversión",
	"wallet.index.645d70": "Por favor, introduzca el nombre del Banco al que pertenece.",
	"wallet.index.n21787": "Introduzca el nombre del usuario de la tarjeta bancaria",
	"wallet.index.7s5j14": "Por favor, introduzca el número de tarjeta bancaria.",
	"components.recharge.l576h7": "Cambios en el saldo",
	"components.recharge.3x6844": "Nota",
	"components.appjump.u85nhy": "¡¡ Money easy se ha convertido en mi nueva adicción!",
	"components.appjump.543ht1": "Acabo de ganar 50 dólares en Money easy, y finalmente puedo ganar dinero extra en efectivo en cualquier momento y en cualquier lugar. ¡¡ puedo participar en encuestas, jugar juegos o probar nuevas aplicaciones y ser pagado! ¡¡ registrarse ahora puede obtener un bono de $50!",
	"components.appjump.8k17w7": "Compartir éxito",
	"components.appjump.648wav": "Compartir falló:",
	"components.appjump.7c2hl4": "El navegador actual no admite la función de compartir",
	"components.fuwu.1t2h7i": "Contenido",
	"components.fuwu.6t32g3": "Antecedentes del mercado.",
	"components.fuwu.4c8u7w": "Sistema inteligente de comercio cuantitativo",
	"components.fuwu.mw89jh": "Solicitud automática de retransmisión",
	"components.fuwu.v5o60h": "Nacido.",
	"components.fuwu.12x554": "Visión",
	"components.fuwu.d8kj48": "Valores fundamentales.",
	"components.fuwu.sns568": "Lógica de diseño",
	"components.fuwu.131mc5": "Principios básicos.",
	"components.fuwu.76i3z7": "Modelo tecnológico del sistema inteligente de comercio cuantitativo",
	"components.fuwu.253h82": "Tecnología de cadena de bloques.",
	"components.fuwu.64z717": "Arquitectura",
	"components.fuwu.14n9v4": "Miembros del equipo.",
	"components.fuwu.ewgac0": "Historia y planes",
	"components.fuwu.yy18w5": "Valor de mercado futuro",
	"components.fuwu.h4l80q": "Consideraciones jurídicas",
	"components.fuwu.1nvu89": "Declaración de exención de responsabilidad",
	"components.fuwu.yvo758": "Contexto del mercado",
	"components.fuwu.pgt1oc": "De",
	"components.fuwu.4vy2ub": "Año a año",
	"components.fuwu.sxj11f": "A principios de año, el rendimiento de las acciones de valor se quedó significativamente por detrás de las acciones de crecimiento, lo que agrava el bajo rendimiento a largo plazo de los factores de valor desde la crisis financiera mundial. Algunas personas lo atribuyen al factor de tasa de interés macro.... 8212; -. 8212; Tipos de interés bajos, rendimientos reducidos de bonos o curvas de rendimiento planas.",
	"components.fuwu.ihu32d": "Estudiando estas afirmaciones, se encontró que la relación entre el valor y la tasa de interés es bastante inútil y compleja en teoría. Empíricamente, bajo ciertos parámetros, solo hay una ligera correlación entre la variable de interés y el factor de valor. Aunque en las últimas muestras de datos hay patrones significativos relacionados con los cambios en los rendimientos de los bonos o los diferenciales de rendimiento, la importancia económica de estas correlaciones es menor e inconsistente en otras muestras.",
	"components.fuwu.ynfmrx": "La conclusión es que el rendimiento del factor de valor no se puede explicar por el factor de tasa de interés, y la estrategia de tiempo del factor basada en la señal de tasa de interés es difícil de implementar eficazmente. Para recuperar la curva de retorno de la inversión anterior considerable, hay que prestar atención a la economía digital.",
	"components.fuwu.041265": "Es por",
	"components.fuwu.7ybglf": "Con diez empleados en",
	"components.fuwu.cv8q4k": "Una compañía de fondos de cobertura fundada en Nueva York en el año pasado, con investigación académica y métodos innovadores para resolver problemas para clientes de una manera profesional.",
	"components.fuwu.u18kd5": "En el pasado",
	"components.fuwu.345nr1": "A mediados de año, durante muchos años,",
	"components.fuwu.uux4kt": "Se han lanzado varias estrategias bursátiles internacionales y productos de inversión financiera para ayudar a los inversores a lograr una apreciación estable de los activos en los mercados financieros. A",
	"components.fuwu.2k2868": "Año,",
	"components.fuwu.dfo204": "Tamaño de los activos gestionados por fondos de cobertura y productos financieros",
	"components.fuwu.rer5h5": "Ha superado",
	"components.fuwu.y62673": "100 millones de dólares, hasta",
	"components.fuwu.mdq3mn": "Al final del año,",
	"components.fuwu.944x0p": "El tamaño de los fondos administrados a nivel mundial supera",
	"components.fuwu.64sd2z": "100 millones de dólares",
	"components.fuwu.i2bs5g": "Con",
	"components.fuwu.34l46q": "Potencia informática, Big data, Internet de las cosas",
	"components.fuwu.t8n5nh": "Con el desarrollo exponencial de campos como la computación en la nube, la inteligencia artificial comenzó a liberar su poder, lo que llevó a la prosperidad de la industria.",
	"components.fuwu.5765y1": "Barrió Internet en solo unos meses. Sus aplicaciones en minería de datos, visión de contenido, diagnóstico inteligente, análisis predictivo y evolución inteligente son gradualmente conocidas por la gente. Sin embargo, muchas personas no saben que las aplicaciones de inteligencia artificial, como el reconocimiento facial y las tecnologías de interacción de voz, se han integrado en la vida cotidiana de las personas.",
	"components.fuwu.138tt4": "Según las estadísticas, desde",
	"components.fuwu.jk42p7": "En el año, el número de startups de inteligencia artificial en todo el mundo aumentó.",
	"components.fuwu.lu0nu3": "Diez mil, el monto total de financiación alcanza",
	"components.fuwu.edrz45": "Billones de dólares. Muchas empresas tecnológicas han creado departamentos de inteligencia artificial, lo que demuestra que ha llegado la era de la inteligencia artificial. En el pasado",
	"components.fuwu.0ty944": "Durante el año,",
	"components.fuwu.267zhq": "Ha estado a la vanguardia de la innovación en inversión cruzada en tecnología, datos y Finanzas conductuales. Explora constantemente impulsar el mercado y aplica estas ideas a la cartera de clientes para ayudar a los clientes globales a lograr un crecimiento estable de la riqueza.",
	"components.fuwu.768x8x": "Con la computación en la nube, la computación de borde, el Big data,",
	"components.fuwu.8hhz36": "Y el rápido desarrollo de la inteligencia artificial,",
	"components.fuwu.o32734": "Fundador de",
	"components.fuwu.8hhu5s": "Consciente de la necesidad de que los productos sean consistentes con los tiempos",
	"components.fuwu.jwkv4j": "De lo contrario, serán eliminados por el mercado. Además de mantener las operaciones bursátiles y financieras existentes,",
	"components.fuwu.4m7d39": "En",
	"components.fuwu.n48x5n": "Comenzó a desplegar productos de inteligencia artificial en Año Nuevo. Al utilizar la tecnología de inteligencia artificial para mejorar la eficiencia, predecir las tendencias de aplicación, integrar diversos datos y utilizar modelos de comercio de frecuencia de onda,",
	"components.fuwu.4ukw28": "Puede mantener la rentabilidad media del producto integral en",
	"components.fuwu.ed1635": "Izquierda y derecha.",
	"components.fuwu.o0516q": "Incursionar profundamente en la vanguardia de la inteligencia artificial y esforzarse por integrar las características de la minería de datos, el análisis inteligente y la evolución inteligente en los productos financieros para que",
	"components.fuwu.7ognk6": "Puede promover los mercados financieros con mayor precisión, relajación y estabilidad. Después de los incansables esfuerzos del equipo,",
	"components.fuwu.9ye2y0": "El sistema inteligente de comercio cuantitativo se ha completado y ha pasado con éxito dos años de pruebas, y creemos que es hora de llevarlo al mercado.",
	"components.fuwu.hrwn57": "El sistema inteligente de comercio cuantitativo es un producto financiero digital desplegado en la cadena de bloques después de sus productos financieros del mercado de Valores. Confíe en la economía digital y la inteligencia artificial para gestionar los activos digitales de los usuarios de una manera más eficiente, precisa, estable, flexible, de bajo costo y trazable. Esta es una combinación novedosa de economía, finanzas conductuales, datos y tecnología para agregar valor a los activos de los clientes de manera más efectiva.",
	"components.fuwu.jb545g": "Anual",
	"components.fuwu.31h712": "Se establecieron cuatro empleados en Nueva York. Es un fondo de cobertura que utiliza investigación académica profesional y métodos innovadores para resolver los problemas de los clientes. En el pasado",
	"components.fuwu.623c56": "Se han lanzado varias estrategias bursátiles internacionales y productos de inversión financiera para ayudar a los inversores a lograr un crecimiento constante en los mercados financieros. A",
	"components.fuwu.o8270p": "Activos de fondos de cobertura y productos financieros gestionados",
	"components.fuwu.j53w1u": "100 millones de dólares. Hasta",
	"components.fuwu.4fogox": "Gestionar más de",
	"components.fuwu.h0al2w": "Un fondo de 100 millones de dólares, gracias a su enorme sombra global",
	"components.fuwu.202g6o": "Fuerza de sonido.",
	"components.fuwu.85g7o1": "Se abrió la primera Oficina Internacional en Australia.",
	"components.fuwu.2ubf7n": "Se ha establecido una oficina en el Reino Unido.",
	"components.fuwu.ud1j47": "En la escuela de Negocios de Londres se estableció el Instituto de gestión de activos para avanzar en la investigación de gestión de activos.",
	"components.fuwu.z45lgv": "Se abrió una oficina en Hong Kong y se ampliaron las operaciones asiáticas.",
	"components.fuwu.4582f8": "Se ha establecido un centro de ingeniería en bangalore, India.",
	"components.fuwu.m00yuh": "La creación de una nueva oficina en Alemania ha ampliado aún más su influencia mundial. En",
	"components.fuwu.3r7069": "Año.",
	"components.fuwu.jwwo48": "Es reconocido.",
	"components.fuwu.jnvf5m": "Año a año",
	"components.fuwu.l0bqqt": "Mejor compañía de gestión de fondos de pensiones e inversiones del Año.",
	"components.fuwu.e777k5": "Tener",
	"components.fuwu.j0u235": "Socios y casi mil empleados, con un",
	"components.fuwu.8d8hnh": "Equipo de investigación con muchos años de experiencia en investigación y ha publicado un gran número de artículos en revistas académicas de primer nivel. La compañía combina una sólida experiencia práctica con herramientas cuantitativas para procesar información básica y gestionar riesgos. Adoptar un enfoque sistemático impulsado por la investigación para gestionar estrategias alternativas y tradicionales es un raro ®; Investigación académica",
	"components.fuwu.y17m3k": "Inversión cuantitativa ® 8221; Empresa de doble tracción.",
	"components.fuwu.59t85m": "Nacimientos",
	"components.fuwu.xd3f51": "De",
	"components.fuwu.p1o1m9": "Desde hace años,",
	"components.fuwu.s6631u": "Se ha estado pensando en la aplicación de la inteligencia artificial en el campo financiero para resolver los puntos dolorosos de las transacciones manuales.",
	"components.fuwu.654rrg": "Se lanzó oficialmente el establecimiento del Centro de investigación del sistema de comercio cuantitativo de inteligencia artificial y se redactó el primer",
	"components.fuwu.3w3i3q": "Libro Blanco sobre el sistema inteligente de comercio cuantitativo. Estudiar y resolver problemas relacionados con la inteligencia artificial y el modelo de transacción, establecer una plataforma de computación de inteligencia artificial y construir un sistema de comercio cuantitativo de inteligencia artificial.",
	"components.fuwu.315354": "La inteligencia artificial se aplicó con éxito a las transacciones y se desarrolló",
	"components.fuwu.oef1gv": "Sistema inteligente de comercio cuantitativo. A partir de la recopilación de datos, el sistema utiliza inteligencia artificial para capturar datos de mercado, incluidos precios históricos, volumen de transacciones, sentimiento del mercado, etc. Luego se analiza y explora el mercado, se establecen varias estrategias de transacción, se establecen diferentes modelos de transacción y se generan las señales de transacción correspondientes de acuerdo con las estrategias de transacción y los parámetros de control de viento preestablecidos. El sistema opera a través de transacciones automáticas.",
	"components.fuwu.kinmrh": "El sistema inteligente de comercio cuantitativo es una plataforma de comercio inteligente desarrollada y verificada conjuntamente por Hong Kong Fund y el principal equipo técnico alemán de cadena de bloques. Utiliza tecnologías informáticas avanzadas como inteligencia artificial, cadena de bloques y Big data para lograr la gestión descentralizada de las transacciones financieras.",
	"components.fuwu.1khwn2": "El algoritmo tecnológico central del sistema inteligente de comercio cuantitativo utiliza la tecnología de cadena de bloques para lograr transacciones financieras globales descentralizadas sin restricciones geográficas.",
	"components.fuwu.e5g3fa": "El sistema inteligente de comercio cuantitativo también servirá como el",
	"components.fuwu.7ftmw3": "...... 8220; Asistente.... 8221;, Proporcionar soluciones de transacción cuantitativa para transacciones financieras globales y lograr un despliegue rápido de aplicaciones de cadena de bloques eficientes. Simplifica las transacciones e intercambios de activos y activos digitales. También ofrece a las principales aplicaciones financieras",
	"components.fuwu.4646q8": "Interfaz clave, conectando varios escenarios de aplicación para lograr una integración perfecta de activos digitales con diversas aplicaciones industriales. Esto proporciona a los inversores un entorno de inversión seguro, rápido, fluido y estable. En",
	"components.fuwu.kh359j": "Bajo el sistema inteligente de comercio cuantitativo, se utilizará el poder de la inteligencia artificial para construir un ecosistema de servicios de aplicaciones financieras con capacidades súper inteligentes y súper rápidas. Esto permitirá que más personas disfruten de la súper experiencia traída por la era digital, con direcciones claras como velocidad, seguridad, estabilidad y descentralización. Tiene como objetivo crear un nuevo ecosistema que aplique la inteligencia artificial a la digitalización de activos, ofreciendo libertad e innovación.",
	"components.fuwu.wsp5m5": "La visión del sistema inteligente de comercio cuantitativo se basa en el pensamiento de la banca de inversión y el modelo de capital de riesgo. Con la tecnología de inteligencia artificial como núcleo y basándose en Big data, proporciona a los inversores productos de inversión rentables, seguros y confiables para lograr rendimientos estables a largo plazo.",
	"components.fuwu.861380": "Los factores de valor aportan rendimientos atractivos a largo plazo, pero también se ven afectados por períodos desafiantes, lo que puede ser doloroso para los inversores. Sin embargo, para aquellos que perseveran, la recuperación económica posterior es rentable.",
	"components.fuwu.igh8q8": "El sistema inteligente de comercio cuantitativo puede ayudar a los inversores a superar los problemas emocionales en tiempos difíciles para lograr rendimientos de valor a largo plazo.",
	"components.fuwu.u7cy1f": "Mirando hacia atrás desde",
	"components.fuwu.502xa6": "Los cambios que se han producido desde el año, los rendimientos esperados más bajos en muchas categorías de activos no han cambiado sustancialmente.",
	"components.fuwu.yg0781": "El sistema inteligente de comercio cuantitativo puede resolver eficazmente estos problemas.",
	"components.fuwu.k7s41z": "Los grandes datos muestran que las pérdidas netas causadas por la implementación de la estrategia sistemática de adquisición de índices directos disminuyeron gradualmente en los primeros años posteriores a la implementación. Estas estrategias también muestran resultados de pérdidas netas altamente diversificados. Por lo tanto, de",
	"components.fuwu.pw7jg1": "La estrategia corta de inversión de factores impulsada por el sistema inteligente de comercio cuantitativo puede ser significativamente mejor que la estrategia de índice directo.",
	"components.fuwu.9gya07": "La prima esperada para invertir en mercados emergentes y desarrollados está en el pasado.",
	"components.fuwu.yfiuur": "La parte superior del rango observado en el año. Al mismo tiempo, muchos de los riesgos asociados históricamente a los mercados emergentes han ido disminuyendo durante mucho tiempo. Los inversores tienen buenas razones para aumentar la asignación a los mercados emergentes, incluidos los activos digitales.",
	"components.fuwu.j8nq18": "Valores fundamentales",
	"components.fuwu.40fdvu": "En las últimas dos décadas,",
	"components.fuwu.w5kk35": "Se ha estado explorando constantemente los impulsores del mercado y cómo aplicarlo a la cartera de clientes en los campos de la economía, las finanzas conductuales, los datos y la tecnología. Con el auge del Big data y la mano de obra, con el desarrollo de la inteligencia,",
	"components.fuwu.pg3e4s": "Las estrategias y modelos de transacciones se están volviendo cada vez más complejos.",
	"components.fuwu.kcjcom": "El sistema inteligente de comercio cuantitativo ha entrado en la etapa de prueba y ahora está oficialmente abierto al mundo a través de la Oficina de Hong kong.",
	"components.fuwu.n21shs": "El sistema inteligente de comercio cuantitativo se basa en el pensamiento de la banca de inversión y el modelo de capital de riesgo, con la tecnología de inteligencia artificial como núcleo. Se basa en Big data para establecer modelos de transacciones cuantitativas, diseñados para proporcionar a los inversores productos de inversión rentables y seguros, así como rendimientos estables a largo plazo.",
	"components.fuwu.0l7u49": "Utilizando la tecnología de cadena de bloques,",
	"components.fuwu.lgcse5": "El sistema inteligente de comercio cuantitativo ha desarrollado una red informática distribuida, de bajo costo y de alto rendimiento que protege la privacidad, y ofrece productos financieros integrales.",
	"components.fuwu.0t263a": "Los nodos de computación de alto rendimiento proporcionan una fuerte capacidad de computación para el modelo de transacción, incluyendo entrenamiento y razonamiento de inteligencia artificial, captura de datos, computación de conocimiento cero de la cadena de bloques, etc.",
	"components.fuwu.f5s17o": "Los nodos de computación de alto rendimiento pueden tener varias formas, incluyendo funcionar en grandes",
	"components.fuwu.u2615w": "O",
	"components.fuwu.a5en87": "Nodos totalmente funcionales en el clúster de servidores",
	"components.fuwu.d4f145": "Nodo Permanente",
	"components.fuwu.29o1r5": ", así como de pequeñas y medianas empresas e individuos",
	"components.fuwu.r54iix": "Ocioso",
	"components.fuwu.5zx75g": "Nodo de computación del servidor.",
	"components.fuwu.6156mg": "El sistema inteligente de comercio cuantitativo garantiza el funcionamiento seguro y estable del sistema, al tiempo que permite a todos los inversores beneficiarse de él.",
	"components.fuwu.p54y10": "En",
	"components.fuwu.o69yz3": "En el proceso de comercio cuantitativo inteligente, la capacidad de procesamiento y aprendizaje de la inteligencia artificial es crucial. Hace que el sistema de transacciones sea más inteligente, preciso y automatizado, logrando así una toma de decisiones de transacciones y una gestión de riesgos más eficientes.",
	"components.fuwu.h1r7s5": "Los principios de diseño del sistema inteligente de comercio cuantitativo son los siguientes:",
	"components.fuwu.x27379": "Principio de escalabilidad",
	"components.fuwu.rhqg19": "Los módulos del sistema inteligente de comercio cuantitativo deben estar acoplados sueltamente, lo que facilita la adición de nuevos módulos sin necesidad de cambiar las interfaces de otros módulos.",
	"components.fuwu.c7uqex": "Productos de clientes usuarios pares",
	"components.fuwu.46e7x1": "El acceso al sistema inteligente de comercio cuantitativo es volátil. Cuando un gran número de usuarios acceden a un solo nodo, puede causar un colapso del negocio. Por lo tanto, el contenedor del nodo debe ser capaz de desplegarse automáticamente, permitiendo una rápida expansión horizontal cuando hay presión solicitada por el usuario.",
	"components.fuwu.xc8u4k": "Principio de privacidad",
	"components.fuwu.1n531t": "Los inversores, los nodos de computación, las fuentes de datos y otros componentes del sistema inteligente de comercio cuantitativo disfrutan de medidas de protección de la privacidad.",
	"components.fuwu.ed16w3": "Principios básicos",
	"components.fuwu.7h553b": "¿¿ qué es?",
	"components.fuwu.k4l2md": "Transacciones cuantitativas inteligentes",
	"components.fuwu.7nve0q": "Las transacciones cuantitativas inteligentes se refieren al uso de tecnologías y algoritmos de inteligencia artificial para ejecutar automáticamente decisiones de inversión para maximizar la eficiencia de las transacciones. Es una estrategia de comercio de alta frecuencia basada en Big data, aprendizaje automático, aprendizaje profundo y otras tecnologías. A través del análisis rápido de los datos del mercado, se programan automáticamente. Identificar las mejores oportunidades comerciales y ejecutar las transacciones",
	"components.fuwu.g21385": "La ventaja de las transacciones cuantitativas inteligentes es que pueden analizar rápidamente los datos del mercado, ejecutar automáticamente las decisiones de transacción y evitar interferencias y errores de juicio por factores humanos. Además, puede optimizar y expandir las transacciones en un mercado más amplio y a mayor escala. Además,",
	"components.fuwu.v75es3": "El sistema de comercio inteligente seguirá aprendiendo a través de la inteligencia artificial. A través de la formación a largo plazo de datos y la optimización de modelos, se crean mejores modelos de transacción para adaptarse a los cambios del mercado y ayudar a los usuarios a lograr ganancias a largo plazo.",
	"components.fuwu.rcm80u": "El proceso de transacción está en",
	"components.fuwu.2x15l9": "En el sistema inteligente de comercio cuantitativo, se aplica la tecnología de inteligencia artificial para lograr la capacidad de toma de decisiones independiente de comercio inteligente. Puede ejecutar automáticamente las transacciones de acuerdo con las estrategias y parámetros de transacción preestablecidos, y realizar análisis y predicciones inteligentes de acuerdo con las condiciones del mercado y los datos históricos, logrando decisiones de transacción más inteligentes y autónomas. En concreto,",
	"components.fuwu.xe1844": "El sistema inteligente de comercio cuantitativo utiliza una gran cantidad de datos históricos e información de mercado para la minería de datos y el aprendizaje automático. Construye modelos y algoritmos de transacciones, monitorea y analiza el mercado en tiempo real, optimiza y ajusta las señales y estrategias de transacciones para lograr decisiones de transacciones más efectivas y gestión de riesgos. Además, el sistema de comercio cuantitativo impulsado por la inteligencia artificial mejora y mejora constantemente sus propias estrategias y algoritmos de comercio a través de la tecnología de inteligencia artificial y la capacidad de autoaprendizaje para lograr decisiones de comercio más inteligentes y autónomas. Por lo tanto,",
	"components.fuwu.8jr1h7": "...... 8220; Inteligencia ® 8221; Representa el nivel de inteligencia y la capacidad de toma de decisiones independiente del sistema de comercio cuantitativo de inteligencia artificial. Puede lograr un retorno de la inversión más eficiente, preciso y grande para los inversores. Así como la toma de decisiones automáticas sobre transacciones y la gestión de riesgos, lo que trae consigo",
	"components.fuwu.62r19x": "En las transacciones cuantitativas inteligentes,",
	"components.fuwu.1v4571": "...... 8220; Cuantificación ® 8221; Representa la cuantificación y el análisis de los datos del mercado y las estrategias comerciales. Las transacciones cuantitativas son algoritmos digitales basados en la recopilación, análisis y procesamiento de grandes cantidades de datos, convirtiendo la información del mercado y las estrategias comerciales en decisiones y transacciones automáticas. En concreto,",
	"components.fuwu.35fgx0": "El sistema inteligente de comercio cuantitativo recopila una gran cantidad de datos de mercado, incluidos precios históricos, volumen de transacciones, sentimiento del mercado, etc., para analizar y explorar el mercado. Construye diversas estrategias comerciales basadas en estos datos. Utilizando estas estrategias de transacción y parámetros predefinidos de control de riesgos, el sistema genera las señales de transacción correspondientes y realiza transacciones automáticas. En este proceso, la cuantificación y el análisis de datos hacen que el sistema de transacciones sea más inteligente, preciso y automatizado, logrando así una toma de decisiones de transacciones y una gestión de riesgos más eficientes. Por lo tanto,",
	"components.fuwu.ce4mr4": "...... 8220; Cuantificación ® 8221; Representado",
	"components.fuwu.cfq4pv": "La tecnología central de las transacciones cuantitativas. Es un medio importante para digitalizar la información del mercado y las estrategias comerciales, y puede lograr decisiones comerciales y control de riesgos más rápidos, precisos y automatizados.",
	"components.fuwu.6b0w31": "Ventaja",
	"components.fuwu.7q677y": "Eficiencia",
	"components.fuwu.624ufa": "El sistema inteligente de comercio cuantitativo implementa automáticamente estrategias de comercio, lo que puede capturar oportunidades de comercio de manera rápida y precisa, evitando errores humanos e interferencia emocional.",
	"components.fuwu.6k6y74": "Precisión",
	"components.fuwu.y23qq8": "El sistema inteligente de comercio cuantitativo utiliza una gran cantidad de datos históricos e información de mercado para el análisis de datos y la predicción de modelos, mejorando la precisión y fiabilidad de las decisiones de transacción.",
	"components.fuwu.s11cqu": "Estabilidad",
	"components.fuwu.3q6d66": "El sistema inteligente de comercio cuantitativo implementa una estricta gestión de riesgos y control de transacciones para garantizar la estabilidad de la cartera y el rendimiento a largo plazo.",
	"components.fuwu.w386vn": "Flexibilidad",
	"components.fuwu.530y3q": "El sistema inteligente de comercio cuantitativo puede adaptarse rápidamente a los cambios del mercado y las nuevas oportunidades de comercio, al tiempo que permite personalizar las estrategias y parámetros de comercio de acuerdo con las preferencias de los inversores.",
	"components.fuwu.ph3l81": "Trazabilidad",
	"components.fuwu.7ap85x": "El sistema inteligente de transacciones cuantitativas registra y rastrea los detalles y datos históricos de cada transacción, lo que facilita el análisis y optimización posteriores de los inversores.",
	"components.fuwu.v2jkcm": "Ecosistemas",
	"components.fuwu.ics30f": "El sistema inteligente de comercio cuantitativo",
	"components.fuwu.0nui8m": "Como núcleo, se realiza el suministro descentralizado de computación de alto rendimiento. El sistema se centra en la construcción de infraestructuras como servicios para la inteligencia artificial",
	"components.fuwu.n868ra": "Capa. Todas las plataformas que requieren computación de alto rendimiento son servicios",
	"components.fuwu.ns02co": "Y el software como servicio",
	"components.fuwu.f94h07": "Los servicios de nivel se pueden encontrar en",
	"components.fuwu.6ftu9v": "El sistema inteligente de comercio cuantitativo funciona libremente.",
	"components.fuwu.b19msv": "Tecnología de cadena de bloques",
	"components.fuwu.864583": "El núcleo del sistema inteligente de comercio cuantitativo radica en la tecnología de cadena pública subyacente, que combina modelos financieros, inteligencia artificial y transacciones cuantitativas para manejar un alto rendimiento de transacciones. Por lo tanto,",
	"components.fuwu.61j6bp": "La cadena principal del sistema inteligente de comercio cuantitativo adopta",
	"components.fuwu.q1j1n6": "El mecanismo de consenso y la combinación",
	"components.fuwu.30nl6v": "La practicidad mejora la capacidad tolerante a fallas, la eficiencia de las transacciones y la frecuencia de interacción del sistema. La red está compuesta por",
	"components.fuwu.3kqnle": "Se compone de varios supernodos, que son responsables de la verificación de transacciones y la generación de bloques. Además de los supernodos, también hay nodos convencionales que sincronizan los datos con los nodos centrales, gestionan las claves y direcciones privadas de la billetera y inician transacciones en la cadena. Para satisfacer las necesidades de modelado de inteligencia artificial y transacciones cuantitativas de alta frecuencia,",
	"components.fuwu.i1g6e7": "El sistema inteligente de comercio cuantitativo promueve constantemente el desarrollo de la tecnología de cadena pública subyacente. Al optimizar el mecanismo de consenso y utilizar la tecnología de cadena lateral, se logra un mayor",
	"components.fuwu.j3spal": "Manejar más transacciones",
	"components.fuwu.d6r6r2": "Manejar más transacciones simultáneamente y apoyar el aumento del tráfico de usuarios.",
	"components.fuwu.rfs8eb": "El sistema inteligente de comercio cuantitativo realiza un mecanismo de cadena cruzada e introduce un mecanismo de protección de la privacidad de certificación de conocimiento cero para garantizar que la información del usuario no se filtre. El sistema se registra en la cadena de bloques de una manera unificada",
	"components.fuwu.5l87r6": "Aplicación que garantiza que todos los datos sean abiertos, transparentes y a prueba de manipulación una vez que se confirmen los activos a través de contratos inteligentes. Por lo tanto, el uso",
	"components.fuwu.ci8bt7": "Las transacciones de activos compartidos se realizan sobre la base de datos completamente confiables, eliminando el riesgo de activos falsos o transacciones falsas.",
	"components.fuwu.li76g2": "Estructura general",
	"components.fuwu.2881li": "Los nodos de red del sistema inteligente de comercio cuantitativo pueden ser nodos de computación a gran escala, como grandes piscinas mineras, o",
	"components.fuwu.u15fc6": "Nodos de computación de tamaño mediano o recursos de computación en la nube proporcionados por plataformas como Alibaba cloud.",
	"components.fuwu.2w1u75": "La capacidad de computación en la nube y en el borde obtenida por el sistema inteligente de comercio cuantitativo garantiza la estabilidad del entorno de funcionamiento de la inteligencia artificial. Una vez que los inversores",
	"components.fuwu.6cu4ph": "Depósito",
	"components.fuwu.88fccf": "Sistema inteligente de comercio cuantitativo, la inteligencia artificial se basará en",
	"components.fuwu.4gfpl8": "El número es buscado, identificado, jerárquico, gestionado y guardado. Posteriormente",
	"components.fuwu.5vdauc": "A través",
	"components.fuwu.0ud3oi": "En la red, correspondiente",
	"components.fuwu.8sm2by": "Y los nodos de computación para encontrar los adecuados para este lote",
	"components.fuwu.189o1y": "Cadena de bloques de modo de transacción. La capacidad de cálculo necesaria para este modelo se asigna a la correspondiente",
	"components.fuwu.y83nn2": "Lograr transacciones cuantitativas.",
	"components.fuwu.78ik3b": "3.2.2 arquitectura de nodos informáticos",
	"components.fuwu.0wdoj7": "Arquitectura de nodos informáticos",
	"components.fuwu.25g0dk": "3.2.3 Big Data + inteligencia artificial",
	"components.fuwu.r2e1x7": "El sistema inteligente de comercio cuantitativo contiene una red informática distribuida de alto rendimiento, que es esencialmente una infraestructura que combina Big data e inteligencia artificial. Tiene las siguientes características",
	"components.fuwu.n4ct1t": ": el marco subyacente se construye en la Plataforma matrix, utilizando una arquitectura de software basada en suscripciones topic, procesamiento impulsado por eventos y procesadores. A través de una arquitectura asíncrona de alto rendimiento, puede lograr un rendimiento excelente. La estructura de múltiples cadenas se compone de la cadena principal y la cadena de trabajo. La cadena principal contiene la definición del patrón de todas las cadenas de trabajo, y el número de cadenas de trabajo no está limitado. Cada cadena de trabajo se compone de cadenas de bloques de división, que admiten la División infinita. Los datos de bloque se basan en la definición del esquema, permiten un tamaño de bloque flexible y admiten el almacenamiento comprimido de los datos de bloque.",
	"components.fuwu.b9yr3c": "La comunicación y transmisión dentro del sistema inteligente de comercio cuantitativo utiliza un Protocolo de compresión de código propio o un Protocolo de transmisión cifrado. Esto puede reducir el ancho de banda de la red y negociar diferentes protocolos de Codificación de paquetes de mensajes dentro del mismo enlace, mejorando la seguridad de la transmisión.",
	"components.fuwu.92179q": "Red tridimensional",
	"components.fuwu.y24z29": ": basado en una red punto a punto (p2p), establece una red jerárquica para acelerar la difusión de mensajes. Los nodos SN tienen diversidad geográfica y pueden ajustar dinámicamente la estructura topológica de la red, mejorar la robustez de la red general, resistir los ataques DDoS y reducir los riesgos de Seguridad. Mecanismo de consenso de la red: adopta dpos & nbsp; (delegated & nbsp; proof & nbsp; de & nbsp; stake) mecanismo de consenso que combina los derechos y contribuciones de los usuarios para evaluar la participación en la red. Utiliza un método de procesamiento jerárquico para almacenar, consensuar y verificar, lo que mejora la escalabilidad de toda la red.",
	"components.fuwu.i5s5w9": "3.2.4 estructura de la red",
	"components.fuwu.248t2x": "Las principales soluciones para resolver la escalabilidad de la cadena de bloques se pueden dividir en dos tipos.",
	"components.fuwu.75e81d": ": escalabilidad vertical y escalabilidad horizontal. Las soluciones típicas de escalabilidad vertical incluyen algoritmos de consenso de optimización, almacenamiento a granel y mejora del rendimiento del sistema, como expansión de bloques, EOS & nbsp; Dpos y vrf + pos + pbft. Las soluciones típicas de escalabilidad horizontal incluyen redes relámpago, arquitectura de múltiples cadenas y arquitectura de fragmentación, que están diseñadas para resolver los problemas de escalabilidad y escalabilidad de la cadena de bloques. La cadena de bloques 4.0 tiene como objetivo construir un sistema de negocios distribuidos de alto rendimiento en una red descentralizada a gran escala, centrándose en la implementación real del negocio. Ninguna mejora tecnológica única puede resolver completamente el problema general de escalabilidad. El diseño arquitectónico general de cualquier cadena de bloques debe considerar cuatro elementos clave: arquitectura de red p2p, arquitectura de almacenamiento subyacente, arquitectura de computación / consenso y diseño de procesos de negocio. Además, en el mundo de la cadena de bloques, solo pueden haber dos atributos de diseño descentralizados, escalables y seguros, y todos los diseños escalables se basan en este principio.",
	"components.fuwu.854i8h": "El sistema inteligente de comercio cuantitativo adopta una arquitectura de múltiples cadenas, lo que mejora la escalabilidad de toda la red. Al utilizar el aislamiento proporcionado por múltiples cadenas, se puede aislar de manera segura.",
	"components.fuwu.kw1e7d": "Datos dapp. Con la ejecución paralela de múltiples cadenas, el rendimiento del sistema puede crecer linealmente. Sin embargo, bajo la arquitectura de múltiples cadenas, todavía hay cuellos de botella de rendimiento en una sola cadena. Por lo tanto, la introducción de la tecnología de fragmentación ha mejorado aún más el rendimiento de una sola cadena de bloques y ha logrado una escalabilidad horizontal. Introducir routers de cadena cruzada y capas de retransmisión de cadena cruzada entre múltiples cadenas para apoyar el retransmisión de subchainas homogéneas y cadenas laterales heterogéneas. A través del anclaje de la cadena del estator / Cadena lateral hasta el establecimiento de la cadena principal, la interconexión entre múltiples cadenas, se crea una arquitectura de cadena de bloques con escalabilidad infinita.",
	"components.fuwu.y11qiw": "3.2.5 la estructura de fragmentación se encuentra en",
	"components.fuwu.q7u6kb": "El objetivo principal de la adopción de la fragmentación en el sistema inteligente de comercio cuantitativo es mejorar el rendimiento de una sola cadena de bloques y la escalabilidad de la red general. Cada pieza procesa diferentes conjuntos de transacciones en paralelo, logrando el consenso dentro de la pieza y logrando la ejecución paralela de transacciones.",
	"components.fuwu.k61o0n": "3.2.5.1 la arquitectura completa de fragmentación consta de tres partes: fragmentación de la red, fragmentación computacional (transacción / consenso) y fragmentación de almacenamiento (fragmentación del estado). En primer lugar,",
	"components.fuwu.4t6qe4": "El sistema inteligente de comercio cuantitativo divide los nodos de la red en diferentes segmentos virtuales de acuerdo con las reglas de fragmentación.",
	"components.fuwu.7yx4qo": "(también conocido como clúster). Garantiza la conectividad dentro de cada segmento y la conectividad de red entre los nodos dentro del segmento y la red principal. Las transacciones dentro del chip solo se propagan dentro del chip para reducir el consumo de ancho de banda. En segundo lugar, todos los nodos que participan en el consenso deben proporcionar una fianza y registrarse en un contrato inteligente. Sobre la base de las reglas de fragmentación, podemos empaquetar y verificar las transacciones de los nodos de consenso que se producen en diferentes segmentos por los nodos de consenso de los segmentos correspondientes. Debido a que las diferentes transacciones de fragmentación se verifican en paralelo entre fragmentación, el rendimiento del consenso se puede ampliar linealmente. Con la mejora del rendimiento de la red, se vuelve inviable almacenar todo el dato del libro mayor en cada nodo de la red. Suponiendo que el rendimiento general de la red sea de 1 millón de tps, el espacio de almacenamiento de datos del libro mayor diario de cada nodo superará los 20 & nbsp; Tb. Para resolver este problema,",
	"components.fuwu.2wb32r": "El sistema inteligente de comercio cuantitativo separa la capa de almacenamiento de la capa de cálculo y utiliza el sistema de archivos de libro de cuentas para almacenar los datos del libro de cuentas.",
	"components.fuwu.69229m": "La red inteligente de sistemas de comercio cuantitativo se mantiene conjuntamente.",
	"components.fuwu.l65907": "3.2.5.2 cada nodo de la red P2P de fragmentos virtuales programables (vshard) tiene un peerid independiente que forma una red P2P estructurada o no estructurada con otros nodos. Sobre la base de diferentes necesidades comerciales, los nodos de red utilizan contratos inteligentes para la fragmentación virtual sobre redes P2P físicas. Cada nodo calcula su propio vshard & nbsp de manera programática; Id, perteneciente al mismo vshard & nbsp; El nodo del ID constituye lógicamente un segmento de red independiente. Cada fragmentación de la red proviene físicamente de una red P2P unificada. Sin embargo, está lógicamente aislado y proporciona la independencia lógica necesaria para el diseño de escalabilidad de la red funcional. Cada nodo de red P2P independiente puede pertenecer lógicamente a múltiples segmentos virtuales. Es necesario establecer una conexión de red entre los nodos de fragmentación para formar una red de fragmentación autónoma regional. La fragmentación es dinámica y flexible, permitiendo a la red aumentar flexiblemente el número de fragmentación. La red principal todavía puede establecer redes P2P no estructuradas y estructuradas.",
	"components.fuwu.255q2n": "3.2.5.3 calcular los nodos de consenso divididos: se dividen en nodos de consenso global y nodos de consenso interno. El nodo de consenso global es responsable de llegar a un consenso en la red principal, mientras que el nodo de consenso dentro del segmento es responsable de procesar el consenso dentro de cada segmento. El consenso puede adoptar un mecanismo plug - in que permita que diferentes segmentos apoyen diferentes mecanismos de consenso. Protocolo de generación aleatoria distribuida: el Protocolo aleatorio necesita ser justo, impredecible, verificable y escalable por terceros. El nodo principal de consenso de segmentos utiliza el algoritmo vrf para asignar segmentos y es responsable aleatoriamente del Consenso de diferentes segmentos. Los nodos de consenso de diferentes segmentos procesan las transacciones dentro de sus respectivos segmentos. Presenciar la selección de nodos: seleccionar aleatoriamente el Pool de nodos de consenso divididos en nodos para participar en el proceso real de consenso. Los nodos de consenso evitan ataques maliciosos a través de elecciones periódicas. El número de nodos de consenso por pieza puede aumentar de manera flexible. Los nodos de consenso evitan ataques maliciosos a través de elecciones periódicas. El número de nodos de consenso por pieza puede aumentar de manera flexible.",
	"components.fuwu.vr9w4q": "3.2.5.4 División de asuntos",
	"components.fuwu.524h25": "El sistema inteligente de comercio cuantitativo divide las transacciones en transacciones intratabletas y transacciones intertabletas.",
	"components.fuwu.25ebl0": "La Dirección de transacción de shard está compuesta por la dirección de transacción y vshard & nbsp; Composición de Id. Si la dirección del remitente y la dirección del receptor de una transacción pertenecen al mismo fragmento, la transacción es una transacción dentro del fragmento. Si la dirección del remitente y del receptor pertenece a un segmento diferente, se trata de una transacción entre segmentos. Insurance & nbsp; Will & nbsp; Be & nbsp; Cada & nbsp; A & nbsp; E & nbsp; Trabajadores & nbsp; The & nbsp; Transacciones dentro de la División tsa: las transacciones solo se propagan dentro de la División vshard y son empaquetadas y verificadas por el nodo de consenso de la división. Transacciones entre segmentos: empaquetadas y verificadas por el nodo principal de consenso de segmentos",
	"components.fuwu.737wf6": "3.2.6 rastreadores de red",
	"components.fuwu.6ub735": "El funcionamiento del sistema inteligente de comercio cuantitativo depende de las capacidades de recopilación, análisis y modelado de datos.",
	"components.fuwu.3917n5": "El sistema inteligente de comercio cuantitativo utiliza inteligencia artificial para recopilar datos de toda la red de manera regular y cuantitativa a través de métodos basados en la nube. Pasa",
	"components.fuwu.540486": "La integración se integra perfectamente con el sistema cuantitativo de inteligencia artificial. Luego, el sistema realiza la selección, filtrado, análisis y modelado para proporcionar información visual al personal de back - end y proporcionar soporte de datos para la inteligencia artificial.",
	"components.fuwu.i576fn": "Miembros del equipo",
	"components.fuwu.8a523u": "Cliff & nbsp; Asness en Eugene & nbsp, un famoso economista y ganador del Premio Nobel de economía 2013; Bajo la Guía del Profesor fama, obtuvo una doble Licenciatura de la escuela de negocios Wharton de la Universidad de Pensilvania y la escuela de ingeniería y ciencias aplicadas.",
	"components.fuwu.clunb8": "Capital Management es uno de los fondos de cobertura cuantitativos más famosos de Wall street. Ha servido",
	"components.fuwu.w47xsg": "Goldman, & nbsp; Sachs & nbsp; /.. Nbsp; Co. Director Gerente y Director de investigación cuantitativa del Departamento de gestión de activos. Es el & nbsp; Journal & nbsp; De & nbsp; Portfolio & nbsp; Miembro del Comité Editorial de management, miembro del Comité de gestión de la facultad de matemáticas y Finanzas de la Universidad de Nueva york, miembro del Consejo de Administración del Grupo q, miembro del Consejo de Administración del Comité Internacional de rescate y el & nbsp; Miembros del Consejo de Administración de NTN",
	"components.fuwu.8v041z": "David",
	"components.fuwu.1yi18o": "(...); Jefe fundador de kabil",
	"components.fuwu.f126cm": "Años de experiencia en inversión financiera,",
	"components.fuwu.551s9t": "Licenciada en Administración de empresas, licenciatura, Universidad northwestern, Vicepresidenta de Goldman sachs, Sachs",
	"components.fuwu.638xk0": "Liu John EPOCH EPT",
	"components.fuwu.2d27j5": "Principalmente",
	"components.fuwu.891s7f": "Doctor en experiencia en inversión financiera, Maestría en Administración de empresas y licenciatura en letras por la Universidad de chicago, donde obtuvo una licenciatura en economía, fue elegido",
	"components.fuwu.khhc66": "Miembros y continúa obteniendo en Chicago",
	"components.fuwu.qad15u": "Y un doctorado en finanzas.",
	"components.fuwu.ummboy": "Paul",
	"components.fuwu.f1uc41": "(...); Jacobs",
	"components.fuwu.7du1a3": "Años de experiencia en desarrollo tecnológico el Jefe de tecnología de EPOCH EPT trabajó como ingeniero de software en Google y tiene el Instituto Politécnico de la India.",
	"components.fuwu.7964ma": "Una licenciatura de la universidad, una maestría de la Universidad de Stanford y una maestría de la escuela de negocios Wharton de la Universidad de pensilvania.",
	"components.fuwu.7m912c": "Grado. Responsable",
	"components.fuwu.nj2151": ", la barra de herramientas de Google y participó en el desarrollo del sistema operativo de google, una persona que también sirvió como Google",
	"components.fuwu.itkrrj": "Presidente, familiarizado con esta aplicación.",
	"components.fuwu.c7gc6f": "Michel",
	"components.fuwu.27102u": "(...); Agassi tiene 25 años de experiencia laboral principal, doctor del Instituto Tecnológico de massachusetts, Licenciatura de la Universidad Brown",
	"components.fuwu.5549r8": "Puestos:",
	"components.fuwu.qtx3m3": "EPOCH & nbsp; EPT & nbsp; División de Ia cuantitativa a corto plazo de 3 - 5 días",
	"components.fuwu.xj5p65": "Famoso dicho: el mercado cambia rápidamente, aprovecha la oportunidad a corto plazo, entra y sale rápidamente.",
	"components.fuwu.nbk6k7": "Gregor",
	"components.fuwu.o11m0w": "(...); M; Andrade tiene 21 años de experiencia principal, Doctorado de la Universidad de chicago, Licenciatura del Instituto Tecnológico de Massachusetts",
	"components.fuwu.58snmu": "EPOCH & nbsp; EPT & nbsp; División de Ia cuantitativa a corto plazo de 5 - 10 días",
	"components.fuwu.sdve3x": "Famoso dicho: suficiente experiencia en el mercado con nunca parar",
	"components.fuwu.3no8us": "AI intelligence, los beneficios son tan simples.",
	"components.fuwu.8ru302": "Bradley",
	"components.fuwu.6zk9gb": "(...); D; Asnis tiene 25 años de experiencia laboral principal, Doctorado en derecho por la facultad de derecho de Nueva york, Licenciatura en Literatura por la Universidad Brandeis",
	"components.fuwu.748c4r": "EPOCH & nbsp; EPT & nbsp; División de Ia cuantitativa a corto plazo de 15 - 20 días",
	"components.fuwu.ykoem3": "Famoso dicho: el tiempo suficiente puede traer mejores oportunidades y hacer que los beneficios sean más estables.",
	"components.fuwu.c8741k": "Jeffrey",
	"components.fuwu.mnj8g5": "(...); Bolduque tiene 19 años de experiencia como analista financiero con licencia, Bates college, Licenciatura en Administración de empresas, Universidad de chicago, 5. & nbsp;",
	"components.fuwu.pt4258": "EPOCH & nbsp; EPT & nbsp; División de Ia cuantitativa a corto plazo de 30 - 50 días",
	"components.fuwu.yhx273": "Famosa frase: soy muy optimista sobre la inversión a largo plazo y estable, y no hay nada mejor que esto.",
	"components.fuwu.l1axrb": "Eventos temporales",
	"components.fuwu.425wcf": "/ hito",
	"components.fuwu.n33tpy": "1998. creación y lanzamiento del primer producto de fondos de cobertura.",
	"components.fuwu.hch6ip": "2000. se ha puesto en marcha la primera estrategia bursátil internacional, cuyos rendimientos han ganado elogios internacionales.",
	"components.fuwu.51k20j": "2016. se abrió una oficina en Hong Kong para expandir sus operaciones en Asia.",
	"components.fuwu.e7b8ez": "2016. en el cuarto trimestre, EPOCH EPT estableció el Centro de investigación de inteligencia artificial en Hong Kong",
	"components.fuwu.gk53k8": "2019. segunda temporada",
	"components.fuwu.51y6x1": "Se publica la edición 1.0 del Libro Blanco",
	"components.fuwu.7p2h89": "2020. desarrollo de la cadena de bloques públicos EPOCH EPT en el primer trimestre",
	"components.fuwu.19t2i2": "2020. cuarto trimestre EPOCH EPT crea un gran centro de datos",
	"components.fuwu.00bmfi": "2021. primer trimestre",
	"components.fuwu.812he4": "El sistema de comercio cuantitativo está conectado al Centro de Big data EPOCH ept.",
	"components.fuwu.7vb4bx": "2021. temporada 3",
	"components.fuwu.i5s411": "El sistema de comercio cuantitativo inicia la prueba del paquete",
	"components.fuwu.4rzg05": "2022. segunda temporada",
	"components.fuwu.6f1vku": "El sistema de comercio cuantitativo logra un total",
	"components.fuwu.7j4ksr": "Un beneficio cuantitativo de 1.300 millones de dólares.",
	"components.fuwu.oox35c": "Birmingham, Reino Unido",
	"components.fuwu.t13534": "Registro y establecimiento",
	"components.fuwu.27p688": "EPOCH & nbsp; EPT también lanzó el primer sistema de manejo inteligente de Ia del mundo.",
	"components.fuwu.0at9r7": "6. & nbsp; En un futuro próximo, el valor de mercado puede predecir que miles de millones de personas almacenarán su riqueza en forma de activos digitales. Estos activos incluyen no solo monedas tradicionales y propiedades físicas, sino también activos digitales virtuales. Los activos digitales tienen múltiples atributos como la preservación del valor, la convertibilidad y la cobertura, lo que puede reducir el riesgo de depreciación o pérdida causada por crisis o conflictos financieros. Proporcionan un mecanismo para proteger y proteger los activos personales, dejando la interacción sin restricciones entre diferentes períodos y áreas. A medida que más y más usuarios poseen activos digitales, buscan inversiones estables para aumentar el valor de los activos digitales y hacer frente a los cambios en la economía real y la inflación. Esto es",
	"components.fuwu.r4o4y5": "El sistema de comercio cuantitativo es la mejor opción. En la actualidad, hay más de",
	"components.fuwu.yt83vn": "300 millones de personas participan en actividades de inversión en el mercado de la cadena de bloques, y el valor global de los activos digitales supera los 50.000 millones de dólares. Las transacciones de activos digitales de blockchain generan comisiones de más de 500 millones de dólares y tienen un gran potencial de mercado. Para preservar y agregar mejor valor, se adopta",
	"components.fuwu.74n7mh": "El sistema de comercio cuantitativo ha cambiado completamente los métodos tradicionales de comercio, permitiendo que más inversores en activos digitales obtengan mayores rendimientos y rendimientos. También ofrece a los inversores tradicionales la oportunidad de cubrir y crecer su riqueza. En el mundo del futuro, los mercados financieros serán el campo de las transacciones de activos digitales, mientras que",
	"components.fuwu.h335ga": "El sistema de comercio cuantitativo liderará el desarrollo de esta era. Mirando al mundo,",
	"components.fuwu.5173h3": "El sistema de comercio cuantitativo se ha establecido en el campo de la inversión financiera en el menor tiempo posible. Ha creado nuevos modelos de aplicación basados en la economía digital, los activos digitales y la tecnología de cadena de bloques.",
	"components.fuwu.jw5ueb": "7. + nbsp; En la actualidad, las autoridades financieras de Estados unidos, singapur, china, Suiza y Alemania han endurecido sus políticas o han emitido advertencias sobre la emisión inicial de tokens (ico) a medida que más y más criptomonedas se clasifican como Valores.",
	"components.fuwu.r3jm21": "Reconocer y cumplir con las regulaciones de valores de varias jurisdicciones principales y cumplir",
	"components.fuwu.2a723k": "KYC (conozca a su cliente) y las regulaciones AML (lucha contra el blanqueo de dinero). Específicamente, esto significa: reglas de valores: lea atentamente el contenido de esta sección. Si tiene alguna duda sobre las acciones que debe tomar, le recomendamos consultar a su Asesor legal, financiero, fiscal u otro profesional. La información enumerada en este documento puede no ser exhaustiva ni representar ningún elemento esencial de la relación contractual. Este documento no constituye inversión, impuestos, derecho, regulación, finanzas, contabilidad o cualquier otra recomendación, ni puede ser la única razón para participar en el apoyo y desarrollo del ecosistema del índice Hang seng. Antes de tomar cualquier decisión, los posibles compradores deben consultar a sus asesores legales, de inversión, fiscales, contables y de otro tipo para determinar los posibles beneficios, restricciones y otras consecuencias de tales transacciones. Ninguna parte de este documento es una oferta, prospecto o licitación, ni está destinada a ser un requisito para invertir en valores en ninguna jurisdicción. Este documento no se basa en ninguna ley o normativa de jurisdicción que prohíba o restrinja las transacciones relacionadas con tokens digitales o su uso. KYC y aml: conocer los detalles técnicos de las regulaciones de su cliente (kyc) y lucha contra el blanqueo de dinero (aml) puede variar de un país a otro, pero ambos requieren la verificación y el control cruzado de la identidad del cliente en función de una variedad de listas de terroristas, embargos y personas expuestas políticamente (pep).",
	"components.fuwu.n5ijvs": "Este proceso se está implementando en colaboración con proveedores de servicios profesionales. En el proceso de inversión popular, el inversor Kate",
	"components.fuwu.y06wpn": "8. + nbsp; Descargo de responsabilidad este documento no aborda ningún producto regulado dentro de la jurisdicción legal. Este documento es un documento conceptual (libro blanco) para la descripción del proyecto, solo para referencia y no para la venta o recaudación de acciones, valores o cualquier otro",
	"components.fuwu.5t61y5": "Productos relacionados y productos regulados de empresas afiliadas. Este documento no debe considerarse un prospecto ni ningún documento de cumplimiento estandarizado, ni constituye una propuesta o solicitud de inversión en valores o cualquier otro producto regulado en ninguna jurisdicción. Este documento no debe utilizarse como recomendación para la venta, suscripción o invitación a comprar o suscribir ningún valor o cualquier contrato, contacto o compromiso basado en ellos. No debe considerarse una propuesta de participación en la inversión. Cualquier información o análisis proporcionado en este documento no constituye ninguna propuesta para participar en la inversión en tokens, ni hará ninguna propuesta específica. Debe buscar todas las opiniones profesionales necesarias sobre asuntos relacionados, como la revisión fiscal y contable. Este documento no constituye ninguna declaración o garantía. Para explicar lo que proponemos",
	"components.fuwu.j73g2y": "- & nbsp; Sistema de comercio cuantitativo al, pero",
	"components.fuwu.3ip5we": "La Fundación aclara lo siguiente:",
	"components.fuwu.1x311y": "8.1 no se hace ninguna declaración ni garantía de ninguna otra forma de exactitud o integridad de ningún contenido descrito en este documento o de cualquier otro contenido relacionado con el proyecto publicado en este documento.",
	"components.fuwu.0c5427": "8.2 no se proporcionan declaraciones o garantías prospectivas o conceptuales sobre logros o viabilidad sin condiciones previas.",
	"components.fuwu.h6u91t": "8.3 nada de lo dispuesto en este documento sirve de base para cualquier compromiso o declaración futura.",
	"components.fuwu.if1i12": "8.4 La empresa no será responsable de ninguna pérdida causada por personas en relación con este libro blanco u otros aspectos.",
	"components.fuwu.m21co1": "8.5 La responsabilidad se limita a la mayor medida permitida por la ley aplicable y dentro de la responsabilidad legal inevitable. Riesgos relacionados con las políticas regulatorias regionales y nacionales pertinentes: la tecnología de cadena de bloques está apoyada y reconocida a nivel mundial, pero también está cuidadosamente revisada por varios reguladores.",
	"components.fuwu.t5i928": "El funcionamiento del sistema de comercio cuantitativo puede verse afectado por ciertas políticas regulatorias, incluidas, entre otras, restricciones al uso o tenencia.",
	"components.fuwu.77k38h": "Usdt, que puede obstaculizar o restringir",
	"components.fuwu.sxf326": "Desarrollo del sistema de comercio cuantitativo. Otros riesgos desconocidos",
	"components.fuwu.t62x6w": ": la tecnología blockchain y la tecnología de moneda digital correspondiente son tecnologías relativamente nuevas y no plenamente verificadas, que pueden tener riesgos imprevistos de diversas maneras.",
	"login.register.6l6651": "Registro telefónico",
	"login.register.bsu16p": "Confirmar contraseña",
	"login.register.8r578v": "La contraseña introducida dos veces es diferente.",
	"components.xieyi.z4tui6": "Bienvenido a usarlo",
	"components.xieyi.985i12": "Co., Ltd. (en adelante) '",
	"components.xieyi.k71g1d": "Nosotros",
	"components.xieyi.6473ug": "¡¡ productos y servicios de 'o' plataforma '! Para proporcionarle varios servicios técnicos bajo la plataforma, Procesaremos su información personal durante su operación. Somos conscientes de la importancia de la información personal para usted, su confianza es muy importante para nosotros, tomaremos las medidas de protección de seguridad correspondientes en estricta conformidad con los requisitos de las leyes y reglamentos en las condiciones técnicas vigentes y nos comprometeremos a proteger su información personal. Sobre esta base, desarrollamos esta política de privacidad (en adelante, 'esta política') para ayudarle a comprender completamente cómo recopilamos, utilizamos, compartimos, almacenamos y protegemos su información personal y cómo puede gestionarla para que pueda tomar las decisiones apropiadas mejor durante su uso de nuestros productos y servicios.",
	"components.xieyi.r997hr": "Antes de comenzar a usar nuestros productos y servicios, asegúrese de leer y comprender cuidadosamente esta política, centrándose especialmente en nosotros para",
	"components.xieyi.23i3k9": "Subrayado en negrita / negrita",
	"components.xieyi.5jo3lp": "Los términos del logotipo, asegúrese de comprender y aceptar completamente antes de comenzar a usarlo. Las palabras profesionales involucradas en esta política, tratamos de explicarle en términos concisos y populares para que pueda entenderlas.",
	"components.xieyi.sw2x3b": "Si tiene alguna duda, opinión o sugerencia sobre el contenido de esta política, puede aprobar",
	"components.xieyi.w1nc5l": "El servicio oficial de atención al cliente de la Plataforma se puso en contacto con nosotros.",
	"components.xieyi.u855jt": "Su consentimiento a la política de privacidad solo significa que ha entendido las funciones proporcionadas por la aplicación y la información personal necesaria para el funcionamiento de la función, y no significa que haya aceptado que podemos recopilar información personal no esencial, que le solicitará su consentimiento por separado de acuerdo con la autorización durante su uso.",
	"components.xieyi.jiyuvt": "Si rechaza esta política de privacidad, todavía puede usar las funciones básicas de navegación sin registro / inicio de sesión. También puede autorizar por separado durante el uso posterior del servicio.",
	"components.xieyi.61890q": "Esta política le ayudará a comprender lo siguiente:",
	"components.xieyi.5mt6j8": "I. definición y ámbito de aplicación",
	"components.xieyi.41xpk4": "2. Cómo recopilamos y utilizamos su información",
	"components.xieyi.83j8rl": "III. cómo usamos las cookies y tecnologías similares",
	"components.xieyi.8b639p": "4. cómo compartimos, transferimos y divulgamos públicamente su información",
	"components.xieyi.6dq28b": "V. Cómo protegemos su información",
	"components.xieyi.2ulrqx": "6. cómo almacenamos su información",
	"components.xieyi.3nz3ga": "7. cómo gestiona su información",
	"components.xieyi.9s39yt": "8. cómo manejamos la información de los menores",
	"components.xieyi.ew14nj": "9. cómo actualizar esta política",
	"components.xieyi.12eq82": "10. cómo contactarnos",
	"components.xieyi.b1sb46": "I) definición",
	"components.xieyi.we1z6j": "Birmingham, Reino Unido",
	"components.xieyi.rw8pei": "Registro de EPOCH EPT",
	"components.xieyi.idu1sg": "Co., Ltd.",
	"components.xieyi.m568up": "Plataforma:",
	"components.xieyi.hvxy61": "Dedo",
	"components.xieyi.73ijj4": "Sitio web (nombre de dominio www.weipaitang.com), cliente app..",
	"components.xieyi.h0re24": ") y medios de servicio como la cuenta pública de wechat, también conocido como '",
	"components.xieyi.ivy1b3": "',' plataforma '.",
	"components.xieyi.ki07fh": "Empresas afiliadas:",
	"components.xieyi.6c317m": "Dedo y",
	"components.xieyi.wr8y6r": "Empresas que tengan una relación de control directo o indirecto o una relación de impacto significativo entre sí, incluidas las tenencias",
	"components.xieyi.5865bv": "Accionistas con más del 5% de las acciones,",
	"components.xieyi.hty2r8": "Filiales,",
	"components.xieyi.36ty5w": "Otras empresas controladas por el controlador real, etc.",
	"components.xieyi.lsnc7x": "Empresas de pago",
	"components.xieyi.47x242": ": se refiere a su",
	"components.xieyi.gmmfq4": "Las compras de la plataforma ofrecen servicios de pago a las instituciones de pago.",
	"components.xieyi.k10tpg": "Información personal:",
	"components.xieyi.69e87n": "Se refiere a todo tipo de información relacionada con personas naturales identificadas o identificables registrada electrónicamente o de otra manera, excluyendo la información anonimizada.",
	"components.xieyi.o1m0al": "Información personal sensible:",
	"components.xieyi.2h5e47": "Se refiere a la información personal que, una vez filtrada o utilizada ilegalmente, puede conducir fácilmente a la violación de la dignidad humana de las personas naturales o poner en peligro la seguridad personal y patrimonial, incluida",
	"components.xieyi.2ipl66": "Biometría, creencias religiosas, identidad específica, salud médica, cuentas financieras, trayectoria",
	"components.xieyi.8122dw": "Información como, y",
	"components.xieyi.124tei": "Información personal de menores de catorce años",
	"components.xieyi.swn32o": "(en esta política, trataremos la información sensible a individuos específicos con",
	"components.xieyi.704x6v": "Negrita",
	"components.xieyi.274q3q": "Hacer una identificación significativa).",
	"components.xieyi.u2twjv": "Procesamiento de información personal:",
	"components.xieyi.9813ba": "Se refiere a la recopilación, almacenamiento, uso, procesamiento, transmisión, suministro, divulgación, eliminación de información personal.",
	"components.xieyi.rutsx1": "Niños",
	"components.xieyi.81twf5": ": se refiere a menores menores de 14 años.",
	"components.xieyi.5jsxyn": "Salvo acuerdo en contrario, las definiciones utilizadas en esta política y",
	"components.xieyi.hu1431": "Acuerdo de usuario de servicios de transacción",
	"components.xieyi.f61m93": "La definición en tiene el mismo significado.",
	"components.xieyi.4kd4rv": "Ii) ámbito de aplicación",
	"components.xieyi.s893e8": "Esta política se aplica a",
	"components.xieyi.p95qjo": "Con",
	"components.xieyi.1d536g": "Sitios web, clientes, applets, cuentas públicas y las nuevas formas de productos y servicios que le brindan con el desarrollo de la tecnología.",
	"components.xieyi.d02u4n": "Por ejemplo, hemos utilizado en nuestros productos o servicios y empresas afiliadas.",
	"components.xieyi.vd5142": "Productos o servicios (por ejemplo, uso",
	"components.xieyi.2ifgyw": "Si la cuenta de la Plataforma se inicia sesión), pero no hay una política independiente de privacidad, esta política también se aplica a esta parte de los productos o servicios. Si nosotros y las empresas afiliadas tenemos una política de privacidad separada para los productos o servicios que le proporcionan, los productos o servicios correspondientes aplicarán la política de privacidad correspondiente.",
	"components.xieyi.lzne8r": "Además de las actividades de recopilación y uso de información pertinentes descritas en esta política,",
	"components.xieyi.8o7eza": "Esta política no se aplica",
	"components.xieyi.c7i3mg": "Otros servicios prestados por vendedores de plataformas y otros terceros a usted, otros servicios aplican sus reglas de recopilación y uso de información personal, como la política de privacidad que se le indica por separado.",
	"components.xieyi.f4n8lb": ". Por ejemplo, cuando participas",
	"components.xieyi.51p7u0": "Cuando opera el EPOCH EPT",
	"components.xieyi.9ngrl3": ", que le explique sus reglas para recopilar y aplicar su información personal sobre la base de los requisitos de las leyes y reglamentos.",
	"components.xieyi.62e3v6": "De acuerdo con el Reglamento sobre el alcance de la información personal necesaria para los tipos comunes de aplicaciones de Internet móvil,",
	"components.xieyi.3n4mh2": "La App pertenece a 'en línea'",
	"components.xieyi.8q81vo": "Gestión financiera",
	"components.xieyi.57m6k2": "'Los servicios funcionales básicos son'",
	"components.xieyi.6891w4": "Inteligencia ai",
	"components.xieyi.s2ji36": "'La información personal necesaria bajo esta función incluye: su número de teléfono; Nombre (nombre), dirección y número de contacto del destinatario; Tiempo de pago, monto de pago, Canal de pago y otra información de pago.",
	"components.xieyi.wyy6r5": "Le proporcionaremos servicios convenientes de plataforma de comercio electrónico. para lograr la prestación de servicios, Procesaremos su información personal por separado en función de las diferentes funciones de la plataforma:",
	"components.xieyi.t1c3k8": "1. funciones básicas: para realizar las funciones básicas que le proporcionan nuestros productos y / o servicios, debe autorizarnos a recopilar y utilizar la información necesaria. Si se niega a proporcionar la información correspondiente, de lo contrario no podrá usar nuestros productos y / o servicios normalmente.",
	"components.xieyi.y7m0r2": "2. funciones adicionales: para implementar funciones adicionales que le proporcionen nuestros productos y / o servicios, puede elegir si autoriza la información que recopilamos y utilizamos. Si se niega a proporcionarlo, no podrá usar correctamente las funciones adicionales relevantes o no podrá lograr los efectos funcionales que pretendemos lograr.",
	"components.xieyi.cwp3h6": "Le recordamos especialmente:",
	"components.xieyi.63078v": "1. nos comprometemos a crear una variedad de productos y servicios para satisfacer sus necesidades.",
	"components.xieyi.11t174": "Debido a que le ofrecemos muchos tipos de productos y servicios, y hay diferencias en el alcance específico de los productos / servicios que los diferentes usuarios eligen utilizar, las funciones básicas / adicionales y el tipo y alcance de la información personal recopilada y utilizada correspondientes serán diferentes, por favor, tome como referencia la función específica de producto / servicio;",
	"components.xieyi.654y1j": "Para traerle una mejor experiencia de producto y servicio, seguimos trabajando para mejorar nuestros productos, servicios y tecnologías, y luego podemos lanzar funciones nuevas o optimizadas de vez en cuando, que pueden requerir la recopilación, el uso de información personal nueva o el cambio de propósito o fórmula de uso de información personal. En caso de lo anterior, le explicaremos por separado actualizando esta política, ventanas emergentes, consejos de página, etc., y le proporcionaremos una forma de elegir el consentimiento de forma independiente, y comenzaremos a recogerlo y usarlo después de obtener su consentimiento expreso.",
	"components.xieyi.228036": "En este proceso, si tiene alguna pregunta, opinión o sugerencia, puede ponerse en contacto con nosotros a través de los canales establecidos en esta política, y le responderemos lo antes posible.",
	"components.xieyi.3l69jv": "Los escenarios funcionales específicos que le proporcionaremos incluyen:",
	"components.xieyi.xq77st": "(1) registro de usuarios y gestión de cuentas",
	"components.xieyi.hgd0m6": "1. servicios básicos de usuario",
	"components.xieyi.sy4rg3": "Nos basamos en",
	"components.xieyi.3768od": "La cuenta de la plataforma le sirve. Para crear",
	"components.xieyi.k5pm58": "Cuenta de plataforma necesita proporcionarnos al menos su",
	"components.xieyi.ijyhl0": "Número de teléfono móvil, nombre de usuario y contraseña a usar",
	"components.xieyi.k04bce": ". Si se niega a proporcionar la información anterior, no podrá registrarse",
	"components.xieyi.4b0us6": "Cuenta de plataforma, pero todavía puede usar el Servicio de navegación y búsqueda.",
	"components.xieyi.3k9d16": "Puede modificar y complementar su apodo, sexo, región, contacto e información relacionada con su autenticación de nombre real, que pertenece a su 'información de cuenta'.",
	"components.xieyi.rqik5d": "Entre ellos, el apodo y el avatar que ha establecido se mostrarán públicamente.",
	"components.xieyi.ilmlc1": "La información de la cuenta que agregue nos ayudará a proporcionarle recomendaciones personalizadas de productos y una mejor experiencia de compra, pero si no proporciona esta información adicional, no afectará las funciones básicas de su uso de compras en línea.",
	"components.xieyi.2d8htr": "Para asegurarnos de que estamos prestando servicios a usted mismo, podemos verificar su identidad en función de la información anterior que usted proporcionó. Si hay escenarios en los que es necesario determinar la identidad de su usuario de acuerdo con la Ley (incluida la retención de impuestos y tasas para usted de acuerdo con la ley, la aplicación de la Ley administrativa o la identificación de sujetos relevantes en procedimientos judiciales, etc.), usted nos autoriza a obtener la información de certificación relevante correspondiente de sus socios para Los fines anteriores.",
	"components.xieyi.qimk2p": "Dependeremos de usted.",
	"components.xieyi.z57g36": "El uso de la cuenta de la Plataforma y las reglas de cálculo del nivel de la cuenta establecidas por la plataforma determinan su nivel actual y le proporcionan los derechos e intereses básicos correspondientes al nivel de la cuenta correspondiente.",
	"components.xieyi.21n38j": "2. servicios adicionales",
	"components.xieyi.dh7o8w": "Certificación de persona real: puede proporcionar la correspondiente de acuerdo con los requisitos de certificación.",
	"components.xieyi.57s046": "Información de identidad (dni, pasaporte, libro de registro de hogares e información de otros documentos de identidad), características biométricas (características faciales estáticas o dinámicas)",
	"components.xieyi.eb3ena": "Para completar la certificación de persona real.",
	"components.xieyi.2fnv9d": "Inicio de sesión autorizado: es posible que compartamos la información de su cuenta (avatares, apodos y otra información solicitada en la página) con terceros con su consentimiento, lo que le permite realizar fácilmente el registro de una cuenta de terceros o iniciar sesión directamente en un tercero. Además, es posible que obtengamos información de su cuenta de terceros de terceros como Wechat de acuerdo con su autorización y trabajemos con su",
	"components.xieyi.2586kj": "Las cuentas de la plataforma están vinculadas para que pueda iniciar sesión y usar nuestros productos y / o servicios directamente en terceros. Usaremos su información relevante dentro del alcance de su consentimiento autorizado.",
	"components.xieyi.75o8vj": "Otros servicios de rol de usuario: si solicita registrarse como vendedor u otros roles de usuario con requisitos adicionales de autenticación de identidad, debe tomar la iniciativa de proporcionarnos información de identidad y / o información relacionada con la empresa para la verificación, registro, publicidad y otros fines que informamos claramente de su identidad y otros roles de usuario para obtener la calificación. Como '",
	"components.xieyi.ni3907": "Gestión Financiera Autorizada",
	"components.xieyi.1168tn": "'Usted acordó que recopilaremos su número de teléfono móvil y número de identificación para la gestión del sistema de nombre real de la cuenta de transmisión en vivo, así como para recopilar el id del dispositivo, el modelo de marca del dispositivo, el sistema operativo del dispositivo, la ubicación geográfica para la coincidencia de funciones de transmisión en vivo y el control de Seguridad de transmisión en vivo.",
	"components.xieyi.k2wd14": "3. autenticación",
	"components.xieyi.1ns543": "Para cumplir con las disposiciones legales y los requisitos regulatorios pertinentes, garantizar la autenticidad de la identidad del usuario (incluida la protección de los derechos e intereses de los menores de acuerdo con la ley, la lucha contra el fraude en las redes de telecomunicaciones, la retención y el pago del impuesto sobre la renta de las personas físicas, la aplicación de la ley administrativa o la identificación de los sujetos pertinentes en los procedimientos judiciales), lograr el control de riesgos como la lucha contra el fraude y garantizar la seguridad del sistema y el servicio, necesitamos recopilar su información relevante para la autenticación cuando utilice servicios / funciones específicas. Por ejemplo: [modificar el número de teléfono móvil] cuando recupere su cuenta utilizando la función 'modificar el número de teléfono móvil', los usuarios individuales pueden recopilar su información de identidad, incluida su",
	"components.xieyi.3lj887": "Nombre real, número de identificación, información de reconocimiento facial, información de pedido",
	"components.xieyi.7y2f63": "Se utiliza para confirmar su identidad y ayudarlo a recuperar su cuenta de plataforma.",
	"components.xieyi.y57u7o": "Usuarios corporativos podemos recopilar información sobre su identidad corporativa, incluyendo",
	"components.xieyi.82yye6": "Información de la licencia comercial de la empresa, nombre real de la persona jurídica de la empresa, número de identificación, información de reconocimiento facial",
	"components.xieyi.t6tsvp": "Si ya lo tiene",
	"components.xieyi.3coa6i": "Cuenta de la plataforma, podemos estar en",
	"components.xieyi.4g34dh": "La información personal anterior de usted se muestra en el Servicio de la plataforma, así como su",
	"components.xieyi.i3lx1u": "En la plataforma o con",
	"components.xieyi.cd0jo8": "Operaciones realizadas en productos y servicios vinculados a la cuenta de la plataforma, incluso a través de",
	"components.xieyi.f5dq1g": "La cuenta de la Plataforma se centra en mostrar sus datos personales, derechos e intereses preferenciales, pedidos de transacciones",
	"components.xieyi.vo3428": ". Le respetaremos.",
	"components.xieyi.y3j2fo": "Selección hecha por la configuración de la cuenta de la plataforma.",
	"components.xieyi.pw3g77": "(2) mostrarle información sobre bienes, servicios y mensajes",
	"components.xieyi.82aac2": "1. para mostrarle información sobre bienes o servicios, incluidas sus huellas de acceso, búsquedas históricas, recopilaremos y utilizaremos su información en el acceso o uso.",
	"components.xieyi.h4s010": "Registros de navegación y búsqueda en la plataforma. Combinaremos la información del dispositivo recopilada de acuerdo con la ley, la información de registro de servicio y otra información autorizada por usted para predecir sus características de preferencia a través del modelo algorítmico. Le enviaremos anuncios comerciales y otros mensajes que puedan ser de su interés, o le enviaremos mensajes de texto comerciales basados es en sus características de preferencia.",
	"components.xieyi.kf81h2": "Los algoritmos de recomendación y recuperación personalizados predicen sus características de preferencia basadas en modelos, que coinciden con los bienes, servicios u otra información que pueden interesarle, y ordenan los bienes, servicios u otra información que se le muestran. De acuerdo con el comportamiento de navegación durante su uso del producto, retroalimentaremos el modelo de recomendación en tiempo real y ajustaremos y optimizaremos constantemente los resultados de la recomendación. Para satisfacer sus múltiples necesidades, introduciremos tecnologías de recomendación diversificadas en el proceso de clasificación, ampliaremos el contenido recomendado y evitaremos la concentración excesiva del mismo tipo de contenido.",
	"components.xieyi.182j2p": "Información del dispositivo: recibiremos y registraremos la información relacionada con el dispositivo que utiliza (incluyendo el modelo del dispositivo, la versión del sistema operativo, la configuración del dispositivo, la Dirección MAC y el imei, idfa, oaid, android) de acuerdo con sus operaciones específicas en el acceso y / o uso.",
	"components.xieyi.1ns292": "Identificación del dispositivo id, entorno del dispositivo, información de características de software y hardware de la lista de aplicaciones móviles), información relacionada con la ubicación del dispositivo (incluida la ubicación GPS autorizada por usted y la información del punto de acceso wlan, Bluetooth y sensores de la estación base).",
	"components.xieyi.y2hur5": "Información de registro de servicio: cuando accede o utiliza",
	"components.xieyi.2l7v45": "Al momento de la plataforma, recopilamos y registramos automáticamente sus detalles de uso y los guardamos como registros de servicio, incluyendo registros de navegación, registros de visualización de clics, registros de consulta de búsqueda, espectadores, informes, transacciones, post - venta, atención, intercambio de información, información de publicación y dirección ip, tipo de navegador, operador de telecomunicaciones, lenguaje de uso, fecha y hora de acceso.",
	"components.xieyi.i2q27g": "Tenga en cuenta que la información separada del dispositivo y la información de registro de servicio son información que no puede identificar la identidad de una persona física específica.",
	"components.xieyi.48iqgk": "Si combinamos este tipo de información no personal con otra información para identificar a una persona física específica, o la combinamos con información personal, este tipo de información no personal se considerará información personal durante el uso combinado, y anonimizaremos y desidentificaremos este tipo de información, a menos que obtenga su autorización o las leyes y reglamentos dispongan otra cosa.",
	"components.xieyi.2e8s23": "Al mismo tiempo, nos esforzamos por garantizar su experiencia de privacidad a través de las siguientes medidas:",
	"components.xieyi.83qc71": "(1) si desea eliminar sus registros de navegación y búsqueda, puede elegir eliminarlos en 'my - footprint' y 'search box - Recent search';",
	"components.xieyi.72qs2r": "(2) si necesita ver resultados de búsqueda que no estén dirigidos a sus características personales al usar el Servicio de búsqueda en la estación que ofrecemos, puede hacer clic en 'filtrar' en la página de resultados de búsqueda para configurarlos;",
	"components.xieyi.35ps8a": "(3) si desea gestionar los anuncios personalizados que le enviamos, puede hacerlo en '",
	"components.xieyi.777kl1": "Mi - configuración (esquina superior derecha) - privacidad - personalización '",
	"components.xieyi.8026t2": "Se establece en. Hay que tener en cuenta que puede configurar que no usamos ciertas categorías de información para mostrarle anuncios relacionados, pero todavía verá anuncios, el número de anuncios que verá no cambiará, pero la relevancia de los anuncios disminuirá.",
	"components.xieyi.5hx7h7": "Si desea gestionar el contenido personalizado que le empujamos, puede hacerlo en '",
	"components.xieyi.5vxbqy": "Mi - configuración (esquina superior derecha) - privacidad - personalización",
	"components.xieyi.8wfq82": "'Se establece en. Después del cierre, el contenido de los módulos de recomendación relacionados con 'recomendar, recomendarte' es recomendar recomendaciones no personalizadas al azar.",
	"components.xieyi.f98v89": "(4) si desea eliminar la etiqueta de pasatiempos con sus características personales, puede pasar",
	"components.xieyi.3ve6e7": "(5) si no está interesado en los productos que se muestran en la página de inicio, puede elegir 'no está interesado en tomar fotos' siguiendo la imagen del producto recomendado durante mucho tiempo y seleccionando 'no está interesado en tomar fotos' de acuerdo con la ventana emergente del recordatorio, reduciremos la exhibición de productos relacionados.",
	"components.xieyi.6mlx8c": "2. servicio de empuje de información comercial y recordatorio de actividades",
	"components.xieyi.1y06bp": "Para que entienda y use mejor",
	"components.xieyi.t45916": "Usted está de acuerdo en que le enviamos mensajes comerciales y otros recordatorios de actividades,",
	"components.xieyi.q2y74e": "Su proveedor de servicios recopilará y utilizará su nombre e información de contacto sobre la base de los fines del servicio anterior.",
	"components.xieyi.84b8nh": "Si no quiere recibir nuestro mensaje de empuje,",
	"components.xieyi.eh6abn": "Se puede cerrar a través de 'mi - configuración (esquina superior derecha) - configuración del mensaje'",
	"components.xieyi.3drpe9": "Si no quiere aceptar los anuncios comerciales que le enviamos,",
	"components.xieyi.16389e": "Puede darse de baja o cerrar a través de un mensaje de texto para responder a la cancelación o de otros medios que proporcionamos;",
	"components.xieyi.i69xy9": "Si cierra o cancela la función de empuje, no podrá obtener el Servicio de empuje de información de bienes o eventos.",
	"components.xieyi.2s65pg": "3. información del sensor.",
	"components.xieyi.vn4s8x": "Cuando se utiliza nuestro producto / servicio, es posible que recopilemos información de sus sensores para adaptarse al Estado de su dispositivo o para identificar sus movimientos cuando elija participar en la actividad, y los tipos de sensores en diferentes escenarios pueden ser inconsistentes, solo lo usaremos en el campo que necesite específicamente. Por ejemplo, cuando se filma, se utiliza para juzgar si el usuario gira la pantalla cuando se filma, lo que facilita la corrección y optimización del video filmado.",
	"components.xieyi.opxu33": "(3) le ofrece funciones de visualización, atención y compartir",
	"components.xieyi.46wa51": "En su navegación",
	"components.xieyi.sm4d35": "Durante el proceso de la plataforma, puede elegir ver los bienes, servicios y / u otros usuarios interesados y compartir información de bienes / servicios con terceros a través de los componentes funcionales que ofrecemos. Durante su uso de las funciones anteriores, recopilaremos",
	"components.xieyi.qt6mj3": "Operación",
	"components.xieyi.k465t1": "Registros,",
	"components.xieyi.13pdf3": "Cooperación",
	"components.xieyi.j6413h": "La información de registro de servicios, incluida la relación y el historial compartido, se utiliza para realizar las funciones anteriores y otros fines que informamos claramente.",
	"components.xieyi.g5yo4i": "Puede eliminar la información de su espectador y atención en 'mi - espectador' y 'mi - atención'.",
	"components.xieyi.es68il": "(4) ayudarlo a completar el pedido y la gestión del pedido",
	"components.xieyi.439hc7": "Cuando usted pide un producto y / o servicio específico en nuestro producto y / o servicio, le generaremos un pedido para comprar el producto y / o servicio a través del sistema. Durante el proceso de pedido, debe proporcionar al menos su",
	"components.xieyi.4zfg7p": "Nombre del destinatario, dirección de recepción, número de contacto del destinatario",
	"components.xieyi.875811": "Para algunos tipos especiales de bienes y servicios, también necesita proporcionar otra información necesaria para ese bien o servicio, como",
	"components.xieyi.sg3r7o": "Al comerciar con reliquias culturales, debe proporcionar su nombre real y número de identificación para completar el registro requerido por las leyes y reglamentos relacionados con la protección de reliquias culturales y las autoridades reguladoras de reliquias culturales.",
	"components.xieyi.x52n3c": "Al mismo tiempo, el pedido indicará la información de los bienes y / o servicios que ha comprado, el número de producto específico y la cantidad que debe pagar. Recopilamos esta información para ayudarle a completar la transacción sin problemas, garantizar la seguridad de su transacción, facilitar su consulta de información de pedidos, proporcionar servicio al cliente y post - venta y otros propósitos que le informamos claramente.",
	"components.xieyi.633jos": "Puede pasar",
	"components.xieyi.s43d5n": "La plataforma ordena bienes y / o servicios para otras personas, y usted necesita proporcionar la información personal anterior del destinatario real y asegurarse de que ha obtenido el consentimiento del destinatario real.",
	"components.xieyi.q3y8ui": "Para facilitar su comprensión, consulta y gestión de la información del pedido, recopilaremos la información del pedido generada por usted durante el uso de nuestros servicios para mostrarle y facilitar su gestión del pedido.",
	"components.xieyi.prc15q": "Puede ver y gestionar la información de su pedido a través de 'mi - mi pedido'.",
	"components.xieyi.ncd26i": "(5) ayudarlo a completar el pago",
	"components.xieyi.3u52sy": "Para completar el pago del pedido, debe elegir el método de pago y el canal de pago al pagar. Compartiremos su información personal, la información relacionada con el pago de pedidos, la información relacionada con equipos relacionados con la seguridad de pedidos y la información necesaria requerida por otras leyes contra el blanqueo de dinero con las empresas de pago a través del paquete de desarrollo de herramientas de la empresa de pago (el paquete de desarrollo de herramientas de software se llama 'sdk' en esta política) incrustado en el sitio web o cliente. Si elige que otras instituciones financieras le presten servicios de pago, también compartiremos la información necesaria para el pago con su tarjeta bancaria, incluido el número de tarjeta bancaria y el período de validez, con las instituciones financieras correspondientes que elija.",
	"components.xieyi.m65845": "Para informarnos y confirmar oportunamente el progreso y el Estado de su pago y proporcionarle servicios post - venta y resolución de disputas, está de acuerdo en que podemos recopilar información relacionada con el progreso del pago del objeto de transacción de su elección, la empresa de pago u otra institución financiera de su elección.",
	"components.xieyi.bydbtx": "Puede ver su",
	"components.xieyi.rarv1g": "Información de la cuenta bancaria vinculada a la cuenta de la billetera de la plataforma o solicitud de cambio de la cuenta bancaria vinculada.",
	"components.xieyi.73148a": "(6) ayudar a completar la entrega de bienes o servicios a usted",
	"components.xieyi.f8ce6b": "Para garantizar que los bienes y / o servicios que compra puedan ser entregados y proporcionados sin problemas, de manera segura y precisa, usted sabe y está de acuerdo en que el sujeto de distribución logística correspondiente debe conocer y utilizar inevitablemente su información de distribución para completar el propósito de entrega.",
	"components.xieyi.wi14jv": "Para proporcionarle servicios post - venta y resolución de disputas, necesitamos conocer y confirmar oportunamente el progreso y el Estado de la entrega, y usted está de acuerdo en que podemos recopilar información relacionada con el progreso de la entrega del cuerpo principal del servicio relacionado con la logística.",
	"components.xieyi.h3txyu": "(7) servicio al cliente y manejo de disputas",
	"components.xieyi.75p62d": "Cuando se ponga en contacto con nosotros o presente una solicitud de resolución de disputas durante, después de la venta o disputas, para garantizar la seguridad de su cuenta y sistema,",
	"components.xieyi.u3u59b": "Necesitamos que proporcione la información personal necesaria para verificar su identidad de usuario.",
	"components.xieyi.r9ucq4": "Para facilitar el contacto con usted, ayudarlo a resolver el problema lo antes posible o registrar el plan de procesamiento y los resultados de los problemas relacionados, podemos guardar su comunicación con nosotros, registro de comunicación / llamada / video y contenido relacionado (incluida la información de la cuenta, la información del pedido, otra información que usted proporcionó para demostrar los hechos relevantes, o La información de contacto que dejó), si consulta, se queja o Asesora sobre un pedido específico, Usaremos la información de su cuenta y la información del pedido.",
	"components.xieyi.te87ps": "Para proporcionar el servicio y mejorar las necesidades razonables de la calidad del servicio, también podemos usar su otra información, incluida la información relevante que proporcionó cuando se puso en contacto con el servicio al cliente y la información de respuesta al cuestionario que nos envió cuando participó en el cuestionario.",
	"components.xieyi.259477": "(8) le proporciona funciones de publicación pública de comentarios, preguntas y respuestas y otra información.",
	"components.xieyi.67h2ep": "Puede publicar públicamente información a través de las funciones de comentarios, comunidades, transmisión en vivo y otras funciones de publicación de información que le proporcionamos, incluyendo la publicación de contenido gráfico / de video, la publicación de contenido de evaluación, etc., como usuario, así como la publicación de información relacionada con bienes y / o servicios, información relacionada con tiendas como vendedor.",
	"components.xieyi.194252": "Es posible que juzguemos si puede disfrutar de los permisos funcionales correspondientes en función del tipo de usuario y la información de registro de la red (por ejemplo, solo los usuarios que han comprado un producto pueden publicar una evaluación de este producto).",
	"components.xieyi.x8rr9h": "Tenga en cuenta que la información que publica públicamente puede involucrar información personal de usted o de otras personas o incluso información personal sensible. si elige subir imágenes que contengan información personal en su evaluación, considere con más cuidado si comparte o incluso comparte públicamente información relevante al usar nuestros servicios. Si la información que publica públicamente involucra información personal de otra persona, debe obtener el consentimiento de otra persona antes de publicarla.",
	"components.xieyi.h60ox8": "(9) brindarle Seguridad",
	"components.xieyi.q466n3": "Para cumplir con sus obligaciones legales de garantizar la seguridad de las transacciones de comercio electrónico, mejorar la seguridad de su uso de los servicios prestados por nosotros y nuestras empresas afiliadas y socios, proteger la seguridad personal y patrimonial de usted u otros usuarios o el público de la violación, prevenir mejor los riesgos de Seguridad como phishing, fraude, vulnerabilidades cibernéticas, virus informáticos, ciberataques, ciberintrusiones, etc., e identificar con mayor precisión las violaciones de leyes y reglamentos o",
	"components.xieyi.3p5627": "En el caso de los protocolos y reglas relacionados con la plataforma, incrustamos en la aplicación el SDK de Seguridad de la aplicación desarrollado por nuestra empresa afiliada para recopilar información sobre su dispositivo.",
	"components.xieyi.4n220b": "Información de registro de servicio, datos de sensores de dispositivo, información de software común y posible uso o integración de su información de usuario, información de transacción, información de dispositivo, información de registro de servicio e información que nuestras empresas afiliadas y socios autorizan o comparten de acuerdo con la Ley (entre ellos, podemos recopilar direcciones mac, información relacionada con el dispositivo IMEI para La inspección de riesgos durante la operación de fondo de la aplicación), Para juzgar exhaustivamente los riesgos de su cuenta y transacción, realizar la autenticación, detectar y prevenir incidentes de seguridad, y tomar las medidas necesarias de registro, auditoría, análisis y eliminación de acuerdo con la ley.",
	"components.xieyi.4ay5f9": "(10) app iniciada por sí misma o asociada",
	"components.xieyi.i2rhwf": "Para mejorar la experiencia de los usuarios en el uso de nuestros servicios y garantizar mejor la seguridad de las transacciones, necesitamos alertar y ayudar a los usuarios a obtener nuestra versión actualizada del Servicio de aplicación a través de la función de inicio automático o asociado de la Aplicación.",
	"components.xieyi.46wk64": "(11) otros servicios adicionales para usted",
	"components.xieyi.9w14b7": "Con el fin de ofrecerle productos y / o servicios más convenientes, de mejor calidad y personalizados y esforzarse por mejorar su experiencia, podemos recopilar y utilizar su información personal en los siguientes servicios adicionales que le brindamos.",
	"components.xieyi.7jt388": "Puede elegir si proporciona esta información o no, y si no la proporciona, no afectará su uso.",
	"components.xieyi.1c6h45": "Servicios básicos,",
	"components.xieyi.035322": "Pero es posible que no pueda obtener la experiencia del usuario que le brindan estos servicios adicionales.",
	"components.xieyi.w541k9": "Puede ver el Estado de los permisos anteriores uno por uno en su dispositivo 'configuración' o en nuestro cliente 'my - configuración - privacidad - permisos del sistema', y puede decidir si estos permisos se abren o cierran en cualquier momento.",
	"components.xieyi.t77ks8": "Tenga en cuenta que si abre cualquiera de los permisos, nos autoriza a recopilar y utilizar la información personal relevante para proporcionarle el servicio correspondiente. una vez que cierre cualquiera de los permisos, cancelará la autorización en su nombre. ya no continuaremos recopilando y utilizando la información personal relevante basada en los permisos correspondientes, ni continuaremos proporcionándole el servicio correspondiente a ese permiso. Su decisión de cerrar la autoridad no afectará la recopilación y el uso de información anterior basada en su autorización.",
	"components.xieyi.q563dr": "Usted puede",
	"components.xieyi.bs34gv": "Haga clic aquí",
	"components.xieyi.w6lbn0": "Ver la solicitud de permiso de aplicación y la descripción del uso",
	"components.xieyi.835h48": "Xii) otras normas para la recopilación y el uso de información personal",
	"components.xieyi.jy12wf": "Si la información que proporciona contiene información personal de otros usuarios, en",
	"components.xieyi.m6i6t5": "Antes de que la plataforma proporcione esta información personal, debe asegurarse de que ha obtenido una autorización legal.",
	"components.xieyi.a1o5v4": "Si usamos la información para otros fines no especificados en esta política, o la información recopilada con fines específicos para otros fines, obtendremos su consentimiento previo, excepto en los casos estipulados por las leyes y reglamentos.",
	"components.xieyi.41gslf": "3. si obtenemos su información indirectamente de un tercero, le pediremos expresamente por escrito antes de la recopilación que indique su fuente de información personal y si ha obtenido su autorización legal para su recopilación, procesamiento y suministro de su información personal a nosotros. Excepto en los casos expresamente permitidos por la ley, solo recopilaremos su información personal de terceros después de confirmar que un tercero ha obtenido su consentimiento autorizado, y si el alcance de la autorización de un tercero no puede cubrir nuestro propósito de procesamiento y uso, Procesaremos su información personal por nosotros mismos o Solicitaremos a ese tercero que obtenga su consentimiento. Al mismo tiempo, reforzaremos la seguridad de la información personal (incluida la presentación de información sensible, el almacenamiento cifrado de información sensible, el control de acceso, etc.). Utilizaremos no menos de los mismos medios y medidas de protección que protegemos la información personal de nuestros propios usuarios para proteger la información personal obtenida indirectamente.",
	"components.xieyi.x3r3y6": "4. excepciones con consentimiento autorizado",
	"components.xieyi.3jev4v": "De acuerdo con las leyes y reglamentos pertinentes, no necesitamos su consentimiento autorizado para procesar su información personal en las siguientes circunstancias:",
	"components.xieyi.214uq6": "(1) necesario para el desempeño de sus funciones u obligaciones legales;",
	"components.xieyi.81k4nu": "(2) directamente relacionado con la seguridad nacional, la seguridad de la defensa nacional y el interés público importante;",
	"components.xieyi.j7fomu": "(3) para hacer frente a emergencias de salud pública;",
	"components.xieyi.7806cy": "(4) llevar a cabo informes de noticias, supervisión de la opinión pública y otros actos para el interés público y tratar la información personal dentro de un rango razonable;",
	"components.xieyi.754474": "(5) en caso de emergencia, si no se le puede informar a tiempo o de manera efectiva para proteger su vida y salud o la seguridad de los bienes materiales de otras personas, se le informará a tiempo después de que se elimine la emergencia;",
	"components.xieyi.i1yv2t": "(6) de conformidad con las leyes y reglamentos pertinentes, tratar la información personal que las personas hacen pública por sí mismas u otra información personal que se ha hecho pública legalmente dentro de un rango razonable;",
	"components.xieyi.5dz1kl": "(7) según sea necesario para firmar y cumplir con usted el acuerdo pertinente u otros documentos escritos;",
	"components.xieyi.5bon72": "(8) necesario para mantener el funcionamiento seguro y estable de los productos y / o servicios proporcionados, como detectar y eliminar fallas en los productos y / o servicios;",
	"components.xieyi.cw60x0": "(9) otras circunstancias estipuladas en las leyes y reglamentos.",
	"components.xieyi.vyw19u": "Por favor, sepa que de acuerdo con las leyes pertinentes vigentes, si",
	"components.xieyi.4t3bn8": "Medidas técnicas y otras medidas necesarias para el procesamiento de la información personal, de modo que el receptor de datos no pueda volver a identificar a una persona específica y no pueda restaurarla",
	"components.xieyi.1c74f9": "O podemos desidentificar la información recopilada para la investigación, el análisis estadístico y la predicción para mejorar",
	"components.xieyi.1m85sf": "El contenido y el diseño de la plataforma, el soporte de productos o servicios para la toma de decisiones empresariales, y la mejora de nuestros productos y servicios (incluido el uso de datos anónimos para el aprendizaje automático o el entrenamiento de algoritmos de modelos),",
	"components.xieyi.me4rhf": "Entonces, el uso de tales datos procesados no necesita ser notificado a usted por separado y obtener su consentimiento.",
	"components.xieyi.257c58": "3. cómo lo usamos",
	"components.xieyi.77f388": "Y tecnologías similares",
	"components.xieyi.759p76": "I) uso de cookies",
	"components.xieyi.48ze34": "Para garantizar que el sitio web funcione correctamente, darle una experiencia de acceso más fácil, recomendarle lo que pueda ser de interés, almacenaremos cookies, cookies flash en su computadora o dispositivo móvil, u otro almacenamiento local (colectivamente 'cookies') proporcionado por el navegador (o aplicación asociada) que suele contener etiquetas, nombres de sitio y algunos números y caracteres. Con la ayuda de",
	"components.xieyi.s020rs": "Si su navegador o el servicio adicional del navegador lo permiten, puede modificar la aceptación de las cookies o rechazar nuestras cookies.",
	"components.xieyi.3w12li": "Para más detalles, consulte aboutcookies.org. Pero si realiza las operaciones mencionadas en este artículo, en algunos casos puede afectar su acceso seguro a nuestro sitio web y puede ser necesario cambiar la configuración del usuario cada vez que visite nuestro sitio Web.",
	"components.xieyi.q45ikb": "Ii) uso de tecnologías similares a las cookies",
	"components.xieyi.h550rh": "Eliminar",
	"components.xieyi.7248f8": "Además, usaremos otras tecnologías similares como balizas de sitio web, etiquetas de píxeles y ETAG en el sitio Web.",
	"components.xieyi.jvxl8f": "Por ejemplo, los correos electrónicos que le enviamos pueden contener enlaces de dirección vinculados al contenido de nuestro sitio web, y si hace clic en ese enlace, haremos un seguimiento de este clic para ayudarnos a comprender sus preferencias de producto o servicio para que podamos tomar la iniciativa de mejorar la experiencia del usuario. La baliza del sitio web suele ser una imagen transparente incrustada en un sitio web o correo electrónico. Con la etiqueta de píxeles en el correo electrónico, podemos saber si el correo electrónico está abierto. Si no desea que sus actividades sean rastreadas de esta manera, puede darse de baja de nuestra lista de mensajes en cualquier momento.",
	"components.xieyi.52wb6a": "ETAG (etiqueta física) es la cabecera del Protocolo https transmitida detrás del navegador de Internet y el servidor de internet, que puede reemplazar las cookies. ETAG puede ayudarnos a evitar cargas innecesarias de servidores, mejorar la eficiencia del servicio y ahorrar recursos y energía. Al mismo tiempo, es posible que registremos su identidad a través de ETAG para que podamos comprender y mejorar más profundamente nuestros productos o servicios.",
	"components.xieyi.y92k4u": "La mayoría de los navegadores ofrecen a los usuarios la función de eliminar los datos almacenados en la caché del navegador, y puede realizar las operaciones de eliminación de datos correspondientes en la función de configuración del navegador.",
	"components.xieyi.o54yhy": "Pero tenga en cuenta que si ETAG está desactivado, es posible que no pueda disfrutar de una experiencia de producto o servicio relativamente mejor.",
	"components.xieyi.37e045": "I) compartir",
	"components.xieyi.675822": "No estaremos con",
	"components.xieyi.x1y6r8": "Empresas, organizaciones e individuos distintos comparten su información personal, excepto en los siguientes casos:",
	"components.xieyi.8q0s05": "1. compartir en circunstancias legales: Compartiremos su información personal con el mundo exterior de acuerdo con las leyes y reglamentos.",
	"components.xieyi.9irym2": "2. compartir con el consentimiento expreso: después de obtener su consentimiento expreso, Compartiremos su información personal con otras partes.",
	"components.xieyi.371i1r": "3. compartir cuando sea necesario para proporcionarle los productos o servicios que necesita: pase",
	"components.xieyi.75u39x": "La plataforma compra bienes o servicios y, según su elección, compartiremos la información necesaria relacionada con la transacción en la información de su pedido con los proveedores de bienes o servicios relacionados para satisfacer sus necesidades de transacción, distribución y servicio post - venta.",
	"components.xieyi.u6d81m": "4. compartir con empresas afiliadas:",
	"components.xieyi.30si92": "Para facilitar que nos basemos en",
	"components.xieyi.6pysc9": "La cuenta de la plataforma le ofrece productos y servicios, recomienda información que pueda interesarle, identifica anomalías en la cuenta del usuario y protege",
	"components.xieyi.7vmrlb": "La seguridad personal y patrimonial de una empresa afiliada u otro usuario o público está protegida, y su información personal puede ser compartida con nuestra empresa afiliada y / o su proveedor de servicios designado.",
	"components.xieyi.269i0k": "Solo compartiremos la información personal necesaria y estará sujeta al propósito declarado en esta política. si compartimos su información personal sensible o las empresas afiliadas cambian el propósito de uso y procesamiento de la información personal, solicitaremos su consentimiento autorizado nuevamente.",
	"components.xieyi.5475kt": "5. encomendar a los socios autorizados:",
	"components.xieyi.u1sa8e": "Podemos confiar a un socio autorizado que le preste ciertos servicios o desempeñe funciones en nuestro nombre,",
	"components.xieyi.f1h4lc": "Solo Compartiremos su información con fines legítimos, legítimos, necesarios, específicos y claros de esta declaración de política. los socios autorizados solo tendrán acceso a la información que necesitan para desempeñar sus funciones, y les pediremos por acuerdo que no utilicen esta información para ningún otro propósito.",
	"components.xieyi.g700e1": "En la actualidad, nuestros socios autorizados incluyen los siguientes tipos:",
	"components.xieyi.36nvwn": "(1) socios autorizados en la categoría de servicios de publicidad y análisis. Encargaremos a estos socios que manejen la información relacionada con la cobertura y efectividad de la publicidad, pero a menos que obtenga su permiso, no Compartiremos su información de identidad personal con los socios que brindan servicios de publicidad y análisis, o la desidentificaremos para que los socios autorizados no puedan identificarle personalmente. Este tipo de socios pueden combinar la información anterior con otros datos que obtengan legalmente para implementar los servicios de publicidad o recomendaciones de toma de decisiones que encargamos.",
	"components.xieyi.5xh5u7": "(2) proveedores, proveedores de servicios y otros socios. Enviamos información a proveedores, proveedores de servicios y otros socios que apoyan nuestro negocio, que incluyen la prestación de servicios de infraestructura tecnológica, el análisis de cómo se utilizan nuestros servicios, la medición de la efectividad de la publicidad y los servicios, la prestación de atención al cliente, la facilitación de pagos o la realización de Investigaciones y encuestas académicas, servicios de distribución logística, Servicios de pago, procesamiento de datos, etc. El objetivo de compartir esta información es realizar las funciones básicas de compra de nuestros productos y / o servicios, como que debemos compartir su información de pedido con el proveedor de servicios logísticos para organizar la entrega; O necesitamos compartir su número de pedido y el monto del pedido con una agencia de pago de terceros para que confirme sus instrucciones de pago y complete el pago.",
	"components.xieyi.67e7b8": "(3) proveedor de servicios de autenticación de nombre real. Cuando le ofrecemos un servicio de autenticación de nombre real, necesita autorizarnos a recopilar su información de autenticación de nombre real y proporcionarla a agencias de terceros (incluyendo, pero no limitado a, Tencent Cloud Computing (beijing) co., ltd., en adelante, 'agencias de terceros'), Las instituciones de terceros consultarán y verificarán por sí mismas la autenticidad, exactitud e integridad de esa información a las unidades pertinentes (incluidas, entre otras cosas, la base de datos básica de información crediticia personal del Banco Popular de China y otras instituciones gubernamentales pertinentes, operadores básicos de telecomunicaciones, instituciones de información, órganos de Seguridad pública, fiscalías, tribunales, instituciones financieras, otras instituciones cooperativas o canales públicos), y analizarán, compararán, procesarán y ordenarán esa información. Y retroalimentarnos el informe de evaluación general de su información de crédito personal.",
	"components.xieyi.12lv44": "Para garantizar",
	"components.xieyi.3749fw": "El funcionamiento estable de la Plataforma y la implementación de las funciones anteriores le permiten disfrutar y usar más servicios y funciones, y nuestras aplicaciones incorporarán SDK u otras aplicaciones similares de socios autorizados.",
	"components.xieyi.3nos3g": "Sobre nuestros socios de acceso, etc., puede compartir la lista con terceros.",
	"components.xieyi.70yu7f": "Revisa.",
	"components.xieyi.89014s": "Realizaremos estrictas pruebas de Seguridad de la interfaz de aplicación (api), el paquete de desarrollo de herramientas de software (sdk) para que los socios autorizados obtengan información relevante, y acordaremos estrictas medidas de protección de datos con los socios autorizados para que manejen la información personal de acuerdo con esta política y cualquier otra medida de confidencialidad y seguridad relacionada.",
	"components.xieyi.1l5529": "Ii) cesión",
	"components.xieyi.ls670h": "No transferiremos su información personal a ninguna empresa, organización o individuo, excepto:",
	"components.xieyi.u50832": "1. transferencia con consentimiento expreso: después de obtener su consentimiento expreso, transferiremos su información personal a otras partes;",
	"components.xieyi.6snoud": "2. en",
	"components.xieyi.411611": "En caso de fusión, adquisición o liquidación de quiebra, u otras situaciones relacionadas con fusión, adquisición o liquidación de quiebra, si se trata de transferencia de información personal, le informaremos oportunamente del nombre y los datos de contacto de la nueva empresa, organización que posee su información personal y le pediremos que siga sujeta a esta política, de lo contrario le pediremos a la empresa, organización y persona que vuelva a solicitar su autorización para aceptar.",
	"components.xieyi.783yjz": "(3) divulgación pública",
	"components.xieyi.zdq4b7": "Solo divulgaremos públicamente su información personal si:",
	"components.xieyi.3in5j6": "1. con su consentimiento expreso o basado en su elección activa, podemos divulgar públicamente su información personal.",
	"components.xieyi.ey5b87": "2. sobre la base de los requisitos de divulgación de información estipulados en las leyes y reglamentos, divulgue su información personal dentro del alcance prescrito, como la publicidad de la información del vendedor estipulada en las medidas para la supervisión y administración de transacciones en línea.",
	"components.xieyi.ok7p68": "Si estamos seguros de que ha violado las leyes y reglamentos o ha violado gravemente",
	"components.xieyi.6kb306": "Situación de los acuerdos y normas relacionados con la plataforma, o para la protección",
	"components.xieyi.41el81": "La seguridad personal y patrimonial de los usuarios de la plataforma o del público está protegida, y podemos revelar información personal sobre usted y dejar de prestarle servicios de acuerdo con las leyes y reglamentos o con su consentimiento.",
	"components.xieyi.4c16r3": "Incluidas las violaciones conexas y",
	"components.xieyi.7uq8p7": "Las medidas que la Plataforma ha tomado contra usted. Por ejemplo, si viola gravemente la ley por vender productos falsificados",
	"components.xieyi.kc0i6h": "Reglas de la plataforma, podemos revelar públicamente la información principal de la certificación de su tienda, irregularidades y",
	"components.xieyi.100i6j": "Medidas de tratamiento.",
	"components.xieyi.veqih2": "(4) excepciones para obtener el consentimiento autorizado para compartir, transferir y divulgar públicamente información personal",
	"components.xieyi.i5fma7": "De acuerdo con las leyes y reglamentos pertinentes, no es necesario obtener su consentimiento autorizado para compartir, transferir y divulgar públicamente su información personal en las siguientes circunstancias:",
	"components.xieyi.r82363": "1. ser necesario para el desempeño de sus funciones u obligaciones legales;",
	"components.xieyi.ett47l": "2. está directamente relacionado con la seguridad nacional, la seguridad de la defensa nacional y los principales intereses públicos;",
	"components.xieyi.nql27g": "3. para hacer frente a las emergencias de salud pública;",
	"components.xieyi.r2138s": "4. implementar informes de noticias, supervisión de la opinión pública, etc. para el interés público y modificar para tratar la información personal dentro de un rango razonable;",
	"components.xieyi.i32523": "5. en caso de emergencia, si no se le puede informar de manera oportuna o efectiva para proteger su vida y salud o la seguridad de los bienes materiales de otras personas, se le informará de manera oportuna después de que se elimine la emergencia;",
	"components.xieyi.4stqc6": "6. de conformidad con las leyes y reglamentos pertinentes, tratar la información personal que las personas hacen pública por sí mismas u otra información personal que se ha hecho pública legalmente dentro de un rango razonable.",
	"components.xieyi.7k22k8": "Tenga en cuenta que, de acuerdo con la Ley aplicable,",
	"components.xieyi.7w6sng": "Si tomamos medidas técnicas y otras medidas necesarias para procesar la información personal, de modo que el receptor de datos no pueda volver a identificar a una persona específica y no pueda restaurarla, el intercambio, la transferencia y la divulgación pública de tales datos procesados no requieren notificación adicional y su consentimiento.",
	"components.xieyi.t8w9o9": "I) medidas técnicas de protección de datos",
	"components.xieyi.5k26r2": "Hemos tomado medidas de protección de seguridad razonables y factibles que cumplen con las normas de la industria para proteger su información de acceso no autorizado, divulgación pública, uso, modificación, daño o pérdida de información personal. Por ejemplo, usaremos la tecnología de cifrado para mejorar la seguridad de la información personal, al intercambiar datos entre su navegador y su servidor.",
	"components.xieyi.824h5u": "El protocolo SSL está encriptado y protegido, y al mismo tiempo hacemos",
	"components.xieyi.rs1e76": "La plataforma proporciona un método de navegación seguro del Protocolo https; Utilizaremos mecanismos de protección de confianza para evitar ataques maliciosos contra la información personal; Desplegaremos mecanismos de control de acceso y haremos todo lo posible para garantizar que solo las personas autorizadas puedan acceder a la información personal.",
	"components.xieyi.qj2xrx": "(2) sistema de cumplimiento de la gestión de la protección de datos",
	"components.xieyi.y1edur": "Hemos establecido un sistema avanzado de cumplimiento de la gestión de la seguridad de los datos en la industria, centrado en los datos y en torno al ciclo de vida de los datos, para mejorar la seguridad de la información personal desde múltiples dimensiones, como la construcción organizativa, el diseño del sistema, la gestión del personal y la tecnología de productos. Estableceremos una agencia de protección de la información personal para supervisar la protección de la información personal y organizaremos cursos de capacitación en seguridad y protección de la privacidad para fortalecer la conciencia de los empleados sobre la importancia de proteger la información personal.",
	"components.xieyi.d7ix35": "(3) respuesta a incidentes de Seguridad de la información personal",
	"components.xieyi.2o454t": "Si nuestras instalaciones de protección física, técnica o administrativa son dañadas, lo que resulta en acceso no autorizado a la información, divulgación pública, manipulación o destrucción, lo que resulta en daños a sus derechos e intereses legítimos, activaremos oportunamente el plan de emergencia y tomaremos medidas correctivas razonables para minimizar el impacto en su información personal y otros derechos e intereses. En caso de incidente de Seguridad de la información personal, también le informaremos de las circunstancias básicas y los posibles efectos del incidente de seguridad, las medidas de eliminación que hemos tomado o tomaremos, las recomendaciones de que puede prevenir y reducir el riesgo de forma autónoma, las medidas correctivas para usted, etc. Le informaremos por correo electrónico, carta, teléfono, aviso de empuje, etc. cuando sea difícil informar al cuerpo principal de la información personal uno por uno, emitiremos un anuncio de manera razonable y efectiva. Al mismo tiempo, también informaremos sobre la eliminación de incidentes de Seguridad de la información personal de acuerdo con los requisitos de las autoridades reguladoras.",
	"components.xieyi.4706wi": "(4) supervisión social",
	"components.xieyi.4ouosr": "Publicaremos periódicamente informes de responsabilidad social sobre la protección de la información personal de acuerdo con los requisitos de las leyes y reglamentos administrativos nacionales pertinentes y aceptaremos la supervisión social.",
	"components.xieyi.6r5a4q": "(5) prevención independiente de riesgos para la seguridad de las cuentas",
	"components.xieyi.veukf5": "Internet no es un entorno absolutamente seguro, el uso",
	"components.xieyi.ri9a0j": "Al usar la plataforma, le recomendamos encarecidamente que no use no",
	"components.xieyi.382lb2": "El método de comunicación recomendado por la plataforma envía su información y, al mismo tiempo, por favor, use una contraseña compleja para ayudarnos a proteger la seguridad de su cuenta.",
	"components.xieyi.s2h72a": "En uso",
	"components.xieyi.8mtgk8": "Cuando la plataforma realiza transacciones en línea, es inevitable que divulgue su información personal a la contraparte o a una posible contraparte, como",
	"components.xieyi.6ue558": "Información de contacto",
	"components.xieyi.5h8pl3": "Dirección de contacto",
	"components.xieyi.74n5cs": ". Además, también puede establecer comunicación, intercambio de información o análisis de contenido con terceros a través de nuestros servicios. Por favor, proteja adecuadamente su información personal y póngala a disposición de los demás solo si es necesario. Si encuentra filtrada su información personal, especialmente su cuenta o contraseña, Póngase en contacto de inmediato.",
	"components.xieyi.1100gs": "Servicio oficial de atención al cliente de la plataforma para que podamos tomar las medidas correspondientes de acuerdo con su solicitud.",
	"components.xieyi.p898io": "Tenga en cuenta que la información que comparte voluntariamente o incluso públicamente cuando utiliza nuestro servicio puede involucrar información personal o incluso información personal sensible de usted o de otras personas. Por favor, tenga más cuidado de considerar si compartir o incluso compartir públicamente información relevante al usar nuestros servicios.",
	"components.xieyi.pfhp6l": "I) lugar de almacenamiento",
	"components.xieyi.id973y": "La información personal que recopilamos y generamos en nuestras operaciones en el territorio de la República Popular China se almacenará en el territorio de china.",
	"components.xieyi.76w8ku": "Si es realmente necesario proporcionar su información personal al extranjero debido al negocio o dentro del alcance del propósito cubierto por esta política, realizaremos una evaluación previa del impacto de la protección de la información personal, registraremos el procesamiento de acuerdo con las leyes y reglamentos pertinentes, y lo Procesaremos de acuerdo con las disposiciones de las leyes y reglamentos pertinentes sobre la base de su consentimiento claro, y le informaremos oportunamente del nombre, la información de contacto, el propósito del procesamiento, el método de procesamiento del receptor de información extranjera. El tipo de información personal y la forma y el procedimiento en que usted ejerce los derechos estipulados en la ley nacional a la parte receptora en el extranjero.",
	"components.xieyi.7fciuv": "Ii) duración del almacenamiento",
	"components.xieyi.f1a2af": "Solo conservaremos su información personal durante el período necesario para lograr los fines mencionados en la política de costos, a menos que la ley tenga un requisito obligatorio de retención,",
	"components.xieyi.42nn2k": "Por ejemplo, la Ley de comercio electrónico de la República Popular China requiere que el tiempo de conservación de la información sobre bienes y servicios y la información sobre transacciones no sea inferior a tres años a partir de la fecha de finalización de la transacción. Juzgamos que el período de almacenamiento de la información personal se refiere principalmente a los siguientes criterios y se basa en los mayores:",
	"components.xieyi.6hv739": "1. completar el propósito de la transacción relacionada con usted y mantener los registros de transacciones y negocios correspondientes para hacer frente a posibles consultas o quejas suyas;",
	"components.xieyi.483k46": "2. garantizar la seguridad y la calidad de nuestros servicios para usted;",
	"components.xieyi.v5a774": "¿3. ¿ está de acuerdo con un período de retención más largo?",
	"components.xieyi.7g6607": "De acuerdo con las necesidades pertinentes de la prescripción;",
	"components.xieyi.8iszne": "5. si existen otros acuerdos especiales o leyes y reglamentos sobre el período de reserva.",
	"components.xieyi.2xiglt": "Después de la expiración del período de reserva, Procesaremos su información personal a tiempo de acuerdo con las disposiciones de las leyes y reglamentos pertinentes.",
	"components.xieyi.7rg77i": "Además de seguir la ruta de operación acordada en esta política, puede",
	"components.xieyi.t2esz7": "Además de operar por sí mismo en la plataforma, también puede acceder y gestionar su información de las siguientes maneras:",
	"components.xieyi.kb51e6": "(1) consultar, corregir y complementar su información",
	"components.xieyi.nu7m3c": "Tiene derecho a consultar, corregir o complementar su información. Puede iniciar sesión",
	"components.xieyi.bcd5d5": "Plataforma, haga clic en 'mi - configuración (esquina superior derecha)' para ingresar al Centro de compradores para consultar y corregir datos personales e información relacionada con la cuenta personal, seguridad de la cuenta y dirección de recepción.",
	"components.xieyi.043c4c": "(2) elimine su información",
	"components.xieyi.2d2n9p": "Puede eliminar parte de su información de la manera establecida en '(1) consultar, corregir y complementar su información'.",
	"components.xieyi.8n2d00": "En los siguientes casos, puede pasar por",
	"components.xieyi.4zh20s": "El contacto del servicio al cliente de la plataforma nos hizo una solicitud para eliminar la información personal:",
	"components.xieyi.25t31b": "1. si nuestro tratamiento de la información personal viola las leyes y reglamentos;",
	"components.xieyi.8tjokj": "2. si recopilamos y utilizamos su información personal sin su consentimiento expreso;",
	"components.xieyi.4m1259": "3. si nuestro manejo de información personal viola gravemente el acuerdo con usted;",
	"components.xieyi.q81de3": "4. si su cuenta está cancelada.",
	"components.xieyi.xd43vl": "Si decidimos responder a su solicitud de eliminación, también Notificaremos a los sujetos que obtengan su información personal de nosotros tanto como sea posible y les pediremos que la eliminen a tiempo (a menos que las leyes y reglamentos dispongan otra cosa, o que estos sujetos hayan obtenido su autorización de forma independiente).",
	"components.xieyi.1c21t6": "Cuando usted o nosotros le ayudemos a eliminar la información relevante, debido a las restricciones legales y técnicas de Seguridad aplicables, es posible que no podamos eliminar inmediatamente la información correspondiente del sistema de respaldo, y almacenaremos su información personal de manera segura y limitaremos cualquier procesamiento adicional de ella hasta que la copia de Seguridad pueda ser eliminada o anonimizada.",
	"components.xieyi.43p4r8": "(3) cancelar su cuenta",
	"components.xieyi.w2lnx4": "Puede solicitar la cancelación de su cuenta de la siguiente manera:",
	"components.xieyi.d17ah0": "1. iniciar sesión",
	"components.xieyi.113v7u": "Plataforma, a través de 'mi - configuración (esquina superior derecha) - Centro de Seguridad de la cuenta - cuenta de cancelación' para presentar la solicitud de cancelación de la cuenta;",
	"components.xieyi.390iqo": "2. contacto",
	"components.xieyi.n6o511": "Servicio al cliente de la plataforma para ayudarle a solicitar la cancelación de su cuenta",
	"components.xieyi.g4i68q": "Después de cancelar su cuenta por iniciativa propia, dejaremos de proporcionarle productos o servicios y eliminaremos su información personal o la anonimizaremos de acuerdo con los requisitos de la ley aplicable.",
	"components.xieyi.i8g44k": "(4) cambiar el alcance de su consentimiento autorizado",
	"components.xieyi.7162l2": "Puede cambiar o retirar el alcance de su autorización para recopilar y procesar su información personal de la siguiente manera: puede",
	"components.xieyi.83m5qy": "'El mío",
	"components.xieyi.ih02m6": "- configuración - privacidad - permisos del sistema '",
	"components.xieyi.3aq776": "Gestiona las aplicaciones que ha autorizado.",
	"components.xieyi.uyy3k2": "Para las autorizaciones que no puede configurar directamente de la manera anterior, puede modificarlas contactando con el servicio al cliente de EPOCH EPT o enviando un correo electrónico a jubao@weipaitang.com . Responderemos a su solicitud de retirada en un plazo de 15 días hábiles. Sin embargo, tenga en cuenta que es posible que no podamos responder a su solicitud de cambiar el alcance de la autorización para algunos tipos de información personal, como la información necesaria para implementar las funciones básicas del EPOCH EPT o la información necesaria para que cumplamos con nuestras obligaciones en virtud de las leyes y reglamentos.",
	"components.xieyi.38w831": "Cuando retire la autorización, ya no Procesaremos la información personal correspondiente. Pero su decisión de retirar la autorización no afectará nuestro procesamiento anterior de información personal basado en su autorización.",
	"components.xieyi.o2932d": "Si no desea aceptar los anuncios comerciales personalizados que le enviamos, puede cancelarlos en cualquier momento de la siguiente manera:",
	"components.xieyi.7l39m6": "'Mi EPOCH EPT - configuración - sobre EPOCH EPT - personalización'",
	"components.xieyi.uw6w84": "V) decisión automática del sistema de información restrictiva",
	"components.xieyi.6ixh35": "En algunas funciones comerciales, es posible que solo tomemos decisiones basadas en mecanismos de toma de decisiones no manuales y automáticos, incluidos sistemas de información, algoritmos, etc. Si estas decisiones afectan significativamente sus derechos e intereses legítimos, tiene derecho a pedirnos explicaciones y también estaremos en",
	"components.xieyi.8i0uc6": "Proporcionar métodos de queja bajo la premisa de secretos comerciales u otros derechos e intereses de los usuarios e intereses públicos sociales.",
	"components.xieyi.sace84": "6) copia de la información personal",
	"components.xieyi.iz38g1": "Si necesita una copia de su información personal, puede contactarnos a través de los canales de retroalimentación enumerados en esta política 'cómo contactarnos'. después de verificar su identidad, le proporcionaremos una copia de su información personal de acuerdo con sus requisitos, a menos que las leyes y reglamentos dispongan otra cosa o esta política disponga otra cosa.",
	"components.xieyi.q0m5n8": "(7) responder a su solicitud anterior",
	"components.xieyi.fqi536": "Puede ponerse en contacto con nosotros a través del servicio al cliente o iniciar una queja directamente con nuestro Departamento de protección de información personal a tiempo completo sobre sus o posibles tutores, familiares cercanos y otros sujetos con derecho a solicitarnos lo anterior, así como sobre sus derechos de información personal relacionados estipulados por las leyes de la República Popular China y otras Leyes aplicables. Responderemos en 15 días.",
	"components.xieyi.a1304e": "Para garantizar la seguridad de su cuenta, cuando nos haga la solicitud anterior, podemos solicitar primero la verificación de su identidad (como pedirle una solicitud por escrito u otros medios) antes de procesar su solicitud.",
	"components.xieyi.31h37g": "En los siguientes casos, de acuerdo con los requisitos legales y reglamentarios, no podremos responder a su solicitud:",
	"components.xieyi.1udf40": "1. para el desempeño de sus funciones u obligaciones legales;",
	"components.xieyi.0ro7r7": "3. hay pruebas suficientes de la malicia subjetiva o el abuso de Derechos del sujeto de la información personal;",
	"components.xieyi.70z43w": "4. responder a su solicitud causará graves daños a los derechos e intereses legítimos de usted u otras personas u organizaciones;",
	"components.xieyi.335lfg": "5. involucrar secretos comerciales;",
	"components.xieyi.1eb324": "6. otras circunstancias estipuladas en las leyes y reglamentos administrativos.",
	"components.xieyi.e0vv42": "En las actividades de comercio electrónico, presumimos que tiene la capacidad civil correspondiente. Si usted es menor de edad, le pedimos que pida a sus padres u otros tutores que lean atentamente esta política y utilicen nuestros servicios o nos proporcionen información con el consentimiento de sus padres u otros tutores.",
	"components.xieyi.jp5623": "Si usted es menor de 14 años, antes de usar nuestros servicios, usted y su tutor deben leer atentamente las reglas de protección de la información personal del niño que hemos establecido específicamente para asegurarse de que antes de obtener el",
	"components.xieyi.4637q9": "Normas para la protección de la información personal de los niños",
	"components.xieyi.b7i17n": "Después del consentimiento, puede usar nuestros servicios bajo la Guía de su tutor para garantizar su seguridad al usar nuestros servicios y realizar transacciones.",
	"components.xieyi.63z56b": "Si usted es el padre u otro tutor del niño, preste atención a si el niño utiliza nuestros servicios después de obtener su consentimiento autorizado. Si tiene alguna pregunta sobre la información personal de los niños, Póngase en contacto con nuestro Departamento de protección de la información personal a tiempo completo.",
	"components.xieyi.064797": "Nuestros servicios y tecnologías se actualizan y optimizan constantemente con las necesidades de usted y otros usuarios, por lo que cuando se cambian nuevos servicios y procesos de negocio, podemos actualizar nuestra política de privacidad para informarle de los cambios específicos. Pero sin su consentimiento expreso, No limitaremos sus derechos de acuerdo con esta política. Estaremos en",
	"components.xieyi.7xys1n": "Página especializada",
	"components.xieyi.5ln786": "Publicar cualquier cambio realizado en esta política.",
	"components.xieyi.1i9q62": "Para los cambios importantes, también proporcionaremos avisos más significativos (incluidos los que aprobaremos).",
	"components.xieyi.dg46h5": "La forma en que la plataforma lo publica se notifica o se le proporciona un recordatorio de ventana emergente).",
	"components.xieyi.o3si3a": "Los cambios importantes a que se refiere esta política incluyen, entre otros:",
	"components.xieyi.sntd36": "1. nuestro modelo de servicio ha cambiado significativamente, como el propósito de procesar la información personal, el tipo de información personal procesada, la forma de usar la información personal, etc.;",
	"components.xieyi.x97b27": "2. hemos experimentado cambios importantes en el control y otros aspectos, como los cambios en los controladores de información causados por fusiones y adquisiciones;",
	"components.xieyi.hc7g60": "3. cambios en los principales objetos de intercambio, transferencia o divulgación pública de información personal;",
	"components.xieyi.4icom0": "4. los derechos de procesamiento de información personal que tiene y la forma de ejercerlos han cambiado significativamente;",
	"components.xieyi.krv6jh": "5. nuestro departamento responsable de la protección de la información personal, los datos de contacto y los canales de queja han cambiado;",
	"components.xieyi.t6x4vu": "6. el informe de evaluación del impacto en la seguridad de la información personal muestra un alto riesgo.",
	"components.xieyi.oso4jc": "Nombre de la compañía: Hangzhou EPOCH EPT cultural Creative co., Ltd.",
	"components.xieyi.0vvw2h": "Dirección registrada: 7º piso, edificio comercial chuangfeng huaxing, No. 19 Huaxing road, Distrito de xihu, ciudad de hangzhou, Provincia de Zhejiang",
	"components.xieyi.4j9bb7": "Si tiene alguna pregunta, opinión o sugerencia sobre esta política, puede ponerse en contacto con nosotros a través de los siguientes medios, y responderemos a su solicitud en un plazo de 15 días hábiles:",
	"components.xieyi.5mz349": "1. servicio al cliente en línea de EPOCH EPT",
	"components.xieyi.py8lg2": "2. también hemos establecido un Departamento a tiempo completo de protección de la información personal, puede ponerse en contacto con nuestro buzón de correo del Jefe de protección de la información personal. shaktrysrtgscrtbslanderor5046@gmail.com .",
	"components.xieyi.nfphnk": "Cabe señalar que es posible que no respondamos a preguntas relacionadas con esta política o con la protección de la información personal de los usuarios.",
	"components.xieyi.6vag57": "Si no está satisfecho con nuestra respuesta, especialmente si cree que nuestro comportamiento de procesamiento de información personal perjudica sus derechos e intereses legítimos,",
	"components.xieyi.iw8u70": "También puede buscar una solución presentando una demanda ante un tribunal competente en la Sede de la plataforma EPOCH ept.",
	"components.xieyi.668kbh": "Primer lanzamiento: 13 de diciembre de 2018",
	"components.xieyi.1hqds4": "Última actualización: 30 de marzo de 2023",
	"src.error.3if060": "- > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error - > mensaje de error.",
	"src.error.f78hx4": "> > > > instancia de la UE > > > > > > > > superior",
	"libs.axios.3m3ozo": "¡El servidor es anormal!",
	"libs.axios.u68235": "¡Error al solicitar!",
	"libs.axios.8pvp71": "¡¡ no tienes autoridad!",
	"libs.axios.3e23l0": "Se produjo un error en la dirección de la solicitud",
	"libs.axios.ctl7o2": "¡La solicitud se excede, ¡ por favor, actualice y vuelva a intentarlo!",
	"libs.axios.7f90de": "Error interno del servidor",
	"libs.axios.6r1ua2": "El servicio no se materializó",
	"libs.axios.232in7": "Error en la pasarela",
	"libs.axios.ft8yo6": "El servicio no está disponible",
	"libs.axios.1o3gds": "¡La pasarela está fuera de tiempo, ¡ por favor, actualice y vuelva a intentarlo!",
	"libs.axios.43w873": "La versión HTTPS no es compatible",
	"goods.index.86p673": "Compra",
	"goods.index.40o4np": "Número de compras",
	"goods.index.r257np": "Comprar de inmediato",
	"goods.index.6i5t88": "¡¡ el producto no se puede comprar por el momento!",
	"goods.index.263l56": "Por favor, elija el producto",
	"goods.index.e5yso2": "¡¡ por favor, elija el método de pago!",
	"rewards.activityb.t04rn4": "Comisión total",
	"rewards.activityb.r5174s": "Comisión de hoy",
	"rewards.activityb.q4544h": "Comisión de ayer",
	"rewards.activityb.uflar8": "Número de personas empujadas directamente",
	"rewards.activityb.fsyaor": "Comisiones",
	"rewards.activityb.xd17r7": "Número de personas indirectas",
	"trending.index copy 2.891396": "Merchandising",
	"trending.index copy 2.rc6wik": "Rango de entrada:",
	"trending.index copy 2.y4r77n": "Ciclo cuantitativo:",
	"trending.index copy 2.m2v371": "Horas",
	"trending.index copy 2.5vyoay": "Ganancias cuantitativas:",
	"trending.index copy 2.rpeid0": "Ganancias del documentario $1185.65",
	"trending.index copy 2.g0677s": "Con una sola escala $15120184.00",
	"trending.index copy 2.8sgku2": "En fideicomiso",
	"trending.index copy 2.g2jni6": "Importe de la custodia",
	"trending.index copy 2.39cp6b": "Plazo de custodia",
	"trending.index copy 2.050n02": "Ganancias diarias",
	"trending.index copy 2.380j4f": "Hora de inicio de la custodia",
	"trending.index copy 2.6en3x1": "Fin de la custodia",
	"trending.index copy 2.v761ws": "Ciclo de seguimiento:",
	"trending.index copy 2.8gow40": "Restricciones de seguimiento:",
	"trending.index copy 2.1ul440": "Rentabilidad mínima:",
	"trending.index copy 2.7fk35c": "Rendimiento máximo:",
	"trending.index copy 2.3ys73k": "Saldo disponible",
	"trending.index copy 2.f615yk": "Precio total",
	"trending.index copy 2.11c26y": "Seguimiento inmediato",
	"trending.index copy 2.ojxv4u": "Todo",
	"trending.index copy 2.2v0ku3": "Mi pedido",
	"trending.index copy 2.9m6dh6": "¡¡ el pedido fue exitoso!",
	"trending.index copy 3.7v38ky": "Cancelado",
	"trending.index copy 3.3ksfcn": "Pedidos de precompra",
	"trending.index copy 3.u6n586": "Pendiente de liquidación",
	"trending.index copy 3.7d8jnh": "Título",
	"trending.index copy 3.22f28b": "¿Está seguro de que quiere cancelar el pedido?",
	"trending.index copy 3.plw663": "Confirmación",
	"trending.index copy 3.e4pcfx": "¡Cancelación exitosa!",
	"trending.index copy.voov3i": "Rentabilidad en los últimos 30 días",
	"trending.index copy.60fqu2": "Ganancias de casi 30 días",
	"trending.index copy.38c2yt": "Tasa de victorias",
	"trending.touzi1.30c92u": "Número de pedido",
	"trending.touzi1.w3r6h6": "Importe",
	"trending.touzi1.2q58dy": "Estado",
	"trending.touzi1.830j6d": "Tiempo",
	"wallet.index.ralw0t": "Por favor, introduzca el número de teléfono móvil",
	"wallet.index.tme1u9": "Número de teléfono",
	"wallet.index.h1wve2": "Por favor, introduzca el número de teléfono",
	"login.login.qnpe05": "Por favor, introduzca el teléfono",
	"login.login.8za2m1": "La contraseña de inicio de sesión no puede estar vacía",
	"components.recharge.r79s4d": "Ha entrado en vigor",
	"components.recharge.v93frd": "En proceso",
	"wallet.index.bm205v": "El importe mínimo no puede ser inferior a 0",
	"components.recharge.h1w232": "En el descuento",
	"components.recharge.wx5ntw": "El descuento fue exitoso",
	"components.recharge.42t1o3": "Recarga exitosa",
	"components.recharge.d03old": "Falló el retiro de efectivo",
	"components.recharge.cn2rpv": "Retiro exitoso"
}