module.exports = {
	"Language": {
		"title": "Dil"
	},
	"Noproductsforsale": "No products for sale",
	"rechargePresentationText0_1": "Recharge 1000 reward  ",
	"rechargePresentationText0_2": ", Recharge 10000 reward ",
	"rechargePresentationText0_3": ", and so on... Click Me !",
	"rechargePresentationText0": "You will receive ",
	"rechargePresentationText1": "% of the recharge amount when you start the recharge now. The activity time is limited, and it will stop after the gift is over.",
	"rechargePresentationText2": "Reward End Time, CountDown !",
	"Online_Chat": "Çevrim içi sohbet",
	"gobindgoogle": "Lütfen Google Authenticator'ınızı çöpe atın",
	"Order": "Sipariş",
	"Record": "Kayıt",
	"Assist": "Yardımcı",
	"Market": "Pazar",
	"History": "Geçmiş",
	"Confirm": "Onaylamak",
	"close": "kapalı",
	"Cancel": "İptal etmek",
	"Previous": "Öncesi",
	"Next": "Sonraki",
	"Copy": "kopyala",
	"Nomoredata": "Daha fazla veri yok",
	"moneychar": "₹",
	"DownloadApp": " Daha fazla veri yok",
	"Yes": "Evet",
	"No": "HAYIR",
	"Wating": "Sulama",
	"Recharge": "şarj et",
	"Withdrawal": "Para çekme",
	"Success": "Başarı",
	"OrderSuccessful": "Sipariş Başarılı",
	"Handledataerror": "Veri hatasını işle",
	"Copysuccess": "Başarıyı kopyala",
	"MyBalanceLoding": "Bakiye Yüklemem",
	"UPDATE": "GÜNCELLEME",
	"New_version_available": "Yeni sürüm mevcut",
	"Please,update_app_to_new_version": "Lütfen uygulamayı yeni sürüme güncelleyin",
	"tabbar": {
		"Home": "Ev",
		"AndarBahar": "Andar Bahar",
		"Invest": "Yatırım",
		"Bonus": "ikramiye",
		"Mine": "Bana ait",
		"Trending": "Trendler",
		"Wallet": "Cüzdan",
		"Rewards": "Ödüller"
	},
	"homeTop": {
		"Sign_Up": "Kayıt Ol",
		"Sign_up_for_rewards": "Ödüller için kaydolun",
		"up_to": "kadar",
		"TOTAL_ASSETS": "TOPLAM VARLIKLAR",
		"User_ID": "Kullanıcı ID'si",
		"Registration_time": "Kayıt tarihi"
	},
	"PieChart": {
		"BIG&SMALL": "BÜYÜK&KÜÇÜK",
		"NUMBER": "NUMARA",
		"ODD&EVEN": "TEK&ÇİFT",
		"ALL_NUMBER_TRENDING": "TÜM NUMARALARIN TRENDİ",
		"BIG&SMALL_NUMBER_TRENDING": "BÜYÜK&KÜÇÜK NUMARALARIN TRENDİ",
		"ODD&EVEN_NUMBER_TRENDING": "TEK&ÇİFT NUMARALARIN TRENDİ"
	},
	"register": {
		"tips_3": "Kodu gelen kutunuza almadıysanız, lütfen spam klasörünüzü kontrol edin.",
		"receiveCode": "Kodu almadınız mı?",
		"receiveCode_title": "Kod e-posta adresinize gönderildi. Kodu birkaç deneme sonrasında alamadıysanız, lütfen şunları deneyin:",
		"receiveCode_text1": "1. Spam/Junk klasörünü kontrol edin.",
		"receiveCode_text2": "2. E-posta adresinizin doğru olduğundan emin olun.",
		"receiveCode_text3": "3. Mesaj birkaç dakika gecikebilir. 20 dakika sonra tekrar deneyin.",
		"receiveCode_text4": "4. Bu e-posta adresi zaten kayıtlıysa, doğrulama kodunu göndermeyeceğiz. Lütfen giriş yapın veya şifrenizi sıfırlayın.",
		"Sign_up_for_Rewards": "Ödüller için kaydolun",
		"Email_Verification": "E-posta Doğrulama",
		"tips": "{{formData.email}} adresine gönderilen 6 haneli doğrulama kodunu girin. Kod 30 dakika geçerlidir.",
		"tips_1": "Lütfen ",
		"tips_2": " adresine gönderilen 6 haneli doğrulama kodunu girin. Kod 30 dakika geçerlidir.",
		"Resend_code_in": "Kodu yeniden gönderin",
		"Sent_again": "Yeniden gönderildi",
		"CREATE_ACCOUNT": "HESAP OLUŞTUR",
		"inviteCodecheck": "Davet kodu boş olamaz!",
		"Mobile": "Cep numarası",
		"Mobilemsg": "Cep telefonu numarası gerekli",
		"Password": "Şifre",
		"Passwordmsg": "Şifre gereklidir",
		"OTPCode": "OTP Kodu",
		"OTPCodemsg": "OTP Kodu gerekli",
		"SendOTP": "OTP gönder",
		"Email": "Email",
		"Emailmsg": "Email gereklidir",
		"Invitecode": "davet kodu",
		"Invitecodemsg": "Davet kodu gerekli",
		"emailFormatter": "Lütfen doğru e-posta adresini girin",
		"usernameFormatter": "kullanıcı adı karakter ve sayı olmalı ve aralık 6<=uzunluk<=20",
		"phoneFormatter": "Lütfen 10 haneli cep telefonu numarasını giriniz",
		"msgsuccess": "Başarıyla kayıt olundu",
		"msgerr": "kayıt başarısız",
		"errorphonecheck": "Cep telefonu numarası boş geçilemez!",
		"phonecheck": "Lütfen 10 haneli cep telefonu numarasını giriniz!",
		"imgcodecheck": "OTP Kodu boş geçilemez!"
	},
	"login": {
		"Welcome_back": "Tekrar hoş geldiniz",
		"tips": "{{this.$LogoName}} Hizmet Şartları ve Gizlilik Politikasını okudum ve kabul ediyorum",
		"tips_1": "Okudum ve kabul ediyorum",
		"tips_2": "Hizmet Şartları",
		"tips_3": "ve",
		"tips_4": "Gizlilik Politikası",
		"Continue_with_Google": "Google ile devam et",
		"Continue_with_Apple": "Apple ile devam et",
		"text": "Henüz bir hesabınız yok mu?",
		"text2": "Zaten bir hesabınız var mı?",
		"Login": "giriş yapmak",
		"Register": "Kayıt olmak",
		"username": "Kullanıcı adı veya telefon numarası",
		"password": "Şifre",
		"imgcode": "resim kodu",
		"BackHome": "Eve dön",
		"Forgetpwd": "Şifreyi unut"
	},
	"forgot": {
		"title": "Şifreyi unut",
		"msgsuccess": "Parola sıfırlama tamamlandı",
		"newpassword": "Yeni Şifre",
		"newpasswordmsg": "Yeni şifre gerekli",
		"Continue": "Devam etmek"
	},
	"home": {
		"Revolutionize_Trading": "Ticarette Devrim Yaratmak",
		"Shape_the_Future": "Geleceği Şekillendir",
		"content0": "Haydi, Kripto Para Borsası'nın Yeni Çağını Birlikte Deneyimleyelim!",
		"3_Reasons_for_Choosing": "Seçme Nedenleri",
		"Innovative_Trading_Features": "Yenilikçi Ticaret Özellikleri",
		"content1": "Piyasada mevcut olan her şeye benzemeyen devrimci bir ticaret deneyimi. Yenilikçi ticaret özellikleri, gelişmiş güvenlik önlemleri ve hızlı işlem hızlarıyla rekabet avantajı sağlamak için tasarlanmıştır.",
		"Lower_Fees_and_Better_Rewards": "Daha Düşük Ücretler ve Daha İyi Ödüller",
		"content2": "Arayüzümüz, ticareti başlatmak isteyenler için basit hale getirir. Ücretsiz bir hesap oluşturabilir, kripto para birimleriyle ticaret yapabilirsiniz ve borsamızdaki düşük işlem ücretleriyle kripto para birimi ticaretinin tadını çıkarabilirsiniz. Ayrıca, davet ettiğiniz tüm kullanıcılar için borsa işlem ücretlerinin %50'sine kadar paylaşabilirsiniz.",
		"Institutional-grade_Security": "Kurumsal Güvenlik",
		"content3": "En yüksek güvenlik standartlarından birini izleriz. Ayrıca, tüm kripto paranızı soğuk depolama ortamında saklarız. Müşteri fonlarımız için en az 1:1 rezerv oranını garanti ediyoruz. 300M USDT fonumuz, güvenlik tehditlerine karşı ek bir koruma katmanı sağlar.",
		"Start_investing_in_as_little_as_5_minutes": "Sadece 5 dakika içinde yatırıma başlayın",
		"content4": "{{this.$LogoNames.toUpperCase()}} ile ücretsiz kaydolun",
		"content4_1": "Ücretsiz kayıt olun ",
		"content4_2": " ile",
		"Complete_google_authentication": "Google kimlik doğrulamasını tamamlayın",
		"content5": "Başlamak için e-posta adresinizi girin ve doğrulayın. Bir hesap açmak için herhangi bir ücret yoktur.",
		"content7": "Google kimlik doğrulayıcınızı 5 dakikadan kısa bir sürede doğrulayarak tam işlevselliği kilidini açın.",
		"content8": "{{this.$LogoNames.toUpperCase()}} hesabınızı anında finanse edin",
		"content8_1": "Hesabınızı anında finanse edin",
		"content8_2": "ile",
		"content9": "Belirli ağlar aracılığıyla USDT yatırın ve fonlarınıza anında erişim sağlayın. İşlem yapmaya başlamak için 3-5 gün beklemek gerekmez.",
		"Start_building_your_crypto_portfolio": "Kripto portföyünüzü oluşturmaya başlayın",
		"Invitation_Program": "Davet Programı",
		"Referral_Program": "Referans Programı",
		"content10": "Geçerli herhangi bir daveti tamamlayın ve davet bonusu olarak ortalama 10 USDT değerinde bir bonus alın. Tek bir kişi için maksimum bonus ",
		"content11": "Davet edenler, davet ettikleri kişiler tarafından oluşturulan işlem hacmine orantılı bonus alabilirler. Geçerli bir davet için maksimum bonus Unlimit USDT'dir.",
		"TRADING_TASKS": "TİCARET GÖREVLERİ",
		"content12": "Kullanıcılar, ticaret yaparken USDT olarak ödüller alacaklar ve farklı ticaret modları için çeşitli ticaret görevleri olacaktır. Ödüller anında dağıtılacaktır.",
		"Trade_anytime_anywhere": "Her zaman, her yerde ticaret yapın",
		"content13": "USA, Inc. New York Devlet Finansal Hizmetler Departmanı tarafından Sanal Para Birimi İşletme Faaliyetinde bulunmak için lisanslandırılmıştır.",
		"USA_Inc": "USA, Inc",
		"content6": "Uygulamamızı indirin ve 7/24 bağlı kalın",
		"Legal&Disclosures": "Hukuki ve Açıklamalar",
		"Regulatory_License": "Düzenleyici Lisans",
		"AML/KYC_Policies": "AML/KYC Politikaları",
		"Privacy_Policy": "Gizlilik Politikası",
		"Terms_of_Use": "Kullanım Şartları",
		"Legal_Statement": "Hukuki Açıklama",
		"Risk_Disclosure": "Risk Açıklaması",
		"About_Us": "Hakkımızda",
		"Proof_of_Reserves": "Rezerv Kanıtı",
		"Twitter": "Twitter",
		"Bitget_Token": "Bitget Jetonu",
		"Careers": "Kariyerler",
		"Company": "Şirket",
		"Support_Service": "Destek Hizmeti",
		"title": "Bir uygulama ile aç",
		"titlebtn": "İndirmek",
		"Balance": "Denge",
		"ID": "İD",
		"Recharge": "şarj et",
		"Withdraw": "Geri çekilmek",
		"Bonus": "ikramiye",
		"Checkin": "Giriş",
		"Eventclosed": "etkinlik kapatıldı",
		"Ranking": "Sıralama"
	},
	"Ranking": {
		"Today_s winning ranking": "Bugünün kazanan sıralaması",
		"tips1": "Kazanan miktar ne kadar büyükse = sıralama o kadar yüksek olur!",
		"UserName": "Kullanıcı adı",
		"Winning Amount": "Kazanan Miktar",
		"Today_s rebate ranking": "Bugünün indirim sıralaması",
		"tips2": "Daha düşük seviyeli kullanıcılar için daha yüksek indirimler = daha yüksek sıralama!",
		"Rebate Amount": "İndirim Tutarı",
		"Rebate ranking": "En iyi 20 indirim sıralaması"
	},
	"Checkin": {
		"Checkin": "Giriş",
		"CheckinList": "Giriş listesi"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Kural",
		"Record": "Kayıt",
		"Records": "Andar Bahar  Records",
		"MyOrder": "Benim siparişim",
		"more": "Daha",
		"Period": "Dönem",
		"Order": "Emir",
		"Buy": "Satın almak",
		"Result": "Sonuç",
		"Profit": "Kâr",
		"AndarBaharRule": "Andar Bahar Kuralı",
		"CountDown": "Geri sayım",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "sonuç",
		"ok": "Tamam",
		"start": "başlangıç",
		"Point": "Nokta",
		"ContractPoint": "Sözleşme Noktası",
		"Number": "Sayı",
		"Delivery": "Teslimat",
		"Fee": "Ücret",
		"Amount": "Miktar"
	},
	"pokerOrder": {
		"OrderRecord": "Sipariş Kaydı",
		"WatingOrder": "Yeme Düzeni",
		"HistoryOrder": "Tarih Sırası",
		"Nomoredata": "Daha fazla veri yok",
		"Buy": "Satın almak",
		"Result": "Sonuç",
		"Time": "Zaman",
		"Amount": "Miktar",
		"Fee": "Ücret",
		"Profit": "Kâr"
	},
	"pokerRecord": {
		"title": "Andar Bahar Rekoru",
		"Period": "Dönem",
		"Result": "Sonuç",
		"OpenTime": "Açık Zaman",
		"Nomore": "Daha fazla yok"
	},
	"Google2FA": {
		"title": "Google 2FA Kimlik Doğrulaması",
		"Authenticator_Instructions": "Doğrulayıcı Talimatları",
		"Authenticator_Status": "Doğrulayıcı Durumu",
		"Google_Authenticator": "Google Kimlik Doğrulayıcı",
		"Download_Google_Authenticator_App": "Google Authenticator Uygulamasını İndirin",
		"App_Store": "Uygulama mağazası",
		"Google_Play": "Google Oyun",
		"Next": "Sonraki",
		"Backup_key": "yedek anahtar",
		"Backup_key_text": "Lütfen bu Yedekleme Anahtarını güvenli bir yere kaydedin. Bu Anahtar, telefonunuzu kaybetmeniz durumunda Doğrulayıcınızı kurtarmanıza olanak tanır. Aksi takdirde, Kimlik Doğrulayıcınızı sıfırlamak için müşteri hizmetlerine danışmanız gerekecektir.",
		"Verifiaction": "Doğrulama",
		"Verifiaction_text": "Google doğrulama kodunu girin",
		"Google_verification_code": "Google doğrulama kodu",
		"contentlist1": "1. +this.$LogoName+'dan 16 haneli Yedekleme Anahtarını kaydedin ve bir kasada saklayın.",
		"contentlist1_1": "1.",
		"contentlist1_2": "'dan 16 haneli Yedekleme Anahtarını kaydedin ve bir kasada saklayın.",
		"contentlist2": "2.Google Authenticator'ı açın.",
		"contentlist3": "3.Bir +this.$LogoName+ hesabı ekleyin ve kopyalanan 16 haneli anahtarı girin.",
		"contentlist3_1": "3.Bir",
		"contentlist3_2": "hesabı ekleyin ve kopyalanan 16 haneli anahtarı girin.",
		"contentlist4": "4. Ekranda 6 haneli bir doğrulama kodu göreceksiniz.",
		"contentlist5": "5. 6 haneli kodu kopyalayın ve +this.$LogoName+ içine yapıştırın.",
		"contentlist5_1": "5. 6 haneli kodu kopyalayın ve ",
		"contentlist5_2": "içine yapıştırın.",
		"contentlist6": "6. Ardından, Doğrulayıcınız başarıyla bağlanacaktır."
	},
	"win": {
		"Live_Price": "Canlı Fiyat",
		"title1": "Los resultados de inversión se calculan por el último dígito de la operación spot de Binance BTC/USDT correspondiente al tiempo UTC. Los inversores pueden verificar los resultados en Binance en cualquier momento para garantizar la equidad y seguridad de la inversión.",
		"title": "Hay 2 modos de inversión",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Cada ronda dura 1 minuto. El modo Lite solo proporciona inversión en números pares e impares. El resultado se calcula por el último dígito del resultado de cierre del margen de operación de Binance BTC/USDT en intervalos de 1M.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Her tur 5 dakika sürer. Pro modu EPOCH EPT'in tam yatırım yöntemini kilidini açar. Sonuç, Binance BTC/USDT spot ticareti 5 dakikalık aralığın kapanış sonucundaki son rakamla hesaplanır.",
		"5M_Mode_text_1": "Her tur 5 dakika sürer. Pro modu ",
		"5M_Mode_text_2": "'ın tam yatırım yöntemini kilidini açar. Sonuç, Binance BTC/USDT spot ticareti 5 dakikalık aralığın kapanış sonucundaki son rakamla hesaplanır.",
		"Investment_Guide": "Yatırım Rehberi",
		"Investment_Rules": "Yatırım Kuralları",
		"title2": "Sonucun adil olduğu nasıl kanıtlanır?",
		"Investment_Info": "Yatırım Bilgileri",
		"3M_Mode": "3 Dakikalık Mod",
		"3M_Mode_text": "Her tur 3 dakika sürer. Sonuç, Binance BTC/USDT spot işlemi 3M aralığının kapanış sonucunun son rakamıyla hesaplanır.",
		"Yatırım_dönüşü": "Yatırım dönüşü",
		"Sayı": "Sayı",
		"Kazanç_Yatırılan_tutar": "Kazanç: Yatırılan tutar",
		"guide1": "Bir zaman modu seçin.",
		"guide2": "Yatırım tutarınızı ayarlayın.",
		"guide3": "Yatırım yapmak istediğiniz sayıları seçin.",
		"guide4": "Portföyünüzü kontrol edin ve yatırım yapmak için tıklayın.",
		"NEXT": "İLERİ",
		"Yatırım_Tutarı": "Yatırım Tutarı",
		"Toplam": "Toplam",
		"YATIRIM": "YATIRIM",
		"Portföy": "Portföy",
		"Yatırım": "Yatırım",
		"BINANCE": "BINANCE",
		"Bitcoin_Fiyatı": "Bitcoin Fiyatı",
		"YatırımKaydı": "Yatırım Kaydı",
		"Best_Assistant": "En İyi Asistan",
		"last_100_rounds": "(son 100 tur)",
		"What_do_these_data_mean": "Bu veriler ne anlama geliyor?",
		"Missing": "Eksik",
		"Missing_text": "Bu, bu sayıyı üreten son turdan bu yana tur sayısı anlamına gelir.",
		"Frequency": "Sıklık",
		"Frequency_text": "Son 100 turda görünen karşılık gelen sayının sürelerini ifade eder.",
		"Max_Continued": "Maks Devam",
		"Max_Continued_text": "Son 100 turda karşılık gelen sayının maksimum ardışık görünme süresini ifade eder.",
		"Rule": "Kural",
		"Trend": "Akım",
		"MyBalance": "Benim dengem",
		"JoinGreen": "Green'e Katılın",
		"JoinRed": "Red'e Katıl",
		"JoinViolet": "Violet'e Katılın",
		"Parity": "küçük",
		"Sapre": "safir",
		"Bcone": "kemik",
		"Emerd": "Zümrüt",
		"Record": "Kayıt",
		"more": "Daha",
		"Period": "Dönem",
		"Price": "Fiyat",
		"Result": "Sonuç",
		"My": "Benim",
		"Order": "Emir",
		"Buy": "Satın almak",
		"Profit": "Kâr",
		"Green": "Green",
		"Red": "Red",
		"Violet": "Violet",
		"tips": "İşlem ücreti, sipariş tutarının %{bet_fee*100} kadarıdır",
		"tips1": "İşlem ücreti, sipariş tutarının ",
		"tips2": "kadarıdır ",
		"OrderAmount": "Sipariş miktarı",
		"Quantity": "Miktar",
		"TransactionFee": "İşlem ücreti",
		"TotalAmount": "Toplam tutar",
		"PresalePolicy": "Ön Satış Politikası",
		"Iagree": "Kabul ediyorum",
		"err1": "Lütfen önce satış öncesi yönetim kurallarını kabul edin"
	},
	"historyPeriods": {
		"Orderrecord": "Sipariş kaydı",
		"record": "kayıt"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Yeme Düzeni",
		"HistoryOrder": "Tarih Sırası"
	},
	"Activity": {
		"added_to_account_balance": "hesap bakiyesine eklendi.",
		"contentText": "Cuando los invitados completen el primer depósito, se contará como una invitación válida. El invitador recibirá una bonificación de invitación por cada invitación válida. Los niveles de bonificación por invitación van desde {{aaaaaa}}USDT por 1 invitación válida hasta {{aaaaaa}}USDT por 500 invitaciones válidas.",
		"contentText_1": "Cuando los invitados completen el primer depósito, se contará como una invitación válida. El invitador recibirá una bonificación de invitación por cada invitación válida. Los niveles de bonificación por invitación van desde",
		"contentText_2": "para 1 invitación válida hasta",
		"contentText_3": "para 500 invitaciones válidas",
		"contentText_3_1": " para ",
		"contentText_3_2": " invitaciones válidas",
		"contentText2": "Los invitadores compartirán el {{LV.1%}} de la tarifa de operación de cada volumen de operación de invitados de Nivel 1. Los invitadores compartirán el {{LV.2%}} de la tarifa de operación de cada volumen de operación de invitados de Nivel 2. La bonificación máxima por volumen de operaciones de los invitados es ilimitada en USDT.",
		"contentText2_1": "Los invitadores compartirán",
		"contentText2_2": "de la tarifa de operación de cada volumen de operación de invitados de Nivel 1. Los invitadores compartirán",
		"contentText2_3": "de la tarifa de operación de cada volumen de operación de invitados de Nivel 2. La bonificación máxima por volumen de operaciones de los invitados es ilimitada en USDT.",
		"My_Rewards_History": "Ödül Geçmişim",
		"Cumulative_Single_Number_Trading_Volumn": "Kümülatif Tek Numara İşlem Hacmi",
		"Cumulative_ODD_Number_Trading_Volumn": "Kümülatif TEK Sayı İşlem Hacmi",
		"Cumulative_EVEN_Number_Trading_Volumn": "Kümülatif ÇİFT Sayı İşlem Hacmi",
		"Cumulative_BIG_Number_Trading_Volumn": "Kümülatif BÜYÜK Sayı İşlem Hacmi",
		"Cumulative_SMALL_Number_Trading_Volumn": "Kümülatif KÜÇÜK Sayı İşlem Hacmi",
		"Advanced_Tasks": "Gelişmiş Görevler",
		"Invite_Friends_and_Earn_Up_to": "Arkadaşlarınızı Davet Edin ve En Fazla Kazanın",
		"Referral_Code": "Davet Kodu",
		"Referral_Link": "Davet Linki",
		"INVITE_FRIENDS": "ARKADAŞLARINIZI DAVET EDİN",
		"Complete_tasks_to_earn_up_to": "En fazla kazanmak için görevleri tamamlayın",
		"MY_REWARDS": "ÖDÜLLERİM",
		"SHARE": "PAYLAŞ",
		"Earn_with_your_friends_together": "Arkadaşlarınızla birlikte kazanın",
		"Get_your_link": "Linkinizi Alın",
		"Claim_your_rewards": "Ödüllerinizi Talep Edin",
		"Earn_together": "Birlikte Kazanın",
		"contentlist0": "{{gogobit}}'a katılın ve referans bağlantınızı alın. Bu bağlantı aracılığıyla kaydolan kullanıcılar ödüller alacaklar.",
		"contentlist0_1": "Katılın ",
		"contentlist0_2": " ve referans bağlantınızı alın. Bu bağlantı aracılığıyla kaydolan kullanıcılar ödüller alacaklar.",
		"contentlist1": "Arkadaşlarınız ilk para yatırmalarını yapar ve gerekli işlem hacmini tamamlar tamamlamaz, {{XX}} USDT tutarında bir yönlendirme ödülü alacaksınız. Ödüller {{XX}} USDT'ye kadar olabilir.",
		"contentlist1_1": "Arkadaşlarınız ilk para yatırmalarını yapar ve gerekli işlem hacmini tamamlar tamamlamaz, ortalama {{XX}} USDT tutarında bir yönlendirme ödülü alacaksınız.",
		"contentlist1_2": " USDT tutarında bir yönlendirme ödülü alacaksınız. Arkadaşlarınız ilk para yatırmalarını yapar ve gerekli işlem hacmini tamamlar tamamlamaz, ödüller ",
		"contentlist1_3": " USDT'ye kadar olabilir.",
		"contentlist2": "Arkadaşınız hesap oluşturduktan sonra, 5.050 USDT'ye kadar işlem bonusu talep edebilir.",
		"Referral_Rewards": "Davet Ödülleri",
		"Detail_Rules": "Detaylı Kurallar",
		"Invitation_Bonus": "Davetiye Bonusu",
		"Up_to": "Kadar",
		"content0": "Herhangi bir geçerli davetiyeyi tamamlayın ve ortalama değeri 10 USDT olan bir davet bonusu alın. Tek bir kişi için maksimum bonus ",
		"Trading_Bonus": "İşlem Bonusu",
		"content1": "Davet edilen kişiler tarafından oluşturulan işlem hacmine orantılı bonus kazanabilirsiniz. Geçerli bir davet için maksimum bonus",
		"My_Referral_History": "Davet Geçmişim",
		"Total_Invitees": "Toplam Davetli",
		"Valid_Invitees": "Geçerli Davetli",
		"Cumulative_Invitees": "Davetliler",
		"Trading_Volumn": "İşlem Hacmi",
		"Detail_Rules_title": "Günlük 888 USDT Şans Çekilişi",
		"Detail_Rules_title1": "1. Günün vadeli işlem verileri bir sonraki gün hesaplanacaktır.",
		"Detail_Rules_title2": "2. Gün içinde 200.000 USDT veya daha fazla vadeli işlem hacmi gerçekleştiren kullanıcılar, 888 USDT ödülü için bir çekiliş hakkı kazanacaklardır.",
		"CLAIM_NOW": "ŞİMDİ TALEP ET",
		"INVEST_NOW": "ŞİMDİ YATIRIM YAPIN",
		"title": "ikramiye cüzdan",
		"BonusBalance": "ikramiye Bakiyesi",
		"Withdrawtobalance": "Dengelemek için para çekin",
		"BonusList": "ikramiye Listesi",
		"Bonuswithdrawtobalance": "Dengelemek için ikramiye çekme",
		"WithdrawalAmount": "Para Çekme Miktarı",
		"Pleaseentertheamount": "Lütfen tutarı girin",
		"err1": "Para çekme tutarı 0 olamaz",
		"success": "Transfer başarısı"
	},
	"Refer": {
		"title": "referansım",
		"ReferTotalBonus": "Toplam Bonusa Bakın",
		"InviteFriends": "Arkadaşları davet etmek",
		"Level1": "Seviye 1",
		"Level2": "Seviye 2",
		"TotalPeople": "Toplam Kişi",
		"Bonus": "ikramiye",
		"Myinvitationlink": "davet bağlantım",
		"Rebate_description": "İndirim açıklaması"
	},
	"Task": {
		"InviteBonusToday": "Davet Bonusu Bugün",
		"bonusrecord": "ikramiye rekoru",
		"hint1": "1. Veriler her 10-30 dakikada bir güncellenir!",
		"hint2": "2. Yalnızca son 3 gün içinde kayıt yaptıran ve bugün yüklemeyi tamamlayanlar sayılır!",
		"hint2_1": "2. Yalnızca son ",
		"hint2_2": " gün içinde kayıt yaptıran ve bugün yüklemeyi tamamlayanlar sayılır!",
		"hint3": "3. Bugün minimum 20u depozito gerekli!",
		"hint3_1": "3. Bugün minimum ",
		"hint3_2": " depozito gerekli!",
		"hint4": "Son 33 gün içinde davet edilen kişi sayısı",
		"hint4_1": "Son   ",
		"hint4_2": " gün içinde davet edilen kişi sayısı",
		"hint5": "Bugün şarjı tamamlayan kişi sayısı",
		"Invite": "Davet etmek",
		"friendstorecharge": "şarj etmek için arkadaşlar",
		"Earn": "Para kazanmak",
		"Completed": "Tamamlanmış",
		"ExpireTime": "Vade zamanı"
	},
	"Invited": {
		"title": "Bonus Kayıt",
		"InviteTotalBonus": "Davet Toplam Bonusu",
		"InviteTime": "Davet Zamanı",
		"Bonus": "ikramiye",
		"NoRecored": "Kayıt yok"
	},
	"mine": {
		"JOIN US": "JOIN US",
		"title": "Kişisel Merkez",
		"InviteCode": "Davet Kodu",
		"AccountBalance": "Hesap bakiyesi",
		"BonusBalance": "Bonus Bakiyesi",
		"Wallet": "Cüzdan",
		"Bank": "Banka",
		"Invite": "Davet etmek",
		"Refer": "Bahsetmek",
		"CustomerService": "Müşteri servisi",
		"ComplaintsSuggestions": "Şikayet & Öneriler",
		"ChangePassword": "Şifre değiştir",
		"Activity": "Aktivite",
		"PresalePolicy": "Ön Satış Politikası",
		"DownloadApp": "indir uygulaması",
		"AppVersion": "Uygulama sürümü",
		"Logout": "Çıkış Yap",
		"Logouttips": "Oturumu kapatmak istediğinizden emin misiniz?"
	},
	"App": {
		"title": "Uygulama İndirme",
		"DownloadforAndroid": "Android için indir",
		"DownloadforiOS": "iOS için indir"
	},
	"Presale": {
		"title": "Ön Satış Politikası"
	},
	"Account": {
		"title2": "Yeni şifre belirle",
		"title2_text": "Google hesabınız için yeni bir şifre belirleyin. Şifrenizle Google hesabınıza giriş yapabilirsiniz.",
		"title_text": "Şifrenizi sıfırlamaya çalışıyorsunuz. Sıfırlama işlemi için Google Authenticator'ınızı hazır bulundurunuz.",
		"err1": "Girilen iki yeni şifre farklıdır",
		"success2": "Şifre başarıyla değiştirildi",
		"title": "Şifre değiştir",
		"OldPassword": "eski Şifre",
		"OldPasswordmsg": "eski şifre gerekli",
		"NewPassword": "Yeni Şifre",
		"NewPasswordmsg": "yeni şifre gerekli",
		"RetypeNewPassword": "Yeni şifrenizi tekrar yazınız",
		"RetypeNewPasswordmsg": "Yeni şifrenizi tekrar yazınız",
		"Continue": "Devam etmek"
	},
	"Service": {
		"title": "Hizmet",
		"CONTACTUS": "BİZE ULAŞIN",
		"tips": "Müşteri hizmetleri mola veriyor!",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "bağlantı"
	},
	"Bank": {
		"BankName": "Banka adı",
		"ChooseaCurrencyType": "Para Birimi Türünü Seçin",
		"SelectWithdrawalMethod": "Para Çekme Yöntemini Seçin",
		"Newbankcard": "Yeni banka kartı",
		"Editbankcard": "Banka kartını düzenle",
		"title": "Benim bankam",
		"Cannotbeempty": "Boş olamaz",
		"BankHolderName": "Banka Sahibinin Adı",
		"BankAccountNumber": "Banka hesabı numarası",
		"IFSCCode": "IFSC Kodu",
		"Email": "Email",
		"Phone": "Telefon",
		"OTPCode": "OTP Kodu",
		"tips1": "1. OTP almak için lütfen kayıtlı cep telefonu numaranızı kullanınız.",
		"tips2": "2. Fonların güvenliği için banka kartı günde yalnızca bir kez değiştirilebilir.",
		"Add": "Eklemek",
		"error1": "Banka Sahibi Adı boş geçilemez!",
		"error2": "Lütfen doğru Banka Sahibi Adını girin!",
		"error3": "Banka Adı boş geçilemez!",
		"error4": "Lütfen doğru Banka Adını giriniz!",
		"error5": "Banka Hesap Numarası boş geçilemez!",
		"error6": "Lütfen doğru Banka Hesap Numarasını giriniz!",
		"error7": "IFSC Kodu boş geçilemez!",
		"error8": "Lütfen doğru IFSC Kodunu girin!",
		"error9": "E-posta boş olamaz!",
		"error10": "Lütfen doğru e-posta adresini girin!",
		"error11": "telefon boş olamaz!",
		"error12": "OTP Kodu boş geçilemez!",
		"Successfully": "Banka kartı başarıyla eklendi"
	},
	"wallet": {
		"title": "Cüzdanım",
		"MyBalance": "Benim dengem",
		"MyBank": "Benim bankam",
		"RechargeRecords": "Şarj Kayıtları",
		"WithdrawalRecords": "Para Çekme Kayıtları"
	},
	"Rechargepage": {
		"Reminder": "Hatırlatma",
		"Reminder_1": "1. Adrese USDT'den başka herhangi bir kripto para birimi yatırmayın. Bu adrese desteklenmeyen coin veya token yatırmak geri alınamaz kayıplara neden olabilir.",
		"Reminder_2": "2. Minimum yatırım miktarı 5 USDT'dir.",
		"Reminder_2_1": "2. Minimum yatırım miktarı ",
		"Reminder_2_2": " USDT'dir.",
		"Reminder_3": "3. Yatırımlar 15 ağ onayından sonra hesabınıza yansıtılacaktır.",
		"Minimum": "Asgari",
		"title": "şarj et",
		"History": "Tarih",
		"Mybalance": "Benim dengem",
		"RechargeAmount": "Şarj Miktarı",
		"RechargeAmountmsg": "Lütfen tutarı girin",
		"tips1": "Ödeme kanalı bakımda!",
		"tips2": "Lütfen ödeme kanalını seçin",
		"PayNow": "Şimdi öde",
		"err1": "Ödeme kanalı bakımda",
		"err2": "tutardan az olamaz ",
		"err3": "Lütfen ödeme kanalını seçin",
		"Rechargemethod": "Şarj yöntemi"
	},
	"Rechargerecord": {
		"title": "Şarj kaydı",
		"WatingOrder": "Vuruş Sırası",
		"HistoryOrder": "Tarih Sırası",
		"Rupee": "Dolar",
		"Status": "Durum",
		"Time": "Zaman"
	},
	"withdrawal": {
		"Tips": "İpuçları",
		"Tips_1": "İpuçları: ",
		"Tips_2": " USDT ve altındaki para çekme ücreti ",
		"Tips_3": " USDT'dir, ",
		"Tips_4": "USDT'nin üzerindeki para çekme ücreti %",
		"Tips_5": "dir.",
		"WalletAddress": "Cüzdan Adresi",
		"Addwalletaddress": "Cüzdan adresi ekle",
		"Network_fee": "Ağ ücreti",
		"Receive_amount": "Alınacak miktar",
		"Select Chains": "Zincirleri Seçin",
		"Bankcard": "banka kartı",
		"Nobankcardselected": "Banka kartı eklenmedi",
		"Withdrawalfee": "Para çekme ücreti",
		"title": "para çekme",
		"Mybalance": "Benim dengem",
		"Withdrawablebalance": "çekilebilir bakiye",
		"tips1": "toplam satın alma tutarı {{codeAmount}} tutarından fazla olmalıdır, tamamı geri çekilebilir",
		"tips1-1": "toplam satın alma tutarı",
		"tips1-2": "tutarından fazla olmalıdır, tamamı geri çekilebilir",
		"tips2": "Günlük para çekme sayısı {{draw_num}} defadır",
		"tips2-1": "Günlük para çekme sayısı",
		"tips2-2": "defadır",
		"tips3": "Maksimum günlük para çekme tutarı",
		"tips4": "Minimum tek çekim tutarı ₹{{draw_min_money}} ve maksimum tek çekim tutarı ₹{{draw_max_money}}",
		"tips4-1": "Minimum tek çekim tutarı",
		"tips4-2": "ve maksimum tek çekim tutarı ",
		"tips5": "Para çekme zamanı {{draw_time}}, lütfen belirtilen zamanda para çekin",
		"tips5-1": "Para çekme zamanı",
		"tips5-2": "lütfen belirtilen zamanda para çekin",
		"tips6": "Nakit çekme, nakit çekme ücretinin %{{draw_fee}} kadarını düşecektir",
		"tips6-1": "Nakit çekme, nakit çekme ücretinin",
		"tips6-2": "kadarını düşecektir",
		"tips7": "Para çekme tutarınız ₹{{solid_min_amount}} tutarından az veya eşitse, ₹{{solid_feemoney}} tutarında sabit bir ücret alınacaktır",
		"tips7-1": "Para çekme tutarınız",
		"tips7-2": "tutarından az veya eşitse",
		"tips7-3": "tutarında sabit bir ücret alınacaktır",
		"WithdrawalAmount": "Para Çekme Miktarı",
		"WithdrawalAmountmsg": "Lütfen tutarı girin",
		"Withdrawal": "Para çekme",
		"WithdrawalHistory": "Para Çekme Tarihi",
		"Tipsmsg": "Para çekme başvurusu başarılı, denetim sonucu bekleniyor!",
		"dangerMsg1": "Lütfen önce banka kartınızı ekleyin",
		"dangerMsg2": "Para çekme tutarı şundan az olamaz:",
		"dangerMsg3": "Para çekme tutarı 100'ün katı olmalıdır",
		"dangerMsg4": "Para çekme tutarı şundan fazla olamaz:",
		"dangerMsg5": "Para çekme tutarı, Çekilebilir bakiyeden fazla olamaz",
		"dangerMsg6": "Lütfen önce banka kartınızı ekleyin"
	},
	"withdrawalrecord": {
		"title": "para çekme kaydı",
		"WatingOrder": "Vuruş Sırası",
		"HistoryOrder": "Tarih Sırası",
		"Rupee": "Dolar",
		"Status": "Durum",
		"Time": "Zaman",
		"Nomore": "Daha fazla yok!"
	},
	"Version": {
		"title": "Sürüm",
		"stableversion": "stabil sürüm",
		"Alreadythelatestversion": "Zaten en son sürüm",
		"Newversionfound": "Yeni sürüm bulundu",
		"DownloadUpdate": "Güncellemeyi indir"
	},
	"Bonus": {
		"title": "ikramiye Etkinlik",
		"ReceiveBonus": "ikramiye Al",
		"Hi": "MERHABA",
		"tips1": "Bonusu aldığınız için tebrikler",
		"tips2": "Şuna eşit veya daha fazla şarj etmeniz gerekir:",
		"tips3": "ve ardından ikramiye otomatik olarak hesap bakiyenize eklenecektir",
		"Received": "Kabul edilmiş",
		"Back": "Geri",
		"Rechargenow": "Şimdi şarj edin"
	},
	"ComplaintsSuggestions": {
		"title": "Şikayet & Öneriler",
		"Answered": "cevaplandı",
		"AddNew": "Yeni ekle",
		"Time": "Zaman",
		"Type": "Tip",
		"WhatsApp": "WhatsApp",
		"Description": "Tanım",
		"Remark": "açıklama",
		"Waiting": "Beklemek",
		"Suggestion": "Telkin",
		"Consult": "danışın",
		"RechargeProblem": "Şarj Sorunu",
		"WithdrawProblem": "Para Çekme Sorunu",
		"OrderProblem": "Sipariş Sorunu",
		"Other": "Diğer",
		"Describeyourproblems": "Sorunlarınızı tanımlayın",
		"WhatsApptocontactyou": "WhatsApp sizinle iletişim kurmak için",
		"submit": "göndermek",
		"err1": "WhatsApp boş olamaz!",
		"err2": "Yazım boş olamaz!",
		"err3": "Açıklama boş geçilemez!"
	},
	"slotMachine": {
		"title": "meyve makinesi",
		"Myblance": "Benim dengem",
		"Records": "Meyve makinesi kayıtları",
		"titleRecord": "kayıtlar"
	},
	"bankrecharge": {
		"Orderamount": "Sipariş miktarı",
		"Ordernumber": "Sipariş numarası",
		"BankName": "Banka adı",
		"BankAccount": "Banka hesabı",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Banka kartı numaranız",
		"Enterthebanktransactionordernumber": "Banka işlem sipariş numarasını girin",
		"submit": "göndermek",
		"Tips": "İpuçları",
		"tips1": "1.Sistem siparişinizi işliyor.",
		"tips2": "2. Hesap uzun süre gelmediyse lütfen müşteri hizmetleri ile iletişime geçin. ",
		"err1": "Lütfen banka kartı numaranızı giriniz",
		"err2": "Lütfen banka sipariş numarasını giriniz"
	},
	"javaCode": {
		"200": "başarı",
		"20001": "invalid access token",
		"20002": "invalid login token",
		"20003": "Phone error",
		"20004": "Phone has exists!",
		"20005": "Email error!",
		"20006": "Email exists!",
		"20007": "Password error!",
		"20010": "account or pwd error!",
		"20011": "bank account error!",
		"20012": "err bank ifsc, (standard IFSC format) - length 11, first four IFSC and 5th 0!",
		"20013": "beneficiary name error, 5 < name <= 50!",
		"20021": "Account not exists!",
		"20022": "Account is freeze!",
		"20031": "The layer is limit 10 !",
		"20032": "update relation role error !",
		"20033": "not enough balance!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Register Error!",
		"200082": "account has disable!",
		"200091": "add card is limit 10!",
		"200092": "input error login pwd too many times, and the account is disable!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Server hatası!",
		"Handledataerror": "Lütfen giriş yapın!",
		"-91": "Server hatası!",
		"-92": "Veri hatasını işle!",
		"-1": "Sistem hatası!",
		"-2": "parametre hatası",
		"-3": "veri yok",
		"-4": "kısa sürede sık operasyon",
		"-5": "Sistem meşgul, daha sonra tekrar deneyin!",
		"-6": " There are many people at present, please try again!",
		"-7": "data error!",
		"-8": "Google code error!",
		"-9": "System is being maintained! please try again later !",
		"-10": "save error!",
		"-11": "delete error!",
		"-12": "update error!",
		"-13": "Ilegal operation!",
		"-14": "Operation fail!",
		"-15": "Operation disabled!",
		"-16": "Operation Finished!",
		"-17": "Exists error",
		"-18": "Not exists!",
		"-19": "expires",
		"-20": "Has disabled",
		"-21": "err verify code",
		"-22": "The modification interval must be greater than 1 day",
		"-23": "The new password cannot be the same as the original password",
		"-100": "custom error!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Toplam Satın Alma Tutarı",
		"TotalRevenue": "Toplam gelir",
		"AccountBalance": "Hesap bakiyesi",
		"Income": "Gelir",
		"Transaction": "işlem",
		"Myhold": "benim tutuşum",
		"ProductList": "Ürün listesi",
		"Investmentperiod": "yatırım dönemi",
		"days": "günler",
		"Buynow": "Şimdi al",
		"Saletime": "satış zamanı",
		"Nomore": "Daha fazla yok",
		"ProductDetails": "Ürün Detayları",
		"ForSale": "For Sale",
		"Sale": "Sale",
		"SoldOut": "Sold Out",
		"ProductInformation": "Product Information",
		"InvestmentPeriod": "Investment Period",
		"TotalShare": "Total Share",
		"ExpectedReturn": "Expected Return",
		"SaleTime": "SaleTime",
		"PurchaseConditionsAndRules": "Purchase Conditions And Rules",
		"MinimumPurchaseAmount": "Minimum Purchase Amount",
		"MaximumSalesLimit": "Maximum Sales Limit",
		"AccountBalanceMustBeGreaterThan": "Account Balance Must Be Greater Than",
		"DayBetIsGreaterThan": "Day Bet Is Greater Than",
		"ProductDescription": "Product Description",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"Maturity": "Maturity",
		"EarlyRedemption": "Early Redemption",
		"Redemptiontime": "Redemption time",
		"Amountofincome": "Amount of income",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "Yardım",
		"HelpCenter": "Yardım Merkezi",
		"text": "herhangi bir soru, buraya bak"
	},
	"SetingHa": {
		"Seting": "Ayar",
		"SetingCenter": "Ayar Merkezi",
		"text": "Daha Fazla Ödül Alın"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"stop_investing": "Yatırımı bırak",
	"register.checkerr": "Parola: 8 veya daha fazla karakter kullanın (harfler ve sayıların kombinasyonu)",
	"home.Products&Services": "Yapılar ve Hizmetler",
	"home.Protection_Fund": "Koruma Fonda",
	"win.Investment_return": "Yatırımlara dönüş",
	"win.Number": "Sayılar",
	"win.Profit_Invested_amount": "Geldi: Yatırım miktarı",
	"win.Invest_Amount": "Yatırım miktarı",
	"win.Total": "Toplam miktar",
	"win.INVEST": "Yatırım",
	"win.Portfolio": "Yatırım portfeli",
	"win.Invest": "Yatırım",
	"win.Bitcoin_Price": "Bitcoin Ödülü",
	"win.InvestmentRecord": "Yatırım kayıtları",
	"Activity.content0_1": "Ortalama değeri kazanmak için geçerli davet tamamlayın",
	"Activity.content0_2": "100 davet için maksimum bonus",
	"Activity.Daily_Tasks": "Günlük Görevler",
	"mine.ProvacyPolicy": "Gizlilik politikası",
	"Rechargepage.Tips": "Teşekkürler hatırlatma: İlk yeniden yükleme ödülü yeniden yükleme miktarının %",
	"Rechargepage.Tips_1": "İlk yükleme ödülü, yükleme miktarına dayanılır.",
	"Rechargepage.Tips_2": "%",
	"withdrawal.Google_Code": "Google doğrulama kodu",
	"index.index.gayb21": "Ödülleri almak için kayıt:",
	"index.index.72y628": "EPOCH EPT'i seçmek için üç sebep",
	"index.index.z6022y": "EPOCH EPT endüstrilerin en yüksek güvenlik standartlarından birini takip ediyor. Şifrelerimizin yüzde 100'ünü dondurulmuş depoda sakladık. En azından müşteriler fonlarının 1:1 rezerve oranını sağlamayı garanti ediyoruz. 300 milyon dolar fonumuz güvenlik tehditlerinden fazla koruma sağlıyor.",
	"index.index.574982": "1. Şimdi EPOCH EPT için özgür kayıt",
	"index.index.qdi1g4": "2. Tamam Google Verifikasyonu",
	"index.index.weko28": "3. Hemen EPOCH EPT hesabınızı yeniden yükleyin.",
	"index.index.sgkb35": "EPOCH EPT Davet Program ı",
	"index.index.2u17nf": "Her geçerli davet tamamlaması ortalama değeri 5-15 USD'e kadar kazanabilir. 100 davet için maksimum bonus",
	"index.index.e8is4a": "EPOCH EPT Tahmin Program ı",
	"index.index.47uevl": "EPOCH EPT transaksyon görevi",
	"index.index.2516gq": "EPOCH EPT, Inc. New York Eyalet Mali Hizmetleri Departmanından sanal para işletim lisansı aldı.",
	"invest.index.3aqi3d": "Hesap dengesi:",
	"invest.index.ubk1j4": "Yatırımı bırak!",
	"invest.prize1.4733u6": "Yatırım portfeli:",
	"invest.prize1.t55a16": "Toplam miktar:",
	"login.login.09m52q": "Okudum ve EPOCH EPT ile kabul ettim.",
	"login.login.045csy": "Kullanıcı adı",
	"login.login.r7rbm5": "Lütfen anlaşmaya katılın!",
	"rewards.activityb.4nvrq9": "Davet ilk depozitlerini tamamladığında, geçerli bir davet olarak kabul edilir. Davet, her geçerli davet için davet bonusu alacak. Davet ödülü seviyesi 2 USD 1 geçerli davet, 559 USD 100 geçerli davet.",
	"rewards.activityb.t10k6e": "Davet eden her LV.1 davetinin transaksyon ücretinin %20'ini paylaşır. Davet eden her LV. 2 davetinin transaksyon ücretinin %10'ünü paylaşır. Davetin transaksyon ücreti ödülünün maksimum bonusu ABD doları sınırlı değil.",
	"rewards.activityb.3d6850": "LV. 1 Etkileyici davet sayısı",
	"rewards.activityb.lvxo74": "LV. 2 Etkileyici davet sayısı",
	"rewards.activityb.zj26f9": "LV.1 Tahmin edilen davet sayısı",
	"rewards.activityb.dkgxr5": "LV.2 Tahmin edilen davet sayısı",
	"rewards.activityb.xjo077": "İtiraz ödülü:",
	"rewards.activityb.k8wv6v": "EPOCH EPT'e katılın ve önerilen bağlantınızı alın. Bu bağla kayıtlı kullanıcılar ödülleri alacaklar.",
	"rewards.activityb.4slpzg": "Arkadaşınız ilk depozitlerini tamamladıktan sonra ve gerekli transaksyon volumunu tamamladıktan sonra ortalama değeri 5,59 dolar ile referral ödülünü alacaksınız. Maksimum ödül 559 dolara ulaşabilir.",
	"rewards.index.993jwg": "Başarılı kopyalandı",
	"rewards.index.7141ft": "Bu tarayıcı otomatik kopyalama desteklemez",
	"wallet.index.9uz2gn": "Ağ yönetimi 0 USDT ücreti",
	"wallet.index.ych3e1": "Gerçek Receipt:",
	"wallet.index.3v558d": "Hatırlatma: Eğer çekilme 20 dolardan az olsa, çekilme ücreti 1 dolardır. Eğer çekilme 20 dolardan fazla olsa, çekilme ücreti 3%",
	"wallet.index.255e30": "1. Maksimum günlük çekme miktarı 8000000 dolar.",
	"wallet.index.3g68m8": "2. En az tek çekme miktarı 5 dolar ve en az tek çekme miktarı 8000000 dolar.",
	"wallet.index.c6x3ti": "3. Çıkış zamanı saat 00:00'dan 23:59'ya kadar. Lütfen belirtilen zamanda çekilmesini yapın.",
	"wallet.index.h94rlg": "Minimum limit 5",
	"wallet.index.167862": "Lütfen cüzdanın adresini girin!",
	"wallet.index.pi869h": "Lütfen çekilme miktarını girin!",
	"wallet.index.ysa85i": "Maksimum tek çekilme miktarı",
	"components.changepass.68j9kh": "Parolayı yeniden ayarlamaya çalışıyorsun.",
	"components.changepass.ouxtp2": "Orijinal parola",
	"components.languagebox.8no849": "Japonca",
	"components.languagebox.oif20b": "Traditional Chinese",
	"components.mycode.xyqr66": "Görevleri tamamlamak 5000 USDT kadar kazanabilir",
	"components.mymenu.277ss7": "Kullanıcı Kimliği:",
	"components.mymenu.74l443": "Kayıt zamanı:",
	"components.myposter.36rw5e": "Teklif kodu:",
	"components.mytabbar.zw2bh2": "ev sayfası",
	"components.mytabbar.8x6c28": "trend",
	"components.mytabbar.28s7qr": "yatırım",
	"components.mytabbar.4r75d9": "Ödül",
	"components.xieyi.zqk7pe": "Yazıtipi boyutu: 16px; Yazı tipi ailesi: Tahoma;",
	"components.help.6gpud8": "İki yatırım modeli var:",
	"components.help.5qrq36": "Her dönem 1 dakika sürüyor ve sadece tek ya da iki yatırım teklif ediyor. Sonuç, Binance BTC/USDT nokta ticaretinin son sayısına göre 1 dakikalık K-satır kapatma fiyatının hesaplanır.",
	"components.help.isl7r1": "Her oturum 5 dakika sürer. Profesyonel versiyon EPOCH EPT'in bütün yatırım metodlarını açar. Sonuç, Binance BTC/USDT nokta ticaret fiyatının 5 dakikalık K-satır kapatma fiyatının son sayısına göre hesaplanır.",
	"components.help.eai3nm": "Yatırım geri dönüşü:",
	"components.help.35xg82": "Tek Numara:",
	"components.help.mpfg0s": "Geldi: Yatırım miktarı X 9.4",
	"components.help.v2ziib": "Tuhaf Numara:",
	"components.help.vfzxor": "Geldi: Yatırım miktarı X 1.94",
	"components.help.73168b": "Eve Numarası:",
	"components.help.76wgv1": "Büyük numara:",
	"components.help.05ed33": "Küçük Sayı:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Lütfen orijinal parolanı girin!",
	"components.changepass.xme6my": "Lütfen yeni bir parola girin!",
	"components.changepass.u434f8": "İki parola uymuyor!",
	"components.changepass.574790": "Başarılı değiştirildi!",
	"invest.index.9y8wg0": "Ödül kazanmaz.",
	"invest.index.ql5nc1": "Kazanan ödüller",
	"login.login.76451t": "Diğer değişiklikleri Değiştir. Kıymetli ticaret!",
	"login.login.915p36": "İlk küresel olarak mali yönetim sıraları",
	"login.login.sen863": "Merhaba girdikten sonra daha heyecanlı.",
	"login.login.nz6q9x": "Telefon giriş",
	"login.login.w7p592": "E-posta giriş",
	"login.login.g9y91k": "Mobile phone number",
	"login.login.456gbb": "Email number",
	"login.login.5ly8m8": "ülke",
	"login.login.69h472": "Lütfen bir ülke seçin",
	"login.login.i4p378": "Numara",
	"login.login.fl82xx": "Örnek:",
	"wallet.index.urkoub": "QR kodu yeniden yükle",
	"wallet.index.25cocl": "Adresi kopyala",
	"wallet.index.t35fvg": "1. Kaçırma zamanı: Lütfen Pazartesi günü 24 saat içinde cumartesi gününe kadar bölge vergi hesaplayın!",
	"wallet.index.68xx61": "2. Küresel ev mali geri çekilmesi en son 48 saat içinde, 8% (bölge vergi dahil olmak üzere) geri çekilmesi üzere alınmalıdır.",
	"wallet.index.4ws3w9": "3. USDT geri çekilmesi en son 24 saat içinde hesaba kredi edilmeli, 2% (bölge vergi dahil olmak üzere)",
	"wallet.index.9b7wq8": "4. En az çekilme miktarı 12 USDT ve maksimum tek çekilme miktarı 3000 USDT.",
	"wallet.index.23y92a": "Lütfen bilin ki",
	"wallet.index.pl5473": "5'in en az sınırı",
	"wallet.index.nb3u72": "Banka kartı bilgileri",
	"wallet.index.842so4": "Banka kartı numarasını ekle",
	"wallet.index.86j717": "İlişkin bankayı ekle",
	"wallet.index.763d7x": "Banka kartı ismini ekle",
	"wallet.index.50j237": "Bağlantı banka",
	"wallet.index.hb6lun": "Banka kartı adı",
	"wallet.index.62y2jc": "Banka kartı numarası",
	"login.register.666hp4": "Al fiyat farkındaki miktarlı ticaret",
	"login.register.9298b8": "Arkadaşları davet et",
	"login.register.375g14": "Yeni gelenler ilk kez katılıyorlar.",
	"login.register.01922f": "Bir kere yapılabilir.",
	"login.register.527o4q": "Günlük kimyasal gelir AI",
	"login.register.c563gt": "E-posta kayıtları",
	"login.register.2ls856": "Telefon numarası format ı hatası!",
	"login.register.4nu77e": "E-posta numarası format ı hatası!",
	"lang.zh.pu623z": "1 Geçerli Davet",
	"lang.zh.3hv80b": "USDT şarjı!",
	"lang.zh.9tli5f": "İpucu: Geri çekilme daha az",
	"lang.zh.62e882": "Amerikan Doları'na göre, X-Men'in bir parçası olmaya devam edecek.",
	"lang.zh.m3818q": "Amerikan Doları, M.I.T.",
	"lang.zh.xs2f10": "Erken dönüş hiçbir gelir sağlamayacak."
}