<template>
    <div id="app">
        <div
            class="layout"
            :class="!isMobile() ? 'pc_style' : ''"
        >
            <audio-mp3 ref="AudioMp3"></audio-mp3>
            <transition :name="transitionName">
                <router-view></router-view>
                
            </transition>
            <MyTabbar :class="!isMobile() ? 'pc_style' : ''"></MyTabbar>
        </div>

    </div>
</template>

<script>
import AudioMp3 from "@/components/AudioMp3";
import KeFu from "@/components/KeFu";
export default {
    components: {KeFu,AudioMp3},
    data() {
        return {
            transitionName: "",
        };
    },
    props: {},
    created() {
        let inviteCode = this.getQueryString("inviteCode");
        // console.log(inviteCode)
        if (inviteCode) {
            this.Cache.set("inviteCode", inviteCode);
            // console.log(123123)
            this.$router.push("/register");
        }
    },
    mounted() {
        // this.$toast("提示文案");
        // this.$message.error("提示文案");
    },
    computed: {},
    watch: {
        $route(to, from) {
            if (
                ["home", "invest", "trending", "wallet", "rewards"].indexOf(
                    to.meta.name
                ) != -1
            ) {
                this.transitionName = "slide-right";
            } else {
                this.transitionName = "slide-left";
            }
        },
    },
    methods: {
        getQueryString(paraName) {
             var url = document.location.toString();
              var arrObj = url.split("?");

              if (arrObj.length > 1) {
                var arrPara = arrObj[1].split("&");
                var arr;

                for (var i = 0; i < arrPara.length; i++) {
                  arr = arrPara[i].split("=");

                  if (arr != null && arr[0] == paraName) {
                    return arr[1];
                  }
                }
                return null;
              } else {
                return null;
              }
        },
        isMobile() {
            var u = navigator.userAgent;
            var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isiOS || isAndroid) {
                //ios或android
                return true;
            } else {
                //pc
                return false;
            }
        },
    },
};
</script>

<style lang="scss">
//个人配置
@import "./assets/css/common.scss";
@import "./assets/css/user.scss";
// @import "./assets/fonts/IMPACT.f1290202.TTF";
// @import "./assets/fonts/BRITANIC.e22abe6d.TTF";
// @import "./assets/fonts/Adobe-HeitiSt-R.5243e300.otf";

* {
    outline: none;
}

@font-face {
    font-family: "MyFont-BRITANIC";
    src: url("./assets/fonts/BRITANIC.e22abe6d.TTF");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: "MyFont-impact";
    src: url("./assets/fonts/IMPACT.f1290202.TTF");
    font-weight: normal;
    font-style: normal;
}

html {
    font-size: 12px;
    overflow-x: hidden;
}
#app,
body {
    overflow-x: hidden;
}

@media (max-width: 600px) {
    html {
        font-size: 15px;
    }
}

@media (min-width: 600px) {
    #app .pc_style {
        max-width: 30rem;
        margin: 0 auto;
        left: auto;
    }
    #app .betShowbox22 {
        width: 100%;
        height: 3.5rem;
        position: fixed;
        bottom: 5rem;
        z-index: 1;
        margin: auto;
        max-width: 30rem;
        left: 0;
        right: 0;
    }
}

.van-goods-action {
    background: #182639 !important;
}

img:not([src]) {
    opacity: 0;
}

.br{
    margin-top: 10px;
    margin-bottom: 10px;
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active {
    will-change: transform;
    transition: all 300ms;
    position: absolute;
    width: 100%;
    height: 100%;
}
.slide-right-enter {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-enter {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
}

pre {
    white-space-collapse: preserve-breaks;
}

.van-toast__loading{
    color:#fff!important;
}
</style>