module.exports = {
	"Language": {
		"title": "日本語"
	},
	"Noproductsforsale": "販売中の商品はありません",
	"rechargePresentationText0_1": "1000をリチャージすると、リワードが",
	"rechargePresentationText0_2": "10000をリチャージすると、リワードが",
	"rechargePresentationText0_3": "など。クリックしてください！",
	"rechargePresentationText0": "リチャージすると、リチャージ金額の",
	"rechargePresentationText1": "%が受け取れます。この活動は期間限定であり、プレゼントがなくなったら終了します。",
	"rechargePresentationText2": "リワード終了時間、カウントダウン！",
	"Online_Chat": "オンラインチャット",
	"gobindgoogle": "Google 認証システムをビン化してください",
	"Order": "注文",
	"Record": "記録",
	"Assist": "アシスト",
	"Market": "市場",
	"History": "歴史",
	"Confirm": "確認",
	"close": "閉じる",
	"Cancel": "キャンセル",
	"Previous": "前へ",
	"Next": "次へ",
	"Copy": "コピー",
	"Nomoredata": "データがありません",
	"moneychar": "₹",
	"DownloadApp": "今すぐアプリをダウンロード",
	"Yes": "はい",
	"No": "いいえ",
	"Wating": "処理中",
	"Recharge": "入金",
	"Withdrawal": "出金",
	"Success": "成功",
	"stop_investing": "投資を中止する",
	"OrderSuccessful": "注文が成功しました",
	"Handledataerror": "データエラーを処理する",
	"Copysuccess": "コピーに成功しました",
	"MyBalanceLoding": "残高をロード中",
	"UPDATE": "更新",
	"New_version_available": "新しいバージョンが利用可能です",
	"Please,update_app_to_new_version": "新しいバージョンに更新してください",
	"tabbar": {
		"Home": "ホーム",
		"AndarBahar": "アンダール・バハール",
		"Invest": "投資",
		"Bonus": "ボーナス",
		"Mine": "ウォレット",
		"Trending": "トレンド",
		"Wallet": "ウォレット",
		"Rewards": "報酬"
	},
	"homeTop": {
		"Sign_Up": "サインアップ",
		"Sign_up_for_rewards": "報酬にサインアップする",
		"up_to": "最大",
		"TOTAL_ASSETS": "総資産",
		"User_ID": "ユーザーID",
		"Registration_time": "登録日時"
	},
	"PieChart": {
		"BIG&SMALL": "ビッグ＆スモール",
		"NUMBER": "数字",
		"ODD&EVEN": "奇数＆偶数",
		"ALL_NUMBER_TRENDING": "全数字トレンド",
		"BIG&SMALL_NUMBER_TRENDING": "ビッグ＆スモール数字トレンド",
		"ODD&EVEN_NUMBER_TRENDING": "奇数＆偶数数字トレンド"
	},
	"register": {
		"tips_3": "受信トレイにコードが届かない場合は、スパム フォルダを確認してください。",
		"receiveCode": "認証コードが届かない場合",
		"receiveCode_title": "認証コードがメールに送信されました。数回試してもコードが届かない場合は、以下をお試しください。",
		"receiveCode_text1": "1. 迷惑メールフォルダ/スパムメールフォルダを確認してください。",
		"receiveCode_text2": "2. メールアドレスが正しいことを確認してください。",
		"receiveCode_text3": "3. メッセージには数分の遅延がある場合があります。20分後にもう一度お試しください。",
		"receiveCode_text4": "4. このメールアドレスが既に存在する場合、認証コードは送信されません。ログインするか、パスワードをリセットしてください。",
		"Sign_up_for_Rewards": "報酬のためのサインアップ",
		"Email_Verification": "メール認証",
		"tips": "{{formData.email}}に送信された6桁の認証コードを入力してください。コードの有効期限は30分間です。",
		"tips_1": "送信された6桁の認証コードを入力してください：",
		"tips_2": "コードの有効期限は30分間です。",
		"Resend_code_in": "再送信までの時間：",
		"Sent_again": "再送信",
		"CREATE_ACCOUNT": "アカウントを作成する",
		"inviteCodecheck": "招待コードを入力してください。",
		"Mobile": "携帯番号",
		"Mobilemsg": "携帯番号は必須です",
		"Password": "パスワード",
		"Passwordmsg": "パスワードは必須です",
		"OTPCode": "OTPコード",
		"OTPCodemsg": "OTPコードは必須です",
		"SendOTP": "OTPを送信",
		"Email": "メール",
		"Emailmsg": "メールは必須です",
		"Invitecode": "紹介コード（オプション）",
		"Invitecodemsg": "紹介コードは必須です",
		"emailFormatter": "正しいメールアドレスを入力してください",
		"usernameFormatter": "ユーザー名は文字と数字である必要があり、6≦length≦20でなければなりません",
		"phoneFormatter": "10桁の携帯番号を入力してください",
		"msgsuccess": "登録に成功しました",
		"msgerr": "登録に失敗しました",
		"errorphonecheck": "携帯番号は必須項目です！",
		"phonecheck": "10桁の携帯番号を入力してください！",
		"imgcodecheck": "OTPコードは必須項目です！"
	},
	"login": {
		"Welcome_back": "お帰りなさい",
		"tips": "{{this.$LogoName}}の利用規約とプライバシーポリシーに同意します。",
		"tips_1": "私は",
		"tips_2": "利用規約",
		"tips_3": "と",
		"tips_4": "プライバシーポリシー",
		"Continue_with_Google": "Googleで継続",
		"Continue_with_Apple": "Appleで継続",
		"text": "アカウントをお持ちでない場合",
		"text2": "アカウントをお持ちの場合",
		"Login": "ログイン",
		"Register": "登録",
		"username": "ユーザー名または電話番号",
		"password": "パスワード",
		"imgcode": "画像認証コード",
		"BackHome": "ホームに戻る",
		"Forgetpwd": "パスワードを忘れた場合"
	},
	"forgot": {
		"title": "パスワードを忘れた場合",
		"msgsuccess": "パスワードのリセットが完了しました",
		"newpassword": "新しいパスワード",
		"newpasswordmsg": "新しいパスワードは必須です",
		"Continue": "続ける"
	},
	"home": {
		"Revolutionize_Trading": "取引の革新",
		"Shape_the_Future": "未来を形作る",
		"content0": "一緒に暗号通貨取引の新しい時代を体験しましょう！",
		"3_Reasons_for_Choosing": "選択する3つの理由",
		"Innovative_Trading_Features": "革新的な取引機能",
		"content1": "市場に現在存在するものとは異なる革命的な取引体験。革新的な取引機能、高度なセキュリティ対策、超高速取引速度を備え、競争力を持っています。",
		"Lower_Fees_and_Better_Rewards": "低コストでより良い報酬",
		"content2": "初心者にも取引が簡単なインターフェース。無料でアカウントを作成し、マーケットプレイスで低い取引手数料で暗号通貨を取引することができます。さらに、招待したすべてのユーザーの取引手数料の最大20%を共有できます。",
		"Institutional-grade_Security": "機関級セキュリティ",
		"content3": "{{this.$LogoNames.toUpperCase()}}は業界で最高水準のセキュリティ基準の1つを採用しています。また、お客様の暗号通貨を100％のコールドストレージで保管しています。顧客資産の1：1以上の準備金比率を保証しており、3億USDTの基金により、セキュリティ脅威に対する追加の保護を提供しています。",
		"Start_investing_in_as_little_as_5_minutes": "5分で投資を開始",
		"content4": "{{this.$LogoNames.toUpperCase()}}に無料でサインアップ",
		"content4_1": "無料で",
		"content4_2": "にサインアップ",
		"Complete_google_authentication": "Google認証を完了する",
		"content5": "メールアドレスを入力して確認し、始めましょう。口座開設には手数料はかかりません。",
		"content7": "5分以内にGoogle認証を確認して、完全な機能を解除します。",
		"content8": "{{this.$LogoNames.toUpperCase()}}アカウントにすぐに資金を投入してください",
		"content8_1": "即時に",
		"content8_2": "アカウントに資金を投入してください",
		"content9": "セレクトネットワークを介してUSDTを入金し、即時に資金にアクセスできます。トレードを開始するために3-5日間待つ必要はありません。",
		"Start_building_your_crypto_portfolio": "暗号資産ポートフォリオの構築を開始する",
		"Invitation_Program": "招待プログラム",
		"Referral_Program": "紹介プログラム",
		"content10": "有効な招待を完了すると、招待ボーナス平均値の10 USDTが付与されます。個人ごとの最大ボーナスは、",
		"content11": "招待者は、招待者が生成した取引量に比例したボーナスを取得できます。有効な招待の最大ボーナスは、Unlimit USDTです。",
		"TRADING_TASKS": "トレーディングタスク",
		"content12": "ユーザーは、取引時にUSDTの報酬を受け取ります。異なる取引モードに対する多様な取引タスク。報酬は即時に分配されます",
		"Trade_anytime_anywhere": "いつでも、どこでもトレード",
		"content13": "米国社USA, Inc.は、ニューヨーク州金融サービス局によって仮想通貨事業活動に従事するためのライセンスを取得しています。",
		"USA_Inc": "USA, Inc",
		"content6": "アプリをダウンロードして24時間365日接続しましょう。",
		"Legal&Disclosures": "法律および開示",
		"Regulatory_License": "規制ライセンス",
		"AML/KYC_Policies": "AML/KYC ポリシー",
		"Privacy_Policy": "プライバシーポリシー",
		"Terms_of_Use": "利用規約",
		"Legal_Statement": "法的声明",
		"Risk_Disclosure": "リスク開示",
		"About_Us": "会社概要",
		"Protection_Fund": "保護基金",
		"Proof_of_Reserves": "準備証明",
		"Twitter": "Twitter",
		"Bitget_Token": "Bitget トークン",
		"Careers": "キャリア採用情報",
		"Company": "企業情報",
		"Support_Service": "サポートサービス",
		"title": "アプリで開く",
		"titlebtn": "ダウンロード",
		"Balance": "残高",
		"ID": "ID",
		"Recharge": "チャージ",
		"Withdraw": "引き出し",
		"Bonus": "ボーナス",
		"Checkin": "チェックイン",
		"Eventclosed": "イベント終了",
		"Ranking": "ランキング"
	},
	"Ranking": {
		"Today_s winning ranking": "今日の勝者ランキング",
		"tips1": "勝利金額が大きいほど、ランキングが上位になります！",
		"UserName": "ユーザー名",
		"Winning Amount": "勝利金額",
		"Today_s rebate ranking": "今日のリベートランキング",
		"tips2": "低レベルのユーザーほど高いリベート=より高いランキング！",
		"Rebate Amount": "リベート金額",
		"Rebate ranking": "トップ20リベートランキング"
	},
	"Checkin": {
		"Checkin": "チェックイン",
		"CheckinList": "チェックインリスト"
	},
	"AndarBahar": {
		"title": "アンダール・バハール",
		"Rule": "ルール",
		"Record": "記録",
		"Records": "アンダール・バハールの記録",
		"MyOrder": "マイオーダー",
		"more": "もっと見る",
		"Period": "期間",
		"Order": "オーダー",
		"Buy": "購入",
		"Result": "結果",
		"Profit": "利益",
		"AndarBaharRule": "アンダール・バハールのルール",
		"CountDown": "カウントダウン",
		"Andar": "アンダール",
		"Bahar": "バハール",
		"Tie": "引き分け",
		"result": "結果",
		"ok": "OK",
		"start": "スタート",
		"Point": "ポイント",
		"ContractPoint": "契約ポイント",
		"Number": "番号",
		"Delivery": "配信",
		"Fee": "手数料",
		"Amount": "金額"
	},
	"pokerOrder": {
		"OrderRecord": "注文履歴",
		"WatingOrder": "注文待ち",
		"HistoryOrder": "過去の注文",
		"Nomoredata": "データがありません",
		"Buy": "買い注文",
		"Result": "結果",
		"Time": "時間",
		"Amount": "金額",
		"Fee": "手数料",
		"Profit": "利益"
	},
	"pokerRecord": {
		"title": "アンダーバハールの記録",
		"Period": "期間",
		"Result": "結果",
		"OpenTime": "開始時間",
		"Nomore": "これ以上のデータはありません"
	},
	"Google2FA": {
		"title": "Google認証システム",
		"Authenticator_Instructions": "認証システムの使用方法",
		"Authenticator_Status": "認証システムの状態",
		"Google_Authenticator": "Google 認証システム",
		"Download_Google_Authenticator_App": "Google 認証システムアプリをダウンロード",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "次へ",
		"Backup_key": "バックアップキー",
		"Backup_key_text": "このバックアップキーを安全な場所に保存してください。このキーを使用することで、認証システムを復元することができます。もしスマートフォンを紛失した場合は、カスタマーサポートに問い合わせて、認証システムをリセットする必要があります。",
		"Verifiaction": "認証",
		"Verifiaction_text": "Google認証システムの認証コードを入力してください。",
		"Google_verification_code": "Google認証システムの認証コード",
		"contentlist1": "1. +this.$LogoName+ から16桁のバックアップキーを控え、安全な場所に保管してください。",
		"contentlist1_1": "1. +this.$LogoName+ から16桁のバックアップキーを控え、",
		"contentlist1_2": "安全な場所に保管してください。",
		"contentlist2": "2. Google認証システムを開いてください。",
		"contentlist3": "3. +this.$LogoName+ アカウントを追加し、16桁のキーを入力してください。",
		"contentlist3_1": "3. +this.$LogoName+ アカウントを追加し、16桁のキーを入力してください。",
		"contentlist4": "4. 画面に6桁の認証コードが表示されます。",
		"contentlist5": "5. 6桁のコードをコピーし、+this.$LogoName+ に貼り付けてください。",
		"contentlist5_1": "5. 6桁のコードをコピーし、",
		"contentlist5_2": "に貼り付けてください。",
		"contentlist6": "6. これで、認証システムと+this.$LogoName+が正常にリンクされました。"
	},
	"win": {
		"Live_Price": "ライブ価格",
		"title1": "Binance BTC/USDT スポット取引の最終数字によって投資結果が計算されます。投資家はBinanceでいつでも結果を確認して、投資の公正性とセキュリティを確保できます。",
		"title": "投資には2つのモードがあります",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "1回あたり1分かかります。ライトモードでは奇数と偶数の投資しか提供されません。結果は、Binance BTC/USDTマージン取引1M間隔の終値の最後の数字で計算されます。",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "各ラウンドは5分間かかります。プロモードはEPOCH EPTの全投資方法を開放します。結果は、Binance BTC/USDTマージン取引5分間のクローズ結果の最後の数字で計算されます。",
		"5M_Mode_text_1": "各ラウンドは5分間かかります。プロモードは",
		"5M_Mode_text_2": " の全投資方法を開放します。結果は、Binance BTC/USDTマージン取引5分間のクローズ結果の最後の数字で計算されます。",
		"Investment_Guide": "投資ガイド",
		"Investment_Rules": "投資規則",
		"title2": "結果が公正であることの証明方法",
		"Investment_Info": "投資情報",
		"3M_Mode": "3Mモード",
		"3M_Mode_text": "各ラウンドは3分かかります。結果は、Binance BTC/USDTマージン取引の3M間隔のクローズ結果の最後の数字で計算されます。",
		"Investment_return": "投資リターン",
		"Number": "数字",
		"Profit_Invested_amount": "利益：投資額",
		"guide1": "時間モードを選択してください。",
		"guide2": "投資額を調整してください。",
		"guide3": "投資したい数字を選択してください。",
		"guide4": "ポートフォリオを確認し、投資をクリックしてください。",
		"NEXT": "次へ",
		"Invest_Amount": "投資額",
		"Total": "合計",
		"INVEST": "投資",
		"Portfolio": "ポートフォリオ",
		"Invest": "投資",
		"BINANCE": "BINANCE",
		"Bitcoin_Price": "ビットコイン価格",
		"InvestmentRecord": "投資記録",
		"Best_Assistant": "投資アシスタント",
		"last_100_rounds": "（最後の100ラウンド）",
		"What_do_these_data_mean": "これらのデータは何を意味するのか",
		"Missing": "欠落している",
		"Missing_text": "それは、この数字を生成した最後のラウンド以降のラウンド数を表します。",
		"Frequency": "頻度",
		"Frequency_text": "最新の100ラウンドにおいて、対応する数字が出現した回数を指します。",
		"Max_Continued": "最大連続",
		"Max_Continued_text": "最新の100ラウンドにおいて、対応する数字の最大連続出現期間を指します。",
		"Rule": "ルール",
		"Trend": "トレンド",
		"MyBalance": "私のバランス",
		"JoinGreen": "グリーンに参加",
		"JoinRed": "レッドに参加",
		"JoinViolet": "バイオレットに参加",
		"Parity": "偶数",
		"Sapre": "サプライ",
		"Bcone": "ビーコン",
		"Emerd": "エメラルド",
		"Record": "記録",
		"more": "もっと見る",
		"Period": "期間",
		"Price": "価格",
		"Result": "結果",
		"My": "私の",
		"Order": "注文",
		"Buy": "買う",
		"Profit": "利益",
		"Green": "グリーン",
		"Red": "レッド",
		"Violet": "バイオレット",
		"tips": "手数料は注文金額の{bet_fee*100}%です",
		"tips1": "手数料は",
		"tips2": "注文金額の",
		"OrderAmount": "注文金額",
		"Quantity": "数量",
		"TransactionFee": "取引手数料",
		"TotalAmount": "合計金額",
		"PresalePolicy": "プライバシーポリシー",
		"Iagree": "同意する",
		"err1": "まず、販売前管理規則に同意してください。"
	},
	"historyPeriods": {
		"Orderrecord": "受注実績",
		"record": "記録"
	},
	"historyOrderPeriods": {
		"WatingOrder": "待機順",
		"HistoryOrder": "履歴順"
	},
	"Activity": {
		"added_to_account_balance": "アカウント残高に追加されました。",
		"contentText": "招待者は、招待者が有効な招待ごとに招待ボーナスを受け取ります。1回の有効な招待に対して{{aaaaaa}}USDTから{{aaaaaa}}USDTまでの招待ボーナスティアがあります。",
		"contentText_1": "招待者が有効な招待ごとに招待ボーナスを受け取るには、招待者が初回入金を完了する必要があります。",
		"contentText_2": "1回の有効な招待に対して",
		"contentText_3": " 500回の有効な招待まで、招待ボーナスの段階があります。",
		"contentText_3_1": "有効な招待に対して",
		"contentText_3_2": "回",
		"contentText2": "招待者は、各LV.1招待者の取引量から{{LV.1％}}の取引手数料を分け合います。招待者は、各LV.2招待者の取引量から{{LV.2％}}の取引手数料を分け合います。招待者の取引量ボーナスの最大ボーナスは無制限のUSDTです。",
		"contentText2_1": "招待者は、",
		"contentText2_2": "各LV.1招待者の取引量から取引手数料を分け合います。招待者は、",
		"contentText2_3": "各LV.2招待者の取引量から{{LV.2％}}の取引手数料を分け合います。招待者の取引量ボーナスの最大ボーナスは無制限のUSDTです。",
		"My_Rewards_History": "リワード履歴",
		"Cumulative_Single_Number_Trading_Volumn": "累計シングル数字の取引量",
		"Cumulative_ODD_Number_Trading_Volumn": "累計奇数数字の取引量",
		"Cumulative_EVEN_Number_Trading_Volumn": "累計偶数数字の取引量",
		"Cumulative_BIG_Number_Trading_Volumn": "累計大数字の取引量",
		"Cumulative_SMALL_Number_Trading_Volumn": "累計小数字の取引量",
		"Advanced_Tasks": "高度なタスク",
		"Invite_Friends_and_Earn_Up_to": "友達を招待して最大",
		"Referral_Code": "紹介コード",
		"Referral_Link": "紹介リンク",
		"INVITE_FRIENDS": "友達を招待",
		"Complete_tasks_to_earn_up_to": "最大報酬を獲得するにはタスクを完了してください",
		"MY_REWARDS": "私の報酬",
		"SHARE": "共有する",
		"Earn_with_your_friends_together": "友達と一緒に稼ぐ",
		"Get_your_link": "リンクを取得する",
		"Claim_your_rewards": "報酬を請求する",
		"Earn_together": "一緒に稼ぐ",
		"contentlist0": "{{gogobit}}に参加して紹介リンクを取得してください。このリンクから登録したユーザーは報酬を受け取ることができます。",
		"contentlist0_1": "参加する ",
		"contentlist0_2": " そして紹介リンクを取得する。このリンクから登録したユーザーは報酬を受け取ることができます。",
		"contentlist1": "あなたは友人が最初の入金をし、必要な取引高を達成した後、{{XX}} USDTの平均紹介報酬を受け取ることができます。報酬は最大で{{XX}} USDTまで可能です。",
		"contentlist1_1": "あなたは友人が最初の入金をし、必要な取引高を達成した後、平均{{XX}} USDTの紹介報酬を受け取ることができます。",
		"contentlist1_2": " USDTの紹介報酬を受け取ることができます。あなたの友人が最初の入金をし、必要な取引高を達成した後、報酬は最大で ",
		"contentlist1_3": " USDTまで可能です。",
		"contentlist2": "友達がアカウントを作成すると、最大5,050USDTの取引ボーナスを受け取ることができます。",
		"Referral_Rewards": "紹介報酬",
		"Detail_Rules": "詳細ルール",
		"Invitation_Bonus": "招待ボーナス",
		"Up_to": "最大",
		"content0": "有効な招待を完了すると、平均値が10USDTの招待ボーナスが支払われます。単一のユーザーの最大ボーナスは、",
		"content0_1": "有効な招待を完了すると、平均値が",
		"content0_2": "単一のユーザーの最大ボーナスは、",
		"Trading_Bonus": "取引ボーナス",
		"content1": "招待者は、招待されたユーザーが生成した取引量に比例したボーナスを受け取ることができます。有効な招待に対する最大ボーナスは、",
		"My_Referral_History": "私の紹介履歴",
		"Total_Invitees": "総招待数",
		"Valid_Invitees": "有効な招待",
		"Cumulative_Invitees": "累積招待数",
		"Trading_Volumn": "取引量",
		"Daily_Tasks": "日課タスク",
		"Detail_Rules_title": "888USDTのデイリーラッキードロー",
		"Detail_Rules_title1": "1.当日の先物取引データは、翌日に計算されます。",
		"Detail_Rules_title2": "2.当日200,000USDT以上の先物取引量を行ったユーザーは、888USDTの賞金を引き換えるチャンスを1回獲得することができます。",
		"CLAIM_NOW": "今すぐ受け取る",
		"INVEST_NOW": "今すぐ投資する",
		"title": "ボーナスウォレット",
		"BonusBalance": "ボーナス残高",
		"Withdrawtobalance": "残高への出金",
		"BonusList": "ボーナスリスト",
		"Bonuswithdrawtobalance": "ボーナスを残高に出金する",
		"WithdrawalAmount": "出金額",
		"Pleaseentertheamount": "金額を入力してください",
		"err1": "出金額は0にできません",
		"success": "振替が成功しました"
	},
	"Refer": {
		"title": "私の紹介",
		"ReferTotalBonus": "紹介ボーナスの合計",
		"InviteFriends": "友達を招待する",
		"Level1": "レベル1",
		"Level2": "レベル2",
		"TotalPeople": "総人数",
		"Bonus": "ボーナス",
		"Myinvitationlink": "私の紹介リンク",
		"Rebate_description": "リベート説明"
	},
	"Task": {
		"InviteBonusToday": "本日の紹介ボーナス",
		"bonusrecord": "ボーナス記録",
		"hint1": "1. データは10〜30分ごとに更新されます!",
		"hint2": "2. 最後の3日間に登録し、今日の入金が完了した人のみがカウントされます!",
		"hint2_1": "2. 最後の",
		"hint2_2": "日間に登録し、今日の入金が完了した人のみがカウントされます!",
		"hint3": "3. 今日は最低20uの入金が必要です!",
		"hint3_1": "3. 今日は最低",
		"hint3_2": "uの入金が必要です!",
		"hint4": "過去3日間に招待された人数",
		"hint4_1": "過去",
		"hint4_2": "日間に招待された人数",
		"hint5": "今日入金が完了した人数",
		"Invite": "招待",
		"friendstorecharge": "人の友達が入金を完了しました",
		"Earn": "獲得",
		"Completed": "完了",
		"ExpireTime": "有効期限"
	},
	"Invited": {
		"title": "ボーナス記録",
		"InviteTotalBonus": "招待ボーナス合計額",
		"InviteTime": "招待時間",
		"Bonus": "ボーナス",
		"NoRecored": "記録がありません"
	},
	"mine": {
		"ProvacyPolicy": "プライバシーポリシー",
		"JOIN US": "参加する",
		"title": "個人センター",
		"InviteCode": "招待コード",
		"AccountBalance": "アカウント残高",
		"BonusBalance": "ボーナス残高",
		"Wallet": "ウォレット",
		"Bank": "銀行",
		"Invite": "招待",
		"Refer": "参照",
		"CustomerService": "カスタマーサービス",
		"ComplaintsSuggestions": "クレーム&提案",
		"ChangePassword": "パスワードを変更する",
		"Activity": "アクティビティ",
		"PresalePolicy": "プライバシーポリシー",
		"DownloadApp": "アプリをダウンロード",
		"AppVersion": "アプリバージョン",
		"Logout": "ログアウト",
		"Logouttips": "ログアウトしてもよろしいですか？"
	},
	"App": {
		"title": "アプリダウンロード",
		"DownloadforAndroid": "Android用ダウンロード",
		"DownloadforiOS": "iOS用ダウンロード"
	},
	"Presale": {
		"title": "プライバシーポリシー"
	},
	"Account": {
		"title2": "新しいパスワードを設定する",
		"title2_text": "Googleアカウントの新しいパスワードを設定してください。パスワードを使用してGoogleアカウントにログインできます。",
		"title_text": "パスワードをリセットしようとしています。Google認証システムがリセットの準備ができていることを確認してください。",
		"err1": "新しいパスワードが一致しません",
		"success2": "パスワードが正常に変更されました",
		"title": "パスワード変更",
		"OldPassword": "旧パスワード",
		"OldPasswordmsg": "旧パスワードは必須です",
		"NewPassword": "新しいパスワード",
		"NewPasswordmsg": "新しいパスワードは必須です",
		"RetypeNewPassword": "新しいパスワード再入力",
		"RetypeNewPasswordmsg": "新しいパスワード再入力は必須です",
		"Continue": "続ける"
	},
	"Service": {
		"title": "サービス",
		"CONTACTUS": "お問い合わせ",
		"tips": "現在、カスタマーサービスは休憩中です！",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "リンク"
	},
	"Bank": {
		"BankName": "銀行名",
		"ChooseaCurrencyType": "通貨タイプを選択してください",
		"SelectWithdrawalMethod": "出金方法を選択してください",
		"Newbankcard": "新しい銀行カード",
		"Editbankcard": "銀行カードを編集する",
		"title": "私の銀行",
		"Cannotbeempty": "空にできません",
		"BankHolderName": "銀行口座名義人の名前",
		"BankAccountNumber": "銀行口座番号",
		"IFSCCode": "IFSCコード",
		"Email": "メールアドレス",
		"Phone": "電話番号",
		"OTPCode": "OTPコード",
		"tips1": "1. OTPを受け取るには、登録した携帯電話番号を使用してください",
		"tips2": "2. 資金の安全のため、銀行カードは1日に1回しか変更できません",
		"Add": "追加する",
		"error1": "銀行口座名義人の名前は空にできません！",
		"error2": "正しい銀行口座名義人の名前を入力してください！",
		"error3": "銀行名は空にできません！",
		"error4": "正しい銀行名を入力してください！",
		"error5": "銀行口座番号は空にできません！",
		"error6": "正しい銀行口座番号を入力してください！",
		"error7": "IFSCコードは空にできません！",
		"error8": "正しいIFSCコードを入力してください！",
		"error9": "メールアドレスは空にできません！",
		"error10": "正しいメールアドレスを入力してください！",
		"error11": "電話番号は空にできません！",
		"error12": "OTPコードは空にできません！",
		"Successfully": "銀行カードを追加しました！"
	},
	"wallet": {
		"title": "私のウォレット",
		"MyBalance": "残高",
		"MyBank": "銀行",
		"RechargeRecords": "チャージ履歴",
		"WithdrawalRecords": "出金履歴"
	},
	"Rechargepage": {
		"Reminder": "リマインダー",
		"Reminder_1": "1. このアドレスにUSDT以外のコインやトークンを送信しないでください。サポートされていないコインやトークンを送信すると、取り返しのつかない損失が生じる可能性があります。",
		"Reminder_2": "2. 最低入金額は5USDTです。",
		"Reminder_2_1": "2. 最低入金額は",
		"Reminder_2_2": "USDTです。",
		"Reminder_3": "3. 入金は15回のネットワーク確認後に到着します。",
		"Minimum": "最低",
		"title": "入金",
		"History": "入金履歴",
		"Mybalance": "残高",
		"RechargeAmount": "入金額",
		"RechargeAmountmsg": "金額を入力してください",
		"tips1": "支払いチャンネルはメンテナンス中です！",
		"tips2": "支払いチャンネルを選択してください",
		"PayNow": "今すぐ支払う",
		"err1": "支払いチャンネルはメンテナンス中です",
		"err2": "金額は ",
		"err3": "より小さくできません。支払いチャンネルを選択してください",
		"Rechargemethod": "入金方法"
	},
	"Rechargerecord": {
		"title": "入金記録",
		"WatingOrder": "処理中注文",
		"HistoryOrder": "過去の注文",
		"Rupee": "ドル",
		"Status": "ステータス",
		"Time": "時間"
	},
	"withdrawal": {
		"Tips": "ヒント",
		"Tips_1": "ヒント: ",
		"Tips_2": " USDT 以下の出金手数料は ",
		"Tips_3": " USDT、",
		"Tips_4": " USDT を超える出金手数料は ",
		"Tips_5": "% です。",
		"WalletAddress": "ウォレットアドレス",
		"Addwalletaddress": "ウォレットアドレスを追加",
		"Network_fee": "ネットワーク手数料",
		"Receive_amount": "受取額",
		"Select Chains": "チェーンを選択する",
		"Bankcard": "銀行カード",
		"Nobankcardselected": "追加された銀行カードはありません",
		"Withdrawalfee": "引き出し手数料",
		"title": "引き出し",
		"Mybalance": "私の残高",
		"Withdrawablebalance": "引き出し可能残高",
		"tips1": "合計購入金額は{{codeAmount}}より多く、全額を引き出すことができます。",
		"tips1-1": "合計購入金額は",
		"tips1-2": "より多く、全額を引き出すことができます。",
		"tips2": "1日あたりの引き出し回数は{{draw_num}}回です",
		"tips2-1": "1日あたりの引き出し回数は",
		"tips2-2": "回です",
		"tips3": "1日あたりの最大引き出し額は",
		"tips4": "1回の引き出しの最小額は₹{{draw_min_money}}で、最大額は₹{{draw_max_money}}です",
		"tips4-1": "1回の引き出しの最小額は",
		"tips4-2": "で、最大額は",
		"tips5": "引き出し時間は{{draw_time}}であり、指定の時間に引き出してください",
		"tips5-1": "引き出し時間は",
		"tips5-2": "であり、指定の時間に引き出してください",
		"tips6": "キャッシュ引き出しには、引き出し手数料の{{draw_fee}}%が掛かります。",
		"tips6-1": "キャッシュ引き出しには、引き出し手数料の",
		"tips6-2": "%が掛かります。",
		"tips7": "出金額が ₹{{solid_min_amount}}以下の場合、固定手数料 ₹{{solid_feemoney}} がかかります。",
		"tips7-1": "出金額が",
		"tips7-2": "以下の場合、固定手数料",
		"tips7-3": "がかかります。",
		"WithdrawalAmount": "出金額",
		"WithdrawalAmountmsg": "金額を入力してください。",
		"Withdrawal": "出金",
		"WithdrawalHistory": "出金履歴",
		"Tipsmsg": "出金申請が成功しました。審査結果を待ってください。",
		"dangerMsg1": "銀行カードを追加してください",
		"dangerMsg2": "出金額は ₹{{draw_min_money}} 以下にできません。",
		"dangerMsg3": "出金額は100の倍数でなければなりません。",
		"dangerMsg4": "出金額は ₹{{draw_max_money}} 以上にできません。",
		"dangerMsg5": "出金額は出金可能残高を超えることはできません",
		"dangerMsg6": "まず銀行口座を追加してください"
	},
	"withdrawalrecord": {
		"title": "出金履歴",
		"WatingOrder": "処理中の注文",
		"HistoryOrder": "過去の注文",
		"Rupee": "ドル",
		"Status": "ステータス",
		"Time": "時間",
		"Nomore": "これ以上ありません！"
	},
	"Version": {
		"title": "バージョン",
		"stableversion": "安定版",
		"Alreadythelatestversion": "すでに最新版です",
		"Newversionfound": "新しいバージョンが見つかりました",
		"DownloadUpdate": "アップデートをダウンロード"
	},
	"Bonus": {
		"title": "ボーナスアクティビティ",
		"ReceiveBonus": "ボーナスを受け取る",
		"Hi": "こんにちは",
		"tips1": "おめでとうございます、ボーナスを獲得しました",
		"tips2": "以下の金額以上をチャージする必要があります",
		"tips3": "その後、ボーナスは自動的にアカウント残高に追加されます",
		"Received": "受領",
		"Back": "戻る",
		"Rechargenow": "今すぐチャージ"
	},
	"ComplaintsSuggestions": {
		"title": "苦情と提案",
		"Answered": "回答済み",
		"AddNew": "新規追加",
		"Time": "時間",
		"Type": "種類",
		"WhatsApp": "WhatsApp",
		"Description": "内容",
		"Remark": "備考",
		"Waiting": "回答待ち",
		"Suggestion": "提案",
		"Consult": "相談",
		"RechargeProblem": "チャージ問題",
		"WithdrawProblem": "出金問題",
		"OrderProblem": "注文問題",
		"Other": "その他",
		"Describeyourproblems": "問題を説明してください",
		"WhatsApptocontactyou": "連絡先のWhatsApp",
		"submit": "送信",
		"err1": "WhatsAppを入力してください！",
		"err2": "種類を選択してください！",
		"err3": "内容を入力してください！"
	},
	"slotMachine": {
		"title": "フルーツマシン",
		"Myblance": "私の残高",
		"Records": "フルーツマシンの記録",
		"titleRecord": "記録"
	},
	"bankrecharge": {
		"Orderamount": "注文金額",
		"Ordernumber": "注文番号",
		"BankName": "銀行名",
		"BankAccount": "口座番号",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "あなたの銀行カード番号",
		"Enterthebanktransactionordernumber": "銀行取引注文番号を入力してください",
		"submit": "提出する",
		"Tips": "ヒント",
		"tips1": "1.システムはあなたの注文を処理しています。",
		"tips2": "2.アカウントが長時間到着しない場合は、カスタマーサービスに連絡してください。",
		"err1": "あなたの銀行カード番号を入力してください",
		"err2": "銀行注文番号を入力してください"
	},
	"javaCode": {
		"200": "成功",
		"20001": "無効なアクセストークン",
		"20002": "無効なログイントークン",
		"20003": "電話番号エラー",
		"20004": "電話番号は既に存在しています",
		"20005": "メールアドレスエラー",
		"20006": "メールアドレスは既に存在しています",
		"20007": "パスワードエラー",
		"20010": "アカウントまたはパスワードがエラーです",
		"20011": "銀行口座番号がエラーです",
		"20012": "銀行のIFSCがエラーです（標準IFSCフォーマット）-長さ11、最初の4つのIFSCと5番目の0",
		"20013": "受取人名がエラーです、5<name<=50！",
		"20021": "アカウントが存在しません",
		"20022": "アカウントが凍結されています",
		"20031": "層は10を超えることはできません！",
		"20032": "関連する役割を更新できません！",
		"20033": "残高が不足しています！",
		"20034": "不正な引き出し金額です！",
		"30001": "一時的にサービスが停止しています！",
		"30002": "賭け金は10から50000の間で制限されています！",
		"30003": "現在の発行物は終了しました！",
		"30005": "チェックイン済み",
		"31001": "単一トランザクションが制限を超えています！",
		"31002": "今日の総額が上限に達しました！",
		"31003": "今日の回数が制限に達しました！",
		"31004": "現在のスタッフが未設定のため、管理者またはエージェントに連絡してください！",
		"200081": "登録エラー",
		"200082": "アカウントが無効になっています",
		"200091": "カードは10枚までしか追加できません",
		"200092": "ログインパスワードの入力エラーが多すぎるため、アカウントが無効になっています",
		"300041": "問題のすべての取引金額に制限があります！",
		"300042": "ユーザーのすべての取引金額に制限があります！",
		"300043": "ベットアイテムエラー！",
		"300044": "既に購入済みで、再度購入することはできません！",
		"Servererror": "サーバーエラー！",
		"Handledataerror": "ログインしてください！",
		"-91": "サーバーエラー！",
		"-92": "データ処理エラー！",
		"-1": "システムエラー！",
		"-2": "パラメーターエラー",
		"-3": "データがありません",
		"-4": "短時間での頻繁な操作",
		"-5": "システムがビジー状態です。後でもう一度お試しください！",
		"-6": "現在多くの人がいます。もう一度お試しください！",
		"-7": "データエラー！",
		"-8": "Googleコードエラー！",
		"-9": "システムがメンテナンス中です！後でもう一度お試しください！",
		"-10": "保存エラー！",
		"-11": "削除エラー！",
		"-12": "更新エラー！",
		"-13": "不正な操作です！",
		"-14": "操作に失敗しました！",
		"-15": "操作は無効になっています！",
		"-16": "操作が完了しました！",
		"-17": "エラーが発生しました",
		"-18": "存在しません！",
		"-19": "期限切れ",
		"-20": "無効になっています",
		"-21": "認証コードエラー",
		"-22": "修正間隔は1日以上でなければなりません",
		"-23": "新しいパスワードは元のパスワードと同じにできません",
		"-100": "カスタムエラー！",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Total Purchase Amount",
		"TotalRevenue": "Total Revenue",
		"AccountBalance": "Account Balance",
		"Income": "Income",
		"Transaction": "Transaction",
		"Myhold": "My hold",
		"ProductList": "Product List",
		"Investmentperiod": "Investment period",
		"days": "days",
		"Buynow": "Buy now",
		"Saletime": "Sale time",
		"Nomore": "No more",
		"ProductDetails": "Product Details",
		"ForSale": "For Sale",
		"Sale": "Sale",
		"SoldOut": "Sold Out",
		"ProductInformation": "Product Information",
		"InvestmentPeriod": "Investment Period",
		"TotalShare": "Total Share",
		"ExpectedReturn": "Expected Return",
		"SaleTime": "SaleTime",
		"PurchaseConditionsAndRules": "Purchase Conditions And Rules",
		"MinimumPurchaseAmount": "Minimum Purchase Amount",
		"MaximumSalesLimit": "Maximum Sales Limit",
		"AccountBalanceMustBeGreaterThan": "Account Balance Must Be Greater Than",
		"DayBetIsGreaterThan": "Day Bet Is Greater Than",
		"ProductDescription": "Product Description",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"Maturity": "Maturity",
		"EarlyRedemption": "Early Redemption",
		"Redemptiontime": "Redemption time",
		"Amountofincome": "Amount of income",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "Help",
		"HelpCenter": "Help Center",
		"text": "any questions,look here"
	},
	"SetingHa": {
		"Seting": "Setting",
		"SetingCenter": "Setting Center",
		"text": "Get More Rewards"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"register.checkerr": "パスワード：8桁以上の文字（文字と数字の組み合わせ）を使用する",
	"home.Products&Services": "製品とサービス",
	"Google2FA.contentlist3_2": "アカウントを開き、コピーした16ビットのデジタルキーを入力します。",
	"Rechargepage.Tips": "お知らせ：初回チャージ奨励金はチャージ金額の{{firstRPR*100}%",
	"Rechargepage.Tips_1": "お知らせ：初回チャージ奨励金がチャージ金額の",
	"Rechargepage.Tips_2": "% .",
	"withdrawal.Google_Code": "Google認証コード",
	"index.index.gayb21": "登録取得奨励金：",
	"index.index.72y628": "3つの選択理由EPOCH EPT",
	"index.index.z6022y": "EPOCH EPTは業界最高のセキュリティ基準の1つに準拠しています。私たちは100%の暗号化通貨を冷蔵庫に保管しています。私たちは少なくとも顧客資金に1：1の備蓄比率を提供することを保証します。デルの3億ドル基金は、セキュリティ脅威に追加的な保護を提供しています。",
	"index.index.574982": "1.EPOCH EPTへの無料登録",
	"index.index.qdi1g4": "2.グーグル検証の完了",
	"index.index.weko28": "3.EPOCH EPTアカウントの即時チャージ",
	"index.index.sgkb35": "EPOCH EPT招待プログラム",
	"index.index.2u17nf": "有効な招待を完了すると、平均価値が5-15 USDTに達することができます。招待された100人の最高賞金は",
	"index.index.e8is4a": "EPOCH EPT推奨プラン",
	"index.index.47uevl": "EPOCH EPT取引タスク",
	"index.index.2516gq": "EPOCH EPT,Inc.は、ニューヨーク州の金融サービス部門から仮想通貨ビジネス活動のライセンスを取得しています。",
	"invest.index.3aqi3d": "勘定残高：",
	"invest.index.ubk1j4": "投資を止めろ！",
	"invest.prize1.4733u6": "ポートフォリオ：",
	"invest.prize1.t55a16": "合計:",
	"login.login.09m52q": "EPOCH EPTを読んで同意しました",
	"login.login.045csy": "ユーザー名",
	"login.login.r7rbm5": "合意してください！",
	"rewards.activityb.4nvrq9": "招待者が最初の預金を完了した場合、有効な招待とみなされます。招待者には、有効な招待ごとに招待ボーナスが支給されます。招待賞金等級は2 USDT 1回有効招待559 USDT 100から有効招待された。",
	"rewards.activityb.t10k6e": "招待者は、招待者ごとにLV.1被招待者の取引手数料の20%の取引手数料を共有します。招待者は、招待者ごとにLV.2被招待者の取引手数料の10%の取引手数料を共有します。招待者の取引手数料奨励金の最大ボーナスは無制限ドル。",
	"rewards.activityb.3d6850": "LV.1有効招待者数",
	"rewards.activityb.lvxo74": "LV.2有効招待者数",
	"rewards.activityb.zj26f9": "LV.1招待者数の累計",
	"rewards.activityb.dkgxr5": "LV.2招待者数の累計",
	"rewards.activityb.xjo077": "推奨奨励金：",
	"rewards.activityb.k8wv6v": "EPOCH EPTを追加して、推奨される連結を取得します。この連結を通じて登録されたユーザーには報酬が与えられます。",
	"rewards.activityb.4slpzg": "友人が最初の預金を完了し、必要な取引量を完了すると、平均5-15ドルの推奨奨励金が得られます。奨励金は最高559ドルに達する。",
	"rewards.index.993jwg": "コピー成功",
	"rewards.index.7141ft": "このブラウザは自動コピーをサポートしていません",
	"wallet.index.9uz2gn": "ネットワーク手数料0 USDT",
	"wallet.index.ych3e1": "実際の入金：",
	"wallet.index.3v558d": "ヒント：引き出しは20ドルより低く、引き出し手数料は1ドル、引き出しは20ドル以上、引き出し手数料は3%である。",
	"wallet.index.255e30": "1.1日あたりの最大引き出し金額は$800,000,000",
	"wallet.index.3g68m8": "2.最低シングル引き出し金額は$5、最高シングル引き出し金額は$800,000,000",
	"wallet.index.c6x3ti": "3.引き出し時間は00：00-23：59で、指定の時間内に引き出してください",
	"wallet.index.h94rlg": "最低限度額5",
	"wallet.index.167862": "財布の住所を入力してください！",
	"wallet.index.pi869h": "引き出し金額を入力してください！",
	"wallet.index.ysa85i": "最大1回の引き出し金額は",
	"components.changepass.68j9kh": "パスワードをリセットしようとしています。",
	"components.changepass.ouxtp2": "元のパスワード",
	"components.languagebox.8no849": "日本語",
	"components.languagebox.oif20b": "繁体字中国語",
	"components.mycode.xyqr66": "タスクを完了すると最大5000 USDTを取得できます",
	"components.mymenu.277ss7": "ユーザID:",
	"components.mymenu.74l443": "登録時間：",
	"components.myposter.36rw5e": "推奨コード：",
	"components.mytabbar.zw2bh2": "トップページ",
	"components.mytabbar.8x6c28": "傾向",
	"components.mytabbar.28s7qr": "投資",
	"components.mytabbar.4r75d9": "に報いる",
	"components.xieyi.zqk7pe": "font-size: 16px; font-family：宋体、",
	"components.help.6gpud8": "投資には2つのモデルがあります。",
	"components.help.5qrq36": "：毎期1分間継続し、シングルダブル投資のみを提供する。結果は、Binance BTC/USDT現物取引の1分K線終値の最後の数字で計算された。",
	"components.help.isl7r1": "：毎期5分間続きます。専門版はEPOCH EPTのすべての投資方法をロック解除した。結果はBinance BTC/USDT現物取引5分K線終値の最後の数字で計算された。",
	"components.help.eai3nm": "ROI：",
	"components.help.35xg82": "Single数字：",
	"components.help.mpfg0s": "収益：投資金額X 9.4",
	"components.help.v2ziib": "Odd数字：",
	"components.help.vfzxor": "収益：投資金額X 1.94",
	"components.help.73168b": "Even数字：",
	"components.help.76wgv1": "Big数字：",
	"components.help.05ed33": "Small数字：",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "元のパスワードを入力してください！",
	"components.changepass.xme6my": "新しいパスワードを入力してください！",
	"components.changepass.u434f8": "2回のパスワードが一致しません！",
	"components.changepass.574790": "修正に成功しました！",
	"invest.index.9y8wg0": "入賞を逃した",
	"invest.index.ql5nc1": "賞をもらう",
	"login.login.76451t": "取引所差額A定量取引！",
	"login.login.915p36": "財テク世界一",
	"login.login.sen863": "ハロー上陸後はもっと素晴らしかった",
	"login.login.nz6q9x": "電話ログイン",
	"login.login.w7p592": "メールボックスログイン",
	"login.login.g9y91k": "携帯番号",
	"login.login.456gbb": "メールボックス番号",
	"login.login.5ly8m8": "国",
	"login.login.69h472": "国を選択してください",
	"login.login.i4p378": "番号",
	"login.login.fl82xx": "例：",
	"wallet.index.urkoub": "チャージ2次元コード",
	"wallet.index.25cocl": "アドレスのコピー",
	"wallet.index.t35fvg": "1.引き出し時間：週1〜土曜日の24時間以内に、日曜日に地域税を計算してください！",
	"wallet.index.68xx61": "2.世界の自国通貨の引き出しは遅くとも48時間以内に入金され、引き出し手数料は8%（地域税を含む）。",
	"wallet.index.4ws3w9": "3.USDT引き出しは遅くとも24時間入金、手数料2%（地域税を含む）",
	"wallet.index.9b7wq8": "4.最低引き出し12 USDT、最高一回引き出し金額3000 USDT。",
	"wallet.index.23y92a": "知ってください",
	"wallet.index.pl5473": "最低限度額5",
	"wallet.index.nb3u72": "銀行カード情報",
	"wallet.index.842so4": "銀行カード番号の追加",
	"wallet.index.86j717": "所属銀行の追加",
	"wallet.index.763d7x": "銀行カード名の追加",
	"wallet.index.50j237": "所属銀行",
	"wallet.index.hb6lun": "銀行カード名",
	"wallet.index.62y2jc": "銀行カード番号",
	"login.register.666hp4": "Al差額定量取引",
	"login.register.9298b8": "友達を招待する",
	"login.register.375g14": "新人初加入",
	"login.register.01922f": "1回実行可能",
	"login.register.527o4q": "日化収益AI",
	"login.register.c563gt": "メールボックス登録",
	"login.register.2ls856": "携帯番号のフォーマットが間違っている！",
	"login.register.4nu77e": "メールボックス番号のフォーマットが間違っています！",
	"lang.zh.pu623z": "1回の有効な招待",
	"lang.zh.3hv80b": "USDTのチャージ！",
	"lang.zh.9tli5f": "ヒント：引き出しが低い",
	"lang.zh.62e882": "ドル、引き出し手数料は",
	"lang.zh.m3818q": "ドル、引き出し",
	"lang.zh.xs2f10": "早期償還では何の収益も得られない"
}