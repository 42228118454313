import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cache from 'store2'
import './error'; // 日志

import clipboard from 'clipboard'
import dayjs from 'dayjs'
import axios from './utils/axios'
import base from './utils/base';
import jquery from 'jquery'
Vue.prototype.$ = jquery
Vue.prototype.$base = base

import Vant from 'vant';
import 'vant/lib/index.less';
Vue.use(Vant);

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 引入全局组件
import '@/components'
import langList from "@/config/langList";
if (!Cache.get('lang')) {

  var type = navigator.appName;
  var lang = navigator.userLanguage; //获取浏览器配置语言，支持IE5+ == navigator.systemLanguage
  if (type == "Netscape") {
    lang = navigator.language; //获取浏览器配置语言，支持非IE浏览器
  }
  lang = lang.substr(0, 2); //获取浏览器配置语言前两位

  let flag = false
  langList.map((v) => {
    if (v.language == lang) {
      flag = true
      Cache.set('lang', lang)
      Cache.set('language_name', v.name)
    }
  });
  if (!flag) {
    Cache.set('lang', 'en')
    Cache.set('language_name', "English")
  }

}

//语言包
import i18n from '@/lang/index'
Vue.prototype._i18n = i18n

Vue.prototype.axios = axios
Vue.prototype.Cache = Cache
Vue.prototype.dayjs = dayjs
Vue.prototype.Clipboard = clipboard

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
