<template>
    <div class="home">
        <div
            id="hometopid"
            class="layout"
        >
            <div class="hometop">
                <div class="layouttop">
                  
                    <div
                        class="swipe0"
                        v-if="!token"
                    >
                   
                        <div class="el-dropdown"><span
                                class="el-dropdown-link el-dropdown-selfdefine"
                                aria-haspopup="list"
                                aria-controls="dropdown-menu-8441"
                                role="button"
                                tabindex="0"
                                style="display: block;"
                            >
                                <div class="swipe02_btn1 cursor">
                                    <language-box></language-box>
                                </div>
                            </span>
                        </div>
                        <div
                            class="swipe0_btn1_2 cursor"
                            style="margin-right: 6%;"
                            @click="goRegister"
                        >{{$t('login.Register')}}</div>
                        <div
                            class="swipe0_btn12 cursor"
                            style="margin-right: 2%; text-shadow: rgb(0, 0, 0) 1px 1px 10px;"
                            @click="goLogin"
                        >{{$t('login.Login')}}</div>
                        <div style="height: 1.2rem;"></div>
                        <div
                            class="fnzbox"
                            style="margin-top: 0rem; margin-left: 5%; width: 100%; text-shadow: rgb(0, 0, 0) 1px 1px 10px;"
                        >
                            <div style="font-size: 2.2rem;">{{$t('index.index.gayb21')}}</div>
                            <div style="font-size: 1.8rem; margin-top: -0.2rem;">{{$t('homeTop.up_to')}}<span style="color: rgb(255, 213, 44);">5050</span> USDT!</div>
                        </div>
                    </div>
                    <div
                        class="swipe02"
                        v-if="token"
                    >
                        <div
                            class="swipe02_btn1"
                            style="margin-right: 1.1rem;"
                        ><img
                                @click="showMenu"
                                loading="lazy"
                                src="/img/1508a44h6cc2f400.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div style="margin-top: 2.5rem; margin-left: 6%; font-size: 1rem;">{{$t('homeTop.TOTAL_ASSETS')}}</div>
                        <div style="width: 82%; height: 3.5rem; margin-left: 6%; line-height: 4rem; font-size: 2.7rem; margin-top: 0rem;" @click="getBalanceFun"> ${{userinfo.balance || '0.00'}}USD
                          <span style="width: 30px;"><img src="/img/301a3g4bh522e000.png" class="fzbox22_img" /></span>

                        </div>
                        <div
                            class="fheiti"
                            style="color: rgb(19, 236, 89); margin-left: 7%; font-weight: 100; float: left;"
                        >
                            <!-- -$0.00 (0.00%) -->
                            ≈ {{parseFloat(userinfo.curr_rate*userinfo.balance).toFixed(2)}}PHP
                        </div>
                        <div
                            class="swipe2_text clearfix"
                            style="width: 3rem; line-height: 1rem; float: left; margin-left: 0.2rem;"
                        >
                            <div style="display: block; float: left; text-decoration: underline; width: 5rem; margin-top: 0.1rem;">
                                <div style="float: left; margin-top: -1rem; font-size: 1rem; color: white;">24H</div>
                            </div>
                        </div>
                    </div>
                    <div class="minueShowcss"></div>
                </div>
            </div>
            <!-- <div class="swipe111">
                <van-swipe
                    class="my-swipe"
                    :autoplay="6000"
                    :loop="false"
                    :width="250"
                    :show-indicators="false"
                >
                    <van-swipe-item
                        v-for="(v,i) in banner"
                        :key="i"
                    >
                        <div
                            class="top_banner  cwhite flex"
                            :style="i != banner.length - 1 ? 'width: 94%' : 'width: 100%'"
                        >
                            <div class="top  w100 flex">
                                <img
                                    class="avatar"
                                    :src="v.img"
                                >
                                <div>
                                    <div class="name1 font-14 w-600">{{v.title}}</div>
                                    <div class="desc font-12 m-t-5 line-5">
                                        {{v.url}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            style="width: 6%;"
                            v-if="i != banner.length - 1"
                        ></div>
                    </van-swipe-item>
                </van-swipe>

            </div> -->
            <!-- <div class="swipe1">
                <van-swipe
                    class="my-swipe"
                    :autoplay="6000"
                    indicator-color="white"
                    :width="270"
                    :loop="false"
                    :show-indicators="false"
                >
                    <van-swipe-item
                        v-for="(v,i) in banner"
                        :key="i"
                        @click="goUrl(v)"
                    >
                        <div :style="i != banner.length - 1 ? 'width: 94%' : 'width: 100%'"><img
                                :src="v.img"
                                style="border-radius: 1rem;"
                                class="cursor"
                            ></div>
                        <div
                            style="width: 6%;"
                            v-if="i != banner.length - 1"
                        ></div>
                    </van-swipe-item>
                </van-swipe>

            </div> -->
            <div style="height: 0rem;"></div>
            <div style="width: 92%; margin-left: 4%;">
                <!-- <div class="homebox">
                    <div
                        class="HeiTi"
                        style="width: 92%; margin-left: 4%;"
                    >
                        <div
                            class="impact"
                            style="width: 100%; text-decoration: underline; font-size: 2rem;"
                        >{{$t('home.Revolutionize_Trading')}}</div>
                        <div
                            class="impact"
                            style="width: 100%; text-decoration: underline; text-align: right; font-size: 2rem;"
                        >{{$t('home.Shape_the_Future')}}</div>
                        <div style="height: 12.8rem; width: 100%; margin-top: 1rem; position: relative;"><iframe
                                width="100%"
                                height="100%"
                                src="https://www.youtube.com/embed/5Z8Le01SiSM"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen="allowfullscreen"
                            ></iframe></div>
                        <div style="width: 100%; font-size: 0.8rem; margin-top: 1rem;">{{$t('home.content0')}}</div>
                    </div>
                </div> -->
                <div class="homebox">
                    <div
                        class="HeiTi"
                        style="width: 92%; margin-left: 4%;"
                    >
                        <div
                            class="impact"
                            style="width: 100%; text-decoration: underline; font-size: 1.4rem; text-align: center; margin-top: 1.5rem;"
                        >{{$t('index.index.72y628')}}</div>
                        <div style="height: 8rem; width: 9rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-ino.ba1603d9.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 1rem;"
                        >{{$t('home.Innovative_Trading_Features')}}</div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem;">{{$t('home.content1')}}</div>
                        <div style="height: 9rem; width: 21rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-50bi.3594af3f.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 1rem;"
                        >{{$t('home.Lower_Fees_and_Better_Rewards')}}</div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem;">{{$t('home.content2')}}</div>
                        <div style="height: 9rem; width: 9rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-sec.160994e0.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 1rem;"
                        >{{$t('home.Institutional-grade_Security')}}</div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem;">{{$t('index.index.z6022y')}}</div>
                    </div>
                </div>
                <div class="homebox">
                    <div
                        class="HeiTi"
                        style="width: 92%; margin-left: 4%;"
                    >
                        <div
                            class="impact"
                            style="width: 100%; text-decoration: underline; font-size: 1.3rem; text-align: center; margin-top: 1.5rem;"
                        >{{$t('home.Start_investing_in_as_little_as_5_minutes')}}</div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 2.5rem;"
                        >{{$t('index.index.574982')}}</div>
                        <div style="height: 8rem; width: 15rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-1.sign-up.3cac115b.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 1rem; line-height: 1.2rem;">{{$t('home.content5')}}</div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 2.5rem;"
                        >{{$t('index.index.qdi1g4')}}</div>
                        <div style="height: 8rem; width: 15rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-2.complete.d27e8a08.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 1rem; line-height: 1.2rem;">{{$t('home.content7')}}</div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 2.5rem;"
                        >{{$t('index.index.weko28')}}</div>
                        <div style="height: 8rem; width: 15rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-3.fund.8836cac8.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 1rem; line-height: 1.2rem;">{{$t('home.content9')}}</div>
                    </div>
                </div>
                <div class="homebox">
                    <div
                        class="HeiTi"
                        style="width: 92%; margin-left: 4%;"
                    >
                        <div
                            class="impact"
                            style="width: 100%; text-decoration: underline; font-size: 1.4rem; text-align: center; margin-top: 1.5rem;"
                        >{{$t('home.Start_building_your_crypto_portfolio')}}</div>
                        <div style="height: 8rem; width: 10rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-gogoinvi.b8c30da1.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 1rem;"
                        >{{$t('index.index.sgkb35')}}</div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem;">{{$t('index.index.2u17nf')}}<span style="color: rgb(255, 213, 44);">559 USDT</span>. </div>
                        <div style="height: 8rem; width: 10rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-gogorefer.52abfb54.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 1rem;"
                        >{{$t('index.index.e8is4a')}}</div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem;">{{$t('home.content11')}}</div>
                        <div style="height: 8rem; width: 10rem; margin: 3.5rem auto auto;"><img
                                loading="lazy"
                                src="img/gogobit-gogotask.6f680d6e.png"
                                style="width: 100%; height: 100%;"
                            ></div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.3rem; text-align: center; margin-top: 1rem;"
                        >{{$t('index.index.47uevl')}}</div>
                        <div style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem;">{{$t('home.content12')}}</div>
                    </div>
                </div>
                <div class="homebox">
                    <div
                        class="HeiTi"
                        style="width: 92%; margin-left: 4%;"
                    >
                        <div
                            class="impact"
                            style="width: 100%; text-decoration: underline; font-size: 2rem; text-align: center;"
                        >{{$t('home.Trade_anytime_anywhere')}}</div>
                        <div
                            class="impact"
                            style="width: 100%; font-size: 1.2rem; text-align: center; margin-top: 1rem;"
                        >{{$t('home.content6')}}</div>
                        <div style="height: 1rem;"></div>
                        <div class="phdemo"></div>
                        <div style="overflow: auto; margin: auto; width: 17rem;">
                            <div style="height: 1rem;"></div>
                            <div class="appdownbox1"></div>
                            <div class="appdownbox2"></div>
                            <div class="appdownbox"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="elCollapsebox"
                style="width: 100%; margin-top: 2rem;"
            >
                <div style="width: 92%; margin-left: 4%;">
                    <div style="width: 100%; height: 4rem; margin-left: -4%;">
                        <div
                            class="m0_bg flex flex-middle"
                            style="width: 2rem; height: 2rem; float: left; margin-top: 0.75rem; margin-left: 0.75rem;"
                        ><img
                                loading="lazy"
                                src="img/logo.png"
                                style="width: 100%; height: 100%;"
                            >
                        </div>
                        <div
                            class="m0_bg2 cwhite m-l-5"
                            style="width: 6rem; height: 2rem; float: left; margin-top: 0.95rem; margin-left: 0.2rem;"
                        >EPOCH EPT</div>
                    </div>

                    <el-collapse
                        v-model="activeNames"
                        :border="false"
                    >
                        <el-collapse-item
                            v-for="(item, index) in itemList"
                            :key="index"
                            :title="item.head"
                            :name="index"
                            :border="false"
                        >
                            <div class="cursor">
                                <div v-for="(v,i) in item.list">
                                    <div class="cwhite font-13 ">{{v.name}}</div>
                                    <div
                                        class="cwhite font-12"
                                        v-if="v.val"
                                        :class="v.val ? 'm-b-10' : ''"
                                    >{{v.val}}</div>
                                </div>
                            </div>
                        </el-collapse-item>
                    </el-collapse>

                    <div style="width: 100%; border-top: 0.8px solid rgb(93, 93, 93); overflow: auto;"></div>
                    <!-- <div
                        class="css-vurnku2"
                        style="overflow: auto;"
                    ><a
                            href="https://twitter.com/gogobitofficial"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img src="/img/7ecie5ei3h2eg00.png"></div>
                        </a><a
                            href="https://www.youtube.com/@gogobitofficial"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img src="/img/1f84e82j1022e800.png"></div>
                        </a><a
                            href="https://www.tiktok.com/@gogobit2023"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img src="/img/127hac0hchc2f400.png"></div>
                        </a><a
                            href="https://www.instagram.com/gogobitofficial"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img src="/img/22h7jfja6022e800.png"></div>
                        </a><a
                            href="https://t.me/gogobitofficial"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img src="/img/1jhbj9664f22e800.png"></div>
                        </a><a
                            href="https://wa.me/447435823496"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img src="/img/b9g0i295dh2eg00.png"></div>
                        </a><a
                            href="https://www.facebook.com/EPOCH EPTCorp"
                            target="_blank"
                            class="css-1ug574w"
                        >
                            <div class="css-jw93ck"><img
                                    src="/img/h636e1i6ih2e800.png"
                                    style="width: 100%; height: 100%;"
                                ></div>
                        </a></div> -->
                    <!-- <div style="height: 3.5rem;"></div> -->
                    <div
                        class="HeiTi"
                        style="width: 100%; font-size: 0.9rem; margin-top: 0.6rem; line-height: 1.2rem; color: rgb(190, 190, 190);"
                    >{{$t('index.index.2516gq')}}</div>
                    <div
                        class="HeiTi"
                        style="width: 100%; font-size: 0.9rem; margin-top: 2rem; line-height: 1.2rem; color: rgb(190, 190, 190);"
                    > © 2023 EPOCH EPT, Inc. </div>
                    <div style="height: 5rem;"></div>
                </div>
            </div>
            <div class="togoogleShowbox"></div>
        </div>

        <my-menu ref="myMenu"> </my-menu>

    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            token: "",
            activeNames: ["1"],
            banner: [],
            userinfo: {},
            itemList: [
                {
                    head: this.$t("home.Company"),
                    list: [
                        {
                            name: this.$t("home.About_Us"),
                            val: "",
                        },
                        {
                            name: this.$t("home.Protection_Fund"),
                            val: "",
                        },
                        {
                            name: this.$t("home.Proof_of_Reserves"),
                            val: "",
                        },
                        {
                            name: "Twitter",
                            val: "",
                        },
                    ],
                    open: false,
                },

                {
                    head: this.$t("home.Products&Services"),
                    list: [
                        {
                            name: this.$t("win.Trend"),
                            val: "",
                        },
                        {
                            name: this.$t("win.Invest"),
                            val: "",
                        },
                        {
                            name: this.$t("Invited.Bonus"),
                            val: "",
                        },
                        {
                            name: this.$t("mine.Wallet"),
                            val: "",
                        },
                    ],
                    open: false,
                },
                {
                    head: this.$t("home.Legal&Disclosures"),
                    list: [
                        {
                            name: this.$t("home.Regulatory_License"),
                            val: "",
                        },
                        {
                            name: this.$t("Presale.title"),
                            val: "",
                        },
                        {
                            name: this.$t("home.Terms_of_Use"),
                            val: "",
                        },
                        {
                            name: this.$t("home.Legal_Statement"),
                            val: "",
                        },
                        {
                            name: this.$t("home.Risk_Disclosure"),
                            val: "",
                        },
                    ],
                    open: false,
                },
                // {
                //     head: this.$t("home.Support_Service"),
                //     list: [
                //         {
                //             name: "Support Service ",
                //             val: "support@gogobit.com",
                //         },
                //         {
                //             name: "Advertisement",
                //             val: "ads@gogobit.com",
                //         },
                //         {
                //             name: "VIP Service",
                //             val: "vip@gogobit.com",
                //         },
                //     ],
                //     open: false,
                // },
            ],
        };
    },
    props: {},
    created() {
        this.token = this.Cache.get("token");
        this.getBanber();
        this.getUserInfo();
    },
    mounted() {

      var img = document.querySelector('.fzbox22_img'); // const img = document.querySelector('img');

      var rotation = 0;
      // img.addEventListener('click', function () {
      //   rotation += 360;
      //   img.style.transform = 'rotate('.concat(rotation, 'deg)');
      // });
    },
    computed: {
    },
    methods: {

      getBalanceFun(){
        this.axios
            .post('/data/api.auth.center/balance',{})
            .then((v) => {
              this.getUserInfo()
            })
            .catch((err) => {
              this.getUserInfo()
            });
      },
        getUserInfo() {
            if (this.token) {
                this.axios
                    .post("/data/api.auth.center/get")
                    .then((v) => {
                        const { data } = v;
                        this.userinfo = data;
                        const num1 = this.$base.subtr(data.balance_total, data.balance_used)
                        const num2 = this.$base.accAdd(num1, data.rebate_total )
                        let balance = 0;
                        if(this.$base.subtr(num2, data.rebate_used) < 0){
                          balance = 0;
                        }else{
                          balance = this.$base.subtr(num2, data.rebate_used)
                        }
                        this.userinfo.balance = parseFloat(balance).toFixed(2)
                        this.$store.commit('SET_USERINFO', data);
                    })
                    .catch((err) => {});
            }
        },
        showMenu() {
            this.$refs.myMenu.open();
        },
        goRegister() {
            this.$router.push("/register");
        },
        goLogin() {
            this.$router.push("/login");
        },
        getBanber() {
            this.axios
                .post("/data/api.data/getSlider", {
                    keys: "nav",
                    loading:true
                })
                .then((v) => {
                    this.banner = v.data;
                })
                .catch((err) => {});
        },
        goUrl(v) {
            // if (v.type == "LK") {
            //     window.open(v.url, "_blank");
            // }
        },
    },
};
</script>

<style lang="scss" scoped>
.swipe111 {
    margin-top: 0.8rem;
    width: 92%;
    margin-left: 4%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.top_banner {
    background-image: linear-gradient(
            35deg,
            #182639 0%,
            #073048 44%,
            #0d608f 100%
        ),
        linear-gradient(#212322, #212322);
    padding: 10px;
    min-height: 120px;
    border-radius: 10px;
    .avatar {
        width: 80px;
        height: auto;
        object-fit: cover;
        margin-right: 8px;
    }
    .desc {
        color: #e0dddd;
    }
    .name {
        font-weight: 600;
        font-size: 14px;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 1.5px;
            background: #fff;
            left: 0;
        }
    }
}
.color-btn-jibian {
    background-image: linear-gradient(
            76deg,
            #08906a 0%,
            #0b787d 64%,
            #0d608f 100%
        ),
        linear-gradient(#30c6e4, #30c6e4);
}
.appdownbox {
    margin-left: 0.5rem;
    float: left;
    height: 3.2rem;
    background-image: url(~@/assets/img/72bh3e8ef22e800.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 5rem;
}
.appdownbox1 {
    margin-left: 1rem;
    float: left;
    height: 3.2rem;
    background-image: url(~@/assets/img/22d84bdf1672e800.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 5rem;
}
.appdownbox2 {
    margin-left: 0rem;
    float: left;
    height: 3.2rem;
    background-image: url(~@/assets/img/114680310172e800.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 5rem;
}
.phdemo {
    width: 11rem;
    height: 15rem;
    margin: auto;
    background-image: url(~@/assets/img/phdemo.a53d9075.png);
    background-size: 100%;
    background-repeat: no-repeat;
}
.impact {
    font-family: "MyFont-impact";
}
.HeiTi {
    font-family: "MyFont-HeiTi";
}
.homebox {
    margin-top: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 2.8rem;
    width: 100%;
    background-image: linear-gradient(
        52deg,
        #101925 0%,
        #182639 64%,
        #1f324c 100%
    );
    border-radius: 0.7rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    color: white;
}
.homebox span {
    color: #ffd52c;
}
.css-jw93ck {
    margin-top: 1rem;
    float: left;
    box-sizing: border-box;
    border-radius: 5rem;
    margin-right: 4%;
    color: currentColor;
    width: 1.7rem;
    height: 1.7rem;
    fill: currentColor;
    overflow: hidden;
}
.css-jw93ck img {
    width: 100%;
    height: 100%;
}
.van-popup__close-icon--top-right {
    color: #707a8a;
    font-weight: bold;
    width: 1rem;
    height: 1rem;
}
.el-collapse-item__wrap {
    border: none;
    background-color: rgba(0, 0, 0, 0);
}
.el-collapse {
    border: none;
}
.el-collapse-item__header {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 1rem;
    color: white !important;
}
.is-active .el-collapse-item__arrow {
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
}
.el-collapse-item__arrow {
    font-size: 1.2rem;
    width: 1rem;
    height: 1rem;
}
.elCollapsebox {
    font-family: "MyFont-HeiTi";
    background-color: #042132;
}
.css-zmxxr7 {
    background-color: rgba(0, 0, 0, 0);
}
.css-zmxxr7 a {
    width: 70%;
    margin-left: 7%;
    color: white;
    display: block;
    min-width: 20rem;
    height: 1.7rem;
    font-size: 0.85rem;
}
.swipe1 {
    margin-top: 0.8rem;
    width: 92%;
    height: 6rem;
    margin-left: 4%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe1 img {
    width: 100%;
    height: 6rem;
}
.numbtnbox {
    height: 11.5rem;
    position: absolute;
    width: 5rem;
    margin-left: calc(100% - 5.5rem);
    z-index: 99;
    margin-top: 1.5rem;
}
.swipe2_text {
    position: relative;
    margin-top: 1rem;
    margin-right: 2%;
    float: right;
    color: white;
    font-family: "MyFont-impact";
    font-size: 0.8rem;
}
.jiaoyisuo {
    float: left;
    height: 10.5rem;
    margin-left: 6%;
    width: 60%;
    background-image: url(~@/assets/img/jiaoyisuo.34f01c15.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.googleappdonwn {
    background-image: url(~@/assets/img/erweima.40019b3c.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.googleappdonwn2 {
    background-image: url(~@/assets/img/appstore.01bb861c.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.text_color {
    color: #029798 !important;
}
.bg_color {
    border: #029798 !important;
    color: white !important;
    background: linear-gradient(to right, #0a8076, #029798);
}
.bg_color2 {
    border: #029798 !important;
    color: white !important;
    background: linear-gradient(to right, #0a8076, #0a8076);
}
.text_color2 {
    color: #0a8076;
}
.color_bg_btn {
    background: linear-gradient(to right, #0a8076, #029798) !important;
}
.color-bg-h {
    background-color: #262434;
}
.color-bg-1 {
    background-color: #000000;
}
.color-bg-1222 {
    background-image: linear-gradient(
        52deg,
        #101925 0%,
        #182639 64%,
        #1f324c 100%
    );
}
.color-bg-blue {
    background-color: #029798;
}
.color-bg-tips-top {
    background-color: #f5f5f5;
}
.color-bg-h-top {
    background-color: #262434;
}
.van-nav-bar {
    background: #262434 !important;
}
.van-nav-bar__title {
    font-size: 1rem !important;
}
.van-hairline--bottom::after {
    border-bottom-width: 0px;
}
.iconLogo {
    width: 2rem;
    height: 2rem;
}
.barRight {
    width: 12rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: #000000;
}
.barRight1 {
    float: left;
    width: 55%;
    height: 2rem;
}
.barRight1_1 {
    color: white;
    font-weight: bold;
    height: 0.8rem;
}
.barRight1_2 {
    color: white;
    height: 1.2rem;
}
.barRight2 {
    line-height: 2rem;
    background: linear-gradient(90deg, #029798, #0a8076);
    border-radius: 2rem;
    float: left;
    width: 45%;
    height: 2rem;
}
.textcorol {
    color: #029798;
    font-weight: bold;
    font-size: 1.1rem;
    background: linear-gradient(-81deg, #0a8076, #0a8076);
    -webkit-background-clip: text;
    color: transparent;
}
.van-swipe__indicator--active {
    background-color: #029798;
}
.van-notice-bar__wrap {
    color: white;
}
.van-grid-item__content--center {
    background-color: #262434 !important;
}
.van-image {
    width: 70%;
    height: 70%;
}
.van-grid_text {
    overflow: hidden;
    text-align: center;
    height: 1rem;
    line-height: 0.95rem;
    width: 100%;
    color: #7e8899;
    font-size: 0.7rem;
    font-weight: bold;
    margin-top: 0.1rem;
}
.homeCard-Taskimg {
    margin-left: 20%;
    float: left;
    width: 1.5rem;
    height: 1.5rem;
}
.homeCard-Tasktext {
    margin-left: 5%;
    float: left;
    width: 4rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.9rem;
    font-weight: bold;
}
.homeCard-Taskbox {
    margin-top: 1.25rem;
    width: 100%;
    height: 1.5rem;
}
.homeCard-Task {
    width: 92%;
    margin: 4%;
    height: 4rem;
}
.homeCard-Task0 {
    float: left;
    width: 48%;
    height: 4rem;
    border-radius: 0.625rem;
    box-shadow: 0rem 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
}
.homeCard-Task1 {
    margin-left: 4%;
    float: left;
    width: 48%;
    height: 4rem;
    border-radius: 0.625rem;
    box-shadow: 0rem 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
}
.home {
    font-size: 1rem;
}
.home {
    /*********自适应***********/
    max-width: 100%;
    min-width: 16rem;
    margin: 0 auto;
    display: flex;
    min-height: 100vh;
}
.home .layout {
    width: 100%;
    flex: 1;
    min-height: 100vh;
}
.homeCard-right1 {
    height: 1.125rem;
    width: 100%;
    font-size: 0.9375rem;
    color: white;
    margin-top: 1.125rem;
}
.homeCard-right2 {
    margin-top: 1.125rem;
    height: 2.5rem;
    width: 100%;
    font-size: 1.375rem;
    font-weight: bold;
    color: white;
    margin-top: 1.125rem;
}
.homeCard-right3 {
    height: 1.125rem;
    width: 100%;
    font-size: 0.9375rem;
    margin-top: 1.125rem;
    color: white;
}
.homeCard-left1 {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1.125rem;
    width: 100%;
    height: 2.7rem;
    line-height: 2.7rem;
    border-radius: 1.5625rem;
    text-align: center;
    background-image: linear-gradient(180deg, #e9c79a, #e7bd88);
    color: white;
}
.homeCard-left2 {
    font-weight: bold;
    font-size: 1rem;
    border-radius: 1.5625rem;
    margin-top: 1.125rem;
    width: 100%;
    height: 2.7rem;
    text-align: center;
    line-height: 2.7rem;
    color: white;
    background-color: #e8e8e8;
    background-color: rgba(40, 40, 40, 0.5);
}
.homeCard-right {
    margin-left: 3%;
    width: 58%;
    height: 9rem;
    float: left;
}
.homeCard-left {
    width: 35%;
    height: 9rem;
    float: left;
}
.homeCard {
    background-image: linear-gradient(0deg, #e9c79a, #a88571);
    box-shadow: 0px 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
    width: 92%;
    height: 9rem;
    margin: 4%;
    border-radius: 0.625rem;
}
.download {
    height: 3.125rem;
    background-image: linear-gradient(180deg, #5d5d5d, #030000);
    color: #fff;
    line-height: 3.125rem;
}
.download span {
    display: block;
    float: left;
    text-align: center;
    margin: 0 0 0 1.875rem;
}
.downloadspan {
    display: block;
    float: left;
    text-align: center;
    font-size: 1.4rem;
    margin: 0 0 0 6.075rem;
}
.download button {
    line-height: 1.875rem;
    margin: 0.75rem 0.625rem 0.1875rem 0;
    float: right;
    display: block;
    border-radius: 0.3125rem;
    padding: 0 1.125rem;
    font-size: 0.8125rem;
    border: 0;
    background: #a88571;
    color: #fff;
    box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.2);
}
.swipe {
    margin-top: 0.8rem;
    width: 92%;
    height: 11rem;
    margin-left: 4%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe img {
    width: 100%;
    height: 11rem;
}
.search {
    padding-left: 31%;
    color: #a88571;
    font-weight: bold;
    font-size: 1.25rem;
    text-align: center;
    background-color: white;
    box-shadow: 0rem 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
    width: 50%;
    height: 2.5rem;
    margin: 1.25rem auto 1.25rem;
    border-radius: 0.625rem;
}
.searchbtn {
    background-image: linear-gradient(to right, #e9c79a, #e7bd88);
    border: 0;
    border-radius: 18px;
    height: 25px;
    font-size: 14px;
}
.list {
    width: 96%;
    margin: 0 auto;
}
.list-li {
    width: 50%;
    margin: 0.625rem 0;
    float: left;
}
.li1 {
    width: 90%;
    margin: 0 auto;
    border-radius: 0.625rem;
}
.img-box {
    width: 100%;
    height: 10rem;
    margin: 0 auto;
}
.imgshow {
    height: 50px;
    position: relative;
}
.img-hot {
    width: 45px;
    height: 45px;
}
.img-new {
    width: 35px;
    height: 35px;
    float: right;
    border-radius: 0 10px 0 0;
}
.img-img {
    width: 100%;
    height: 100%;
    margin-top: -50px;
    border-radius: 10px;
}
.title {
    color: #000;
    font-weight: bold;
    margin: 0 auto;
    width: 95%;
    font-size: 14px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: center;
}
.des {
    color: #666;
    margin: 0 auto;
    width: 95%;
    font-size: 14px;
    height: 40px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-align: center;
}
.li2 {
    width: 45vw;
    height: 30px;
    border-radius: 10px;
    background: #e7bd89;
    margin: 20px auto 0;
    color: #fff;
    line-height: 30px;
}
.li2 img {
    width: 20px;
    height: 20px;
    margin: 5px 10px 5px 17%;
    float: left;
}
.box2 {
    overflow: hidden;
}
.box2 .img2 {
    width: 100%;
    height: 9.375rem;
}
.box2 .content2 {
    margin-left: 5%;
    width: 92%;
}
.box2 .id2 {
    font-size: 0.875rem;
    color: #f79f81;
    margin: 0.3125rem auto;
    line-height: 1.5625rem;
}
.box2 .copy2 {
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    background: #fff;
    margin-bottom: 1.25rem;
}
.box2 .copy2 .text2 {
    width: 50%;
    line-height: 2.75rem;
}
.box2 .copy2 .van-button--normal2 {
    height: 1.875rem;
    margin-top: 0.375rem;
    border: 0;
    color: #fff;
    background: #e9c79a;
    border-radius: 1.25rem;
}
.togoogleShowbox .van-popup {
    border-radius: 0.5rem !important;
    background-color: rgba(0, 0, 0, 0);
    max-width: 23rem !important;
    min-width: 23rem !important;
    position: fixed !important;
    margin: 0 !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
}
</style>


<style scoped>
.avaratbox {
    width: 5.5rem;
    height: 5.5rem;
    box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.56);
    margin: 0.5rem auto 1rem auto;
}
.color-text-ebe9e9 {
    margin-top: -0.7rem;
    color: #7e8899;
}
@-webkit-keyframes fade-data-v-1e2accb2 {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes fade-data-v-1e2accb2 {
    from {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.box {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background: #ff0;
    padding: 10px;
    font-size: 15px;
    -webkit-animation: fade-data-v-1e2accb2 2000ms infinite ease-in-out;
    animation: fade-data-v-1e2accb2 2000ms infinite ease-in-out;
    z-index: 1;
}
.box2 {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    background: #ee0;
    padding: 10px;
    font-size: 15px;
    z-index: 2;
    margin-top: -110px;
    margin-left: 10px;
    position: absolute;
}
.swipe0 {
    color: white;
    background-image: url(~@/assets/img/topbg.c8bd898e.png);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 0.8rem;
    width: 94%;
    height: 15rem;
    margin-left: 3%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe0 img {
    width: 100%;
    height: 11rem;
}
.swipe0 .swipe02_btn1 {
    float: left;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.56);
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    margin: 1.3rem;
}
.swipe0 .fnzbox {
    font-family: "MyFont-impact";
}
.swipe0 .fheiti {
    font-family: "MyFont-HeiTi";
}
.swipe0 .swipe0_btn12 {
    font-family: "MyFont-HeiTi";
    font-weight: bold;
    line-height: 2rem;
    text-align: center;
    float: right;
    height: 2rem;
    margin-top: 1.5rem;
}
.swipe0 .box {
    height: 100px;
    border-radius: 50%;
    background: #ff0;
    padding: 10px;
    font-size: 15px;
    -webkit-animation: fade-data-v-1e2accb2 2000ms infinite ease-in-out;
    animation: fade-data-v-1e2accb2 2000ms infinite ease-in-out;
    z-index: 1;
}
.swipe0 .box2 {
    font-family: "MyFont-HeiTi";
    height: 1.8rem;
    border-radius: 50%;
    background: #ee0;
    padding: 10px;
    font-size: 15px;
    z-index: 2;
    position: absolute;
}
.swipe0 .swipe0_btn1_2 {
    box-shadow: 1px 1px 1px 1px rgba(20, 208, 208, 0.56);
    -webkit-animation: 1.5s ease 0s infinite normal none running
        fade-data-v-1e2accb2;
    animation: 1.5s ease 0s infinite normal none running fade-data-v-1e2accb2;
    z-index: 2;
    position: relative;
    font-family: "MyFont-HeiTi";
    margin-top: 1.5rem;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.8rem;
    text-align: center;
    float: right;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    height: 1.8rem;
    background: #029798;
    border-radius: 0.3rem;
}
@keyframes fade-data-v-1e2accb2 {
    10%,
    90% {
        transform: translate3d(-1px, 0, 0);
    }
    0% {
        transform: scale(1);
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 30px #5a99d400;
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 #5a99d400;
    }
}
.swipe0 .swipe0_btn1 {
    margin-left: 67%;
    margin-top: 1.2rem;
    -webkit-animation: fade-data-v-1e2accb2 2000ms infinite ease-in-out;
    animation: fade-data-v-1e2accb2 2000ms infinite ease-in-out;
    z-index: 1;
    font-family: "MyFont-HeiTi";
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 2.5rem;
    text-align: center;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    height: 2.5rem;
    background: #0a8076;
    border-radius: 0.3rem;
}
.swipe0 .swipe0_btn1box {
    margin-top: 0.7rem;
    font-family: "MyFont-HeiTi";
    float: right;
    height: 3rem;
    border-radius: 0.3rem;
    padding: 0.2rem;
}
.swipe0 .swipe0_btn1box_2 {
    box-shadow: 1px 1px 1px 1px rgba(20, 208, 208, 0.56);
    -webkit-animation: 1.5s ease 0s infinite normal none running
        fade-data-v-1e2accb2;
    animation: 1.5s ease 0s infinite normal none running fade-data-v-1e2accb2;
    margin-left: 0.4rem;
    z-index: 2;
    position: absolute;
    font-family: "MyFont-HeiTi";
    margin-top: -2.2rem;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 1.8rem;
    text-align: center;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    height: 1.8rem;
    background: #029798;
    border-radius: 0.3rem;
}
.swipe0 .swipe0_btn2 {
    float: right;
}
.helpbox {
    margin-top: 2rem;
    width: 86%;
    margin-left: 6%;
    height: 3.1rem;
    border-radius: 0.7rem;
    background-color: #323043;
}
.helpbox_text {
    width: 6.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.languagebox_text {
    width: 6.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
    font-weight: 200;
    color: white;
}
.languagebox {
    position: fixed;
    bottom: 1rem;
    margin-top: 2rem;
    width: 86%;
    margin-left: 6%;
    height: 3.1rem;
    border-radius: 0.7rem;
    background-color: #1f324c;
}
.languagebox2 {
    bottom: 1rem;
    margin-top: 2rem;
    width: 86%;
    margin-left: 6%;
    height: 3.1rem;
    border-radius: 0.7rem;
    background-color: #1f324c;
}
.addcolorhome1 {
    color: #029798 !important;
    background: linear-gradient(
        269.64deg,
        #060507 -3.96%,
        #262434 -3.95%,
        #333144 86.73%
    ) !important;
}
.addcolorhome2 {
    background-image: none !important;
    color: #adadc2 !important;
}
.minueShowcss .van-popup {
    width: 16rem !important;
    min-width: 5rem !important;
    margin: 0px;
}
.minueShowbox {
    position: relative;
    font-family: "MyFont-HeiTi";
    color: white;
    background-image: linear-gradient(
        52deg,
        #101925 0%,
        #182639 64%,
        #1f324c 100%
    );
    font-size: 0.9rem;
    height: 100vh;
    width: 16rem;
}
.minueShowbox .m0_icon {
    width: 2rem;
    height: 2rem;
    float: right;
    margin-top: 0.75rem;
    margin-right: 0.75rem;
    border-radius: 0.5rem;
    background-color: white;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.56);
}
.minueShowbox .m0_icon .css-dxyzad {
    margin-top: 0.45rem;
    margin-left: 0.35rem;
}
.minueShowbox .m1 {
    width: 100%;
    height: 3rem;
    color: #029798;
    background: linear-gradient(
        269.64deg,
        #060507 -3.96%,
        #262434 -3.95%,
        #333144 86.73%
    );
}
.minueShowbox .m2 {
    width: 100%;
    height: 3rem;
}
.minueShowbox .m2_text {
    width: 11rem;
    height: 3rem;
    line-height: 3rem;
    padding-left: 0.75rem;
    float: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.9rem;
}
.minueShowbox .right_icon {
    margin-top: 1rem;
    float: left;
    width: 0.8rem;
    height: 1rem;
    background-image: url(~@/assets/img/minueright.bcb5c3ed.png);
    background-size: 100% 100%;
}
.minueShowbox .right_icon2 {
    margin-left: -0.1rem;
    margin-top: 1.05rem;
    float: left;
    width: 1rem;
    height: 1rem;
    background-image: url(~@/assets/img/yes.8c0d10ea.png);
    background-size: 100% 100%;
}
.minueShowbox .m2_icon {
    width: 1.2rem;
    height: 1.2rem;
    float: left;
    margin-top: 0.9rem;
    margin-left: 0.9rem;
    background-image: url(~@/assets/img/minuewallet.bd8cba38.png);
    background-size: 100% 100%;
}
.minueShowbox .m3_icon {
    width: 1.2rem;
    height: 1.2rem;
    float: left;
    margin-top: 0.9rem;
    margin-left: 0.9rem;
    background-image: url(~@/assets/img/minuepassword.72f33984.png);
    background-size: 100% 100%;
}
.minueShowbox .m4_icon {
    width: 1.2rem;
    height: 1.2rem;
    float: left;
    margin-top: 0.9rem;
    margin-left: 0.9rem;
    background-image: url(~@/assets/img/minuegogleyz.18370302.png);
    background-size: 100% 100%;
}
.minueShowbox .m5_icon {
    width: 1.2rem;
    height: 1.2rem;
    float: left;
    margin-top: 0.9rem;
    margin-left: 0.9rem;
    background-image: url(~@/assets/img/minuepolicy.a8d8a2c8.png);
    background-size: 100% 100%;
}
.minueShowbox .m6_icon {
    width: 1.2rem;
    height: 1.2rem;
    float: left;
    margin-top: 0.9rem;
    margin-left: 0.9rem;
    background-image: url(~@/assets/img/minuehelp.5ed65dd8.png);
    background-size: 100% 100%;
}
.minueShowbox .m7_icon {
    width: 1.2rem;
    height: 1.2rem;
    float: left;
    margin-top: 0.9rem;
    margin-left: 0.9rem;
    background-image: url(~@/assets/img/minueout.b59b3d90.png);
    background-size: 100% 100%;
}
.swipe02 {
    font-family: "MyFont-impact";
    position: relative;
    color: white;
    background-image: linear-gradient(
            35deg,
            #182639 0%,
            #073048 44%,
            #0d608f 100%
        ),
        linear-gradient(#212322, #212322);
    background-blend-mode: normal, normal;
    box-shadow: 4px 3px 18px 2px rgba(0, 0, 0, 0.56);
    margin-top: 0.8rem;
    width: 94%;
    height: 9.5rem;
    margin-left: 3%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe02 .swipe02_btn1 {
    font-family: "MyFont-HeiTi";
    float: right;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    width: 1.95rem;
    height: 1.95rem;
    border-radius: 0.3rem;
    margin: 0.8rem;
    overflow: hidden;
}
.swipe02 .swipe02_btn12 {
    font-family: "MyFont-HeiTi";
    float: right;
    width: 1.95rem;
    height: 1.95rem;
    margin-top: 0.8rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    overflow: hidden;
}
.swipe02 .swipe02_btn13 {
    font-family: "MyFont-HeiTi";
    float: right;
    width: 1.95rem;
    height: 1.95rem;
    margin-top: 0.8rem;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    overflow: hidden;
}
.swipe02 .swipe02_text1 {
    padding-top: 1.5rem;
    width: 50%;
    float: left;
    margin: auto;
    height: 3rem;
}
.swipe02 .swipe02_btn2 {
    float: right;
    background-image: url(~@/assets/img/centerman.8c8d676d.png);
    background-size: 100% 100%;
    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    margin: 1.3rem;
}
.swipe02_btn2 {
    float: right;
    background-image: url(~@/assets/img/centerman.8c8d676d.png);
    background-size: 100% 100%;
    width: 3rem;
    height: 3rem;
    border-radius: 5rem;
    margin: 1.3rem;
}
.swipe1 {
    margin-top: 0.8rem;
    width: 92%;
    height: 6rem;
    margin-left: 4%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe1 img {
    width: 100%;
    height: 6rem;
}
.swipe2 {
    background-image: url(~@/assets/img/kimg.19a46b6c.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    margin-top: 0.8rem;
    width: 96%;
    height: 16rem;
    margin-left: 2%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe2 img {
    width: 100%;
    height: 16rem;
}
.text_color {
    color: #029798 !important;
}
.bg_color {
    border: #029798 !important;
    color: white !important;
    background: linear-gradient(to right, #0a8076, #029798);
}
.bg_color2 {
    border: #029798 !important;
    color: white !important;
    background: linear-gradient(to right, #0a8076, #0a8076);
}
.text_color2 {
    color: #0a8076;
}
.color_bg_btn {
    background: linear-gradient(to right, #0a8076, #029798) !important;
}
.color-bg-h {
    background-color: #262434;
}
.color-bg-h1f324c {
    background-color: #1f324c;
}
.color-bg-1 {
    background-color: #000000;
}
.color-bg-blue {
    background-color: #029798;
}
.color-bg-tips-top {
    background-color: #f5f5f5;
}
.color-bg-h-top {
    background-color: #262434;
}
.color-bg-h-top23 {
    background-color: red;
}
.selectkfu1 {
    background-image: linear-gradient(
        52deg,
        #101925 0%,
        #182639 64%,
        #1f324c 100%
    );
}
.selectkfu1_box {
    width: 100%;
    border-radius: 0.7rem;
    background-color: #1f324c;
}
.selectkfu1_box_text {
    color: white !important;
}
.van-nav-bar {
    background: #262434 !important;
}
.van-nav-bar__title {
    font-size: 1rem !important;
}
.van-hairline--bottom::after {
    border-bottom-width: 0px;
}
.iconLogo {
    width: 2rem;
    height: 2rem;
}
.barRight {
    width: 12rem;
    height: 2rem;
    border-radius: 2rem;
    background-color: #000000;
}
.barRight1 {
    float: left;
    width: 55%;
    height: 2rem;
}
.barRight1_1 {
    color: white;
    font-weight: bold;
    height: 0.8rem;
}
.barRight1_2 {
    color: white;
    height: 1.2rem;
}
.barRight2 {
    line-height: 2rem;
    background: linear-gradient(90deg, #029798, #0a8076);
    border-radius: 2rem;
    float: left;
    width: 45%;
    height: 2rem;
}
.textcorol {
    color: #029798;
    font-weight: bold;
    font-size: 1.1rem;
    background: linear-gradient(-81deg, #0a8076, #0a8076);
    -webkit-background-clip: text;
    color: transparent;
}
.van-swipe__indicators {
    margin-left: 38%;
}
.van-swipe__indicator--active {
    background-color: #029798;
}
.van-notice-bar__wrap {
    color: white;
}
.van-grid-item__content--center {
    background-color: #262434 !important;
}
.van-image {
    width: 70%;
    height: 70%;
}
.van-grid_text {
    overflow: hidden;
    text-align: center;
    height: 1rem;
    line-height: 0.95rem;
    width: 100%;
    color: #7e8899;
    font-size: 0.7rem;
    font-weight: bold;
    margin-top: 0.1rem;
}
.homeCard-Taskimg {
    margin-left: 20%;
    float: left;
    width: 1.5rem;
    height: 1.5rem;
}
.homeCard-Tasktext {
    margin-left: 5%;
    float: left;
    width: 4rem;
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: 0.9rem;
    font-weight: bold;
}
.homeCard-Taskbox {
    margin-top: 1.25rem;
    width: 100%;
    height: 1.5rem;
}
.homeCard-Task {
    width: 92%;
    margin: 4%;
    height: 4rem;
}
.homeCard-Task0 {
    float: left;
    width: 48%;
    height: 4rem;
    border-radius: 0.625rem;
    box-shadow: 0rem 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
}
.homeCard-Task1 {
    margin-left: 4%;
    float: left;
    width: 48%;
    height: 4rem;
    border-radius: 0.625rem;
    box-shadow: 0rem 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
}
.home {
    font-size: 1rem;
}
.home {
    /*********自适应***********/
    background-color: #000000;
    margin: 0 auto;
    display: flex;
}
.home .layout {
    width: 100%;
    flex: 1;
}
.homeCard-right1 {
    height: 1.125rem;
    width: 100%;
    font-size: 0.9375rem;
    color: white;
    margin-top: 1.125rem;
}
.homeCard-right2 {
    margin-top: 1.125rem;
    height: 2.5rem;
    width: 100%;
    font-size: 1.375rem;
    font-weight: bold;
    color: white;
    margin-top: 1.125rem;
}
.homeCard-right3 {
    height: 1.125rem;
    width: 100%;
    font-size: 0.9375rem;
    margin-top: 1.125rem;
    color: white;
}
.homeCard-left1 {
    font-weight: bold;
    font-size: 1rem;
    margin-top: 1.125rem;
    width: 100%;
    height: 2.7rem;
    line-height: 2.7rem;
    border-radius: 1.5625rem;
    text-align: center;
    background-image: linear-gradient(180deg, #e9c79a, #e7bd88);
    color: white;
}
.homeCard-left2 {
    font-weight: bold;
    font-size: 1rem;
    border-radius: 1.5625rem;
    margin-top: 1.125rem;
    width: 100%;
    height: 2.7rem;
    text-align: center;
    line-height: 2.7rem;
    color: white;
    background-color: #e8e8e8;
    background-color: rgba(40, 40, 40, 0.5);
}
.homeCard-right {
    margin-left: 3%;
    width: 58%;
    height: 9rem;
    float: left;
}
.homeCard-left {
    width: 35%;
    height: 9rem;
    float: left;
}
.homeCard {
    background-image: linear-gradient(0deg, #e9c79a, #a88571);
    box-shadow: 0px 0.125rem 0.625rem rgba(41, 12, 127, 0.2);
    width: 92%;
    height: 9rem;
    margin: 4%;
    border-radius: 0.625rem;
}
.download {
    height: 3.125rem;
    background-image: linear-gradient(180deg, #5d5d5d, #030000);
    color: #fff;
    line-height: 3.125rem;
}
.download span {
    display: block;
    float: left;
    text-align: center;
    margin: 0 0 0 1.875rem;
}
.downloadspan {
    display: block;
    float: left;
    text-align: center;
    font-size: 1.4rem;
    margin: 0 0 0 6.075rem;
}
.download button {
    line-height: 1.875rem;
    margin: 0.75rem 0.625rem 0.1875rem 0;
    float: right;
    display: block;
    border-radius: 0.3125rem;
    padding: 0 1.125rem;
    font-size: 0.8125rem;
    border: 0;
    background: #a88571;
    color: #fff;
    box-shadow: 0 0.125rem 0.125rem 0 rgba(0, 0, 0, 0.2);
}
.swipe {
    margin-top: 0.8rem;
    width: 92%;
    height: 11rem;
    margin-left: 4%;
    border-radius: 0.8rem;
    overflow: hidden;
}
.swipe img {
    width: 100%;
    height: 11rem;
}
.outLoginshow0 {
    font-family: "MyFont-HeiTi";
    background-image: linear-gradient(
        52deg,
        #101925 0%,
        #182639 64%,
        #1f324c 100%
    );
}
.outLoginshow0 .van-dialog__footer {
    background-color: rgba(0, 0, 0, 0);
}
.outLoginshow0 .van-dialog__cancel {
    background: linear-gradient(90deg, #1f324c, #1f324c);
    font-weight: bold;
}
.outLoginshow0 .van-dialog__confirm {
    font-weight: bold;
    background: linear-gradient(90deg, #1f324c, #1f324c);
}
.outLoginshow0 .i22 {
    font-size: 0.875rem;
}
.outLoginshow0 .outLoginshow {
    color: #7e8899;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    font-size: 0.95rem;
}
.el-dropdown-menu__item {
    font-family: "MyFont-HeiTi";
    font-size: 0.9rem;
    color: white;
}
.popper__arrow::after {
    border-bottom-color: #133361 !important;
}
.van-popup {
    background-color: rgba(0, 0, 0, 0);
}
.cobyDomObj {
    margin-left: 0.3rem;
    background-image: url(~@/assets/img/h3818h8hhc2f400.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 1rem;
    width: 1rem;
    line-height: 2rem;
    font-size: 0.9rem;
    float: left;
}

/deep/ .el-collapse-item__wrap {
    border: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0);
}
/deep/ .el-collapse-item__header {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 1rem;
    color: white !important;
}
/deep/ .el-collapse-item__content {
    color: #fff;
    margin: 10px;
    padding-bottom: 0;
    font-size: 12px;
}

.fzbox22_img {
  transition: transform 1s;
}
</style>