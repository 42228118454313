module.exports = {
	"Language": {
		"title": "Sprache"
	},
	"Noproductsforsale": "Keine Produkte zum Verkauf",
	"rechargePresentationText0_1": "Aufladen 1000 Belohnung ",
	"rechargePresentationText0_2": ", Aufladen 10000 Belohnung ",
	"rechargePresentationText0_3": ", und so weiter... Klick mich!",
	"rechargePresentationText0": "Sie erhalten ",
	"rechargePresentationText1": "% des Aufladebetrags, wenn Sie jetzt aufladen. Die Aktivitätszeit ist begrenzt und endet nach dem Ende des Geschenks.",
	"rechargePresentationText2": "Belohnung Endzeit, Countdown!",
	"Online_Chat": "Online-Chat",
	"gobindgoogle": "Bitte binden Sie Ihren Google Authenticator",
	"Order": "Bestellen",
	"Record": "Datensatz",
	"Assist": "Unterstützung",
	"Market": "Markt",
	"History": "Geschichte",
	"Confirm": "Bestätigen",
	"close": "Schließen",
	"Cancel": "Abbrechen",
	"Previous": "Zurück",
	"Next": "Nächster",
	"Copy": "Kopieren",
	"Nomoredata": "keine Daten",
	"moneychar": "₹",
	"DownloadApp": "Jetzt App herunterladen",
	"Yes": "Ja",
	"No": "Nein",
	"Wating": "in Warteschlange",
	"Recharge": "Einzahlen",
	"Withdrawal": "Abheben",
	"Success": "Erfolg",
	"stop_investing": "Investitionen beenden",
	"OrderSuccessful": "Bestellung erfolgreich",
	"Handledataerror": "Datenverarbeitungsfehler",
	"Copysuccess": "Kopieren erfolgreich",
	"MyBalanceLoding": "Mein Guthaben wird geladen",
	"UPDATE": "AKTUALISIERUNG",
	"New_version_available": "Neue Version verfügbar",
	"Please,update_app_to_new_version": "Bitte aktualisieren Sie die App auf die neue Version",
	"tabbar": {
		"Home": "Startseite",
		"AndarBahar": "Andar Bahar",
		"Invest": "Investieren",
		"Bonus": "Bonus",
		"Mine": "Brieftasche",
		"Trending": "Trending",
		"Wallet": "Brieftasche",
		"Rewards": "Belohnungen"
	},
	"homeTop": {
		"Sign_Up": "Registrieren",
		"Sign_up_for_rewards": "Für Belohnungen anmelden",
		"up_to": "bis zu",
		"TOTAL_ASSETS": "GESAMTVERMÖGEN",
		"User_ID": "Benutzer-ID",
		"Registration_time": "Registrierungszeit"
	},
	"PieChart": {
		"BIG&SMALL": "GROß & KLEIN",
		"NUMBER": "ZAHL",
		"ODD&EVEN": "UNGERADE & GERADE",
		"ALL_NUMBER_TRENDING": "ALLE ZAHLEN TRENDS",
		"BIG&SMALL_NUMBER_TRENDING": "GROß & KLEIN ZAHLEN TRENDS",
		"ODD&EVEN_NUMBER_TRENDING": "UNGERADE & GERADE ZAHLEN TRENDS"
	},
	"register": {
		"checkerr": "Passwort: Verwenden Sie 8 oder mehr Zeichen (eine Kombination aus Buchstaben und Zahlen)",
		"tips_3": "Wenn Sie den Code nicht in Ihrem Posteingang erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner.",
		"receiveCode": "Code nicht erhalten?",
		"receiveCode_title": "Code an Ihre E-Mail gesendet. Wenn Sie den Code nach mehreren Versuchen nicht erhalten haben, versuchen Sie bitte Folgendes:",
		"receiveCode_text1": "1. Überprüfen Sie, ob er in Ihrem Junk/Spam-Ordner ist.",
		"receiveCode_text2": "2. Stellen Sie sicher, dass Ihre E-Mail-Adresse ",
		"receiveCode_text3": "3. Die Nachricht kann sich einige Minuten verzögern. Versuchen Sie es nach 20 Minuten erneut.",
		"receiveCode_text4": "4. Wenn diese E-Mail-Adresse bereits vorhanden ist, senden wir Ihnen keinen Authentifizierungscode. Bitte melden Sie sich an oder setzen Sie Ihr Passwort zurück.",
		"Sign_up_for_Rewards": "Für Belohnungen anmelden",
		"Email_Verification": "E-Mail-Verifizierung",
		"tips": "Bitte geben Sie den 6-stelligen Verifizierungscode ein, der an {{formData.email}} gesendet wurde. Der Code ist 30 Minuten lang gültig.",
		"tips_1": "Bitte geben Sie den 6-stelligen Verifizierungscode ein, der an ",
		"tips_2": " gesendet wurde. Der Code ist 30 Minuten lang gültig.",
		"Resend_code_in": "Code erneut senden in",
		"Sent_again": "Erneut senden",
		"CREATE_ACCOUNT": "KONTO ERSTELLEN",
		"inviteCodecheck": "Einladungscode darf nicht leer sein!",
		"Mobile": "Handynummer",
		"Mobilemsg": "Handynummer ist erforderlich",
		"Password": "Passwort",
		"Passwordmsg": "Passwort ist erforderlich",
		"OTPCode": "OTP-Code",
		"OTPCodemsg": "OTP-Code ist erforderlich",
		"SendOTP": "OTP senden",
		"Email": "E-Mail",
		"Emailmsg": "E-Mail ist erforderlich",
		"Invitecode": "Empfehlungscode (optional)",
		"Invitecodemsg": "Empfehlungscode ist erforderlich",
		"emailFormatter": "Bitte geben Sie die korrekte E-Mail-Adresse ein",
		"usernameFormatter": "Benutzername muss Buchstaben und Zahlen enthalten und der Bereich ist 6 <= Länge <= 20",
		"phoneFormatter": "Bitte geben Sie die 10-stellige Handynummer ein",
		"msgsuccess": "Erfolgreich registriert",
		"msgerr": "Registrierung fehlgeschlagen",
		"errorphonecheck": "Handynummer darf nicht leer sein!",
		"phonecheck": "Bitte geben Sie die 10-stellige Handynummer ein!",
		"imgcodecheck": "OTP-Code darf nicht leer sein!"
	},
	"login": {
		"Welcome_back": "Willkommen zurück",
		"tips": "Ich habe die Nutzungsbedingungen und die Datenschutzrichtlinie von {{this.$LogoName}} gelesen und stimme ihnen zu",
		"tips_1": "Ich habe die",
		"tips_2": "Nutzungsbedingungen",
		"tips_3": "und",
		"tips_4": "Datenschutzrichtlinie",
		"Continue_with_Google": "Weiter mit Google",
		"Continue_with_Apple": "Weiter mit Apple",
		"text": "Sie haben noch keinen Account?",
		"text2": "Sie haben bereits einen Account?",
		"Login": "Anmelden",
		"Register": "Registrieren",
		"username": "Benutzername oder Telefonnummer",
		"password": "Passwort",
		"imgcode": "Bildcode",
		"BackHome": "Zurück zur Startseite",
		"Forgetpwd": "Passwort vergessen"
	},
	"forgot": {
		"title": "Passwort vergessen",
		"msgsuccess": "Passwort zurückgesetzt",
		"newpassword": "Neues Passwort",
		"newpasswordmsg": "Neues Passwort ist erforderlich",
		"Continue": "Fortsetzen"
	},
	"home": {
		"Products&Services": "Produkte & Dienstleistungen",
		"content11": "Einladende Personen können Bonus proportional zum Transaktionsvolumen erhalten, das von ihren eingeladenen Personen generiert wird. Der maximale Bonus für eine gültige Einladung beträgt unbegrenzte USDT.",
		"Revolutionize_Trading": "Revolutionäre Handel",
		"Shape_the_Future": "Gestalten Sie die Zukunft",
		"content0": "Erleben Sie gemeinsam mit uns die neue Ära des Kryptowährungshandels!",
		"3_Reasons_for_Choosing": "3 Gründe zur Wahl",
		"Innovative_Trading_Features": "Innovative Handelsfunktionen",
		"content1": "Revolutionäre Handelserfahrung, die anders ist als alles, was derzeit auf dem Markt erhältlich ist. Entworfen, um Ihnen einen Wettbewerbsvorteil mit innovativen Handelsfunktionen, fortschrittlichen Sicherheitsmaßnahmen und blitzschnellen Transaktionsgeschwindigkeiten zu geben.",
		"Lower_Fees_and_Better_Rewards": "Niedrigere Gebühren und bessere Belohnungen",
		"content2": "Unsere Benutzeroberfläche macht den Handel für Anfänger einfach. Sie können kostenlos ein Konto erstellen und Krypto mit niedrigen Transaktionsgebühren aus unserem Marktplatz handeln. Außerdem können Sie bis zu 20% Transaktionsgebühren vom Austausch für alle Benutzer erhalten, die Sie einladen.",
		"Institutional-grade_Security": "Institutionelle Sicherheit",
		"content3": "befolgt einen der höchsten Sicherheitsstandards der Branche. Wir speichern 100% Ihrer Krypto in kalter Lagerung. Wir garantieren ein Reserveverhältnis von mindestens 1:1 unserer Kundengelder. Unser 300M USDT-Fonds bietet eine zusätzliche Schutzschicht gegen Sicherheitsbedrohungen.",
		"Start_investing_in_as_little_as_5_minutes": "Investieren Sie in nur 5 Minuten",
		"content4": "Melden Sie sich kostenlos bei {{this.$LogoNames.toUpperCase()}} an.",
		"content4_1": "Melden Sie sich kostenlos bei ",
		"content4_2": "an.",
		"Complete_google_authentication": "Google-Authentifizierung abschließen",
		"content5": "Geben Sie Ihre E-Mail-Adresse ein und überprüfen Sie sie, um loszulegen. Es fallen keine Gebühren für die Kontoeröffnung an.",
		"content7": "Schalten Sie die volle Funktionalität frei, indem Sie Ihre Google-Authentifizierung in weniger als 5 Minuten überprüfen.",
		"content8": "Finanzieren Sie Ihr {{this.$LogoNames.toUpperCase()}}-Konto sofort",
		"content8_1": "Finanzieren Sie Ihr ",
		"content8_2": "-Konto sofort",
		"content9": "Zahlen Sie USDT über das ausgewählte Netzwerk ein und greifen Sie sofort auf Ihre Mittel zu. Sie müssen nicht 3-5 Tage warten, um mit dem Handel zu beginnen.",
		"Start_building_your_crypto_portfolio": "Erstellen Sie Ihr Kryptowährungsportfolio",
		"Invitation_Program": "Einladungsprogramm",
		"Referral_Program": "Empfehlungsprogramm",
		"content10": "Schließen Sie eine beliebige gültige Einladung ab und erhalten Sie einen Einladungsbonus im Durchschnittswert von 10 USDT. Der maximale Bonus für eine einzige Person beträgt ",
		"TRADING_TASKS": "HANDELSAUFGABEN",
		"content12": "Benutzer erhalten Belohnungen in USDT beim Handel, verschiedene Handelsaufgaben für unterschiedliche Handelsmodi. Die Belohnungen werden sofort verteilt",
		"Trade_anytime_anywhere": "Handeln Sie jederzeit, überall",
		"content13": "USA, Inc. ist von der New York State Department of Financial Services für die Durchführung von Geschäftsaktivitäten mit virtuellen Währungen lizenziert.",
		"USA_Inc": "USA, Inc",
		"content6": "Laden Sie unsere App herunter, um rund um die Uhr in Verbindung zu bleiben",
		"Legal&Disclosures": "Rechtliche Hinweise und Offenlegungen",
		"Regulatory_License": "Regulierungslizenz",
		"AML/KYC_Policies": "AML/KYC-Richtlinien",
		"Privacy_Policy": "Datenschutzerklärung",
		"Terms_of_Use": "Nutzungsbedingungen",
		"Legal_Statement": "Rechtliche Erklärung",
		"Risk_Disclosure": "Risikohinweis",
		"About_Us": "Über uns",
		"Protection_Fund": "Schutzfonds",
		"Proof_of_Reserves": "Nachweis der Reserven",
		"Twitter": "Twitter",
		"Bitget_Token": "Bitget-Token",
		"Careers": "Karriere",
		"Company": "Unternehmen",
		"Support_Service": "Support-Service",
		"title": "Mit einer App öffnen",
		"titlebtn": "Herunterladen",
		"Balance": "Kontostand",
		"ID": "ID",
		"Recharge": "Aufladen",
		"Withdraw": "Abheben",
		"Bonus": "Bonus",
		"Checkin": "Einchecken",
		"Eventclosed": "Veranstaltung beendet",
		"Ranking": "Rangliste"
	},
	"Ranking": {
		"Today_s winning ranking": "Tägliches Gewinn-Ranking",
		"tips1": "Je höher der Gewinnbetrag = desto höher das Ranking!",
		"UserName": "Benutzername",
		"Winning Amount": "Gewinnbetrag",
		"Today_s rebate ranking": "Tägliches Rabatt-Ranking",
		"tips2": "Höhere Rabatte für Benutzer niedrigerer Ebenen = höheres Ranking!",
		"Rebate Amount": "Rabattbetrag",
		"Rebate ranking": "Top 20 Rabatt-Rankings"
	},
	"Checkin": {
		"Checkin": "Einchecken",
		"CheckinList": "Eincheckliste"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Regel",
		"Record": "Aufzeichnung",
		"Records": "Andar Bahar-Aufzeichnungen",
		"MyOrder": "Meine Bestellung",
		"more": "mehr",
		"Period": "Zeitraum",
		"Order": "Bestellung",
		"Buy": "Kaufen",
		"Result": "Ergebnis",
		"Profit": "Gewinn",
		"AndarBaharRule": "Andar Bahar-Regel",
		"CountDown": "Countdown",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Unentschieden",
		"result": "Ergebnis",
		"ok": "ok",
		"start": "starten",
		"Point": "Punkt",
		"ContractPoint": "Kontrahentenpunkt",
		"Number": "Nummer",
		"Delivery": "Lieferung",
		"Fee": "Gebühr",
		"Amount": "Menge"
	},
	"pokerOrder": {
		"OrderRecord": "Bestellaufzeichnung",
		"WatingOrder": "Wartende Bestellung",
		"HistoryOrder": "Bestellverlauf",
		"Nomoredata": "Keine weiteren Daten",
		"Buy": "Kaufen",
		"Result": "Ergebnis",
		"Time": "Zeit",
		"Amount": "Menge",
		"Fee": "Gebühr",
		"Profit": "Profit"
	},
	"pokerRecord": {
		"title": "Andar Bahar Aufzeichnung",
		"Period": "Zeitraum",
		"Result": "Ergebnis",
		"OpenTime": "Öffnungszeit",
		"Nomore": "Keine weiteren Daten"
	},
	"Google2FA": {
		"title": "Google Authenticator",
		"Authenticator_Instructions": "Anleitung zum Google Authenticator",
		"Authenticator_Status": "Status des Authenticators",
		"Google_Authenticator": "Google Authenticator",
		"Download_Google_Authenticator_App": "Lade die Google Authenticator App herunter",
		"App_Store": "App Store",
		"Google_Play": "Google Play",
		"Next": "Weiter",
		"Backup_key": "Backup-Schlüssel",
		"Backup_key_text": "Bitte speichere diesen Backup-Schlüssel an einem sicheren Ort. Dieser Schlüssel ermöglicht es dir, deinen Authenticator wiederherzustellen, falls du dein Handy verlierst. Andernfalls musst du den Kundenservice kontaktieren, um deinen Authenticator zurückzusetzen.",
		"Verifiaction": "Verifizierung",
		"Verifiaction_text": "Gib den Google-Verifizierungscode ein.",
		"Google_verification_code": "Google-Verifizierungscode",
		"contentlist1": "1. Notiere den 16-stelligen Backup-Schlüssel von +this.$LogoName+ und bewahre ihn an einem sicheren Ort auf.",
		"contentlist1_1": "1. Notiere den 16-stelligen Backup-Schlüssel von",
		"contentlist1_2": "und bewahre ihn an einem sicheren Ort auf.",
		"contentlist2": "2. Öffne den Google Authenticator.",
		"contentlist3": "3. Füge ein +this.$LogoName+ Konto hinzu und gib den kopierten 16-stelligen Schlüssel ein.",
		"contentlist3_1": "3. Füge ein",
		"contentlist3_2": "Konto hinzu und gib den kopierten 16-stelligen Schlüssel ein.",
		"contentlist4": "4. Du siehst jetzt einen 6-stelligen Verifizierungscode auf dem Bildschirm.",
		"contentlist5": "5. Kopiere den 6-stelligen Code und füge ihn in +this.$LogoName+ ein.",
		"contentlist5_1": "5. Kopiere den 6-stelligen Code und füge ihn in",
		"contentlist5_2": "ein.",
		"contentlist6": "6. Dein Authenticator ist nun erfolgreich verknüpft."
	},
	"win": {
		"Live_Price": "Live-Preis",
		"title1": "Die Investitionsergebnisse werden anhand der letzten Ziffer des Binance BTC/USDT-Spot-Handels berechnet, die der UTC-Zeit entspricht. Anleger können die Ergebnisse jederzeit auf Binance überprüfen, um die Fairness und Sicherheit der Investition zu gewährleisten.",
		"title": "Es gibt 2 Modi in der Investition",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "Jede Runde dauert 1 Minute. Der Lite-Modus bietet nur eine Investition in ungerade und gerade Zahlen. Das Ergebnis wird durch die letzte Ziffer des Schlusskurses des Binance BTC/USDT-Spot-Handels im Intervall von 1 Minute berechnet.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Jede Runde dauert 5 Minuten. Der Pro-Modus schaltet die vollständige Investitionsmethode von EPOCH EPT frei. Das Ergebnis wird durch die letzte Ziffer des Schlusskurses des Binance BTC/USDT-Spot-Handels im Intervall von 5 Minuten berechnet.",
		"5M_Mode_text_1": "Jede Runde dauert 5 Minuten. Der Pro-Modus schaltet die vollständige Investitionsmethode von",
		"5M_Mode_text_2": "frei. Das Ergebnis wird durch die letzte Ziffer des Schlusskurses des Binance BTC/USDT-Spot-Handels im Intervall von 5 Minuten berechnet.",
		"Investment_Guide": "Investitionsleitfaden",
		"Investment_Rules": "Investitionsregeln",
		"title2": "Wie lässt sich beweisen, dass das Ergebnis fair ist?",
		"Investment_Info": "Investitionsinformationen",
		"3M_Mode": "3M-Modus",
		"3M_Mode_text": "Jede Runde dauert 3 Minuten. Das Ergebnis wird durch die letzte Ziffer des Schlusskurses des Binance BTC/USDT-Spot-Handels im Intervall von 3 Minuten berechnet.",
		"Investment_return": "Investitionsrendite",
		"Number": "Nummer",
		"Profit_Invested_amount": "Gewinn: Investierter Betrag",
		"guide1": "Wählen Sie einen Zeitmodus.",
		"guide2": "Passen Sie Ihren Investitionsbetrag an.",
		"guide3": "Wählen Sie die Zahlen, in die Sie investieren möchten.",
		"guide4": "Überprüfen Sie Ihr Portfolio und klicken Sie auf Investieren.",
		"NEXT": "WEITER",
		"Invest_Amount": "Investitionsbetrag",
		"Total": "Gesamt",
		"INVEST": "INVESTIEREN",
		"Portfolio": "Portfolio",
		"Invest": "Investieren",
		"BINANCE": "Binance",
		"Bitcoin_Price": "Bitcoin-Preis",
		"InvestmentRecord": "Investitionsaufzeichnung",
		"Best_Assistant": "Investment Assistant",
		"last_100_rounds": "(letzten 100 Runden)",
		"What_do_these_data_mean": "Was bedeuten diese Daten?",
		"Missing": "Fehlt",
		"Missing_text": "Das bedeutet die Anzahl der Runden seit der letzten Runde, die diese Zahl produziert hat.",
		"Frequency": "Häufigkeit",
		"Frequency_text": "Bezieht sich auf die Anzahl der Zeiten, die die entsprechende Nummer in den letzten 100 Runden erscheint.",
		"Max_Continued": "Max. Fortlaufend",
		"Max_Continued_text": "Bezieht sich auf die längste aufeinanderfolgende Erscheinungsperiode der entsprechenden Zahl in den letzten 100 Runden.",
		"Rule": "Regel",
		"Trend": "Trend",
		"MyBalance": "Mein Kontostand",
		"JoinGreen": "Grün beitreten",
		"JoinRed": "Rot beitreten",
		"JoinViolet": "Violett beitreten",
		"Parity": "Parität",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "Rekord",
		"more": "mehr",
		"Period": "Zeitraum",
		"Price": "Preis",
		"Result": "Ergebnis",
		"My": "Meine",
		"Order": "Bestellung",
		"Buy": "Kaufen",
		"Profit": "Gewinn",
		"Green": "Grün",
		"Red": "Rot",
		"Violet": "Violett",
		"tips": "Die Transaktionsgebühr beträgt {bet_fee*100}% des Bestellbetrags",
		"tips1": "Die Transaktionsgebühr beträgt ",
		"tips2": "des Bestellbetrags",
		"OrderAmount": "Bestellmenge",
		"Quantity": "Menge",
		"TransactionFee": "Transaktionsgebühr",
		"TotalAmount": "Gesamtbetrag",
		"PresalePolicy": "Datenschutzrichtlinie",
		"Iagree": "Ich stimme zu",
		"err1": "Bitte stimmen Sie zuerst den Vorverkaufs-Management-Regeln zu."
	},
	"historyPeriods": {
		"Orderrecord": "Bestellverlauf",
		"record": "aufzeichnen"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Wartende Bestellung",
		"HistoryOrder": "Verlauf der Bestellungen"
	},
	"Activity": {
		"added_to_account_balance": "zum Kontostand hinzugefügt.",
		"contentText": "Wenn der Eingeladene zum ersten Mal eine Einzahlung tätigt, gilt dies als gültige Einladung. Der Einlader erhält für jede gültige Einladung einen Einladungsbonus. Die Einladungsbonusstufen reichen von {{aaaaaa}} USDT für 1 gültige Einladung bis {{aaaaaa}} USDT für 500 gültige Einladungen.",
		"contentText_1": "Wenn der Eingeladene zum ersten Mal eine Einzahlung tätigt, gilt dies als gültige Einladung. Der Einlader erhält für jede gültige Einladung einen Einladungsbonus. Die Einladungsbonusstufen reichen von",
		"contentText_2": "für 1 gültige Einladung bis",
		"contentText_3": "für 500 gültige Einladungen",
		"contentText_3_1": "für",
		"contentText_3_2": "gültige Einladungen",
		"contentText2": "Einlader teilen sich {{LV.1%}} Handelsgebühr von jedem Handelsvolumen des LV.1 Eingeladenen. Einlader teilen sich {{LV.2%}} Handelsgebühr von jedem Handelsvolumen des LV.2 Eingeladenen. Der maximale Bonus für das Handelsvolumen des Eingeladenen ist unbegrenzt USDT.",
		"contentText2_1": "Einlader teilen sich",
		"contentText2_2": "Handelsgebühr von jedem Handelsvolumen des LV.1 Eingeladenen. Einlader teilen sich",
		"contentText2_3": "Handelsgebühr von jedem Handelsvolumen des LV.2 Eingeladenen. Der maximale Bonus für das Handelsvolumen des Eingeladenen ist unbegrenzt USDT.",
		"My_Rewards_History": "Meine Belohnungshistorie",
		"Cumulative_Single_Number_Trading_Volumn": "Kumulatives Handelsvolumen für einzelne Nummern",
		"Cumulative_ODD_Number_Trading_Volumn": "Kumulatives Handelsvolumen für Ungerade Nummern",
		"Cumulative_EVEN_Number_Trading_Volumn": "Kumulatives Handelsvolumen für Gerade Nummern",
		"Cumulative_BIG_Number_Trading_Volumn": "Kumulatives Handelsvolumen für Große Nummern",
		"Cumulative_SMALL_Number_Trading_Volumn": "Kumulatives Handelsvolumen für Kleine Nummern",
		"Advanced_Tasks": "Fortgeschrittene Aufgaben",
		"Invite_Friends_and_Earn_Up_to": "Freunde einladen und bis zu verdienen",
		"Referral_Code": "Empfehlungscode",
		"Referral_Link": "Empfehlungslink",
		"INVITE_FRIENDS": "FREUNDE EINLADEN",
		"Complete_tasks_to_earn_up_to": "Erledigen Sie Aufgaben, um bis zu zu verdienen",
		"MY_REWARDS": "MEINE BELOHNUNGEN",
		"SHARE": "TEILEN",
		"Earn_with_your_friends_together": "Verdienen Sie gemeinsam mit Ihren Freunden",
		"Get_your_link": "Holen Sie sich Ihren Link",
		"Claim_your_rewards": "Fordern Sie Ihre Belohnungen an",
		"Earn_together": "Gemeinsam verdienen",
		"contentlist0": "Treten Sie {{gogobit}} bei und erhalten Sie Ihren Empfehlungslink. Benutzer, die sich über diesen Link anmelden, erhalten Belohnungen.",
		"contentlist0_1": "Treten Sie ",
		"contentlist0_2": " bei und erhalten Sie Ihren Empfehlungslink. Benutzer, die sich über diesen Link anmelden, erhalten Belohnungen.",
		"contentlist1": "Sie erhalten eine durchschnittliche Empfehlungsbelohnung von {{XX}} USDT, nachdem Ihre Freunde ihre erste Einzahlung getätigt und das erforderliche Handelsvolumen erreicht haben. Belohnungen können bis zu {{XX}} USDT betragen.",
		"contentlist1_1": "Sie erhalten eine durchschnittliche Empfehlungsbelohnung von ",
		"contentlist1_2": " USDT, nachdem Ihre Freunde ihre erste Einzahlung getätigt und das erforderliche Handelsvolumen erreicht haben. Belohnungen können bis zu ",
		"contentlist1_3": " USDT betragen.",
		"contentlist2": "Nachdem Ihr Freund ein Konto erstellt hat, kann er einen Handelsbonus von bis zu 5.050 USDT beanspruchen.",
		"Referral_Rewards": "Empfehlungsbelohnungen",
		"Detail_Rules": "Detailregeln",
		"Invitation_Bonus": "Einladungsbonus",
		"Up_to": "Bis zu",
		"content0": "Schließen Sie jede gültige Einladung ab und erhalten Sie einen Einladungsbonus mit einem durchschnittlichen Wert von 10 USDT. Der maximale Bonus für hundert Personen beträgt ",
		"content0_1": "Schließen Sie jede gültige Einladung ab und erhalten Sie einen Einladungsbonus mit einem durchschnittlichen Wert von ",
		"content0_2": "Der maximale Bonus für hundert Einladungen beträgt ",
		"Trading_Bonus": "Handelsbonus",
		"content1": "Einladende Benutzer können einen Bonus proportional zum Transaktionsvolumen erhalten, das von ihren Einladungen generiert wird. Der maximale Bonus für eine gültige Einladung beträgt",
		"My_Referral_History": "Meine Empfehlungshistorie",
		"Total_Invitees": "Gesamteingeladene",
		"Valid_Invitees": "Gültige Einladungen",
		"Cumulative_Invitees": "Einladungen insgesamt",
		"Trading_Volumn": "Handelsvolumen",
		"Daily_Tasks": "Tägliche Aufgaben",
		"Detail_Rules_title": "Tägliche Glücksziehung von 888 USDT",
		"Detail_Rules_title1": "1. Die Futures-Handelsdaten des Tages werden am nächsten Tag berechnet.",
		"Detail_Rules_title2": "2. Benutzer, die an einem Tag ein Futures-Handelsvolumen von 200.000 USDT oder mehr erzielen, haben die Möglichkeit, einmal an der Verlosung des 888-USDT-Preises teilzunehmen.",
		"CLAIM_NOW": "JETZT ANFORDERN",
		"INVEST_NOW": "JETZT INVESTIEREN",
		"title": "Bonus-Guthaben",
		"BonusBalance": "Bonus-Guthaben",
		"Withdrawtobalance": "Auf Guthaben übertragen",
		"BonusList": "Bonus-Liste",
		"Bonuswithdrawtobalance": "Bonus auf Guthaben übertragen",
		"WithdrawalAmount": "Abhebungsbetrag",
		"Pleaseentertheamount": "Bitte geben Sie den Betrag ein",
		"err1": "Der Abhebungsbetrag darf nicht 0 sein",
		"success": "Überweisung erfolgreich"
	},
	"Refer": {
		"title": "Mein Empfehlungslink",
		"ReferTotalBonus": "Empfohlene Gesamtprämie",
		"InviteFriends": "Freunde einladen",
		"Level1": "Ebene 1",
		"Level2": "Ebene 2",
		"TotalPeople": "Insgesamt eingeladene Nutzer",
		"Bonus": "Bonus",
		"Myinvitationlink": "Mein Einladungslink",
		"Rebate_description": "Rabattbeschreibung"
	},
	"Task": {
		"InviteBonusToday": "Einladungsbonus heute",
		"bonusrecord": "Bonusaufzeichnung",
		"hint1": "1. Die Daten werden alle 10-30 Minuten aktualisiert!",
		"hint2": "2. Es werden nur diejenigen gezählt, die sich in den letzten 3 Tagen registriert und heute die Aufladung abgeschlossen haben!",
		"hint2_1": "2. Es werden nur diejenigen gezählt, die sich in den letzten ",
		"hint2_2": " Tagen registriert und heute die Aufladung abgeschlossen haben!",
		"hint3": "3. Heute ist eine Mindesteinzahlung von 20 USDT erforderlich!",
		"hint3_1": "3. Heute ist eine Mindesteinzahlung von ",
		"hint3_2": " USDT erforderlich!",
		"hint4": "Anzahl der in den letzten drei Tagen eingeladenen Personen",
		"hint4_1": "Anzahl der in den letzten ",
		"hint4_2": " Tagen eingeladenen Personen",
		"hint5": "Anzahl der Personen, die heute die Aufladung abgeschlossen haben",
		"Invite": "Einladen",
		"friendstorecharge": "Freunde zum Aufladen",
		"Earn": "Verdienen",
		"Completed": "Abgeschlossen",
		"ExpireTime": "Verfallszeit"
	},
	"Invited": {
		"title": "Bonusaufzeichnung",
		"InviteTotalBonus": "Gesamtbonus einladen",
		"InviteTime": "Einladungszeit",
		"Bonus": "Bonus",
		"NoRecored": "Kein Eintrag vorhanden"
	},
	"mine": {
		"ProvacyPolicy": "Datenschutzrichtlinie",
		"JOIN US": "Treten Sie uns bei",
		"title": "Persönliches Zentrum",
		"InviteCode": "Einladungscode",
		"AccountBalance": "Kontoguthaben",
		"BonusBalance": "Bonusguthaben",
		"Wallet": "Wallet",
		"Bank": "Bank",
		"Invite": "Einladen",
		"Refer": "Weiterempfehlen",
		"CustomerService": "Kundenservice",
		"ComplaintsSuggestions": "Beschwerden & Vorschläge",
		"ChangePassword": "Passwort ändern",
		"Activity": "Aktivität",
		"PresalePolicy": "Datenschutzrichtlinie",
		"DownloadApp": "App herunterladen",
		"AppVersion": "App-Version",
		"Logout": "Abmelden",
		"Logouttips": "Sind Sie sicher, dass Sie sich abmelden möchten?"
	},
	"App": {
		"title": "App-Download",
		"DownloadforAndroid": "Herunterladen für Android",
		"DownloadforiOS": "Herunterladen für iOS"
	},
	"Presale": {
		"title": "Datenschutzerklärung"
	},
	"Account": {
		"title2": "Neues Passwort festlegen",
		"title2_text": "Legen Sie ein neues Passwort für Ihr Google-Konto fest. Sie können sich mit dem Passwort in Ihrem Google-Konto anmelden.",
		"title_text": "Sie versuchen, Ihr Passwort zurückzusetzen. Bitte halten Sie Ihren Google Authenticator bereit, um es zurückzusetzen.",
		"err1": "Die beiden neuen eingegebenen Passwörter sind unterschiedlich",
		"success2": "Passwort erfolgreich geändert",
		"title": "Passwort ändern",
		"OldPassword": "Altes Passwort",
		"OldPasswordmsg": "Altes Passwort ist erforderlich",
		"NewPassword": "Neues Passwort",
		"NewPasswordmsg": "Neues Passwort ist erforderlich",
		"RetypeNewPassword": "Neues Passwort erneut eingeben",
		"RetypeNewPasswordmsg": "Neues Passwort erneut eingeben",
		"Continue": "Weiter"
	},
	"Service": {
		"title": "Service",
		"CONTACTUS": "KONTAKTIEREN SIE UNS",
		"tips": "Der Kundenservice macht eine Pause!",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "Link"
	},
	"Bank": {
		"BankName": "Bank Name",
		"ChooseaCurrencyType": "Währungstyp auswählen",
		"SelectWithdrawalMethod": "Abhebungsmethode auswählen",
		"Newbankcard": "Neue Bankkarte",
		"Editbankcard": "Bankkarte bearbeiten",
		"title": "Meine Bank",
		"Cannotbeempty": "Darf nicht leer sein",
		"BankHolderName": "Kontoinhaber",
		"BankAccountNumber": "Bankkontonummer",
		"IFSCCode": "IFSC-Code",
		"Email": "E-Mail",
		"Phone": "Telefon",
		"OTPCode": "OTP-Code",
		"tips1": "Bitte verwenden Sie die Mobiltelefonnummer, mit der Sie sich registriert haben, um den OTP-Code zu erhalten",
		"tips2": "Zu Sicherheitsgründen kann die Bankkarte nur einmal täglich geändert werden",
		"Add": "Hinzufügen",
		"error1": "Kontoinhaber darf nicht leer sein!",
		"error2": "Bitte geben Sie den korrekten Kontoinhaber an!",
		"error3": "Bankname darf nicht leer sein!",
		"error4": "Bitte geben Sie den korrekten Banknamen an!",
		"error5": "Bankkontonummer darf nicht leer sein!",
		"error6": "Bitte geben Sie die korrekte Bankkontonummer an!",
		"error7": "IFSC-Code darf nicht leer sein!",
		"error8": "Bitte geben Sie den korrekten IFSC-Code an!",
		"error9": "E-Mail darf nicht leer sein!",
		"error10": "Bitte geben Sie eine korrekte E-Mail-Adresse ein!",
		"error11": "Telefon darf nicht leer sein!",
		"error12": "OTP-Code darf nicht leer sein!",
		"Successfully": "Bankkarte erfolgreich hinzugefügt"
	},
	"wallet": {
		"title": "Mein Portemonnaie",
		"MyBalance": "Mein Guthaben",
		"MyBank": "Meine Bank",
		"RechargeRecords": "Aufladungsverlauf",
		"WithdrawalRecords": "Abhebungsverlauf"
	},
	"Rechargepage": {
		"Tips": "Hinweis: Die erste Aufladungsbelohnung beträgt {{firstRPR*100}}% des Aufladebetrags",
		"Tips_1": "Hinweis: Die erste Aufladungsbelohnung beträgt",
		"Tips_2": "% des Aufladebetrags",
		"Reminder": "Erinnerung",
		"Reminder_1": "1. Bitte überweisen Sie keine anderen Währungen außer USDT auf die angegebene Adresse. Das Senden von nicht unterstützten Münzen oder Tokens an diese Adresse kann zu einem unwiederbringlichen Verlust führen.",
		"Reminder_2": "2. Der Mindestbetrag für eine Einzahlung beträgt 5 USDT.",
		"Reminder_2_1": "2. Der Mindestbetrag für eine Einzahlung beträgt ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Einzahlungen werden nach 15 Netzwerkbestätigungen gutgeschrieben.",
		"Minimum": "Minimum",
		"title": "Einzahlung",
		"History": "Einzahlungshistorie",
		"Mybalance": "Mein Kontostand",
		"RechargeAmount": "Einzahlungsbetrag",
		"RechargeAmountmsg": "Bitte geben Sie den Betrag ein",
		"tips1": "Der Zahlungskanal wird gewartet!",
		"tips2": "Bitte wählen Sie den Zahlungskanal",
		"PayNow": "Jetzt bezahlen",
		"err1": "Der Zahlungskanal wird gewartet",
		"err2": "Der Betrag darf nicht kleiner als ",
		"err3": "Bitte wählen Sie den Zahlungskanal",
		"Rechargemethod": "Einzahlungsmethode"
	},
	"Rechargerecord": {
		"title": "Aufladerekord",
		"WatingOrder": "Wartende Bestellung",
		"HistoryOrder": "Vergangene Bestellungen",
		"Rupee": "Dollar",
		"Status": "Status",
		"Time": "Zeit"
	},
	"withdrawal": {
		"Google_Code": "Google-Code",
		"Tips": "Tipps",
		"Tips_1": "Hinweis: Die Abhebungsgebühr für Beträge unter",
		"Tips_2": "USDT beträgt",
		"Tips_3": "USDT. Die Gebühr für Beträge von",
		"Tips_4": "USDT oder mehr beträgt",
		"Tips_5": "%.",
		"WalletAddress": "Wallet-Adresse",
		"Addwalletaddress": "Wallet-Adresse hinzufügen",
		"Network_fee": "Netzwerkgebühr",
		"Receive_amount": "Erhaltener Betrag",
		"Select Chains": "Wählen Sie Ketten",
		"Bankcard": "Bankkarte",
		"Nobankcardselected": "Keine Bankkarte hinzugefügt",
		"Withdrawalfee": "Abhebungsgebühr",
		"title": "Abheben",
		"Mybalance": "Mein Kontostand",
		"Withdrawablebalance": "Abhebbarer Betrag",
		"tips1": "Der Gesamtbetrag des Kaufs muss höher sein als {{codeAmount}}, um vollständig abgehoben zu werden.",
		"tips1-1": "Der Gesamtbetrag des Kaufs muss höher sein als",
		"tips1-2": "kann vollständig abgehoben werden.",
		"tips2": "Die maximale Anzahl an täglichen Abhebungen beträgt {{draw_num}}.",
		"tips2-1": "Die maximale Anzahl an täglichen Abhebungen beträgt",
		"tips2-2": "mal.",
		"tips3": "Der maximale tägliche Abhebungsbetrag beträgt",
		"tips4": "Der Mindestabhebungsbetrag beträgt ₹{{draw_min_money}} und der Höchstbetrag beträgt ₹{{draw_max_money}}",
		"tips4-1": "Der Mindestabhebungsbetrag beträgt",
		"tips4-2": "und der Höchstbetrag beträgt",
		"tips5": "Die Abhebungszeit ist {{draw_time}}, bitte heben Sie zum angegebenen Zeitpunkt ab.",
		"tips5-1": "Die Abhebungszeit ist",
		"tips5-2": "Bitte heben Sie zum angegebenen Zeitpunkt ab.",
		"tips6": "Bei einer Abhebung wird eine Abhebungsgebühr von {{draw_fee}}% berechnet.",
		"tips6-1": "Bei einer Abhebung wird eine Abhebungsgebühr von",
		"tips6-2": "berechnet.",
		"tips7": "Wenn Ihr Abhebungsbetrag weniger als oder gleich ₹{{solid_min_amount}} beträgt, wird eine feste Gebühr von ₹{{solid_feemoney}} erhoben.",
		"tips7-1": "Wenn Ihr Abhebungsbetrag weniger als oder gleich",
		"tips7-2": "beträgt, wird eine feste Gebühr von",
		"tips7-3": "erhoben.",
		"WithdrawalAmount": "Betrag zum Abheben",
		"WithdrawalAmountmsg": "Bitte geben Sie den Betrag ein",
		"Withdrawal": "Abheben",
		"WithdrawalHistory": "Abhebungshistorie",
		"Tipsmsg": "Die Abhebungsanfrage war erfolgreich. Warten Sie auf das Prüfungsergebnis!",
		"dangerMsg1": "Bitte fügen Sie zuerst Ihre Bankkarte hinzu",
		"dangerMsg2": "Der Abhebungsbetrag darf nicht weniger als",
		"dangerMsg3": "Der Abhebungsbetrag muss ein Vielfaches von 100 sein",
		"dangerMsg4": "Der Abhebungsbetrag darf nicht größer sein als",
		"dangerMsg5": "Der Abhebungsbetrag darf nicht größer sein als der abhebbare Kontostand",
		"dangerMsg6": "Bitte fügen Sie zuerst Ihre Bankkarte hinzu"
	},
	"withdrawalrecord": {
		"title": "Abhebungsverlauf",
		"WatingOrder": "Wartende Aufträge",
		"HistoryOrder": "Historie",
		"Rupee": "Dollar",
		"Status": "Status",
		"Time": "Zeit",
		"Nomore": "Keine weiteren Einträge vorhanden!"
	},
	"Version": {
		"title": "Version",
		"stableversion": "Stabile Version",
		"Alreadythelatestversion": "Bereits die neueste Version",
		"Newversionfound": "Neue Version gefunden",
		"DownloadUpdate": "Herunterladen und aktualisieren"
	},
	"Bonus": {
		"title": "Bonus Aktivität",
		"ReceiveBonus": "Bonus erhalten",
		"Hi": "Hallo",
		"tips1": "Herzlichen Glückwunsch zum Erhalt des Bonus",
		"tips2": "Sie müssen mehr als oder gleich",
		"tips3": "aufladen und dann wird der Bonus automatisch zu Ihrem Kontostand hinzugefügt",
		"Received": "Erhalten",
		"Back": "Zurück",
		"Rechargenow": "Jetzt aufladen"
	},
	"ComplaintsSuggestions": {
		"title": "Beschwerden & Vorschläge",
		"Answered": "Beantwortet",
		"AddNew": "Hinzufügen",
		"Time": "Zeit",
		"Type": "Typ",
		"WhatsApp": "WhatsApp",
		"Description": "Beschreibung",
		"Remark": "Anmerkung",
		"Waiting": "Warten",
		"Suggestion": "Vorschlag",
		"Consult": "Beratung",
		"RechargeProblem": "Aufladungsproblem",
		"WithdrawProblem": "Abhebungsproblem",
		"OrderProblem": "Bestellproblem",
		"Other": "Andere",
		"Describeyourproblems": "Beschreiben Sie Ihr Problem",
		"WhatsApptocontactyou": "WhatsApp zur Kontaktaufnahme",
		"submit": "einreichen",
		"err1": "WhatsApp darf nicht leer sein!",
		"err2": "Typ darf nicht leer sein!",
		"err3": "Beschreibung darf nicht leer sein!"
	},
	"slotMachine": {
		"title": "Fruchtmaschine",
		"Myblance": "Mein Guthaben",
		"Records": "Fruchtmaschinen-Aufzeichnungen",
		"titleRecord": "Aufzeichnungen"
	},
	"bankrecharge": {
		"Orderamount": "Bestellmenge",
		"Ordernumber": "Bestellnummer",
		"BankName": "Bankname",
		"BankAccount": "Bankkonto",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "Ihre Bankkartennummer",
		"Enterthebanktransactionordernumber": "Geben Sie die Bank-Transaktionsauftragsnummer ein",
		"submit": "einreichen",
		"Tips": "Tipps",
		"tips1": "1. Das System verarbeitet Ihre Bestellung.",
		"tips2": "2. Bitte wenden Sie sich an den Kundenservice, wenn das Konto lange nicht erreicht wurde.",
		"err1": "Bitte geben Sie Ihre Bankkartennummer ein",
		"err2": "Bitte geben Sie die Bankauftragsnummer ein"
	},
	"javaCode": {
		"200": "Erfolgreich",
		"20001": "Ungültiger Zugriffstoken",
		"20002": "Ungültiger Login-Token",
		"20003": "Fehlerhafte Telefonnummer",
		"20004": "Telefonnummer existiert bereits!",
		"20005": "Fehlerhafte E-Mail!",
		"20006": "E-Mail existiert bereits!",
		"20007": "Fehlerhaftes Passwort!",
		"20010": "Benutzername oder Passwort falsch!",
		"20011": "Bankkonto falsch!",
		"20012": "Fehlerhafter Bank IFSC, (Standard IFSC-Format) - Länge 11, die ersten vier IFSC und die 5. 0!",
		"20013": "Empfängernamenfehler, 5 < name <= 50!",
		"20021": "Konto existiert nicht!",
		"20022": "Konto ist eingefroren!",
		"20031": "Die Ebene ist auf 10 begrenzt!",
		"20032": "Aktualisierungsbeziehung fehlerhaft!",
		"20033": "Nicht genug Guthaben!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Registrierungsfehler!",
		"200082": "Konto wurde deaktiviert!",
		"200091": "Karten hinzufügen ist auf 10 begrenzt!",
		"200092": "Fehler bei der Eingabe des Anmeldepassworts zu oft, und das Konto ist deaktiviert!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Serverfehler!",
		"Handledataerror": "Bitte einloggen!",
		"-91": "Serverfehler!",
		"-92": "Datenverarbeitungsfehler!",
		"-1": "Systemfehler!",
		"-2": "Parameterfehler",
		"-3": "Keine Daten vorhanden",
		"-4": "Häufige Operationen in kurzer Zeit",
		"-5": "Systembeschäftigt, bitte später erneut versuchen!",
		"-6": "Zurzeit gibt es viele Benutzer, bitte versuchen Sie es erneut!",
		"-7": "Datenfehler!",
		"-8": "Google-Code-Fehler!",
		"-9": "Das System wird gewartet! Bitte versuchen Sie es später erneut!",
		"-10": "Speicherfehler!",
		"-11": "Löschfehler!",
		"-12": "Aktualisierungsfehler!",
		"-13": "Unerlaubte Operation!",
		"-14": "Betrieb fehlgeschlagen!",
		"-15": "Betrieb deaktiviert!",
		"-16": "Operation abgeschlossen!",
		"-17": "Existiert Fehler",
		"-18": "Existiert nicht!",
		"-19": "Abgelaufen",
		"-20": "Deaktiviert",
		"-21": "Fehlerhafter Verifizierungscode",
		"-22": "Der Änderungsintervall muss größer als 1 Tag sein",
		"-23": "Das neue Passwort darf nicht mit dem Originalpasswort identisch sein",
		"-100": "Benutzerdefinierter Fehler!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Gesamtkaufbetrag",
		"TotalRevenue": "Gesamteinnahmen",
		"AccountBalance": "Kontostand",
		"Income": "Einkommen",
		"Transaction": "Transaktion",
		"Myhold": "Mein Bestand",
		"ProductList": "Produktliste",
		"Investmentperiod": "Investitionszeitraum",
		"days": "Tage",
		"Buynow": "Jetzt kaufen",
		"Saletime": "Verkaufszeit",
		"Nomore": "Keine mehr",
		"ProductDetails": "Produktdetails",
		"ForSale": "Zum Verkauf",
		"Sale": "Verkauf",
		"SoldOut": "Ausverkauft",
		"ProductInformation": "Produktinformation",
		"InvestmentPeriod": "Investitionszeitraum",
		"TotalShare": "Gesamtanteile",
		"ExpectedReturn": "Erwartete Rendite",
		"SaleTime": "Verkaufszeit",
		"PurchaseConditionsAndRules": "Kaufbedingungen und Regeln",
		"MinimumPurchaseAmount": "Mindestkaufbetrag",
		"MaximumSalesLimit": "Maximales Verkaufslimit",
		"AccountBalanceMustBeGreaterThan": "Kontostand muss größer sein als",
		"DayBetIsGreaterThan": "Tageseinsatz ist größer als",
		"ProductDescription": "Produktbeschreibung",
		"MyHold": "Mein Bestand",
		"Ordernumber": "Bestellnummer",
		"PurchasingPrice": "Kaufpreis",
		"NoRecord": "Kein Eintrag",
		"Expiredate": "Verfallsdatum",
		"Purchasetime": "Kaufzeitpunkt",
		"redemption": "Rücknahme",
		"redemptionTips": "Frühe Rücknahme wird keine Einnahmen generieren. Möchten Sie die Rücknahme bestätigen?",
		"cancel": "abbrechen",
		"determine": "bestätigen",
		"Buyin": "Kaufen",
		"Buyamount": "Kaufbetrag",
		"BuyinTips": "Frühe Rücknahme wird keine Einnahmen generieren",
		"confirm": "bestätigen",
		"Minimumbuy": "Mindestkauf",
		"Maturity": "Fälligkeit",
		"EarlyRedemption": "Frühe Rücknahme",
		"Redemptiontime": "Rücknahmezeit",
		"Amountofincome": "Einkommensbetrag",
		"prompt": "Hinweis"
	},
	"HelpHa": {
		"Help": "Hilfe",
		"HelpCenter": "Hilfezentrum",
		"text": "Bei Fragen hier schauen"
	},
	"SetingHa": {
		"Seting": "Einstellungen",
		"SetingCenter": "Einstellungszentrum",
		"text": "Erhalten Sie mehr Belohnungen"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"index.index.gayb21": "Registrieren Sie sich, um Prämien zu erhalten:",
	"index.index.72y628": "Drei Gründe für EPOCH EPT",
	"index.index.z6022y": "EPOCH EPT folgt einem der höchsten Sicherheitsstandards der Branche. Wir lagern 100% unserer Kryptowährung in einem Kühllager. Wir garantieren, mindestens ein 1:1 Reserveverhältnis für Kundengelder bereitzustellen. Unser 300-Millionen-Fonds bietet zusätzlichen Schutz vor Sicherheitsbedrohungen.",
	"index.index.574982": "1. Jetzt kostenlos für EPOCH EPT registrieren",
	"index.index.qdi1g4": "2. Vollständige Google-Verifizierung",
	"index.index.weko28": "3. Laden Sie Ihr EPOCH EPT-Konto sofort auf",
	"index.index.sgkb35": "EPOCH EPT Einladungsprogramm",
	"index.index.2u17nf": "Das Ausfüllen einer gültigen Einladung kann einen Durchschnittswert von bis zu 5,59 USD verdienen Der maximale Bonus für hundert Eingeladene ist",
	"index.index.e8is4a": "EPOCH EPT-Empfehlungsprogramm",
	"index.index.47uevl": "EPOCH EPT-Transaktionsaufgabe",
	"index.index.2516gq": "EPOCH EPT, Inc. hat eine Lizenz für Geschäftstätigkeiten in virtueller Währung vom New York State Financial Services Department erhalten.",
	"invest.index.3aqi3d": "Kontostand:",
	"invest.index.ubk1j4": "Hör auf zu investieren!",
	"invest.prize1.4733u6": "Anlageportfolio:",
	"invest.prize1.t55a16": "Gesamtbetrag:",
	"login.login.09m52q": "Ich habe EPOCH EPT gelesen und bin damit einverstanden",
	"login.login.045csy": "Benutzername",
	"login.login.r7rbm5": "Bitte stimmen Sie der Vereinbarung zu!",
	"rewards.activityb.4nvrq9": "Wenn der Eingeladene seine erste Einzahlung abgeschlossen hat, gilt dies als gültige Einladung. Der Einladende erhält für jede gültige Einladung einen Einladungsbonus. Der Einladungsbonus kommt von 2 USD 1 gültige Einladung, 559 USD 100 gültige Einladungen.",
	"rewards.activityb.t10k6e": "Der Einladende teilt 20% der Transaktionsprovision für die Transaktionsgebühr jedes LV.1 geladenen Teilnehmers. Der Einladende teilt sich 10% der Transaktionsprovision für jeden LV. 2 Transaktionsgebühr des eingeladenen Teilnehmers. Der maximale Bonus für die Transaktionsgebühr Belohnung des Eingeladenen beträgt unbegrenzte US-Dollar.",
	"rewards.activityb.3d6850": "LV.1 Effektive Anzahl der eingeladenen Personen",
	"rewards.activityb.lvxo74": "LV.2 Effektive Anzahl der eingeladenen Personen",
	"rewards.activityb.zj26f9": "LV.1 Kumulierte Anzahl der eingeladenen Personen",
	"rewards.activityb.dkgxr5": "LV.2 Kumulierte Anzahl der eingeladenen Personen",
	"rewards.activityb.xjo077": "Empfehlung Belohnung:",
	"rewards.activityb.k8wv6v": "Treten Sie EPOCH EPT bei und erhalten Sie Ihren empfohlenen Link. Benutzer, die über diesen Link registriert sind, erhalten Prämien.",
	"rewards.activityb.4slpzg": "Nachdem Ihr Freund seine erste Einzahlung abgeschlossen hat und das erforderliche Transaktionsvolumen abgeschlossen hat, erhalten Sie eine Empfehlungsbelohnung mit einem durchschnittlichen Wert von $5,59. Die maximale Belohnung kann $559 erreichen.",
	"rewards.index.993jwg": "Erfolgreich kopiert",
	"rewards.index.7141ft": "Dieser Browser unterstützt kein automatisches Kopieren",
	"wallet.index.9uz2gn": "Netzbehandlungsgebühr 0 USDT",
	"wallet.index.ych3e1": "Tatsächlicher Eingang:",
	"wallet.index.3v558d": "Hinweis: Wenn die Auszahlung kleiner als $20 ist, beträgt die Auszahlungsbearbeitungsgebühr $1. Wenn die Auszahlung mehr als $20 ist, beträgt die Auszahlungsbearbeitungsgebühr 3%",
	"wallet.index.255e30": "1. Der maximale tägliche Auszahlungsbetrag beträgt $800000",
	"wallet.index.3g68m8": "2. Der minimale einzelne Auszahlungsbetrag ist $5 und der maximale einzelne Auszahlungsbetrag ist $800000",
	"wallet.index.c6x3ti": "3. Die Widerrufsfrist beträgt von 00:00 bis 23:59, bitte nehmen Sie die Auszahlung innerhalb der angegebenen Zeit vor",
	"wallet.index.h94rlg": "Mindestgrenze 5",
	"wallet.index.167862": "Bitte geben Sie die Wallet-Adresse ein!",
	"wallet.index.pi869h": "Bitte geben Sie den Auszahlungsbetrag ein!",
	"wallet.index.ysa85i": "Der maximale einzelne Auszahlungsbetrag ist",
	"components.changepass.68j9kh": "Sie versuchen, Ihr Passwort zurückzusetzen.",
	"components.changepass.ouxtp2": "Originalpasswort",
	"components.languagebox.8no849": "Japanisch",
	"components.languagebox.oif20b": "Traditionelles Chinesisch",
	"components.mycode.xyqr66": "Das Erledigen von Aufgaben kann bis zu 5000 USDT verdienen",
	"components.mymenu.277ss7": "Benutzer-ID:",
	"components.mymenu.74l443": "Anmeldezeit:",
	"components.myposter.36rw5e": "Empfehlungscode:",
	"components.mytabbar.zw2bh2": "Startseite",
	"components.mytabbar.8x6c28": "Trend",
	"components.mytabbar.28s7qr": "Investitionen",
	"components.mytabbar.4r75d9": "Belohnung",
	"components.xieyi.zqk7pe": "Schriftgröße: 16px; Schriftfamilie: Tahoma;",
	"components.help.6gpud8": "Es gibt zwei Anlagemodelle:",
	"components.help.5qrq36": "Jede Periode dauert eine Minute und bietet nur Einzel- oder Doppelinvestitionen. Das Ergebnis wird durch die letzte Ziffer des 1-minütigen K-Line Schlusskurses des Binance BTC/USDT Spothandels berechnet.",
	"components.help.isl7r1": "Jede Sitzung dauert fünf Minuten. Die professionelle Version schaltet alle Anlagemethoden von EPOCH EPT frei. Das Ergebnis wird durch die letzte Ziffer des 5-minütigen K-Line Schlusskurses des Binance BTC/USDT Spothandels berechnet.",
	"components.help.eai3nm": "Investitionsrendite:",
	"components.help.35xg82": "Einzelnummer:",
	"components.help.mpfg0s": "Erträge: Investitionsbetrag X 9.4",
	"components.help.v2ziib": "Ungerade Zahl:",
	"components.help.vfzxor": "Erträge: Investitionsbetrag X 1.94",
	"components.help.73168b": "Eve Nummer:",
	"components.help.76wgv1": "Große Zahl:",
	"components.help.05ed33": "Kleine Zahl:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Bitte geben Sie das ursprüngliche Passwort ein!",
	"components.changepass.xme6my": "Bitte geben Sie ein neues Passwort ein!",
	"components.changepass.u434f8": "Die beiden Passwörter stimmen nicht überein!",
	"components.changepass.574790": "Modifiziert erfolgreich!",
	"invest.index.9y8wg0": "Kein Preis gewinnen",
	"invest.index.ql5nc1": "Preise gewinnen",
	"login.login.76451t": "Börsenpreisdifferenz Ein quantitativer Handel!",
	"login.login.915p36": "Das Finanzmanagement steht weltweit an erster Stelle",
	"login.login.sen863": "Spannender, nachdem sich Hello einloggt",
	"login.login.nz6q9x": "Telefonanmeldung",
	"login.login.w7p592": "E-Mail-Anmeldung",
	"login.login.g9y91k": "Mobiltelefonnummer",
	"login.login.456gbb": "E-Mail-Nummer",
	"login.login.5ly8m8": "Land",
	"login.login.69h472": "Bitte wählen Sie ein Land",
	"login.login.i4p378": "Zahl",
	"login.login.fl82xx": "Beispiel:",
	"wallet.index.urkoub": "QR-Code aufladen",
	"wallet.index.25cocl": "Adresse kopieren",
	"wallet.index.t35fvg": "1. Auszahlungszeit: Bitte berechnen Sie die Regionalsteuer am Sonntag innerhalb von 24-Stunden von Montag bis Samstag!",
	"wallet.index.68xx61": "2. Globale Auszahlungen in Inlandswährung müssen spätestens innerhalb von 48 Stunden mit einer Auszahlungsgebühr von 8% (einschließlich Regionalsteuer) eingegangen sein.",
	"wallet.index.4ws3w9": "3. USDT-Auszahlung muss dem Konto spätestens innerhalb von 24 Stunden gutgeschrieben werden, mit einer Bearbeitungsgebühr von 2% (einschließlich Regionalsteuer)",
	"wallet.index.9b7wq8": "4. Der minimale Auszahlungsbetrag ist 12 USDT, und der maximale einzelne Auszahlungsbetrag ist 3000 USDT.",
	"wallet.index.23y92a": "Bitte beachten Sie, dass",
	"wallet.index.pl5473": "Mindestgrenze von 5",
	"wallet.index.nb3u72": "Bankkarteninformationen",
	"wallet.index.842so4": "Bankkartennummer hinzufügen",
	"wallet.index.86j717": "Zugehörige Bank hinzufügen",
	"wallet.index.763d7x": "Name der Bankkarte hinzufügen",
	"wallet.index.50j237": "Verbundene Bank",
	"wallet.index.hb6lun": "Name der Bankkarte",
	"wallet.index.62y2jc": "Bankkartennummer",
	"login.register.666hp4": "Quantitativer Handel mit Al-Preisdifferenz",
	"login.register.9298b8": "Freunde einladen",
	"login.register.375g14": "Neueinsteiger zum ersten Mal",
	"login.register.01922f": "Kann einmal durchgeführt werden",
	"login.register.527o4q": "Tägliche Einnahmen aus Chemikalien KI",
	"login.register.c563gt": "E-Mail Registrierung",
	"login.register.2ls856": "Fehler beim Format der Telefonnummer!",
	"login.register.4nu77e": "Fehler beim Formatieren der E-Mail-Nummer!",
	"lang.zh.pu623z": "1 gültige Einladung",
	"lang.zh.3hv80b": "Zahlen Sie USDT ein!",
	"lang.zh.47ttf6": "Warme Erinnerung: Die erste Aufladeprämie beträgt {{firstRPR*100}} % des Aufladebetrags",
	"lang.zh.9tli5f": "Tipp: Die Auszahlung beträgt weniger als",
	"lang.zh.62e882": "USD beträgt die Abhebungsgebühr",
	"lang.zh.m3818q": "USD, Auszahlung",
	"lang.zh.xs2f10": "Eine vorzeitige Rückzahlung führt zu keinem Gewinn",
	"invest.index.hl86r1": "Investitionsassistent",
	"wallet.index.645d70": "Bitte geben Sie den Namen Ihrer Bank ein",
	"wallet.index.n21787": "Bitte geben Sie den Benutzernamen der Bankkarte ein",
	"wallet.index.7s5j14": "Bitte geben Sie Ihre Bankkartennummer ein",
	"components.recharge.l576h7": "Saldoänderungen",
	"components.recharge.3x6844": "Bemerkungen",
	"components.appjump.u85nhy": "MoneyEasy ist zu meiner neuen Sucht geworden!",
	"components.appjump.543ht1": "Ich habe gerade 50€ auf MoneyEasy verdient, und endlich kann ich frei zusätzliches Geld jederzeit und überall verdienen. Ich kann an Umfragen teilnehmen, Spiele spielen oder neue Anwendungen testen und Belohnungen erhalten! Registrieren Sie sich jetzt, um einen Bonus von 50€ zu erhalten!",
	"components.appjump.8k17w7": "Teilen erfolgreich",
	"components.appjump.648wav": "Freigabe fehlgeschlagen:",
	"components.appjump.7c2hl4": "Der aktuelle Browser unterstützt keine Freigabefunktion",
	"components.fuwu.1t2h7i": "Inhalt",
	"components.fuwu.6t32g3": "Markthintergrund.",
	"components.fuwu.4c8u7w": "Intelligentes quantitatives Handelssystem",
	"components.fuwu.mw89jh": "Automatische Anfrage zum erneuten Senden",
	"components.fuwu.v5o60h": "Geboren.",
	"components.fuwu.12x554": "Sichtfeld",
	"components.fuwu.d8kj48": "Kernwerte.",
	"components.fuwu.sns568": "Konstruktionslogik",
	"components.fuwu.131mc5": "Grundprinzipien.",
	"components.fuwu.76i3z7": "Intelligentes Quantitatives Handelssystem Technologie Modell",
	"components.fuwu.253h82": "Blockchain-Technologie.",
	"components.fuwu.64z717": "Architektur",
	"components.fuwu.14n9v4": "Teammitglieder.",
	"components.fuwu.ewgac0": "Geschichte und Plan",
	"components.fuwu.yy18w5": "Zukünftiger Marktwert",
	"components.fuwu.h4l80q": "Rechtliche Erwägungen",
	"components.fuwu.1nvu89": "Haftungsausschlüsse",
	"components.fuwu.yvo758": "Markthintergrund",
	"components.fuwu.pgt1oc": "von",
	"components.fuwu.4vy2ub": "Jahr bis",
	"components.fuwu.sxj11f": "Zu Jahresbeginn lag die Wertentwicklung der Wertaktien deutlich hinter der der Wachstumsaktien zurück und verschärfte die langfristige Unterperformance der Wertfaktoren seit der globalen Finanzkrise. Einige Leute führen es auf makroökonomische Zinsfaktoren zurück&# 8212; Niedrige Zinsen, sinkende Anleihenrenditen oder flache Zinskurven.",
	"components.fuwu.ihu32d": "Nach der Untersuchung dieser Behauptungen stellte sich heraus, dass das Verhältnis zwischen Wert und Zins theoretisch recht vage und komplex ist. Aus empirischer Sicht besteht nur eine geringe Korrelation zwischen Zinsvariablen und Wertfaktoren unter bestimmten Parametern. Obwohl es signifikante Muster in aktuellen Datenproben gibt, die mit Veränderungen der Anleiherenditen oder Renditespreads zusammenhängen, ist die wirtschaftliche Bedeutung dieser Korrelationen gering und in anderen Stichproben inkonsistent.",
	"components.fuwu.ynfmrx": "Die Schlussfolgerung ist, dass die Wertentwicklung nicht durch Zinsfaktoren erklärt werden kann und dass Factor Timing Strategien, die auf Zinssignalen basieren, nur schwer effektiv umzusetzen sind. Um die bisher signifikante Investitionsrendite zurückzugewinnen, muss der digitalen Wirtschaft Aufmerksamkeit geschenkt werden.",
	"components.fuwu.041265": "Es wird verursacht durch",
	"components.fuwu.7ybglf": "Zusammen mit zehn Mitarbeitern",
	"components.fuwu.cv8q4k": "Ein in New York gegründetes Hedgefonds-Unternehmen, das Kunden professionelle Problemlösungen, wissenschaftliche Forschung und innovative Methoden bietet.",
	"components.fuwu.u18kd5": "In der Vergangenheit",
	"components.fuwu.345nr1": "Im Laufe der Jahre,",
	"components.fuwu.uux4kt": "Wir haben mehrere internationale Aktienstrategien und Finanzinvestitionsprodukte eingeführt, um Anlegern zu helfen, eine stabile Wertsteigerung am Finanzmarkt zu erreichen. Reichweite",
	"components.fuwu.2k2868": "Jahr,",
	"components.fuwu.dfo204": "Vermögensgröße, die von Hedgefonds und Finanzprodukten verwaltet wird",
	"components.fuwu.rer5h5": "Überschreitung",
	"components.fuwu.y62673": "Milliarden US-Dollar ab",
	"components.fuwu.mdq3mn": "Am Ende des Jahres,",
	"components.fuwu.944x0p": "Der Umfang der weltweit verwalteten Fonds übersteigt",
	"components.fuwu.64sd2z": "USD100mn",
	"components.fuwu.i2bs5g": "zusammen mit",
	"components.fuwu.34l46q": "Rechenleistung, Big Data, Internet der Dinge",
	"components.fuwu.t8n5nh": "Die exponentielle Entwicklung von Bereichen wie Cloud Computing und künstlicher Intelligenz hat begonnen, ihre Macht zu entfesseln, was zu Wohlstand in der Industrie führt.",
	"components.fuwu.5765y1": "Fegte das Internet in nur wenigen Monaten. Seine Anwendungen in Data Mining, Content Insights, intelligenter Diagnose, prädiktiver Analyse und intelligenter Evolution werden allmählich den Menschen bekannt. Viele Menschen wissen jedoch nicht, dass Anwendungen der künstlichen Intelligenz, wie Gesichtserkennung und Sprachinteraktionstechnologie, in den Alltag der Menschen integriert wurden.",
	"components.fuwu.138tt4": "Nach Angaben der Statistik",
	"components.fuwu.jk42p7": "Im Jahr stieg die Zahl der globalen Start-ups für künstliche Intelligenz",
	"components.fuwu.lu0nu3": "Wanjia, der Gesamtbetrag der Finanzierung hat erreicht",
	"components.fuwu.edrz45": "Trillion Dollar. Viele Technologieunternehmen haben Abteilungen für künstliche Intelligenz eingerichtet, was darauf hindeutet, dass die Ära der künstlichen Intelligenz angebrochen ist. In der Vergangenheit",
	"components.fuwu.0ty944": "Im Jahr,",
	"components.fuwu.267zhq": "Immer an der Spitze der Cross-Investment-Innovation in Technologie, Daten und Behavioral Finance. Es erforscht und treibt den Markt kontinuierlich an und wendet diese Erkenntnisse auf Kundenanlageportfolios an, um globalen Kunden zu einem stabilen Vermögenswachstum zu verhelfen.",
	"components.fuwu.768x8x": "Mit Cloud Computing, Edge Computing, Big Data",
	"components.fuwu.8hhz36": "Mit der rasanten Entwicklung der künstlichen Intelligenz,",
	"components.fuwu.o32734": "Gründer von",
	"components.fuwu.8hhu5s": "Erkennen, dass das Produkt mit der Zeit konsistent sein muss",
	"components.fuwu.jwkv4j": "Andernfalls werden sie vom Markt eliminiert. Neben der Aufrechterhaltung bestehender Aktien- und Finanzgeschäfte,",
	"components.fuwu.4m7d39": "zu",
	"components.fuwu.n48x5n": "Der Einsatz von Produkten der künstlichen Intelligenz begann im Jahr. Durch die Verwendung künstlicher Intelligenz-Technologie, um die Effizienz zu verbessern, Anwendungstrends vorherzusagen, verschiedene Daten zu integrieren und Wellenfrequenzhandelsmodelle zu nutzen,",
	"components.fuwu.4ukw28": "In der Lage zu sein, eine durchschnittliche umfassende Produktrendite innerhalb",
	"components.fuwu.ed1635": "Links und rechts.",
	"components.fuwu.o0516q": "Vertiefen Sie sich tief in die Spitze der künstlichen Intelligenz und streben Sie danach, Funktionen wie Data Mining, intelligente Analyse und intelligente Evolution in Finanzprodukte zu integrieren, um",
	"components.fuwu.7ognk6": "In der Lage zu sein, den Finanzmarkt genauer, einfacher und stetig voranzutreiben. Nach den unermüdlichen Bemühungen des Teams,",
	"components.fuwu.9ye2y0": "Das intelligente quantitative Handelssystem wurde abgeschlossen und erfolgreich zwei Jahre Tests bestanden. Wir glauben, es ist an der Zeit, es auf den Markt zu bringen.",
	"components.fuwu.hrwn57": "Das intelligente quantitative Handelssystem ist ein digitales Finanzprodukt, das auf der Blockchain nach seinen Börsenfinanzprodukten eingesetzt wird. Verlassen Sie sich auf die digitale Wirtschaft und künstliche Intelligenz und verwalten Sie die digitalen Assets der Nutzer effizienter, genauer, stabiler, flexibler, kostengünstiger und nachvollziehbarer. Dies ist eine neuartige Kombination aus Wirtschaft, Behavioral Finance, Daten und Technologie, die eine effektivere Wertschätzung des Kundenvermögens ermöglicht.",
	"components.fuwu.jb545g": "Jahr nach",
	"components.fuwu.31h712": "Ein Angestellter wurde in New York gegründet. Es ist ein Hedgefonds, der professionelle akademische Forschung und innovative Methoden zur Lösung von Kundenproblemen nutzt. In der Vergangenheit",
	"components.fuwu.623c56": "Mehrere internationale Aktienstrategien und Finanzinvestitionsprodukte wurden eingeführt, um Investoren zu einem stabilen Wachstum am Finanzmarkt zu verhelfen. Reichweite",
	"components.fuwu.o8270p": "Managed Hedge Fund und Vermögenswerte von Finanzprodukten",
	"components.fuwu.j53w1u": "USD100mn Ab",
	"components.fuwu.4fogox": "Management über",
	"components.fuwu.h0al2w": "Ein Milliardenfonds dank seiner enormen globalen Wirkung",
	"components.fuwu.202g6o": "Schallleistung.",
	"components.fuwu.85g7o1": "Das erste internationale Büro wurde in Australien eröffnet.",
	"components.fuwu.2ubf7n": "In Großbritannien wurde ein Büro eingerichtet.",
	"components.fuwu.ud1j47": "Das Asset Management Research Institute wurde an der London Business School gegründet, um die Asset Management Forschung zu fördern.",
	"components.fuwu.z45lgv": "Wir haben ein Büro in Hongkong eröffnet und unser Geschäft in Asien ausgebaut.",
	"components.fuwu.4582f8": "In Bangalore, Indien, wurde ein Ingenieurszentrum eingerichtet.",
	"components.fuwu.m00yuh": "In Deutschland wurde ein neues Büro gegründet, das seinen globalen Einfluss weiter ausbaut. bleiben",
	"components.fuwu.3r7069": "Jahr.",
	"components.fuwu.jwwo48": "Es wird anerkannt.",
	"components.fuwu.jnvf5m": "Jahr bis",
	"components.fuwu.l0bqqt": "Beste Pensions- und Investmentfonds Management Gesellschaft des Jahres.",
	"components.fuwu.e777k5": "haben",
	"components.fuwu.j0u235": "Ein Team von Partnern und fast tausend Mitarbeitern,",
	"components.fuwu.8d8hnh": "Ein Forscherteam mit langjähriger Forschungserfahrung, und hat eine große Anzahl von Artikeln in Top-wissenschaftlichen Zeitschriften veröffentlicht. Das Unternehmen wird solide praktische Erfahrungen mit quantitativen Instrumenten kombinieren, um grundlegende Informationen zu handhaben und Risiken zu managen. Die Einführung eines systematischen forschungsorientierten Ansatzes zur Verwaltung alternativer und traditioneller Strategien ist ein seltener Ansatz. wissenschaftliche Forschung",
	"components.fuwu.y17m3k": "Quantitative Investitionen; Dual Drive Company.",
	"components.fuwu.59t85m": "Geburt",
	"components.fuwu.xd3f51": "seit",
	"components.fuwu.p1o1m9": "Seit Anfang dieses Jahres,",
	"components.fuwu.s6631u": "Ich habe über die Anwendung künstlicher Intelligenz im Finanzbereich nachgedacht, um die Schmerzpunkte manueller Transaktionen zu lösen.",
	"components.fuwu.654rrg": "Formal initiiert die Gründung des Quantitative Trading System Research Centers Künstliche Intelligenz und erstellt den ersten Entwurf",
	"components.fuwu.3w3i3q": "Weißbuch über intelligente quantitative Handelssysteme. Erforschen und lösen Sie Probleme im Zusammenhang mit künstlicher Intelligenz und Handelsmodellen, etablieren Sie eine künstliche Intelligenz-Computing-Plattform und konstruieren Sie ein quantitatives künstliches Intelligenz-Handelssystem.",
	"components.fuwu.315354": "Erfolgreich angewandte künstliche Intelligenz im Handel und entwickelte",
	"components.fuwu.oef1gv": "Intelligentes quantitatives Handelssystem. Ausgehend von der Datenerfassung nutzt das System künstliche Intelligenz, um Marktdaten zu erfassen, einschließlich historischer Preise, Handelsvolumen, Marktstimmung usw. Analysieren und erforschen Sie dann den Markt, legen Sie mehrere Handelsstrategien fest, etablieren Sie verschiedene Handelsmodelle und generieren Sie entsprechende Handelssignale basierend auf Handelsstrategien und voreingestellten Risikokontrollparametern. Das System funktioniert durch automatischen Handel.",
	"components.fuwu.kinmrh": "Das intelligente quantitative Handelssystem ist eine intelligente Handelsplattform, die gemeinsam von Hongkong-Fonds und führenden Blockchain-Technologie-Teams in Deutschland entwickelt und validiert wurde. Es nutzt fortschrittliche Computertechnologien wie künstliche Intelligenz, Blockchain und Big Data, um eine dezentrale Verwaltung von Finanztransaktionen zu erreichen.",
	"components.fuwu.1khwn2": "Der Kerntechnologiealgorithmus des intelligenten quantitativen Handelssystems nutzt Blockchain-Technologie, um globale dezentrale Finanztransaktionen ohne geografische Einschränkungen zu erreichen.",
	"components.fuwu.e5g3fa": "Das intelligente quantitative Handelssystem wird auch als Grundlage für das zukünftige Ökosystem der digitalen Wirtschaft dienen",
	"components.fuwu.7ftmw3": "&#8220; Assistent;, Stellen Sie quantitative Handelslösungen für globale Finanztransaktionen bereit und erreichen Sie eine schnelle Bereitstellung effizienter Blockchain-Anwendungen. Es vereinfacht die Transaktion und den Austausch von Vermögenswerten und digitalen Vermögenswerten. Es sieht auch umfangreiche Finanzanwendungen vor",
	"components.fuwu.4646q8": "Schlüsselschnittstellen, die verschiedene Anwendungsszenarien verbinden und eine nahtlose Integration digitaler Assets mit verschiedenen Industrieanwendungen erreichen. Dies bietet Anlegern ein sicheres, schnelles, reibungsloses und stabiles Anlageumfeld. bleiben",
	"components.fuwu.kh359j": "Unter dem intelligenten quantitativen Handelssystem wird die Kraft der künstlichen Intelligenz genutzt, um ein Finanzanwendungsdienst-Ökosystem mit Superintelligenz und superschnellen Fähigkeiten aufzubauen. Dies wird es mehr Menschen ermöglichen, die Super-Erfahrung des digitalen Zeitalters zu genießen, mit klaren Richtungen für Geschwindigkeit, Sicherheit, Stabilität und Dezentralisierung. Ziel ist es, ein neues Ökosystem zu schaffen, das künstliche Intelligenz auf die Digitalisierung von Assets anwendet und Freiheit und Innovation bietet.",
	"components.fuwu.wsp5m5": "Die Vision eines intelligenten quantitativen Handelssystems basiert auf Investment Banking Thinking und Venture Capital Modellen. Mit künstlicher Intelligenz als Kern und auf Big Data setzen wir auf kostengünstige, sichere und zuverlässige Anlageprodukte, um langfristig stabile Renditen zu erzielen.",
	"components.fuwu.861380": "Wertfaktoren bringen attraktive langfristige Renditen, können aber auch durch herausfordernde Perioden beeinflusst werden, die für Anleger schmerzhaft sein können. Für diejenigen, die ausharren, ist die anschließende wirtschaftliche Erholung jedoch rentabel.",
	"components.fuwu.igh8q8": "Intelligente quantitative Handelssysteme können Anlegern helfen, emotionale Belastungen in schwierigen Zeiten zu überwinden und langfristige Wertrenditen zu erzielen.",
	"components.fuwu.u7cy1f": "Bewertung von",
	"components.fuwu.502xa6": "Die seit Jahresbeginn eingetretenen Veränderungen haben in vielen Anlageklassen keine wesentlichen Veränderungen der erwarteten Renditen zur Folge.",
	"components.fuwu.yg0781": "Intelligente quantitative Handelssysteme können diese Probleme effektiv lösen.",
	"components.fuwu.k7s41z": "Big Data zeigt, dass der Nettoverlust durch die Implementierung einer systematischen Strategie der direkten Indexerfassung in den ersten Jahren nach der Implementierung allmählich abnimmt. Diese Strategien weisen auch stark diversifizierte Nettoverlustsergebnisse auf. Daher durch",
	"components.fuwu.pw7jg1": "Die Factor Investment Long Short Strategie, die von intelligenten quantitativen Handelssystemen angetrieben wird, kann die Direct Index Strategie deutlich übertreffen.",
	"components.fuwu.9gya07": "Die erwartete Prämie für Investitionen in Schwellen- und Industriemärkte liegt in der Vergangenheit",
	"components.fuwu.yfiuur": "Das obere Ende des jährlich beobachteten Intervalls. Gleichzeitig sinken in der Geschichte viele Risiken, die mit Schwellenländern verbunden sind, seit langem. Anleger haben genügend Gründe, ihre Allokation in Schwellenländern, einschließlich digitaler Vermögenswerte, zu erhöhen.",
	"components.fuwu.j8nq18": "Kernwerte",
	"components.fuwu.40fdvu": "In den letzten zwanzig Jahren,",
	"components.fuwu.w5kk35": "Wir erforschen kontinuierlich die treibenden Faktoren des Marktes und wie diese auf Kundenanlageportfolios in den Bereichen Wirtschaft, Behavioral Finance, Daten und Technologie angewendet werden können. Mit dem Aufstieg von Big Data und künstlicher Intelligenz,",
	"components.fuwu.pg3e4s": "Handelsstrategien und -modelle werden immer komplexer.",
	"components.fuwu.kcjcom": "Das intelligente quantitative Handelssystem ist in die Testphase eingetreten und ist nun offiziell über das Büro in Hongkong für die Welt geöffnet.",
	"components.fuwu.n21shs": "Das intelligente quantitative Handelssystem basiert auf Investment Banking Thinking und Venture Capital Modellen mit künstlicher Intelligenz als Kern. Sie stützt sich auf Big Data, um quantitative Handelsmodelle zu etablieren, mit dem Ziel, Anlegern kostengünstige und sichere Anlageprodukte sowie stabile langfristige Renditen zu bieten.",
	"components.fuwu.0l7u49": "Nutzung der Blockchain-Technologie,",
	"components.fuwu.lgcse5": "Das intelligente quantitative Handelssystem hat ein verteiltes, kostengünstiges, datenschutzerhaltendes Hochleistungsrechennetzwerk entwickelt und bietet umfassende Finanzprodukte.",
	"components.fuwu.0t263a": "Die Hochleistungsrechenknoten bieten leistungsstarke Rechenfunktionen für Transaktionsmodelle, einschließlich künstlicher Intelligenz Training und Inferenz, Data Scraping und Blockchain Zero Knowledge Computing.",
	"components.fuwu.f5s17o": "Hochleistungsrechenknoten können verschiedene Formen annehmen, einschließlich der Ausführung in großen Maßstäben",
	"components.fuwu.u2615w": "oder",
	"components.fuwu.a5en87": "Voll funktionsfähige Knoten auf Serverclustern",
	"components.fuwu.d4f145": "Permanenter Knoten",
	"components.fuwu.29o1r5": "sowie von kleinen und mittleren Unternehmen und Einzelpersonen",
	"components.fuwu.r54iix": "Leerlauf",
	"components.fuwu.5zx75g": "Server Computing Knoten.",
	"components.fuwu.6156mg": "Das intelligente quantitative Handelssystem sorgt für den sicheren und stabilen Betrieb des Systems und ermöglicht es allen Anlegern, davon zu profitieren.",
	"components.fuwu.p54y10": "bleiben",
	"components.fuwu.o69yz3": "Im Prozess des intelligenten quantitativen Handels sind die Verarbeitungs- und Lernfähigkeiten künstlicher Intelligenz entscheidend. Es macht Handelssysteme intelligenter, genauer und automatisierter und ermöglicht effektivere Handelsentscheidungen und Risikomanagement.",
	"components.fuwu.h1r7s5": "Die Gestaltungsprinzipien eines intelligenten quantitativen Handelssystems sind wie folgt:",
	"components.fuwu.x27379": "Grundsatz der Skalierbarkeit",
	"components.fuwu.rhqg19": "Die verschiedenen Module eines intelligenten quantitativen Handelssystems sollten lose gekoppelt sein, so dass neue Module problemlos hinzugefügt werden können, ohne die Schnittstellen anderer Module zu verändern.",
	"components.fuwu.c7uqex": "Feedback von Kunden zu Produkten",
	"components.fuwu.46e7x1": "Der Zugang zu intelligenten quantitativen Handelssystemen ist volatil. Wenn eine große Anzahl von Benutzern auf einen einzelnen Knoten zugreift, kann dies zu Geschäftsabstürzen führen. Daher sollte der Container des Knotens automatisch bereitgestellt werden können, was eine schnelle horizontale Ausdehnung unter Druck von Benutzeranfragen ermöglicht.",
	"components.fuwu.xc8u4k": "Datenschutzgrundsatz",
	"components.fuwu.1n531t": "Anleger, Rechenknoten, Datenquellen und andere Komponenten des intelligenten quantitativen Handelssystems genießen Datenschutzmaßnahmen.",
	"components.fuwu.ed16w3": "Grundprinzipien",
	"components.fuwu.7h553b": "Was ist",
	"components.fuwu.k4l2md": "Intelligenter quantitativer Handel",
	"components.fuwu.7nve0q": "Intelligenter quantitativer Handel bezieht sich auf die Verwendung von künstlicher Intelligenz Technologie und Algorithmen, um Anlageentscheidungen automatisch auszuführen, um die Handelseffizienz zu maximieren. Es handelt sich um eine Hochfrequenzhandelsstrategie, die auf Technologien wie Big Data, Machine Learning und Deep Learning basiert. Durch schnelle Analyse von Marktdaten und automatisierte Programmierung. Identifizieren Sie die besten Handelsmöglichkeiten und führen Sie Trades aus",
	"components.fuwu.g21385": "Der Vorteil des intelligenten quantitativen Handels liegt in seiner Fähigkeit, Marktdaten schnell zu analysieren, Handelsentscheidungen automatisch auszuführen und menschliche Eingriffe und Fehleinschätzungen zu vermeiden. Darüber hinaus kann es Transaktionen auf einem breiteren Markt und größeren Maßstab optimieren und erweitern. Darüber hinaus,",
	"components.fuwu.v75es3": "Das intelligente Handelssystem wird kontinuierlich durch künstliche Intelligenz lernen. Durch langfristige Datenschulungen und Modelloptimierungen können Sie bessere Handelsmodelle erstellen, um sich an Marktveränderungen anzupassen und Benutzern zu helfen, langfristige Rentabilität zu erzielen.",
	"components.fuwu.rcm80u": "Der Transaktionsprozess ist",
	"components.fuwu.2x15l9": "In intelligenten quantitativen Handelssystemen wird künstliche Intelligenz eingesetzt, um autonome Entscheidungsfähigkeit in intelligenten Transaktionen zu erreichen. Es kann Transaktionen basierend auf voreingestellten Handelsstrategien und -parametern automatisch ausführen und basierend auf Marktbedingungen und historischen Daten intelligent analysieren und vorhersagen, um intelligentere und autonomere Handelsentscheidungen zu erzielen. Insbesondere",
	"components.fuwu.xe1844": "Das intelligente quantitative Handelssystem nutzt eine große Menge historischer Daten und Marktinformationen für Data Mining und maschinelles Lernen. Es konstruiert Handelsmodelle und -algorithmen, überwacht und analysiert den Markt in Echtzeit, optimiert und passt Handelssignale und -strategien an, um effektivere Handelsentscheidungen und Risikomanagement zu erzielen. Darüber hinaus verbessern und verbessern KI-gesteuerte quantitative Handelssysteme kontinuierlich ihre Handelsstrategien und Algorithmen durch KI-Technologie und selbstlernende Fähigkeiten und erzielen intelligentere und autonomere Handelsentscheidungen. Daher,",
	"components.fuwu.8jr1h7": "&#8220; Intelligent; Es repräsentiert die Intelligenz und autonome Entscheidungsfähigkeit quantitativer Handelssysteme künstlicher Intelligenz. Es kann effizientere, genauere und höhere Anlagerenditen für Investoren erzielen. Und automatisierte Handelsentscheidungen und Risikomanagement bringen",
	"components.fuwu.62r19x": "Im intelligenten quantitativen Handel,",
	"components.fuwu.1v4571": "&#8220; Quantifizierung; Stellt die Quantifizierung und Analyse von Marktdaten und Handelsstrategien dar. Quantitativer Handel ist ein digitaler Algorithmus, der auf der Sammlung, Analyse und Verarbeitung großer Datenmengen basiert und Marktinformationen und Handelsstrategien in automatische Entscheidungsfindung und Handel umwandelt. Insbesondere",
	"components.fuwu.35fgx0": "Das intelligente quantitative Handelssystem sammelt eine große Menge von Marktdaten, einschließlich historischer Preise, Handelsvolumen, Marktstimmung usw., um den Markt zu analysieren und zu erforschen. Es konstruiert verschiedene Handelsstrategien basierend auf diesen Daten. Durch die Nutzung dieser Handelsstrategien und vordefinierten Risikokontrollparameter generiert das System entsprechende Handelssignale und führt den automatischen Handel aus. In diesem Prozess machen die Quantifizierung und Analyse von Daten das Handelssystem intelligenter, genauer und automatisierter, wodurch effektivere Handelsentscheidungen und Risikomanagement erzielt werden. Daher,",
	"components.fuwu.ce4mr4": "&#8220; Quantifizierung; Stellt",
	"components.fuwu.cfq4pv": "Die Kerntechnologie des quantitativen Handels. Es ist ein wichtiges Mittel zur Digitalisierung von Marktinformationen und Handelsstrategien, die schnellere, genauere und automatisierte Handelsentscheidungen und Risikokontrolle erreichen können.",
	"components.fuwu.6b0w31": "Vorteil",
	"components.fuwu.7q677y": "Effizienz",
	"components.fuwu.624ufa": "Das intelligente quantitative Handelssystem führt automatisch Handelsstrategien aus, die Handelschancen schnell und genau erfassen und menschliche Fehler und emotionale Störungen vermeiden können.",
	"components.fuwu.6k6y74": "genau",
	"components.fuwu.y23qq8": "Das intelligente quantitative Handelssystem nutzt eine große Menge historischer Daten und Marktinformationen für Datenanalyse und Modellvorhersage und verbessert die Genauigkeit und Zuverlässigkeit von Handelsentscheidungen.",
	"components.fuwu.s11cqu": "Stabilität",
	"components.fuwu.3q6d66": "Das intelligente quantitative Handelssystem implementiert ein strenges Risikomanagement und Handelskontrolle, um die Stabilität und langfristige Renditen von Anlageportfolios zu gewährleisten.",
	"components.fuwu.w386vn": "Flexibilität",
	"components.fuwu.530y3q": "Das intelligente quantitative Handelssystem kann sich schnell an Marktveränderungen und neue Handelsmöglichkeiten anpassen und gleichzeitig maßgeschneiderte Handelsstrategien und -parameter basierend auf den Vorlieben der Anleger ermöglichen.",
	"components.fuwu.ph3l81": "Rückverfolgbarkeit",
	"components.fuwu.7ap85x": "Das intelligente quantitative Handelssystem erfasst und verfolgt die Details und historischen Daten jeder Transaktion und erleichtert den Anlegern die anschließende Analyse und Optimierung.",
	"components.fuwu.v2jkcm": "Ökosystem",
	"components.fuwu.ics30f": "Intelligentes quantitatives Handelssystem",
	"components.fuwu.0nui8m": "Als Kern hat es eine dezentrale Versorgung mit Hochleistungsrechnern erreicht. Der Fokus dieses Systems liegt auf dem Aufbau von Infrastruktur als Service für künstliche Intelligenz",
	"components.fuwu.n868ra": "Schicht. Alle Plattformen als Service, die High-Performance Computing erfordern",
	"components.fuwu.ns02co": "Und Software as a Service",
	"components.fuwu.f94h07": "Alle Layer-Dienste können über",
	"components.fuwu.6ftu9v": "Freier Betrieb intelligenter quantitativer Handelssysteme.",
	"components.fuwu.b19msv": "Blockchain-Technologie",
	"components.fuwu.864583": "Der Kern eines intelligenten quantitativen Handelssystems liegt in der zugrunde liegenden Public Chain Technologie, die Finanzmodelle, künstliche Intelligenz und quantitativen Handel kombiniert, um einen hohen Transaktionsdurchsatz zu bewältigen. Daher,",
	"components.fuwu.61j6bp": "Die Hauptkette des intelligenten quantitativen Handelssystems nimmt",
	"components.fuwu.q1j1n6": "Konsensmechanismus, kombiniert mit",
	"components.fuwu.30nl6v": "Die Praktikabilität des Systems hat seine Fehlertoleranz, Transaktionseffizienz und Interaktionshäufigkeit verbessert. Dieses Netzwerk besteht aus",
	"components.fuwu.3kqnle": "Bestehend aus Superknoten, die für die Transaktionsprüfung und Blockgenerierung verantwortlich sind. Neben Superknoten gibt es auch reguläre Knoten, die Daten mit Kernknoten synchronisieren, private Wallet-Schlüssel und Adressen verwalten und Kettentransaktionen initiieren. Um die Bedürfnisse der Modellierung künstlicher Intelligenz und des quantitativen Hochfrequenzhandels zu erfüllen,",
	"components.fuwu.i1g6e7": "Das intelligente quantitative Handelssystem fördert kontinuierlich die Entwicklung der zugrunde liegenden Public Chain Technologie. Durch die Optimierung des Konsensmechanismus und den Einsatz der Seitenkettentechnologie können höhere Konsensniveaus erreicht werden",
	"components.fuwu.j3spal": "Abwicklung von mehr Transaktionen",
	"components.fuwu.d6r6r2": "Behandeln Sie mehr Transaktionen gleichzeitig und unterstützen Sie erhöhten Benutzerverkehr.",
	"components.fuwu.rfs8eb": "Das intelligente quantitative Handelssystem hat einen Cross-Chain-Mechanismus implementiert und einen wissensfreien Datenschutzmechanismus eingeführt, um sicherzustellen, dass Benutzerinformationen nicht durchgesickert werden. Das System registriert sich einheitlich auf der Blockchain",
	"components.fuwu.5l87r6": "Anwendungen stellen sicher, dass alle Daten offen, transparent und manipulationssicher sind, sobald Assets durch Smart Contracts bestätigt wurden. Daher verwenden",
	"components.fuwu.ci8bt7": "Der Handel mit Shared Assets basiert auf absolut zuverlässigen Daten und eliminiert das Risiko falscher Vermögenswerte oder falscher Transaktionen.",
	"components.fuwu.li76g2": "Gesamtarchitektur",
	"components.fuwu.2881li": "Die Netzwerkknoten intelligenter quantitativer Handelssysteme können große Rechenknoten wie große Mining-Pools sein, sowie",
	"components.fuwu.u15fc6": "Mittelgroße Computing-Knoten oder Cloud Computing-Ressourcen, die von Plattformen wie Alibaba Cloud bereitgestellt werden.",
	"components.fuwu.2w1u75": "Die Cloud- und Edge-Computing-Fähigkeiten des intelligenten quantitativen Handelssystems gewährleisten die Stabilität der KI-Betriebsumgebung. Einmal Investoren",
	"components.fuwu.6cu4ph": "Kaution",
	"components.fuwu.88fccf": "Intelligentes quantitatives Handelssystem, künstliche Intelligenz basiert auf",
	"components.fuwu.4gfpl8": "Suchen, identifizieren, schichten, verwalten und speichern Sie basierend auf der Menge. nachträglich",
	"components.fuwu.5vdauc": "verabschieden",
	"components.fuwu.0ud3oi": "Im Netz, entsprechend",
	"components.fuwu.8sm2by": "Und Rechenknoten haben passende Lösungen für diesen Batch gefunden",
	"components.fuwu.189o1y": "Der Transaktionsmodus der Blockchain. Die benötigte Rechenleistung für dieses Modell wird den entsprechenden",
	"components.fuwu.y83nn2": "Realisieren Sie quantitativen Handel.",
	"components.fuwu.78ik3b": "3.2.2 Computing Node Architecture",
	"components.fuwu.0wdoj7": "Rechenknoten-Architektur",
	"components.fuwu.25g0dk": "3.2.3 Big Data+Künstliche Intelligenz",
	"components.fuwu.r2e1x7": "Das intelligente quantitative Handelssystem umfasst ein verteiltes Hochleistungsrechennetzwerk, das im Wesentlichen eine Infrastruktur ist, die Big Data und künstliche Intelligenz kombiniert. Es hat die folgenden Eigenschaften",
	"components.fuwu.n4ct1t": "Das zugrunde liegende Framework basiert auf der Matrix-Plattform und übernimmt eine Softwarearchitektur, die auf Topic-Abonnement, ereignisgesteuerter Verarbeitung und Prozessor basiert. Durch eine leistungsstarke asynchrone Architektur kann es hervorragende Leistung erzielen. Die Mehrkettenstruktur besteht aus einer Haupt- und einer Arbeitskette. Die Hauptkette enthält Musterdefinitionen für alle Arbeitsketten, und die Anzahl der Arbeitsketten ist unbegrenzt. Jede Arbeitskette besteht aus Sharded Blockchain und unterstützt unendliches Sharding. Blockdaten werden anhand von Mustern definiert, was flexible Blockgrößen ermöglicht und die komprimierte Speicherung von Blockdaten unterstützt.",
	"components.fuwu.b9yr3c": "Die Kommunikation und Übertragung innerhalb des intelligenten quantitativen Handelssystems nehmen Selbstkodierungskompressionsprotokoll oder verschlüsseltes Übertragungsprotokoll an. Dies kann die Netzwerkbandbreite reduzieren und verschiedene Protokolle zur Nachrichtenpaketcodierung innerhalb derselben Verbindung aushandeln, wodurch die Übertragungssicherheit verbessert wird.",
	"components.fuwu.92179q": "3D-Netzwerk",
	"components.fuwu.y24z29": "Basierend auf Peer-to-Peer (P2P)-Netzwerken baut es ein mehrschichtiges Netzwerk auf, um die Verbreitung von Nachrichten zu beschleunigen. SN-Knoten verfügen über eine geografische Vielfalt und können die Netzwerktopologie dynamisch anpassen, um die allgemeine Netzwerkrobust zu verbessern, DDoS-Angriffen zu widerstehen und Sicherheitsrisiken zu reduzieren. Konsensmechanismus im Netz: Annahme des DPOS; Der Konsensmechanismus (Delegated Proof of Stake) bewertet die Netzwerkbeteiligung anhand von Nutzerrechten und Beiträgen. Es verwendet eine hierarchische Verarbeitungsmethode für Speicherung, Konsens und Verifizierung und verbessert die Skalierbarkeit des gesamten Netzwerks.",
	"components.fuwu.i5s5w9": "3.2.4 Netzstruktur",
	"components.fuwu.248t2x": "Die Mainstream-Lösungen zur Lösung der Blockchain-Skalierbarkeit können in zwei Arten unterteilt werden",
	"components.fuwu.75e81d": "Vertikale Skalierbarkeit und horizontale Skalierbarkeit. Typische Lösungen für vertikale Skalierbarkeit umfassen die Optimierung von Konsensusalgorithmen, Massenspeicher und die Verbesserung der Systemleistung, wie Blockerweiterung und EOS; DPOS und VRF+POS+PBFT. Typische horizontale Skalierbarkeitslösungen umfassen Lightning-Netzwerke, Multi-Chain-Architekturen und Shard-Architekturen, die alle darauf abzielen, die Probleme der Skalierbarkeit und Skalierbarkeit von Blockchain anzugehen. Blockchain 4.0 zielt darauf ab, leistungsstarke verteilte Geschäftssysteme auf großen dezentralen Netzwerken aufzubauen, mit einem Fokus auf der tatsächlichen Geschäftsimplementierung. Keine einzige technologische Verbesserung kann das gesamte Skalierbarkeitsproblem vollständig lösen. Das Gesamtarchitekturdesign einer Blockchain muss vier Schlüsselelemente berücksichtigen: P2P-Netzwerkarchitektur, zugrundeliegende Speicherarchitektur, Computing-/Konsensusarchitektur und Geschäftsprozessdesign. Darüber hinaus kann es in der Welt der Blockchain nur zwei Designattribute für Dezentralisierung, Skalierbarkeit und Sicherheit geben, und alle Skalierbarkeitsdesigns basieren auf diesem Prinzip.",
	"components.fuwu.854i8h": "Das intelligente quantitative Handelssystem übernimmt eine Multi-Chain-Architektur und verbessert die Skalierbarkeit des gesamten Netzwerks. Durch die Verwendung der Isolierung durch mehrere Ketten kann eine sichere Isolierung erreicht werden",
	"components.fuwu.kw1e7d": "DApp-Daten. Durch die parallele Ausführung mehrerer Ketten kann die Leistung des Systems linear wachsen. In einer Multi-Chain-Architektur gibt es jedoch immer noch Leistungsengpässe für eine einzelne Kette. Daher verbessert die Einführung der Sharding-Technologie die Performance einzelner Blockchains weiter und erreicht horizontale Skalierbarkeit. Führen Sie Cross-Chain-Router und Cross-Chain-Relais-Schichten zwischen mehreren Ketten ein, die das Relais von homogenen Unterketten und heterogenen Seitenketten unterstützen. Durch die Verankerung der Unterkette/Seitenkette zur Etablierung der Hauptkette und die Vernetzung mehrerer Ketten wurde eine Blockchain-Architektur mit unendlicher Skalierbarkeit geschaffen.",
	"components.fuwu.y11qiw": "3.2.5 Fragmentierte Architektur in",
	"components.fuwu.q7u6kb": "Der Hauptzweck des Einsatzes von Sharding in intelligenten quantitativen Handelssystemen besteht darin, die Performance einzelner Blockchains und die Skalierbarkeit des Gesamtnetzwerks zu verbessern. Jeder Shard verarbeitet verschiedene Teilmengen von Transaktionen parallel, wodurch Konsens innerhalb des Shard und der parallelen Ausführung von Transaktionen erzielt wird.",
	"components.fuwu.k61o0n": "3.2.5.1 Die komplette Sharding-Architektur besteht aus drei Teilen: Network Sharding, Computational Sharding (Transaktion/Konsens) und Storage Sharding (State Sharding). Erstens:",
	"components.fuwu.4t6qe4": "Das intelligente quantitative Handelssystem unterteilt Knoten im Netzwerk in verschiedene virtuelle Shards basierend auf Sharding-Regeln",
	"components.fuwu.7yx4qo": "(auch Cluster genannt). Es stellt die Konnektivität innerhalb jedes Shards und Netzwerkkonnektivität zwischen Knoten innerhalb des Shards und des Hauptnetzwerks sicher. Transaktionen innerhalb eines Shards werden nur innerhalb dieses Shards propagiert, um den Bandbreitenverbrauch zu reduzieren. Zweitens müssen alle Knoten, die am Konsens teilnehmen, eine Einzahlung leisten und sich für den Smart Contract registrieren. Auf Basis von Sharding-Regeln können wir Konsens-Knoten teilen. Transaktionen, die in verschiedenen Shards stattfinden, werden von den entsprechenden Shard-Konsens-Knoten verpackt und validiert. Da verschiedene Shard-Transaktionen parallel über Shards verifiziert werden, kann die Konsens-Performance linear skaliert werden. Mit der Verbesserung der Netzwerkleistung wird es unmöglich, die gesamten Ledger-Daten auf jedem Knoten im Netzwerk zu speichern. Unter der Annahme, dass die Gesamtleistung des Netzwerks eine Million TPS beträgt, wird der tägliche Speicherplatz der Ledger-Daten für jeden Knoten 20%überschreiten; Tuberkulose. Um dieses Problem zu lösen,",
	"components.fuwu.2wb32r": "Das intelligente quantitative Handelssystem trennt die Speicherschicht von der Rechenschicht und verwendet ein Ledger-Dateisystem, um Ledger-Daten zu speichern",
	"components.fuwu.69229m": "Gemeinsame Aufrechterhaltung eines intelligenten quantitativen Handelssystemnetzes.",
	"components.fuwu.l65907": "3.2.5.2 Jeder Knoten in einem programmierbaren virtuellen Shard P2P-Netzwerk verfügt über eine unabhängige PeerID, die ein strukturiertes oder unstrukturiertes P2P-Netzwerk mit anderen Knoten bildet. Basierend auf unterschiedlichen Geschäftsanforderungen verwenden Netzwerkknoten Smart Contracts für virtuelles Sharing auf physischen P2P-Netzwerken. Jeder Knoten berechnet seine eigene vShard programmgesteuert; ID, die zu derselben vShard&nbsp gehört; Die Knoten mit IDs bilden logischerweise ein unabhängiges Netzwerk-Shard. Jeder Netzwerksplitt stammt physisch aus einem vereinheitlichten P2P-Netzwerk. Es ist jedoch logisch isoliert und bietet die logische Unabhängigkeit, die für das Design der Netzwerkskalierbarkeit erforderlich ist. Jeder unabhängige P2P-Netzwerkknoten kann logischerweise mehreren virtuellen Shards angehören. Netzwerkverbindungen zwischen Sharding-Knoten müssen hergestellt werden, um ein regionales autonomes Sharding-Netzwerk zu bilden. Sharding ist dynamisch und elastisch, sodass das Netzwerk die Anzahl der Shards flexibel erhöhen kann. Das Hauptnetzwerk kann weiterhin unstrukturierte und strukturierte P2P-Netzwerke aufbauen.",
	"components.fuwu.255q2n": "3.2.5.3 Berechnen Sie Shard-Konsens-Knoten: unterteilt in globale Konsens-Knoten und interne Konsens-Knoten. Globale Konsensknoten sind für das Erreichen eines Konsenses im Hauptnetzwerk verantwortlich, während Konsensknoten innerhalb von Shards für die Verarbeitung des Konsenses innerhalb jedes Shards verantwortlich sind. Konsens kann Plugin-Mechanismen übernehmen, die es verschiedenen Shards ermöglichen, verschiedene Konsensmechanismen zu unterstützen. Distributed Random Generation Protocol: Zufällige Protokolle müssen Fairness, Unvorhersehbarkeit, Verifizierbarkeit durch Dritte und Skalierbarkeit aufweisen. Der Haupt-Shard-Konsens-Knoten verwendet den VRF-Algorithmus, um Shards zuzuweisen und ist zufällig für Konsens über verschiedene Shards verantwortlich. Die Konsensknoten verschiedener Shards bearbeiten Transaktionen innerhalb ihrer jeweiligen Shards. Knotenauswahl beobachten: Wählen Sie zufällig Knoten aus dem Shard-Konsens-Knotenpool aus, um am eigentlichen Konsens-Prozess teilzunehmen. Konsensknoten verhindern böswillige Angriffe durch regelmäßige Wahlen. Die Anzahl der Konsensknoten für jeden Shard kann flexibel erhöht werden. Konsensknoten verhindern böswillige Angriffe durch regelmäßige Wahlen. Die Anzahl der Konsensknoten für jeden Shard kann flexibel erhöht werden.",
	"components.fuwu.vr9w4q": "3.2.5.4 Teilung von Transaktionen",
	"components.fuwu.524h25": "Das intelligente quantitative Handelssystem unterteilt Transaktionen in Intra-Shard-Transaktionen und Cross-Shard-Transaktionen.",
	"components.fuwu.25ebl0": "Die Shard-Transaktionsadresse setzt sich aus der Transaktionsadresse und vShard zusammen; ID-Zusammensetzung. Wenn die Absenderadresse und die Empfängeradresse einer Transaktion zu demselben Shard gehören, dann handelt es sich bei der Transaktion um eine Intra-Shard-Transaktion. Wenn die Absender- und Empfängeradresse zu verschiedenen Shards gehören, handelt es sich um eine Cross-Shard-Transaktion. Versicherung; Will&nbsp; Be&nbsp; Jeder; To&nbsp; E&nbsp; Arbeitnehmer; The&nbsp; TSA-Shard-Intra-Transaktion: Die Transaktion wird nur innerhalb des vShard-Shards verbreitet und von Shard-Konsens-Knoten verpackt und validiert. Cross Shard-Transaktion: verpackt und verifiziert durch den Haupt-Shard-Konsens-Knoten",
	"components.fuwu.737wf6": "3.2.6 Web-Crawler",
	"components.fuwu.6ub735": "Der Betrieb intelligenter quantitativer Handelssysteme beruht auf der Fähigkeit, Daten zu sammeln, zu analysieren und zu modellieren.",
	"components.fuwu.3917n5": "Das intelligente quantitative Handelssystem nutzt künstliche Intelligenz, um regelmäßig und quantitativ Daten aus dem gesamten Netzwerk durch cloudbasierte Methoden zu sammeln. Es geht durch",
	"components.fuwu.540486": "Nahtlose Integration mit quantitativen Systemen künstlicher Intelligenz. Anschließend führt das System Filterung, Analyse und Modellierung durch, um visuelle Informationen für Backend-Mitarbeiter und Datenunterstützung für künstliche Intelligenz bereitzustellen.",
	"components.fuwu.i576fn": "Teammitglieder",
	"components.fuwu.8a523u": "Cliff&nbsp; Asness ist ein renommierter Ökonom und 2013 Nobelpreisträger für Wirtschaft, Eugene; Unter der Leitung von Professor Fama erwarb er duale Bachelor-Abschlüsse von der Wharton School of Business und der School of Engineering and Applied Sciences an der University of Pennsylvania.",
	"components.fuwu.clunb8": "Kapitalverwaltungsgesellschaften gehören zu den bekanntesten quantitativen Hedgefonds an der Wall Street. Er diente einst als",
	"components.fuwu.w47xsg": "Goldman,&nbsp; Sachs&nbsp&& Nbsp; Der Geschäftsführer und Direktor für Quantitative Forschung der Abteilung Asset Management der Co. Er ist der, Journal; von; Portfolio&nbsp; Mitglied des Editorial Committee of Management, Mitglied des Management Committee der School of Mathematics and Finance an der New York University, Mitglied des Board of Directors der Q-Group, Mitglied des Board of Directors des International Rescue Committee und der Vorstandsmitglieder von Ntn",
	"components.fuwu.8v041z": "David",
	"components.fuwu.1yi18o": "&#183; Gründer von Kabila",
	"components.fuwu.f126cm": "Jahrelange Erfahrung in Finanzanlagen,",
	"components.fuwu.551s9t": "Certified Master of Business Administration, Bachelor, Northwestern University, Vice President von Goldman Sachs, Sachs Company",
	"components.fuwu.638xk0": "Liu John EPOCH EPT",
	"components.fuwu.2d27j5": "Haupt",
	"components.fuwu.891s7f": "Mit langjähriger Erfahrung in Finanzinvestitionen hat er einen Doktortitel in Betriebswirtschaft und einen Bachelor of Arts von der University of Chicago. Er erwarb einen Bachelor of Economics von der University of Chicago, wo er als",
	"components.fuwu.khhc66": "Und weiterhin Mitgliedschaft in Chicago zu erhalten",
	"components.fuwu.qad15u": "Und einen Doktortitel in Finanzen.",
	"components.fuwu.ummboy": "Paul",
	"components.fuwu.f1uc41": "&#183; Jacobs",
	"components.fuwu.7du1a3": "Jahrelange Erfahrung in der technischen Entwicklung. Dieser EPOCH EPT-Technologieführer war zuvor als Software Engineer bei Google tätig und besitzt das Indian Institute of Technology",
	"components.fuwu.7964ma": "Bachelor-Abschluss vom Campus, Master-Abschluss von der Stanford University und Master-Abschluss von der Wharton School of Business an der University of Pennsylvania",
	"components.fuwu.7m912c": "Grad. verantwortlich für",
	"components.fuwu.nj2151": "Ich habe auch für Google persönlich gearbeitet.",
	"components.fuwu.itkrrj": "Der CEO ist mit dieser Anwendung vertraut.",
	"components.fuwu.c7gc6f": "Michelle",
	"components.fuwu.27102u": "&#183; Agassi verfügt über 25-jährige Berufserfahrung, mit einem PhD vom MIT und einem Bachelor-Abschluss von der Brown University",
	"components.fuwu.5549r8": "Position:",
	"components.fuwu.qtx3m3": "EPOCH; EPT; 3-5-Tage-Spezialist für quantitative KI",
	"components.fuwu.xj5p65": "Berühmtes Sprichwort: Der Markt verändert sich schnell, Chancen kurzfristig ergreifen, schnell rein und schnell raus.",
	"components.fuwu.nbk6k7": "Grego",
	"components.fuwu.o11m0w": "&#183; M; Andrade verfügt über 21-jährige Erfahrung, mit einem PhD von der University of Chicago und einem Bachelor-Abschluss vom Massachusetts Institute of Technology",
	"components.fuwu.58snmu": "EPOCH; EPT; 5-10 Tage kurzfristiger quantitativer KI-Spezialist",
	"components.fuwu.sdve3x": "Berühmtes Zitat: Reichhaltige Markterfahrung kombiniert mit nie endenden",
	"components.fuwu.3no8us": "KI-Intelligenz, die Vorteile sind so einfach.",
	"components.fuwu.8ru302": "Bradley",
	"components.fuwu.6zk9gb": "&#183; D; Asnis verfügt über 25-jährige Berufserfahrung, mit einem PhD in Recht von der New York Law School und einem Bachelor of Arts von der Brandeis University",
	"components.fuwu.748c4r": "EPOCH; EPT; 15-20 Tage kurzfristiger quantitativer KI-Spezialist",
	"components.fuwu.ykoem3": "Berühmtes Sprichwort: Ausreichende Zeit kann bessere Chancen bringen und Gewinne stabiler machen.",
	"components.fuwu.c8741k": "Jeffrey",
	"components.fuwu.mnj8g5": "&#183; Borduke verfügt über 19-jährige Erfahrung als Chartered Financial Analyst mit einem Bachelor of Arts vom Bates College und einem Master of Business Administration von der University of Chicago;",
	"components.fuwu.pt4258": "EPOCH; EPT; 30-50 Tage kurzfristiger quantitativer KI-Spezialist",
	"components.fuwu.yhx273": "Berühmtes Sprichwort: Ich bin sehr optimistisch in Bezug auf langfristige und stabile Investitionen, und es gibt nichts Besseres als das.",
	"components.fuwu.l1axrb": "Zeitereignisse",
	"components.fuwu.425wcf": "/Meilensteine",
	"components.fuwu.n33tpy": "Gründung und Einführung des ersten Hedgefonds-Produkts im 1998.",
	"components.fuwu.hch6ip": "Im 2000-er-Jahr wurde die erste internationale Aktienstrategie gestartet und ihre Renditen wurden international anerkannt.",
	"components.fuwu.51k20j": "Im 2016 wurde ein Büro in Hongkong eröffnet, um das asiatische Geschäft auszubauen.",
	"components.fuwu.e7b8ez": "Im vierten Quartal von 2016 gründete EPOCH EPT ein Forschungszentrum für Künstliche Intelligenz in Hongkong",
	"components.fuwu.gk53k8": "2019 Q2",
	"components.fuwu.51y6x1": "Version 1.0 White Paper Release",
	"components.fuwu.7p2h89": "Entwicklung der öffentlichen EPOCH EPT Blockchain im ersten Quartal 2020",
	"components.fuwu.19t2i2": "Im vierten Quartal von 2020 gründete EPOCH EPT ein Big Data Center",
	"components.fuwu.00bmfi": "Q1 2021",
	"components.fuwu.812he4": "Das quantitative Handelssystem ist mit dem EPOCH EPT Big Data Center verbunden.",
	"components.fuwu.7vb4bx": "Saison 3, 2021",
	"components.fuwu.i5s411": "Test des Startpakets für quantitative Handelssysteme",
	"components.fuwu.4rzg05": "Q2 2022",
	"components.fuwu.6f1vku": "Das quantitative Handelssystem hat insgesamt eine",
	"components.fuwu.7j4ksr": "Ein quantitativer Gewinn von 1,3 Milliarden US Dollar.",
	"components.fuwu.oox35c": "Birmingham, UK",
	"components.fuwu.t13534": "Registrierung und Niederlassung",
	"components.fuwu.27p688": "EPOCH; EPT hat das weltweit erste intelligente KI-Handelssystem eingeführt.",
	"components.fuwu.0at9r7": "6.&nbsp; In naher Zukunft ist absehbar, dass Milliarden von Menschen ihr Vermögen in Form von digitalen Assets speichern werden. Diese Vermögenswerte umfassen nicht nur traditionelle Währung und physische Vermögenswerte, sondern auch virtuelle digitale Vermögenswerte. Digitale Vermögenswerte haben mehrere Attribute wie Werterhaltung, Wandelbarkeit und Absicherung, die das Risiko von Abschreibungen oder Verlusten durch Finanzkrisen oder Konflikte verringern können. Sie bieten einen Mechanismus zum Schutz und Schutz persönlicher Vermögenswerte und ermöglichen eine uneingeschränkte Interaktion zwischen verschiedenen Zeiträumen und Bereichen. Da immer mehr Nutzer digitale Vermögenswerte besitzen, suchen sie stabile Investitionen, um den Wert digitaler Vermögenswerte zu erhöhen und Veränderungen der Realwirtschaft und Inflation zu bewältigen. Das ist",
	"components.fuwu.r4o4y5": "Quantitative Handelssysteme sind die beste Wahl. Derzeit sind",
	"components.fuwu.yt83vn": "300 Millionen Menschen beteiligen sich an Investitionsaktivitäten im Blockchain-Markt mit einem globalen Wert digitaler Vermögenswerte über 50 Milliarden US-Dollar. Die Provision, die durch den Handel mit digitalen Blockchain-Assets generiert wird, übersteigt 500 Millionen US-Dollar mit großem Marktpotenzial. Um den Wert besser zu erhalten und zu steigern, übernehmen wir",
	"components.fuwu.74n7mh": "Das quantitative Handelssystem hat die traditionellen Handelsmethoden völlig verändert, sodass mehr Anleger digitaler Vermögenswerte höhere Renditen und Renditen erzielen können. Es bietet auch Hedging- und Vermögenswachstumsmöglichkeiten für traditionelle Investoren. In der zukünftigen Welt werden die Finanzmärkte das Feld des Handels mit digitalen Vermögenswerten sein, und",
	"components.fuwu.h335ga": "Quantitative Handelssysteme werden die Entwicklung dieser Ära vorantreiben. Wenn ich die Welt betrachte,",
	"components.fuwu.5173h3": "Das quantitative Handelssystem hat sich in kürzester Zeit auf dem Gebiet der Finanzanlage etabliert. Es hat neue Anwendungsmodelle entwickelt, die auf digitaler Wirtschaft, digitalen Assets und Blockchain-Technologie basieren.",
	"components.fuwu.jw5ueb": "7.&nbsp; Da immer mehr Kryptowährungen als Wertpapiere eingestuft werden, haben Finanzbehörden in den USA, Singapur, China, der Schweiz und Deutschland ihre Richtlinien verschärft oder Warnungen bezüglich der Initial Token Emission (ICOs) herausgegeben.",
	"components.fuwu.r3jm21": "Wertpapiervorschriften in mehreren wichtigen Rechtsordnungen anerkennen und einhalten und diese einhalten",
	"components.fuwu.2a723k": "KYC (Know Your Customer) und AML (Anti Money Laundering) Vorschriften. Konkret bedeutet dies: Wertpapierregeln: Bitte lesen Sie diesen Abschnitt sorgfältig durch. Wenn Sie Fragen zu den Maßnahmen haben, die ergriffen werden sollten, empfehlen wir Ihnen, Ihren rechtlichen, finanziellen, steuerlichen oder anderen professionellen Berater zu konsultieren. Die in diesem Dokument aufgeführten Informationen sind möglicherweise nicht umfassend und stellen keine wesentlichen Elemente des Vertragsverhältnisses dar. Dieses Dokument stellt weder eine Anlageberatung, Steuer-, Rechts-, Regulierungs-, Finanz-, Buchhaltungs- oder sonstige Beratung dar, noch kann es der einzige Grund für die Teilnahme an der Unterstützung und Entwicklung des Hang Seng Index Ökosystems sein. Bevor potenzielle Käufer eine Entscheidung treffen, müssen sie ihre Rechts-, Investitions-, Steuer-, Buchhaltungs- und anderen Berater konsultieren, um die potenziellen Vorteile, Einschränkungen und anderen Folgen solcher Transaktionen zu ermitteln. Ein Teil dieses Dokuments stellt weder ein Angebot, Prospekt oder Ausschreibungsdokument dar, noch ist er als Anforderung für Anlagen in Wertpapieren in irgendeiner Rechtsordnung gedacht. Dieses Dokument ist nicht in Übereinstimmung mit den Gesetzen oder Vorschriften einer Gerichtsbarkeit geschrieben, die Transaktionen im Zusammenhang mit digitalen Token oder deren Verwendung verbietet oder einschränkt. KYC und AML: Das Verständnis der technischen Details Ihres Kunden (KYC) und der Anti-Geldwäsche-Vorschriften (AML) kann je nach Land/Region variieren, aber beide erfordern eine Überprüfung und Gegenprüfung der Kundenidentität basierend auf verschiedenen Listen von Terroristen, Embargos und politisch exponierten Personen (PEPs).",
	"components.fuwu.n5ijvs": "Um diesen Prozess umzusetzen, arbeiten wir mit professionellen Dienstleistern zusammen. Im Prozess der öffentlichen Investitionen, Investor Kate",
	"components.fuwu.y06wpn": "8.&nbsp; Haftungsausschluss: Dieses Dokument bezieht sich nicht auf geregelte Produkte im Rahmen der gesetzlichen Gerichtsbarkeit. Dieses Dokument ist ein konzeptionelles Dokument (White Paper), das nur zur Projektbeschreibung verwendet wird und nicht für den Verkauf oder Fundraising von Aktien, Wertpapieren oder anderen Zwecken bestimmt ist.",
	"components.fuwu.5t61y5": "Verwandte Produkte und regulierte Produkte von verbundenen Unternehmen. Dieses Dokument sollte weder als Prospekt oder standardisiertes Vertragsdokument betrachtet werden, noch sollte es Anlageberatung oder Aufforderung zu Wertpapieren oder anderen regulierten Produkten in irgendeiner Rechtsordnung darstellen. Dieses Dokument sollte nicht als Beratung für den Verkauf, die Zeichnung oder die Aufforderung zum Kauf oder zur Zeichnung von Wertpapieren oder Verträgen, Kontakten oder Verpflichtungen verwendet werden, die auf diesen beruhen. Sie sollte nicht als Vorschlag für eine Beteiligung an Investitionen angesehen werden. Die in diesem Dokument enthaltenen Informationen oder Analysen stellen keine Empfehlungen für die Teilnahme an Token-Anlagen dar und enthalten auch keine spezifischen Empfehlungen. Sie müssen alle notwendigen fachlichen Ratschläge in relevanten Angelegenheiten wie Steuer- und Rechnungsprüfungen einholen. Dieses Dokument stellt keine Zusicherung oder Garantie dar. Wird verwendet, um unseren Vorschlag zu erklären",
	"components.fuwu.j73g2y": "-&Nbsp; AL quantitatives Handelssystem, aber",
	"components.fuwu.3ip5we": "Die Stiftung erklärt klar:",
	"components.fuwu.1x311y": "8.1 Es werden keinerlei Zusicherungen oder Gewährleistungen in Bezug auf die Richtigkeit oder Vollständigkeit der in diesem Dokument beschriebenen Inhalte oder anderer Inhalte im Zusammenhang mit dem in diesem Dokument veröffentlichten Projekt übernommen.",
	"components.fuwu.0c5427": "8.2 Ohne vorherige Bedingungen werden keine zukunftsgerichteten oder konzeptionellen Aussagen oder Garantien hinsichtlich der Erreichung oder Machbarkeit abgegeben.",
	"components.fuwu.h6u91t": "8.3 Nichts in diesem Dokument soll als Grundlage für Zusagen oder Aussagen in Bezug auf die Zukunft dienen.",
	"components.fuwu.if1i12": "8.4 Das Unternehmen haftet nicht für Verluste, die von Einzelpersonen aufgrund dieses Whitepapers oder anderer Aspekte verursacht werden.",
	"components.fuwu.m21co1": "8.5 Die Haftung ist im Rahmen des unvermeidbaren Umfangs der gesetzlichen Haftung auf das nach geltendem Recht zulässige Höchstmaß beschränkt. Risiken im Zusammenhang mit regulatorischen Maßnahmen in relevanten Regionen und Ländern: Blockchain-Technologie wird zwar weltweit unterstützt und anerkannt, unterliegt aber auch einer sorgfältigen Prüfung durch verschiedene Regulierungsbehörden.",
	"components.fuwu.t5i928": "Die Funktionalität quantitativer Handelssysteme kann durch bestimmte regulatorische Richtlinien beeinträchtigt werden, einschließlich, aber nicht beschränkt auf Beschränkungen der Nutzung oder des Besitzes",
	"components.fuwu.77k38h": "USDT, die behindern oder begrenzen kann",
	"components.fuwu.sxf326": "Entwicklung quantitativer Handelssysteme. Andere unbekannte Risiken",
	"components.fuwu.t62x6w": "Die Blockchain-Technologie und die dazugehörige digitale Währungstechnologie sind relativ neue und unzureichend validierte Technologien, die auf verschiedene Weise unvorhersehbare Risiken bergen können.",
	"login.register.6l6651": "Telefonregistrierung",
	"login.register.bsu16p": "Passwort bestätigen",
	"login.register.8r578v": "Die zweimal eingegebenen Passwörter sind unterschiedlich",
	"components.xieyi.z4tui6": "Willkommen zur Nutzung",
	"components.xieyi.985i12": "Gesellschaft mit beschränkter Haftung (nachfolgend 'Gesellschaft mit beschränkter Haftung')'",
	"components.xieyi.k71g1d": "Wir",
	"components.xieyi.6473ug": "'Oder 'Plattform' Produkte und Dienstleistungen! Um Ihnen verschiedene technische Dienstleistungen im Rahmen der Plattform anbieten zu können, verarbeiten wir Ihre personenbezogenen Daten während Ihres Betriebs. Wir sind uns der Bedeutung personenbezogener Daten für Sie bewusst, und Ihr Vertrauen ist uns sehr wichtig. Wir werden die Anforderungen der Gesetze und Vorschriften strikt einhalten und entsprechende Sicherheitsmaßnahmen unter den aktuellen effektiven technischen Bedingungen ergreifen, um die Sicherheit Ihrer personenbezogenen Daten zu schützen. Auf dieser Grundlage haben wir diese Datenschutzrichtlinie (nachfolgend als 'diese Richtlinie' bezeichnet) formuliert, um Ihnen zu helfen, vollständig zu verstehen, wie wir Ihre personenbezogenen Daten während Ihrer Nutzung unserer Produkte und Dienstleistungen sammeln, verwenden, weitergeben, speichern und schützen, sowie wie Sie Ihre personenbezogenen Daten verwalten können, damit Sie geeignete Entscheidungen treffen können.",
	"components.xieyi.r997hr": "Bevor Sie mit der Nutzung unserer Produkte und Dienstleistungen beginnen, sollten Sie diese Richtlinie sorgfältig lesen und verstehen.",
	"components.xieyi.23i3k9": "Fett/Fett unterstrichen",
	"components.xieyi.5jo3lp": "Bitte stellen Sie sicher, dass Sie die Bedingungen der Identifikation vollständig verstehen und akzeptieren, bevor Sie mit der Nutzung beginnen. Wir werden unser Bestes geben, um Ihnen das in dieser Richtlinie enthaltene Fachvokabular in prägnanten und leicht verständlichen Begriffen zu erklären.",
	"components.xieyi.sw2x3b": "Wenn Sie Fragen, Meinungen oder Anregungen zum Inhalt dieser Richtlinie haben, können Sie uns über",
	"components.xieyi.w1nc5l": "Der offizielle Kundenservice der Plattform kontaktiert uns.",
	"components.xieyi.u855jt": "Ihre Zustimmung zur Datenschutzerklärung bedeutet nur, dass Sie die Funktionen der Anwendung und die notwendigen persönlichen Informationen verstanden haben, die für den Betrieb der Funktionen erforderlich sind. Dies bedeutet nicht, dass Sie zugestimmt haben, dass wir nicht wesentliche personenbezogene Daten sammeln können. Nicht wesentliche personenbezogene Daten werden gesondert nach Ihrer Zustimmung während der Nutzung eingeholt.",
	"components.xieyi.jiyuvt": "Wenn Sie diese Datenschutzerklärung ablehnen, können Sie die grundlegenden Browsing-Funktionen weiterhin ohne Registrierung/Login nutzen. Sie können bei der späteren Nutzung des Dienstes auch gesondert autorisieren.",
	"components.xieyi.61890q": "Diese Richtlinie hilft Ihnen, die folgenden Inhalte zu verstehen:",
	"components.xieyi.5mt6j8": "1,Definition und Anwendungsbereich",
	"components.xieyi.41xpk4": "2,Wie sammeln und verwenden wir Ihre Informationen",
	"components.xieyi.83j8rl": "3,Wie verwenden wir Cookies und ähnliche Technologien",
	"components.xieyi.8b639p": "4,Wie teilen wir, übertragen und öffentlich offenlegen Ihre Informationen",
	"components.xieyi.6dq28b": "5,Wie schützen wir Ihre Daten",
	"components.xieyi.2ulrqx": "6,Wie speichern wir Ihre Informationen",
	"components.xieyi.3nz3ga": "7,Wie verwalten Sie Ihre Informationen",
	"components.xieyi.9s39yt": "8,Wie gehen wir mit Informationen über Minderjährige um",
	"components.xieyi.ew14nj": "9,How to update this policy",
	"components.xieyi.12eq82": "10,Wie kontaktieren Sie uns",
	"components.xieyi.b1sb46": "(1) Definition",
	"components.xieyi.we1z6j": "Bezieht sich auf Birmingham, UK",
	"components.xieyi.rw8pei": "Die Registrierung des EPOCH EPT hat",
	"components.xieyi.idu1sg": "Aktiengesellschaft.",
	"components.xieyi.m568up": "Plattform:",
	"components.xieyi.hvxy61": "Finger",
	"components.xieyi.73ijj4": "Website (Domainname www.weipaitang.com), APP client(",
	"components.xieyi.h0re24": "Und WeChat offizielles Konto und andere Service-Medien, auch bekannt als'",
	"components.xieyi.ivy1b3": "'Plattform'.",
	"components.xieyi.ki07fh": "Verbundene Unternehmen:",
	"components.xieyi.6c317m": "Bezieht sich auf und",
	"components.xieyi.wr8y6r": "Unternehmen mit direkter oder indirekter Kontrolle oder erheblichen Einflussverhältnissen, einschließlich derjenigen, die",
	"components.xieyi.5865bv": "Aktionäre mit Eigenkapital über 5%",
	"components.xieyi.hty2r8": "Unsere Tochtergesellschaft",
	"components.xieyi.36ty5w": "Andere vom eigentlichen Verantwortlichen kontrollierte Unternehmen.",
	"components.xieyi.lsnc7x": "Zahlungsunternehmen",
	"components.xieyi.47x242": "Bezieht sich auf Ihre Beteiligung an",
	"components.xieyi.gmmfq4": "Das Zahlungsinstitut, das Zahlungsdienste für Einkäufe auf der Plattform anbietet.",
	"components.xieyi.k10tpg": "Persönliche Daten:",
	"components.xieyi.69e87n": "Bezieht sich auf verschiedene elektronisch aufgezeichnete oder anderweitig identifizierte oder identifizierbare natürliche Personen bezogene Informationen, ausgenommen Informationen, die anonym verarbeitet werden.",
	"components.xieyi.o1m0al": "Persönliche sensible Informationen:",
	"components.xieyi.2h5e47": "Personenbezogene Daten, die nach Durchsickern oder rechtswidriger Verwendung leicht zur Verletzung der persönlichen Würde natürlicher Personen oder zur Schädigung der Personen- und Vermögenssicherheit führen können, einschließlich",
	"components.xieyi.2ipl66": "Biometrische Identifikation, religiöse Überzeugungen, spezifische Identitäten, Gesundheitswesen, Finanzkonten, Reiseverläufe",
	"components.xieyi.8122dw": "Warten auf Informationen und",
	"components.xieyi.124tei": "Personenbezogene Daten von Minderjährigen unter vierzehn Jahren",
	"components.xieyi.swn32o": "Wir werden spezifische persönliche sensible Informationen in dieser Richtlinie ansprechen",
	"components.xieyi.704x6v": "fett",
	"components.xieyi.274q3q": "Deutlich markiert.",
	"components.xieyi.u2twjv": "Verarbeitung personenbezogener Daten:",
	"components.xieyi.9813ba": "Bezieht sich auf die Erhebung, Speicherung, Nutzung, Verarbeitung, Übermittlung, Bereitstellung, Offenlegung, Löschung usw. von personenbezogenen Daten.",
	"components.xieyi.rutsx1": "Kinder",
	"components.xieyi.81twf5": "Bezieht sich auf Minderjährige unter vierzehn Jahren.",
	"components.xieyi.5jsxyn": "Sofern nicht anders vereinbart, stehen die in dieser Richtlinie verwendeten Definitionen im Einklang mit",
	"components.xieyi.hu1431": "Nutzungsvereinbarung für Transaktionsdienste",
	"components.xieyi.f61m93": "Die Definitionen in haben die gleiche Bedeutung.",
	"components.xieyi.4kd4rv": "(2) Geltungsbereich",
	"components.xieyi.s893e8": "Diese Richtlinie gilt für",
	"components.xieyi.p95qjo": "mit",
	"components.xieyi.1d536g": "Websites, Kunden, Applets, offizielles Konto und verschiedene Produkte und Dienstleistungen, die Ihnen in neuen Formen mit der Entwicklung der Technologie zur Verfügung gestellt werden.",
	"components.xieyi.d02u4n": "Bei Verwendung in unseren Produkten oder Dienstleistungen und denen unserer verbundenen Unternehmen",
	"components.xieyi.vd5142": "Produkte oder Dienstleistungen (wie",
	"components.xieyi.2ifgyw": "Wenn das Plattformkonto angemeldet ist, es aber keine unabhängige Datenschutzerklärung gibt, gilt diese Richtlinie auch für diesen Teil des Produkts oder der Dienstleistung. Wenn wir und unsere verbundenen Unternehmen separate Datenschutzrichtlinien für die Produkte oder Dienstleistungen haben, die sie Ihnen zur Verfügung stellen, unterliegen die entsprechenden Produkte oder Dienstleistungen den entsprechenden Datenschutzrichtlinien.",
	"components.xieyi.lzne8r": "Zusätzlich zu den in dieser Richtlinie beschriebenen Aktivitäten zur Erfassung und Nutzung von Informationen,",
	"components.xieyi.8o7eza": "Diese Richtlinie gilt nicht für",
	"components.xieyi.c7i3mg": "Andere Dienstleistungen, die Ihnen von Plattformverkäufern und anderen Dritten zur Verfügung gestellt werden, unterliegen der Datenschutzrichtlinie und anderen Regeln zur Erhebung und Nutzung personenbezogener Daten, die Ihnen gesondert erläutert werden.",
	"components.xieyi.f4n8lb": ". Zum Beispiel, wenn Sie an",
	"components.xieyi.51p7u0": "Beim Betrieb von EPOCH und EPT",
	"components.xieyi.9ngrl3": "Basierend auf den Anforderungen der Gesetze und Vorschriften erläutern wir Ihnen die Regeln für die Erhebung und Anwendung Ihrer personenbezogenen Daten.",
	"components.xieyi.62e3v6": "Gemäß den Vorschriften über den Umfang notwendiger personenbezogener Daten für gängige Arten von mobilen Internet-Anwendungen",
	"components.xieyi.3n4mh2": "Die APP gehört zur Kategorie 'online'",
	"components.xieyi.8q81vo": "Finanzierung",
	"components.xieyi.57m6k2": "'Die grundlegenden funktionalen Dienstleistungen sind'",
	"components.xieyi.6891w4": "KI Intelligence",
	"components.xieyi.s2ji36": "'Die notwendigen personenbezogenen Daten im Rahmen dieser Funktion umfassen: Ihre Telefonnummer; Name, Adresse und Telefonnummer des Empfängers; Zahlungsinformationen wie Zahlungszeit, Zahlungsbetrag und Zahlungskanal.",
	"components.xieyi.wyy6r5": "Wir bieten Ihnen komfortable E-Commerce-Transaktionsplattformen. Um die Erbringung von Dienstleistungen zu erreichen, verarbeiten wir Ihre personenbezogenen Daten auf der Grundlage der verschiedenen Funktionen der Plattform:",
	"components.xieyi.t1c3k8": "1. Grundfunktion: Um die Grundfunktion der Bereitstellung unserer Produkte und/oder Dienstleistungen zu erreichen, müssen Sie uns ermächtigen, notwendige Informationen zu sammeln und zu verwenden. Wenn Sie sich weigern, die entsprechenden Informationen bereitzustellen, können Sie unsere Produkte und/oder Dienstleistungen nicht normal nutzen",
	"components.xieyi.y7m0r2": "2. Zusätzliche Funktionen: Um Ihnen zusätzliche Funktionen unserer Produkte und/oder Dienstleistungen zur Verfügung zu stellen, können Sie wählen, ob Sie uns autorisieren möchten, die Informationen zu sammeln und zu verwenden. Wenn Sie die Bereitstellung verweigern, können Sie die entsprechenden Zusatzfunktionen nicht normal nutzen oder die gewünschten funktionalen Effekte erzielen.",
	"components.xieyi.cwp3h6": "Wir möchten Sie daran erinnern, dass:",
	"components.xieyi.63078v": "1. Wir sind verpflichtet, eine vielfältige Palette von Produkten und Dienstleistungen zu schaffen, um Ihre Bedürfnisse zu erfüllen.",
	"components.xieyi.11t174": "Aufgrund der Vielzahl der Produkte und Dienstleistungen, die wir Ihnen zur Verfügung stellen, und der Unterschiede im spezifischen Produkt-/Dienstleistungsumfang, den verschiedene Benutzer wählen, können die grundlegenden/zusätzlichen Funktionen und die entsprechenden Arten und Umfang der erhobenen und verwendeten personenbezogenen Daten variieren.",
	"components.xieyi.654y1j": "Um Ihnen ein besseres Produkt- und Serviceerlebnis zu bieten, bemühen wir uns kontinuierlich, unsere Produkte, Dienstleistungen und Technologien zu verbessern. Daher können wir gelegentlich neue oder optimierte Funktionen einführen, die die Erfassung und Verwendung neuer personenbezogener Daten oder Änderungen des Zwecks oder der Methode der Verwendung personenbezogener Daten erfordern. Wenn die oben genannte Situation eintritt, werden wir Ihnen detaillierte Erklärungen durch Aktualisierung dieser Richtlinie, Popups, Seitenaufforderungen und andere Mittel zur Verfügung stellen und Ihnen die Möglichkeit zur Auswahl und Zustimmung geben.",
	"components.xieyi.228036": "Wenn Sie während dieses Prozesses Fragen, Meinungen oder Vorschläge haben, können Sie uns über die in dieser Richtlinie angegebenen Kanäle kontaktieren, und wir werden Ihnen so schnell wie möglich Antworten geben.",
	"components.xieyi.3l69jv": "Wir stellen Ihnen verschiedene spezifische Funktionsszenarien zur Verfügung, darunter:",
	"components.xieyi.xq77st": "(1) Benutzerregistrierung und Kontoverwaltung",
	"components.xieyi.hgd0m6": "1. Grundlegende Benutzerdienste",
	"components.xieyi.sy4rg3": "Wir basieren auf",
	"components.xieyi.3768od": "Das Plattformkonto stellt Dienstleistungen für Sie bereit. So erstellen",
	"components.xieyi.k5pm58": "Sie müssen uns mindestens Ihr Plattformkonto zur Verfügung stellen",
	"components.xieyi.ijyhl0": "Mobiltelefonnummer, beabsichtigter Benutzername und Passwort zu verwenden",
	"components.xieyi.k04bce": ". Wenn Sie die oben genannten Informationen ablehnen, können Sie sich nicht registrieren.",
	"components.xieyi.4b0us6": "Plattformkonto, aber Sie können weiterhin Browsing- und Suchdienste verwenden.",
	"components.xieyi.3k9d16": "Sie können Ihren Spitznamen, Ihr Geschlecht, Ihre Region, Ihre Kontaktperson und Ihre Echtnamen-Authentifizierungsdaten ändern und ergänzen, die alle zu Ihren 'Kontoinformationen' gehören.",
	"components.xieyi.rqik5d": "Unter ihnen werden der Nickname und Avatar, den Sie festgelegt haben, öffentlich angezeigt.",
	"components.xieyi.ilmlc1": "Die zusätzlichen Kontoinformationen, die Sie uns zur Verfügung stellen, helfen uns, Ihnen personalisierte Produktempfehlungen und ein besseres Einkaufserlebnis zu bieten. Wenn Sie diese zusätzlichen Informationen jedoch nicht angeben, hat dies keinen Einfluss auf Ihre grundlegenden Funktionen des Online-Einkaufs.",
	"components.xieyi.2d8htr": "Um sicherzustellen, dass wir Dienstleistungen für Sie persönlich erbringen, können wir Ihre Identität anhand der von Ihnen bereitgestellten Informationen überprüfen. Wenn es Szenarien gibt, in denen es erforderlich ist, Ihre Benutzeridentität in Übereinstimmung mit dem Gesetz zu bestimmen (einschließlich Quellensteuern und Gebühren für Sie gemäß dem Gesetz, Identifizierung relevanter Parteien in Verwaltungs- oder Gerichtsverfahren usw.), ermächtigen Sie uns, Ihre entsprechenden Authentifizierungsdaten von unseren Partnern für die oben genannten Zwecke zu erhalten.",
	"components.xieyi.qimk2p": "Wir berücksichtigen Ihre",
	"components.xieyi.z57g36": "Die Nutzung von Plattformkonten und die von der Plattform festgelegten Berechnungsregeln für Kontoebenen bestimmen Ihr aktuelles Niveau und bieten Ihnen die grundlegenden Vorteile entsprechend der entsprechenden Kontoebene.",
	"components.xieyi.21n38j": "2. Zusätzliche Dienstleistungen",
	"components.xieyi.dh7o8w": "Echtpersonauthentifizierung: Sie können entsprechende Authentifizierungsanforderungen angeben",
	"components.xieyi.57s046": "Identitätsinformationen (Personalausweis, Reisepass, Haushaltsregisterbuch und andere Identitätsdokumente), biometrische Merkmale (statische oder dynamische Gesichtszüge)",
	"components.xieyi.eb3ena": "Um die Echtpersonauthentifizierung abzuschließen.",
	"components.xieyi.2fnv9d": "Autorisierte Anmeldung: Mit Ihrer Zustimmung können wir Ihre Kontoinformationen (Avatar, Nickname und andere Eingabeaufforderungen) an Dritte weitergeben, so dass Sie sich einfach für Drittanbieter-Konten registrieren oder sich direkt von Dritten anmelden können. Darüber hinaus können wir Ihre Drittanbieter-Kontoinformationen von Dritten wie WeChat basierend auf Ihrer Genehmigung erhalten und mit Ihren",
	"components.xieyi.2586kj": "Die Plattformkontobebindung ermöglicht es Ihnen, sich direkt anzumelden und unsere Produkte und/oder Dienstleistungen von Dritten zu nutzen. Wir verwenden Ihre relevanten Informationen im Rahmen Ihrer autorisierten Einwilligung.",
	"components.xieyi.75o8vj": "Sonstige Benutzerrollendienste: Wenn Sie sich als Verkäufer oder eine andere Benutzerrolle mit zusätzlichen Identitätsauthentifizierungsanforderungen registrieren lassen, müssen Sie uns proaktiv Identitätsinformationen und/oder unternehmensbezogene Informationen zur Verfügung stellen, um Ihre Identität und andere Benutzerrollendienste zu verifizieren, zu registrieren, zu veröffentlichen und für andere Zwecke, über die wir Sie eindeutig informiert haben. Wie '",
	"components.xieyi.ni3907": "Autorisierte Vermögensverwaltung",
	"components.xieyi.1168tn": "'Sie stimmen zu, unsere Erfassung Ihrer Mobiltelefonnummer und ID-Kartennummer für die Verwaltung von Live-Broadcast-Konten sowie die Erfassung von Gerätekennungen, Gerätemarkenmodellen, Gerätebetriebssystemen und geografischen Standorten für Live-Broadcast-Funktionen und Live-Broadcast-Sicherheitskontrolle zu verwenden.",
	"components.xieyi.k2wd14": "3. Identitätsprüfung",
	"components.xieyi.1ns543": "Um relevante gesetzliche und regulatorische Anforderungen zu erfüllen, die Authentizität der Benutzeridentität zu gewährleisten (einschließlich Schutz der Rechte und Interessen Minderjähriger in Übereinstimmung mit dem Gesetz, Bekämpfung von Telekommunikationsbetrug, Quellensteuer und Zahlung von Einkommensteuer, Identifizierung relevanter Personen in Verwaltungs- oder Gerichtsverfahren), Risikokontrolle wie Betrugsbekämpfung zu erreichen und die Sicherheit von Systemen und Diensten zu gewährleisten, müssen wir Ihre relevanten Informationen für die Identitätsprüfung erfassen, wenn Sie bestimmte Dienste/Funktionen nutzen. Zum Beispiel: [Telefonnummer ändern] Wenn Sie die Funktion 'Telefonnummer ändern' verwenden, um Ihr Konto abzurufen, können wir als einzelner Benutzer Ihre Identitätsinformationen erfassen, einschließlich Ihrer",
	"components.xieyi.3lj887": "Echter Name, ID-Nummer, Gesichtserkennungsinformationen, Bestellinformationen",
	"components.xieyi.7y2f63": "Wird verwendet, um Ihre Identität zu überprüfen und Ihnen zu helfen, Ihr Plattformkonto abzurufen.",
	"components.xieyi.y57u7o": "Für Unternehmensnutzer können wir Ihre rechtlichen Identitätsinformationen erfassen, einschließlich",
	"components.xieyi.82yye6": "Unternehmenslizenzinformationen, echter Name des gesetzlichen Vertreters, ID-Nummer, Gesichtserkennungsinformationen",
	"components.xieyi.t6tsvp": "Wenn Sie bereits",
	"components.xieyi.3coa6i": "Plattformkonto, auf das wir möglicherweise Zugriff haben",
	"components.xieyi.4g34dh": "Ihre oben genannten personenbezogenen Daten werden in den Plattformdiensten sowie in Ihren",
	"components.xieyi.i3lx1u": "Auf der Plattform oder in Zusammenarbeit mit",
	"components.xieyi.cd0jo8": "Die Vorgänge in den Produkten und Dienstleistungen, die mit dem Plattformkonto verbunden sind, einschließlich durch",
	"components.xieyi.f5dq1g": "Das Plattformkonto zeigt Ihre persönlichen Informationen, Rabattverfahren und Transaktionsaufträge zentralisiert an",
	"components.xieyi.vo3428": ". Wir respektieren Ihren Respekt für",
	"components.xieyi.y3j2fo": "Die Auswahl der Einstellungen des Plattformkontos.",
	"components.xieyi.pw3g77": "(2) Zeigen Sie Produkt-, Service-Informationen und Nachrichten Push zu Ihnen",
	"components.xieyi.82aac2": "1. Um Ihnen Produkt- oder Serviceinformationen anzuzeigen, einschließlich Ihres Besuchsverlaufs und Suchverlaufs, erfassen und verwenden wir während Ihres Besuchs oder Ihrer Nutzung Informationen von Ihnen.",
	"components.xieyi.h4s010": "Browsing- und Suchverlauf auf der Plattform. Wir kombinieren gesetzlich erfasste Geräteinformationen, Serviceprotokollinformationen und andere autorisierte Informationen, um Ihre Präferenzmerkmale anhand eines Algorithmenmodells vorherzusagen. Wir werden Ihnen kommerzielle Werbung und andere Informationen, die Sie interessieren könnten, basierend auf Ihren Präferenzmerkmalen senden oder Ihnen kommerzielle Kurznachrichten senden.",
	"components.xieyi.kf81h2": "Personalisierte Empfehlungs- und Abrufaulgorithmen prognostizieren Ihre bevorzugten Funktionen basierend auf dem Modell, passen Produkte, Dienstleistungen oder andere Informationen an, die Sie interessieren könnten, und sortieren die Produkte, Dienstleistungen oder andere Informationen, die Ihnen angezeigt werden. Wir geben Echtzeit-Feedback zum Empfehlungsmodell basierend auf Ihrem Surfverhalten während der Nutzung des Produkts und passen die Empfehlungsergebnisse kontinuierlich an und optimieren sie. Um Ihren vielfältigen Bedürfnissen gerecht zu werden, führen wir im Sortierprozess verschiedene Empfehlungstechniken ein, um die empfohlenen Inhalte zu erweitern und eine übermäßige Konzentration ähnlicher Inhalte zu vermeiden.",
	"components.xieyi.182j2p": "Geräteinformationen: Wir empfangen und speichern gerätebezogene Informationen (einschließlich Gerätemodell, Betriebssystemversion, Geräteeinstellungen, MAC-Adresse, IMEI, IDFA, OAID, Android) basierend auf Ihren spezifischen Vorgängen während des Zugriffs und/oder der Nutzung",
	"components.xieyi.1ns292": "Gerätekennung, Geräteumgebung, Software- und Hardwarefunktionsinformationen für mobile Anwendungen sowie standortbezogene Informationen des Geräts (einschließlich Ihres autorisierten GPS-Standorts, WLAN-Zugangspunkts, Bluetooth und Sensorinformationen für Basisstationen).",
	"components.xieyi.y2hur5": "Service-Log-Informationen: Wenn Sie darauf zugreifen oder diese nutzen",
	"components.xieyi.2l7v45": "Bei der Nutzung der Plattform erfassen und speichern wir automatisch Ihre detaillierten Nutzungsinformationen als Service-Protokoll, einschließlich Browseraufzeichnungen, Klickdatensätze, Suchabfragedatensätze, Zuschauer, Berichte, Transaktionen, After-Sales, Follow, Teilen von Informationen, Veröffentlichungsinformationen sowie IP-Adresse, Browsertyp, Telekommunikationsbetreiber, Nutzungssprache, Zugriffsdatum und -zeit.",
	"components.xieyi.i2q27g": "Bitte beachten Sie, dass einzelne Geräteinformationen und Serviceprotokollinformationen nicht die Identität einer bestimmten natürlichen Person identifizieren können.",
	"components.xieyi.48iqgk": "Wenn wir diese Art von nicht personenbezogenen Daten mit anderen Informationen kombinieren, um die Identität einer bestimmten natürlichen Person zu identifizieren, oder sie in Kombination mit personenbezogenen Daten verwenden, werden diese Art von nicht personenbezogenen Daten während des kombinierten Nutzungszeitraums als personenbezogene Daten betrachtet.",
	"components.xieyi.2e8s23": "In der Zwischenzeit bemühen wir uns, Ihre Privatsphäre durch die folgenden Maßnahmen sicherzustellen:",
	"components.xieyi.83qc71": "(1) Wenn Sie Ihre Browser- und Suchdatensätze löschen möchten, können Sie sie in 'Meine Fußabdrücke' oder 'Suchfeld Letzte Suchanfragen' löschen;",
	"components.xieyi.72qs2r": "(2) Wenn Sie bei der Nutzung unseres On-Site-Suchdienstes Suchergebnisse anzeigen möchten, die nicht auf Ihre persönlichen Eigenschaften zugeschnitten sind, können Sie auf der Suchergebnisseite auf 'Filter' klicken, um diese einzurichten;",
	"components.xieyi.35ps8a": "(3) Wenn Sie die personalisierten Anzeigen verwalten möchten, die wir Ihnen senden, finden Sie diese unter'",
	"components.xieyi.777kl1": "Mein Profil Einstellungen (obere rechte Ecke)Datenschutz",
	"components.xieyi.8026t2": "Stell ihn ein. Bitte beachten Sie, dass Sie festlegen können, dass wir Ihnen mit bestimmten Kategorieninformationen keine relevante Werbung anzeigen, aber Sie werden trotzdem Anzeigen sehen. Die Anzahl der Anzeigen, die Sie sehen, ändert sich nicht, aber die Relevanz der Anzeigen nimmt ab.",
	"components.xieyi.5hx7h7": "Wenn Sie die personalisierten Inhalte verwalten möchten, klicken Sie auf die Schaltfläche'",
	"components.xieyi.5vxbqy": "Mein Profil Einstellungen (obere rechte Ecke)Datenschutz",
	"components.xieyi.8wfq82": "'Stell es ein. Nach Abschluss der Empfehlungsmodule wie 'Empfehlungen' und 'Empfehlungen für Sie' werden zufällige Empfehlungen und nicht personalisierte Empfehlungen sein.",
	"components.xieyi.f98v89": "(4) Wenn Sie Interesse- und Hobby-Tags mit Ihren persönlichen Eigenschaften löschen möchten, können Sie dies über",
	"components.xieyi.3ve6e7": "(5) Wenn Sie nicht an den auf der Homepage angezeigten Produkten interessiert sind, können Sie die Anzeige verwandter Produkte reduzieren, indem Sie lange auf das empfohlene Produktbild drücken und in der Popup-Eingabeaufforderung 'Produkt nicht interessiert' auswählen.",
	"components.xieyi.6mlx8c": "2. Push- und Erinnerungsdienste für geschäftliche Informationen und Ereignisse",
	"components.xieyi.1y06bp": "Um Ihnen zu helfen, besser zu verstehen und zu verwenden",
	"components.xieyi.t45916": "Sie stimmen zu, dass wir Ihnen Geschäftsinformationen und andere Ereigniserinnerungen zusenden,",
	"components.xieyi.q2y74e": "Der Dienstleister erhebt und verwendet Ihren Namen und Ihre Kontaktdaten auf der Grundlage der oben genannten Serviceziele.",
	"components.xieyi.84b8nh": "Wenn Sie unsere Push-Nachrichten nicht erhalten möchten,",
	"components.xieyi.eh6abn": "Sie können es über 'Meine Einstellungen' (obere rechte Ecke unter Nachrichteneinstellungen) deaktivieren.",
	"components.xieyi.3drpe9": "Wenn Sie die kommerzielle Werbung, die wir Ihnen senden, nicht annehmen möchten,",
	"components.xieyi.16389e": "Sie können den Newsletter abbestellen oder schließen, indem Sie auf die SMS-Aufforderung antworten oder andere von uns bereitgestellte Methoden verwenden;",
	"components.xieyi.i69xy9": "Wenn Sie die Push-Funktion deaktivieren oder abbestellen, können Sie keine Dienste zum Pushen von Produkt- oder Veranstaltungsinformationen erhalten.",
	"components.xieyi.2s65pg": "3. Sensorinformationen.",
	"components.xieyi.vn4s8x": "Wenn Sie unsere Produkte/Dienstleistungen nutzen, können wir Informationen über Ihre Sensoren sammeln, um sich an Ihren Gerätestatus anzupassen oder Ihre Aktionen zu erkennen, wenn Sie sich für die Teilnahme an Aktivitäten entscheiden. Die Arten von Sensoren können in verschiedenen Szenarien variieren und wir verwenden sie nur in bestimmten Szenarien. Zum Beispiel wird es während der Aufnahme verwendet, um festzustellen, ob der Benutzer den Bildschirm während der Aufnahme dreht, so dass es bequem ist, das aufgenommene Video zu korrigieren und zu optimieren.",
	"components.xieyi.opxu33": "(3) Bereitstellung der Funktionen zum Beobachten, Verfolgen und Teilen",
	"components.xieyi.46wa51": "Beim Stöbern",
	"components.xieyi.sm4d35": "Während des Plattformprozesses haben Sie die Möglichkeit, Produkte, Dienstleistungen und/oder andere Nutzer von Interesse zu beobachten und über die von uns bereitgestellten funktionalen Komponenten Produkt-/Dienstleistungsinformationen mit Dritten zu teilen. Während Ihrer Nutzung der oben genannten Funktionen erfassen wir Informationen einschließlich Ihrer",
	"components.xieyi.qt6mj3": "Betrieb",
	"components.xieyi.k465t1": "Aufzeichnung",
	"components.xieyi.13pdf3": "Zusammenarbeit",
	"components.xieyi.j6413h": "Die Service-Log-Informationen, einschließlich Beziehungen und Freigabehistorie, werden verwendet, um die oben genannten Funktionen und andere klar angegebene Zwecke zu erreichen.",
	"components.xieyi.g5yo4i": "Sie können Ihre Zuschauer löschen und Informationen in 'Meine Beobachter' und 'Meine Follower' folgen.",
	"components.xieyi.es68il": "(4) Unterstützung bei der Auftragserteilung und Auftragsverwaltung",
	"components.xieyi.439hc7": "Wenn Sie bestimmte Produkte und/oder Dienstleistungen in unseren Produkten und/oder Dienstleistungen bestellen, generieren wir eine Bestellung für Sie, das Produkt und/oder die Dienstleistung über das System zu erwerben. Während des Bestellvorgangs müssen Sie mindestens Ihre",
	"components.xieyi.4zfg7p": "Name, Adresse und Telefonnummer des Empfängers",
	"components.xieyi.875811": "Für einige besondere Arten von Waren und Dienstleistungen müssen Sie auch andere erforderliche Informationen für die Waren oder Dienstleistungen angeben, z. B. diejenigen im Zusammenhang mit",
	"components.xieyi.sg3r7o": "Beim Umgang mit Kulturgütern müssen Sie Ihren echten Namen und Ihre ID-Nummer angeben, um die von Gesetzen und Verordnungen zum Schutz von Kulturgütern und zur Überwachung und Verwaltung von Kulturgütern geforderte Einreichung abzuschließen.",
	"components.xieyi.x52n3c": "Gleichzeitig enthält die Bestellung Informationen über die von Ihnen erworbenen Waren und/oder Dienstleistungen, die spezifische Produktnummer und den zu zahlenden Betrag. Wir sammeln diese Informationen, um Sie bei der reibungslosen Abwicklung Ihrer Transaktion zu unterstützen, die Sicherheit Ihrer Transaktion zu gewährleisten, Ihre Anfrage nach Bestellinformationen zu erleichtern, Kundendienst und Kundendienst zu bieten und andere Zwecke, die wir eindeutig angegeben haben.",
	"components.xieyi.633jos": "Sie können",
	"components.xieyi.s43d5n": "Die Plattform bestellt Waren und/oder Dienstleistungen für andere, und Sie müssen die oben genannten personenbezogenen Daten des tatsächlichen Empfängers angeben und sicherstellen, dass Sie die Zustimmung des tatsächlichen Empfängers eingeholt haben.",
	"components.xieyi.q3y8ui": "Um Ihnen das Verständnis, die Abfrage und die Verwaltung von Bestellinformationen zu erleichtern, erfassen wir Bestellinformationen, die während Ihrer Nutzung unserer Dienste generiert werden, zur Anzeige und zur Erleichterung Ihrer Auftragsverwaltung.",
	"components.xieyi.prc15q": "Sie können Ihre Bestellinformationen über 'Meine Bestellungen' einsehen und verwalten.",
	"components.xieyi.ncd26i": "(5) Unterstützung beim Abschluss der Zahlung",
	"components.xieyi.3u52sy": "Um die Bestellzahlung abzuschließen, müssen Sie bei der Zahlung die Zahlungsmethode und den Zahlungskanal auswählen. Wir werden Ihre personenbezogenen Daten, Bestellzahlungsinformationen, Geräteinformationen zur Auftragssicherheit und andere notwendige gesetzliche Anforderungen zur Bekämpfung der Geldwäsche an das Zahlungsunternehmen über eingebettete Zahlungsunternehmen-Tool-Entwicklungspakete (in dieser Richtlinie als 'SDKs' bezeichnet) auf der Website oder dem Kunden weitergeben. Wenn Sie sich dafür entscheiden, dass andere Finanzinstitute Zahlungsdienste für Sie erbringen, geben wir Ihre erforderlichen Bankkartenzahlungsinformationen, einschließlich Bankkartennummer und Gültigkeitsdauer, auch an das entsprechende Finanzinstitut weiter, das Sie wählen.",
	"components.xieyi.m65845": "Damit wir Ihren Zahlungsfortschritt und -status umgehend informieren und bestätigen und Ihnen After-Sales- und Streitbeilegungsdienste anbieten können, erklären Sie sich damit einverstanden, dass wir Informationen über den Zahlungsfortschritt von dem Handelspartner, dem Zahlungsunternehmen oder einem anderen von Ihnen gewählten Finanzinstitut erfassen können.",
	"components.xieyi.bydbtx": "Sie können Ihre",
	"components.xieyi.rarv1g": "Die Bankkontoinformationen, die an das Plattformwallet-Konto oder die Anwendung gebunden sind, um das gebundene Bankkonto zu ersetzen.",
	"components.xieyi.73148a": "(6) Unterstützung bei der Lieferung von Waren oder Dienstleistungen an Sie",
	"components.xieyi.f8ce6b": "Um eine reibungslose, sichere und genaue Lieferung und Bereitstellung der von Ihnen gekauften Waren und/oder Dienstleistungen zu gewährleisten, erkennen Sie an und stimmen zu, dass die entsprechende Logistik- und Vertriebsgesellschaft zwangsläufig über Ihre Lieferinformationen informiert wird und diese verwendet, um den Lieferzweck zu erfüllen.",
	"components.xieyi.wi14jv": "Um Ihnen After-Sales- und Streitbeilegungsdienste anbieten zu können, müssen wir rechtzeitig über den Lieferfortschritt und -status informiert werden. Sie erklären sich damit einverstanden, dass wir Informationen über den Lieferfortschritt von Logistikdienstleistern sammeln können.",
	"components.xieyi.h3txyu": "(7) Kundenservice und Streitbeilegung",
	"components.xieyi.75p62d": "Wenn Sie mit uns Kontakt aufnehmen oder die Beilegung von Verkaufs-, After-Sales- oder Streitigkeiten beantragen, um die Sicherheit Ihres Kontos und Systems zu gewährleisten,",
	"components.xieyi.u3u59b": "Wir benötigen von Ihnen die erforderlichen personenbezogenen Daten, um Ihre Benutzeridentität zu überprüfen.",
	"components.xieyi.r9ucq4": "Zur Erleichterung der Kontaktaufnahme mit Ihnen, zur Unterstützung bei der schnellstmöglichen Lösung von Problemen oder zur Aufzeichnung der Lösungen und Ergebnisse verwandter Probleme können wir Ihre Kommunikation, Kommunikations-/Anruf-/Videoaufzeichnungen und damit zusammenhängende Inhalte mit uns speichern (einschließlich Kontoinformationen, Bestellinformationen, andere Informationen, die Sie angeben, um relevante Fakten zu beweisen, oder Kontaktinformationen, die Sie hinterlassen). Wir verwenden Ihre Kontoinformationen und Bestellinformationen.",
	"components.xieyi.te87ps": "Um Dienstleistungen bereitzustellen und die Servicequalität soweit erforderlich zu verbessern, können wir auch Ihre anderen Informationen verwenden, einschließlich der relevanten Informationen, die Sie bei der Kontaktaufnahme mit dem Kundendienst angegeben haben, und der Fragebogenantwort, die Sie uns bei der Teilnahme an der Umfrage gesendet haben.",
	"components.xieyi.259477": "(8) Wir bieten Ihnen die Funktion der öffentlichen Veröffentlichung von Kommentaren, Fragen und Antworten und anderen Informationen",
	"components.xieyi.67h2ep": "Sie können Informationen öffentlich über Kommentare, Communities, Live-Übertragungen und andere Funktionen zur Veröffentlichung von Informationen veröffentlichen, die wir Ihnen zur Verfügung stellen, einschließlich der Möglichkeit, Grafik-/Videoinhalte zu veröffentlichen, Inhalte zu überprüfen usw. als Benutzer, sowie die Möglichkeit, Produkt- und/oder Dienstleistungsbezogene Informationen zu veröffentlichen und verwandte Informationen als Verkäufer zu speichern.",
	"components.xieyi.194252": "Wir können anhand Ihres Benutzertyps und Ihrer Netzwerkprotokollinformationen feststellen, ob Sie für entsprechende funktionale Berechtigungen berechtigt sind (z. B. können nur Benutzer, die ein bestimmtes Produkt gekauft haben, Bewertungen darüber abgeben).",
	"components.xieyi.x8rr9h": "Bitte beachten Sie, dass die Informationen, die Sie öffentlich veröffentlichen, persönliche Informationen oder sogar sensible persönliche Informationen von Ihnen oder anderen beinhalten können.Wenn Sie sich entscheiden, Bilder mit persönlichen Informationen hochzuladen, während Sie unsere Dienste nutzen, seien Sie bitte vorsichtiger und überlegen Sie, ob Sie relevante Informationen teilen oder sogar öffentlich teilen möchten. Wenn die Informationen, die Sie öffentlich veröffentlichen, personenbezogene Daten anderer beinhalten, müssen Sie vor der Veröffentlichung deren Zustimmung einholen.",
	"components.xieyi.h60ox8": "(9) Ihnen Sicherheit zu bieten",
	"components.xieyi.q466n3": "Um der gesetzlichen Verpflichtung nachzukommen, die Sicherheit von E-Commerce-Transaktionen zu gewährleisten, die Sicherheit Ihrer Nutzung von Diensten zu verbessern, die von uns und unseren verbundenen Unternehmen und Partnern bereitgestellt werden, Ihre persönliche und Eigentumssicherheit vor Verletzungen zu schützen, Sicherheitsrisiken wie Phishing-Websites, Betrug, Netzwerkschwachstellen, Computerviren, Netzwerkangriffen und Netzwerkeinbrüchen besser zu verhindern und Verstöße gegen Gesetze und Vorschriften genauer zu identifizieren",
	"components.xieyi.3p5627": "Wir betten das von unserem verbundenen Unternehmen entwickelte Anwendungssicherheits-SDK in die Anwendung ein, um Ihre Geräteinformationen bezüglich plattformbezogener Protokolle und Regeln zu erfassen",
	"components.xieyi.4n220b": "Service-Log-Informationen, Gerätesensordaten, häufig verwendete Software-Informationen und mögliche Verwendung oder Integration Ihrer Benutzerinformationen, Transaktionsinformationen, Geräteinformationen, Service-Log-Informationen sowie Informationen, die von unseren verbundenen Unternehmen und Partnern autorisiert oder rechtlich weitergegeben werden (wobei wir MAC-Adressen, IMEI-gerätebezogene Informationen für Risikotests während des Anwendungsbackend-Betriebs erfassen können), Um die Risiken Ihres Kontos und Ihrer Transaktionen umfassend einzuschätzen, Identitätsprüfungen durchzuführen, Sicherheitsvorfälle zu erkennen und zu verhindern und erforderliche Aufzeichnungs-, Audit-, Analyse- und Entsorgungsmaßnahmen in Übereinstimmung mit dem Gesetz zu ergreifen.",
	"components.xieyi.4ay5f9": "(10) APP Selbststart oder zugehöriger Start",
	"components.xieyi.i2rhwf": "Um die Benutzererfahrung bei der Nutzung unserer Dienste zu verbessern und die Transaktionssicherheit besser zu gewährleisten, müssen wir die Selbststart- oder zugehörige Start-Funktion der App verwenden, um Benutzer beim Abruf unserer aktualisierten Version der App-Anwendungsdienste aufzufordern und zu unterstützen.",
	"components.xieyi.46wk64": "(11) Andere zusätzliche Dienstleistungen für Sie",
	"components.xieyi.9w14b7": "Um Ihnen bequemere, qualitativ hochwertige und personalisierte Produkte und/oder Dienstleistungen anbieten zu können und um Ihre Erfahrung zu verbessern, können wir Ihre personenbezogenen Daten in den folgenden zusätzlichen Dienstleistungen sammeln und verwenden, die Ihnen zur Verfügung gestellt werden.",
	"components.xieyi.7jt388": "Sie können wählen, ob Sie diese Informationen nach eigenem Ermessen zur Verfügung stellen. Wenn Sie diese Informationen nicht bereitstellen, wird dies Ihre Nutzung nicht beeinträchtigen.",
	"components.xieyi.1c6h45": "Basisdienstleistungen,",
	"components.xieyi.035322": "Sie können jedoch möglicherweise nicht auf die Benutzererfahrung zugreifen, die Ihnen diese zusätzlichen Dienste bieten.",
	"components.xieyi.w541k9": "Sie können den Status der oben genannten Berechtigungen Punkt für Punkt in den 'Einstellungen' Ihres Geräts oder in den 'My Settings Privacy System Permissions' unseres Clients überprüfen und entscheiden, ob Sie diese Berechtigungen aktivieren oder deaktivieren möchten.",
	"components.xieyi.t77ks8": "Bitte beachten Sie, dass Sie uns durch das Aktivieren einer Erlaubnis ermächtigen, relevante personenbezogene Daten zu sammeln und zu verwenden, um Ihnen entsprechende Dienste bereitzustellen. Sobald Sie eine Erlaubnis schließen, bedeutet dies, dass Sie die Genehmigung widerrufen. Wir werden nicht mehr relevante personenbezogene Daten auf der Grundlage der entsprechenden Erlaubnis sammeln und verwenden, noch können wir Ihnen weiterhin die entsprechenden Dienste zur Verfügung stellen. Ihre Entscheidung, Berechtigungen zu schließen, hat keinen Einfluss auf die Erfassung und Verwendung von Informationen basierend auf Ihrer Berechtigung.",
	"components.xieyi.q563dr": "Sie können",
	"components.xieyi.bs34gv": "Klicken Sie hier",
	"components.xieyi.w6lbn0": "Anwendungsberechtigungen und Gebrauchsanweisungen anzeigen",
	"components.xieyi.835h48": "(12) Andere Regeln für die Erhebung und Verwendung personenbezogener Daten",
	"components.xieyi.jy12wf": "Wenn die von Ihnen bereitgestellten Informationen personenbezogene Daten anderer Nutzer enthalten, wenden Sie sich bitte an",
	"components.xieyi.m6i6t5": "Bevor Sie diese personenbezogenen Daten auf der Plattform bereitstellen, müssen Sie sicherstellen, dass Sie eine gesetzliche Genehmigung erhalten haben.",
	"components.xieyi.a1o5v4": "Wenn wir die Informationen für andere Zwecke verwenden, die nicht in dieser Richtlinie angegeben sind, oder Informationen für bestimmte Zwecke für andere Zwecke sammeln, werden wir Ihre Zustimmung im Voraus einholen, es sei denn, dies ist durch Gesetze und Vorschriften vorgeschrieben.",
	"components.xieyi.41gslf": "3. Wenn wir Ihre Daten indirekt von einem Dritten beziehen, werden wir vor der Erhebung ausdrücklich schriftlich auffordern, dass der Dritte die Quelle seiner personenbezogenen Daten erklärt und ob er Ihre gesetzliche Genehmigung für die Erhebung, Verarbeitung und Bereitstellung Ihrer personenbezogenen Daten an uns eingeholt hat. Sofern nicht ausdrücklich gesetzlich zulässig, erheben wir Ihre personenbezogenen Daten nur von Dritten, nachdem wir bestätigt haben, dass diese Ihre Zustimmung und Zustimmung eingeholt haben. Wenn der Umfang der Genehmigung des Dritten unsere Verarbeitungs- und Nutzungszwecke nicht umfasst, werden wir Ihre personenbezogenen Daten selbst verarbeiten oder verlangen, dass der Dritte Ihre Zustimmung einholt, bevor wir fortfahren. Gleichzeitig stärken wir die Sicherheit personenbezogener Daten (einschließlich Meldung sensibler Informationen, verschlüsselte Speicherung sensibler Informationen, Zugangskontrolle usw.). Wir werden nicht weniger als die gleichen Schutzmaßnahmen und Maßnahmen anwenden, wie wir es für unsere eigenen personenbezogenen Daten tun, um indirekt erhaltene personenbezogene Daten zu schützen.",
	"components.xieyi.x3r3y6": "4. Ausnahmen von der Einholung einer autorisierten Einwilligung",
	"components.xieyi.3jev4v": "Gemäß den einschlägigen Gesetzen und Vorschriften benötigen wir keine Genehmigung oder Zustimmung, um Ihre personenbezogenen Daten in den folgenden Situationen zu verarbeiten:",
	"components.xieyi.214uq6": "(1) Notwendig zur Erfüllung gesetzlicher Pflichten oder Pflichten;",
	"components.xieyi.81k4nu": "(2) in direktem Zusammenhang mit der nationalen Sicherheit, der nationalen Verteidigungssicherheit und wichtigen öffentlichen Interessen;",
	"components.xieyi.j7fomu": "(3) Reaktion auf plötzliche Notfälle der öffentlichen Gesundheit;",
	"components.xieyi.7806cy": "(4) Durchführung von Nachrichtenberichterstattung, Überwachung der öffentlichen Meinung und anderer Verhaltensweisen im öffentlichen Interesse und Verarbeitung personenbezogener Daten in einem angemessenen Umfang;",
	"components.xieyi.754474": "(5) In Notsituationen, wenn es nicht möglich ist, Sie rechtzeitig oder wirksam zu informieren, um Ihr Leben, Ihre Gesundheit oder erhebliche Vermögenssicherheit zu schützen, Sie aber umgehend informieren, nachdem die Notsituation geklärt ist;",
	"components.xieyi.i1yv2t": "(6) gemäß den einschlägigen Gesetzen und Vorschriften personenbezogene Daten zu behandeln, die von sich selbst oder anderen Personen, die bereits rechtlich offengelegt wurden, in einem angemessenen Umfang offengelegt wurden;",
	"components.xieyi.5dz1kl": "(7) gemäß den erforderlichen Vereinbarungen oder anderen schriftlichen Dokumenten, die mit Ihnen unterzeichnet und erfüllt wurden;",
	"components.xieyi.5bon72": "(8) Notwendig für die Aufrechterhaltung des sicheren und stabilen Betriebs der bereitgestellten Produkte und/oder Dienstleistungen, wie die Entdeckung und Behebung von Fehlern an den Produkten und/oder Dienstleistungen;",
	"components.xieyi.cw60x0": "(9) Andere in Gesetzen und Verordnungen festgelegte Situationen.",
	"components.xieyi.vyw19u": "Bitte beachten Sie, dass nach den geltenden geltenden Gesetzen, wenn wir",
	"components.xieyi.4t3bn8": "Technische und andere notwendige Maßnahmen ergreifen, um personenbezogene Daten zu verarbeiten, damit der Datenempfänger bestimmte Personen nicht erneut identifizieren und nicht wiederherstellen kann",
	"components.xieyi.1c74f9": "Oder wir führen Recherchen zur Identifizierung, statistische Analysen und Vorhersagen der gesammelten Informationen durch, um sie zu verbessern",
	"components.xieyi.1m85sf": "Inhalt und Layout der Plattform, Bereitstellung von Produkt- oder Serviceunterstützung für Geschäftsentscheidungen und Verbesserung unserer Produkte und Dienstleistungen (einschließlich der Verwendung anonymer Daten für maschinelles Lernen oder Modellalgorithmus-Training),",
	"components.xieyi.me4rhf": "Die Nutzung dieser verarbeiteten Daten erfordert keine gesonderte Mitteilung an Sie und Ihre Zustimmung",
	"components.xieyi.257c58": "3,Wie verwenden wir es",
	"components.xieyi.77f388": "Und ähnliche Technologien",
	"components.xieyi.759p76": "(1) Die Verwendung von Cookies",
	"components.xieyi.48ze34": "Um den normalen Betrieb der Website zu gewährleisten, Ihnen einen einfacheren Zugriff zu bieten und Inhalte zu empfehlen, die Sie interessieren könnten, speichern wir Cookies, Flash-Cookies oder andere lokale Speicher, die vom Browser (oder zugehörigen Anwendungen) bereitgestellt werden, die typischerweise Identifikatoren, Website-Namen und einige Zahlen und Zeichen (zusammen als 'Cookies' bezeichnet) auf Ihrem Computer oder Mobilgerät enthalten. Mit Hilfe von",
	"components.xieyi.s020rs": "Wenn Ihr Browser oder Browser-Add-on-Dienst dies zulässt, können Sie die Akzeptanzstufe von Cookies ändern oder unsere Cookies ablehnen.",
	"components.xieyi.3w12li": "Weitere Informationen finden Sie unter AboutCookies.org. Wenn Sie jedoch die in dieser Klausel beschriebenen Aktionen ausführen, kann dies in einigen Fällen Ihren sicheren Zugriff auf unsere Website beeinträchtigen, und Sie müssen möglicherweise die Benutzereinstellungen jedes Mal ändern, wenn Sie unsere Website besuchen.",
	"components.xieyi.q45ikb": "(2) Die Verwendung ähnlicher Technologien in Cookies",
	"components.xieyi.h550rh": "außer",
	"components.xieyi.7248f8": "Darüber hinaus werden wir auf der Website auch andere ähnliche Technologien wie Website Beacons, Pixel Labels, ETags usw. verwenden.",
	"components.xieyi.jvxl8f": "Zum Beispiel kann die E-Mail, die wir Ihnen senden, einen Adresslink zu unseren Website-Inhalten enthalten. Wenn Sie auf diesen Link klicken, verfolgen wir den Klick, um uns zu helfen, Ihre Produkt- oder Servicepräferenzen zu verstehen, so dass wir die Benutzererfahrung proaktiv verbessern können. Ein Website Beacon ist normalerweise ein transparentes Bild, das in eine Website oder E-Mail eingebettet ist. Durch die Verwendung von Pixel-Labels in E-Mails können wir feststellen, ob die E-Mail geöffnet wurde. Wenn Sie nicht möchten, dass Ihre Aktivitäten auf diese Weise verfolgt werden, können Sie sich jederzeit von unserer Mailingliste abmelden.",
	"components.xieyi.52wb6a": "ETag (Entity Tag) ist ein HTTP-Protokoll-Header, der zwischen einem Internetbrowser und einem Internetserver übertragen wird und Cookies ersetzen kann. ETag kann uns helfen, unnötige Serverlast zu vermeiden, die Serviceeffizienz zu verbessern und Ressourcen und Energie zu sparen. In der Zwischenzeit können wir ETag verwenden, um Ihre Identität zu erfassen, damit wir unsere Produkte oder Dienstleistungen besser verstehen und verbessern können.",
	"components.xieyi.y92k4u": "Die meisten Browser bieten Benutzern die Funktion, Browser-Cache-Daten zu löschen, und Sie können entsprechende Datenlöschungsvorgänge in der Browser-Einstellungsfunktion durchführen.",
	"components.xieyi.o54yhy": "Bitte beachten Sie jedoch, dass Sie, wenn Sie ETag deaktivieren, möglicherweise kein besseres Produkt- oder Serviceerlebnis genießen können.",
	"components.xieyi.37e045": "(1) Freigabe",
	"components.xieyi.675822": "Wir werden nicht interagieren mit",
	"components.xieyi.x1y6r8": "Teilen Sie Ihre personenbezogenen Daten mit Unternehmen, Organisationen und Einzelpersonen außerhalb Ihrer Organisation, mit Ausnahme der folgenden Situationen:",
	"components.xieyi.8q0s05": "1. Weitergabe unter rechtlichen Umständen: Wir geben Ihre personenbezogenen Daten gemäß den gesetzlichen Vorschriften an die Öffentlichkeit weiter.",
	"components.xieyi.9irym2": "2. Weitergabe mit ausdrücklicher Zustimmung: Nach Erhalt Ihrer ausdrücklichen Zustimmung geben wir Ihre personenbezogenen Daten an andere Parteien weiter.",
	"components.xieyi.371i1r": "3. Bei Bedarf stellen wir Ihnen die Produkte oder Dienstleistungen zur Verfügung, die Sie benötigen und teilen sie mit Ihnen durch:",
	"components.xieyi.75u39x": "Beim Kauf von Waren oder Dienstleistungen auf der Plattform teilen wir die notwendigen transaktionsbezogenen Informationen in Ihren Bestellinformationen mit den Anbietern der relevanten Waren oder Dienstleistungen basierend auf Ihrer Wahl, um Ihren Transaktions-, Liefer- und After-Sales-Service-Bedarf zu erfüllen.",
	"components.xieyi.u6d81m": "4. Weitergabe an verbundene Unternehmen:",
	"components.xieyi.30si92": "Für die Bequemlichkeit von uns basierend auf",
	"components.xieyi.6pysc9": "Das Plattformkonto stellt Ihnen Produkte und Dienstleistungen zur Verfügung, empfiehlt Informationen, die Sie interessieren könnten, identifiziert Anomalien bei Benutzerkonten und schützt",
	"components.xieyi.7vmrlb": "Die persönliche Sicherheit von verbundenen Unternehmen, anderen Nutzern oder der Öffentlichkeit ist vor Verletzungen geschützt, und Ihre personenbezogenen Daten können mit unseren verbundenen Unternehmen und/oder deren benannten Dienstleistern geteilt werden.",
	"components.xieyi.269i0k": "Wenn wir Ihre sensiblen personenbezogenen Daten weitergeben oder wenn verbundene Unternehmen die Verwendungs- und Verarbeitungszwecke von personenbezogenen Daten ändern, werden wir Ihre Zustimmung und Zustimmung erneut einholen.",
	"components.xieyi.5475kt": "5. Vertraute autorisierte Partner:",
	"components.xieyi.u1sa8e": "Wir können autorisierte Partner damit beauftragen, Ihnen bestimmte Dienstleistungen zur Verfügung zu stellen oder Funktionen in unserem Namen auszuführen,",
	"components.xieyi.f1h4lc": "Wir geben Ihre Daten nur für die legitimen, legitimen, notwendigen, spezifischen und klaren Zwecke weiter, die in dieser Erklärung angegeben sind. Autorisierte Partner haben nur Zugriff auf die Informationen, die sie zur Erfüllung ihrer Pflichten benötigen. Wir werden sie durch eine Vereinbarung dazu verlangen, diese Informationen nicht für andere Zwecke zu verwenden.",
	"components.xieyi.g700e1": "Derzeit gehören zu unseren autorisierten Partnern die folgenden Arten:",
	"components.xieyi.36nvwn": "(1) Autorisierte Partner für Werbe- und Analysedienste. Wir werden diese Partner mit der Verarbeitung von Informationen in Bezug auf Werbeabdeckung und Effektivität betrauen, aber ohne Ihre Zustimmung werden wir Ihre persönlichen Identifikationsdaten nicht an Partner weitergeben, die Werbe- und Analysedienste anbieten, oder wir werden diese Informationen deidentifizieren, damit autorisierte Partner Sie nicht persönlich erkennen können. Diese Art von Partnern kann die oben genannten Informationen mit anderen Daten kombinieren, die sie gesetzlich erhalten, um unsere beauftragten Werbedienstleistungen oder Entscheidungsempfehlungen durchzuführen.",
	"components.xieyi.5xh5u7": "(2) Lieferanten, Dienstleister und andere Partner. Wir senden Informationen an Lieferanten, Dienstleister und andere Partner, die unser Geschäft unterstützen, einschließlich Bereitstellung technischer Infrastrukturdienste, Analyse der Nutzung unserer Dienste, Messung der Effektivität von Werbung und Dienstleistungen, Bereitstellung von Kundenservice, Erleichterung von Zahlungen oder Durchführung akademischer Forschung und Untersuchungen, Logistik- und Vertriebsdienstleistungen, Zahlungsdienste, Datenverarbeitung usw. Unser Zweck bei der Weitergabe dieser Informationen besteht darin, die wichtigsten Einkaufsfunktionen unserer Produkte und/oder Dienstleistungen zu ermöglichen, wie die Weitergabe Ihrer Bestellinformationen an Logistikdienstleister, um die Lieferung zu arrangieren; Alternativ können wir Ihre Bestellnummer und Ihren Betrag an ein Drittanbieter-Zahlungsinstitut weitergeben, um Ihre Zahlungsanweisungen zu bestätigen und die Zahlung abzuschließen.",
	"components.xieyi.67e7b8": "(3) Authentifizierungsdienstleister für echten Namen. Wenn wir Ihnen Echtnamen-Authentifizierungsdienste anbieten, müssen Sie uns autorisieren, Ihre Echtnamen-Authentifizierungsdaten zu sammeln und diese Dritten zur Verfügung zu stellen (einschließlich, aber nicht beschränkt auf Tencent Cloud Computing (Beijing) Co., Ltd., nachfolgend als 'Drittanbieter-Organisationen' bezeichnet). Drittinstitute fragen und überprüfen unabhängig die Echtheit, Richtigkeit und Vollständigkeit solcher Informationen von relevanten Stellen (einschließlich, aber nicht beschränkt auf die Basisdatenbank persönlicher Kreditinformationen der People's Bank of China und anderer relevanter Regierungsbehörden, grundlegender Telekommunikationsbetreiber, Informationsinstitute, öffentliche Sicherheitsorgane, Staatsanwälte, Gerichte, Finanzinstitute, andere kooperative Institutionen oder öffentliche Kanäle), analysieren, vergleichen, verarbeiten und organisieren diese Informationen, Und stellen Sie uns einen umfassenden Auswertungsbericht zu Ihren persönlichen Kreditinformationen zur Verfügung.",
	"components.xieyi.12lv44": "Zur Gewährleistung der Sicherheit",
	"components.xieyi.3749fw": "Der stabile Betrieb der Plattform und die Implementierung der oben genannten Funktionen ermöglichen es Ihnen, mehr Dienste und Funktionen zu genießen und zu nutzen. Unsere Anwendung wird autorisierte Partner SDKs oder ähnliche Anwendungen einbetten.",
	"components.xieyi.3nos3g": "Sie können die Liste unserer verbundenen Partner mit Dritten teilen",
	"components.xieyi.70yu7f": "Überprüfung.",
	"components.xieyi.89014s": "Wir führen strenge Sicherheitskontrollen der Anwendungsprogramm-Schnittstellen (APIs) und Software Tool Development Kits (SDKs) durch, die von autorisierten Partnern verwendet werden, um relevante Informationen zu erhalten, und vereinbaren mit autorisierten Partnern strenge Datenschutzmaßnahmen, um sicherzustellen, dass sie personenbezogene Daten in Übereinstimmung mit dieser Richtlinie und allen anderen relevanten Vertraulichkeits- und Sicherheitsmaßnahmen behandeln.",
	"components.xieyi.1l5529": "(2) Übertragung",
	"components.xieyi.ls670h": "Wir werden Ihre personenbezogenen Daten nicht an Unternehmen, Organisationen oder Einzelpersonen weitergeben, außer in den folgenden Fällen:",
	"components.xieyi.u50832": "1. Weitergabe mit ausdrücklicher Zustimmung: Nach Erhalt Ihrer ausdrücklichen Zustimmung werden wir Ihre personenbezogenen Daten an andere Parteien weitergeben;",
	"components.xieyi.6snoud": "2. Im",
	"components.xieyi.411611": "Wenn es zu einer Fusion, Übernahme oder Insolvenzliquidierung oder anderen Situationen kommt, in denen personenbezogene Daten übertragen werden, werden wir Sie umgehend über den Namen und die Kontaktdaten des neuen Unternehmens oder der neuen Organisation informieren, die Ihre personenbezogenen Daten speichert und verlangen, dass diese weiterhin an diese Richtlinie gebunden sind. Andernfalls verlangen wir von dem Unternehmen, der Organisation und der Einzelperson, Ihre Genehmigung und Zustimmung erneut einzuholen.",
	"components.xieyi.783yjz": "(3) Offenlegung der Öffentlichkeit",
	"components.xieyi.zdq4b7": "Wir werden Ihre personenbezogenen Daten nur unter folgenden Umständen öffentlich offenlegen:",
	"components.xieyi.3in5j6": "1. Wir können Ihre personenbezogenen Daten mit Ihrer ausdrücklichen Zustimmung oder aufgrund Ihrer freiwilligen Wahl öffentlich offenlegen.",
	"components.xieyi.ey5b87": "2. Geben Sie Ihre personenbezogenen Daten auf der Grundlage der durch Gesetze und Vorschriften festgelegten Offenlegungspflichten im vorgeschriebenen Umfang bekannt, wie die Offenlegung von Verkäuferinformationen gemäß den Maßnahmen zur Überwachung und Verwaltung von Online-Transaktionen.",
	"components.xieyi.ok7p68": "Wenn wir feststellen, dass Sie gegen Gesetze und Vorschriften verstoßen oder schwere Verstöße begangen haben",
	"components.xieyi.6kb306": "Die Situation plattformbezogener Vereinbarungen und Regeln oder zum Schutz",
	"components.xieyi.41el81": "Wir können personenbezogene Daten über Sie offenlegen und die Erbringung von Dienstleistungen für Sie in Übereinstimmung mit Gesetzen und Vorschriften oder mit Ihrer Zustimmung einstellen.",
	"components.xieyi.4c16r3": "Einschließlich der damit verbundenen Verstöße und",
	"components.xieyi.7uq8p7": "Die Plattform hat Maßnahmen gegen Sie ergriffen. Zum Beispiel, wenn Sie ernsthaft gegen das Gesetz verstoßen, indem Sie gefälschte Waren verkaufen",
	"components.xieyi.kc0i6h": "Gemäß den Plattformregeln können wir die Authentifizierungsthema Ihres Shops, Verstöße und",
	"components.xieyi.100i6j": "Maßnahmen zur Handhabung.",
	"components.xieyi.veqih2": "(4) Ausnahmen von der Einholung einer Genehmigung und Zustimmung vor der Weitergabe, Übertragung oder öffentlichen Offenlegung personenbezogener Daten",
	"components.xieyi.i5fma7": "Gemäß den einschlägigen Gesetzen und Vorschriften erfordert die Weitergabe, Übertragung oder öffentliche Offenlegung Ihrer personenbezogenen Daten in den folgenden Situationen keine vorherige Genehmigung und Zustimmung:",
	"components.xieyi.r82363": "1. Notwendig zur Erfüllung gesetzlicher Pflichten oder Pflichten;",
	"components.xieyi.ett47l": "2. In direktem Zusammenhang mit nationaler Sicherheit, nationaler Verteidigungssicherheit und großen öffentlichen Interessen;",
	"components.xieyi.nql27g": "3. Um auf plötzliche Notfälle der öffentlichen Gesundheit zu reagieren;",
	"components.xieyi.r2138s": "4. Durchführung von Nachrichtenberichterstattung, Überwachung der öffentlichen Meinung und anderer Änderungen im öffentlichen Interesse, um personenbezogene Daten in einem angemessenen Umfang zu verarbeiten;",
	"components.xieyi.i32523": "5. In Notfallsituationen, wenn es nicht möglich ist, Sie rechtzeitig oder effektiv zu informieren, um Ihr Leben, Ihre Gesundheit oder erhebliche Vermögenssicherheit zu schützen, Sie aber umgehend informieren, nachdem die Notfallsituation gelöst ist;",
	"components.xieyi.4stqc6": "6. Gemäß den einschlägigen Gesetzen und Vorschriften, behandeln Sie personenbezogene Daten, die von Ihnen selbst oder anderen Personen, die bereits rechtlich offengelegt wurden, in einem angemessenen Umfang.",
	"components.xieyi.7k22k8": "Bitte beachten Sie, dass gemäß geltendem Recht,",
	"components.xieyi.7w6sng": "Wenn wir technische und andere notwendige Maßnahmen ergreifen, um personenbezogene Daten so zu verarbeiten, dass der Datenempfänger eine bestimmte Person nicht identifizieren kann und diese nicht wiederherstellen kann, bedarf die Weitergabe, Übertragung und öffentliche Offenlegung dieser verarbeiteten Daten keiner gesonderten Benachrichtigung an Sie und Ihrer Zustimmung.",
	"components.xieyi.t8w9o9": "(1) Datenschutztechnische Maßnahmen",
	"components.xieyi.5k26r2": "Wir haben angemessene und durchführbare Sicherheitsmaßnahmen ergriffen, die den Industriestandards entsprechen, um Ihre Daten zu schützen, unbefugten Zugriff, öffentliche Offenlegung, Verwendung, Änderung, Beschädigung oder Verlust personenbezogener Daten zu verhindern. Zum Beispiel verwenden wir Verschlüsselungstechnologie, um die Sicherheit personenbezogener Daten zu erhöhen, die beim Datenaustausch zwischen Ihrem Browser und Server geschützt sind.",
	"components.xieyi.824h5u": "SSL Protokoll Verschlüsselung Schutz, wir bieten auch",
	"components.xieyi.rs1e76": "Die Plattform bietet eine sichere Browsing-Methode unter Verwendung des HTTPS-Protokolls; Wir verwenden vertrauenswürdige Schutzmechanismen, um böswillige Angriffe auf personenbezogene Daten zu verhindern; Wir werden Zugriffskontrollmechanismen einsetzen, um sicherzustellen, dass nur autorisiertes Personal auf personenbezogene Daten zugreifen kann.",
	"components.xieyi.qj2xrx": "(2) Compliance-System für das Datenschutzmanagement",
	"components.xieyi.y1edur": "Wir haben ein fortschrittliches Compliance-System für Datensicherheitsmanagement etabliert, das sich um den Datenlebenszyklus in der Branche dreht und die Sicherheit personenbezogener Daten aus mehreren Dimensionen wie Organisationsaufbau, Systemdesign, Personalmanagement und Produkttechnologie erhöht. Wir werden eine Agentur für den Schutz personenbezogener Daten einrichten, um den Schutz personenbezogener Daten zu überwachen und Sicherheits- und Datenschutztrainings durchzuführen, um das Bewusstsein der Mitarbeiter für die Bedeutung des Schutzes personenbezogener Daten zu stärken.",
	"components.xieyi.d7ix35": "(3) Reaktion auf Sicherheitsvorfälle in Bezug auf personenbezogene Daten",
	"components.xieyi.2o454t": "Wenn unsere physischen, technischen oder verwaltungstechnischen Schutzeinrichtungen beschädigt werden, was zu unbefugtem Zugriff, öffentlicher Offenlegung, Manipulation oder Zerstörung von Informationen führt, die Ihre legitimen Rechte und Interessen schädigen, werden wir umgehend Notfallpläne aktivieren und angemessene Abhilfemaßnahmen ergreifen, um die Auswirkungen auf Ihre personenbezogenen Daten und andere Rechte und Interessen zu minimieren. Sollte es zu einem Sicherheitsvorfall mit personenbezogenen Daten kommen, informieren wir Sie auch entsprechend den gesetzlichen Anforderungen: die Ausgangslage und mögliche Auswirkungen des Sicherheitsvorfalls, die von uns ergriffenen oder ergriffenen Entsorgungsmaßnahmen, Vorschläge für Sie zur eigenständigen Vermeidung und Reduzierung von Risiken und Abhilfemaßnahmen für Sie. Wir werden Sie über die relevante Situation der Veranstaltung per E-Mail, Brief, Telefon, Push-Benachrichtigungen und andere Mittel informieren. Wenn es schwierig ist, die personenbezogenen Daten einzeln zu informieren, werden wir angemessene und wirksame Maßnahmen ergreifen, um eine Ankündigung zu veröffentlichen. Gleichzeitig werden wir auch den Umgang mit Sicherheitsvorfällen personenbezogener Daten gemäß den gesetzlichen Anforderungen melden.",
	"components.xieyi.4706wi": "(4) Sozialaufsicht",
	"components.xieyi.4ouosr": "Wir veröffentlichen regelmäßig Berichte zum Schutz personenbezogener Daten über soziale Verantwortung gemäß den einschlägigen nationalen Gesetzen und Verwaltungsvorschriften und akzeptieren die soziale Aufsicht.",
	"components.xieyi.6r5a4q": "(5) Autonome Verhinderung von Sicherheitsrisiken für Konten",
	"components.xieyi.veukf5": "Das Internet ist keine absolut sichere Umgebung, die es verwendet",
	"components.xieyi.ri9a0j": "Wir empfehlen Ihnen dringend, keine",
	"components.xieyi.382lb2": "Die von der Plattform empfohlene Kommunikationsmethode besteht darin, Ihre Nachricht zu senden. Bitte verwenden Sie ein komplexes Passwort, um die Sicherheit Ihres Kontos zu schützen.",
	"components.xieyi.s2h72a": "im Einsatz",
	"components.xieyi.8mtgk8": "Bei der Durchführung von Online-Transaktionen auf der Plattform ist es für Sie unvermeidlich, Ihre persönlichen Daten an Gegenparteien oder potenzielle Gegenparteien weiterzugeben, wie",
	"components.xieyi.6ue558": "Kontaktinformationen",
	"components.xieyi.5h8pl3": "Kontaktadresse",
	"components.xieyi.74n5cs": ". Darüber hinaus können Sie über unsere Dienste auch Kommunikation aufbauen, Informationen austauschen oder Inhalte mit Dritten analysieren. Bitte schützen Sie Ihre persönlichen Daten ordnungsgemäß und geben Sie sie nur an Dritte weiter, wenn dies notwendig ist. Wenn Sie feststellen, dass Ihre persönlichen Daten, insbesondere Ihr Konto oder Passwort, durchgesickert sind, kontaktieren Sie uns bitte umgehend.",
	"components.xieyi.1100gs": "Der offizielle Kundenservice der Plattform ermöglicht es uns, auf Basis Ihrer Bewerbung entsprechende Maßnahmen zu ergreifen.",
	"components.xieyi.p898io": "Bitte beachten Sie, dass die Informationen, die Sie freiwillig teilen oder sogar öffentlich teilen, wenn Sie unsere Dienste nutzen, persönliche Daten oder sogar sensible persönliche Daten von Ihnen oder anderen beinhalten können. Bitte seien Sie vorsichtiger, wenn Sie überlegen, ob Sie relevante Informationen bei der Nutzung unserer Dienste teilen oder sogar öffentlich teilen möchten.",
	"components.xieyi.pfhp6l": "(1) Lagerort",
	"components.xieyi.id973y": "Die personenbezogenen Daten, die während unserer Tätigkeit auf dem Gebiet der Volksrepublik China gesammelt und generiert werden, werden innerhalb Chinas gespeichert.",
	"components.xieyi.76w8ku": "Wenn es notwendig ist, Ihre personenbezogenen Daten an ausländische Parteien aufgrund von Geschäften oder Zwecken, die von dieser Richtlinie abgedeckt werden, zur Verfügung zu stellen, führen wir eine Folgenabschätzung zum Schutz personenbezogener Daten durch und erfassen die Verarbeitungssituation im Voraus in Übereinstimmung mit den einschlägigen Gesetzen und Vorschriften. Gleichzeitig werden wir Sie umgehend über den Namen, die Kontaktdaten, den Verarbeitungszweck und die Verarbeitungsmethode des ausländischen Informationsempfängers informieren. Die Arten der personenbezogenen Daten sowie die Methoden und Verfahren zur Ausübung der nationalen gesetzlichen Rechte an ausländischen Empfängern.",
	"components.xieyi.7fciuv": "(2) Lagerdauer",
	"components.xieyi.f1a2af": "Wir werden Ihre personenbezogenen Daten nur für den Zeitraum aufbewahren, der erforderlich ist, um die in dieser Richtlinie genannten Ziele zu erreichen, es sei denn, es gibt zwingende Aufbewahrungspflichten durch Gesetz,",
	"components.xieyi.42nn2k": "Zum Beispiel schreibt das Gesetz über den elektronischen Handel der Volksrepublik China vor, dass die Speicherdauer von Waren- und Dienstleistungsinformationen und Transaktionsinformationen nicht weniger als drei Jahre nach Abschluss der Transaktion beträgt. Wir beziehen uns hauptsächlich auf die folgenden Standards, um die Speicherdauer personenbezogener Daten zu bestimmen, und die längere gilt:",
	"components.xieyi.6hv739": "1. Erfüllung der Transaktionsziele in Bezug auf Sie, Pflege entsprechender Transaktions- und Geschäftsunterlagen, um auf Ihre möglichen Anfragen oder Beschwerden zu reagieren;",
	"components.xieyi.483k46": "2. Gewährleistung der Sicherheit und Qualität unserer Dienstleistungen für Sie;",
	"components.xieyi.v5a774": "3. Sind Sie mit einer längeren Aufbewahrungsfrist einverstanden;",
	"components.xieyi.7g6607": "Entsprechend den einschlägigen Anforderungen der Verjährungsfrist für Rechtsstreitigkeiten;",
	"components.xieyi.8iszne": "5. Besteht eine andere besondere Vereinbarung oder gesetzliche Regelung bezüglich der Aufbewahrungsfrist?",
	"components.xieyi.2xiglt": "Nach Ablauf der Aufbewahrungsfrist werden wir Ihre personenbezogenen Daten umgehend in Übereinstimmung mit den einschlägigen Gesetzen und Vorschriften verarbeiten.",
	"components.xieyi.7rg77i": "Zusätzlich zum Befolgen des in dieser Richtlinie vereinbarten Betriebspfads können Sie",
	"components.xieyi.t2esz7": "Neben dem Eigenbetrieb auf der Plattform können Sie Ihre Informationen auch über die folgenden Methoden abrufen und verwalten:",
	"components.xieyi.kb51e6": "(1) Suche, Korrektur und Ergänzung Ihrer Daten",
	"components.xieyi.nu7m3c": "Sie haben das Recht auf Auskunft, Berichtigung oder Ergänzung Ihrer Angaben. Sie können sich einloggen durch",
	"components.xieyi.bcd5d5": "Klicken Sie auf der Plattform auf 'Meine Einstellungen (obere rechte Ecke)', um das Käuferzentrum zu öffnen, um persönliche Informationen, persönliche Kontoinformationen, Kontosicherheit und Lieferadresse zu überprüfen und zu korrigieren.",
	"components.xieyi.043c4c": "(2) Löschen Sie Ihre Daten",
	"components.xieyi.2d2n9p": "Sie können einige Ihrer Informationen über die unter '(1) Abfrage, Korrektur und Ergänzung Ihrer Informationen' aufgeführten Methoden löschen.",
	"components.xieyi.8n2d00": "In den folgenden Situationen können Sie mit",
	"components.xieyi.4zh20s": "Der Kundendienst der Plattform hat uns kontaktiert, um die Löschung personenbezogener Daten zu beantragen:",
	"components.xieyi.25t31b": "1. Wenn unser Umgang mit personenbezogenen Daten gegen Gesetze und Vorschriften verstößt;",
	"components.xieyi.8tjokj": "2. Wenn wir Ihre personenbezogenen Daten ohne Ihre ausdrückliche Zustimmung erheben und verwenden;",
	"components.xieyi.4m1259": "3. Wenn unser Umgang mit personenbezogenen Daten ernsthaft gegen unsere Vereinbarung mit Ihnen verstößt;",
	"components.xieyi.q81de3": "4. Wenn Ihr Konto gelöscht wird.",
	"components.xieyi.xd43vl": "Wenn wir uns entscheiden, auf Ihre Löschungsanfrage zu antworten, werden wir auch die Parteien, die Ihre personenbezogenen Daten von uns erhalten haben, so weit wie möglich benachrichtigen und sie auffordern, diese rechtzeitig zu löschen (sofern nicht anderweitig durch Gesetze und Vorschriften vorgesehen oder diese Parteien unabhängig von Ihnen Ihre Zustimmung eingeholt haben).",
	"components.xieyi.1c21t6": "Wenn Sie oder wir Sie bei der Löschung relevanter Informationen unterstützen, können wir aufgrund geltender Gesetze und Sicherheitstechnikbeschränkungen möglicherweise nicht in der Lage sein, die entsprechenden Informationen sofort aus dem Sicherungssystem zu löschen. Wir werden Ihre personenbezogenen Daten sicher speichern und jede weitere Verarbeitung einschränken, bis das Backup gelöscht oder anonymisiert werden kann.",
	"components.xieyi.43p4r8": "(3) Ihr Konto kündigen",
	"components.xieyi.w2lnx4": "Sie können die Kündigung Ihres Kontos über die folgenden Methoden beantragen:",
	"components.xieyi.d17ah0": "1. Login",
	"components.xieyi.113v7u": "Plattform, senden Sie einen Antrag auf Kontostornierung über 'Mein Konto Einstellungen' (obere rechte Ecke des Kontosicherheitszentrums für Konto stornieren)';",
	"components.xieyi.390iqo": "2. Kontakt",
	"components.xieyi.n6o511": "Plattform Kundenservice, Unterstützung bei der Beantragung der Kündigung Ihres Kontos",
	"components.xieyi.g4i68q": "Nachdem Sie Ihr Konto freiwillig kündigen, werden wir aufhören, Ihnen Produkte oder Dienstleistungen zur Verfügung zu stellen und Ihre persönlichen Daten löschen oder in Übereinstimmung mit den geltenden Gesetzen anonymisieren.",
	"components.xieyi.i8g44k": "(4) Ändern Sie den Umfang Ihrer autorisierten Einwilligung",
	"components.xieyi.7162l2": "Sie können Ihre Berechtigung zur Erhebung und Verarbeitung Ihrer personenbezogenen Daten ändern oder widerrufen, indem Sie:",
	"components.xieyi.83m5qy": "Meine",
	"components.xieyi.ih02m6": "'Einstellungen Datenschutz Systemberechtigungen'",
	"components.xieyi.3aq776": "Verwalten Sie Ihre autorisierten Anwendungen in.",
	"components.xieyi.uyy3k2": "Für Berechtigungen, die Sie nicht direkt über die oben genannten Methoden festlegen können, können Sie diese ändern, indem Sie sich an den EPOCH EPT Kundendienst wenden oder eine E-Mail an jubao@weipaitang.com . Wir werden Ihre Auszahlungsanfrage innerhalb von 15 Werktagen beantworten. Bitte beachten Sie jedoch, dass wir bei bestimmten Arten von personenbezogenen Daten, wie z. B. Informationen, die zur Umsetzung der grundlegenden Funktionen von EPOCH EPT erforderlich sind, oder Informationen, die für die Erfüllung unserer gesetzlichen Verpflichtungen erforderlich sind, möglicherweise nicht in der Lage sind, auf Ihre Anfrage zur Änderung des Berechtigungsbereichs zu reagieren.",
	"components.xieyi.38w831": "Nach Widerruf Ihrer Einwilligung verarbeiten wir die entsprechenden personenbezogenen Daten nicht mehr. Ihre Entscheidung, die Genehmigung zu widerrufen, hat jedoch keinen Einfluss auf unsere bisherige Verarbeitung personenbezogener Daten aufgrund Ihrer Zustimmung.",
	"components.xieyi.o2932d": "Wenn Sie die personalisierte kommerzielle Werbung, die wir Ihnen senden, nicht annehmen möchten, können Sie diese jederzeit über die folgenden Methoden stornieren:",
	"components.xieyi.7l39m6": "'Meine EPOCH EPT-Einstellungen' Über EPOCH EPT-Einstellungen'",
	"components.xieyi.uw6w84": "(5) Eingeschränktes Informationssystem Automatische Entscheidungsfindung",
	"components.xieyi.6ixh35": "In bestimmten Geschäftsfunktionen können wir nur Entscheidungen treffen, die auf nicht manuellen automatischen Entscheidungsmechanismen wie Informationssystemen und Algorithmen basieren. Wenn diese Entscheidungen Ihre berechtigten Rechte und Interessen erheblich beeinträchtigen, haben Sie das Recht, von uns eine Erklärung zu verlangen, und wir werden auch sicherstellen, dass sie Sie nicht verletzen.",
	"components.xieyi.8i0uc6": "Bereitstellung von Berufungsmethoden unter der Prämisse von Geschäftsgeheimnissen oder anderen Benutzerrechten und sozialen öffentlichen Interessen.",
	"components.xieyi.sace84": "(6) Kopie personenbezogener Daten",
	"components.xieyi.iz38g1": "Wenn Sie eine Kopie Ihrer persönlichen Daten benötigen, können Sie uns über die Feedbackkanäle kontaktieren, die unter 'Wie Sie uns kontaktieren' in dieser Richtlinie aufgeführt sind. Nachdem Sie Ihre Identität überprüft haben, werden wir Ihnen eine Kopie Ihrer personenbezogenen Daten gemäß Ihrer Anfrage zur Verfügung stellen, sofern nicht anderweitig durch Gesetze und Vorschriften oder diese Richtlinie vorgesehen.",
	"components.xieyi.q0m5n8": "(7) Als Antwort auf Ihre obige Anfrage",
	"components.xieyi.fqi536": "Für die oben genannten Anfragen von Ihnen oder Ihren potenziellen Erziehungsberechtigten, nahen Verwandten und anderen autorisierten Parteien an uns sowie Ihre einschlägigen Rechte an personenbezogenen Daten, die durch die Gesetze der Volksrepublik China und andere anwendbare Gesetze festgelegt sind, können Sie uns über den Kundenservice kontaktieren oder direkt eine Beschwerde bei unserer Abteilung für den Schutz personenbezogener Daten einreichen. Wir werden eine Antwort innerhalb von 15 Tagen zur Verfügung stellen.",
	"components.xieyi.a1304e": "Um die Sicherheit Ihres Kontos zu gewährleisten, können wir, wenn Sie die obige Anfrage an uns richten, zuerst eine Überprüfung Ihrer Identität anfordern (z. B. eine schriftliche Anfrage oder andere Mittel anfordern), bevor wir Ihre Anfrage bearbeiten.",
	"components.xieyi.31h37g": "In den folgenden Situationen können wir gemäß den gesetzlichen Anforderungen nicht auf Ihre Anfrage antworten:",
	"components.xieyi.1udf40": "1. Zur Erfüllung gesetzlicher Pflichten oder Pflichten;",
	"components.xieyi.0ro7r7": "3. Es gibt hinreichende Beweise, die darauf hindeuten, dass die betroffene Person subjektive Böswilligkeit oder Rechtsmissbrauch hat;",
	"components.xieyi.70z43w": "4. Die Beantwortung Ihrer Anfrage führt zu schwerwiegenden Schäden an Ihren legitimen Rechten und Interessen anderer Personen oder Organisationen;",
	"components.xieyi.335lfg": "5. mit Geschäftsgeheimnissen;",
	"components.xieyi.1eb324": "6. Sonstige durch Gesetze und Verwaltungsvorschriften vorgeschriebene Umstände.",
	"components.xieyi.e0vv42": "Wir gehen davon aus, dass Sie über entsprechende zivile Kapazitäten im Bereich E-Commerce verfügen. Wenn Sie minderjährig sind, bitten wir Sie, dass Sie Ihre Eltern oder andere Erziehungsberechtigte bitten, diese Richtlinie sorgfältig zu lesen und unsere Dienste zu nutzen oder uns Informationen mit Zustimmung Ihrer Eltern oder anderer Erziehungsberechtigter zur Verfügung zu stellen.",
	"components.xieyi.jp5623": "Wenn Sie ein Kind unter 14-jährigem Alter sind, sollten Sie und Ihr Erziehungsberechtigter unsere speziell formulierten 'Regeln zum Schutz personenbezogener Daten von Kindern' sorgfältig lesen, um sicherzustellen, dass Sie die Zustimmung des Erziehungsberechtigten einholen.",
	"components.xieyi.4637q9": "Vorschriften zum Schutz personenbezogener Daten von Kindern",
	"components.xieyi.b7i17n": "Nur mit Ihrer Zustimmung können Sie unsere Dienste unter Anleitung Ihres Vormunds nutzen, um Ihre Sicherheit bei der Nutzung unserer Dienste und der Durchführung von Transaktionen zu gewährleisten.",
	"components.xieyi.63z56b": "Wenn Sie Elternteil oder sonstiger Vormund eines Kindes sind, achten Sie bitte darauf, ob das Kind unsere Dienste nach Einholung Ihrer autorisierten Zustimmung genutzt hat. Wenn Sie Fragen zu personenbezogenen Daten von Kindern haben, wenden Sie sich bitte an unsere Abteilung für den Schutz personenbezogener Daten.",
	"components.xieyi.064797": "Unsere Dienste und Technologien werden ständig aktualisiert und optimiert, um den Bedürfnissen von Ihnen und anderen Nutzern gerecht zu werden. Wenn sich neue Dienste und Geschäftsprozesse ändern, können wir daher unsere Datenschutzerklärung aktualisieren, um Sie über spezifische Änderungen zu informieren. Aber ohne Ihre ausdrückliche Zustimmung werden wir Ihre Rechte gemäß dieser Richtlinie nicht einschränken. Wir werden",
	"components.xieyi.7xys1n": "Spezialseite",
	"components.xieyi.5ln786": "Veröffentlichen Sie alle an dieser Richtlinie vorgenommenen Änderungen.",
	"components.xieyi.1i9q62": "Bei wesentlichen Änderungen werden wir auch wichtigere Benachrichtigungen bereitstellen (u.a. durch",
	"components.xieyi.dg46h5": "Benachrichtigen Sie über öffentliche Ankündigungen der Plattform oder stellen Sie Popup-Aufforderungen zur Verfügung.",
	"components.xieyi.o3si3a": "Zu den wesentlichen Änderungen, auf die in dieser Richtlinie Bezug genommen wird, gehören unter anderem:",
	"components.xieyi.sntd36": "1. Unser Dienstleistungsmodell hat erhebliche Änderungen erfahren, wie zum Beispiel der Zweck der Verarbeitung personenbezogener Daten, die Art der verarbeiteten personenbezogenen Daten und die Art der Verwendung personenbezogener Daten;",
	"components.xieyi.x97b27": "2. Wir haben erhebliche Änderungen in den Kontrollrechten und anderen Aspekten erfahren, wie z.B. Änderungen bei den Informationsverantwortlichen aufgrund von Fusionen und Übernahmen;",
	"components.xieyi.hc7g60": "3. Die Hauptziele der Weitergabe, Übertragung oder öffentlichen Offenlegung personenbezogener Daten haben sich geändert;",
	"components.xieyi.4icom0": "4. Ihre Rechte und die Ausübung Ihrer Rechte zur Verarbeitung personenbezogener Daten haben sich erheblich geändert;",
	"components.xieyi.krv6jh": "5. Unsere zuständige Abteilung für den Umgang mit personenbezogenen Daten, Kontaktinformationen und Beschwerdekanälen hat sich geändert;",
	"components.xieyi.t6x4vu": "6. Der Bericht zur Folgenabschätzung der Sicherheit personenbezogener Daten weist auf ein hohes Risiko hin.",
	"components.xieyi.oso4jc": "Name der Firma: Hangzhou EPOCH EPT Cultural and Creative Co., Ltd",
	"components.xieyi.0vvw2h": "Registrierte Adresse: 7th floor, Chuangfeng Huaxing Business Building, No. 19 Huaxing Road, Xihu District, Hangzhou City, Provinz Zhejiang",
	"components.xieyi.4j9bb7": "Wenn Sie Fragen, Meinungen oder Vorschläge zu dieser Richtlinie haben, können Sie uns über die folgenden Methoden kontaktieren, und wir werden Ihre Anfrage innerhalb von 15 Werktagen beantworten:",
	"components.xieyi.5mz349": "1. EPOCH EPT Online-Kundenservice",
	"components.xieyi.py8lg2": "2. Wir haben auch eine spezielle Abteilung für den Schutz personenbezogener Daten eingerichtet, und Sie können sich an die E-Mail-Adresse unserer verantwortlichen Person für den Schutz personenbezogener Daten wenden. shaktrysrtgscrtbslanderor5046@gmail.com .",
	"components.xieyi.nfphnk": "Es ist zu beachten, dass wir nicht auf Fragen antworten dürfen, die nicht mit dieser Richtlinie oder dem Schutz personenbezogener Daten von Benutzern zusammenhängen.",
	"components.xieyi.6vag57": "Wenn Sie mit unserer Antwort nicht zufrieden sind, insbesondere wenn Sie der Ansicht sind, dass unser Verhalten bei der Verarbeitung personenbezogener Daten Ihre berechtigten Rechte und Interessen verletzt hat,",
	"components.xieyi.iw8u70": "Sie können auch eine Lösung suchen, indem Sie eine Klage bei einem zuständigen Gericht am Standort der EPOCH EPT-Plattform einreichen.",
	"components.xieyi.668kbh": "Erstes Erscheinungsdatum: Dezember 13, 2018",
	"components.xieyi.1hqds4": "Datum der letzten Aktualisierung: März 30, 2023",
	"src.error.3if060": ">>>>>>Fehlermeldung>>>>>>",
	"src.error.f78hx4": ">>>>>>>Vue Instanz>>>>>",
	"libs.axios.3m3ozo": "Server Ausnahme!",
	"libs.axios.u68235": "Fehler anfordern!",
	"libs.axios.8pvp71": "Du hast keine Erlaubnis!",
	"libs.axios.3e23l0": "Adressfehler anfordern",
	"libs.axios.ctl7o2": "Bitte aktualisieren Sie diese und versuchen Sie es erneut!",
	"libs.axios.7f90de": "Interner Serverfehler",
	"libs.axios.6r1ua2": "Service nicht implementiert",
	"libs.axios.232in7": "Schlechtes Gateway",
	"libs.axios.ft8yo6": "Service nicht verfügbar",
	"libs.axios.1o3gds": "Gateway Timeout, bitte aktualisieren und versuchen Sie es erneut!",
	"libs.axios.43w873": "HTTP-Version nicht unterstützt",
	"goods.index.86p673": "kaufen",
	"goods.index.40o4np": "Einkaufsmenge",
	"goods.index.r257np": "Jetzt kaufen",
	"goods.index.6i5t88": "Dieses Produkt ist vorübergehend nicht verfügbar!",
	"goods.index.263l56": "Bitte wählen Sie ein Produkt",
	"goods.index.e5yso2": "Bitte wählen Sie eine Zahlungsart!",
	"rewards.activityb.t04rn4": "Provision insgesamt",
	"rewards.activityb.r5174s": "Die heutige Kommission",
	"rewards.activityb.q4544h": "Die Kommission von gestern",
	"rewards.activityb.uflar8": "Anzahl der direkten Befassungen",
	"rewards.activityb.fsyaor": "Provision",
	"rewards.activityb.xd17r7": "Indirekte Personenzahl",
	"trending.index copy 2.891396": "Dokumentarfilm",
	"trending.index copy 2.rc6wik": "Anlagebereich:",
	"trending.index copy 2.y4r77n": "Quantitativer Zyklus:",
	"trending.index copy 2.m2v371": "Stunde",
	"trending.index copy 2.5vyoay": "Quantitative Vorteile:",
	"trending.index copy 2.rpeid0": "Tracker Umsatz $1185,65",
	"trending.index copy 2.g0677s": "Bestellgröße $15120184.00",
	"trending.index copy 2.8sgku2": "In Gewahrsam",
	"trending.index copy 2.g2jni6": "Depotbetrag",
	"trending.index copy 2.39cp6b": "Verwahrungszeitraum",
	"trending.index copy 2.050n02": "Tageseinkommen",
	"trending.index copy 2.380j4f": "Startzeit des Hostings",
	"trending.index copy 2.6en3x1": "Endzeit der Verwahrung",
	"trending.index copy 2.v761ws": "Folgezeitraum:",
	"trending.index copy 2.8gow40": "Folgebeschränkungen:",
	"trending.index copy 2.1ul440": "Mindestrendite:",
	"trending.index copy 2.7fk35c": "Maximale Rendite:",
	"trending.index copy 2.3ys73k": "Verfügbarer Saldo",
	"trending.index copy 2.f615yk": "Gesamtpreis",
	"trending.index copy 2.11c26y": "Sofortige Folgemaßnahmen",
	"trending.index copy 2.ojxv4u": "ganz",
	"trending.index copy 2.2v0ku3": "Meine Bestellung",
	"trending.index copy 2.9m6dh6": "Bestellung erfolgreich aufgegeben!",
	"trending.index copy 3.7v38ky": "Abgebrochen",
	"trending.index copy 3.3ksfcn": "Vorbestellung",
	"trending.index copy 3.u6n586": "Zu begleichen",
	"trending.index copy 3.7d8jnh": "Titel",
	"trending.index copy 3.22f28b": "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
	"trending.index copy 3.plw663": "bestätigen",
	"trending.index copy 3.e4pcfx": "Erfolgreich abgesagt!",
	"trending.index copy.voov3i": "Ertrag der letzten 30 Tage",
	"trending.index copy.60fqu2": "Umsatz in den letzten 30 Tagen",
	"trending.index copy.38c2yt": "Gewinnwahrscheinlichkeit",
	"trending.touzi1.30c92u": "Bestellnummer",
	"trending.touzi1.w3r6h6": "Geld",
	"trending.touzi1.2q58dy": "Zustand",
	"trending.touzi1.830j6d": "Zeit",
	"wallet.index.ralw0t": "Bitte geben Sie Ihre Telefonnummer ein",
	"wallet.index.tme1u9": "Telefonnummer",
	"wallet.index.h1wve2": "Bitte geben Sie eine Telefonnummer ein",
	"login.login.qnpe05": "Bitte geben Sie die Telefonnummer ein",
	"login.login.8za2m1": "Login Passwort kann nicht leer sein",
	"components.recharge.r79s4d": "Effektiv",
	"components.recharge.v93frd": "Verarbeitung",
	"wallet.index.bm205v": "Der Mindestbetrag darf nicht kleiner als 0 sein",
	"components.recharge.h1w232": "Rabatt läuft",
	"components.recharge.wx5ntw": "Rabatt erfolgreich",
	"components.recharge.42t1o3": "Erfolgreich aufgeladen",
	"components.recharge.d03old": "Auszahlung fehlgeschlagen",
	"components.recharge.cn2rpv": "Auszug erfolgreich"
}