module.exports = {
	"Language": {
		"title": "Linguagem"
	},
	"Noproductsforsale": "No products for sale",
	"rechargePresentationText0_1": "Recharge 1000 reward  ",
	"rechargePresentationText0_2": ", Recharge 10000 reward ",
	"rechargePresentationText0_3": ", and so on... Click Me !",
	"rechargePresentationText0": "You will receive ",
	"rechargePresentationText1": "% of the recharge amount when you start the recharge now. The activity time is limited, and it will stop after the gift is over.",
	"rechargePresentationText2": "Reward End Time, CountDown !",
	"Online_Chat": "Bate-papo online",
	"gobindgoogle": "Por favor, coloque seu Google Authenticator no lixo",
	"Order": "Pedido",
	"Record": "Gravar",
	"Assist": "Assistência",
	"Market": "Mercado",
	"History": "História",
	"Confirm": "confirme",
	"close": "Fechar",
	"Cancel": "Cancelar",
	"Previous": "Anterior",
	"Next": "Próximo",
	"Copy": "cópia de",
	"Nomoredata": "Sem mais dados",
	"moneychar": "₹",
	"DownloadApp": " Baixar aplicativo agora",
	"Yes": "Sim",
	"No": "Não",
	"Wating": "esperando",
	"Recharge": "Recarrega",
	"Withdrawal": "Cancelamento",
	"Success": "Sucesso",
	"OrderSuccessful": "Pedido bem-sucedido",
	"Handledataerror": "Lidar com erro de dados",
	"Copysuccess": "sucesso da cópia",
	"MyBalanceLoding": "Minha Lotação de Saldo",
	"UPDATE": "ATUALIZAR",
	"New_version_available": "Nova versão disponível",
	"Please,update_app_to_new_version": "Por favor, atualize o aplicativo para a nova versão",
	"tabbar": {
		"Home": "Lar",
		"AndarBahar": "Andar Bahar",
		"Invest": "Investir",
		"Bonus": "Bônus",
		"Mine": "Meu",
		"Trending": "Tendências",
		"Wallet": "Carteira",
		"Rewards": "Recompensas"
	},
	"homeTop": {
		"Sign_Up": "Registre-se",
		"Sign_up_for_rewards": "Registre-se para recompensas",
		"up_to": "até",
		"TOTAL_ASSETS": "ATIVOS TOTAIS",
		"User_ID": "ID do usuário",
		"Registration_time": "Tempo de registro"
	},
	"PieChart": {
		"BIG&SMALL": "GRANDE&PEQUENO",
		"NUMBER": "NÚMERO",
		"ODD&EVEN": "ÍMPAR&EVEN",
		"ALL_NUMBER_TRENDING": "TENDÊNCIA DE TODOS OS NÚMEROS",
		"BIG&SMALL_NUMBER_TRENDING": "TENDÊNCIA DE NÚMEROS GRANDES E PEQUENOS",
		"ODD&EVEN_NUMBER_TRENDING": "TENDÊNCIA DE NÚMEROS ÍMPARES E EVEN"
	},
	"register": {
		"tips_3": "Se você não recebeu o código em sua caixa de entrada, verifique sua pasta de spam.",
		"receiveCode": "Não recebeu o código?",
		"receiveCode_title": "Código enviado para o seu email. Se não recebeu o código após várias tentativas, por favor tente o seguinte:",
		"receiveCode_text1": "1. Verifique se está na sua pasta de spam/lixo eletrônico.",
		"receiveCode_text2": "2. Certifique-se de que seu endereço de email esteja correto.",
		"receiveCode_text3": "3. A mensagem pode ter um atraso de alguns minutos. Tente novamente após 20 minutos.",
		"receiveCode_text4": "4. Se este endereço de email já existir, não enviaremos um código de autenticação. Por favor, faça login ou redefina sua senha.",
		"Sign_up_for_Rewards": "Registre-se para Recompensas",
		"Email_Verification": "Verificação de E-mail",
		"tips": "Por favor, insira o código de verificação de 6 dígitos que foi enviado para {{formData.email}}. O código é válido por 30 minutos.",
		"tips_1": "Por favor, insira o código de verificação de 6 dígitos que foi enviado para ",
		"tips_2": "O código é válido por 30 minutos.",
		"Resend_code_in": "Reenviar o código em",
		"Sent_again": "Enviado novamente",
		"CREATE_ACCOUNT": "CRIAR CONTA",
		"inviteCodecheck": "inviteCode não pode estar vazio!",
		"Mobile": "Número de telemóvel",
		"Mobilemsg": "O número do celular é obrigatório",
		"Password": "Senha",
		"Passwordmsg": "Senha requerida",
		"OTPCode": "Código OTP",
		"OTPCodemsg": "O código OTP é obrigatório",
		"SendOTP": "Enviar OTP",
		"Email": "E-mail",
		"Emailmsg": "O e-mail é obrigatório",
		"Invitecode": "Código de convite",
		"Invitecodemsg": "O código de convite é obrigatório",
		"emailFormatter": "Por favor, insira o endereço de e-mail correto",
		"usernameFormatter": "o nome de usuário deve ser um caractere e um número e o intervalo é 6<=comprimento<=20",
		"phoneFormatter": "Por favor, preencha o número de celular de 10 dígitos",
		"msgsuccess": "Registrado com sucesso",
		"msgerr": "falha ao registrar",
		"errorphonecheck": "O número do celular não pode estar vazio!",
		"phonecheck": "Por favor, preencha o número de celular de 10 dígitos",
		"imgcodecheck": "O código OTP não pode estar vazio!"
	},
	"login": {
		"Welcome_back": "Bem-vindo de volta",
		"tips": "Eu li e concordo com os Termos de Serviço e Política de Privacidade do {{this.$LogoName}}",
		"tips_1": "Eu li e concordo com os",
		"tips_2": "Termos de Serviço",
		"tips_3": "e",
		"tips_4": "Política de Privacidade",
		"Continue_with_Google": "Continuar com o Google",
		"Continue_with_Apple": "Continuar com a Apple",
		"text": "Ainda não tem uma conta?",
		"text2": "Já tem uma conta?",
		"Login": "Conecte-se",
		"Register": "Registro",
		"username": "Nome de usuário ou número de telefone",
		"password": "Senha",
		"imgcode": "Código da imagem",
		"BackHome": "De volta para casa",
		"Forgetpwd": "Esqueceu a senha"
	},
	"forgot": {
		"title": "Esqueceu a senha",
		"msgsuccess": "Redefinição de senha concluída",
		"newpassword": "Nova Senha",
		"newpasswordmsg": "Nova senha é necessária",
		"Continue": "Continuar"
	},
	"home": {
		"Revolutionize_Trading": "Revolutionize Trading",
		"Shape_the_Future": "Molde o Futuro",
		"content0": "Vamos experimentar juntos a nova era da troca de criptomoedas!",
		"3_Reasons_for_Choosing": "3 Razões para Escolher",
		"Innovative_Trading_Features": "Recursos de Negociação Inovadores",
		"content1": "Experiência revolucionária de negociação que é diferente de tudo o que está atualmente disponível no mercado. Projetado para dar a você uma vantagem competitiva com recursos de negociação inovadores, medidas de segurança avançadas e velocidades de transação extremamente rápidas.",
		"Lower_Fees_and_Better_Rewards": "Taxas mais baixas e melhores recompensas",
		"content2": "Nossa interface torna a negociação simples para iniciantes. Você pode criar uma conta gratuitamente, negociar criptomoedas com baixas taxas de transação em nosso mercado. Além disso, você pode compartilhar até 20% das taxas de transação da troca para todos os usuários que você convidar.",
		"Institutional-grade_Security": "Segurança de nível institucional",
		"content3": "segue um dos mais altos padrões de segurança da indústria. Além disso, armazenamos 100% de sua criptomoeda em armazenamento frio. Garantimos uma relação de reserva de pelo menos 1:1 dos fundos de nossos clientes. Nosso fundo de 300 milhões de USDT oferece uma camada extra de proteção contra ameaças de segurança.",
		"Start_investing_in_as_little_as_5_minutes": "Comece a investir em apenas 5 minutos",
		"content4": "Registre-se gratuitamente com {{this.$LogoNames.toUpperCase()}}",
		"content4_1": "Registre-se gratuitamente com",
		"content4_2": "",
		"Complete_google_authentication": "Conclua a autenticação do Google",
		"content5": "Digite e verifique o seu endereço de e-mail para começar. Não há taxas para abrir uma conta.",
		"content7": "Desbloqueie a funcionalidade completa verificando o seu autenticador do Google em menos de 5 minutos.",
		"content8": "Financie a sua conta {{this.$LogoNames.toUpperCase()}} instantaneamente",
		"content8_1": "Financie a sua conta",
		"content8_2": "instantaneamente",
		"content9": "Deposite USDT através da rede selecionada e tenha acesso imediato aos seus fundos. Não há necessidade de esperar 3-5 dias para começar a negociar.",
		"Start_building_your_crypto_portfolio": "Comece a construir sua carteira de criptomoedas",
		"Invitation_Program": "Programa de Convites",
		"Referral_Program": "Programa de Referência",
		"content10": "Conclua qualquer convite válido e receba um bônus de convite com valor médio de 10 USDT. O bônus máximo para uma única pessoa é de ",
		"content11": "Os convidadores podem receber um bônus proporcional ao volume de transação gerado pelos seus convidados. O bônus máximo para um convite válido é de Unlimit USDT.",
		"TRADING_TASKS": "TAREFAS DE NEGOCIAÇÃO",
		"content12": "Os usuários receberão recompensas em USDT ao negociar, com diversas tarefas de negociação para diferentes modos de negociação. As recompensas serão distribuídas instantaneamente.",
		"Trade_anytime_anywhere": "Negocie a qualquer hora, em qualquer lugar",
		"content13": "A USA, Inc. possui licença para realizar atividades comerciais de moedas virtuais pelo Departamento de Serviços Financeiros do Estado de Nova York.",
		"USA_Inc": "USA, Inc.",
		"content6": "Baixe nosso aplicativo para se manter conectado 24/7.",
		"Legal & Disclosures": "Legal & Disclosures",
		"Regulatory License": "Licença Regulatória",
		"AML/KYC Policies": "Políticas AML/KYC",
		"Privacy Policy": "Política de Privacidade",
		"Terms of Use": "Termos de Uso",
		"Legal Statement": "Declaração Legal",
		"Risk Disclosure": "Divulgação de Riscos",
		"About Us": "Sobre Nós",
		"Proof of Reserves": "Comprovação de Reservas",
		"Twitter": "Twitter",
		"Bitget Token": "Token Bitget",
		"Careers": "Carreiras",
		"Company": "Empresa",
		"Support Service": "Serviço de Suporte",
		"title": "Abrir com um aplicativo",
		"titlebtn": "Download",
		"Balance": "Equilíbrio",
		"ID": "ID",
		"Recharge": "Recarrega",
		"Withdraw": "Retirar",
		"Bonus": "Bônus",
		"Checkin": "Check-in",
		"Eventclosed": "Evento encerrado",
		"Ranking": "Classificação"
	},
	"Ranking": {
		"Today_s winning ranking": "Classificação vencedora de hoje",
		"tips1": "Quanto maior a quantia vencedora = maior a classificação!",
		"UserName": "Nome de usuário",
		"Winning Amount": "Valor vencedor",
		"Today_s rebate ranking": "Classificação de descontos de hoje",
		"tips2": "Descontos mais altos para usuários de nível inferior = classificação mais alta!",
		"Rebate Amount": "Valor do desconto",
		"Rebate ranking": "20 principais classificações de descontos"
	},
	"Checkin": {
		"Checkin": "Check-in",
		"CheckinList": "Lista de check-in"
	},
	"AndarBahar": {
		"title": "Andar Bahar",
		"Rule": "Regra",
		"Record": "Registro",
		"Records": "Andar Bahar Registros",
		"MyOrder": "Meu pedido",
		"more": "mais",
		"Period": "Período",
		"Order": "Ordem",
		"Buy": "Comprar",
		"Result": "Resultado",
		"Profit": "Lucro",
		"AndarBaharRule": "Andar Bahar Regra",
		"CountDown": "Contagem regressiva",
		"Andar": "Andar",
		"Bahar": "Bahar",
		"Tie": "Tie",
		"result": "resultado",
		"ok": "ok",
		"start": "começar",
		"Point": "Apontar",
		"ContractPoint": "Ponto de contrato",
		"Number": "Número",
		"Delivery": "Entrega",
		"Fee": "Taxa",
		"Amount": "Quantia"
	},
	"pokerOrder": {
		"OrderRecord": "Registro de pedido",
		"WatingOrder": "Ordem de Comer",
		"HistoryOrder": "Ordem do Histórico",
		"Nomoredata": "Sem mais dados",
		"Buy": "Comprar",
		"Result": "Resultado",
		"Time": "Tempo",
		"Amount": "Quantia",
		"Fee": "Taxa",
		"Profit": "Lucro"
	},
	"pokerRecord": {
		"title": "Andar Bahar Registro",
		"Period": "Período",
		"Result": "Resultado",
		"OpenTime": "Tempo aberto",
		"Nomore": "Não mais"
	},
	"Google2FA": {
		"title": "Autenticação 2FA do Google",
		"Authenticator_Instructions": "Instruções do autenticador",
		"Authenticator_Status": "Status do autenticador",
		"Google_Authenticator": "Autenticador do Google",
		"Download_Google_Authenticator_App": "Baixe o aplicativo Google Authenticator",
		"App_Store": "Loja de aplicativos",
		"Google_Play": "jogo do Google",
		"Next": "Próximo",
		"Backup_key": "Chave de backup",
		"Backup_key_text": "Salve esta Chave de Backup em um local seguro. Esta chave permitirá que você recupere seu autenticador caso perca seu telefone. Caso contrário, redefinir seu autenticador terá que consultar o atendimento ao cliente.",
		"Verifiaction": "Verificação",
		"Verifiaction_text": "Insira o código de verificação do Google",
		"Google_verification_code": "código de verificação do Google",
		"contentlist1": "1.Registre a chave de backup de 16 dígitos de +this.$LogoName+ e guarde-a em um cofre.",
		"contentlist1_1": "1.Registre a chave de backup de 16 dígitos de",
		"contentlist1_2": "e guarde-a em um cofre.",
		"contentlist2": "2. Abra o Google Authenticator.",
		"contentlist3": "3.Adicione uma conta +this.$LogoName+ e insira a chave de 16 dígitos copiada.",
		"contentlist3_1": "3.Adicione uma conta",
		"contentlist3_2": "e insira a chave de 16 dígitos copiada.",
		"contentlist4": "4.Você verá um código de verificação de 6 dígitos na tela.",
		"contentlist5": "5.Copie o código de 6 dígitos e cole-o em +this.$LogoName+ .",
		"contentlist5_1": "5.Copie o código de 6 dígitos e cole-o em",
		"contentlist5_2": ".",
		"contentlist6": "6. Em seguida, seu Autenticador será vinculado com sucesso."
	},
	"win": {
		"Live_Price": "Preço ao vivo",
		"title1": "Os resultados de investimento são calculados pelo último dígito da negociação de spot da Binance BTC/USDT correspondente ao horário UTC. Os investidores podem verificar os resultados na Binance a qualquer momento para garantir a equidade e segurança do investimento.",
		"title": "Existem 2 modos de investimento",
		"1M_Mode": "Lite 1M",
		"1M_Mode_text": "cada rodada dura 1 minuto. O modo Lite oferece apenas investimento em números ímpares e pares. O resultado é calculado pelo último dígito do resultado de fechamento da negociação com spot de 1 minuto da Binance BTC/USDT.",
		"5M_Mode": "Pro 5M",
		"5M_Mode_text": "Cada rodada dura 5 minutos. O modo Pro desbloqueia o método completo de investimento da EPOCH EPT. O resultado é calculado pelo último dígito do fechamento do intervalo de 5 minutos da negociação de spot da Binance BTC/USDT.",
		"5M_Mode_text_1": "Cada rodada dura 5 minutos. O modo Pro desbloqueia o método completo de investimento da",
		"5M_Mode_text_2": ". O resultado é calculado pelo último dígito do fechamento do intervalo de 5 minutos da negociação de spot da Binance BTC/USDT.",
		"Investment_Guide": "Guia de Investimento",
		"Investment_Rules": "Regras de Investimento",
		"title2": "Como comprovar que o resultado é justo?",
		"Investment_Info": "Informações de Investimento",
		"3M_Mode": "Modo 3M",
		"3M_Mode_text": "Cada rodada leva 3 minutos. O resultado é calculado pelo último dígito do resultado de fechamento da transação de spot de 3M da Binance BTC/USDT.",
		"Investment_return": "Retorno do Investimento",
		"Number": "Número",
		"Profit_Invested_amount": "Lucro: Valor Investido",
		"guide1": "Escolha um modo de tempo.",
		"guide2": "Ajuste o valor do seu investimento.",
		"guide3": "Escolha os números nos quais deseja investir.",
		"guide4": "Verifique seu portfólio e clique em investir.",
		"NEXT": "PRÓXIMO",
		"Invest_Amount": "Valor Investido",
		"Total": "Total",
		"INVEST": "INVESTIR",
		"Portfolio": "Portfólio",
		"Invest": "Investir",
		"BINANCE": "BINANCE",
		"Bitcoin_Price": "Preço do Bitcoin",
		"InvestmentRecord": "Registro de Investimento",
		"Best_Assistant": "Melhor assistente",
		"last_100_rounds": "(últimas 100 rodadas)",
		"What_do_these_data_mean": "O que esses dados significam",
		"Missing": "Ausente",
		"Missing_text": "Isso significa o número de rodadas desde a última rodada que produziu esse número.",
		"Frequency": "Frequência",
		"Frequency_text": "Refere-se aos tempos do número correspondente que aparece nas últimas 100 rodadas.",
		"Max_Continued": "Máximo Continuado",
		"Max_Continued_text": "Refere-se ao período máximo de aparição consecutiva do número correspondente nas últimas 100 rodadas.",
		"Rule": "Regra",
		"Trend": "Tendência",
		"MyBalance": "meu saldo",
		"JoinGreen": "Join Green",
		"JoinRed": "Join Red",
		"JoinViolet": "Join Violet",
		"Parity": "Paridade",
		"Sapre": "Sapre",
		"Bcone": "Bcone",
		"Emerd": "Emerd",
		"Record": "Registro",
		"more": "mais",
		"Period": "Período",
		"Price": "Preço",
		"Result": "Resultado",
		"My": "Meu",
		"Order": "Ordem",
		"Buy": "Comprar",
		"Profit": "Lucro",
		"Green": "Green",
		"Red": "Red",
		"Violet": "Violet",
		"tips": "A taxa de transação é {bet_fee*100}% do valor do pedido",
		"tips1": "A taxa de transação é ",
		"tips2": "do valor do pedido ",
		"OrderAmount": "Valor do pedido",
		"Quantity": "Quantidade",
		"TransactionFee": "Taxa de transação",
		"TotalAmount": "Montante total",
		"PresalePolicy": "Política de pré-venda",
		"Iagree": "Concordo",
		"err1": "Por favor, concorde com as regras de gerenciamento de pré-venda primeiro"
	},
	"historyPeriods": {
		"Orderrecord": "registro de pedido",
		"record": "registro"
	},
	"historyOrderPeriods": {
		"WatingOrder": "Ordem de Comer",
		"HistoryOrder": "Ordem do Histórico"
	},
	"Activity": {
		"added_to_account_balance": "adicionado ao saldo da conta.",
		"contentText": "Quando os convidados completarem o primeiro depósito, isso contará como um convite válido. O convidador receberá um bônus de convite para cada convite válido. Os níveis de bônus de convite variam de {{aaaaaa}}USDT para 1 convite válido até {{aaaaaa}}USDT para 500 convites válidos.",
		"contentText_1": "Quando os convidados completarem o primeiro depósito, isso contará como um convite válido. O convidador receberá um bônus de convite para cada convite válido. Os níveis de bônus de convite variam de",
		"contentText_2": "para 1 convite válido até ",
		"contentText_3": " para 500 convites válidos.",
		"contentText_3_1": " para ",
		"contentText_3_2": " convites válidos.",
		"contentText2": "Os convidadores irão compartilhar {{LV.1%}} da taxa de negociação de cada volume de negociação de convidados LV.1. Os convidadores irão compartilhar {{LV.2%}} da taxa de negociação de cada volume de negociação de convidados LV.2. O bônus máximo para o volume de negociação de convidados é ilimitado em USDT.",
		"contentText2_1": "Os convidadores irão compartilhar",
		"contentText2_2": "da taxa de negociação de cada volume de negociação de convidados LV.1. Os convidadores irão compartilhar",
		"contentText2_3": "da taxa de negociação de cada volume de negociação de convidados LV.2. O bônus máximo para o volume de negociação de convidados é ilimitado em USDT.",
		"My_Rewards_History": "Meu histórico de recompensas",
		"Cumulative_Single_Number_Trading_Volumn": "Volume de negociação cumulativo de número único",
		"Cumulative_ODD_Number_Trading_Volumn": "Volume de negociação cumulativo de número ímpar",
		"Cumulative_EVEN_Number_Trading_Volumn": "Volume de negociação cumulativo de número par",
		"Cumulative_BIG_Number_Trading_Volumn": "Volume de negociação cumulativo de número grande",
		"Cumulative_SMALL_Number_Trading_Volumn": "Volume de negociação cumulativo de número pequeno",
		"Advanced_Tasks": "Tarefas avançadas",
		"Invite_Friends_and_Earn_Up_to": "Convide amigos e ganhe até",
		"Referral_Code": "Código de referência",
		"Referral_Link": "Link de referência",
		"INVITE_FRIENDS": "CONVIDE AMIGOS",
		"Complete_tasks_to_earn_up_to": "Complete tarefas para ganhar até",
		"MY_REWARDS": "MINHAS RECOMPENSAS",
		"SHARE": "COMPARTILHAR",
		"Earn_with_your_friends_together": "Ganhe com seus amigos juntos",
		"Get_your_link": "Obtenha seu link",
		"Claim_your_rewards": "Reivindique suas recompensas",
		"Earn_together": "Ganhe juntos",
		"contentlist0": "Junte-se ao {{gogobit}} e obtenha seu link de referência. Os usuários que se inscreverem por meio deste link receberão recompensas.",
		"contentlist0_1": "Junte-se ",
		"contentlist0_2": " e obtenha seu link de referência. Os usuários que se inscreverem por meio deste link receberão recompensas.",
		"contentlist1": "Você receberá uma recompensa de referência com valor médio de {{XX}} USDT depois que seus amigos fizerem o primeiro depósito e concluírem o volume de negociação necessário. As recompensas podem ser de até {{XX}} USDT.",
		"contentlist1_1": "Você receberá uma recompensa de referência com valor médio de ",
		"contentlist1_2": " USDT depois que seus amigos fizerem o primeiro depósito e concluírem o volume de negociação necessário. As recompensas podem ser de até ",
		"contentlist1_3": " USDT.",
		"contentlist2": "Após o seu amigo criar uma conta, ele/ela pode solicitar um bônus de negociação de até 5.050 USDT.",
		"Referral_Rewards": "Recompensas de Indicação",
		"Detail_Rules": "Regras Detalhadas",
		"Invitation_Bonus": "Bônus de Indicação",
		"Up_to": "Até",
		"content0": "Complete qualquer convite válido e receba um bônus de indicação com valor médio de 10 USDT. O bônus máximo para uma única pessoa é de",
		"Trading_Bonus": "Bônus de Negociação",
		"content1": "Os convidadores podem receber um bônus proporcional ao volume de transação gerado por seus convidados. O bônus máximo para um convite válido é de",
		"My_Referral_History": "Meu Histórico de Indicação",
		"Total_Invitees": "Total de Convidados",
		"Valid_Invitees": "Convidados Válidos",
		"Cumulative_Invitees": "Convidados Acumulados",
		"Trading_Volumn": "Volume de Negociação",
		"Detail_Rules_title": "Sorteio Diário da Sorte de 888 USDT",
		"Detail_Rules_title1": "1. Os dados de negociação futuros do dia serão calculados no dia seguinte.",
		"Detail_Rules_title2": "2. Os usuários que fizerem um volume de negociação futuro de 200.000 USDT ou mais no dia terão uma chance de participar do sorteio uma vez para o prêmio de 888 USDT.",
		"CLAIM_NOW": "REIVINDIQUE AGORA",
		"INVEST_NOW": "INVISTA AGORA",
		"title": "Carteira de bônus",
		"BonusBalance": "Saldo de Bônus",
		"Withdrawtobalance": "Retirar para equilibrar",
		"BonusList": "Lista de Bônus",
		"Bonuswithdrawtobalance": "Retirada de bônus para saldo",
		"WithdrawalAmount": "Quantidade retirada",
		"Pleaseentertheamount": "Por favor, insira o valor",
		"err1": "O valor da retirada não pode ser 0",
		"success": "Sucesso na transferência"
	},
	"Refer": {
		"title": "minha referência",
		"ReferTotalBonus": "Bônus total de referência",
		"InviteFriends": "Convide amigos",
		"Level1": "Nível 1",
		"Level2": "Nível 2",
		"TotalPeople": "Pessoas totais",
		"Bonus": "Bônus",
		"Myinvitationlink": "meu link de convite",
		"Rebate_description": "Descrição do desconto"
	},
	"Task": {
		"InviteBonusToday": "Bônus de convite hoje",
		"bonusrecord": "recorde de bônus",
		"hint1": "1. Os dados são atualizados a cada 10-30 minutos!",
		"hint2": "2. Só é contabilizado quem se registou nos últimos 3 dias e completou a recarga hoje!",
		"hint2_1": "2. Só é contabilizado quem se registou nos últimos ",
		"hint2_2": " dias e completou a recarga hoje!",
		"hint3": "3. Depósito mínimo de 20u hoje!",
		"hint3_1": "3. Depósito mínimo de ",
		"hint3_2": " hoje!",
		"hint4": "Número de pessoas convidadas nos últimos três dias",
		"hint4_1": "Número de pessoas convidadas nos últimos  ",
		"hint4_2": " dias",
		"hint5": "Número de pessoas que completaram a recarga hoje",
		"Invite": "Convidar",
		"friendstorecharge": "amigos para recarregar",
		"Earn": "Ganhar",
		"Completed": "Concluído",
		"ExpireTime": "tempo de expiração"
	},
	"Invited": {
		"title": "registro de bônus",
		"InviteTotalBonus": "Bônus total do convite",
		"InviteTime": "Hora do convite",
		"Bonus": "Bônus",
		"NoRecored": "Nenhum registro"
	},
	"mine": {
		"JOIN US": "JUNTE-SE A NÓS",
		"title": "Centro pessoal",
		"InviteCode": "Código de convite",
		"AccountBalance": "Saldo da conta",
		"BonusBalance": "Saldo de Bônus",
		"Wallet": "Carteira",
		"Bank": "Banco",
		"Invite": "Convidar",
		"Refer": "Referir",
		"CustomerService": "Atendimento ao Cliente",
		"ComplaintsSuggestions": "Reclamações&Sugestões",
		"ChangePassword": "Alterar a senha",
		"Activity": "Atividade",
		"PresalePolicy": "Política de pré-venda",
		"DownloadApp": "Baixar aplicativo",
		"AppVersion": "Versão do aplicativo",
		"Logout": "Sair",
		"Logouttips": "Tem certeza que deseja sair ?"
	},
	"App": {
		"title": "Baixar aplicativo",
		"DownloadforAndroid": "Baixar para Android",
		"DownloadforiOS": "Baixar para iOS"
	},
	"Presale": {
		"title": "Política de pré-venda"
	},
	"Account": {
		"title2": "Definir nova senha",
		"title2_text": "Defina uma nova senha para sua conta do Google. Você pode fazer login em sua conta do Google usando senha.",
		"title_text": "Você está tentando redefinir sua senha. Por favor, tenha seu Google Authenticator pronto para redefinição.",
		"err1": "As duas novas senhas digitadas são diferentes",
		"success2": "Senha alterada com sucesso",
		"title": "Alterar a senha",
		"OldPassword": "Senha Antiga",
		"OldPasswordmsg": "senha antiga é necessária",
		"NewPassword": "Nova Senha",
		"NewPasswordmsg": "nova senha é necessária",
		"RetypeNewPassword": "Digite novamente a nova senha",
		"RetypeNewPasswordmsg": "Digite novamente a nova senha",
		"Continue": "Continuar"
	},
	"Service": {
		"title": "Serviço",
		"CONTACTUS": "CONTATE-NOS",
		"tips": "O atendimento ao cliente está dando um tempo!",
		"WhatsApp": "WhatsApp",
		"Telegram": "Telegram",
		"link": "vincular"
	},
	"Bank": {
		"BankName": "Nome do banco",
		"ChooseaCurrencyType": "Selecione o tipo de moeda",
		"SelectWithdrawalMethod": "Selecione o método de retirada",
		"Newbankcard": "Novo cartão bancário",
		"Editbankcard": "Editar cartão do banco",
		"title": "meu banco",
		"Cannotbeempty": "Não pode estar vazio",
		"BankHolderName": "Nome do Titular do Banco",
		"BankAccountNumber": "Número da conta de banco",
		"IFSCCode": "Código IFSC",
		"Email": "E-mail",
		"Phone": "Telefone",
		"OTPCode": "Código OTP",
		"tips1": "1. Por favor, use o número de celular que você registrou para receber OTP",
		"tips2": "2. Para segurança dos fundos, o cartão do banco só pode ser modificado uma vez por dia",
		"Add": "Adicionar",
		"error1": "O Nome do Titular do Banco não pode estar vazio!",
		"error2": "Por favor, insira o nome correto do titular do banco!",
		"error3": "O nome do banco não pode estar vazio!",
		"error4": "Por favor, digite o nome do banco correto!",
		"error5": "O Número da Conta Bancária não pode estar vazio!",
		"error6": "Por favor, insira o Número de Conta Bancária correto!",
		"error7": "O código IFSC não pode estar vazio!",
		"error8": "Insira o código IFSC correto!",
		"error9": "O e-mail não pode estar vazio!",
		"error10": "Por favor, digite o endereço de e-mail correto!",
		"error11": "telefone não pode estar vazio!",
		"error12": "O código OTP não pode estar vazio!",
		"Successfully": "Adicionar cartão bancário com sucesso"
	},
	"wallet": {
		"title": "Minha carteira",
		"MyBalance": "meu saldo",
		"MyBank": "meu banco",
		"RechargeRecords": "registros de recarga",
		"WithdrawalRecords": "Registros de Saque"
	},
	"Rechargepage": {
		"Reminder": "Lembrete",
		"Reminder_1": "1. Não deposite nenhuma outra moeda além de USDT no endereço. O envio de moedas ou tokens não suportados para este endereço pode resultar em perda irreversível.",
		"Reminder_2": "2. O valor mínimo de depósito é de 5 USDT.",
		"Reminder_2_1": "2. O valor mínimo de depósito é de ",
		"Reminder_2_2": " USDT.",
		"Reminder_3": "3. Depósitos serão creditados após 15 confirmações de rede.",
		"Minimum": "Mínimo",
		"title": "Recarrega",
		"History": "História",
		"Mybalance": "meu saldo",
		"RechargeAmount": "Valor da recarga",
		"RechargeAmountmsg": "Por favor, insira o valor",
		"tips1": "O canal de pagamento está em manutenção!",
		"tips2": "Selecione o canal de pagamento",
		"PayNow": "Pague agora",
		"err1": "O canal de pagamento está em manutenção",
		"err2": "O valor não pode ser inferior a ",
		"err3": "Selecione o canal de pagamento",
		"Rechargemethod": "Método de recarga"
	},
	"Rechargerecord": {
		"title": "registro de recarga",
		"WatingOrder": "Ordem de Rebatidas",
		"HistoryOrder": "Ordem do Histórico",
		"Rupee": "Dólar",
		"Status": "Status",
		"Time": "Tempo"
	},
	"withdrawal": {
		"Tips": "Pontas",
		"Tips_1": "Dicas: A taxa de retirada para ",
		"Tips_2": " USDT e abaixo é de ",
		"Tips_3": " USDT, a taxa de retirada para mais de ",
		"Tips_4": "USDT é de",
		"Tips_5": "%.",
		"WalletAddress": "Endereço da carteira",
		"Addwalletaddress": "Adicionar endereço da carteira",
		"Network_fee": "Taxa de rede",
		"Receive_amount": "Valor a receber",
		"Select Chains": "Selecionar Cadeias",
		"Bankcard": "cartão do banco",
		"Nobankcardselected": "Nenhum cartão bancário adicionado",
		"Withdrawalfee": "Taxa de retirada",
		"title": "cancelamento",
		"Mybalance": "meu saldo",
		"Withdrawablebalance": "Saldo sacado",
		"tips1": "O valor total da compra deve ser superior a {{codeAmount}} pode ser retirado na íntegra",
		"tips1-1": "O valor total da compra deve ser superior a",
		"tips1-2": "pode ser retirado na íntegra",
		"tips2": "As retiradas por dia são {{draw_num}} vezes",
		"tips2-1": "As retiradas por dia são",
		"tips2-2": "vezes",
		"tips3": "O valor máximo de saque diário é",
		"tips4": "O valor mínimo de saque único é ₹{{draw_min_money}} e o valor máximo de saque único é ₹{{draw_max_money}}",
		"tips4-1": "O valor mínimo de saque único é",
		"tips4-2": "e o valor máximo de saque único é",
		"tips5": "O horário de saque é {{draw_time}}, retire no horário especificado",
		"tips5-1": "O horário de saque é",
		"tips5-2": " ,retire no horário especificado",
		"tips6": "O saque em dinheiro deduzirá {{draw_fee}}% da taxa de saque em dinheiro",
		"tips6-1": "O saque em dinheiro deduzirá ",
		"tips6-2": "da taxa de saque em dinheiro",
		"tips7": "Se o valor do saque for menor ou igual a ₹{{solid_min_amount}}, será cobrada uma taxa fixa de ₹{{solid_feemoney}}",
		"tips7-1": "Se o valor do saque for menor ou igual a",
		"tips7-2": "será cobrada uma taxa fixa de",
		"tips7-3": "",
		"WithdrawalAmount": "Quantidade retirada",
		"WithdrawalAmountmsg": "Por favor, insira o valor",
		"Withdrawal": "Cancelamento",
		"WithdrawalHistory": "Histórico de retirada",
		"Tipsmsg": "A solicitação de retirada foi bem-sucedida, aguardando o resultado da auditoria!",
		"dangerMsg1": "Adicione seu cartão bancário primeiro",
		"dangerMsg2": "O valor da retirada não pode ser inferior a",
		"dangerMsg3": "O valor da retirada deve ser um múltiplo de 100",
		"dangerMsg4": "O valor da retirada não pode ser maior que",
		"dangerMsg5": "O valor do saque não pode ser maior que o saldo disponível",
		"dangerMsg6": "Adicione seu cartão bancário primeiro"
	},
	"withdrawalrecord": {
		"title": "registro de retirada",
		"WatingOrder": "Ordem de Rebatidas",
		"HistoryOrder": "Ordem do Histórico",
		"Rupee": "Dólar",
		"Status": "Status",
		"Time": "Tempo",
		"Nomore": "Não mais!"
	},
	"Version": {
		"title": "Versão",
		"stableversion": "Versão estável",
		"Alreadythelatestversion": "Já é a versão mais recente",
		"Newversionfound": "Nova versão encontrada",
		"DownloadUpdate": "Baixar atualização"
	},
	"Bonus": {
		"title": "Atividade bônus",
		"ReceiveBonus": "Receber bônus",
		"Hi": "Hi",
		"tips1": "Parabéns por receber o bônus",
		"tips2": "Você precisa recarregar maior ou igual a",
		"tips3": "e então o bônus será adicionado automaticamente ao saldo da sua conta",
		"Received": "Recebido",
		"Back": "Voltar",
		"Rechargenow": "Recarregue agora"
	},
	"ComplaintsSuggestions": {
		"title": "Reclamações e Sugestões",
		"Answered": "Respondidos",
		"AddNew": "Adicionar novo",
		"Time": "Tempo",
		"Type": "Tipo",
		"WhatsApp": "WhatsApp",
		"Description": "Descrição",
		"Remark": "Observação",
		"Waiting": "Esperando",
		"Suggestion": "Sugestão",
		"Consult": "Consultar",
		"RechargeProblem": "problema de recarga",
		"WithdrawProblem": "Problema de Retirada",
		"OrderProblem": "Problema de pedido",
		"Other": "Outro",
		"Describeyourproblems": "Descreva seus problemas",
		"WhatsApptocontactyou": "WhatsApp para entrar em contato com você",
		"submit": "enviar",
		"err1": "O WhatsApp não pode ficar vazio!",
		"err2": "O tipo não pode estar vazio!",
		"err3": "A descrição não pode ficar vazia!"
	},
	"slotMachine": {
		"title": "máquina de frutas",
		"Myblance": "meu saldo",
		"Records": "Discos de máquina de frutas",
		"titleRecord": "Registros"
	},
	"bankrecharge": {
		"Orderamount": "Valor do pedido",
		"Ordernumber": "Número do pedido",
		"BankName": "Nome do banco",
		"BankAccount": "Conta bancária",
		"IFSC": "IFSC",
		"Yourbankcardnumber": "O número do seu cartão bancário",
		"Enterthebanktransactionordernumber": "Digite o número do pedido da transação bancária",
		"submit": "enviar",
		"Tips": "Pontas",
		"tips1": "1. O sistema está processando seu pedido.",
		"tips2": "2. entre em contato com o atendimento ao cliente se a conta não chegar por muito tempo. ",
		"err1": "Insira o número do seu cartão bancário",
		"err2": "Por favor, insira o número do pedido bancário"
	},
	"javaCode": {
		"200": "sucesso",
		"20001": "invalid access token",
		"20002": "invalid login token",
		"20003": "Phone error",
		"20004": "Phone has exists!",
		"20005": "Email error!",
		"20006": "Email exists!",
		"20007": "Password error!",
		"20010": "account or pwd error!",
		"20011": "bank account error!",
		"20012": "err bank ifsc, (standard IFSC format) - length 11, first four IFSC and 5th 0!",
		"20013": "beneficiary name error, 5 < name <= 50!",
		"20021": "Account not exists!",
		"20022": "Account is freeze!",
		"20031": "The layer is limit 10 !",
		"20032": "update relation role error !",
		"20033": "not enough balance!",
		"20034": "Incorrect withdrawal amount!",
		"30001": "Service temporarily closed!",
		"30002": "limit bet money is between 10 and 50000!",
		"30003": "Current issue has finished !",
		"30005": "Checked in",
		"31001": "Single transaction exceeds the limit !",
		"31002": "Today's total amount has reached the upper limit !",
		"31003": "The number of times today has reached the limit  !",
		"31004": "Current Staff un config, pls contact admin or agent!",
		"200081": "Register Error!",
		"200082": "account has disable!",
		"200091": "add card is limit 10!",
		"200092": "input error login pwd too many times, and the account is disable!",
		"300041": "All transaction amount of issue has limit !",
		"300042": "All transaction amount of user has limit !",
		"300043": "Bet item error !",
		"300044": "You have already purchased it and cannot buy it again!",
		"Servererror": "Erro de servidor!",
		"Handledataerror": "Por favor entre!",
		"-91": "Erro de servidor!",
		"-92": "Lidar com erro de dados!",
		"-1": "Erro no sistema!",
		"-2": "erro de parâmetros",
		"-3": "sem dados",
		"-4": "operação frequente em pouco tempo",
		"-5": "Sistema ocupado, tente novamente mais tarde!",
		"-6": " Há muitas pessoas no momento, tente novamente!",
		"-7": "erro de dados!",
		"-8": "Google code error!",
		"-9": "System is being maintained! please try again later !",
		"-10": "save error!",
		"-11": "delete error!",
		"-12": "update error!",
		"-13": "Ilegal operation!",
		"-14": "Operation fail!",
		"-15": "Operation disabled!",
		"-16": "Operation Finished!",
		"-17": "Exists error",
		"-18": "Not exists!",
		"-19": "expires",
		"-20": "Has disabled",
		"-21": "err verify code",
		"-22": "The modification interval must be greater than 1 day",
		"-23": "The new password cannot be the same as the original password",
		"-100": "custom error!",
		"-881": "System is being maintained! please try again later !",
		"-882": "Amount params error",
		"-883": "Channel Params error",
		"-884": "Channel is maintained, pls try again later!",
		"-885": "Channel is maintained, pls try again later!",
		"-886": "Current recharge amount is less than the min limit amount!",
		"-887": "Amount params error",
		"-888": "Maximum number of withdrawals over a day",
		"-889": "Maximum withdrawal amount exceeding one day",
		"-8810": "Not enough code amount, also need code amount ",
		"-8811": "Exceeded the maximum withdrawal amount",
		"-8812": "Below the minimum withdrawal amount",
		"-8813": "Current time is not service for withdraw !"
	},
	"Management": {
		"TotalPurchaseAmount": "Valor total da compra",
		"TotalRevenue": "Rendimento total",
		"AccountBalance": "Saldo da conta",
		"Income": "Renda",
		"Transaction": "Transação",
		"Myhold": "meu domínio",
		"ProductList": "Lista de produtos",
		"Investmentperiod": "Período de investimento",
		"days": "dias",
		"Buynow": "Comprar agora",
		"Saletime": "Sale time",
		"Nomore": "No more",
		"ProductDetails": "Product Details",
		"ForSale": "For Sale",
		"Sale": "Sale",
		"SoldOut": "Sold Out",
		"ProductInformation": "Product Information",
		"InvestmentPeriod": "Investment Period",
		"TotalShare": "Total Share",
		"ExpectedReturn": "Expected Return",
		"SaleTime": "SaleTime",
		"PurchaseConditionsAndRules": "Purchase Conditions And Rules",
		"MinimumPurchaseAmount": "Minimum Purchase Amount",
		"MaximumSalesLimit": "Maximum Sales Limit",
		"AccountBalanceMustBeGreaterThan": "Account Balance Must Be Greater Than",
		"DayBetIsGreaterThan": "Day Bet Is Greater Than",
		"ProductDescription": "Product Description",
		"MyHold": "My Hold",
		"Ordernumber": "Order number",
		"PurchasingPrice": "Purchasing Price",
		"NoRecord": "No Record",
		"Expiredate": "Expire date",
		"Purchasetime": "Purchase time",
		"redemption": "redemption",
		"redemptionTips": "Early redemption will not generate any income, is the redemption confirmed?",
		"cancel": "cancel",
		"determine": "determine",
		"Buyin": "Buy in",
		"Buyamount": "Buy amount",
		"BuyinTips": "Early redemption will not generate any income ",
		"confirm": "confirm",
		"Minimumbuy": "Minimum buy",
		"Maturity": "Maturity",
		"EarlyRedemption": "Early Redemption",
		"Redemptiontime": "Redemption time",
		"Amountofincome": "Amount of income",
		"prompt": "prompt"
	},
	"HelpHa": {
		"Help": "Ajuda",
		"HelpCenter": "Centro de ajuda",
		"text": "qualquer dúvida, olhe aqui"
	},
	"SetingHa": {
		"Seting": "Contexto",
		"SetingCenter": "Centro de configuração",
		"text": "Obtenha mais recompensas"
	},
	"coinjs": {
		"tips1": "Please switch the network to  +this.mNetWorkConfig.name+ and try again",
		"tips1_1": "Please switch the network to ",
		"tips1_2": " and try again",
		"trxErr": "You don't have enough TRX. You need 10 TRX to pay miner fees"
	},
	"coinDefi": {
		"staking": "Staking Now",
		"All_Chains": "All Chains",
		"Annual_Percentage_Yield": "Annual Percentage Yield",
		"Daily_Yield_Rate": "Daily Yield Rate",
		"Mining_Period": "DeFi Period",
		"Flexible": "Flexible",
		"Wallet_Balance": "Wallet Balance",
		"Daily_Yield": "Daily Yield",
		"DeFi_Mining": "Join DeFi Mining Now",
		"Disconnect": "Disconnect",
		"Copy_Link": "Copy Link",
		"text1": "Mobile brower not supported",
		"text2": "TRON doesn't support mobile browser to connet to the wallet.",
		"text3": "Please open it in <strong>mobile wallet browser</strong> that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_1": "Please open it in ",
		"text3_2": "mobile wallet browser",
		"text3_3": " that supports TRON(TRX) chain and make sure the current chain is TRON(TRX). like",
		"text3_4": " etc.",
		"tips1": "Don't repeat DeFi Mining",
		"tips2": "Failed, please try again later",
		"tips3": "The wallet plugin has not been detected, please install the TronLink wallet",
		"tips4": "Please log in to TronLink wallet first"
	},
	"stop_investing": "Pare de investir",
	"register.checkerr": "Senha: Use 8 ou mais caracteres (combinação de letras e números)",
	"home.Products&Services": "Produtos e serviços",
	"home.Legal&Disclosures": "Declaração legal e divulgação",
	"home.Regulatory_License": "Licença Regulatória",
	"home.AML/KYC_Policies": "Política AML/KYC",
	"home.Privacy_Policy": "Política de Privacidade",
	"home.Terms_of_Use": "Termos de Utilização",
	"home.Legal_Statement": "Declaração jurídica",
	"home.Risk_Disclosure": "Divulgação de riscos",
	"home.About_Us": "Sobre nós",
	"home.Protection_Fund": "Fundo de Protecção",
	"home.Proof_of_Reserves": "Prova de reserva",
	"home.Bitget_Token": "Ficha EPOCH EPT",
	"home.Support_Service": "Serviços de Apoio",
	"Activity.content0_1": "Complete qualquer convite válido para ganhar um valor médio de até",
	"Activity.content0_2": "O bônus máximo para cem convidados é",
	"Activity.Daily_Tasks": "Tarefas Diárias",
	"mine.ProvacyPolicy": "Política de Privacidade",
	"Rechargepage.Tips": "Lembrete gentil: A primeira recompensa de recarga é {{firstRPR * 100}% do valor da recarga",
	"Rechargepage.Tips_1": "Lembrete gentil: A primeira recompensa de recarga é baseada no valor da recarga",
	"Rechargepage.Tips_2": "%",
	"withdrawal.Google_Code": "Código de verificação do Google",
	"index.index.gayb21": "Cadastre-se para receber recompensas:",
	"index.index.72y628": "Três razões para escolher EPOCH EPT",
	"index.index.z6022y": "EPOCH EPT segue um dos mais altos padrões de segurança do setor. Armazenamos 100% de nossa criptomoeda em um armazém refrigerado. Garantimos fornecer pelo menos uma taxa de reserva de 1:1 para os fundos dos clientes. Nosso fundo de US$ 300 milhões oferece proteção adicional contra ameaças à segurança.",
	"index.index.574982": "1. Registre-se para EPOCH EPT gratuitamente agora",
	"index.index.qdi1g4": "2. Verificação completa do Google",
	"index.index.weko28": "3. Recarregue instantaneamente a sua conta EPOCH EPT",
	"index.index.sgkb35": "Programa de Convites EPOCH EPT",
	"index.index.2u17nf": "Completar qualquer convite válido pode ganhar um valor médio de até 5,59 USD O bônus máximo para cem convidados é",
	"index.index.e8is4a": "Programa de Recomendação EPOCH EPT",
	"index.index.47uevl": "Tarefa de transacção EPOCH EPT",
	"index.index.2516gq": "A EPOCH EPT, Inc. obteve uma licença de atividade comercial em moeda virtual do Departamento de Serviços Financeiros do Estado de Nova York.",
	"invest.index.3aqi3d": "Saldo da conta:",
	"invest.index.ubk1j4": "Pára de investir!",
	"invest.prize1.4733u6": "Carteira de investimento:",
	"invest.prize1.t55a16": "Montante total:",
	"login.login.09m52q": "Li e concordo com EPOCH EPT",
	"login.login.045csy": "Nome do utilizador",
	"login.login.r7rbm5": "Por favor, concorde com o acordo!",
	"rewards.activityb.4nvrq9": "Quando o convidado completar seu primeiro depósito, ele é considerado um convite válido. O convidado receberá um bônus de convite para cada convite válido. O nível de bônus de convite vem de 2 USD 1 convite válido, 559 USD 100 convites válidos.",
	"rewards.activityb.t10k6e": "O convidado compartilhará 20% da comissão de transação para cada taxa de transação do LV.1 convidado. O convidado compartilhará 10% da comissão de transação para cada LV. 2 taxa de transação do convidado. O bônus máximo para a recompensa da taxa de transação do convidado é de dólares americanos ilimitados.",
	"rewards.activityb.3d6850": "LV.1 Número efectivo de convidados",
	"rewards.activityb.lvxo74": "LV.2 Número efectivo de convidados",
	"rewards.activityb.zj26f9": "LV.1 Número acumulado de convidados",
	"rewards.activityb.dkgxr5": "LV.2 Número acumulado de convidados",
	"rewards.activityb.xjo077": "Recompensa de recomendação:",
	"rewards.activityb.k8wv6v": "Junte-se à EPOCH EPT e obtenha seu link recomendado. Os usuários registrados através deste link receberão recompensas.",
	"rewards.activityb.4slpzg": "Depois que seu amigo completar seu primeiro depósito e completar o volume de transação exigido, você receberá uma recompensa de referência com um valor médio de US $ 5,59. A recompensa máxima pode chegar a US $ 559.",
	"rewards.index.993jwg": "Copiado com sucesso",
	"rewards.index.7141ft": "Este navegador não suporta cópia automática",
	"wallet.index.9uz2gn": "Taxa de tratamento da rede 0 USDT",
	"wallet.index.ych3e1": "Recepção efectiva:",
	"wallet.index.3v558d": "Lembrete: Se a retirada for inferior a US $ 20, a taxa de processamento de retirada é de US $ 1. Se a retirada for superior a US $ 20, a taxa de processamento de retirada é de 3%",
	"wallet.index.255e30": "1. O valor máximo de retirada diária é de US $ 8000000",
	"wallet.index.3g68m8": "2. O valor mínimo de retirada única é de US $ 5, e o valor máximo de retirada única é de US $ 8000000",
	"wallet.index.c6x3ti": "3. O tempo de retirada é de 00:00 a 23:59, por favor, faça a retirada dentro do tempo especificado",
	"wallet.index.h94rlg": "Limite mínimo 5",
	"wallet.index.167862": "Por favor, introduza o endereço da carteira!",
	"wallet.index.pi869h": "Por favor insira o valor da retirada!",
	"wallet.index.ysa85i": "O montante máximo de levantamento único é",
	"components.changepass.68j9kh": "Você está tentando redefinir sua senha.",
	"components.changepass.ouxtp2": "Senha original",
	"components.languagebox.8no849": "Japonês",
	"components.languagebox.oif20b": "Chinês tradicional",
	"components.mycode.xyqr66": "Completar tarefas pode ganhar até 5000 USDT",
	"components.mymenu.277ss7": "ID de utilizador:",
	"components.mymenu.74l443": "Hora de inscrição:",
	"components.myposter.36rw5e": "Código da recomendação:",
	"components.mytabbar.zw2bh2": "Página inicial",
	"components.mytabbar.8x6c28": "tendência",
	"components.mytabbar.28s7qr": "investimento",
	"components.mytabbar.4r75d9": "recompensa",
	"components.xieyi.zqk7pe": "Tamanho do tipo de letra: 16px; Família da fonte: Tahoma;",
	"components.help.6gpud8": "Existem dois modelos de investimento:",
	"components.help.5qrq36": "Cada período dura 1 minuto e oferece apenas investimentos individuais ou duplos. O resultado é calculado pelo último dígito do preço de fechamento da linha K de 1 minuto da negociação à vista BTC/USDT da Binance.",
	"components.help.isl7r1": "Cada sessão dura 5 minutos. A versão profissional desbloqueia todos os métodos de investimento da EPOCH EPT. O resultado é calculado pelo último dígito do preço de fechamento da linha K de 5 minutos da negociação à vista BTC/USDT da Binance.",
	"components.help.eai3nm": "Rendibilidade do investimento:",
	"components.help.35xg82": "Número único:",
	"components.help.mpfg0s": "Rendimentos: Montante do investimento X 9.4",
	"components.help.v2ziib": "Número ímpar:",
	"components.help.vfzxor": "Rendimentos: Montante do investimento X 1,94",
	"components.help.73168b": "Número da véspera:",
	"components.help.76wgv1": "Grande número:",
	"components.help.05ed33": "Número Pequeno:",
	"home.content4_2": "",
	"Activity.contentText_3": "",
	"Activity.contentText_3_1": "",
	"withdrawal.tips7-3": "",
	"components.changepass.2838g2": "Por favor introduza a senha original!",
	"components.changepass.xme6my": "Por favor introduza uma nova senha!",
	"components.changepass.u434f8": "As duas senhas não correspondem!",
	"components.changepass.574790": "Modificado com sucesso!",
	"invest.index.9y8wg0": "Não ganhar um prémio",
	"invest.index.ql5nc1": "Prémios vencedores",
	"login.login.76451t": "Diferença de preço de câmbio Uma negociação quantitativa!",
	"login.login.915p36": "Gestão financeira ocupa o primeiro lugar a nível mundial",
	"login.login.sen863": "Mais excitante depois do Hello entrar",
	"login.login.nz6q9x": "Login do telefone",
	"login.login.w7p592": "Login de e- mail",
	"login.login.g9y91k": "Número de telemóvel",
	"login.login.456gbb": "Número de e-mail",
	"login.login.5ly8m8": "país",
	"login.login.69h472": "Seleccione por favor um país",
	"login.login.i4p378": "número",
	"login.login.fl82xx": "Exemplo:",
	"wallet.index.urkoub": "Recarregar o código QR",
	"wallet.index.25cocl": "Copiar o endereço",
	"wallet.index.t35fvg": "1. tempo de retirada: Por favor, calcule o imposto regional no domingo dentro de 24 horas de segunda a sábado!",
	"wallet.index.68xx61": "2. Retiradas de moeda nacional global devem ser recebidas no prazo máximo de 48 horas, com uma taxa de retirada de 8% (incluindo impostos regionais).",
	"wallet.index.4ws3w9": "3. A retirada USDT deve ser creditada na conta no prazo máximo de 24 horas, com uma taxa de manuseio de 2% (incluindo imposto regional)",
	"wallet.index.9b7wq8": "4. O valor mínimo de retirada é de 12 USDT, e o valor máximo de retirada única é de 3000 USDT.",
	"wallet.index.23y92a": "Por favor, esteja ciente de que",
	"wallet.index.pl5473": "Limite mínimo de 5",
	"wallet.index.nb3u72": "Informação do cartão bancário",
	"wallet.index.842so4": "Adicionar o número do cartão bancário",
	"wallet.index.86j717": "Adicionar banco afiliado",
	"wallet.index.763d7x": "Adicionar o nome do cartão bancário",
	"wallet.index.50j237": "Banco afiliado",
	"wallet.index.hb6lun": "Nome do cartão bancário",
	"wallet.index.62y2jc": "Número do cartão bancário",
	"login.register.666hp4": "Negociação quantitativa da diferença de preço Al",
	"login.register.9298b8": "Convidar amigos",
	"login.register.375g14": "Os recém-chegados juntam-se pela primeira vez",
	"login.register.01922f": "Pode ser realizado uma vez",
	"login.register.527o4q": "Receita diária de produtos químicos IA",
	"login.register.c563gt": "Registo por e-mail",
	"login.register.2ls856": "Erro no formato do número de telefone!",
	"login.register.4nu77e": "Erro no formato do número de e- mail!",
	"lang.zh.pu623z": "1 convite válido",
	"lang.zh.3hv80b": "Deposite USDT!",
	"lang.zh.47ttf6": "Lembrete: a primeira recompensa de recarga é de {{firstRPR*100}}% do valor da recarga",
	"lang.zh.9tli5f": "Dica: a retirada é menor que",
	"lang.zh.62e882": "USD, a taxa de retirada é",
	"lang.zh.m3818q": "USD, retirada",
	"lang.zh.xs2f10": "O resgate antecipado não gerará lucro",
	"invest.index.hl86r1": "Assistente de Investimento",
	"wallet.index.645d70": "Indique o nome do seu banco",
	"wallet.index.n21787": "Indique por favor o nome do utilizador do cartão bancário",
	"wallet.index.7s5j14": "Introduza o número do seu cartão bancário",
	"components.recharge.l576h7": "Variações do saldo",
	"components.recharge.3x6844": "Observações"
}