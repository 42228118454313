import i18n from '../lang/index.js';
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        userInfo: {}
    },
    getters: {
        userInfo: (state) => state.userInfo
    },
    mutations: {
        SET_USERINFO(state, payload) {
            // console.log(payload);
            state.userInfo = payload;
        }
    },
    actions: {},
    modules: {}
});
